<h3 class="fs-20 fw-600 text-grey m-0 py-3 text-center w-100 "
    *ngIf="contract && contract.show_ach==1 && contract.bank_accounts && contract.bank_accounts.length>0 && contract.bank_accounts[0].bank_status!='new'">
    Pay using Customer's connected bank account
</h3>
<div class="a"
    *ngIf="contract && contract.show_ach==1 && contract.bank_accounts && contract.bank_accounts.length>0 && contract.bank_accounts[0].bank_status!='new'">
    <div *ngFor="let card of contract.bank_accounts">
        <div class="row border rounded p-2 mb-2 m-0" [ngClass]="{'highlight' : source_id==card.source_id}"
            *ngIf="card.payment_source_type==1">
            <div class="d-flex justify-content-between">
                <p class="m-0"><i class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}}</p>
                <p class="p-0 d-flex justify-content-between">{{card.brand}}
                    <span class="m-0 p-0 d-flex align-items-center" style="color:#36af47"></span>
                </p>
                <p class="btn btn-primary radius-40" (click)="callFunction(card)"
                    *ngIf="source_id==card.source_id && card.bank_status=='succeeded'">
                    Selected</p>
                <div class="p-0 text-end" *ngIf="source_id!=card.source_id && card.bank_status=='succeeded'">
                    <button class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                        (click)="callFunction(card)">Select</button>
                </div>
            </div>
            <p class="col-4 p-0 text-end text-danger fs-14 m-0 " *ngIf="card.bank_status=='reauthenticate'">
                Reauthentication Required
            </p>
        </div>
    </div>
</div>
<div class="b"
    *ngIf="contract && contract.show_ach==1 && contract.bank_accounts && contract.bank_accounts.length>0 && contract.bank_accounts[0].bank_status!='new'">
    <p class="text-muted m-b-0">*Note</p>
    <ul>
        <li class="text-muted">Please make sure you have the appropriate funds available in your
            account.</li>
        <li class="text-muted">Processing time may take up to 7 business days.</li>
        <li class="text-muted">In the event of a failed transaction, you may be subject to late fees
            and/or failed transaction fees.</li>
        <li class="text-muted">Your Next payment is due for {{contract.next_payment_date |
            date:"MM/dd/yyyy": '+0000'}}. If the funds are not realized for the payment done through
            the Bank before the next payment date we will attempt it.</li>
    </ul>
</div>
<div class="c">
    <p class="m-0"
        *ngIf="contract && contract.show_ach==1 && contract.bank_accounts && contract.bank_accounts.length>0 && contract.bank_accounts[0].bank_status!='new'">
        <b>OR</b>
    </p>
    <label class="fs-20 fw-600 text-grey m-0 py-3 text-center w-100 "> Pay using Customer's card(s)</label>
</div>
<div class="col-12 mr-2" style="max-height:250px;overflow-y:auto">
    <div *ngFor="let card of contract.payment_sources">
        <div class="row border rounded p-2 mb-2 m-0" [ngClass]="{'highlight' : source_id==card.source_id}"
            *ngIf="card.payment_source_type!=1">
            <div class="d-flex justify-content-between align-items-center">
                <p class="m-0 p-0 d-flex"><i class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}</p>
                <p class="m-0 p-0 d-flex">{{card.brand}}
                    <span class="m-0 px-3 d-flex align-items-center" style="color:#36af47"
                        *ngIf="card.source_id==contract.source_id">
                        <i class="fa fa-check-circle"></i>&nbsp;Default</span>
                    <span class="m-0 px-3 d-flex align-items-center" style="color:#36af47" *ngIf="card.is_default == 1">
                        <i class="fa fa-check-circle"></i>&nbsp;Default</span>
                </p>
                <p class="btn btn-primary radius-40 m-0" (click)="callFunction(card)" *ngIf="source_id==card.source_id">
                    Selected </p>
                <div class="p-0 text-end m-0"
                    *ngIf="source_id!=card.source_id && (!checkDisableAction || (checkDisableAction && contract.disable_action && contract.disable_action.length == 0))">
                    <button class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                        (click)="callFunction(card)">Select</button>
                </div>
            </div>
            <p class="col-12 bg-danger" *ngIf="card.last_error">{{card.last_error||''}}</p>
        </div>
    </div>
</div>