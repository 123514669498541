import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ContractsListComponent } from './contracts-list/contracts-list.component';
import { ContractsDetailsComponent } from './contracts-details/contracts-details.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ContractTabsComponent } from 'src/app/components/pages/contracts/contract-tabs/contract-tabs.component';
import { HoverContractComponent } from './hover-contract/hover-contract.component';
import { ContractService } from './contracts.service';
import { EditPersonalInfoComponent } from './edit-personal-info/edit-personal-info.component';
import { DisputedComponent } from './disputed/disputed.component';
import { CustomerDisputesComponent } from './customer-disputes/customer-disputes.component';
import { MismatchCardholderComponent } from './mismatch-cardholder/mismatch-cardholder.component';
import { DisputedChargeInfoComponent } from './disputed-charge-info/disputed-charge-info.component';
import { SettledContractsComponent } from './settled-contracts/settled-contracts.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full'
  },
  {
    path: 'list', component: ContractsListComponent
  },
  {
    path: 'details/:id', component: ContractsDetailsComponent
  },
  {
    path: 'edit-personal-info/:id', component: EditPersonalInfoComponent
  },
  {
    path: 'disputed', component: DisputedComponent
  },
  {
    path: 'customers-disputed', component: CustomerDisputesComponent
  },
  {
    path: 'mismatch-cardholdername-listing', component: MismatchCardholderComponent
  },
  {
    path: 'charges/:id', component: DisputedChargeInfoComponent
  },
  {
    path: 'settled', component: SettledContractsComponent
  }
]

@NgModule({
  declarations: [ContractsDetailsComponent, DisputedChargeInfoComponent, MismatchCardholderComponent, ContractTabsComponent, CustomerDisputesComponent, ContractsListComponent, HoverContractComponent, EditPersonalInfoComponent, DisputedComponent, SettledContractsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    PaginationModule
  ],
  providers: [ContractService]
})
export class ContractsModule { }
