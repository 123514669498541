import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ProfileComponent } from './profile/profile.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomerModalComponent } from './customer-modal/customer-modal.component';
import { CustomerContractsComponent } from './contracts/contracts.component';
import { BusinessLeadsComponent } from './business-leads/business-leads.component';
import { BusinessLeadDetailsComponent } from './business-lead-details/business-lead-details.component';

const routes: Routes = [
  { path: '', redirectTo: 'customers', pathMatch: 'full' },
  {
    path: 'customers',
    component: CustomersComponent,
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
  },
  {
    path: 'contracts/:id',
    component: CustomerContractsComponent,
  },
  {
    path: 'leads',
    component: BusinessLeadsComponent,
  },
  {
    path: 'leads/details/:id',
    component: BusinessLeadDetailsComponent,
  },
];
@NgModule({
  declarations: [
    CustomersComponent,
    ProfileComponent,
    CustomerContractsComponent,
    BusinessLeadsComponent,
    BusinessLeadDetailsComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    ModalModule,
    CustomerModalComponent,
  ],
})
export class CustomersModule {}
