import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value?: any, args?: any, arg2?: any): any {
    if (value) {
      if (!args) { return value; }
      var re = new RegExp(args, 'gi'); // 'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
      if (arg2 == 'capital') {
        return value.replace(re, `<mark class="text-capitalize">${args}</mark>`);
      } else {
        return value.replace(re, `<mark>${args}</mark>`);
      }
    } else {
      return value;
    }
  }

}
