import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-disputed-charge-info',
  templateUrl: './disputed-charge-info.component.html',
  styleUrl: './disputed-charge-info.component.scss'
})
export class DisputedChargeInfoComponent {
  id: any;
  chargeInfo: any;
  transfers: any;
  timezone :any;
  timezoneName:any;
  temp: any;
 constructor(
  public _shared: SharedService,
  private route: ActivatedRoute,
  private _api: ApiService,
  private location: Location
){}
  ngOnInit(): void {
    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();
    this.id = this.route.snapshot.paramMap.get('id');
  this.getChargeInfo(this.id)
  }

  getChargeInfo(id:any){
     let data = {
      charge_id :id,
      offset :0,
      limit:10
     }
     this._api.api(data,"disputes/charge_info").subscribe({
      next:(data)=>{
        this.chargeInfo = data.charge_info;
        this.transfers = data.transfers;
        this.temp = data.transfers;
      },
      error:(error)=>{
        console.log(error,"error-----");
        
      }
     })
  }

  goBack() {
    this.location.back();
  }
}
