<div class="m-4">

	<div class="row mb-4 align-items-center">
		<div class="col-sm-6 col-md-3">
		<h3 class="m-0 p-10">Proposal Request Details</h3>
		</div>
		<h3 class="m-0 mt-2 p-10 w-100 text-danger text-center" style="background-color: #ffe8e8;"
		*ngIf="splitInfo && splitInfo.is_deleted==1">Proposal is deleted</h3>
	</div>
  
	<div class="card p-3" *ngIf="splitInfo && splitInfo.split_req_id">
		<div class="button-container" *ngIf="splitInfo.is_deleted==0">
		<button class="btn add-notes" (click)="addContractNotesModal()">Add Notes</button>
		<ng-container
			*ngIf="!(splitInfo.ppr_status && splitInfo.ppr_status==2) && !(splitInfo.doctor_status == 2 && splitInfo.patient_status == 2 && splitInfo.contract_id)">
			<div class="toggle-container" *ngIf="is_ar_view">
			<h4 class="m-b-10 p-10">Proposal Reminder Email</h4>
			<label class="switch switch-sm switch-success my-2">
				<input type="checkbox" [checked]="splitInfo.stop_reminder == 0 || splitInfo.stop_reminder == 2"
				(change)="stop_resume_reminder($event,1)" />
				<span></span>
			</label>
			<h4 class="m-b-10 ml-4 p-10">Proposal Reminder SMS</h4>
			<label class="switch switch-sm switch-success my-2">
				<input type="checkbox" [checked]="splitInfo.stop_reminder == 0 || splitInfo.stop_reminder == 1"
				(change)="stop_resume_reminder($event,2)" />
				<span></span>
			</label>
			</div>
			<div class="buttons-row py-2">
				<button class="btn resend" (click)="ResendPlanModal()"
				*ngIf="splitInfo.stop_reminder==0 || (splitInfo.stop_reminder==1 && splitInfo.patient_mobile) || (splitInfo.stop_reminder==2 && splitInfo.patient_email)">Resend
				Proposal</button>
				<button class="btn change-settings" (click)="PaymentSettingsModal()">Change
				Settings</button>
				<button class="btn payment-link" (click)="gotopaymentLink()" *ngIf="is_ar_view">Payment
				Link</button>
				<button class="btn delete" (click)="deleteRequest()"
			*ngIf="!(splitInfo.ppr_status && splitInfo.ppr_status==2) && !(splitInfo.doctor_status == 2 && splitInfo.patient_status == 2 && splitInfo.contract_id)">Delete</button>
			</div>
		</ng-container>
		<button class="btn add-notes" tooltip="View Contract"
			*ngIf="splitInfo.doctor_status == 2 && splitInfo.patient_status == 2 && splitInfo.contract_id"
			(click)="goToContract(splitInfo.contract_id)"> View Contract
		</button>
		<button class="btn add-notes" tooltip="View Contract"
			*ngIf="splitInfo.ppr_status && splitInfo.ppr_status==2" (click)="goToPaymentProcess(splitInfo.ppr_id)"> View
			Payment Details
		</button>
		<button class="btn add-notes"
			*ngIf="splitInfo.contract_patient_token && baseUrlCopyLink && (splitInfo.patient_status != 2 && splitInfo.doctor_status != 2 && splitInfo.ppr_status && splitInfo.ppr_status != 2)" (click)="copyLink()"> Copy Link
		</button>
		<!-- <button class="btn delete" (click)="deleteRequest()"
			*ngIf="!(splitInfo.ppr_status && splitInfo.ppr_status==2) && !(splitInfo.doctor_status == 2 && splitInfo.patient_status == 2 && splitInfo.contract_id)">Delete</button> -->
		</div>
	
		<div class="d-flex">
		<div class="p-0" [ngClass]="{'col-6':is_ar_view,'col-12':!is_ar_view}">
			<div class="card-body  col-12 border">
			<h4 class="m-b-10 p-10">Customer Info</h4>
			<div class="row m-0">
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4"
				*ngIf="splitInfo && splitInfo.split_status && splitInfo.split_status=='Financed'">
				<label>Contract Code</label>
				<p>
					<a [routerLink]="'/app/contracts/details/'+splitInfo.contract_id" class="text-primary ">{{splitInfo.contract_code
					|| 'N.A.'}}</a>
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Customer Name</label>
				<p>
					{{splitInfo.title?splitInfo.title+' ':''}} {{splitInfo.patient_first_name+'
					'+splitInfo.patient_last_name}}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Customer Email</label>
				<p>
					{{splitInfo.patient_email || 'N.A.'}}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Customer Phone</label>
				<p>
					{{splitInfo.patient_mobile ? '+1-'+(splitInfo.patient_mobile) : '-'}}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Address</label>
				<p *ngIf="splitInfo.patient_address">
					{{splitInfo.patient_address}}
				</p>
				<p *ngIf="!(splitInfo.patient_address)">
					N.A.
				</p>
				</div>
			</div>
			<h4 class="m-b-10 mt-2 p-10">Business Owner Info</h4>
			<div class="row m-0">
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Name</label>
				<p>
					<a [routerLink]="'/app/business/details/'+splitInfo.doctor_id"
					class="text-primary ">{{splitInfo.doctor_name|| ''}}</a>
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Email</label>
				<p>
					{{splitInfo.doctor_email?splitInfo.doctor_email:'N.A.'}}
				</p>
				</div>
			</div>
			</div>
	
			<div class="card-body border  col-12 mt-2">
			<h4 class="m-b-10 mt-2 p-10">Proposal Info</h4>
			<div class="row m-0">
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Contract Type</label>
				<p>
					<span *ngIf="splitInfo.contract_type==2" class="badge bg-success">
					CredeeFi Financing
					</span>
					<!-- <span *ngIf="splitInfo.contract_type==1" class="badge bg-warning">
								10% Service Fee
							</span> -->
					<span *ngIf="splitInfo.contract_type==3" class="badge bg-primary">
					KeepeeFi Financing
					</span>
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Request Date</label>
				<p>
					{{splitInfo.date_added?(splitInfo.date_added|date: 'MM-dd-yyyy':timezone):'N.A.' }}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Proposal Expiry Date <img *ngIf="(splitInfo.contract_type !== 5 && splitInfo['proposal_status_id'] != 4) || (splitInfo.contract_type == 5 && (splitInfo.proposal_status != 'Payment Completed' && splitInfo.proposal_status != 'Plan Created'))" class="ml-2" style="cursor: pointer;" (click)="editExpiryDateModal()" src="assets/images/png/edit.png"></label>
				<p>
					<!-- {{ splitInfo.start_date?(splitInfo.start_date|date: 'MM-dd-yyyy': '+0000'):'N.A.' }}
					to -->
					{{ splitInfo.end_date?(splitInfo.end_date|date: 'MM-dd-yyyy': '+0000'):'N.A.' }}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Proposal Resent</label>
				<p>
					{{splitInfo.resend_email_count ? splitInfo.resend_email_count : 0}}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Last Resent</label>
				<p>
					{{splitInfo.last_email_sent ? (splitInfo.last_email_sent | date:'MM-dd-yyyy hh:mm a':timezone) : 'N.A.'}}
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Last Opened</label>
				<p>
					{{splitInfo.last_link_opened ? (splitInfo.last_link_opened | date:'MM-dd-yyyy hh:mm a':timezone) :
					'N.A.'}}
				</p>
				</div>
	
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Service(s)</label>
				<p>
					<span>
					{{splitInfo.service_name}}
					</span>
					<span *ngIf="splitInfo.is_expired == 1" class="badge bg-danger">Expired</span>
				</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
				<label>Status</label>
				<p>
					<span>
					{{splitInfo.split_status}}
					</span>
					<span *ngIf="splitInfo.is_expired == 1" class="badge bg-danger">Expired</span>
				</p>
				</div>
	
			</div>
			</div>
		</div>
		<div class="card-body border  col-6 ml-2" *ngIf="is_ar_view">
			<h4 class="m-b-10 mt-2 p-10">Proposal Status</h4>
			<div class="row m-0">
			<div style="max-height:calc(100vh - 204px);overflow: auto;padding: 10px;">
				<div class="tracking_status_container border-left pl-5 pr-4 pb-3"
				[ngClass]="{'border-left': ( status_data.reminder_type!=6 && !last),'upcoming_tracking':status_data.reminder_type==6}"
				*ngFor="let status_data of splitInfo.tracking_reminders;let last = last;">
				<p class="mb-1">{{status_data.reminder_message ? status_data.reminder_message : ''}}</p>
				<p class="m-0">{{status_data.reminder_date_message ? status_data.reminder_date_message + ' ' : ''}} {{status_data.date_sent| date:'MM/dd/yyyy (hh:mm a)': timezone}}</p>
				</div>
			</div>
			</div>
		</div>
		</div>
		<div class="card-body border  col-12 mt-2">
		<h4 class="m-b-10 mt-2 p-10">Financing Plans</h4>
		<div class="plan-body">
			<div class="row" *ngIf="plansDetail && plansDetail.length>0">
			<div
				[ngClass]="{'col-6': plansDetail && plansDetail.length > 1, 'col-12':  plansDetail && plansDetail.length == 1}"
				*ngFor="let plan of plansDetail" style="box-shadow:none">
				<div class="card border plan-box">
				<div class="card-header">
					<div class="mb-0 pl-3 pr-3 row justify-content-between">
					<div>
						<h4>{{plan.month}} months</h4>
					</div>
					<div>
						<span
						*ngIf="splitInfo.number_of_payments == plan.month && splitInfo.apf_id != 0 && splitInfo.contract_id == 0"
						class="badge bg-warning ml-auto pull-right">Plan Selected,
						Financing Details Pending</span>
						<span
						*ngIf="splitInfo.number_of_payments == plan.month && splitInfo.apf_id != 0 && splitInfo.contract_id != 0"
						class="badge bg-success ml-auto pull-right">Selected</span>
					</div>
					</div>
				</div>
				<div class="card-body">
					<div class="text-center" style="color: black;">
					<h3>{{splitInfo.display_logo}}{{plan.recurring_amount | number: '1.2-2'}}</h3>
					<h4>per month</h4>
					</div>
					<div>
					<span>Estimated Service Amount:</span>
					<span class="pull-right">{{splitInfo.display_logo}}{{plan.treatment_amount | number: '1.2-2'}}</span>
					</div>
					<div>
					<span>Down Payment:</span>
					<span
						class="pull-right">{{splitInfo.display_logo}}{{plan.downpayment?plan.downpayment:plan.downpayment_amount}}</span>
					</div>
					<div>
					<span>Estimated Financed Amount:</span>
					<span class="pull-right">{{splitInfo.display_logo}}{{(plan.treatment_amount -
						(plan.downpayment?plan.downpayment:plan.downpayment_amount)) | number: '1.2-2'}}</span>
					</div>
					<div>
					<span
						*ngIf="splitInfo.contract_type == 2 || splitInfo.contract_type == 4  || splitInfo.contract_type == 5 ">Upfront
						Payment:</span>
					<span *ngIf="splitInfo.contract_type == 1 || splitInfo.contract_type == 3">First Recurring Due
						Today:</span>
					<span class="pull-right">{{splitInfo.display_logo}}{{plan.advance_interest_amount | number:
						'1.2-2'}}</span>
					</div>
					<div>
					<span>Interest Rate:</span>
					<span class="pull-right">{{plan.interest_rate_to_show?plan.interest_rate_to_show:plan.interest_rate}}%
						<sup>{{!plan.interest_rate_to_show || (plan.interest_rate == plan.interest_rate_to_show) ?
						(plan.interest_rate>0?'C':'') : 'F'}}</sup>
					</span>
					</div>
					<div>
					<span>Transaction Fee:</span>
					<span *ngIf="GetTransactionFee(plan, plan.recurring_amount?plan.recurring_amount:plan.recurring_amount)"
						class="pull-right">{{GetTransactionFee(plan,
						plan.recurring_amount?plan.recurring_amount:plan.recurring_amount)}}
						per payment</span>
					</div>
					<div>
					<span>Enrollment Fee:</span>
					<span class="pull-right"> {{splitInfo.display_logo}}{{plan.enrollment_fee ? plan.enrollment_fee : 0}}
					</span>
					</div>
					<div>
					<span> Total Payable Amount:</span>
					<span class="pull-right">{{splitInfo.display_logo}}{{plan.amount?plan.amount:plan.remaining_amount |
						number: '1.2-2'}}</span>
					</div>
					<div>
					<span><strong>Total Payable Today:</strong></span>
					<span class="pull-right"><strong>{{splitInfo.display_logo}}{{plan.payable_amount_today | number:
						'1.2-2'}}</strong></span>
					</div>
				</div>
				</div>
			</div>
			<p class="col-sm-12 m-0">F: Flat Interest, C: Compound Interest</p>
			</div>
		</div>
		</div>
	</div>
	
	<div class="text-center" *ngIf="splitInfo && splitInfo.split_req_id && !load_notes">
		<button class="btn btn-primary rounded my-2 blink" (click)="load_notes = true">View notes</button>
	</div>
	<app-common-notes-listing [notesId]="splitInfo.contract_patient_token" [type]="'proposal'" [data]="splitInfo"
		*ngIf="splitInfo  &&  load_notes">
	</app-common-notes-listing>
</div>