<ng-container *ngIf="modalType == _customer.modalTypes.customerDetails">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content border-0">
      <form [formGroup]="customerDetails" (ngSubmit)="customerDetailsSubmit()">
        <div class="modal-header">
          <h4 class="modal-title">Update Customer Details</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="Cancel()"
          >
            &times;
          </button>
        </div>
        <div class="modal-body m-3">
          <div>
            <div class="row no-gutters" autocomplete="_away">
              <div
                class="pr-3 w-100 make_payment"
                style="max-height: calc(100vh - 170px); overflow-y: auto"
              >
                <label
                  for=""
                  class="m-0 text-bold mb-3"
                  style="color: #313a44"
                  id="personal-details"
                >
                  Personal Information
                </label>

                <div class="w-100">
                  <div
                    class="custom-form mt-3"
                    [ngClass]="
                      customerDetails.controls['customerFirstName'].touched &&
                      customerDetails.controls['customerFirstName'].invalid
                        ? 'inputContainer'
                        : ''
                    "
                  >
                    <input
                      type="text"
                      class="input_field pt-2 px-3"
                      formControlName="customerFirstName"
                      trim="true"
                      autocomplete="_away"
                    />
                    <label for="text" class="label-name">
                      <span class="content-name f-c-p-3">
                        Customer First Name
                      </span>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['customerFirstName'].touched &&
                      customerDetails.controls['customerFirstName'].invalid
                    "
                  >
                    Please enter a valid customer first name.
                  </p>
                </div>
                <div class="w-100">
                  <div
                    class="custom-form mt-3"
                    [ngClass]="[
                      customerDetails.controls['customerLastName'].touched &&
                      customerDetails.controls['customerLastName'].invalid
                        ? 'inputContainer'
                        : ''
                    ]"
                  >
                    <input
                      type="text"
                      class="input_field pt-2 px-3"
                      name="customerLastName"
                      formControlName="customerLastName"
                      trim="true"
                      autocomplete="_away"
                    />
                    <label for="text" class="label-name">
                      <span class="content-name f-c-p-3">
                        Customer Last Name
                      </span>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['customerLastName'].touched &&
                      customerDetails.controls['customerLastName'].invalid
                    "
                  >
                    Please enter a valid customer last name.
                  </p>
                </div>
                <div class="form-group row w-100 m-0 mt-3">
                  <div class="w-100">
                    <div
                      class="w-100 d-flex align-items-center b-10 m-h-56"
                      [ngClass]="[
                        customerDetails.controls['customerDob'].touched &&
                        customerDetails.controls['customerDob'].invalid
                          ? 'inputContainer'
                          : customerDetails.controls['customerDob'].disabled
                          ? 'disabled-input'
                          : ''
                      ]"
                    >
                      <div class="custom-form">
                        <input
                          readonly
                          formControlName="customerDob"
                          type="text"
                          bsDatepicker
                          class="input_field px-3 pt-3 notranslate"
                          autocomplete="_away"
                          [minDate]="minDate"
                          [maxDate]="maxDate"
                          [bsConfig]="{ containerClass: 'theme-blue' }"
                        />
                        <label for="text" class="label-name">
                          <span class="content-name f-c-p-3">
                            Customer Date of Birth
                          </span>
                        </label>
                      </div>
                    </div>
                    <p
                      class="mat-error mb-0 ml-0 pl-0"
                      *ngIf="
                        customerDetails.controls['customerDob'].dirty == true &&
                        customerDetails.controls['customerDob'].invalid
                      "
                    >
                      Please enter a valid date of birth.
                    </p>
                  </div>
                </div>
                <div class="row form-group w-100 m-0 mt-3">
                  <label class="form-label f-c-p-4 fs-14 text-bold"
                    >Gender*</label
                  >
                  <div class="radio ml-4 mr-3">
                    <label class="pointer">
                      <input
                        type="radio"
                        formControlName="gender"
                        [value]="1"
                        id="gender"
                      />
                      <label for="gender">
                        <span class="fs-14 f-c-p-1 pl-2">Male</span>
                      </label>
                    </label>
                  </div>
                  <div class="radio mr-3">
                    <label class="pointer">
                      <input
                        type="radio"
                        [value]="2"
                        id="gender1"
                        formControlName="gender"
                      />
                      <label for="gender1">
                        <span class="fs-14 f-c-p-1 pl-2">Female</span>
                      </label>
                    </label>
                  </div>
                  <div class="radio">
                    <label class="pointer">
                      <input
                        type="radio"
                        [value]="3"
                        id="gender2"
                        formControlName="gender"
                      />
                      <label for="gender2">
                        <span class="fs-14 f-c-p-1 pl-2">Others</span>
                      </label>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['gender'].invalid &&
                      customerDetails.controls['gender'].dirty == true
                    "
                  >
                    Please select a valid gender.
                  </p>
                </div>
                <div class="w-100">
                  <div
                    class="custom-form mt-3"
                    [ngClass]="[
                      customerDetails.controls['socailSecuityNumber'].touched &&
                      customerDetails.controls['socailSecuityNumber'].invalid
                        ? 'inputContainer'
                        : ''
                    ]"
                  >
                    <input
                      type="text"
                      class="input_field pt-2 px-3"
                      name="socailSecuityNumber"
                      formControlName="socailSecuityNumber"
                      autocomplete="_away"
                      maxlength="9"
                      minlength="9"
                      onkeydown="if(event.key==='.'){event.preventDefault();}"
                      (keypress)="validate_ssn()"
                      (focusout)="validate_ssn()"
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                      [readonly]="
                        customerInfo &&
                        customerInfo[0] &&
                        customerInfo[0].patient_ssn
                      "
                    />
                    <label for="text" class="label-name">
                      <span class="content-name f-c-p-3">
                        Social Security Number
                      </span>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['socailSecuityNumber'].dirty ==
                        true &&
                      customerDetails.controls['socailSecuityNumber'].invalid
                    "
                  >
                    Please enter a valid social security number.
                  </p>
                </div>
                <div class="col-12 p-0">
                  <div
                    class="mt-3 row m-0 p-0"
                    [ngClass]="[
                      (customerDetails.controls['phoneNumber'].invalid &&
                        customerDetails.controls['phoneNumber'].touched) ||
                      (customerDetails.controls['phoneNumber'].invalid &&
                        customerDetails.controls['phoneNumber'].touched)
                        ? 'inputContainer'
                        : ''
                    ]"
                  >
                    <div
                      class="col-md-2 p-0 m-0"
                      style="
                        height: 56px;
                        border: 1px solid #c3c8cd;
                        border-radius: 5px;
                      "
                    >
                      <span
                        class="pt-2 pr-1 font-weight-bold dollar_input"
                        style="position: absolute; left: 14px; bottom: 8px"
                        >{{ country_info && country_info.mobile_code }}
                      </span>
                    </div>
                    <div class="custom-form col-md-10 p-0 m-0">
                      <input
                        class="input_field pt-3"
                        autocomplete="_away"
                        [ngStyle]="{
                          'padding-left':
                            customerDetails.controls['phoneNumber'].value !== ''
                              ? '10px'
                              : '1rem'
                        }"
                        name="phoneNumber"
                        formControlName="phoneNumber"
                        [mask]="phone_mask"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3"> Phone Number </span>
                      </label>
                    </div>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['phoneNumber'].invalid &&
                      customerDetails.controls['phoneNumber'].dirty == true
                    "
                  >
                    Please enter a valid phone number
                  </p>
                </div>
                <div class="col-12 p-0">
                  <div
                    class="custom-form mt-3"
                    [ngClass]="[
                      (customerDetails.controls['emailAddress'].invalid &&
                        customerDetails.controls['emailAddress'].touched) ||
                      (customerDetails.controls['emailAddress'].invalid &&
                        customerDetails.controls['emailAddress'].touched)
                        ? 'inputContainer'
                        : ''
                    ]"
                  >
                    <input
                      autocomplete="_away"
                      class="input_field pt-2 px-3"
                      name="emailAddress"
                      formControlName="emailAddress"
                      trim="true"
                    />
                    <label for="text" class="label-name">
                      <span class="content-name f-c-p-3"> Email Address </span>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['emailAddress'].invalid &&
                      customerDetails.controls['emailAddress'].dirty == true
                    "
                  >
                    Please enter a valid email address
                  </p>
                </div>
                <div class="w-100">
                  <div
                    class="custom-form mt-3"
                    [ngClass]="[
                      customerDetails.controls['dlNumber'].touched &&
                      customerDetails.controls['dlNumber'].invalid
                        ? 'inputContainer'
                        : ''
                    ]"
                  >
                    <input
                      type="text"
                      autocomplete="_away"
                      class="input_field pt-2 px-3"
                      name="dlNumber"
                      formControlName="dlNumber"
                      trim="true"
                      maxlength="20"
                      (keypress)="omit_special_char($event)"
                      (keydown.space)="$event.preventDefault()"
                      autofocus
                    />
                    <label for="text" class="label-name">
                      <span class="content-name f-c-p-3"> DL Number </span>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['dlNumber'].dirty == true &&
                      customerDetails.controls['dlNumber'].invalid
                    "
                  >
                    Please enter a valid DL number.
                  </p>
                </div>
                <div class="w-100">
                  <div
                    class="custom-form mt-3"
                    [ngClass]="[
                      customerDetails.controls['address'].touched &&
                      customerDetails.controls['address'].invalid
                        ? 'inputContainer'
                        : ''
                    ]"
                  >
                    <input
                      autocomplete="_away"
                      type="text"
                      class="input_field pt-2 px-3"
                      name="address"
                      formControlName="address"
                      trim="true"
                    />
                    <label for="text" class="label-name">
                      <span class="content-name f-c-p-3"> Address </span>
                    </label>
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['address'].dirty == true &&
                      customerDetails.controls['address'].invalid
                    "
                  >
                    Please enter a valid address.
                  </p>
                </div>
                <div class="w-100 d-padding mt-3">
                  <!-- <zip-selector (zipSelected)="getZipselected($event)" [placeholder]=""
                    [customClass]="[customerDetails.controls.zipPostalCode.touched && customerDetails.controls.zipPostalCode.invalid ? 'inputContainer' : 'custom-form']"
                    t="2" [isRequired]="true" id="zipPostalCode" name="zipPostalCode" [model]="zipPostalCode" ngDefaultControl
                    [defaultzip]="zipCode">
                  </zip-selector> -->
                  <!-- TODO: Zip is pending -->
                  <!-- <app-google-zip
                    (setAddress)="getZipselected($event, 1)"
                    [country_code]="country_info && country_info.country_code"
                    [t]="1"
                    [customClass]="
                      customerDetails.controls['zipPostalCode'].touched &&
                      customerDetails.controls['zipPostalCode'].invalid
                        ? 'inputContainer'
                        : 'custom-form'
                    "
                    id="zipPostalCode"
                    formControlName="zipPostalCode"
                    ngDefaultControl
                    [defaultzip]="zipCode"
                    [comp_class]="'profile_zip'"
                  >
                  </app-google-zip> -->
                </div>
                <p
                  class="mat-error"
                  *ngIf="
                    customerDetails.controls &&
                    customerDetails.controls['zipPostalCode'] &&
                    customerDetails.controls['zipPostalCode'].dirty == true &&
                    (customerDetails.controls['zipPostalCode'].invalid ||
                      !(
                        customerDetails.controls['zipPostalCode'] &&
                        zipCode &&
                        zipCode.zip
                      ))
                  "
                >
                  Please enter a valid zip.
                </p>
                <div class="row no-gutters">
                  <div
                    class="col-md-6 col-12 pl-md-0 form-group mi-w-180 p-0 pr-md-3"
                  >
                    <div
                      class="custom-form mt-3"
                      [ngClass]="[
                        customerDetails.controls['city'].touched &&
                        customerDetails.controls['city'].invalid
                          ? 'inputContainer'
                          : ''
                      ]"
                    >
                      <input
                        autocomplete="_away"
                        type="text"
                        class="input_field pt-2 px-3"
                        name="city"
                        formControlName="city"
                        trim="true"
                        autocomplete="disabled"
                      />
                      <label
                        for="text"
                        [ngClass]="[
                          customerDetails.controls['city'].value
                            ? 'label-name'
                            : ''
                        ]"
                      >
                        <span class="content-name f-c-p-3"> City </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['city'].dirty == true &&
                        customerDetails.controls['city'].invalid
                      "
                    >
                      Please enter a valid city.
                    </p>
                  </div>
                  <div class="col-md-6 col-12 pr-md-0 form-group mi-w-180 p-0">
                    <div class="custom-form mt-3">
                      <input
                        type="text"
                        class="input_field pt-2 px-3 disabled_field"
                        name="state"
                        formControlName="state"
                        trim="true"
                      />
                      <label
                        for="text"
                        [ngClass]="[
                          customerDetails.controls['state'].value
                            ? 'label-name'
                            : ''
                        ]"
                      >
                        <span class="content-name f-c-p-3"> State </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <label for="" class="m-0 text-bold" style="color: #313a44">
                    Employment Information
                  </label>
                </div>
                <div class="w-100 py-3 mt-2">
                  <p class="font-weight-bold fs-14 f-c-p-4 m-0">
                    Are you Employed?
                  </p>
                  <input
                    class="form-check-input d-none"
                    formControlName="employed"
                  />
                </div>
                <div class="d-flex align-items-center w-100">
                  <div class="form-check mr-2 pl-3">
                    <label
                      class="form-check-label pointer p-2"
                      for="flexRadioDefault1"
                    >
                      <input
                        class="form-check-input"
                        name="employed"
                        type="radio"
                        value="0"
                        id="flexRadioDefault1"
                        (change)="changeFeilds(0)"
                        [checked]="
                          customerDetails.value.employed === '0' ||
                          customerDetails.value.employed === 0
                            ? true
                            : false
                        "
                      />
                      <label for="flexRadioDefault1">
                        <span class="pl-2">Yes</span>
                      </label>
                    </label>
                  </div>
                  <div class="form-check mr-2 pl-0">
                    <label
                      class="form-check-label pointer p-2"
                      for="flexRadioDefault2"
                    >
                      <input
                        class="form-check-input"
                        name="employed"
                        type="radio"
                        value="1"
                        id="flexRadioDefault2"
                        (change)="changeFeilds(1)"
                        [checked]="
                          customerDetails.value.employed === '1' ||
                          customerDetails.value.employed === 1
                            ? true
                            : false
                        "
                      />
                      <label for="flexRadioDefault2">
                        <span class="pl-2">No</span>
                      </label>
                    </label>
                  </div>
                  <div class="form-check pl-0">
                    <label
                      class="form-check-label pointer p-2"
                      for="flexRadioDefault3"
                    >
                      <input
                        class="form-check-input"
                        name="employed"
                        (change)="changeFeilds(2)"
                        [checked]="
                          customerDetails.value.employed === '2' ||
                          customerDetails.value.employed === 2
                            ? true
                            : false
                        "
                        type="radio"
                        value="2"
                        id="flexRadioDefault3"
                      />
                      <label for="flexRadioDefault3">
                        <span class="pl-2"> Self Employed </span>
                      </label>
                    </label>
                  </div>
                </div>
                <p
                  class="mat-error"
                  *ngIf="
                    customerDetails.controls['employed'].invalid &&
                    customerDetails.controls['employed'].touched
                  "
                >
                  Please select a valid employed option.
                </p>
                <ng-container
                  *ngIf="customerDetails.controls['employed'].value == '1'"
                >
                  <div class="w-100">
                    <div
                      class="wrap m-h-56 h-100 h-56 border_10 d-block mt-3"
                      [ngClass]="[
                        customerDetails.controls['payment_souce'].touched &&
                        customerDetails.controls['payment_source'].invalid
                          ? 'inputContainer_select'
                          : ''
                      ]"
                    >
                      <div
                        class="select w-100 h-100 h-56 d-flex justify-content-between align-items-center"
                      >
                        <div class="w-100 h-100">
                          <select
                            id="img_logo_emp"
                            formControlName="payment_source"
                            (change)="changeSelection()"
                            class="select-text pt-3 px-3 mt-1 input_field font-weight-bold m-h-56 h-100 w-100"
                          >
                            <option
                              *ngFor="let item of allowed_payback_methods"
                              [value]="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <span class="select-highlight"></span>
                          <!-- <span class="select-bar"></span> -->
                          <!-- <span class="select-bar-1"></span> -->
                          <span class="select-bar-1"></span>
                          <label class="select-label f-c-p-3"
                            >Please select your payment source</label
                          >
                        </div>
                      </div>
                    </div>
                    <p
                      class="mat-error w-100"
                      *ngIf="
                        customerDetails.controls['payment_source'].dirty ==
                          true &&
                        customerDetails.controls['payment_source'].invalid
                      "
                    >
                      Please Select a valid Payment Source
                    </p>
                  </div>
                  <div
                    class="w-100"
                    *ngIf="
                      customerDetails?.controls?.['payment_source'] &&
                      customerDetails?.controls?.['payment_source']?.value ===
                        'Others'
                    "
                  >
                    <div
                      class="custom-form mt-3"
                      *ngIf="
                        customerDetails.controls['payment_source_reference']
                      "
                      [ngClass]="[
                        customerDetails.controls['payment_source_reference']
                          .touched &&
                        customerDetails.controls['payment_source_reference']
                          .invalid
                          ? 'inputContainer'
                          : ''
                      ]"
                    >
                      <input
                        autocomplete="_away"
                        type="text"
                        class="input_field pt-2 px-3"
                        name="payment_source_reference"
                        formControlName="payment_source_reference"
                        trim="true"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3">
                          Payment Source Name
                        </span>
                      </label>
                    </div>
                    <!-- <p class="mat-error"
                        *ngIf="customerDetails.controls && customerDetails.controls.payment_source_reference && customerDetails.controls.payment_source_reference?.dirty==true && customerDetails.controls.payment_source_reference.invalid ">
                        Please enter a valid payback source</p> -->
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="customerDetails.controls['employed'].value == '0'"
                >
                  <div class="form-group row w-100 m-0">
                    <div
                      class="custom-form mt-3"
                      [ngClass]="[
                        customerDetails.controls['empName'].touched &&
                        customerDetails.controls['empName'].invalid
                          ? 'inputContainer'
                          : ''
                      ]"
                    >
                      <input
                        autocomplete="_away"
                        type="text"
                        class="input_field pt-2 px-3"
                        name="empName"
                        formControlName="empName"
                        trim="true"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3">
                          Employer Name
                        </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['empName'].dirty == true &&
                        customerDetails.controls['empName'].invalid
                      "
                    >
                      Please enter a valid employer name.
                    </p>
                  </div>
                  <div class="col-12 p-0">
                    <div
                      class="mt-3 row p-0 m-0"
                      [ngClass]="[
                        (customerDetails.controls['empPhoneNumber'].invalid &&
                          customerDetails.controls['empPhoneNumber'].touched) ||
                        (customerDetails.controls['empPhoneNumber'].invalid &&
                          customerDetails.controls['empPhoneNumber'].touched)
                          ? 'inputContainer'
                          : ''
                      ]"
                    >
                      <div
                        class="col-md-2 p-0 m-0"
                        style="
                          height: 56px;
                          border: 1px solid #c3c8cd;
                          border-radius: 5px;
                        "
                      >
                        <span
                          class="pt-2 pr-1 font-weight-bold dollar_input"
                          style="position: absolute; left: 14px; bottom: 8px"
                          >{{ country_info && country_info.mobile_code }}
                        </span>
                      </div>

                      <div class="custom-form col-md-10 p-0 m-0">
                        <input
                          autocomplete="_away"
                          class="input_field pt-3"
                          [ngStyle]="{
                            'padding-left':
                              customerDetails.controls['empPhoneNumber']
                                .value !== ''
                                ? '10px'
                                : '1rem'
                          }"
                          name="empPhoneNumber"
                          formControlName="empPhoneNumber"
                          [mask]="phone_mask"
                        />
                        <label for="text" class="label-name">
                          <span class="content-name f-c-p-3">
                            Employer Phone Number
                          </span>
                        </label>
                      </div>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['empPhoneNumber'].invalid &&
                        customerDetails.controls['empPhoneNumber'].dirty == true
                      "
                    >
                      Please enter a valid employer phone number
                    </p>
                  </div>
                  <div class="w-100">
                    <div
                      class="custom-form mt-3"
                      [ngClass]="[
                        customerDetails.controls['empAddress'].touched &&
                        customerDetails.controls['empAddress'].invalid
                          ? 'inputContainer'
                          : ''
                      ]"
                    >
                      <input
                        autocomplete="_away"
                        type="text"
                        class="input_field pt-2 px-3"
                        name="empAddress"
                        formControlName="empAddress"
                        trim="true"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3">
                          Employer Address
                        </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['empAddress'].touched &&
                        customerDetails.controls['empAddress'].invalid
                      "
                    >
                      Please enter a valid employer address.
                    </p>
                  </div>
                  <div class="w-100 d-padding mt-2">
                    <!-- <zip-selector (zipSelected)="getEmpZipselected($event)" [placeholder]=""
                      [customClass]="[customerDetails.controls.empZipCode && customerDetails.controls.empZipCode.touched && customerDetails.controls.empZipCode.invalid ? 'inputContainer' : 'custom-form']"
                      t="2" [isRequired]="true" id="empZipCode" name="empZipCode" [model]="empZipCode" ngDefaultControl
                      [defaultzip]="empZipCodeData">
                    </zip-selector> -->
                    <!-- TODO: Pending zip -->
                    <!-- <app-google-zip
                      (setAddress)="getEmpZipselected($event, 1)"
                      [country_code]="country_info && country_info.country_code"
                      [comp_class]="'profile_zip'"
                      [customClass]="(customerDetails.controls['empZipCode'].dirty == true &&
                        customerDetails.controls['empZipCode'].invalid)
                          ? 'inputContainer'
                          : 'custom-form'"
                      id="empZipCode"
                      name="empZipCode"
                      [model]="empZipCode"
                      ngDefaultControl
                      [defaultzip]="empZipCodeData"
                      [t]="1"
                    >
                    </app-google-zip> -->
                  </div>
                  <p
                    class="mat-error"
                    *ngIf="
                      customerDetails.controls['empZipCode'].dirty == true &&
                      (customerDetails.controls['empZipCode'].invalid ||
                        !(
                          customerDetails.controls['empZipCode'] &&
                          empZipCodeData &&
                          empZipCodeData.zip
                        ))
                    "
                  >
                    Please enter a valid zip.
                  </p>
                  <div class="row no-gutters">
                    <div
                      class="col-md-6 col-12 pl-md-0 form-group mi-w-180 p-0 pr-md-3"
                    >
                      <div
                        class="custom-form mt-3"
                        [ngClass]="[
                          customerDetails.controls['empCity'].touched &&
                          customerDetails.controls['empCity'].invalid
                            ? 'inputContainer'
                            : ''
                        ]"
                      >
                        <input
                          autocomplete="_away"
                          class="input_field pt-2 px-3"
                          type="text"
                          name="empCity"
                          trim="true"
                          formControlName="empCity"
                          autocomplete="disabled"
                        />
                        <label for="text" class="label-name">
                          <span class="content-name f-c-p-3"> City </span>
                        </label>
                      </div>
                      <p
                        class="mat-error"
                        *ngIf="
                          customerDetails.controls['empCity'].dirty == true &&
                          customerDetails.controls['empCity'].invalid
                        "
                      >
                        Please enter a valid city.
                      </p>
                    </div>
                    <div
                      class="col-md-6 col-12 pr-md-0 form-group mi-w-180 p-0"
                    >
                      <div
                        class="custom-form mt-3"
                        [ngClass]="[
                          customerDetails.controls['empState'].touched &&
                          customerDetails.controls['empState'].invalid
                            ? 'inputContainer'
                            : ''
                        ]"
                      >
                        <input
                          class="input_field pt-2 px-3 disabled_field"
                          type="text"
                          name="empState"
                          formControlName="empState"
                        />
                        <label
                          for="text"
                          [ngClass]="[
                            customerDetails.controls['empState'].value
                              ? 'label-name'
                              : ''
                          ]"
                        >
                          <span class="content-name f-c-p-3"> State </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="w-100">
                    <div
                      class="custom-form mt-3"
                      [ngClass]="[
                        (customerDetails.controls['empAnnualIncome'] &&
                          customerDetails.controls['empAnnualIncome'].touched &&
                          customerDetails.controls['empAnnualIncome']
                            .invalid) ||
                        (customerDetails.controls['empAnnualIncome'].errors &&
                          customerDetails.controls['empAnnualIncome'].errors)
                          ? 'inputContainer'
                          : ''
                      ]"
                    >
                      <span
                        *ngIf="
                          customerDetails.controls['empAnnualIncome'] &&
                          customerDetails.controls['empAnnualIncome'].value !==
                            null &&
                          customerDetails.controls['empAnnualIncome'].value !==
                            '' &&
                          customerDetails.controls['empAnnualIncome'].value !==
                            0
                        "
                        class="pt-2 pr-1 font-weight-bold dollar_input notranslate"
                        style="position: absolute; left: 14px; bottom: 8px"
                        >{{ currency_info?.display_logo }}</span
                      >
                      <input
                        autocomplete="_away"
                        class="input_field pt-2 px-3 pl-4 pt-3"
                        type="text"
                        name="empAnnualIncome"
                        formControlName="empAnnualIncome"
                        (keypress)="enterNumberOnly($event)"
                        onKeyPress="if(this.value.length==7) return false;"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3">
                          Annual Income
                        </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        (customerDetails.controls['empAnnualIncome'].dirty ==
                          true &&
                          customerDetails.controls['empAnnualIncome']
                            .invalid) ||
                        (customerDetails.controls['empAnnualIncome'].errors &&
                          customerDetails.controls['empAnnualIncome'].errors)
                      "
                    >
                      Please enter a valid annual income.
                    </p>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="
                    customerDetails.value.employed == 2 ||
                    customerDetails.value.employed == '2'
                  "
                >
                  <div
                    class="w-100 mi-w-180 my-3"
                    *ngIf="
                      (customerDetails.value.employed == 2 ||
                        customerDetails.value.employed == '2') &&
                      customerDetails.controls['profession']
                    "
                  >
                    <div
                      [ngClass]="[
                        customerDetails.controls['profession'].touched &&
                        customerDetails.controls['profession'].invalid
                          ? 'inputContainer custom-form'
                          : 'custom-form'
                      ]"
                    >
                      <input
                        type="text"
                        class="input_field px-3 pt-3"
                        name="profession"
                        formControlName="profession"
                        trim="true"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3"> Profession </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['profession'].dirty == true &&
                        customerDetails.controls['profession'].invalid
                      "
                    >
                      Please enter a your profession.
                    </p>
                  </div>
                  <!-- office address -->
                  <div
                    class="w-100 mi-w-180 mb-3"
                    *ngIf="
                      (customerDetails.value.employed == 2 ||
                        customerDetails.value.employed == '2') &&
                      customerDetails.controls['office_address']
                    "
                  >
                    <div
                      [ngClass]="[
                        customerDetails.controls['office_address'].touched &&
                        customerDetails.controls['office_address'].invalid
                          ? 'inputContainer custom-form'
                          : 'custom-form'
                      ]"
                    >
                      <input
                        class="input_field px-3 pt-3"
                        type="text"
                        name="office_address"
                        formControlName="office_address"
                        trim="true"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3">
                          Office Address
                        </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['office_address'].dirty ==
                          true &&
                        customerDetails.controls['office_address'].invalid
                      "
                    >
                      Please enter a your office address.
                    </p>
                  </div>
                  <!-- rest changes -->
                  <div
                    class="col-sm-12 px-0 pb-2"
                    *ngIf="check_home_add_exist()"
                  >
                    <input
                      type="checkbox"
                      class="pointer"
                      name="same_office_address_value"
                      formControlName="check_office"
                      id="same_office_address_value"
                      style="margin-right: 8px"
                      (change)="FillOfficeAddress($event)"
                      [checked]="SameAddress"
                    />
                    <label for="same_office_address_value">
                      <span> Office Address Same as Home Address. </span>
                    </label>
                  </div>

                  <div class="w-100 d-padding">
                    <!-- TODO: Zip pending -->
                    <!-- <app-google-zip
                      (setAddress)="getOfficeZipselected($event, 1)"
                      [country_code]="country_info && country_info.country_code"
                      [t]="1"
                      [customClass]="[
                        customerDetails.controls.office_zip.touched &&
                        customerDetails.controls.office_zip.invalid
                          ? 'inputContainer'
                          : 'custom-form'
                      ]"
                      label="Office Zip"
                      id="office_zip"
                      name="office_zip"
                      [defaultzip]="office_zip"
                      [model]="office_zip"
                      ngDefaultControl
                      [comp_class]="'profile_zip'"
                    >
                    </app-google-zip> -->

                    <!-- <app-google-zip [comp_class]="'profile_zip'" (setAddress)="getZipselected($event)" [country_code]="partner['country_code']"
                      [model]="selectedZipCode" id="selectedZipCode" name="selectedZipCode" ngDefaultControl> </app-google-zip> -->
                    <p
                      class="mat-error"
                      *ngIf="
                        customerDetails.controls['office_zip'].dirty == true &&
                        customerDetails.controls['office_zip'].invalid
                      "
                    >
                      Please enter a valid Office zip.
                    </p>
                  </div>

                  <div class="row no-gutters">
                    <div
                      class="col-md-6 col-12 pl-md-0 form-group mi-w-180 p-0 pr-md-3"
                      *ngIf="customerDetails.controls['office_city']"
                    >
                      <div
                        [ngClass]="[
                          customerDetails.controls['office_city'].touched &&
                          customerDetails.controls['office_city'].invalid
                            ? 'inputContainer custom-form mt-3'
                            : 'custom-form mt-3'
                        ]"
                      >
                        <input
                          class="input_field px-3 pt-3"
                          type="text"
                          name="office_city"
                          formControlName="office_city"
                          trim="true"
                          autocomplete="disabled"
                        />
                        <label for="text" class="label-name">
                          <span class="content-name f-c-p-3"> City </span>
                        </label>
                      </div>
                      <p
                        class="mat-error"
                        *ngIf="
                          customerDetails.controls['office_city'].dirty ==
                            true &&
                          customerDetails.controls['office_city'].invalid
                        "
                      >
                        Please enter a valid Office city.
                      </p>
                    </div>

                    <div
                      class="col-md-6 col-12 pr-md-0 form-group mi-w-180 p-0"
                      *ngIf="customerDetails.controls['office_state']"
                    >
                      <div
                        [ngClass]="[
                          (customerDetails.controls['.office_state'].invalid &&
                            customerDetails.controls['.office_state']
                              .touched) ||
                          (customerDetails.controls['.office_state'].invalid &&
                            customerDetails.controls['.office_state'].dirty)
                            ? 'custom-form   inputContainer mt-3'
                            : 'custom-form mt-3'
                        ]"
                      >
                        <input
                          class="input_field px-3 pt-3"
                          type="text"
                          name="office_state"
                          formControlName="office_state"
                          trim="true"
                        />
                        <label for="text" class="label-name">
                          <span class="content-name f-c-p-3"> State </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- rest changes -->
                  <div class="w-100 mi-w-180 mb-3">
                    <div
                      [ngClass]="[
                        customerDetails.controls['.annual_income'].touched &&
                        customerDetails.controls['.annual_income'].invalid
                          ? 'inputContainer custom-form'
                          : 'custom-form'
                      ]"
                    >
                      <span
                        *ngIf="
                          customerDetails.controls['.annual_income'].value !==
                            null &&
                          customerDetails.controls['.annual_income'].value !==
                            '' &&
                          customerDetails.controls['.annual_income'].value !== 0
                        "
                        class="pt-2 pr-1 font-weight-bold dollar_input notranslate"
                        style="position: absolute; left: 14px; bottom: 8px"
                        >{{ currency_info && currency_info.display_logo }}</span
                      >
                      <input
                        class="input_field px-3 pt-3"
                        type="text"
                        name="annual_income"
                        maxlength="7"
                        formControlName="annual_income"
                        trim="true"
                        (keypress)="enterNumberOnly($event)"
                        onKeyPress="if(this.value.length==7) return false;"
                        appDecimaNumber
                        [ngClass]="[
                          customerDetails.value.annual_income == ''
                            ? 'px-3'
                            : 'px-4'
                        ]"
                      />
                      <label for="text" class="label-name">
                        <span class="content-name f-c-p-3">
                          Annual Income
                        </span>
                      </label>
                    </div>
                    <p
                      class="mat-error"
                      *ngIf="
                        ((customerDetails.controls['.annual_income'].dirty ==
                          true &&
                          customerDetails.controls['.annual_income'].invalid) ||
                          (customerDetails.controls['.annual_income'].errors &&
                            customerDetails.controls['.annual_income'].errors?.['err']))
                      "
                    >
                      Please enter a valid annual income.
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="text-center d-flex align-items-center"
            style="height: 100vh"
          >
            <div class="m-auto text-center">
              <img src="assets/img/png/check_signup.png" width="90px" alt="" />
              <h3 class="text-bold my-4">Details updated successfully!</h3>
              <div class="form-group">
                <button button-primary-auth (click)="Cancel()">Done</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col">
              <button class="btn btn-default" (click)="Cancel()">Cancel</button>
            </div>
            <div class="col">
              <button class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
