<div class="login-wrapper">
    <div class="login-wrapper-inner vh-100">
        <div class="login-header px-3 py-2">
            <img src="assets\images\icons\FM-logo.svg" alt="">
        </div>
        <div class="login-body d-flex align-items-center justify-content-center">
            <div class="login-form w-100">
                <ng-container>
                    <form [formGroup]="loginForm" class="col-12">
                        <ng-container *ngIf="loginStep == step.EMAIL">
                            <div class="mb-3">
                                <div [ngClass]="[ (loginForm.controls['email'].touched && loginForm.controls['email'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
                                    class="">
                                    <label for="text" class="label-name fs-14 w-100">
                                        Email
                                    </label>
                                    <input type="text" class="input_field w-100" name="email" required
                                        formControlName="email" placeholder="Enter your Email" />
                                </div>
                                <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                                    *ngIf="(loginForm.controls['email'].touched && loginForm.controls['email'].invalid)">
                                    {{loginForm.controls['email'].errors ? 'Please enter a valid Email' : ''}}
                                </p>
                            </div>
                            <div class="w-100 d-flex align-items-center justify-content-center">
                                <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600 me-3"
                                    (click)="changeloginStep(step.PASSWORD)">Enter
                                    Password</button>
                                <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600"
                                    (click)="changeloginStep(step.VERIFY_OTP)">Generate Otp</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="loginStep == step.PASSWORD">
                            <div class="mb-3">
                                <div [ngClass]="[ (loginForm.controls['password'].touched && loginForm.controls['password'].invalid)? 'custom-form  inputContainer' : 'custom-form']"
                                    class=" position-relative">
                                    <label for="text" class="label-name fs-14 w-100">
                                        Password
                                    </label>
                                   <div class="position-relative">
                                    <input [type]="!hidePwd ? 'password':'text'" class="input_field w-100"
                                    name="password" autocomplete="off" required formControlName="password"
                                    placeholder="Enter your Password" />
                                <img [src]="!hidePwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                                    alt="" class="position-absolute eye-icon cursor" (click)="hidePwd = !hidePwd">
                                   </div>
                                </div>

                                <p class="mat-error text-danger w-100 d-block fs-14"
                                    *ngIf="((loginForm.controls['password'].invalid && loginForm.controls['password'].touched))">
                                    {{( (loginForm.controls['password'].errors) ||
                                    (loginForm.controls['password'].invalid
                                    &&
                                    loginForm.controls['password'].touched)) ? 'Please enter a valid Password' : ''}}
                                </p>

                            </div>
                            <div class="w-100 d-flex align-items-center justify-content-center">
                                <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600 me-3" (click)="login()">Sign
                                    In</button>
                                <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600"
                                    (click)="changeloginStep(step.EMAIL)">Back</button>
                            </div>
                            <div class=" w-100 text-center">
                                <p>
                                    <a (click)="loginStep = step.FORGOT_PASSWORD"
                                        class="fw-600 cursor d-block d-blue fs-14 decoration-none">Forgot
                                        Password</a>
                                </p>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="loginStep == step.VERIFY_OTP">
                            <div class="mb-3">
                                <h4 class="fs-40 fw-600">
                                    Enter the 6-digit OTP
                                </h4>
                                <div [ngClass]="[ (loginForm.controls['otp'].touched && loginForm.controls['otp'].invalid)? 'custom-form  inputContainer' : 'custom-form']"
                                    class=" position-relative mt-5">
                                    <label for="text" class="label-name fs-14 w-100">
                                        OTP has been sent to {{loginForm.controls['email'].value}}
                                    </label>
                                    <div class="position-relative">
                                        <input type="text" class="input_field w-100" name="otp" autocomplete="off"
                                            required formControlName="otp" placeholder="Enter OTP" />
                                        <img [src]="!hidePwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                                            alt="" class="position-absolute eye-icon cursor "
                                            (click)="hidePwd = !hidePwd">
                                    </div>
                                </div>
                                <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                                    *ngIf="(loginForm.controls['otp'].touched && loginForm.controls['otp'].invalid)">
                                    {{loginForm.controls['otp'].errors ? 'Please enter OTP' : ''}}
                                </p>
                                <div class="d-flex justify-content-between py-2">
                                    <div>
                                        <span class="fs-14 fw-400 me-2">Request for new OTP after</span>
                                        <span class="fs-14 fw-500">{{refreshTimer}}</span>
                                    </div>
                                    <p class="fs-14 text-danger"> Resend </p>
                                </div>

                            </div>
                            <div class="w-100 d-flex align-items-center justify-content-center">
                                <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600 me-3" type="button" (click)="login()">Sign
                                    In</button>
                                <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600"
                                    (click)="changeloginStep(step.EMAIL)">Back</button>
                            </div>
                        </ng-container>


                    </form>
                </ng-container>
                <ng-container *ngIf="loginStep == step.FORGOT_PASSWORD">
                    <form (submit)="forgotPassword()" [formGroup]="forgotPasswordForm" class="col-12">

                        <div class="mb-3">
                            <div [ngClass]="[ (forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
                                class="">
                                <label for="text" class="label-name fs-14 w-100">
                                    Email
                                </label>
                                <input type="text" class="input_field w-100" name="email" required
                                    formControlName="email" placeholder="Enter your Email" />
                            </div>
                            <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                                *ngIf="(forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].invalid)">
                                {{forgotPasswordForm.controls['email'].errors ? 'Please enter a valid Email' : ''}} </p>
                        </div>
                        <button type="submit()" class="w-100 border-0 mb-3 btn btn-primary fs-16 fw-600">
                            Submit
                        </button>
                        <div class=" w-100 text-center">
                            <p>
                                <a (click)="loginStep = step.EMAIL"
                                    class="fw-600 cursor d-block d-blue fs-14 decoration-none">Back to Login</a>
                            </p>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</div>