import { Injectable } from '@angular/core';
import { MainService } from '../main.service';
import { ActivatedRoute } from '@angular/router';
import { localStorageService } from '../localstorage.service';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  constructor(
    private mainService: MainService,
    public route: ActivatedRoute,
    private _local: localStorageService,
    public _shared: SharedService
  ) {
  }

  /**------------------- Business Filters ---------------- **/
  getBusinessFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: `Sort By`,
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.getBusinessSortBy()[0].value,
        default: this.getBusinessSortBy()[0].value,
        options: this.getBusinessSortBy(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getBusinessStatuses()[0].value,
        default: this.getBusinessStatuses()[0].value,
        options: this.getBusinessStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Type',
        control: 'type',
        value: params['type'] ? params['type'] : this.getBusinessTypes()[0].value,
        default: this.getBusinessTypes()[0].value,
        options: this.getBusinessTypes(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Subscription',
        control: 'subscription',
        value: params['subscription'] ? params['subscription'] : this.getSubscriptionPlanType()[0].value,
        default: this.getSubscriptionPlanType()[0].value,
        options: this.getSubscriptionPlanType(),
        hide: false
      },
      {
        type: 'country',
        label: 'Country',
        control: 'country',
        value: params['country_id'] ? params['country_id'] : this.getCountry()[0].country_id,
        default: this.getCountry()[0].country_id,
        options: this.getCountry(),
        hide: false
      }
    ];
  }
  /**  Get Sort By  */
  getBusinessSortBy(): any {
    const status = [
      {
        value: '1',
        label: 'Registration Date'
      },
      {
        value: '2',
        label: 'Total Contracts'
      },
      {
        value: '3',
        label: 'Active Contracts'
      },
      {
        value: '4',
        label: 'Finished Contracts'
      },
      {
        value: '5',
        label: 'Canceled Contracts'
      },
      {
        value: '6',
        label: 'Overdue Contracts'
      },
      {
        value: '7',
        label: 'First Name A-Z'
      },
      {
        value: '8',
        label: 'First Name Z-A'
      },
      {
        value: '9',
        label: 'Last Name A-Z'
      },
      {
        value: '10',
        label: 'Last Name Z-A'
      },
      {
        value: '11',
        label: 'Partner Name A-Z'
      },
      {
        value: '12',
        label: 'Partner Name Z-A'
      },
      {
        value: '13',
        label: 'Last Action(ASC)'
      },
      {
        value: '14',
        label: 'Last Action(DESC)'
      }
    ];
    return status;
  }
  /**  Get Statues  */
  getBusinessStatuses(): any {
    const status = [
      {
        value: '0',
        label: 'All Businesses'
      },
      {
        value: '1',
        label: 'Active Businesses'
      },
      {
        value: '2',
        label: 'Blocked Business'
      }
    ];
    return status;
  }
  /**  Get Types  */
  getBusinessTypes(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Protected Businesses'
      },
      {
        value: '2',
        label: 'Non-Protected Businesses'
      },
      {
        value: '3',
        label: 'Bank added'
      },
      {
        value: '4',
        label: 'Bank not added'
      },
      {
        value: '5',
        label: 'Transfers disabled/Stripe not verified'
      },
      {
        value: '21',
        label: 'Transfers disabled'
      },
      // {
      //   value: '6',
      //   label: 'Deleted/Blocked Businesses'
      // },
      {
        value: '7',
        label: 'Active Contracts > 0'
      },
      {
        value: '8',
        label: 'Businesses with 0 Active Contracts'
      },
      {
        value: '9',
        label: 'Businesses with 2-5 Active Contracts'
      },
      {
        value: '10',
        label: 'Businesses with more than 5 Active Contracts'
      },
      {
        value: '11',
        label: 'Overdue Contracts > 0'
      },
      {
        value: '22',
        label: 'Pending Businesses'
      },
      {
        value: '12',
        label: 'Approved Businesses'
      },
      {
        value: '13',
        label: 'Disapproved Businesses'
      },
      {
        value: '14',
        label: 'Active Businesses In Last 30 Days'
      },
      {
        value: '15',
        label: 'Active Businesses This Month'
      },
      {
        value: '16',
        label: 'Registered Today'
      },
      {
        value: '17',
        label: 'VIP Businesses'
      },
      {
        value: '18',
        label: 'Inactive from 30 days'
      },
      {
        value: '19',
        label: 'Inactive from 60 days'
      },
      {
        value: '20',
        label: 'Businesses with 50+ contracts'
      },
      {
        value: '23',
        label: 'Businesses With No Training'
      }
    ];
    return status;
  }


  /**------------------- Customer Filters ---------------- **/
  getCustomerFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: `Sort By`,
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.getCustomerSortBy()[0].value,
        default: this.getCustomerSortBy()[0].value,
        options: this.getCustomerSortBy(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getCustomerStatuses()[0].value,
        default: this.getCustomerStatuses()[0].value,
        options: this.getCustomerStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Type',
        control: 'type',
        value: params['type'] ? params['type'] : this.getCustomerTypes()[0].value,
        default: this.getCustomerTypes()[0].value,
        options: this.getCustomerTypes(),
        hide: false
      }
    ];
  }
  /**  Get Sort By  */
  getCustomerSortBy(): any {
    const status = [
      {
        value: '1',
        label: 'Registration Date'
      },
      {
        value: '2',
        label: 'First Name'
      },
      {
        value: '3',
        label: 'Last Name'
      },
      {
        value: '4',
        label: 'Patient Code'
      },
      {
        value: '5',
        label: 'Date of Birth'
      },
      {
        value: '6',
        label: 'Email'
      }
    ];
    return status;
  }
  /**  Get Statues  */
  getCustomerStatuses(): any {
    const status = [
      {
        value: '0',
        label: 'All Customers'
      },
      {
        value: '1',
        label: 'Blocked Customers'
      },
      {
        value: '2',
        label: 'Unblocked Customers'
      }
    ];
    return status;
  }
  /**  Get Types  */
  getCustomerTypes(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Customers with Contracts'
      },
      {
        value: '2',
        label: 'Customers without Contracts'
      },
      {
        value: '3',
        label: 'Verified Emails'
      },
      {
        value: '4',
        label: 'Unverified Emails'
      },
      {
        value: '5',
        label: 'Approved Customers'
      },
      {
        value: '6',
        label: 'Diapproved Customers'
      }
    ];
    return status;
  }

  /**------------------- Disputed Contracts Filters ---------------- **/
  getDisputeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Choose Date',
        control: 'date',
        value: params['date'] ? new Date(params['date']) : null,
        default: null,
        placeholder: 'Choose Date',
        hide: false,
      },
      {
        type: 'dropdown',
        label: ' Dispute Status',
        control: 'dispute_status',
        value: params['dF'] ? params['dF'] : this.getDisputeStatuses()[0].value,
        default: this.getDisputeStatuses()[0].value,
        options: this.getDisputeStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: ' Charge Status',
        control: 'charge_status',
        value: params['cF'] ? params['cF'] : this.getChargeStatuses()[0].value,
        default: this.getChargeStatuses()[0].value,
        options: this.getChargeStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Financing Type',
        control: 'type',
        value: params['cT'] ? params['cT'] : this.getDisputeTypes()[0].value,
        default: this.getDisputeTypes()[0].value,
        options: this.getDisputeTypes(),
        hide: false
      }
    ];
  }
  /**  Get Statues  */
  getDisputeStatuses(): any {
    const status = [
      {
        value: 'All',
        label: 'All'
      },
      {
        value: 'Warning Needs Response',
        label: 'Warning Needs Response'
      },
      {
        value: 'Warning Under Review',
        label: 'Warning Under Review'
      },
      {
        value: 'Warning Closed',
        label: 'Warning Closed'
      },
      {
        value: 'Needs Response',
        label: 'Needs Response'
      },
      {
        value: 'Under Review',
        label: 'Under Review'
      },
      {
        value: 'Won',
        label: 'Won'
      },
      {
        value: 'Lost',
        label: 'Lost'
      }
    ];
    return status;
  }
  /**  Get Statues  */
  getChargeStatuses(): any {
    const status = [
      {
        value: 'All',
        label: 'All'
      },
      {
        value: 'Charge Refunded',
        label: 'Charge Refunded'
      },
      {
        value: 'First Recurring Payment',
        label: 'First Recurring Payment'
      },
      {
        value: 'Uncharged Businesses',
        label: 'Uncharged Businesses'
      }
    ];
    return status;
  }

  /**  Get Types  */
  getDisputeTypes(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      // {
      //   value: '1',
      //   label: '10% Platform Fee'
      // },
      {
        value: '2',
        label: 'CredeeFi Financing'
      },
      // {
      //   value: '3',
      //   label: 'Payment Processing'
      // },
      {
        value: '4',
        label: 'Credee Bill'
      },
      {
        value: '5',
        label: 'KeepeeFi Financing'
      },
      {
        value: '6',
        label: 'SimpleeFi'
      },
      {
        value: '7',
        label: 'Credee Bill Financing'
      },
      {
        value: '8',
        label: 'ZeroFi Payment Plan'
      }
    ];
    return status;
  }

  /**  Get Contract Statues  */
  getContractStatuses(): any {
    const status = [
      { value: "1", label: "Active" },
      { value: "2", label: "Overdue" },
      { value: "3", label: "Canceled" },
      { value: "4", label: "Completed" },
      { value: "5", label: "Legal" },
      { value: "6", label: "Disputed Treatment" },
      { value: "7", label: "Bankruptcy" }
    ];
    return status;
  }

  getContractStatusesWithOutLegalBankruptcy(): any {
    const status = [
      { value: "1", label: "Active" },
      { value: "2", label: "Overdue" },
      { value: "3", label: "Canceled" },
      { value: "4", label: "Completed" },
      { value: "6", label: "Disputed Treatment" }
    ];
    return status;
  }

  /**  Get Sort  */
  getAllContractSort(): any {
    const status = [
      { value: "1", label: "Registration Date" },
      { value: "2", label: "Customer Name A-Z" },
      { value: "3", label: "Customer Name Z-A" },
      { value: "4", label: "Business Owner Name A-Z" },
      { value: "5", label: "Business Owner Name Z-A" },
      { value: "6", label: "Cancelation Date (DESC)" },
      { value: "7", label: "Completion Date (DESC)" }
    ];
    return status;
  }

  /**  Get Contract Filters  */
  getAllContractFilterBy(): any {
    const status = [
      { value: "1", label: "Customers Above 70 Years" },
      { value: "2", label: "Finanaced Amount upto $3000 for more than 24 months" },
      { value: "3", label: "Enrollment Fees not deducted" },
      { value: "4", label: "Protected Payments" },
      { value: "5", label: "Non-Protected Payments" },
      { value: "6", label: "Do not disturb" },
      // { value: "8", label: "10% Platform Fee" },
      { value: "9", label: "CredeeFi Financing" },
      { value: "11", label: "Verification Pending" },
      { value: "12", label: "Verification Completed" },
      { value: "13", label: "Customer without personal Info" },
      { value: "14", label: "Contracts with Bank" },
      { value: "15", label: "Contracts without Bank" },
      { value: "16", label: "Contracts with bank as default" },
      { value: "17", label: "Contracts without bank as default" },
      { value: "18", label: "Contracts with Backup Method" },
      { value: "19", label: "Contracts without Backup Method" },
      { value: "20", label: "Contract creation pending" },
      { value: "21", label: "Contract creation failed" },
      { value: "22", label: "Having single unique payment method" },
      { value: "23", label: "Having two unique payment method" },
      { value: "24", label: "Having three unique payment method" },
      { value: "25", label: "Having multiple unique payment method" },
      { value: "26", label: "KeepeeFi Financing" },
      { value: "27", label: "SimpleeFi Financing" },
      { value: "31", label: "Verified" },
      { value: "32", label: "Non-verified" },
      { value: "33", label: "SimpleeFi Financing (With Deferred Interest)" },
      { value: "29", label: "Credee Bill Payment Plan" },
      { value: "35", label: "ZeroFi Financing" }
      // { value: "29", label: "Contracts with apple pay payments" },
      // { value: "30", label: "Contracts with google pay payments" }
    ];
    return status;
  }

  /**  Get claim request filters  */
  getClaimRequestFilterBy(): any {
    const filter = [
      { value: "0", label: "All" },
      { value: "6", label: "Submitted" },
      { value: "1", label: "Conditionally Approved" },
      { value: "4", label: "Denied" },
      { value: "2", label: "Paid" },
      { value: "3", label: "Expired" },
      { value: "5", label: "Documention Needed" },
      { value: "7", label: "Documention Uploaded" },
      { value: "13", label: "Unpaid Pending" },
      { value: "8", label: "Unpaid" },
      { value: "9", label: "Exceptions" },
      { value: "10", label: "Remove From Unpaid" },
      { value: "11", label: "Contract Edited-Yes" },
      { value: "12", label: "Contract Edited-No" }
    ];
    return filter;
  }

  claimBooleanFilter(): any {
    const filter = [
      { value: "-1", label: "All" },
      { value: "0", label: "No" },
      { value: "1", label: "Yes" }
    ];
    return filter;
  }

  /**  Get Contract Statues  */
  getContractChangeStatuses(): any {
    const status = [
      { value: "0", label: "All" },
      { value: "1", label: "Active" },
      { value: "3", label: "Overdue" },
      { value: "4", label: "Completed" },
      { value: "5", label: "Canceled" }
    ];
    return status;
  }

  /**  Get Contract Type  */
  getContractType(): any {
    const status = [
      { value: "0", label: "All" },
      // { value: "1", label: "Contracts with 10% Platform Fee" },
      { value: "2", label: "Contracts with CredeeFi" },
      { value: "3", label: "Contracts with KeepeeFi" },
      { value: "4", label: "Contracts with SimpleeFi" },
      { value: "5", label: "Contracts with Credee Bill Financing" },
      { value: "7", label: "Contracts with ZeroFi Payment Plan" },
      { value: "6", label: "Contracts with EZ Payment Plan (With Deferred Interest)" },
    ];
    return status;
  }

  /**  Get Contract Change Action By  */
  actionByFilter(): any {
    const status = [
      { value: "0", label: "All" },
      { value: "1", label: "Admin" },
      { value: "2", label: "Business" },
      { value: "3", label: "Customer" },
      { value: "4", label: "Enterprise" }
    ];
    return status;
  }

  /**  Get Edited Contract Change Action By  */
  editedActionByFilter(): any {
    const status = [
      { value: "0", label: "All" },
      { value: "1", label: "Admin" },
      { value: "2", label: "Business" },
      { value: "3", label: "Customer" }
    ];
    return status;
  }

  /**  Get Edited Contract Change Action By  */
  payoffActionByFilter(): any {
    const status = [
      { value: "0", label: "All" },
      { value: "1", label: "Admin" },
      { value: "3", label: "Customer" }
    ];
    return status;
  }

  /**  Get Edited Contract Change Action By  */
  partialPaymentActionByFilter(): any {
    const status = [
      { value: "0", label: "All" },
      { value: "1", label: "Admin" },
      { value: "3", label: "Customer" },
      { value: "5", label: "Arrangement Cron" }
    ];
    return status;
  }

  /**------------------- Proposal Request Filters ---------------- **/
  getProposalFiltersArray(ar_filter_check: number): any {
    let params = this.route.snapshot.queryParams
    let filter_object = [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'dropdown',
        label: 'Filter By',
        control: 'last_resent',
        value: params['last_resent'] ? params['last_resent'] : this.lastResentValues()[0].value,
        default: this.lastResentValues()[0].value,
        options: this.lastResentValues(),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? this._shared.getExactDate(new Date(params['endDate'])) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: `Sort By`,
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.getProposalSortBy()[0].value,
        default: this.getProposalSortBy()[0].value,
        options: this.getProposalSortBy(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Filter',
        control: 'status',
        value: params['status'] ? params['status'] : this.getProposalFilters(ar_filter_check)[0].value,
        default: this.getProposalFilters(ar_filter_check)[0].value,
        options: this.getProposalFilters(ar_filter_check),
        hide: false,
        for: "proposal_request"
      },
      {
        type: 'dropdown',
        label: 'Proposal Type',
        control: 'ProposalType',
        value: params['ProposalType'] ? params['ProposalType'] : '0',
        default: '0',
        options: [{ value: '0', label: 'All' }, { value: '1', label: 'Credee Bill Proposal' }, { value: '2', label: 'Debt Proposal' }],
        hide: window.location.href.includes('/accounts-receivable/') ? false : true,
        for: "proposal_type"
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'activeStatus',
        value: params['activeStatus'] ? params['activeStatus'] : this.getProposalStatuses()[0].value,
        default: this.getProposalStatuses()[0].value,
        options: this.getProposalStatuses(),
        hide: false,
        can_disable: true,
        disabled_condition: [
          {
            control: "status", value: ["4", "10", "11"]  /* This filter is disabled when value of above filter having control name "status" is 0, 4, 5 */
          }
        ]
      },
    ];
    return filter_object;
  }
  /**  Get Sort By  */
  getProposalSortBy(): any {
    const status = [
      {
        value: '1',
        label: 'Request Date'
      },
      {
        value: '6',
        label: 'Plan Selected Date'
      },
      {
        value: '2',
        label: 'Customer Name A-Z'
      },
      {
        value: '3',
        label: 'Customer Name Z-A'
      },
      {
        value: '4',
        label: 'Business Name A-Z'
      },
      {
        value: '5',
        label: 'Business Name Z-A'
      }
    ];
    return status;
  }
  /**  Get filter  */
  getProposalFilters(ar_filter_check: any): any {
    let status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Request Sent'
      },
      {
        value: '2',
        label: 'Link Opened'
      },
      {
        value: '3',
        label: 'Plan Selected'
      },
      {
        value: '4',
        label: 'Financed'
      },
      // {
      //   value: '5',
      //   label: 'Canceled'
      // },
      {
        value: '6',
        label: 'Settings Changed'
      },
      // {
      //   value: '13',
      //   label: 'Last Resent'
      // }
    ];
    if (ar_filter_check) {
      status = [
        {
          value: '0',
          label: 'All'
        },
        {
          value: '7',
          label: 'Unread'
        },
        {
          value: '8',
          label: 'Read'
        },
        {
          value: '9',
          label: 'Link Opened'
        },
        {
          value: '10',
          label: 'Plan Selected'
        },
        {
          value: '11',
          label: 'Plan Created'
        },
        {
          value: '12',
          label: 'Payment Intiated'
        },
        {
          value: '13',
          label: 'Payment Completed'
        },
        {
          value: '14',
          label: 'Payment Failed'
        },
        {
          value: '15',
          label: 'Payment Reversed'
        },
        {
          value: '16',
          label: 'Payment Declined'
        },
        {
          value: '17',
          label: 'Payment Pending'
        }]
      status = status.filter(val => val.label != "Request Sent");
    }
    return status;
  }
  /**  Get Statuses  */
  getProposalStatuses(): any {
    const status = [
      {
        value: '-1',
        label: 'All'
      },
      {
        value: '0',
        label: 'Active'
      },
      {
        value: '1',
        label: 'Expired'
      }
    ];
    return status;
  }

  /**------------------- Settled Contract Filters ---------------- **/
  getSettledFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'dropdown',
        label: 'Financing Type',
        control: 'financing_type',
        value: params['fT'] ? params['fT'] : this.getSettledFinancingTypes()[0].value,
        default: this.getSettledFinancingTypes()[0].value,
        options: this.getSettledFinancingTypes(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Settlement Status',
        control: 'payment_type',
        value: params['pT'] ? params['pT'] : this.getSettledPaymentTypes()[0].value,
        default: this.getSettledPaymentTypes()[0].value,
        options: this.getSettledPaymentTypes(),
        hide: false
      },
      {
        type: 'dropdown',
        label: `Sort By`,
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.getSettledSortBy()[0].value,
        default: this.getSettledSortBy()[0].value,
        options: this.getSettledSortBy(),
        hide: false
      }
    ];
  }

  /**  Get Sort By  */
  getSettledSortBy(): any {
    const status = [
      {
        value: '1',
        label: 'Registration Date'
      },
      {
        value: '6',
        label: 'Settlement Date'
      },
      {
        value: '2',
        label: 'Customer Name A-Z'
      },
      {
        value: '3',
        label: 'Customer Name Z-A'
      },
      {
        value: '4',
        label: 'Business Owner Name A-Z'
      },
      {
        value: '5',
        label: 'Business Owner Name Z-A'
      }
    ];
    return status;
  }

  /**  Get Financing Types  */
  getSettledFinancingTypes(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      // {
      //   value: '1',
      //   label: 'Contracts with 10% Platform Fee'
      // },
      {
        value: '2',
        label: 'Contracts with CredeeFi'
      },
      {
        value: '3',
        label: 'Contracts with KeepeeFi'
      },
      {
        value: '4',
        label: 'Contracts with SimpleeFi'
      },
      {
        value: '5',
        label: 'Contracts with Credee Bill Financing'
      },
    ];
    return status;
  }

  /**  Get Payment Types  */
  SettlementListing(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      // {
      //   value: '1',
      //   label: '10% Platform Fee'
      // },
      {
        value: '2',
        label: 'CredeeFi Financing'
      },
      {
        value: '3',
        label: 'KeepeeFi Financing'
      },
      {
        value: '4',
        label: 'SimpleeFi Financing'
      },
      {
        value: '6',
        label: 'SimpleeFi Financing (With Deferred Interest)'
      },
      {
        value: '7',
        label: 'ZeroFi Payment Plan'
      },
      // {
      //   value: '4',
      //   label: 'Processed Payments'
      // },
      // {
      //   value: '5',
      //   label: 'Payments with Credee Bill'
      // },

    ];
    return status;
  }

  // settlement listing
  SettlementListingArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'dropdown',
        label: 'Financing Type',
        control: 'financing_type',
        value: params['fT'] ? params['fT'] : this.SettlementListing()[0].value,
        default: this.SettlementListing()[0].value,
        options: this.SettlementListing(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment Status',
        control: 'payment_type',
        value: params['pT'] ? params['pT'] : this.getSettledPaymentTypes()[0].value,
        default: this.getSettledPaymentTypes()[0].value,
        options: this.getSettledPaymentTypes(),
        hide: false
      },
      {
        type: 'dropdown',
        label: `Sort By`,
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.SettlementListingSortBy()[0].value,
        default: this.SettlementListingSortBy()[0].value,
        options: this.SettlementListingSortBy(),
        hide: false
      }, {
        type: 'dropdown',
        label: `Business Status`,
        control: 'is_active',
        value: params['is_active'] ? params['is_active'] : this.getSettledActive()[0].value,
        default: this.getSettledActive()[0].value,
        options: this.getSettledActive(),
        hide: false
      }
    ];
  }

  //getsettledActive
  getSettledActive() {
    const status = [
      {
        value: "0",
        label: 'All'
      },
      {
        value: '2',
        label: 'Inactive'
      },
      {
        value: '1',
        label: 'Active'
      },

    ]
    return status
  }

  getSettledPaymentTypes(): any {
    const status = [
      {
        value: '2',
        label: 'Success'
      },
      {
        value: '3',
        label: 'Failed'
      },
      {
        value: '7',
        label: 'Initiated'
      }

    ];
    return status;
  }

  SettlementListingSortBy(): any {
    const status = [
      {
        value: 'date_added',
        label: 'Payment Date'
      },
      // {
      //   value: '2',
      //   label: 'Old Registered contracts first'
      // },
      {
        value: '3',
        label: 'High to Low Remaining Amount'
      },
      {
        value: '4',
        label: 'Low to High Remaining Amount'
      }
    ];
    return status;
  }

  /**------------------- Manage Partners Filters ---------------- **/
  getManagePartnersFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getManagePartnersStatuses()[0].value,
        default: this.getManagePartnersStatuses()[0].value,
        options: this.getManagePartnersStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Filter',
        control: 'filter',
        value: params['filter'] ? params['filter'] : this.getManagePartnersTypes()[0].value,
        default: this.getManagePartnersTypes()[0].value,
        options: this.getManagePartnersTypes(),
        hide: false
      }
    ];
  }

  /**  Get Manage Partners Statuses  */
  getManagePartnersStatuses(): any {
    const status = [
      {
        value: '-1',
        label: 'All Partners'
      },
      {
        value: '0',
        label: 'Active Partners'
      },
      {
        value: '1',
        label: 'Blocked Partners'
      }
    ];
    return status;
  }

  /**  Get Manage Partners Types  */
  getManagePartnersTypes(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Bank Added'
      },
      {
        value: '2',
        label: 'Bank not Added'
      },
      {
        value: '3',
        label: 'Partners with no Business'
      },
      {
        value: '4',
        label: 'Partners with 1 Business'
      },
      {
        value: '5',
        label: 'Partners with 2-5 Businesses'
      },
      {
        value: '6',
        label: 'Partners with more than 5 Businesses'
      },
      {
        value: '13',
        label: 'Approved Partners'
      },
      {
        value: '14',
        label: 'Disapproved Partners'
      },
      {
        value: '15',
        label: 'Verified Emails'
      },
      {
        value: '16',
        label: 'Unverified Emails'
      }
    ];
    return status;
  }

  /**------------------- Manage Agents Filters ---------------- **/
  getManageAgentsFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getManageAgentsStatuses()[0].value,
        default: this.getManageAgentsStatuses()[0].value,
        options: this.getManageAgentsStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Filter',
        control: 'filter',
        value: params['filter'] ? params['filter'] : this.getManageAgentsTypes()[0].value,
        default: this.getManageAgentsTypes()[0].value,
        options: this.getManageAgentsTypes(),
        hide: false
      }
    ];
  }

  /**  Get Manage Agents Statuses  */
  getManageAgentsStatuses(): any {
    const status = [
      {
        value: '-1',
        label: 'All Agents'
      },
      {
        value: '0',
        label: 'Active Agents'
      },
      {
        value: '1',
        label: 'Blocked Agents'
      }
    ];
    return status;
  }

  /**  Get Manage Agents Types  */
  getManageAgentsTypes(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Bank Added'
      },
      {
        value: '2',
        label: 'Bank not Added'
      },
      {
        value: '3',
        label: 'Agents with no Business'
      },
      {
        value: '4',
        label: 'Agents with 1 Business'
      },
      {
        value: '5',
        label: 'Agents with 2-5 Businesses'
      },
      {
        value: '6',
        label: 'Agents with more than 5 Businesses'
      },
      {
        value: '13',
        label: 'Approved Agents'
      },
      {
        value: '14',
        label: 'Disapproved Agents'
      },
      {
        value: '15',
        label: 'Verified Emails'
      },
      {
        value: '16',
        label: 'Unverified Emails'
      }
    ];
    return status;
  }

  /**------------------- Manage Ach Customers Filters ---------------- **/
  getAchCustomersFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Type',
        control: 'type',
        value: params['type'] ? params['type'] : this.getAchCustomersTypes()[0].value,
        default: this.getAchCustomersTypes()[0].value,
        options: this.getAchCustomersTypes(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getAchCustomersStatuses()[0].value,
        default: this.getAchCustomersStatuses()[0].value,
        options: this.getAchCustomersStatuses(),
        hide: false
      }
    ];
  }

  /**  Get Ach Customers Types  */
  getAchCustomersTypes(): any {
    const status = [
      {
        value: '-1',
        label: 'All'
      },
      {
        value: '1',
        label: 'Micro Deposit'
      },
      {
        value: '0',
        label: 'Instant Auth'
      }
    ];
    return status;
  }

  /**  Get Ach Customers Statuses  */
  getAchCustomersStatuses(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Pending'
      },
      {
        value: '2',
        label: 'Verified'
      },
      {
        value: '3',
        label: 'Failed'
      },
      {
        value: '4',
        label: 'Reauthenticate'
      }
    ];
    return status;
  }
  /**------------------- Manage Ach Payments Filters ---------------- **/
  getAchPaymentsFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Transaction Initiated Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2020, 5, 1),
        default: new Date(2020, 5, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Transaction Initiated End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getAchPaymentStatuses()[0].value,
        default: this.getAchPaymentStatuses()[0].value,
        options: this.getAchPaymentStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment From',
        control: 'payment_from',
        value: params['payment_from'] ? params['payment_from'] : this.getAchPaymentPanels()[0].value,
        default: this.getAchPaymentPanels()[0].value,
        options: this.getAchPaymentPanels(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment Type',
        control: 'payment_type',
        value: params['payment_type'] ? params['payment_type'] : -1,
        default: -1,
        options: [{
          value: '-1',
          label: 'All'
        }],
        hide: false
      }
    ];
  }

  getWalletPaymentsFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2021, 7, 1),
        default: new Date(2020, 5, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getAchPaymentStatuses()[0].value,
        default: this.getAchPaymentStatuses()[0].value,
        options: this.getAchPaymentStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Wallet Type',
        control: 'wallet_type',
        value: params['wallet_type'] ? params['wallet_type'] : -1,
        default: -1,
        options: [
          {
            value: '-1',
            label: 'All'
          },
          {
            value: '1',
            label: 'Apple Pay'
          },
          {
            value: '2',
            label: 'Google Pay'
          }
        ],
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment From',
        control: 'payment_from',
        value: params['payment_from'] ? params['payment_from'] : this.getWalletPaymentPanels()[0].value,
        default: this.getWalletPaymentPanels()[0].value,
        options: this.getWalletPaymentPanels(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment Type',
        control: 'payment_type',
        value: params['payment_type'] ? params['payment_type'] : -1,
        default: this.getWalletPaymentType()[0].value,
        options: this.getWalletPaymentType(),
        hide: false
      }
    ];
  }

  getWalletPaymentType(): any {
    const status = [
      {
        value: '-1',
        label: 'All'
      },
      {
        value: '2',
        label: 'Recurring Payment'
      },
      {
        value: '11',
        label: 'Partial Payment'
      },
      {
        value: '18',
        label: 'Payoff'
      },
      {
        value: '10',
        label: 'Recurring Date Edited'
      }
    ];
    return status;
  }

  /**  Get Ach Payment Statuses  */
  getAchPaymentStatuses(): any {
    const status = [
      {
        value: '-1',
        label: 'All'
      },
      {
        value: '0',
        label: 'Pending'
      },
      {
        value: '1',
        label: 'Success'
      },
      {
        value: '2',
        label: 'Failed'
      }
    ];
    return status;
  }
  /**  Get Ach Payment From  */
  getAchPaymentPanels(): any {
    const status = [
      {
        value: '-1',
        label: 'All'
      },
      {
        value: '1',
        label: 'Admin'
      },
      {
        value: '2',
        label: 'Business'
      },
      {
        value: '3',
        label: 'Customer'
      },
      // {
      //   value: '4',
      //   label: 'Enterprise'
      // },
      {
        value: '5',
        label: 'Cron'
      },
      {
        value: '6',
        label: 'Request Panel'
      },
      {
        value: '7',
        label: 'System'
      }
    ];
    return status;
  }
  /**  Get wallet Payment From  */
  getWalletPaymentPanels(): any {
    const status = [
      {
        value: '5',
        label: 'All'
      },
      {
        value: '0',
        label: 'Customer Panel'
      },
      {
        value: '1',
        label: 'Android App'
      },
      {
        value: '2',
        label: 'IOS App'
      },
      {
        value: '3',
        label: 'Request Panel'
      }
    ];
    return status;
  }
  /**------------------- Manage Edited Completed Contracts Filters ---------------- **/
  getEditedCompletedContractsFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2020, 0, 1),
        default: new Date(2020, 0, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Status',
        control: 'status',
        value: params['status'] ? params['status'] : -1,
        default: -1,
        options: [
          {
            value: '-1',
            label: 'All'
          },
          {
            value: '0',
            label: 'Pending'
          },
          {
            value: '1',
            label: 'Paid'
          },
          {
            value: '2',
            label: 'Rejected'
          },
          {
            value: '3',
            label: 'Unpaid'
          }
        ],
        hide: false
      }
    ];
  }

  /**------------------- All Contracts Filters ---------------- **/
  allContractFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['sD'] ? new Date(params['sD']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        placeholder: 'Start Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['less_than_to', "endDate"]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['eD'] ? new Date(params['eD']) : new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        placeholder: 'End Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['more_than_from', "startDate"]
      },
      {
        type: 'multiselect',
        label: 'Contract by Status',
        control: 'contractStatus',
        value: params['status'] ? params['status'] : [],
        default: null,
        placeholder: 'No Filter',
        options: params && params['type'] > 0 && params['type'] != 6 ? this.getContractStatusesWithOutLegalBankruptcy() : this.getContractStatuses(),
        hide: false
      },
      {
        type: 'multiselect',
        label: 'Filter By',
        control: 'filterBy',
        value: params['extraFilter'] ? params['extraFilter'] : [],
        default: null,
        placeholder: 'Select Filter',
        options: this.getAllContractFilterBy(),
        hide: false,
      },
      {
        type: 'multiselect',
        label: 'Filter By Industry',
        control: 'industryFilter',
        value: params['industryFilter'] ? params['industryFilter'] : [],
        default: null,
        placeholder: 'Select Industry',
        options: [],
        hide: false,
        onChangeFunc: "industryFilter"
      },
      {
        type: 'multiselect',
        label: 'Filter By Sub-Industry',
        control: 'subIndustryFilter',
        value: params['subIndustryFilter'] ? params['subIndustryFilter'] : [],
        default: null,
        placeholder: 'Select Sub-Industry',
        options: [],
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Sort By ',
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.getAllContractSort()[0].value,
        default: this.getAllContractSort()[0].value,
        options: this.getAllContractSort(),
        hide: false
      },
      {
        type: 'country_currency',
        label: 'Country',
        control: 'country',
        value: this._local.customGetItem("country_id"),
        default: this._local.customGetItem("country_id"),
        options: this.getCountry(),
        hide: true
      }
    ];
  }

  paymentProcessFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['sD'] ? new Date(params['sD']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        placeholder: 'Start Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['less_than_to', "endDate"]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['eD'] ? new Date(params['eD']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        placeholder: 'End Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['more_than_from', "startDate"]
      }
    ];
  }

  accountReceivableFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['sD'] ? new Date(params['sD']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        placeholder: 'Start Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['less_than_to', "endDate"]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['eD'] ? new Date(params['eD']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        placeholder: 'End Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['more_than_from', "startDate"]
      }
    ];
  }

  claimRequestFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'dropdown',
        label: 'Filter By',
        control: 'filterBy',
        value: params['filter'] ? params['filter'] : this.getClaimRequestFilterBy()[0].value,
        default: this.getClaimRequestFilterBy()[0].value,
        options: this.getClaimRequestFilterBy(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'ORP Filter',
        control: 'isOrp',
        value: params['sortBy'] ? params['sortBy'] : this.claimBooleanFilter()[0].value,
        default: this.claimBooleanFilter()[0].value,
        options: this.claimBooleanFilter(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Instant Claim',
        control: 'isInstant',
        value: params['sortBy'] ? params['sortBy'] : this.claimBooleanFilter()[0].value,
        default: this.claimBooleanFilter()[0].value,
        options: this.claimBooleanFilter(),
        hide: false,
        can_disable: true,
        disabled_condition: [
          {
            control: "isOrp", value: ["0"]  /* This filter is disabled when value of above filter having control name "status" is 0 */
          }
        ]
      },
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        placeholder: 'Start Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['less_than_to', "endDate"]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        placeholder: 'End Date',
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['more_than_from', "startDate"]
      }
    ];
  }

  contractChangeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Contract Type',
        control: 'contract_type',
        value: params['contract_type'] ? params['contract_type'] : this.getContractType()[0].value,
        default: this.getContractType()[0].value,
        options: this.getContractType(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Contract Status',
        control: 'pf_status',
        value: params['pf_status'] ? params['pf_status'] : this.getContractChangeStatuses()[0].value,
        default: this.getContractChangeStatuses()[0].value,
        options: this.getContractChangeStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Panel',
        control: 'action_by',
        value: params['action_by'] ? params['action_by'] : this.actionByFilter()[0].value,
        default: this.actionByFilter()[0].value,
        options: this.actionByFilter(),
        hide: false
      },
      {
        type: 'multiDatePicker',
        label: '',
        control: 'dateRange',
        value: params['sD'] && params['eD'] ? [new Date(params['sD']), new Date(params['eD'])] : [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        default: [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        placeholder: '',
        hide: false
      }
    ];
  }

  editedContractChangeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Contract Type',
        control: 'contract_type',
        value: params['contract_type'] ? params['contract_type'] : this.getContractType()[0].value,
        default: this.getContractType()[0].value,
        options: this.getContractType(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Contract Status',
        control: 'pf_status',
        value: params['pf_status'] ? params['pf_status'] : this.getContractChangeStatuses()[0].value,
        default: this.getContractChangeStatuses()[0].value,
        options: this.getContractChangeStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Panel',
        control: 'action_by',
        value: params['action_by'] ? params['action_by'] : this.editedActionByFilter()[0].value,
        default: this.editedActionByFilter()[0].value,
        options: this.editedActionByFilter(),
        hide: false
      },
      {
        type: 'multiDatePicker',
        label: '',
        control: 'dateRange',
        value: params['sD'] && params['eD'] ? [new Date(params['sD']), new Date(params['eD'])] : [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        default: [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        placeholder: '',
        hide: false
      }
    ];
  }

  canceledContractChangeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'multiDatePicker',
        label: '',
        control: 'dateRange',
        value: params['sD'] && params['eD'] ? [new Date(params['sD']), new Date(params['eD'])] : [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        default: [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        placeholder: '',
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Contract Type',
        control: 'contract_type',
        value: params['contract_type'] ? params['contract_type'] : this.getContractType()[0].value,
        default: this.getContractType()[0].value,
        options: this.getContractType(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Panel',
        control: 'action_by',
        value: params['action_by'] ? params['action_by'] : this.actionByFilter()[0].value,
        default: this.actionByFilter()[0].value,
        options: this.actionByFilter(),
        hide: false
      },
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      }
    ];
  }

  payoffContractChangeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'multiDatePicker',
        label: '',
        control: 'dateRange',
        value: params['sD'] && params['eD'] ? [new Date(params['sD']), new Date(params['eD'])] : [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        default: [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        placeholder: '',
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Contract Type',
        control: 'contract_type',
        value: params['contract_type'] ? params['contract_type'] : this.getContractType()[0].value,
        default: this.getContractType()[0].value,
        options: this.getContractType(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Panel',
        control: 'action_by',
        value: params['action_by'] ? params['action_by'] : this.payoffActionByFilter()[0].value,
        default: this.payoffActionByFilter()[0].value,
        options: this.payoffActionByFilter(),
        hide: false
      },
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      }
    ];
  }

  partialPaymentChangeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Contract Type',
        control: 'contract_type',
        value: params['contract_type'] ? params['contract_type'] : this.getContractType()[0].value,
        default: this.getContractType()[0].value,
        options: this.getContractType(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Contract Status',
        control: 'pf_status',
        value: params['pf_status'] ? params['pf_status'] : this.getContractChangeStatuses()[0].value,
        default: this.getContractChangeStatuses()[0].value,
        options: this.getContractChangeStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Panel',
        control: 'action_by',
        value: params['action_by'] ? params['action_by'] : this.partialPaymentActionByFilter()[0].value,
        default: this.partialPaymentActionByFilter()[0].value,
        options: this.partialPaymentActionByFilter(),
        hide: false
      },
      {
        type: 'multiDatePicker',
        label: '',
        control: 'dateRange',
        value: params['sD'] && params['eD'] ? [new Date(params['sD']), new Date(params['eD'])] : [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        default: [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        placeholder: '',
        hide: false
      }
    ];
  }

  socialPaymentContractChangeFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Contract Type',
        control: 'contract_type',
        value: params['contract_type'] ? params['contract_type'] : this.getContractType()[0].value,
        default: this.getContractType()[0].value,
        options: this.getContractType(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Contract Status',
        control: 'pf_status',
        value: params['pf_status'] ? params['pf_status'] : this.getContractChangeStatuses()[0].value,
        default: this.getContractChangeStatuses()[0].value,
        options: this.getContractChangeStatuses(),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Link Sent From',
        control: 'action_by',
        value: params['action_by'] ? params['action_by'] : this.actionByFilter()[0].value,
        default: this.actionByFilter()[0].value,
        options: this.actionByFilter(),
        hide: false
      },
      {
        type: 'multiDatePicker',
        label: '',
        control: 'dateRange',
        value: params['sD'] && params['eD'] ? [new Date(params['sD']), new Date(params['eD'])] : [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        default: [new Date(2024, 0, 1), new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700"))],
        placeholder: '',
        hide: false
      }
    ];
  }

  declinedPaymentFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['sD'] ? this._shared.getExactDate(new Date(params['sD'])) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
        validate: ['less_than_to', "endDate"]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['eD'] ? this._shared.getExactDate(new Date(params['eD'])) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false,
        validate: ['more_than_from', "startDate"]
      },
      {
        type: 'dropdown',
        label: 'Payment Status',
        control: 'status',
        value: params['status'] ? params['status'] : -1,
        default: -1,
        options: [
          {
            value: '-1',
            label: 'All'
          },
          {
            value: '0',
            label: 'Failed'
          },
          {
            value: '1',
            label: 'Success'
          },
          {
            value: '2',
            label: 'Ignore'
          }
        ],
        hide: false
      }
    ];
  }

  ORPListFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['sD'] ? new Date(params['sD']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['eD'] ? new Date(params['eD']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Payment Type',
        control: 'payment_type',
        value: params['payment_type'] ? params['payment_type'] : 0,
        default: 0,
        options: [
          { value: "0", label: "All" },
          { value: "2", label: "Recurring" },
          { value: "18", label: "Payoff" },
          { value: "16", label: "Charge Full" }
        ],
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Claimed Status',
        control: 'is_claimed',
        value: params['is_claimed'] ? params['is_claimed'] : -1,
        default: -1,
        options: [
          { value: "-1", label: "All" },
          { value: "1", label: "Claimed" },
          { value: "0", label: "Not Claimed" }
        ],
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Claim Paid',
        control: 'is_claim_paid',
        value: params['is_claim_paid'] ? params['is_claim_paid'] : -1,
        default: -1,
        options: [
          { value: "-1", label: "All" },
          { value: "1", label: "Yes" },
          { value: "0", label: "No" }
        ],
        hide: false,
        can_disable: true,
        disabled_condition: [
          {
            control: "is_claimed", value: ["0", "-1", 0, -1]  /* This filter is disabled when value of above filter having control name "status" is 0, 4, 5 */
          }
        ]
      }
    ];
  }
  UnpaidPendingFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Business Status',
        control: 'active_status',
        value: params['active_status'] ? String(params['active_status']) : '0',
        default: '0',
        options: [
          { value: "0", label: "All" },
          { value: "1", label: "Active" },
          { value: "2", label: "Inactive" }
        ],
        hide: false
      },

      {
        type: 'dropdown',
        label: 'ORP Filter',
        control: 'orp_filter',
        value: params['orp_filter'] ? params['orp_filter'] : -1,
        default: -1,
        options: [
          { value: "-1", label: "All" },
          { value: "1", label: "Yes" },
          { value: "0", label: "No" }
        ],
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Instant Claim',
        control: 'instant_claim',
        value: params['instant_claim'] ? params['instant_claim'] : -1,
        default: -1,
        options: [
          { value: "-1", label: "All" },
          { value: "1", label: "Yes" },
          { value: "0", label: "No" }
        ],
        hide: false,
        can_disable: true,
        disabled_condition: [
          {
            control: "orp_filter", value: ["0", 0]
          }
        ]
      }
    ];
  }

  splitErrorsList(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Time Interval',
        control: 'time_interval',
        value: params['time_interval'] ? params['time_interval'] : "24",
        default: "24",
        options: [
          { value: "24", label: "24 Hours" },
          { value: "48", label: "48 Hours" },
          { value: "168", label: "1 Week" },
          { value: "672", label: "4 Weeks" },
          { value: "0", label: "Custom" },
        ],
        hide: false
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'start_date',
        value: params['start_date'] ? new Date(params['start_date']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['less_than_to', "end_date"],
        can_disable: true,
        disabled_condition: [
          {
            control: "time_interval", value: ["24", "48", "168", "672", 24, 48, 168, 672]
          }
        ]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'end_date',
        value: params['end_date'] ? new Date(params['end_date']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['more_than_from', "start_date"],
        can_disable: true,
        disabled_condition: [
          {
            control: "time_interval", value: ["24", "48", "168", "672", 24, 48, 168, 672]
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Error Type',
        control: 'error_type',
        value: params['error_type'] ? params['error_type'] : '',
        default: "",
        options: [
          { value: "", label: "All" },
          { value: "split_opened", label: "Proposal Opened" },
          { value: "choose_plan", label: "Choose Plan" },
          { value: "split_contract_created", label: "Proposal Contract Created" },
        ],
        hide: false
      },
    ];
  }
  /**------------------- Proposal Request Filters ---------------- **/
  getCustomerDisputesFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Assignment Status',
        control: 'status',
        value: params['status'] ? params['status'] : this.getDisputeStatusFilters()[0].value,
        default: this.getDisputeStatusFilters()[0].value,
        options: this.getDisputeStatusFilters(),
        hide: false,
        for: "proposal_request"
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 0, 1),
        default: new Date(2024, 0, 1),
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['less_than_to', "endDate"]
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false,
        minDate: new Date(2024, 0, 1),
        maxDate: new Date(this._shared.getMaxDate(this._local.customGetItem("timezone"))),
        validate: ['more_than_from', "startDate"]
      },
    ];
  }
  /**  Get filter  */
  getDisputeStatusFilters(): any {
    const status = [
      {
        value: '-1',
        label: 'All'
      },
      {
        value: '0',
        label: 'Pending'
      },
      {
        value: '1',
        label: 'Closed'
      },
      {
        value: '2',
        label: 'Reopen'
      },
      {
        value: '3',
        label: 'Followup'
      }
    ];
    return status;
  }
  getGuaranteedChangesFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'Filter',
        control: 'filter',
        value: params['filter'] ? params['filter'] : this.getGuaranteedFilters()[0].value,
        default: this.getGuaranteedFilters()[0].value,
        options: this.getGuaranteedFilters(),
        hide: false
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['sD'] ? new Date(params['sD']) : new Date(2021, 6, 1),
        default: new Date(2021, 6, 1),
        hide: false
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['eD'] ? new Date(params['eD']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      }
    ];
  }
  getGuaranteedFilters(): any {
    const status = [
      { value: "-1", label: "All" },
      { value: "1", label: "Protected to Non-Protected" },
      { value: "2", label: "Non-Protected to Protected" },
    ];
    return status;
  }
  // subscription plans
  getSubscriptionPlanType(): any {
    const status = [
      { value: "0", label: "All" },
      { value: "1", label: "Free" },
      { value: "4", label: "Silver" },
      { value: "3", label: "Gold" },
      { value: "2", label: "Platinum" },
    ];
    return status;
  }
  // subscription plans
  getCountry(): any {
    const status = [
      { cocu_id: '0', country_id: '0', country_name: "All" },
      ...(this._shared.getCountryData() ? this._shared.getCountryData() : [])
    ];
    return status;
  }

  // webnar
  getWebinar(): any {
    let params = this.route.snapshot.queryParams
    console.log(params, "params");

    return [
      {
        type: 'dropdown',
        label: 'Select Date',
        control: 'webinar_date',
        value: params['webinar_date'] ? params['webinar_date'] : this.getWebinarData()[0].value,
        default: this.getWebinarData()[0].value,
        options: this.getWebinarData(),
        hide: false,
      },
      {
        type: 'dropdown',
        label: 'time slot',
        control: 'sortBy',
        value: params['sortBy'] ? params['sortBy'] : this.getWebinartime()[0].value,
        default: this.getWebinartime()[0].value,
        options: this.getWebinartime(),
        hide: false,
      }

    ];
  }

  dynamic_date = [
    { value: "0", label: "All" }
  ];
  dynamic_time = [
    { value: "0", label: "All" }
  ];
  setvalwebinardate(val: any) {
    this.dynamic_date = val.map((val: any) => {
      return { value: val, label: val }
    })

  }

  getWebinarData(): any {
    const status1 = this.dynamic_date;
    return status1;
  }


  setvalwebinardtime(val: any) {
    this.dynamic_time = val.map((val: any) => {
      return { value: val.start_time, label: val.start_time }
    })
  }

  getWebinartime(): any {
    const status = this.dynamic_time;
    return status;
  }

  // -----------------busines leads filter---------------
  getBusinessLeadsFilter(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,
      },
      {
        type: 'dropdown',
        label: `Status`,
        control: 'status',
        value: params['status'] ? params['status'] : this.getpreapprovalstatus()[0].value,
        default: this.getpreapprovalstatus()[0].value,
        options: this.getpreapprovalstatus(),
        hide: false
      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2024, 2, 5),
        default: new Date(2024, 0, 1),
        hide: false
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      }
    ];
  }
  /**  Get Sort By  */
  getpreapprovalstatus(): any {
    const status = [
      {
        value: '0',
        label: 'All'
      },
      {
        value: '1',
        label: 'Pending'
      },
      {
        value: '2',
        label: 'Follow-up'
      },
      {
        value: '3',
        label: 'Incomplete'
      },
      {
        value: '4',
        label: 'Converted'
      }
    ];
    return status;
  }

  geUTMFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2021, 7, 1),
        default: new Date(2020, 5, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        hide: false
      }
    ];
  }
  geUTMVisitorFiltersArray(): any {
    let params = this.route.snapshot.queryParams
    let timezone = this._shared.getTimezone();
    // this._shared.getTimezone().subscribe((timeZone: any) => {
    //   timezone = timeZone;
    // })
    return [
      {
        type: 'search',
        label: 'Search',
        control: 'search',
        value: params['search'] ? params['search'] : '',
        default: '',
        placeholder: 'Search (press enter to search)',
        hide: false,

      },
      {
        type: 'datepicker',
        label: 'Start Date',
        control: 'startDate',
        value: params['startDate'] ? new Date(params['startDate']) : new Date(2021, 7, 1),
        default: new Date(2020, 5, 1),
        hide: false,
      },
      {
        type: 'datepicker',
        label: 'End Date',
        control: 'endDate',
        value: params['endDate'] ? new Date(params['endDate']) : new Date(this._shared.getMaxDate(this._shared.getTimezone() || "-700")),
        default: new Date(this._shared.getMaxDate(timezone || "-700")),
        hide: false
      },
      {
        type: 'dropdown',
        label: 'Is Business',
        control: 'is_business',
        value: params['isBusiness'] || 0,
        default: '0',
        options: [
          { value: "0", label: "All" },
          { value: "1", label: "Yes" },
          { value: "2", label: "No" },
        ],
        hide: false
      },
    ];
  }

  lastResentValues() {
    return [
      {
        value: '0',
        label: 'Proposal Sent Date'
      },
      {
        value: '1',
        label: 'Last Resent Date'
      }
    ]
  }

}
