<div class="row details" *ngIf="calculation_details">
    <div *ngFor="let cal of calculation_details; let i=index;" class="col-md-12">
      <div class="row">
        <div  [ngClass]="{'col-md-12':cal.type=='ARRAY','col-md-7':cal.type!='ARRAY'}">
          <h4 class="fs-16 fw-500 text-secondary">{{cal.name}}
            <i *ngIf="cal.info" class="fa fa-info-circle" data-container="body" data-placement="top"tooltip={{cal.info}}></i>
          </h4>
        </div>
        <div class="col-md-5" *ngIf="cal.type=='VALUE'">
          <h5 class="fs-14 fw-400 text-muted">{{cal.value}}</h5>
        </div>
        <div class="col-md-12">
          <h5 *ngIf="cal.type=='ARRAY'">
            <ul *ngIf="cal && cal.value.length>0">
              <ng-template ngFor let-cal1 [ngForOf]="cal['value']">
                <li class="m-0 fs-14 fw-400 text-muted">
                  <div class="row">
                    <div  [ngClass]="{'col-md-12':cal1.type=='ARRAY','col-md-7':cal1.type!='ARRAY'}">
                      <h5 class="fs-16 fw-500 text-secondary">{{cal1.name}}
                        <i *ngIf="cal1.info" class="fa fa-info-circle" data-container="body" data-placement="top"tooltip={{cal1.info}}></i>
                      </h5>
                    </div>
                    <div class="col-md-5" *ngIf="cal1.type=='VALUE'">
                      <h5 class="fs-14 fw-400 text-muted">{{cal1.value}}</h5>
                    </div>
                    <div class="col-md-12">
                      <h5 *ngIf="cal1.type=='ARRAY'">
                        <ul *ngIf="cal1 && cal1.value.length>0">
                          <ng-template ngFor let-cal2 [ngForOf]="cal1['value']">
                            <li class="m-0 fs-14 fw-400 text-muted">
                              <div class="row">
                                <div  [ngClass]="{'col-md-12':cal2.type=='ARRAY','col-md-7':cal2.type!='ARRAY'}">
                                  <h5 class="fs-16 fw-500 text-secondary">{{cal2.name}}
                                    <i *ngIf="cal2.info" class="fa fa-info-circle" data-container="body" data-placement="top"tooltip={{cal2.info}}></i>
                                  </h5>
                                </div>
                                <div class="col-md-5" *ngIf="cal2.type=='VALUE'">
                                  <h5 class="fs-14 fw-400 text-muted">{{cal2.value}}</h5>
                                </div>
                                <div class="col-md-12">
                                  <h5 *ngIf="cal2.type=='ARRAY'">
                                    <ul *ngIf="cal2 && cal2.value.length>0">
                                      <ng-template ngFor let-cal3 [ngForOf]="cal2['value']">
                                        <li class="m-0 fs-14 fw-400 text-muted">
                                          <div class="row">
                                            <div  [ngClass]="{'col-md-12':cal3.type=='ARRAY','col-md-7':cal3.type!='ARRAY'}">
                                              <h5 class="fs-16 fw-500 text-secondary">{{cal3.name}}
                                                <i *ngIf="cal3.info" class="fa fa-info-circle" data-container="body" data-placement="top"tooltip={{cal3.info}}></i>
                                              </h5>
                                            </div>
                                            <div class="col-md-5" *ngIf="cal3.type=='VALUE'">
                                              <h5 class="fs-14 fw-400 text-muted">{{cal3.value}}</h5>
                                            </div>
                                          </div>
                                        </li>
                                      </ng-template>
                                    </ul>
                                  </h5>
                                </div>
                              </div>
                            </li>
                          </ng-template>
                        </ul>
                      </h5>
                    </div>
                  </div>
                </li>
              </ng-template>
            </ul>
          </h5>
        </div>
      </div>
    </div>
  </div>
  