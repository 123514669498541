import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-profile-image-modal',
  templateUrl: './profile-image-modal.component.html',
  styleUrl: './profile-image-modal.component.scss'
})
export class ProfileImageModalComponent {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  croppedImage: any;
  @ViewChild('cropper', { static: false }) cropper!: ImageCropperComponent;
  public extra_data!: extra_data;
  public modalType = '';

  constructor(
    private bsModalRef: BsModalRef,
    public _shared: SharedService,
    // private _api: ApiService,
    // private _fb: UntypedFormBuilder,
    // public _business: BusinessService,
    // public mainService: MainService,
    // public datePipe: DatePipe,
    // private router: Router
  ) { }

  hide() {
    this.bsModalRef.hide();
  }
  updateAgentProfile() {
    this.passEntry.emit({ success: true, croppedImage: this.croppedImage });
    this.hide();
  }

  imageCropped(event: ImageCroppedEvent) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.croppedImage = fileReader.result; // base64 string
    };
    
    if (event.blob) {
      fileReader.readAsDataURL(event.blob); // convert Blob to base64
    }
  }

  cancel() {
    this.passEntry.emit({ success: false });
    this.hide();
  }
}

interface extra_data {
  doctor_info?: any;
  profileData?: any;
  doctor_financial_settings?: any;
  currency_data?: any;
  imageChangedEvent?: any;
  doctor_financing_type?: any;
  kpi_stats?: any;
  timezone?: string;
}