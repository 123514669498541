import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { AddNotesComponent } from '../../../shared/add-notes/add-notes.component';
import { VerifyComponent } from '../../../shared/verify/verify.component';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
// import * as _ from 'underscore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { ToasterService } from 'src/app/components/shared/toaster/toaster.service';
import { CompressImageService } from 'src/app/components/shared/compress-image.service';
import { LoaderService } from 'src/app/components/shared/loader/loader.service';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { CustomerService } from 'src/app/components/shared/customers.service';
import { CustomerModalComponent } from '../customer-modal/customer-modal.component';
import { GenerateStripeElementComponent } from 'src/app/components/shared/generate-stripe-element/generate-stripe-element.component';
import { ProfileImageModalComponent } from 'src/app/components/shared/profile-image-modal/profile-image-modal.component';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [DatePipe],
})
export class ProfileComponent {
  today: any = new Date();
  endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
  startDate = '2017-04-01';
  buttonClickedSubject: Subject<any> = new Subject();
  patient: any = {};
  patient_settings: any = {
    show_google_pay: true,
    show_apple_pay: true,
    paymentNote: '',
  };
  is_dummy: any = this._local.customGetItem('is_dummy');
  // timezone: any = MainService.customGetItem('timezone');
  timezone: any = this._shared.timezone;
  timezoneName: any = this._shared.timezoneName;
  data1: any;
  imageUploader: any;
  imageUploaderDL: any;
  modelRef: any;

  public profileForm!: UntypedFormGroup;

  @ViewChild('cropper', { static: false }) cropper!: ImageCropperComponent;
  @ViewChild(ModalDirective, { static: false }) smModal!: ModalDirective;
  @ViewChild('updateEmailModal', { static: false })
  updateEmailModal!: ModalDirective;
  @ViewChild('fileUpload', { static: false }) fileUpload!: ElementRef;
  newMobile: any;
  alternateMobile: any;
  alternateEmail: any;

  zip: any = [];
  selectedZipCode: any;
  registered_doctors: any;
  // @ViewChild(DatatableComponent) table: DatatableComponent;
  temp: any = [];
  approveNote: any = [];
  addCardNote: any;
  update: any = {
    patient_email: '',
    allContracts: false,
    contract_code: '',
  };
  patient_notes: any;
  comment: any;
  id: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  bsModalRef: BsModalRef | null = null;
  noteDetails: any;
  patient_contract: any;
  bank_accounts: any = [];
  addresses: any = {
    address_array: [],
    count: 0,
    add_address: {
      zipcode_list: [],
      new_address: '',
      new_mobile: '',
      new_zip: '',
      city: '',
      city_id: '',
      state: '',
      addAddressNote: '',
    },
    update_address: {
      choose_option: 0,
      selected_address_id: 0,
      updateAddressNote: '',
    },
    contracts: [],
    selected_contracts: [],
  };
  alternate_emails: any = {
    emails: [],
    count: 0,
    alternate_email: '',
    email_id: 0,
    addEmailNote: '',
  };
  alternate_phones: any = {
    phones: [],
    count: 0,
    alternate_phone: '',
    email_id: 0,
    addPhoneNote: '',
  };
  send_reauth_email: number = 0;
  reAuth_note: string = '';
  bank_data: any = {};
  showPwd = false;
  password = '';
  note = '';
  @ViewChild('verifyBankModal', { static: false })
  verifyBankModal!: ModalDirective;
  @ViewChild('addStripeBankModal', { static: false })
  addStripeBankModal!: ModalDirective;
  @ViewChild('addBankLinkModal', { static: false })
  addBankLinkModal!: ModalDirective;
  @ViewChild('verifyBankLinkModal', { static: false })
  verifyBankLinkModal!: ModalDirective;
  @ViewChild('addNewAddressModal', { static: false })
  addNewAddressModal!: ModalDirective;
  @ViewChild('makeDefaultAddressModal', { static: false })
  makeDefaultAddressModal!: ModalDirective;
  @ViewChild('addAlternateEmailModal', { static: false })
  addAlternateEmailModal!: ModalDirective;
  @ViewChild('addAlternatePhoneModal', { static: false })
  addAlternatePhoneModal!: ModalDirective;

  @ViewChild('reauthenticateBankLinkModal', { static: false })
  reauthenticateBankLinkModal!: ModalDirective;
  achNote: any;
  updateAcc: any = {};
  user_phone1: any;
  user_email1: any;
  verify_link_phone: any;
  verify_link_email: any;
  plaid_accounts: any = [];
  patient_balance: any;
  reauth_link_phone: any;
  reauth_link_email: any;
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  webcheckbox = false;
  isDisabledWeb = true;
  androidcheckbox = false;
  isDisabledAndroid = true;
  ioscheckbox = false;
  isDisabledios = true;
  weblogin: any;
  androidlogin: any;
  ioslogin: any;
  using_app: any;
  logindate: any;

  load_notes = false;
  show_full_ssn = false;
  file: any;
  return: any;
  currencyData = {
    bank_support_type: ['us_bank_account'],
    currency_code: 1,
    currency_id: "US Dollar",
    display_logo: "$"
  }
  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public cd: ChangeDetectorRef,
    private location: Location,
    private modalService: BsModalService,
    private router: Router,
    private compressImage: CompressImageService,
    public loaderService: LoaderService,
    public http: HttpClient,
    public _shared: SharedService,
    private _local: localStorageService,
    private _api: ApiService,
    private _toaster: ToasterService,
    private _customerService: CustomerService,
    private _fb: UntypedFormBuilder
  ) {
    this.data1 = {};
  }

  ngOnInit() {
    this.timezone = this._shared.timezone;
    this.timezoneName = this._shared.timezoneName;

    this.id = Number(this.route.snapshot.paramMap.get('id')) || 0;
    if (!(this.id && this.id > 0)) {
      this.router.navigate(['/app/customers']);
      return;
    }

    this.route.queryParams.subscribe((data) => {
      this.getPatientInfo(this.id);
      this.fetchAlternateEmails(this.id);
      this.fetchAlternatePhones(this.id);
      this.fetchCustomerAddressDetails(this.id);
    });

    const date_of_birth = this.patient.date_registered;

    this.profileForm = this._fb.group({
      profilePic: [''],
      imgUploader: [''],
      dl_number: [''],
      fname: [''],
      lname: [''],
      patient_ssn: [''],
      date_of_birth: [''],
      date_registered: [''],
      email: [''],
      mobile: [''],
    });

    this.getPatientInfo(this.id);
  }

  getPatientInfo(id: any) {
    const params = {
      patient_id: id,
      is_dummy: this.is_dummy,
    };
    this._api.api(params, 'customer/patient_profile_data', 'post').subscribe(
      (data: any) => {
        this.patient = data.patient_profile[0];

        if(this.patient.bank_support_type){
          this.patient.bank_support_type = true;
        }

        this.profileForm.patchValue({
          profilePic: [this.patient.patient_image],
          dl_number: [this.patient.dl_number],
          fname: this.patient.patient_first_name,
          lname: this.patient.patient_last_name,
          patient_ssn: this.patient.patient_ssn ? (this.show_full_ssn ? this.patient.patient_ssn : ('*****' +
            (this.patient.patient_ssn.slice(+5)))) : '',
          date_of_birth: this.datePipe.transform(this.patient.date_of_birth, 'yyyy-MM-dd') ,
          date_registered: this.datePipe.transform(this.patient.date_registered, 'yyyy-MM-dd'),
          email: this.patient.patient_email,
          mobile: this.patient.patient_mobile,
        });

        if (data.is_error === 0) {
          this.load_notes = false;
          this.patient = data.patient_profile[0];
          this.patient['patient_name'] = this.patient.patient_first_name + ' ' + this.patient.patient_last_name;
          this.using_app = data.using_app;
          this.send_reauth_email = this.patient.send_reauth_email;
          if (this.using_app != null) {
            this.using_app.forEach((element: any) => {
              if (element.device_type == 0) {
                this.webcheckbox = true;
                this.weblogin = new Date(element.last_login);
              } else if (element.device_type == 1) {
                this.androidcheckbox = true;
                this.androidlogin = new Date(element.last_login);
              } else if (element.device_type == 2) {
                this.ioscheckbox = true;
                this.ioslogin = new Date(element.last_login);
              } else {
                this.webcheckbox = false;
                this.weblogin = '';
                this.androidcheckbox = false;
                this.androidlogin = '';
                this.ioscheckbox = false;
                this.ioslogin = '';
              }
            });
          }
          this.resetPaymentForm();
          this.patient_contract = data?.patient_contracts?.length
            ? data.patient_contracts[0]
            : null;
          this.bank_accounts = this.patient.payment_sources.filter((data: any) => data.payment_source_type == 1 )
          this.plaid_accounts = this.patient.plaidAccount || [];
          // this.patient_notes = data.patient_notes;
          if (!this.patient.title) {
            this.patient.title = 'None';
          }
          if (
            this.patient &&
            this.patient.date_of_birth &&
            this.patient.date_of_birth == '0000-00-00'
          ) {
            this.patient.date_of_birth = null;
          }
          // if (this.patient.patient_mobile && this.patient.patient_mobile.substr(0,2) == "+1") {
          //   this.patient.patient_mobile = this.patient.patient_mobile.slice(2, 13)
          // }
          if (this.patient.patient_mobile_country_code) {
            this.patient.patient_mobile_country_code =
              this.patient.patient_mobile_country_code;
          }
          this.newMobile = this.patient.patient_mobile;
          this.selectedZipCode = this.patient.zip;
          if (
            this.patient.verification_ip &&
            this.patient.verification_ip.substr(0, 7) == '::ffff:'
          ) {
            this.patient.verification_ip = this.patient.verification_ip.substr(
              7,
              30
            );
          }
        } else {
          this.patient = {};
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  blockUnblock(block: any) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Please enter a note about your update`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        const params = {
          block_unblock: this.patient.is_blocked ? 0 : 1,
          patient_id: this.patient.patient_id,
          action_type: this.patient.is_blocked == 1 ? 3 : 4,
          note: inputValue,
        };
        this._customerService
          .api(params, 'customer/block_unblock_patient', true)
          .subscribe(
            (data: any) => {
              this.load_notes = false;
              this.patient.is_blocked = !this.patient.is_blocked;
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
      }
    });
  }

  goBack() {
    this.location.back();
  }

  sendVerification() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to send the verification mail to this Customer?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue: any) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let data = {
          patient_id: this.patient.patient_id,
          note: inputValue,
          action_type: 61,
        };
        this._api
          .api(data, 'customer/send_patient_email_verification_link', 'post')
          .subscribe(
            (data: any) => {
              if (data.is_error == 0) {
                this.getPatientInfo(this.patient.patient_id);
              }
              if (data.is_error == 1) {
                if (data.override_text) {
                  this._shared.customPopups(data.override_text, 2);
                } else {
                  this._shared.customPopups(data.is_error, data.flag);
                }
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        // swal.showInputError(' You need to write something! ');
        return false;
      }

      return null;
    });
  }

  sendCredential() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to send the credentials on mail to this Customer?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let data = {
          patient_email: this.patient.patient_email,
          note: inputValue,
          action_type: 60,
        };
        this._api
          .api(data, 'customer/send_credentials_to_patient', 'post')
          .subscribe(
            (data: any) => {
              if (data.is_error == 0) {
                this._toaster.showToaster(
                  'success',
                  'Email sent successfully.',
                  'success'
                );
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        // swal.showInputError(' You need to write something! ');
        return false;
      }

      return null;
    });
  }

  updateEmailPop() {
    this.update.patient_email = this.patient.patient_email;
    // this.updateEmailModal.show();
  }

  // add notes
  addNotes() {
    if (!this.comment || (this.comment && this.comment.trim().length === 0)) {
      this._toaster.showToaster('error', 'Please enter note', 'error');
      return false;
    }
    const data = {
      patient_id: parseInt(this.id),
      note: this.comment,
    };
    if (this.comment) {
      this._api.api(data, 'customer/add_patient_note', 'post').subscribe(
        (response: any) => {
          if (response.is_error === 0) {
            this.getPatientInfo(this.id);
            // $state.reload();
          }
          this.comment = '';
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
    return null;
  }

  fileChangeEvent(event: any) {
    // this.smModal.show();
    this.imageChangedEvent = event;
    const modelRef = this.modalService.show(ProfileImageModalComponent, {
      initialState: {
        modalType: 'pictureModal',
        extra_data: {
          imageChangedEvent: event,
        }
      },
      class: 'modal-dialog-centered',
    })
    modelRef.content?.passEntry.subscribe(resp => {
      if (resp.success) {
        if (resp && resp.croppedImage) {
          this.croppedImage = resp.croppedImage;
          this.updatePatientProfile();
        }
      }
    });
  }
  imageCropped(event: any) {
    console.log(event);
    this.croppedImage = event.base64;
  }

  updatePatientProfile() {
    let data = this.profileForm.value;
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    formData.append('patient_id', this.patient.patient_id);
    if (this.croppedImage) {
      formData.append(
        'profile_image',
        this.dataURLtoFile(this.croppedImage, this.patient.patient_name)
      );
    }
    if (this.profileForm.valid && formData) {
      this._customerService.updatePatientProfile(formData).subscribe(
        (res: any) => {
          this.patient.patient_image = res.patient_profile.patient_image;
          // this.smModal?.hide();
          this.imageUploader = '';
          this._shared.customPopups(res.original_override_text || res.override_text, 3);
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  addNotesModal() {
    this.patient.assign_type = 2;
    let initialState = {
      data: this.patient,
    };
    this._shared.noteadded = false;
    this.bsModalRef = this.modalService.show(AddNotesComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this._shared.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getPatientInfo(this.id);
          count = 1;
        }
      }
    });
  }

  clearAddCardNote() {
    this.addCardNote = null;
  }

  verifyModal() {
    this.patient.verify_type = 2;
    let initialState = {
      data: this.patient,
    };
    this.bsModalRef = this.modalService.show(VerifyComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      // TODO: Pending
      // if (this.mainService.done_verification) {
      //   const _reason = reason ? `, dismissed by ${reason}` : '';
      //   if (count == 0) {
      //     this.getPatientInfo(this.id);
      //     count = 1;
      //   }
      // }
    });
  }

  addPatientCard(token_data: any, addCardModel: any) {
    const resData = {
      stripe_token: null,
      setup_id: token_data.token,
      patient_id: this.patient.patient_id,
      contract_id: this.patient.contract_id,
      payment_source_type: 2,
      is_default: 1,
      pf_id: this.patient.pf_id,
      save_card: token_data.save_card,
      deduct_one_dollar: token_data.deduct_one_dollar,
      action_type: 27,
      note: this.addCardNote,
    };
    this._api.api(resData, 'customer/add_payment_source', 'post').subscribe(
      (data: any) => {
        addCardModel.hide();
        this.getPatientInfo(this.patient.patient_id);
        this.cd.detectChanges();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  GetStripeToken(button_action: any) {
    this.buttonClickedSubject.next(button_action);
  }

  AddStripeElement(element_id: string) {
    // TODO: implements below pending
    // setTimeout(() => {
    //   this.mainService.stripe_element.mount('#' + element_id);
    // }, 1000);
  }

  RemoveStripeElement(element_id: string) {
    // TODO: implements below pending
    // this.mainService.stripe_element.unmount('#' + element_id);
  }

  deleteCard(source_id: any, source_type: any) {
    console.log(source_id, source_type);
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete this ${
            source_type == 1 ? 'bank' : 'card'
          }?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let params = {
          [source_type == 1 ? 'source_id' : 'patient_card_id']: source_id,
          note: inputValue,
          action_type: 'delete_card',
        };
        const api =
          source_type == 1
            ? 'customer/delete_patient_bank'
            : 'customer/delete_patient_card';
        this._api.api(params, api, 'post').subscribe(
          (data: any) => {
            if (data.is_error == 0) {
              this._shared.customPopups('Card deleted succsefully', 3);
              this.getPatientInfo(this.patient.patient_id);
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      } else {
      }
    });
  }

  verifyBank(bank: any) {
    // this.bank_data = bank;
    // this.verifyBankModal.show();

    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.verifyBankForm,
        extra_data: {
          bank_data: bank,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
      }
    });
  }

  DoNotDisturb() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to ${
            this.patient.dnd == 0
              ? 'enable Do Not Disturb'
              : 'disable Do Not Disturb'
          }?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let data = {
          patient_id: this.patient.patient_id,
          is_disturb: this.patient.dnd == 1 ? 0 : 1,
          note: inputValue,
          action_type: this.patient.dnd == 1 ? 29 : 28,
        };
        this._api
          .api(data, 'customer/toggle_do_not_disturb_patient', 'post')
          .subscribe(
            (data: any) => {
              if (data.is_error == 0) {
                this.getPatientInfo(this.patient.patient_id);
                this.patient.dnd = this.patient.dnd ? 0 : 1;
              }
              if (data.is_error == 1) {
                if (data.override_text) {
                  this._shared.customPopups(data.override_text, 2);
                } else {
                  this._shared.customPopups(data.is_error, data.flag);
                }
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        this.patient.dnd = this.patient.dnd ? 1 : 0;
      }
    });
  }

  openStripeBankModal() {
    this.updateAcc['account_type'] = 0;
    this.addStripeBankModal.show();
    this._customerService.modalTypes.updateBankForm;
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.updateBankForm,
        extra_data: {
          verify_link_email: this.patient['patient_email'],
          verify_link_phone: this.patient['patient_mobile']
            ? this.patient['patient_mobile'].split('-')[1]
            : '',
        },
      },
      class: 'modal-dialog-centered',
    });
  }

  stripeResponseHandler(response: any, form: any, modal: any) {
    if (response.id) {
      let params = {
        patient_id: this.patient.patient_id,
        token: response.id,
        action_type: 44,
        note: form.value.notes,
      };
      this._api
        .api(params, 'customer/create_customer', 'post')
        .subscribe((data: any) => {
          form.reset();
          modal.hide();
          this.getPatientInfo(this.patient.patient_id);
        });
    } else {
      this._shared.customPopups('Invalid Account or Routing Number', 2);
    }
  }

  openAddBankLinkModal() {
    console.log("openAddBankLinkModal");

    // this.user_phone1 = this.patient['patient_mobile']
    //   ? this.patient['patient_mobile'].split('-')[1]
    //   : '';
    // this.user_email1 = this.patient['patient_email'];

    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.addBankLinkForm,
        extra_data: {
          patient_mobile_country_code: this.patient.patient_mobile_country_code,
          patient_id: this.patient.patient_id,
          user_phone: this.patient['patient_mobile']
            ? this.patient['patient_mobile'].split('-')[1]
            : '',
          user_email: this.patient['patient_email'],
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
      }
    });
  }

  achModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.appAchForm,
        extra_data: {
          show_ach: this.patient.show_ach,
          patient_id: this.patient['patient_id']
        }
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe(() => {
      this.patient.show_ach = this.patient.show_ach === 1 ? 0 : 1;
      this.patient.bank_support_type = !this.patient.bank_support_type;
    });

  }

  openVerifyBankLinkModal() {
    // this.verify_link_phone = this.patient['patient_mobile']
    //   ? this.patient['patient_mobile'].split('-')[1]
    //   : '';
    // this.verify_link_email = this.patient['patient_email'];

    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.verifyBankForm,
        extra_data: {
          verify_link_email: this.patient['patient_email'],
          verify_link_phone: this.patient['patient_mobile']
            ? this.patient['patient_mobile'].split('-')[1]
            : '',
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
      }
    });
  }

  // fetchPlaidPublicToken(plaid_data) {
  //   const params = {
  //     patient_id: this.patient['patient_id'],
  //     public_token: plaid_data.plaid_access_token,
  //   };
  //   this.mainService.api(params, 'create_link_token_plaid', false).subscribe(
  //     (data: any) => {
  //       if (data && data.is_error == 0) {
  //         plaid_data['plaid_token'] = data.data.link_token;
  //         this.plaidService.openPlaidHandler(plaid_data);
  //       }
  //       if (data.is_error == 1) {
  //         if (data.override_text) {
  //           this.mainService.customPopups(data.override_text, data.is_error);
  //         } else {
  //           this.mainService.flagPopUps(data.flag, data.is_error);
  //         }
  //       }
  //     },
  //     (error: any) => {
  //       console.log(error);
  //     }
  //   );
  // }

  deleteAddress(address_id: string) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete this Alternate Email?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let params = {
          patient_id: this.patient['patient_id'],
          address_id: address_id,
          note: inputValue,
        };
        this._api
          .api(params, 'customer/delete_patient_address', 'post')
          .subscribe(
            (data: any) => {
              if (data.is_error == 0) {
                this._shared.customPopups(
                  'Customer Address Deleted Succesfully',
                  0
                );
                this.fetchCustomerAddressDetails(this.id);
              }
              if (data.is_error == 1) {
                if (data.override_text) {
                  this._shared.customPopups(data.override_text, data.is_error);
                } else {
                  this._shared.customPopups(data.is_error, data.flag);
                }
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      }
    });
  }

  fetchCustomerAddressDetails(id: any) {
    const params = {
      patient_id: id,
    };
    this._api.api(params, 'customer/get_patient_address', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          this.addresses.address_array = data.address;
          this.addresses.count = data.count;
        }
        if (data.is_error == 1) {
          if (data.override_text) {
            this._shared.customPopups(data.override_text, data.is_error);
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  openAddNewAddressModal() {
    // this.addNewAddressModal?.show();
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.addNewAddressForm,
        extra_data: {
          id: this.id,
        },
        patient: this.patient,
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
        this.fetchCustomerAddressDetails(patient_id);
      }
    });
  }

  // getZip(zip_code: any) {
  //   if (zip_code == '') {
  //     this.addresses.add_address.zipcode_list = [];
  //     return false;
  //   }
  //   if (zip_code.length > 3) {
  //     this._shared.getZip(zip_code, 1).then((zip_list) => {
  //       this.addresses.add_address.zipcode_list = zip_list;
  //     });
  //   }
  //   return null;
  // }

  typeaheadOnZip(e: any) {
    if (e[0] && e[0].data) {
      this.addresses.add_address.city = e[0] && e[0].data.city;
      this.addresses.add_address.state = e[0] && e[0].data.state_name;
      this.addresses.add_address.new_zip = e[0] && e[0].data.zip.toString();
      this.addresses.add_address.city_id = e[0] && e[0].data.city_id;
    }
  }

  addNewAddress(form: any, modal: any) {
    if (form && form.value) {
      if (!form.value.address || form.value.address.trim() == '') {
        this._toaster.showToaster(
          'warning',
          'Please enter valid Address',
          'warning'
        );
        return false;
      }
      if (
        !this.addresses.add_address.addAddressNote ||
        this.addresses.add_address.addAddressNote.trim() == ''
      ) {
        this._toaster.showToaster(
          'warning',
          'Please enter valid notes',
          'warning'
        );
        return false;
      }
    }
    const data = {
      patient_id: this.patient['patient_id'],
      city_id: this.addresses.add_address.city_id,
      patient_address: form.value.address.trim(),
      note: this.addresses.add_address.addAddressNote,
    };
    this._api.api(data, 'customer/add_patient_address', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          this._toaster.showToaster(
            'success',
            'Customer Address has been added Successfully.',
            'success'
          );
          modal.hide();
          form.reset();
          // this.getPatientInfo(this.id);
          this.fetchCustomerAddressDetails(this.id);
        }
        if (data.is_error == 1) {
          if (data.override_text || data.err) {
            this._shared.customPopups(
              data.override_text || data.err,
              data.is_error
            );
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return null;
  }

  openMakeDefaultAddressModal(address_id: any) {
    this.addresses.selected_contracts = [];
    this.addresses.update_address.selected_address_id = address_id;
    this.makeDefaultAddressModal?.show();
    const params = {
      patient_id: this.id,
      filter: '1,2,3,4,5',
      offset: 0,
      is_dummy: 0,
      limit: 1000,
    };
    this._api.api(params, 'customer/patient_contracts_v2', 'post').subscribe(
      (data: any) => {
        if (data && data.is_error == 0) {
          this.addresses.contracts = data.data;
        }
        if (data.is_error == 1) {
          if (data.override_text || data.err) {
            this._shared.customPopups(
              data.override_text || data.err,
              data.is_error
            );
          } else {
            this._shared.customPopups(data.is_error, data.flag);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );

    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.makeDefaultAddressForm,
        extra_data: {
          addresses: this.addresses,
          id: address_id,
        },
      },
      class: 'modal-dialog-centered',
    });
  }

  openAddAlternateEmailModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.addAlternateEmailForm,
        extra_data: {
          alternate_email: this.patient['patient_email'],
          patient_id: this.patient['patient_id'],
          note: this.patient['note'],
        },
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(() => {
      this.fetchAlternateEmails(this.patient['patient_id']);
    });
  }

  deleteAlternateEmail(pae_id: any) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete this Alternate Email?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue: any) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;
      if (inputValue) {
        let params = {
          patient_id: this.patient['patient_id'],
          pae_id: pae_id,
          note: inputValue,
        };
        this._api
          .api(params, 'customer/delete_patient_alternate_email', 'post')
          .subscribe(
            (data: any) => {
              if (data.is_error == 0) {
                // this.getPatientInfo(this.id);
                this._toaster.showToaster(
                  'success',
                  'Alternate email deleted successfully.',
                  'success'
                );
                this.fetchAlternateEmails(this.patient['patient_id']);
              }              
            },
            (error: any) => {
              console.log(error);
            }
          );
      }
    });
  }

  fetchAlternatePhones(id: any) {
    const params = { patient_id: id };
    this._api
      .api(params, 'customer/patient_alternate_mobiles_list', 'post')
      .subscribe(
        (data: any) => {
          if (data && data.is_error == 0) {
            this.alternate_phones.phones = data.alternate_mobiles;
            this.alternate_phones.phones.forEach((o: any) => {
              o.alternate_mobile =
                o.alternate_mobile.slice(0, 6) +
                '-' +
                o.alternate_mobile.slice(6, 9) +
                '-' +
                o.alternate_mobile.slice(9, 13);
            });
            this.alternate_phones.count = data.count;
          }
          if (data.is_error == 1) {
            if (data.override_text || data.err) {
              this._shared.customPopups(
                data.override_text || data.err,
                data.is_error
              );
            } else {
              this._shared.customPopups(data.is_error, data.flag);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  openAddAlternatePhoneModal() {

    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.addAlternatePhoneForm,
        extra_data: {
          patient_mobile_country_code:
            this.patient?.patient_mobile_country_code,
          alternate_mobile: this.patient?.patient_mobile,
          patient_id: this.patient?.patient_id,
          note: this.alternate_phones?.addPhoneNote,
        },
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(() => {
        this.fetchAlternatePhones(this.patient?.patient_id);
    })
  }

  openReauthenticateLinkModal() {

    this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.bankLinkForm,
        extra_data: {
          reauth_link_phone: this.patient['patient_mobile']
            ? this.patient['patient_mobile'].split('-')[1]
            : '',
          reauth_link_email: this.patient['patient_email'],
        },
      },
      class: 'modal-dialog-centered',
    });
  }

  deleteAlternatePhone(pam_id: any) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete this Alternate Phone Number?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;
      if (inputValue) {
        let params = {
          patient_id: this.patient['patient_id'],
          pam_id: pam_id,
          note: inputValue,
        };
        this._api
          .api(params, 'customer/delete_patient_alternate_mobile', 'post')
          .subscribe(
            (data: any) => {
              if (data.is_error == 0) {
                // this.getPatientInfo(this.id);
                this.fetchAlternatePhones(this.id);
              }
              if (data.is_error == 1) {
                if (data.override_text) {
                  this._shared.customPopups(data.override_text, data.is_error);
                } else {
                  this._shared.customPopups(data.is_error, data.flag);
                }
              } else {
                this._toaster.showToaster(
                  'success',
                  'Alternate Phone Number deleted Successfully.',
                  'success'
                );
                this.modalService.hide(this.modelRef.id);
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      }
    });
  }

  dummyLive() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to make this Customer ${
            this.patient.is_dummy == 1 ? 'live' : 'dummy'
          }?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let data = {
          is_dummy: this.patient.is_dummy == 1 ? 0 : 1,
          patient_id: this.patient.patient_id,
          action_type: this.patient.is_dummy == 1 ? 1 : 2,
          note: inputValue,
        };
        this._api.api(data, 'customer/dummy_live_patient', 'post').subscribe(
          (data: any) => {
            this.load_notes = false;
            this.patient.is_dummy = this.patient.is_dummy == 1 ? 0 : 1;
          },
          (error: any) => {
            console.log(error);
          }
        );
      } else {
        this.patient.is_dummy = this.patient.is_dummy == 1 ? 1 : 0;
      }
    });
  }

  onFileInputClick(e: any) {
    // TODO: Pending below
    // this.fileUpload.nativeElement.value = '';
  }

  fileChangeEventDL(event: any) {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files;
      if (file[0].size <= 0) {
        this._toaster.showToaster('error', 'Invalid file.', 'error');
        return false;
      }

      if (
        file[0].type !== 'image/png' &&
        file[0].type !== 'application/pdf' &&
        file[0].type !== 'image/jpeg' &&
        file[0].type !== 'image/jpg'
      ) {
        this._toaster.showToaster(
          'error',
          'File type not supported. Please upload a valid file(.pdf, .jpeg, .png).',
          'error'
        );
        return false;
      }

      if (file[0].size > 10485760) {
        this._toaster.showToaster(
          'error',
          'File size must be less than 10MB.',
          'error'
        );
        return false;
      }

      if (file[0].type === 'application/pdf') {
        this.upload(file[0]);
      } else {
        if (
          file[0].type === 'image/png' ||
          file[0].type === 'image/jpeg' ||
          file[0].type === 'image/jpg'
        ) {
          const image: File = file[0];
          this.loaderService.showLoader();
          this.compressImage
            .compress(image)
            .pipe(take(1))
            .subscribe((compressedImage) => {
              this.upload(compressedImage);
            });
        }
      }
    }
    return null;
  }

  uploadedProfileImage(file: any) {
    if (
      file[0].type === 'image/png' ||
      file[0].type === 'image/jpeg' ||
      file[0].type === 'image/jpg'
    ) {
      const image: File = file[0];
      this.compressImage
        .compress(image)
        .pipe(take(1))
        .subscribe((compressedImage) => {
          this.upload(compressedImage);
        });
    } else {
      this._shared.customPopups('Please select valid file', 1);
    }
  }

  upload(file: any) {
    if (file) {
      let formData = new FormData();
      formData.append('dl_doc', file);
      formData.append('patient_id', this.patient.patient_id);
      this._api.uploadApi(formData, 'upload_cus_dl', false).subscribe(
        (data: any) => {
          if (data.is_error == 1) {
            if (data.original_text) {
              this._shared.customPopups(data.original_text, data.is_error);
            } else {
              this._shared.customPopups(data.flag, data.is_error);
            }
          } else if (data && data.data && data.data.url) {
            this.patient.profile_image = data.data.url;
            this._shared.customPopups(
              data.original_text
                ? data.original_text
                : 'Profile image uploaded successfully!',
              0
            );
          } else {
            this.getPatientInfo(this.id);
          }
        },
        (error: any) => {
          console.log('error');
        }
      );
    } else {
      this._shared.customPopups('Please select valid file', 1);
    }
  }

  // if (file) {
  //   let formData = new FormData();
  //   formData.append('dl_doc', file);
  //   formData.append('patient_id', this.patient.patient_id);
  //   formData.append('access_token', this._local.customGetItem('adminToken'));
  // this._shared.uploadApi(formData, 'upload_cus_dl', false).subscribe(
  //   (data: any) => {
  //     if (data.is_error === 1) {
  //       if (data.override_text) {
  //         this._shared.customPopups(data.override_text, data.is_error);
  //       } else {
  //         this._shared.customPopups(data.is_error, data.flag);
  //       }
  //     } else if (data && data.data && data.data.url) {
  //       this.patient.dl_doc = data.data.url;
  //       this.patient.dl_id = data.data.id;
  //       this._toaster.showToaster(
  //         'success',
  //         data.override_text
  //           ? data.override_text
  //           : 'Document uploaded successfully',
  //         'success'
  //       );
  //     } else {
  //       this.getPatientInfo(this.id);
  //     }
  //   },
  //   (error: any) => {
  //     console.log('error');
  //   }
  // );
  // } else {
  //   this._toaster.showToaster('error', 'Please select valid file', 'error');
  // }
  //}

  deleteUploadedDoc(doc_id: any) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete the uploaded Proof of ID?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((isConfirm) => {
      if (isConfirm) {
        let params = {
          doc_id: doc_id,
          patient_id: this.id,
        };
        this._api.api(params, 'customer/delete_cus_dl', 'post').subscribe(
          (data: any) => {
            if (data.is_error == 0) {
              this.load_notes = false;
              this.patient.dl_doc = null;
              this._toaster.showToaster(
                'success',
                data.override_text
                  ? data.override_text
                  : 'Document deleted successfully',
                'success'
              );
            }

            if (data.is_error == 1) {
              if (data.flag == 584) {
                this.patient.dl_doc = null;
                this.patient.dl_id = null;
              }
              if (data.override_text) {
                this._shared.customPopups(data.override_text, data.is_error);
              } else {
                this._shared.customPopups(data.is_error, data.flag);
              }
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    });
  }

  deleteBankAccount() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to send the verification mail to this Customer?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue) {
        let data = {
          patient_id: this.patient.patient_id,
          note: inputValue,
          action_type: 62,
          admin_id: JSON.parse(this._local.customGetItem('profileData'))[0][
            'admin_id'
          ],
        };
        this._api.api(data, 'customer/delete_bank_account', 'post').subscribe(
          (data: any) => {
            if (data.is_error == 0) {
              this.getPatientInfo(this.patient.patient_id);
            }
            if (data.is_error == 1) {
              if (data.override_text) {
                this._shared.customPopups(data.override_text, 1);
              } else {
                this._shared.customPopups(data.flag, data.is_error); // flagPopUps
              }
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      } else {
        // swal.showInputError(' You need to write something! ');
        return false;
      }

      return null;
    });
  }

  changePaymentMethods() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.changePaymentSettingsForm,
        extra_data: {},
        patient: this.patient,
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
      }
    });
  }

  resetPaymentForm() {
    this.patient_settings.show_google_pay = this.patient.show_google_pay
      ? true
      : false;
    this.patient_settings.show_apple_pay = this.patient.show_apple_pay
      ? true
      : false;
    this.patient_settings.paymentNote = '';
    this.cd.detectChanges();
  }

  // /* Get Ticket Comments */
  latestTicketComment: any = [];
  idExist = false;
  getTicketComments(id: any) {
    this.idExist = false;
    this.latestTicketComment.forEach((ele: any) => {
      if (ele.id === id) {
        this.idExist = true;
      }
      return false;
    });
    if (!this.idExist) {
      let params = { req_id: id };
      this._api.api(params, 'assignments/csr_request_details', 'get').subscribe(
        (data: any) => {
          this.latestTicketComment.push({
            id: id,
            notes: data.notes,
          });
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
    return null;
  }

  // open ticket dropdown state
  dpOpen: boolean = false;
  onOpenChange(data: boolean): void {
    this.dpOpen = data ? true : false;
  }

  resetReAuthEmailForm(form: any, modal: any) {
    modal.hide();
    form.reset();
  }

  fullSSNModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.fullSSNForm,
        extra_data: {
          patient_id: this.patient.patient_id,
        },
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(() => {
      this.show_full_ssn = true;
      this.profileForm.patchValue({
        patient_ssn: this.show_full_ssn ? this.patient.patient_ssn : ('*****' +
          (this.patient.patient_ssn.slice(+5))),
      });
    });
  }

  approveModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.appDisappForm,
        extra_data: {},
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
      }
    });
  }

  openAchModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.appAchForm,
        extra_data: {},
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((patient_id) => {
      if (patient_id) {
        this.getPatientInfo(patient_id);
      }
    });
  }

  activateContractModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customerService.modalTypes.appContractForm,
        patient: this.patient,
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((res) => {
      if (res.success) {
        this.getPatientInfo(this.patient.patient_id);
      }
    });
  }

  fetchAlternateEmails(id: any) {
    const params = { patient_id: id };
    this._api
      .api(params, 'customer/patient_alternate_emails_list', 'post')
      .subscribe(
        (data: any) => {
          if (data && data.is_error == 0) {
            this.alternate_emails.emails = data.alternate_emails;
            this.alternate_emails.count = data.count;
          }
          if (data.is_error == 1) {
            if (data.override_text || data.err) {
              this._shared.customPopups(
                data.override_text || data.err,
                data.is_error
              );
            } else {
              this._shared.customPopups(data.is_error, data.flag);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  addCardModal() {
    const modelRef = this.modalService.show(GenerateStripeElementComponent, {
      initialState: {
        stripeData: {
          patient_profile: this.patient,
          payment_source_type: this._shared.paymentSourceType.CARD,
          currency_data: this.currencyData,
          // contract_id: this.contract.contract_id,
          // pf_id: this.contract.pf_id
        }
      },
      class: 'modal-dialog-centered modal-lg px-4 py-2'
    })

    modelRef.content?.returnStripeData.subscribe(stripeResp => {
      if (stripeResp.success) {
        modelRef.hide();
        this.getPatientInfo(this.patient.patient_id)
        // this.getPaymentSourcesData();
      }
    })
  }

  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  // verifyBankModal() {
  //   const modelRef = this.modalService.show(CustomerModalComponent, {
  //     initialState: {
  //       modalType: this._customerService.modalTypes.verifyBankForm,
  //       extra_data: {},
  //     },
  //     class: 'modal-dialog-centered',
  //   });
  //   modelRef.content?.passEntry.subscribe((patient_id) => {
  //     if (patient_id) {
  //       this.getPatientInfo(patient_id);
  //     }
  //   });
  // }
}
