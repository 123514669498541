import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-payment-sources',
  templateUrl: './payment-sources.component.html',
  styleUrl: './payment-sources.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule]
})

export class PaymentSourcesComponent implements OnInit {
  source_id: string = '';
  payment_source_type!: number;
  @Input() contract!: contract;
  @Input() checkDisableAction: boolean = false;
  @Output() sendData = new EventEmitter();
  ngOnInit(): void {
    console.log(this.contract);
    this.source_id = '';
    this.payment_source_type = 0;
  }

  callFunction(card: paymentSourceType) {
    this.source_id=card.source_id;
    this.payment_source_type=card.payment_source_type;
    this.sendData.emit({source_id: this.source_id, payment_source_type: this.payment_source_type});
  }
}

interface contract {
  bank_accounts: paymentSourceType[],
  show_ach: number,
  payment_sources: paymentSourceType[],
  source_id: string,
  disable_action: [],
  next_payment_date: string
}

interface paymentSourceType {
  brand: string,
  last4: string,
  payment_source_type: number,
  source_id: string,
  bank_status: string,
  last_error: string,
  is_default: number
}