import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, TimeoutError, of, throwError } from "rxjs";
import { catchError, map, timeout, retry } from 'rxjs/operators';
// import { Intercom } from 'ng-intercom';
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LoaderService } from "./loader/loader.service";
import { environment } from "src/environments/environment";
import { localStorageService } from "./localstorage.service";
import { ToasterService } from "./toaster/toaster.service";

@Injectable()
export class ApiService {
  headers: any = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' });
  headersObject: any = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' });
  timeoutError: boolean = false;
  deviceInfo = {
    device_info: this.device_info()
  };
  constructor(private _toaster: ToasterService, public http: HttpClient, private _local: localStorageService,
    private router: Router, private _loader: LoaderService) { }
  api(_data: any, apiName: any, method: string = 'post'): Observable<any> {
    _data['country_id'] = this._local.customGetItem('country_id');
    let apiData: any = {};

    Object.keys(_data).filter(key => {
      if (_data[key] || _data[key] == 0) {
        apiData[key] = _data[key];
      }
    });
    if (method != 'get') {
      apiData = { ...apiData, ...this.deviceInfo }
    }

    let hitting_url = environment.baseUrl + apiName;

    let output;
    if (method === 'get') {
      output = this.http.get<any>(hitting_url, { headers: this.headers, params: apiData });
    } else if (method === 'put') {
      output = this.http.put<any>(hitting_url, apiData, { headers: this.headers });
    } else if (method === 'object') {
      output = this.http.post<any>(hitting_url, apiData, { headers: this.headersObject });
    } else {
      output = this.http.post<any>(hitting_url, apiData, { headers: this.headers });
    }
    this.hideToastr();
    this._loader.showLoader();
    return output
      .pipe(
        timeout(60000),
        retry(3),
        map((data: any) => {
          this._loader.hideLoader();
          if (data.flag === 500 || data.flag === 401) {
            this.customPopups(data.original_text, 1);
            this._local.customClearAllItems();
            this.loginRedirect();
            return false;
          }
          if (data && data.original_text) {
            this.customPopups(data.original_text || data.err, 1);
            // return false;
          }
          return data;
        }),
        catchError((error: any) => {
          this._loader.hideLoader();
          if (error instanceof TimeoutError) {
            if (!this.timeoutError) {
              this.timeoutError = true;
            }
            return throwError(() => { error: 'Timeout Exception' });
          }
          return throwError(() => error);
        })
      );
  }

  uploadApi(_data: any, apiName: any, options: any = {}): Observable<any> {
    let api_url = environment.baseUrl + apiName;
    if (!api_url) {
      api_url = environment.baseUrl;
    }
    this.hideToastr();
    this._loader.showLoader();
    return this.http.post<any>(`${api_url}`, _data, options)
      .pipe(
        timeout(60000),
        map((data: any) => {
          this._loader.hideLoader();
          if (data.flag === 377) {
            this.loginRedirect();
            return false;
          }
          if (data.flag === 4) {
            this.loginRedirect();
            if (data && data.original_text) {
              this.customPopups(data.original_text, 1);
            }
            return false;
          }
          if (data) {
            if (data.err || data.original_text) {
              if (data.original_text) {
                this.customPopups(data.original_text, 1);
              } else {
                this.customPopups(data.err, 1);
              }
            } else {
              // this.customPopups(data.flag, data.original_text);
            }
          }
          return data;
        }),
        catchError((error: any) => {
          this._loader.hideLoader();
          if (error instanceof TimeoutError) {
            if (!this.timeoutError) {
              this.timeoutError = true;
              this.customPopups('Request Timeout', 1);
            }
            return throwError({ error: 'Timeout Exception' });
          }
          if (error.status === 0) {
            if (!this.timeoutError) {
              this.customPopups('Unable to fetch data from server', 1);
            }
          }
          return throwError(error);
        })
      )
  }

  deleteServerFile(file: any, api_name: string) {
    let params = {
      url: file
    }
    return this.http.post(environment.baseUrl + api_name, params)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return error;
        })
      )
  }

  loginRedirect() {
    this._local.customClearAllItems();
    // this.intercom.shutdown();
    this.router.navigate(['login']);
    window.location.reload();
  }

  device_info() {
    var window: any = window;
    var document: any = document;
    // Opera 8.0+
    const isOpera = navigator.userAgent.indexOf(' OPR/') >= 0 || navigator.userAgent.indexOf("Opera") >= 0;
    // Firefox 1.0+
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // Edge 20+
    const isEdge = navigator.userAgent.indexOf("Edge") > -1;
    // Internet Explorer 6-11
    const isIE = typeof document !== 'undefined' && !!document.documentMode && !isEdge;
    // Chrome 1 - 71
    const isChrome = !isOpera && !isEdge && !isSafari;
    // Blink engine detection
    const isBlink = (isChrome || isOpera) && (window && !!window.CSS);
    const browserInfo = {
      isFirefox,
      isChrome,
      isSafari,
      isOpera,
      isIE,
      isEdge,
      isBlink
    };
    const platform = navigator && navigator.platform ? navigator.platform : 'unknown';
    const userAgent = navigator && navigator.userAgent ? navigator.userAgent : 'unknown';
    return {
      browser: browserInfo,
      platform,
      userAgent
    };
  }

  downloadApi(data: any, url: any, view: any) {
    let config = {
      // headers: this.headers,
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
      params: data
    };
    let api_url = environment.baseUrl;
    this._loader.showLoader();
    return this.http.get<any>(`${api_url}${url}`, config).pipe(
      timeout(60000),
      map((data: any) => {
        this._loader.hideLoader();
        if (!data.is_error) {
          let headers = data.headers.get('Content-Disposition');
          if (!headers) {
            throw new Error('Something went wrong, please try again later');
          }
          let fileName = this.extractFirstText(headers);
          data = data.body;
          let blob = new Blob([data], { type: 'application/pdf' });
          let urlwindow = window.URL.createObjectURL(blob);
          if (view == 0) {
            let anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = urlwindow;
            anchor.click();
          } else if (view == 1) {
            let pwa = window.open(urlwindow);
            if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
              alert("Please disable your browser's pop-up blocker and try again.");
            }
          }
          else if (view == 2) {
            let w: any = window.open(urlwindow, "", "width=800,height=800");
            if (!w || w.closed || typeof w.closed == 'undefined') {
              alert("Please disable your browser's pop-up blocker and try again.");
            }
            if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
              w.location.reload();
            }
            w.focus();
            w.print();
          }
        } else {
          this.customPopups(data.original_text, 1);
          return;
        }
      }),
      catchError((error: any) => {
        this._loader.hideLoader();
        if (error instanceof TimeoutError) {
          if (!this.timeoutError) {
            this.timeoutError = true;
            this.customPopups('Request Timeout', 1);
          }
          throw new Error('Request Timeout');
        }
        if (error.status == 0) {
          if (!this.timeoutError) {
            this.customPopups('Unable to fetch data from server', 1);
          }
        }
        this.customPopups(error, 1);
        throw new Error(error);
      })
    );
  }

  customPopups(message: string, flag: Number) {
    if (flag == 1) {
      this._toaster.showToaster('Error', message, 'error');
    } else if (flag == 2) {
      this._toaster.showToaster('Warning', message, 'warning');
    } else {
      this._toaster.showToaster('Success', message, 'success');
    }
  }

  hideToastr() {
    this._toaster.hideAllToasters();
  }

  extractFirstText(str: any) {
    const matches = str && str.match(/"(.*?)"/);
    return matches ? matches[1] : str;
  }


}

