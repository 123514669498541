import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ApiService } from '../api.service';
import { MainService } from '../main.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})
export class AddNotesComponent implements OnInit {
  statusClosed = true;
  public data: any;
  notes: any = {};
  typeaheadLoading: boolean = false;
  assigned_id: any;
  minDate: Date = new Date();
  note: any;

  images: any = [];
  selectedFile: any;
  uploading: boolean = false;
  fileSubscription: any[] = [];
  imageURLs: any = [];
  fileProgress: any = [];
  @ViewChild('imageElement') imgElement!: ElementRef; 
  hours = [{ value: "00" }, { value: "01" }, { value: "02" }, { value: "03" }, { value: "04" }, { value: "05" }, { value: "06" }, { value: "07" }, { value: "08" }, { value: "09" }, { value: "10" }, { value: "11" }, { value: "12" }, { value: "13" }, { value: "14" }, { value: "15" }, { value: "16" }, { value: "17" }, { value: "18" }, { value: "19" }, { value: "20" }, { value: "21" }, { value: "22" }, { value: "23" }];
  minutes = [{ value: "00" }, { value: "15" }, { value: "30" }, { value: "45" }];
  contactNumber = "";
  timezoneName: string = '';
  allResultTypeArr: any;
  result_of_contact_arr: any;
  type_of_call_arr: any;
  public timezone: string = "";
  public globalAdminList: Array<[]> = [];

  bsConfig: Partial<BsDatepickerConfig>;
  constructor(
    public bsModalRef: BsModalRef,
    public mainService: MainService,
    // public doctorService: DoctorsService,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private _http: HttpClient,
    public _shared: SharedService,
    private _api: ApiService
  ) {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement:'bottom'
    });
  }

  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setHours(0, 0, 0, 0);
    this.notes['followup_date'] = this.minDate;
    this.notes.followup_hour = null;
    this.notes.followup_minutes = null;
    this.notes.status = '0';
    this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
      if (isDummy >=0 || (selected_country && selected_country.length > 0)) {
        this.timezone = timezone;
      }
      this.timezoneName = this._shared.getTimezoneName();
    });
    switch (this.data.assign_type) {
      case 1:
        if (this.data.csr_requests.length > 0) {
        }
        break;
      case 2: break;
      case 3: break;
      case 4:
        if (this.data && this.data.csr_notes && this.data.csr_notes.length > 0) {
        }
        break;
    }

    this.globalAdminList = this._shared.getAdminList();
    this.notes.contact_type = this.data.contact_type;
    this.notes.contact_result = this.data.contact_result;
    this.getContactType();
    this.getResultType();
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  onChangeStatus(event: any) {
    const status: Number = event?.target?.value;
    if (status == 1) {
      this.statusClosed = false;
    } else {
      this.statusClosed = true;
    }
  }

  handleAddNotes() {
    let note: any = {
      note: this.note,
      contact_type: this.notes.contact_type,
      contact_result: this.notes.contact_result,
      assigned_id: this.notes.assigned_id,
      followup_date: this.datePipe.transform(this.notes.followup_date, 'yyyy-MM-dd'),
      customer_status: this.notes.customer_status,
      priority: this.notes.priority,
      status: this.notes.status,
      is_dummy: 1,
      action_type: 'financing_type_changed',
      contact_number: this.contactNumber,
      doctor_id: '',
      patient_id: '',
      partner_id: '',
      contract_id: '',
      request_type: '',
      pp_id: '',
      split_token: '',
      preapproval_lead_id: '',
      docs_urls: []
    };
    if (!note.assigned_id) {
      this._shared.customPopups('Please select valid assignee', 2);
      return false;
    }
    if (!note.priority) {
      this._shared.customPopups('Please select priority type', 2);
      return false;
    }
    if (this.data.assign_type == 1) {
      note['doctor_id'] = this.data.doctor_profile[0].doctor_id;
    }
    if (this.data.assign_type == 2) {
      note['patient_id'] = this.data.patient_id;
    }
    if (this.data.assign_type == 3) {
      note['partner_id'] = this.data.partner_id;
    }
    if (this.data.assign_type == 4) {
      note['contract_id'] = this.data.contract_info[0].contract_id;
      note['request_type'] = this.notes.request_type;
    }
    if (this.data.assign_type == 5) {
      note['pp_id'] = this.data.id;
    }
    if (this.data.assign_type == 6) { //proposal request
      note['split_token'] = this.data.proposal_info[0].contract_patient_token;
    }
    if (this.data.assign_type == 7) { //Preapproval leads
      note['preapproval_lead_id'] = this.data.preapproval_lead_id;
      note['action_type']='preapproval_business_leads';
    }
    if (this.images && this.images.length > 0) {
      note['docs_urls'] = this.images.map((x: any) => x.image);
    }
    if (this.uploading) {
      this._shared.customPopups('Please wait while files are uploading', 2);
      return false;
    }
    if (this.notes.status != 1 && (!this.notes.followup_hour || !this.notes.followup_minutes)) {
      this._shared.customPopups('Please select a valid followup time', 2)
      return false;
    }

    if (!this.note || this.note.trim().length == 0) {
      this._shared.customPopups('Please enter a valid note', 2);
      return false;
    }

    if (this.contactNumber && this.contactNumber.length!=10) {
      this._shared.customPopups('Please enter a valid Dialed number', 1);
      return false;
    }

    if (this.notes.followup_hour && this.notes.followup_minutes) {
      let time = new Date().getTimezoneOffset();
      let hours = Math.abs(Math.trunc(time / 60));
      let minutes = ("0" + time % 60).slice(-2);
      let user_timezone = '';
      if (time > 0) {
          user_timezone = "-" + hours + minutes;
      } else {
          user_timezone = "+" + hours + minutes;
      }
      let today_time = this._shared.getfollowupDateTimeInUTCToday(new Date(), user_timezone)
      let today_time_1 = new Date(today_time).getTime();
      let follow_up = this._shared.getfollowupDateTimeInUTC(note.followup_date + ` ${this.notes.followup_hour}:${this.notes.followup_minutes}:00`, this.timezone);
      let follow_up_time = new Date(follow_up).getTime();
      if (today_time_1 >= follow_up_time) {
          this._shared.customPopups('Please select future time for followup', 2);
          return false;
      } else {
          note.followup_date = follow_up;
      }
    }
    
    for (let key in note) {
      if (note[key] === '') {
        delete note[key];
      }
    }

    this._api.api(note, 'assignments/add_csr_request')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.bsModalRef.hide();
          // this.doctorService.noteadded = true;
          this.mainService.noteadded = true;

        }
      }, (error: any) => {
        console.log(error);
      })
      return;
  }

  selectMood(mood: any) {
    if (mood == 1) {
      this.notes.customer_status = 1;
    } else if (mood == 2) {
      this.notes.customer_status = 2;
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onAdminSelect(event: any) {
    this.notes.assigned_id = event.item.admin_id;
  }

  selectAdmin(event: any) {
    this.notes.assigned_id = event;
  }

  adminClear(event: any) {
    if (event.length == 0) {
      this.notes.assigned_id = '';
    }
  }

  fileSelect(event: any) {
    if (this.images && this.images.length > 10) {
      this._shared.customPopups('Can not upload more than 10 files', 2);
      return false;
    }
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      for (let file of event.target.files) {
        let selectedFile = { file: file };
        if ((selectedFile.file.size / 1024 / 1024) > 10) {
          this._shared.customPopups('File size must be less than 10MB', 2);
          return false;
        }
        if (selectedFile.file.type.indexOf('image/') === 0 || selectedFile.file.type.indexOf('application/') === 0) {
          let index = this.images.findIndex((x: any) => x.file.mediaFile.name == selectedFile.file.name);
          const reader = this.readFiles(selectedFile);
          if (index < 0) {
            this.images.push({ file: reader });
            this.uploadFile(reader, this.images.length - 1);
            this.imgElement.nativeElement.value = '';
          }
        } else {
          this._shared.customPopups('Please select valid file', 2);
        }
      }
    }
    return;
  }

  readFiles(file: any) {
    file.mediaFile = file.file;
    file.file = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.mediaFile));
    return file;
  }


  uploadFile(item: any, index?: any) {
    this.uploading = true;
    this.images[index].uploading = true;
    const formData = new FormData();
    formData.append('file', item.mediaFile);
    this.images[index].uploadSub = this._api.uploadApi(formData, 'assignments/upload_csr_doc', {
      reportProgress: true,
      observe: 'events'
    }).pipe(
        map((event: HttpEvent<any>) => {
          let response = [];
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              const percentDone = Math.round(100 * event.loaded / event.total);
              response.push({ status: 'progress', message: percentDone });
            }
          }
          if (event.type === HttpEventType.Response) {
            response.push(event.body);
          }
          return response;
        })
      ).subscribe(d => {
        this.images[index].subs = d;
        this.fileSubscription = d;
        let success = d.every((item: any) => {
          return item.status !== 'progress'
        });
        if (success) {
          this.uploading = false;
          this.images[index].uploading = false;
          d.forEach((item: any) => {
            this.imageURLs.push(item.images[0]);
            this.images[index].image = item.images[0];
          })

        } else {
          this.uploading = true;
          this.images[index].uploading = true;
        }
      });
  }

  deletFile(file: any, index: any) {
    this._api.deleteServerFile(file, 'delete_hard_request_doc')
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          this.images.splice(index, 1);
        }
      })
  }

  cancelImageUpload(file: any, index: any) {
    this.images[index].uploadSub.unsubscribe();
    this.images[index].uploading = false;
    this.images.splice(index, 1);
    this.images.every((item: any) => this.uploading = item.uploading);
  }

  getContactType() {
    const body = {
      // access_token: MainService.customGetItem('adminToken'),
    };
    this._api.api(body, 'assignments/get_contact_types', 'get').subscribe(contactTypes => {
      if (!contactTypes.is_error) {
        if (contactTypes.data.length) {
          this.type_of_call_arr = contactTypes.data;
        }
      }
    });
  }

  getResultType() {
    const body = {
      // access_token: MainService.customGetItem('adminToken'),
    };
    this._api.api(body, 'assignments/get_result_of_contact', 'get').subscribe(resultsOfContact => {
      if (!resultsOfContact.is_error) {
        if (resultsOfContact.data.length) {
          this.allResultTypeArr = resultsOfContact.data;
          this.result_of_contact_arr = this.allResultTypeArr[0].contact_data;
          if (!this.notes.contact_result) {
            this.notes.contact_result = this.result_of_contact_arr[0].result_id;
          }
          // tslint:disable-next-line:max-line-length
          this.filterResult();
        }
      }
    });
  }

  filterResult() {
    if (this.notes.contact_type) {
      const result_of_contact = this.allResultTypeArr.filter((result: any) => result.contact_id == this.notes.contact_type);
      this.result_of_contact_arr = result_of_contact[0] && result_of_contact[0].contact_data;
    } else { // default values
      this.notes.contact_type = 0;
      this.notes.contact_result = 0;
    }
  }

  updateField(key: string, value: any) {
    this.notes[key] = Number(value.target.value);
    if (key === 'type_of_call') {
      const result_of_contact = this.allResultTypeArr.filter((result: any) => result.contact_id == value.target.value)[0];
      this.result_of_contact_arr = result_of_contact && result_of_contact.contact_data;
      if (!this.notes.contact_result || value.target.value === 9) {
        this.notes.contact_result = this.result_of_contact_arr[0].result_id;
      }
    }
  }
}
