import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { DebtRecoveryService } from '../debt-recovery.service';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/components/shared/shared.service';
import { NgxMaskDirective } from 'ngx-mask';
import { ApiService } from 'src/app/components/shared/api.service';
import * as momentTz from 'moment-timezone';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSelectModule } from 'ngx-select-ex';
import { localStorageService } from 'src/app/components/shared/localstorage.service';

@Component({
  selector: 'app-debt-recovery-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BsDatepickerModule, NgxSelectModule, NgxMaskDirective],
  templateUrl: './debt-recovery-modal.component.html',
  styleUrl: './debt-recovery-modal.component.scss'
})
export class DebtRecoveryModalComponent {
  bsConfig: Partial<BsDatepickerConfig>;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  modalType = '';
  min_date = new Date(new Date().setDate(new Date().getDate() + 1));
  reasonsList:any; 
  public extra_data!: modal_data;
  public resend_ar_data !: resend_ar_proposal_data;
  public details_extra_data!: details_modal_data;
  public payment_extra_data!: payment_modal_data;
  public expiry_extra_data!: expiry_modal_data;
  public resendLinkForm!: UntypedFormGroup;
  public detailsForm!: UntypedFormGroup;
  public paymentSettingsForm!: UntypedFormGroup;
  public editExpiryDateForm!: UntypedFormGroup;
  public resend_ar_form!: UntypedFormGroup;
  public deletePaymentInfoForm!: UntypedFormGroup;
  public sendReceiptLinkForm!: UntypedFormGroup ; 
  changesettings: boolean = true;
  public deleteProposalform!: UntypedFormGroup;
  timezone = this._shared.getTimezone();
  constructor(public _debtRecovery: DebtRecoveryService, private _fb: UntypedFormBuilder, private bsModalRef: BsModalRef, public _shared: SharedService, private _api: ApiService,  public changeDetect: ChangeDetectorRef,public _local:localStorageService
  ) {
    this.bsConfig = Object.assign({}, {  containerClass: 'theme-blue', placement:'bottom', selectFromOtherMonth: true });
  }

  ngOnInit(): void {
    if (this.modalType == this._debtRecovery.modalTypes.resendLinkModal){
      this.resendLinkForm = this._fb.group({
        user_email: [ this.modalType === this._debtRecovery.modalTypes.sendReceiptLinkModal? this.extra_data.paymentProcessInfo.patient_email : '', Validators.required],
        user_phone: ['', Validators.required]
      });
    }

    if (this.modalType == this._debtRecovery.modalTypes.detailsModal){
      this.detailsForm = this._fb.group({
        // user_email: [ this.modalType === this._debtRecovery.modalTypes.sendReceiptLinkModal? this.extra_data.paymentProcessInfo.patient_email : '', Validators.required],
        // user_phone: ['', Validators.required]
      });
    }

    if (this.modalType == this._debtRecovery.modalTypes.PaymentSettingsModal){
      this.paymentSettingsForm = this._fb.group({
        primary_type: ['', Validators.required],
        backup_type: ['', Validators.required],
        backup_required: ['', Validators.required],
        recurring_type: ['', Validators.required],
        skip_overdue_check: ['', Validators.required]
      });
    }

    if (this.modalType == this._debtRecovery.modalTypes.editExpiryDateModal){
      this.editExpiryDateForm = this._fb.group({
        notes: ['', Validators.required],
        new_expiry_date: ['', Validators.required]
      });
    }

    if (this.modalType == this._debtRecovery.modalTypes.ResendProposalEmail){
      this.resend_ar_form = this._fb.group({
       customer_email : [this.resend_ar_data.send_all ? "": this.resend_ar_data.data.patient_email],
       customer_mobile: [this.resend_ar_data.send_all ? "": this.resend_ar_data.data.patient_mobile],
       discount_to_customer : [0]
      });
    }
    if (this.modalType == this._debtRecovery.modalTypes.sendReceiptLinkModal){
      this.sendReceiptLinkForm = this._fb.group({
        user_email: [  this.extra_data.paymentProcessInfo['patient_email'] , Validators.required],
        user_phone: ['', Validators.required]
      });
    }

    
    if (this.modalType == this._debtRecovery.modalTypes.deleteProposalmodal){
      this.reasonsList = this._local.customGetItem("delete_reasons")
      this.deleteProposalform = this._fb.group({
        reasons: ['',Validators.required],
        notes:['',Validators.required],
        reasonNote:['']
      });
    }
  }
  // hide modal common function
  hide() {
    this.bsModalRef.hide()
  }

  // hide modal without any action
  cancel() {
    this.passEntry.emit({success: false});
    this.hide();
  }

  resendLinkFn() {
    const data = {
      id: this.extra_data.paymentProcessInfo['id'],
      email: this.resendLinkForm.value.user_email,
      mobile: this.resendLinkForm.value.user_phone,
      mobile_country_code: this.extra_data.paymentProcessInfo['patient_mobile_country_code'],
      is_ar: 1
    };
    let api = 'finance/resend_payment_process_link';
    if (this.modalType === this._debtRecovery.modalTypes.sendReceiptLinkModal) {
      api = 'finance/send_download_receipt_link';
    }
    this._api.api(data, api)
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          if (data.flag == 552) {
            this._shared.customPopups(data.data, 0);
          } else {
            this._shared.customPopups('Link has been sent successfully.', 0)
          }
          this.passEntry.emit({success: true});
          return;
        } else if (data.is_error == 1) {
          if (data.override_text) {
            this._shared.customPopups(data.override_text, data.is_error);
          } else {
            this._shared.customPopups(data.flag, data.is_error);
          }
        }
      });
  }

  GetTransactionFee(contract: any, comparable_value: any) {
    comparable_value = parseFloat(comparable_value);
    contract.recurring_extra_amount = parseFloat(contract.recurring_extra_amount);
    contract.recurring_extra_percent = parseFloat(contract.recurring_extra_percent);
    contract.threshold_amount = parseFloat(contract.threshold_amount);
    contract.threshold_recurring_extra_amount = parseFloat(contract.threshold_recurring_extra_amount);
    contract.threshold_recurring_extra_percent = parseFloat(contract.threshold_recurring_extra_percent);
    let text = '';
    if (comparable_value < contract.threshold_amount) {
      if (contract.recurring_extra_percent) {
        text += `${parseFloat(contract.recurring_extra_percent).toFixed(2)}%`
      }
      if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.recurring_extra_amount) {
        text += `${this.details_extra_data.data[0].display_logo}${parseFloat(contract.recurring_extra_amount).toFixed(2)}`
      }
      if (!contract.recurring_extra_percent && !contract.recurring_extra_amount) {
        text = this.details_extra_data.data[0].display_logo + '0'
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        text += `${parseFloat(contract.threshold_recurring_extra_percent).toFixed(2)}%`
      }
      if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.threshold_recurring_extra_amount) {
        text += `${this.details_extra_data.data[0].display_logo}${parseFloat(contract.threshold_recurring_extra_amount).toFixed(2)}`
      }

      if (!contract.threshold_recurring_extra_percent && !contract.threshold_recurring_extra_amount) {
        text = '0%'
      }
    }
    return text;
  }
  settingDisabled(){
    if((this.paymentSettingsForm.value['primary_type'].length) && (this.paymentSettingsForm.value['backup_type'].length) && (this.paymentSettingsForm.value['recurring_type'].length))
      {
        this.changesettings = false;
      }
  }

  editPaymentSettings() {
    let params: any = {
      split_req_id: this.payment_extra_data.data[0]['split_req_id'],
      primary_type: this.paymentSettingsForm.value['primary_type'],
      backup_type: this.paymentSettingsForm.value['backup_type'],
      recurring_type: this.paymentSettingsForm.value['recurring_type'],
      backup_required: this.paymentSettingsForm.value['backup_required'] ? 1 : 0,
    };
    this._api.api(params, 'business/edit_split_payment_settings')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        if (data.is_error == 0) {
          this.paymentSettingsForm.reset();
          this.hide();
          this.passEntry.emit({success: true});
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  toggleBlockContractForOverdue() {
    let params = {
      split_req_id: this.payment_extra_data.data[0]['split_req_id'],
      skip_overdue_contract_check: this.payment_extra_data.data[0].skip_overdue_contract_check ? 1 : 0,
    };
    this._api.api(params, 'business/edit_split_overdue_check')
      .subscribe((data: any) => {
      }, (error: any) => {
        console.log(error)
      })
  }

  editExpiryDate() {
    try {
      const timezone = this._shared.getTimezone();
      this.editExpiryDateForm.controls['notes'].setValue(this.editExpiryDateForm.value.notes.trim());
      if (!this.editExpiryDateForm.value.new_expiry_date || !this.editExpiryDateForm.value.notes) {
        // tslint:disable-next-line:max-line-length
        const msg = !this.editExpiryDateForm.value.new_expiry_date && !this.editExpiryDateForm.value.notes ? 'Please enter details' : (!this.editExpiryDateForm.value.new_expiry_date ? 'Please select a date.' : 'Please add notes.');
        return this._shared.customPopups(msg, 2);
      }
      const new_date = momentTz(this.editExpiryDateForm.value.new_expiry_date).tz(timezone).utc().format('YYYY-MM-DD HH:mm:ss');
      const params = {
        expiry_date: new_date,
        admin_notes: this.editExpiryDateForm.value.notes,
        id: this.expiry_extra_data.split_req_id
      };

      this._api.api(params, 'finance/update_expiry_date')
        .subscribe((data: any) => {
          if (data && typeof data === 'string') { data = JSON.parse(data); }
          if (+data['is_error'] === 0) {
            this._shared.customPopups(data.message || 'Expiry date updated successfully', 0);
            this.editExpiryDateForm.reset();
            this.hide();
            this.passEntry.emit({success: true});
          }
        }, (error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

  }
  sendRecieptLink(){
    if (!this.sendReceiptLinkForm.value.user_email) {      
    this._shared.customPopups("Please enter a valid email",1)
    }
   
    let data = {
      email : this.sendReceiptLinkForm.value.user_email,
      split_request_id : this.extra_data.paymentProcessInfo.split_req_id
    }
    this._api.api(data, 'finance/send_download_receipt_link').subscribe({
      next : () => {     
        this.hide();
        this.passEntry.emit({ success: true });
      },
      error : () => {}
    }) 
 
  }

  resendProposal(){
    this.passEntry.emit({ success: true, discount: this.resend_ar_form.value.discount_to_customer });
    this.hide();
  }

  deleteProposal() {

    if (this.deleteProposalform.value.notes.trim() == "" && this.deleteProposalform.value.reasonNote.trim() == "") {
      this.deleteProposalform.controls['notes'].setValue("")
      this.deleteProposalform.controls['reasonNote'].setValue("")
      this._shared.customPopups("Please enter a valid note and Reason", 1)
      return
    }

    if (this.deleteProposalform.value.notes.trim() == "") {
      this.deleteProposalform.controls['notes'].setValue("")
      this._shared.customPopups("Please enter a valid note", 1)
      return
    }
    if (this.deleteProposalform.value.reasonNote.trim() == "" && this.deleteProposalform.controls['reasons'].value == 'Other') {
      this.deleteProposalform.controls['reasonNote'].setValue("")
      this._shared.customPopups("Please enter a valid Reason", 1)
      return
    }
    const selectedValue = this.deleteProposalform.get('reasons')?.value;
    const selectedReason = this.reasonsList.filter((reason: any) => {
      return reason.reason_notes === selectedValue
    })
    const data = {
      split_req_id: this.extra_data.paymentProcessInfo,
      reason_id: selectedReason[0].reason_id,
      reason_notes: selectedReason[0].reason_id == 6 ? this.deleteProposalform.value.reasonNote : selectedReason[0].reason_notes,
      notes: this.deleteProposalform.value.notes
    }

    this._api.api(data, 'finance/delete_proposal').subscribe({
      next: (data) => {
        if (data.is_error || data.original_text) {
          this._shared.customPopups(data.original_text, 1)
        } else {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      },
      error : () => {}
    })    
    
  }

  ngOnDestroy(): void {
    this.passEntry.emit({success: false});
    this.hide();
  }
}


interface modal_data {
  paymentProcessInfo: any
}

interface details_modal_data {
  label: string,
  data?: any,
  view_track_status: any
}

interface payment_modal_data {
  label: string,
  data?: any
}

interface expiry_modal_data {
  label: string,
  split_req_id: any
  // data?: any
}

interface resend_ar_proposal_data {
 data ?: any,
 send_all?:boolean
}