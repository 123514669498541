import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared.service';
import { localStorageService } from '../localstorage.service';
import { ApiService } from '../api.service';
import { MainService } from '../main.service';

@Component({
  selector: 'app-add-edit-training',
  templateUrl: './add-edit-training.component.html',
  styleUrls: ['./add-edit-training.component.scss'],
  providers: [DatePipe]
})
export class AddEditTrainingComponent implements OnInit {

  public data: any;
  public trainingSwitch: any = false;
  pastTrainingTime: any = {};
  selectedTimezone: any = null;
  timezones: any = [];
  timezoneArray: any = [];

  eventId: any = null;
  viewType: any = null;
  minTimeGap: any = null;
  searchDoctorTimeOutRef: any = null;

  current_zone_name: any = '';
  timezone: any = '';
  currentUtcOffset:any = moment().utcOffset();

  selectedAppt: any = null;
  trainingDurations: any = [15,30,45,60];
  
  trainingStatusTypes: any = [];
  eventToken = Math.random();
  trainingStatus: any;
  dateConfig: any = {
    containerClass: 'theme-blue',
    placement: 'left',
    dateInputFormat: 'MM-DD-YYYY'
  };
  today: Date = new Date();
  subscription!: Subscription;
  hours = [{ value: "00" }, { value: "01" }, { value: "02" }, { value: "03" }, { value: "04" }, { value: "05" }, { value: "06" }, { value: "07" }, { value: "08" }, { value: "09" }, { value: "10" }, { value: "11" }, { value: "12" }, { value: "13" }, { value: "14" }, { value: "15" }, { value: "16" }, { value: "17" }, { value: "18" }, { value: "19" }, { value: "20" }, { value: "21" }, { value: "22" }, { value: "23" }];
  minutes = [{ value: "00" }, { value: "15" }, { value: "30" }, { value: "45" }];
  mobile_code_arr: any;
  selectedMobileCode: any;
  country_id: any;
  constructor(
    public mainService: MainService,
    public bsModalRef: BsModalRef,
    public datePipe: DatePipe,
    public _shared: SharedService,
    private _local: localStorageService,
    private _api: ApiService
  ) {}

  ngOnInit() {
    this.today.setHours(0, 0, 0, 0);
    this.currentUtcOffset = this.getSelectedTimezoneOffset();
    this.selectedTimezone = this.data.selectedTimezone;
    //this.timezones = this.data.timezones;
    this.viewType = this.data.viewType;
    this.minTimeGap = this.data.minTimeGap;
    this.eventToken = this.data.eventToken ? this.data.eventToken : this.eventToken;
    this.trainingStatus = this.data.trainingStatus ? this.data.trainingStatus : null;
    this.country_id = this.data && this.data.country_id ? this.data.country_id : this.country_id;

    /*this.mainService.getTimezone()
    .subscribe((data: any) => {
      this.timezoneArray = data;
    });*/
    this.timezones = this._shared.getTimezoneList();
    this.subscription = this._shared.timezone$
    .subscribe((data: any) => {
      this.current_zone_name = this._shared.timezoneName;
      this.timezone = this._shared.timezone;
      if(this.viewType === "newTraining")
      {
        this.showNewTrainingModal();
      }
      else
      {
        this.eventId = this.data.eventId;
        this.showViewTrainingModal();
      }

    });
    this.mainService.mobile_code_arr$.subscribe(data => {
      if (data && data.length) {
        this.mobile_code_arr = data;
        this.selectedMobileCode = this.mobile_code_arr[0];
      };
    });
    
    if (this.data && this.data.filterTimzones) {
      this.timezones = this.data.timezones;
    }

  }

  getSelectedTimezoneOffset(){
    return (parseInt(this.timezone.substring(0, this.timezone.length - 2))*60) + parseInt(this.timezone.substring(this.timezone.length - 2))
  }


  showNewTrainingModal() {
    this.selectedAppt = {
        viewType: "newTraining",
        date: moment().toDate(),
        trainingTypes: [],
        selectedTraining: null,
        trainingDuration: null,
        timezone: this.selectedTimezone,
        timezones: [],
        selectedAdmin: null,
        selectedAdminId: null,
        adminUsers: [],
        availableAdminUsers: [],
        availableStartTimes: [],
        availableTimeSlots: [],
        selectedDoctor: null,
        selectedDoctorId: null,
        doctors: [],
        selectedAvailableStartTime: null,
        out_of_system: false,
        assign_automatic: false,
        send_email: true,
        trainingMeetingLink: '',
        training_doctor_partner_type: 'doctor',
        followup_date: ''
    }
    if (this.data.doctor_partner_id) {
      var selectedDoctorId = parseInt(this.data.doctor_partner_id);
      if (this.data.doctor_partner_type == 'partner') {
        let data = {
          
          partner_id: selectedDoctorId,
          timezone_offset: this.getSelectedTimezoneOffset(),
        };
        this._api.api(data, 'partner_info_v2')
          .subscribe((data: any) => {
            if (data.partner_profile[0].partner_id == null) {
              this.getTrainingTypes();
            } else {
              var partnerData = data.partner_profile[0];
              console.log(partnerData);
              this.selectedAppt.selectedDoctorId = selectedDoctorId;
              var selectedDoctor = {
                partner_name: partnerData.partner_name,
                partner_email: partnerData.partner_email,
                partner_mobile: partnerData.partner_mobile,
                doctor_name: partnerData.partner_name,
                doctor_mobile: partnerData.partner_mobile ? partnerData.partner_mobile : '-',
                doctor_emails: partnerData.partner_email ? partnerData.partner_email : '-',
              }
              if (partnerData.partner_mobile_country_code) {
                this.selectedMobileCode = partnerData.partner_mobile_country_code;
              }
              this.selectedAppt.doctors = [selectedDoctor];
              this.selectedAppt.doctors[0].partner_id = selectedDoctorId;
              this.selectedAppt.selectedDoctor = selectedDoctor;
              this.selectedAppt.out_of_system = false;
              this.getTrainingTypes();
              this.selectedAppt.training_doctor_partner_type = 'partner'
            }
          });
      } else {
        let data = {
          
          doctor_id: selectedDoctorId,
          timezone_offset: this.getSelectedTimezoneOffset(),
        };
        this._api.api(data, 'doctor_info')
          .subscribe((data: any) => {
            if (data.doctor_profile[0].doctor_id == null) {
              this.getTrainingTypes();
            } else {
              var doctorData = data.doctor_profile[0];
              this.selectedAppt.selectedDoctorId = selectedDoctorId;
              var selectedDoctor = {
                doctor_id: this.selectedAppt.selectedDoctorId,
                practice_name: doctorData.practice_name ? doctorData.practice_name : '-',
                doctor_name: doctorData.doctor_first_name + ' ' + doctorData.doctor_last_name,
                //doctor_first_name: eventData.doctor_first_name,
                //doctor_last_name: eventData.doctor_last_name,
                doctor_title: doctorData.title,
                doctor_mobile: doctorData.doctor_mobile ? doctorData.doctor_mobile : '-',
                doctor_emails: doctorData.doctor_email ? doctorData.doctor_email : '-',
              }

              if (doctorData.doctor_mobile_country_code) {
                this.selectedMobileCode = doctorData.doctor_mobile_country_code;
              }
              this.selectedAppt.doctors = [selectedDoctor];
              this.selectedAppt.selectedDoctor = selectedDoctor;
              this.selectedAppt.out_of_system = false;
              this.getTrainingTypes();
            }
          });
      }
    } else {
      this.getTrainingTypes();
    }
  };


  getMinDate() {
      return moment().toDate();
  }
  getPastMinDate() {
    return moment().toDate();
}


  showViewTrainingModal() {
    // tslint:disable:max-line-length
    // tslint:disable:comment-format
    //admin_id: this.viewType === "allTimePref" ? this.calType === "single_day_view" ? this.adminUsers[parseInt(col)].admin_id : this.selectedAdmin : this.selectedAdmin,
    /*var selectorDict = this.cloneObj(this.timeSlotSelectorsDict[selectorId]);
    if(selectorDict["type"] === "doctor_partner_training")
    {

    }*/
        //var startCol = parseInt(selectorDict["startDay"]);
        var event_id = parseInt(this.eventId);

        //var admin_id = this.viewType === "allTimePref" ? this.calType === "single_day_view" ? this.adminUsers[parseInt(startCol+"")].admin_id : this.selectedAdmin : this.selectedAdmin;

        var thisRef = this;
        //thisRef.isProcessing = true;
            this.fetchEventDetailsApi(event_id, function(err: any, data: any){
            if(!err)
            {
                var eventData = data.eventData;
                console.log(eventData);
                var selectedUtcOffset = thisRef.selectedTimezone ? parseInt(thisRef.selectedTimezone.split("****")[1]) : thisRef.currentUtcOffset;
                var applicableOffset = thisRef.getApplicableOffsetBasedOnDaylight(eventData.training_timezone_short_name, eventData.training_timezone_offset, selectedUtcOffset);
                var schedule_date = moment(parseInt(eventData.training_start_timestamp)*1000).utcOffset(applicableOffset).format("YYYY-MM-DD");
                var schedule_time = moment(parseInt(eventData.training_start_timestamp)*1000).utcOffset(applicableOffset).format("hh:mm A");

                var training_type = parseInt(eventData.doctor_partner_training_type);
                var admin_id = eventData.training_admin_id;
                var adminObj = {
                    admin_name: eventData.admin_name,
                    admin_email: eventData.admin_email,
                };
                //debugger;
                var selectedDoctorId = parseInt(eventData.training_doctor_partner_id);
                var out_of_system = selectedDoctorId  ? false : true;
                var selectedDoctor = {
                    practice_name: eventData.training_practice_name,
                    doctor_name: eventData.training_doctor_partner_name,
                    //doctor_first_name: eventData.doctor_first_name,
                    //doctor_last_name: eventData.doctor_last_name,
                    doctor_title: eventData.title ? eventData.title : "",
                    practice_mobile: eventData.training_doctor_partner_phone ? eventData.training_doctor_partner_phone : "",
                    doctor_emails: eventData.training_trainee_emails ? eventData.training_trainee_emails : eventData.doctor_email,
                    partner_name: eventData.partner_name,
                    partner_email: eventData.partner_email,
                    partner_mobile: eventData.partner_mobile
                }
                

                thisRef.selectedAppt = {
                    viewType: thisRef.viewType,
                    //date: moment(schedule_date).toDate(),
                    date: moment().toDate(),
                    trainingTypes: [],
                    selectedTraining: training_type,
                    trainingDuration: null,
                    training_start_timestamp: eventData.training_start_timestamp,
                    training_duration: parseInt(((eventData.training_end_timestamp - eventData.training_start_timestamp)/60)+""),
                    training_timezone_short_name: eventData.training_timezone_short_name,
                    training_timezone_offset: eventData.training_timezone_offset,
                    schedule_date: schedule_date,
                    schedule_time: schedule_time,
                    timezone: thisRef.selectedTimezone,
                    //timezones: thisRef.timezones,
                    eventId: event_id,
                    excludeAdminId: admin_id,
                    excludeAdmin: adminObj,
                    selectedTrainingObj: null,
                    selectedAdminId: null,
                    selectedAdmin: null,
                    adminUsers: [],
                    availableAdminUsers: [],
                    availableStartTimes: [],
                    //trainingNote: (thisRef.viewType === "updateTrainingStatus" || thisRef.viewType === "updateTrainingNotes")  ? "" : eventData.training_event_desc,
                    trainingNote: "",
                    trainingMeetingLink: eventData.training_meeting_link,
                    availableTimeSlots: [],
                    selectedDoctor: selectedDoctor,
                    selectedDoctorId: selectedDoctorId,
                    doctors: [],
                    //status: thisRef.viewType === "rescheduleTraining" ? "3" : eventData.training_status+"",
                    status: thisRef.trainingStatus ? thisRef.trainingStatus+"" : eventData.training_status+"",
                    training_status_name: eventData.training_status_name,
                    old_status: eventData.training_status+"",
                    selectedAvailableStartTime: null,
                    out_of_system: out_of_system,
                    assign_automatic: false,
                    send_email: true,
                    training_doctor_partner_type: eventData.training_doctor_partner_type,
                    training_doctor_partner_id: eventData.training_doctor_partner_id,
                    training_source: eventData.training_source 
                }
                console.log(eventData,thisRef.selectedAppt);
                //thisRef.apptModal.show();
                //thisRef.isProcessing = false;
                thisRef.setViewType();
                thisRef.getTrainingTypes();
            }
            else
            {
                //thisRef.isProcessing = false;
                //showLoadingWithMessage("");
                //enableDisableFilters(!isProcessing);
            }
        });
  };

  getApplicableOffsetBasedOnDaylight(tzShortCode: any, tzOffset: any, selectedtzOffset: any) {
    // console.log(tzShortCode, tzOffset, selectedtzOffset, "dataaaaaaaaaaa")
    let selectedUtcOffset = selectedtzOffset;
    console.log(this.timezones);
    let trainingTzObj = this.timezones.find((tz: any) => tz.code === tzShortCode);
    if (!trainingTzObj) {
      let alternateTrainingTzObj: any = this.timezones.find((tz: any) => tz.alternate_daylight_code === tzShortCode);
      if (alternateTrainingTzObj) {
        selectedUtcOffset += (tzOffset - alternateTrainingTzObj.tzOffset);
      }
    }
    return selectedUtcOffset;
  }

  getTimezoneOffsetFromString(timezoneString: any) {
    return (parseInt(timezoneString.substring(0, timezoneString.length - 2))*60) + parseInt(timezoneString.substring(timezoneString.length - 2));
  }

  assignAutomaticChanged(){

  }

  outOfSystemChanged(){
    if(this.selectedAppt.out_of_system)
    {
      this.selectedAppt.selectedDoctorId = null;
      this.selectedAppt.selectedDoctor = {
      }
    }
    else
    {
      this.selectedAppt.selectedDoctorId = null;
      this.selectedAppt.selectedDoctor = null
    }
  }

  enableDisableSubmitTrainingButton(){
    var enableButton = false;
    if(this.selectedAppt.viewType === "editTraining")
    {
        enableButton = (this.selectedAppt.selectedDoctor && this.selectedAppt.selectedAvailableStartTime && this.selectedAppt.trainingNote && (this.selectedAppt.assign_automatic || this.selectedAppt.selectedAdminId));
    }
    else if(this.selectedAppt.viewType === "newTraining")
    {
      var hasDoctorDetails = (this.selectedAppt.out_of_system ? 
      (this.selectedAppt.selectedDoctor.doctor_emails && this.isValidEmail(this.selectedAppt.selectedDoctor.doctor_emails) && 
      (this.selectedAppt.selectedDoctor.practice_name ? this.selectedAppt.selectedDoctor.practice_name.length <= 256 : true)
      && this.selectedAppt.selectedDoctor.doctor_name && this.selectedAppt.selectedDoctor.doctor_name.length <= 256
      && (this.selectedAppt.selectedDoctor.doctor_mobile ? this.isPhoneNumberValid(this.selectedAppt.selectedDoctor.doctor_mobile) : true)) : 
        (this.selectedAppt.selectedDoctorId ? true : false)) && (this.selectedAppt.trainingMeetingLink ? this.isValidUrl(this.selectedAppt.trainingMeetingLink) : true);
      if (this.trainingSwitch) {

        enableButton = (hasDoctorDetails && this.selectedAppt.trainingNote && this.selectedAppt.date && this.pastTrainingTime.hour && this.pastTrainingTime.minutes);
      } else {
        enableButton = (hasDoctorDetails && this.selectedAppt.selectedAvailableStartTime && this.selectedAppt.trainingNote && (this.selectedAppt.assign_automatic || this.selectedAppt.selectedAdminId));
      }
    }
    if(this.selectedAppt.viewType === "updateTrainingNotes" || this.selectedAppt.viewType === "updateTrainingStatus")
    {
        enableButton = this.selectedAppt.trainingNote ? true : false;
    }
    return enableButton;
  }


  submitTrainingViewTitle(){
    var title = "";
    if(this.selectedAppt.viewType === "editTraining")
    {
        title = 'Edit Training Details';
    }
    if(this.selectedAppt.viewType === "newTraining")
    {
        title = 'New Training';
    }
    if(this.selectedAppt.viewType === "updateTrainingNotes")
    {
        title = 'Add Training Notes';
    }
    if(this.selectedAppt.viewType === "updateTrainingStatus")
    {
        title = 'Update Training Status';
    }
    
    return title;
  }

  submitTrainingButtonTitle(){
    var title = "";
    if(this.selectedAppt.viewType === "editTraining")
    {
        title = 'Re-assign Training';
    }
    if(this.selectedAppt.viewType === "newTraining")
    {
        title = 'Schedule Training';
    }
    if(this.selectedAppt.viewType === "updateTrainingNotes")
    {
        title = 'Add Notes';
    }
    if(this.selectedAppt.viewType === "updateTrainingStatus")
    {
        title = 'Update Status';
    }
    
    return title;
  }

  hideTrainingModal(){
    this.bsModalRef.hide();
    //this.selectedAppt = null;
  }

  trainingStatusChanged(){
    this.setViewType();
    if(parseInt(this.selectedAppt.status) === 3)
    {
      this.getAvailableSlotsOnAppointmentDate();
    }
  }

  setViewType(){
    this.selectedAppt.viewType = (parseInt(this.selectedAppt.status) === 3) ? "editTraining" : "updateTrainingStatus";
  }

  getTrainingTypes(){
    let params = { access_token: this._local.customGetItem('adminToken') };
    this._api.api(params, 'fetch_training_types')
      .subscribe((data:any)=>{
        if(data.is_error == 0){
            this.selectedAppt.trainingTypes = data.data;
            if(this.selectedAppt.selectedTraining === null)
            {
                this.selectedAppt.selectedTraining = this.selectedAppt.trainingTypes.length ? this.selectedAppt.trainingTypes[0].type_id : null;
            }
            
            var trainingObj = this.selectedAppt.trainingTypes.find((training: any)=>training.type_id+"" === this.selectedAppt.selectedTraining+"");
            this.selectedAppt.selectedTrainingObj = trainingObj;
            this.selectedAppt.trainingDuration = trainingObj ? trainingObj.duration : null;

      
              var currentTz = this.current_zone_name;
              var currentTzObj: any = this.timezones.find((timezone: any)=>timezone.code === currentTz);
              this.current_zone_name = currentTzObj ? currentTzObj.code : this.timezones[0].code;
              this.currentUtcOffset = currentTzObj ? currentTzObj.tzOffset : this.timezones[0].tzOffset;
      
              this.selectedTimezone = this.selectedTimezone ? this.selectedTimezone : this.current_zone_name+"****"+this.currentUtcOffset;
              if(this.selectedAppt)
              {
                this.selectedAppt.timezone = this.selectedTimezone;
              }
              var thisRef = this;
              if(this.viewType === "newTraining")
              {
                this.getAvailableSlotsOnAppointmentDate();
              }
              else
              {
                this.fetchTrainingStatusesApi((err: any, trainingStatusesData: any)=>{
                  thisRef.trainingStatusTypes = trainingStatusesData.data;
                  thisRef.getAvailableSlotsOnAppointmentDate();
                });
              }
        }
      }, (error:any)=>{
        console.log(error);
      });
  }

  ngOnDestroy() {
    console.log('DESTROYED')
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /*hideModal() {
    this.bsModalRef.hide();
  }*/

  addUpdateTraining() {
    var thisRef = this;
    if (this.selectedAppt.viewType === "updateTrainingStatus" || this.selectedAppt.viewType === "updateTrainingNotes") {
      var extraData: any = {
        event_token: this.eventToken
      }
      console.log(this.selectedAppt);
      if (this.selectedAppt.status == 4 || this.selectedAppt.status == 9) {
        if (this.selectedAppt.status == 4 && !thisRef.selectedAppt.followup_date) {
          this._shared.customPopups('Please select the follow up date', 2);
          return false;
        }
        extraData['followup_date'] = thisRef.selectedAppt.followup_date ? thisRef.datePipe.transform(thisRef.selectedAppt.followup_date, 'yyyy-MM-dd HH:mm:ss') : null;
      }
      this.updateEventStatusNotesApi(this.selectedAppt.eventId, this.selectedAppt.trainingNote,
        this.selectedAppt.viewType === "updateTrainingStatus" ? this.selectedAppt.status : null,
        1, extraData, function (err: any, data: any) {
          if (!err) {
            /*thisRef.viewType = "allTimePref";
            thisRef.calType = "single_day_view";
            thisRef.calWeekOffSetFromCurrentWeek = 0;
            thisRef.singleDayDate = thisRef.selectedAppt.date;
            thisRef.timeSlotSelectorsDict = {};
            thisRef.undoRedoIndex = -1;
            thisRef.timeSlotSelectorsDictUndoRedoArray = [];
            thisRef.refreshWeekDaysAndMonthDates();
            thisRef.createTimeSlotSelectorTable();*/

            var successMsg = "The training was edited successfully";
            thisRef._shared.customPopups(successMsg, 0);

            var excludeAdminId = thisRef.selectedAppt.excludeAdminId ? thisRef.selectedAppt.excludeAdminId : null;

            thisRef.bsModalRef.content.trainingCreated = data;
            thisRef.bsModalRef.content.selectedAppt = thisRef.cloneObj(thisRef.selectedAppt);
            console.log(thisRef.selectedAppt);
            thisRef.bsModalRef.content.selectedAppt.selectedAdminId = excludeAdminId;
            thisRef.hideTrainingModal();
          } else {
            thisRef._shared.customPopups(err, 1);
          }
        });
    } else {
      if (this.trainingSwitch) {
        var selectedUtcOffset = parseInt(this.selectedAppt.timezone.split("****")[1])
        let pastdate = new Date(this.selectedAppt.date);
        let start_time = new Date(pastdate.getFullYear(), pastdate.getMonth(), pastdate.getDate(), this.pastTrainingTime.hour, this.pastTrainingTime.minutes, 0);
        let start_time_timestamp = parseInt(moment(start_time).utc().format("X"));
        var endTimeStamp = start_time_timestamp + (this.selectedAppt.trainingDuration * 60);
        var startDateTime = moment(start_time_timestamp * 1000).utcOffset(selectedUtcOffset).format();
        var endDateTime = moment(endTimeStamp * 1000).utcOffset(selectedUtcOffset).format();
        let adminProfile = JSON.parse(this._local.customGetItem('profileData'));
        var selectedTimzoneObj = this.timezones.find((timezone: any) => parseInt(timezone.tzOffset) === selectedUtcOffset);

        if (adminProfile[0].admin_id) {
          this.insertTimeslotApi(adminProfile[0].admin_id, startDateTime, endDateTime, "doctor_partner_training", {
            doctor_partner_id: !thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctorId : null,
            trainee_emails: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.doctor_emails : "",
            doctor_practice_name: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.practice_name : "",
            doctor_contact_name: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.doctor_name : "",
            doctor_practice_phone: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.doctor_mobile : "",
            doctor_practice_phone_country_code: this.selectedMobileCode ? this.selectedMobileCode  : "",
            doctor_partner_type: thisRef.selectedAppt.training_doctor_partner_type,
            old_event_id: thisRef.selectedAppt.eventId ? thisRef.selectedAppt.eventId : null,
            event_training_status: thisRef.selectedAppt.eventId ? 3 : null,
            training_type: thisRef.selectedAppt.selectedTraining,
            event_desc: thisRef.selectedAppt.trainingNote,
            timezone_short_name: selectedTimzoneObj.code,
            timezone_full_name: selectedTimzoneObj.name,
            event_token: thisRef.eventToken,
            past_training: 1,
            training_meeting_link: thisRef.selectedAppt.trainingMeetingLink ? this.getUrlWithProtocol(thisRef.selectedAppt.trainingMeetingLink) : "",
            send_email: 0,
            country_id: this.country_id
          }, function (err: any, data: any) {
            if (!err) {
              /*thisRef.viewType = "allTimePref";
              thisRef.calType = "single_day_view";
              thisRef.calWeekOffSetFromCurrentWeek = 0;
              thisRef.singleDayDate = thisRef.selectedAppt.date;
              thisRef.timeSlotSelectorsDict = {};
              thisRef.undoRedoIndex = -1;
              thisRef.timeSlotSelectorsDictUndoRedoArray = [];
              thisRef.refreshWeekDaysAndMonthDates();
              thisRef.createTimeSlotSelectorTable();*/
              /*if(thisRef.data.trainingCreatedUpdatedCallback)
              {
                thisRef.data.trainingCreatedUpdatedCallback();
              }*/
              var successMsg = "The training was " + (thisRef.selectedAppt.viewType === "newTraining" ? "booked" : "rescheduled") + " successfully";
              thisRef._shared.customPopups(successMsg, 0);
              thisRef.bsModalRef.content.trainingCreated = data;
              thisRef.bsModalRef.content.selectedAppt = thisRef.cloneObj(thisRef.selectedAppt);
              thisRef.bsModalRef.content.selectedAppt.schedule_date = moment(thisRef.selectedAppt.date).format("YYYY-MM-DD");
              thisRef.bsModalRef.content.selectedAppt.selectedAdminId = selectedAdminId;
              thisRef.hideTrainingModal();
            } else {
              //thisRef.isProcessing = false;
              //showLoadingWithMessage("");
              //enableDisableFilters(!isProcessing);
            }
          });
        }
        
      } else {
        var selectedUtcOffset = parseInt(this.selectedAppt.timezone.split("****")[1]);
        var startTimeStamp = parseInt(moment(this.selectedAppt.selectedAvailableStartTime.fullDateTimeString).utc().format("X"));
        var endTimeStamp = startTimeStamp + (this.selectedAppt.trainingDuration * 60);
        var startDateTime = moment(startTimeStamp * 1000).utcOffset(selectedUtcOffset).format();
        var endDateTime = moment(endTimeStamp * 1000).utcOffset(selectedUtcOffset).format();
        if (startTimeStamp > parseInt(moment().utc().format("X"))) {
          // thisRef.isProcessing = true;
          var selectedAdminId = this.selectedAppt.selectedAdminId;
          var excludeAdminId = this.selectedAppt.excludeAdminId ? this.selectedAppt.excludeAdminId : null;
          if (this.selectedAppt.assign_automatic) {
            var availableAdminObjsExceptCurrentAdmin = this.selectedAppt.availableAdminUsers.filter((adminUser: any) => adminUser.admin_id !== excludeAdminId)
            if (availableAdminObjsExceptCurrentAdmin.length) {
              availableAdminObjsExceptCurrentAdmin.sort(function (a: any, b: any) {
                return a.totalAvailableDuration > b.totalAvailableDuration ? -1 : 1
              });
              selectedAdminId = availableAdminObjsExceptCurrentAdmin[0].admin_id;
            }
          }
          var selectedUtcOffset = parseInt(this.selectedAppt.timezone.split("****")[1]);
          var selectedTimzoneObj = this.timezones.find((timezone: any) => parseInt(timezone.tzOffset) === selectedUtcOffset);

          if (selectedAdminId) {
            //debugger;
            this.insertTimeslotApi(selectedAdminId, startDateTime, endDateTime, "doctor_partner_training", {
              doctor_partner_id: !thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctorId : null,
              trainee_emails: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.doctor_emails : "",
              doctor_practice_name: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.practice_name : "",
              doctor_contact_name: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.doctor_name : "",
              doctor_practice_phone: thisRef.selectedAppt.out_of_system ? thisRef.selectedAppt.selectedDoctor.doctor_mobile : "",
              doctor_practice_phone_country_code: thisRef.selectedAppt.out_of_system ? this.selectedMobileCode  : "",
              doctor_partner_type: thisRef.selectedAppt.training_doctor_partner_type,
              old_event_id: thisRef.selectedAppt.eventId ? thisRef.selectedAppt.eventId : null,
              event_training_status: thisRef.selectedAppt.eventId ? 3 : null,
              training_type: thisRef.selectedAppt.selectedTraining,
              event_desc: thisRef.selectedAppt.trainingNote,
              timezone_short_name: selectedTimzoneObj.code,
              timezone_full_name: selectedTimzoneObj.name,
              event_token: thisRef.eventToken,
              training_meeting_link: thisRef.selectedAppt.trainingMeetingLink ? this.getUrlWithProtocol(thisRef.selectedAppt.trainingMeetingLink) : "",
              send_email: (this.selectedAppt.out_of_system && this.selectedAppt.viewType === 'newTraining') ? 1 : (this.selectedAppt.send_email ? 1 : 0),
              country_id: this.country_id
            }, function (err: any, data: any) {
              if (!err) {
                /*thisRef.viewType = "allTimePref";
                thisRef.calType = "single_day_view";
                thisRef.calWeekOffSetFromCurrentWeek = 0;
                thisRef.singleDayDate = thisRef.selectedAppt.date;
                thisRef.timeSlotSelectorsDict = {};
                thisRef.undoRedoIndex = -1;
                thisRef.timeSlotSelectorsDictUndoRedoArray = [];
                thisRef.refreshWeekDaysAndMonthDates();
                thisRef.createTimeSlotSelectorTable();*/
                /*if(thisRef.data.trainingCreatedUpdatedCallback)
                {
                  thisRef.data.trainingCreatedUpdatedCallback();
                }*/
                var successMsg = "The training was " + (thisRef.selectedAppt.viewType === "newTraining" ? "booked" : "rescheduled") + " successfully";
                thisRef._shared.customPopups(successMsg, 0);
                thisRef.bsModalRef.content.trainingCreated = data;
                thisRef.bsModalRef.content.selectedAppt = thisRef.cloneObj(thisRef.selectedAppt);
                thisRef.bsModalRef.content.selectedAppt.schedule_date = moment(thisRef.selectedAppt.date).format("YYYY-MM-DD");
                thisRef.bsModalRef.content.selectedAppt.selectedAdminId = selectedAdminId;
                thisRef.hideTrainingModal();
              } else {
                //thisRef.isProcessing = false;
                //showLoadingWithMessage("");
                //enableDisableFilters(!isProcessing);
              }
            });
          } else {
            thisRef._shared.customPopups("No CSR available", 1);
          }

        } else {
          thisRef._shared.customPopups("You cannot add training in past time", 1);
        }
      }
    }
    return;
  }


  insertTimeslotApi(userId: any, startDateTime: any, endDateTime: any, timeSlotType: any, extraData: any, callback: any){

    var reqData: any = {
      
        admin_id: userId,
        start_date_time: startDateTime,
        end_date_time: endDateTime,
        time_slot_type: timeSlotType,
        send_email: extraData.send_email,
        training_meeting_link: extraData.training_meeting_link
    }
    if(timeSlotType === "doctor_partner_training")
    {
        reqData["event_desc"] = extraData.event_desc;
        if(extraData.doctor_partner_id)
        {
          reqData["doctor_partner_id"] = extraData.doctor_partner_id;
        }

        if(extraData.trainee_emails)
        {
          reqData["trainee_emails"] = extraData.trainee_emails;
        }
        if(extraData.doctor_practice_name)
        {
          reqData["doctor_practice_name"] = extraData.doctor_practice_name;
        }
        if(extraData.doctor_contact_name)
        {
          reqData["doctor_contact_name"] = extraData.doctor_contact_name;
        }
        if(extraData.doctor_practice_phone)
        {
          reqData["doctor_practice_phone"] = extraData.doctor_practice_phone;
        }

        reqData["doctor_partner_type"] = extraData.doctor_partner_type;
        reqData["training_type"] = extraData.training_type;

        if(extraData.old_event_id)
        {
            reqData["old_event_id"] = extraData.old_event_id;
        }
        if(extraData.event_training_status)
        {
            reqData["event_training_status"] = extraData.event_training_status;
        }
        if(extraData.doctor_practice_phone_country_code)
        {
            reqData["doctor_practice_phone_country_code"] = extraData.doctor_practice_phone_country_code;
        }
    }

    if(extraData.event_token)
    {
        reqData["event_token"] = extraData.event_token+"";
    }

    if(extraData.timezone_short_name)
    {
        reqData["timezone_short_name"] = extraData.timezone_short_name;
    }

    if(extraData.timezone_full_name)
    {
        reqData["timezone_full_name"] = extraData.timezone_full_name;
    }
    if (extraData && extraData.past_training === 1) {
        reqData["past_training"] = extraData.past_training;
    }
    if (extraData && extraData.country_id) {
      reqData["country_id"] = extraData.country_id;
  }
    this._api.api(reqData, 'insert_time_slot')
      .subscribe((data: any) => {
        console.log("data");
        console.log(data);
        if(!data.is_error)
        {
            if(callback)
            {
                callback(null, data);
            }
        }
        else
        {
            if(callback)
            {
                callback(data.err, null);
            }
        }
      }, (err: any) => {
          console.log("err");
          console.log(err);
          if(callback)
          {
              callback(err, null);
          }
      });
  }

  // autocomplete functions for admin, doctor and zip
  // fetching data
  fetchAdminList(event:any) {
    /*let data = {
      access_token: this._local.customGetItem('adminToken')
    };
    if ((event && event.length > 3)) {
      data['search_string'] = event;
    } else {
      return false;
    }
    this.admin.loading = true;
    this._api.api(data, 'fetch_admin_list', false)
      .subscribe((data: any) => {
        if (data.data && data.data.length) {
          this.admin.data = data.data;
        }
        this.admin.loading = false;
        this.admin.error = false;
      }, (error: any) => {
        this.admin.loading = false;
        this.admin.error = true;
        console.log(error);
      });*/
  }
  // fetching data
  fetchAvailableAdminList(event:any) {
    /*let data = {
      
      schedule_time: moment(this.apt_data.time).format("HH:mm"),
      schedule_timezone: this.apt_data.timezone?this.apt_data.timezone:'',
      duration: this.apt_data.duration?this.apt_data.duration:0,
      schedule_date: this.apt_data.date?moment(this.apt_data.date).format("YYYY-MM-DD"):''
    };
    if(!data.schedule_date){
      this._shared.customPopups('Please select date first', 2);
      return false;
    }
    if(!data.schedule_time){
      this._shared.customPopups('Please select time first', 2);
      return false;
    }
    if(!data.duration){
      this._shared.customPopups('Please select duration first', 2);
      return false;
    }
    if(!data.schedule_timezone){
      this._shared.customPopups('Please select timezone first', 2);
      return false;
    }
    if ((event && event.length > 3)) {
      data['search_string'] = event;
    } else {
      return false;
    }
    this.admin.loading = true;
    this._api.api(data, 'fetch_available_admins', false)
      .subscribe((data: any) => {
        if (data.data && data.data.length) {
          this.admin.data = data.data;
        }
        this.admin.loading = false;
        this.admin.error = false;
      }, (error: any) => {
        this.admin.loading = false;
        this.admin.error = true;
        console.log(error);
      });*/
  }


  fetchDoctorList(event: any) {
    let data: any = {
      access_token: this._local.customGetItem('adminToken')
    };
    if (event) {
      data['search_string'] = event;
    } else {
      return false;
    }
    if (this.searchDoctorTimeOutRef) {
      clearTimeout(this.searchDoctorTimeOutRef);
    }
    this.searchDoctorTimeOutRef = setTimeout(() => {
      this.searchDoctorTimeOutRef = null;
      this._api.api(data, this.selectedAppt.training_doctor_partner_type == 'doctor' ? 'search_doctors' : 'search_partner')
        .subscribe((data: any) => {
          if (data.data && data.data.length) {
            this.selectedAppt.doctors = data.data;
          }
          if (data.partner_list && data.partner_list.length) {
            this.selectedAppt.doctors = data.partner_list;
          }
        }, (error: any) => {

        });
    }, 500);
    return;
  }
  // clearing data // selecting data 
  selectApptAdmin(){
    //this.selectedAppt.selectedAvailableStartTime = null;
    var adminObj = this.selectedAppt.adminUsers.find((adminUser: any)=>adminUser.admin_id === this.selectedAppt.selectedAdminId);
    this.selectedAppt.selectedAdmin = adminObj;
    console.log(adminObj);
  }

  onTrainingSelect(){
    var trainingObj = this.selectedAppt.trainingTypes.find((training: any)=>training.type_id+"" === this.selectedAppt.selectedTraining+"");
    this.selectedAppt.trainingDuration = trainingObj ? trainingObj.duration : null;
    //vm.addTraining.duration = vm.trainingTypes.find((trType)=>trType.type_id === vm.addTraining.training_type).duration;
    //this.getDateWithTimeSlots();
    this.getAvailableSlotsOnAppointmentDate();
  }
  trainingDateChanged(){
    this.getAvailableSlotsOnAppointmentDate();
  }

  trainingTimezoneChanged(){
    //this.getDateWithTimeSlots();
    if(this.selectedAppt.viewType !== "newTraining")
    {
        var selectedUtcOffset = parseInt(this.selectedAppt.timezone.split("****")[1]);
        var applicableOffset = this.getApplicableOffsetBasedOnDaylight(this.selectedAppt.training_timezone_short_name, this.selectedAppt.training_timezone_offset, selectedUtcOffset);
        var schedule_date = moment(parseInt(this.selectedAppt.training_start_timestamp)*1000).utcOffset(applicableOffset).format("YYYY-MM-DD");
        var schedule_time = moment(parseInt(this.selectedAppt.training_start_timestamp)*1000).utcOffset(applicableOffset).format("hh:mm A");
        this.selectedAppt.schedule_date = schedule_date;
        this.selectedAppt.schedule_time = schedule_time;
    }
    this.getAvailableSlotsOnAppointmentDate();
  }

  getAvailableSlotsOnAppointmentDate(){
    this.selectedAppt.availableTimeSlots = [];
    this.selectedAppt.adminUsers = [];
    this.selectedAppt.availableAdminUsers = [];
    this.selectedAppt.availableStartTimes = [];
    this.selectedAppt.selectedAdminId = null;
    this.selectedAppt.selectedAdmin = null;
    this.selectedAppt.selectedAvailableStartTime = null;

    var selectedUtcOffset = parseInt(this.selectedAppt.timezone.split("****")[1]);
    var timezone_short_name = this.selectedAppt.timezone.split("****")[0];
    var timezoneHours = parseInt((selectedUtcOffset/60)+"");
    timezoneHours = timezoneHours < 0 ? (-1*timezoneHours) : timezoneHours;
    var timezoneMinutes = (selectedUtcOffset%60);
    timezoneMinutes = timezoneMinutes < 0 ? (-1*timezoneMinutes) : timezoneMinutes;
    var timezoneString = (selectedUtcOffset < 0 ? "-" : "+") + (timezoneHours < 10 ? "0":"") + timezoneHours +":"+(timezoneMinutes < 10 ? "0":"") + timezoneMinutes;
    var startDateString = (moment(this.selectedAppt.date).format("YYYY-MM-DD"))+"T00:00:00"+timezoneString;
    var startDateTimestamp = parseInt(moment(startDateString).utc().format("X"));
    var endDateTimestamp = startDateTimestamp+(24*60*60);
    var endDateString = moment(endDateTimestamp*1000).utcOffset(selectedUtcOffset).format();

    var reqData = {
      
      start_date: startDateString,
      end_date: endDateString,
      timezone_short_name: timezone_short_name,
      country_id: this.country_id,
      doctor_partner_type: this.data.doctor_partner_type
      
    }

    var thisRef = this;

    this._api.api(reqData, "get_time_slots_for_multiple_users")
    .subscribe((data: any) => {
      this.fetchAdminListApi((err: any, adminData: any)=>{
              var adminsSchedules = data.adminsSchedules;
              if(adminsSchedules)
              {
                  for (var key in adminsSchedules) {
                      var adminUsers = adminData.data;
                      var adminObj = adminUsers.find((adminUser: any)=>"admin"+adminUser.admin_id === key);
                      //if(thisRef.selectedAppt.viewType === "newTraining" || key !== "admin"+thisRef.selectedAppt.excludeAdminId)
                      if(thisRef.selectedAppt.viewType === "newTraining" || thisRef.selectedAppt.viewType === "editTraining")
                      {
                        //if(adminObj !== null)
                        if(adminObj)
                        {
                            //var adminColIndex = adminObj.admin_index;
                            var selectedDateStr = moment(thisRef.selectedAppt.date).format("YYYY-MM-DD");
                            var selectedDateObj = adminsSchedules[key].dates.find((dateObj: any)=>dateObj.date === selectedDateStr);
                            if(selectedDateObj)
                            {
                                var dateTimeSlots = selectedDateObj.timeSlots ? selectedDateObj.timeSlots : [];
                                dateTimeSlots = dateTimeSlots.filter((timeslot: any)=>timeslot.type === "available");
                                if(dateTimeSlots.length)
                                {
                                  dateTimeSlots.sort(function(a: any, b: any){
                                      return a.startMinutes+a.endMinutes > b.startMinutes+b.endMinutes ? 1 : -1;
                                  });
                                  var clubbedTimeSlots = [];
                                  for(var i = 0; i < dateTimeSlots.length; i++)
                                  {
                                      if(!clubbedTimeSlots.length)
                                      {
                                          clubbedTimeSlots.push(dateTimeSlots[i]);
                                      }
                                      else
                                      {
                                          if(dateTimeSlots[i].startMinutes === clubbedTimeSlots[clubbedTimeSlots.length - 1].endMinutes)
                                          {
                                              clubbedTimeSlots[clubbedTimeSlots.length - 1].endMinutes = dateTimeSlots[i].endMinutes;
                                          }
                                          else
                                          {
                                              clubbedTimeSlots.push(dateTimeSlots[i]);
                                          }
                                      }
                                  }

                                  var totalAvailableDurationOfAdmin = 0;
                                  for(var i = 0; i < clubbedTimeSlots.length; i++)
                                  {
                                    totalAvailableDurationOfAdmin += (clubbedTimeSlots[i].endMinutes - clubbedTimeSlots[i].startMinutes)
                                  }
  
                                  clubbedTimeSlots = clubbedTimeSlots.filter((timeSlot)=>(timeSlot.endMinutes - timeSlot.startMinutes) >= thisRef.selectedAppt.trainingDuration);
  
                                  var availableStartTimes = [];
  
                                  for(var i = 0; i < clubbedTimeSlots.length; i++)
                                  {
                                      for(var j = clubbedTimeSlots[i].startMinutes; j <= clubbedTimeSlots[i].endMinutes - thisRef.selectedAppt.trainingDuration; j+=thisRef.minTimeGap)
                                      {
                                        var timeHours = parseInt((j/60)+"");
                                        var timeMinutes = j%60;
                                        var timeString = (((timeHours >= 10 ? "" : "0")+timeHours) +":"+((timeMinutes >= 10 ? "" : "0")+timeMinutes));
                                          var fullDateTimeString = moment(thisRef.selectedAppt.date).format("YYYY-MM-DD")+"T"+timeString+":00"+timezoneString;
                                          if(parseInt(moment().utc().format("X")) < parseInt(moment(fullDateTimeString).utc().format("X")))
                                          {
                                            var timeAmPm = moment(moment(thisRef.selectedAppt.date).format("YYYY-MM-DD")+"T"+timeString).format("hh:mm A");
                                            var timeObj = {
                                              time: timeString, 
                                              fullDateTimeString: fullDateTimeString,
                                              timeAmPm: timeAmPm,
                                            }
                                            availableStartTimes.push(timeObj);
                                            if(thisRef.selectedAppt.availableStartTimes.filter((availableStartTimeObj: any)=>availableStartTimeObj.timeAmPm === timeAmPm).length === 0)
                                            {
                                              thisRef.selectedAppt.availableStartTimes.push(timeObj);
                                            }
                                          }
                                      }
                                  }
                                  if(availableStartTimes.length)
                                  {
                                    //adminObj["availableTimeSlots"] = clubbedTimeSlots;
                                    adminObj["availableStartTimes"] = availableStartTimes;
                                    adminObj["totalAvailableDuration"] = totalAvailableDurationOfAdmin;
    
                                    thisRef.selectedAppt.adminUsers.push(adminObj);
                                  }
                                }
                            }
                        }
                      }

                  }
                  thisRef.selectedAppt.availableStartTimes.sort(function(a: any,b: any){
                    return a.time > b.time ? 1 : -1
                  });
              }
      });
    });
      
  }

  selectTrainingDoctor(event: any) {
    var selectedDoctor: any = {};
    if (this.selectedAppt.training_doctor_partner_type == 'partner') {
      selectedDoctor = this.selectedAppt.doctors.find((doctor: any) => doctor.partner_id === this.selectedAppt.selectedDoctorId);
      selectedDoctor['doctor_name'] = selectedDoctor['partner_name'];
      selectedDoctor['doctor_email'] = selectedDoctor['partner_email'];
      selectedDoctor['doctor_mobile'] = selectedDoctor['partner_mobile'];
      selectedDoctor['doctor_mobile_country_code'] = selectedDoctor['partner_mobile_country_code']
    } else {
      selectedDoctor = this.selectedAppt.doctors.find((doctor: any) => doctor.doctor_id === this.selectedAppt.selectedDoctorId);
    }
    if (selectedDoctor) {
      this.selectedAppt.selectedDoctor = selectedDoctor;
      this.selectedAppt.selectedDoctor.doctor_emails = selectedDoctor['doctor_email'];
    }
    this.selectedMobileCode = selectedDoctor && selectedDoctor['doctor_mobile_country_code'];
  }

  isPhoneNumberValid(phoneNumber: any){
    var updatedPhoneNumber = phoneNumber ? (phoneNumber+"").split(" ").join("").split("+").join("").split("-").join("") : "";
    return (updatedPhoneNumber+"").length === 10 && /^\d+$/.test(updatedPhoneNumber);
  }

  isValidEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && (email.length <= 256);
  }

  isValidUrl(url: any) {
    var urlStrinhWithProtocol = this.getUrlWithProtocol(url);
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(urlStrinhWithProtocol);
  }

  getUrlWithProtocol(url: any){
    var protocols = ["http://","https://"];
    var hasProtocol = false;
    for(var i = 0; i < protocols.length; i++)
    {
      if(url.indexOf(protocols[i]) === 0)
      {
        hasProtocol = true;
      }
    }
    return (!hasProtocol ? "https://" : "")+url;
  }

  selectTrainingTime(selectedAvailableStartTime: any){
    this.selectedAppt.selectedAvailableStartTime = selectedAvailableStartTime;
    this.selectedAppt.selectedAdmin = null;
    this.selectedAppt.selectedAdminId = null;
    this.selectedAppt.availableAdminUsers = this.selectedAppt.adminUsers.filter((adminUser: any)=>{
      return adminUser.availableStartTimes.filter((availableStartTime: any)=>availableStartTime.timeAmPm === selectedAvailableStartTime.timeAmPm).length > 0;
    });
  }

  trainingDurationChanged(){
    this.getAvailableSlotsOnAppointmentDate();
  }

  addEmails(event: any, form: any) {
    //console.log('event.keyCode---',form,form.value.cEmail, form.controls['cEmail'].valid)
    /*if(form && form.value.cEmail && form.controls['cEmail'].valid){
      let index = this.doctorEmails.findIndex(x => x == form.value.cEmail);
      if (index == -1) {
        this.doctorEmails.push(form.value.cEmail)
      } else {
        this._shared.customPopups('Email already added', 2);
      }
      console.log(this.doctorEmails);
      form.controls['cEmail'].reset();
      //console.log('form---',form)
    }*/
    
  }

  removeEmail(email: any) {
    /*let index = this.doctorEmails.findIndex(x => x == email);
    if (index > -1) {
      this.doctorEmails.splice(index, 1);
    }*/
  }


  fetchEventDetailsApi(event_id: any, callback: any) {
    let reqData = {
      
        //admin_id: admin_id,
        event_id: event_id,
    };
    this._api.api(reqData, 'get_event_details')
    .subscribe((data: any) => {
        if(!data.is_error)
        {
            if(callback)
            {
                callback(null, data);
            }
        }
        else
        {
            if(callback)
            {
                callback(data.err, null);
            }
        }
    }, (err: any) => {
        if(callback)
        {
            callback(err, null);
        }
    });
  }

  updateEventStatusNotesApi(event_id: any, event_desc: any, training_status: any, send_email: any, extraData: any, callback: any) {
    let reqData: any = {
      
        training_status: training_status,
        training_desc: event_desc,
        event_id: event_id,
        send_email: send_email,
    };
    if (training_status) {
        reqData.training_status = training_status;
    }

    if (extraData.event_token) {
        reqData["event_token"] = extraData.event_token+"";
    }
    if (extraData.followup_date) {
      reqData["followup_date"] = extraData.followup_date;
    }

    this._api.api(reqData, 'update_event_status_notes')
    .subscribe((data: any) => {
        if (!data.is_error) {
            if (callback) {
                callback(null, data);
            }
        } else {
            if (callback) {
                callback(data.err, null);
            }
        }
    }, (err: any) => {
        if (callback) {
            callback(err, null);
        }
    });
  }

  fetchAdminListApi(callback: any) {
    if (this._shared.getAdminList() && this._shared.getAdminList().length > 0) {
      callback(null, {data: this._shared.getAdminList()});
    } else {
      setTimeout(() => {
        this.fetchAdminListApi(callback);
      }, 100);
    }
  }

  fetchTrainingStatusesApi(callback: any) {
    let reqData = { access_token: this._local.customGetItem('adminToken') };
    this._api.api(reqData, 'get_training_statuses')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          if (callback) {
            callback(null, data);
          }
        } else {
          if (callback) {
            callback(data.err, null);
          }
        }
      }, (error: any) => {
        console.log(error);
        if (callback) {
          callback(error, null);
        }
      });
  }

  cloneObj(obj: any) {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }
  resetTraineeSelection() {
    if (this.selectedAppt.out_of_system) {
      this.selectedAppt.selectedDoctor = {}; 
    } else {
      this.selectedAppt.selectedDoctor = null; 
    }
    this.selectedAppt.selectedDoctorId = null
  }
  changeMobileCode(mobile_code: any) {
    this.selectedMobileCode = mobile_code;
  }
  shouldShowOption(trainingStatusId: number, selectedAppt: any): boolean {
    if (trainingStatusId !== 1 && trainingStatusId !== 9) {
      return true;
    } else if (trainingStatusId === 9) {
      console.log(!selectedAppt.training_doctor_partner_id || selectedAppt.training_source === 'partner');
      return !selectedAppt.training_doctor_partner_id || selectedAppt.training_source === 'partner';
    }
    return false;
  }
}
