<div class="layout-wrapper">
    <div class="layout-wrapper-inner vh-100 overflow-hidden">
        <div class="layout-header px-3 py-2 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <img src="assets/images/icons/hamburger-menu.svg" alt="" class="d-xxl-none me-4"
                    (click)="openNavMenu()" style=" filter: brightness(10.5);">
                <img src="assets/images/icons/FM-logo.svg" alt="" class="me-4" (click)="openNavMenu()">
                
                <div class="btn-group me-4" dropdown>
                    <p id="button-basic" dropdownToggle class="text-white fs-16 fw-600 m-0" style="cursor: pointer;">
                        Timezone <span class="p-1 pt-0 radius-4 bg-danger fs-12 ms-1">{{selectedTimezone && selectedTimezone['val']}}</span> 
                      </p>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li *ngFor="let timezone of timezoneList" role="menuitem">
                            <a (click)="changeTimezone(timezone)" class="dropdown-item">{{timezone.val}}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown>
                    <p id="button-basic" dropdownToggle class="text-white fs-16 fw-600 m-0" style="cursor: pointer;">
                        Country <span class="p-1 pt-0 radius-4 bg-danger fs-12 ms-1">{{selectedCountry && selectedCountry['country_name']}}</span>
                      </p>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li *ngFor="let country of countryList" role="menuitem">
                            <a (click)="changeCountry(country)" class="dropdown-item">{{country.country_name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex">
                <div class="d-flex align-items-center me-3">
                    <label class="text-white fs-14 fw-600 mb-2">Live</label>
                    <div class="mx-2">
                        <label class="switch switch-md switch-success ">
                            <input type="checkbox" [checked]="isDummy" (change)="toggleChange()" />
                            <span></span>
                        </label>
                    </div>
                    <label class="text-white fs-14 fw-600 mb-2">Dummy</label>
                </div>
                <img src="assets/images/icons/profile_placeholder.png" class="profile-icon rounded-circle border hw-40"
                    alt="" (click)="showLogoutModal()">
            </div>
        </div>
        <div class="layout-body d-flex">
            <div class="side-menu-wrapper h-100 w-100 overflow-auto hide-scroll-bar border bg-white top-0 start-0"
                id="mySidenav">
                <img src="assets/images/icons/cross-icon.svg" alt=""
                    class="close-menu text-white d-xxl-none position-absolute top-0 end-0 mt-3 me-3 hw-24"
                    (click)="closeNavMenu()">
                <ul class="menu p-0 pt-3">
                    <li *ngFor="let menu of menuList" class="decoration-none px-3 pb-3 cursor">
                        <span class="d-flex w-100 justify-content-between" (click)="toggleMenu(menu)">
                            <p class="m-0 fs-14">
                                {{ menu.menu_name }}
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="height: 16px;"
                                class="toggle-arrow" *ngIf="menu.submenu" [ngClass]="{ 'rotate': menu.isOpen }">
                                <path
                                    d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        </span>
                        <ul class="sub-menu pl-1 overflow-hidden position-relative"
                            [ngClass]="{ 'active': menu.isOpen }">
                            <li *ngFor="let subMenu of menu.submenu" class="decoration-none p-1 fs-14"
                                (click)="toggleMenu(subMenu)">
                                {{ subMenu.menu_name }}
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
            <div class="right-content overflow-auto flex-fill">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>