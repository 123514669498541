import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PasswordStrengthService {
  calculatePasswordStrength(password: string): string {
    let strength = 0;

    if (!password) {
      return 'Empty';
    }

    // Minimum length requirement (6-12 characters)
    if (password.length >= 6 ) {
      strength += 1;
    }

    // Contains uppercase letters (A-Z) and Contains lowercase letters (a-z)
    if ((/[A-Z]/.test(password)) && (/[a-z]/.test(password))) {
      strength += 1;
    }

    // Contains numbers (0-9)
    if (/[0-9]/.test(password)) {
      strength += 1;
    }

    // Contains special characters (!@#$%^&*)
    if (/[!@#$%^&*]/.test(password)) {
      strength += 1;
    }

    switch (strength) {
      case 0:
        return 'Empty';
      case 1:
        return 'Weak Password';
      case 2:
        return 'Average Password';
      case 3:
        return 'Good Password';
      case 4:
        return 'Strong Password';
      // case 5:
      //   return 'Excellent';
      default:
        return 'Invalid';
    }
  }
}
export class PasswordSpaceValidator {  
  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
      if((control.value as string).indexOf(' ') >= 0){  
          return {cannotContainSpace: true}  
      }  
      return null;  
  }  
}  