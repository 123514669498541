import { Component } from '@angular/core';

@Component({
  selector: 'app-invalid',
  templateUrl: './invalid.component.html',
  styleUrl: './invalid.component.scss'
})
export class InvalidComponent {
  
}
