<form [formGroup]="editForm">
    <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
        <h4 class="modal-title fs-18 text-white ">Edit Contract</h4>
        <img src="assets/images/icons/white-cross.svg" alt="" (click)="bsModalRef.hide()" class="cursor hw-24">
    </div>
    <div class="card card-body p-4" *ngIf="type === 0">
        <div class="col-12 text-center mb-4 mt-4">
            <h4>This contract has cash adjustment payments. Please check the calculations before editing the contract.
            </h4>
        </div>
    </div>
    <div class="card card-body p-4 px-3" *ngIf="type === 1">
        <div class="row">
            <div class="col-sm-6 card-partition">
                <div class="contract-id">
                    <span class="text-bold contract-text fw-600 fs-20">Contract ID:</span>
                    <span class="contract-code fs-20 fw-400">{{contract_details.contract_code}}</span>
                    <span class="contract-status"
                        [ngClass]="{'text-danger':contract_details.pf_status==4||contract_details.pf_status==5,'text-warning':contract_details.pf_status==3,'text-success':contract_details.pf_status<3}">
                        {{contract_details.payment_status}}
                    </span>
                </div>
                <div class="inner-detail-form">
                    <div class="row contract-info">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Service Amount:</label>
                                <br>
                                <label class="fs-14">{{this.currency_logo}}{{contract_details.treatment_amount}}</label>
                            </div>
                        </div>
                        <!-- <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Downpayment Amount:</label>
                                <br>
                                <label
                                    class="fs-14">{{this.currency_logo}}{{contract_details.downpayment_amount}}</label>
                            </div>
                        </div> -->
                    </div>
                    <div class="row contract-info">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Financed Amount:</label>
                                <br>
                                <label class="fs-14">{{this.currency_logo}}{{contract_details.financed_amount}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Interest:</label>
                                <br>
                                <label class="fs-14">{{contract_details.interest_rate_to_show ||
                                    contract_details.interest_rate|number:'0.2-2'}}
                                    %
                                    <sup>{{!contract_details.interest_rate_to_show || (contract_details.interest_rate ==
                                        contract_details.interest_rate_to_show) ?
                                        (contract_details.interest_rate>0?'C':'') : 'F'}}</sup>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row contract-info">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Recurring Amount:</label>
                                <br>
                                <label class="fs-14">
                                    {{this.currency_logo}}{{contract_details.recurring_amount}} <span
                                        *ngIf="_shared.getTransactionFee(contract_details,contract_details.recurring_amount)">
                                        +
                                        {{_shared.getTransactionFee(contract_details,contract_details.recurring_amount)}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Total Payments:</label>
                                <br>
                                <label class="fs-14">{{contract_details.number_of_payments}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row contract-info">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Remaining Payments:</label>
                                <br>
                                <label class="fs-14">{{contract_details.remaining_payments}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Missing Payments:</label>
                                <br>
                                <label class="fs-14">{{contract_details.missing_payments}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row contract-info">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14">Total Remaining Amount:</label>
                                <br>
                                <label class="fs-14">{{this.currency_logo}}{{contract_details.remaining_amount}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14 mb-0">Remaining Principal Amount:</label>
                                <br>
                                <label>{{this.currency_logo}}{{(contract_details.principal_per_recurring *
                                    contract_details.remaining_payments) |number:'0.2-2'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="m-0 fs-14">F: Flat Interest, C: Compound Interest</p>
            </div>
            <div class="col-sm-6 card-partition">
                <div class="inner-form">
                    <!-- <div class="row">
                        <div class="col-sm-4 p-0">
                            <span class="pull-right">Interest from now</span>
                        </div>
                        <div class="col-sm-2">
                            <label class="switch switch-sm switch-success m-t-5">
                                <input type="checkbox" name="hard_copy" (change)="changeContractEditMode($event)"
                                    [(ngModel)]="edit_mode" />
                                <span></span>
                            </label>
                        </div>
                        <div class="col-sm-6 p-0">
                            <span class="pull-left">Interest from beginning</span>
                        </div>
                    </div>
                    <hr> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <span class="text-bold contract-text fs-20 fw-600">Interest From Beginning</span>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="fs-14 mb-2">Remaining Principal </label>
                                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                    <span class="bg-transparent border-0 h-100 hw-40  text-center">
                                        {{this.currency_logo}}
                                    </span>
                                    <input class="input_field border-0 border-start m-0 flex-fill rounded-0" readonly
                                        value="{{contract_details.remaining_principal |number:'0.2-2'}}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14 mb-2">Additional Finance </label>
                                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                    <div class="bg-transparent border-0 h-100 hw-40 text-center">
                                        {{this.currency_logo}}
                                    </div>
                                    <input type="number" class="input_field border-0 border-start m-0 flex-fill rounded-0" name="additional_finance_amount"
                                        formControlName="additional_finance_amount" [min]="doctor_settings.minimum_amount-contract_details.financed_amount"
                                        [max]="doctor_settings.maximum_amount-contract_details.financed_amount" (change)="CalculateNewFinancedAmount(); GetComparableValues();"
                                        >
                                </div>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['additional_finance_amount'].touched && editForm.controls['additional_finance_amount'].errors?.['required']">
                                    Enter a valid Additional finance amount</p>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['additional_finance_amount'].errors?.['max']">Enter
                                    Additional finance amount less than
                                    {{this.currency_logo}}{{doctor_settings.maximum_amount-contract_details.financed_amount}}
                                </p>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['additional_finance_amount'].errors?.['min']">
                                    Enter a Additional finance greater than or equal to
                                    {{this.currency_logo}}{{doctor_settings.minimum_amount-contract_details.financed_amount}}
                                </p>
                            </div>
                        </div>
                        <!-- <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14 mb-0">Downpayment:</label>
                                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                    <span class="bg-transparent border-0 h-100 hw-40 text-center">
                                        {{this.currency_logo}}
                                    </span>
                                    <input type="number" class="input_field border-0 border-start m-0 flex-fill rounded-0" required [min]="0"
                                        [max]="maxCheckValue(contract_details.remaining_principal+(editForm.controls['additional_finance_amount'].value))"
                                        name="new_downpayment_amount" formControlName="new_downpayment_amount"
                                        (change)="CalculateNewFinancedAmount();GetComparableValues()"                                        
                                        [disabled]="contract_details.contract_type == 2 || contract_details.contract_type == 4 || contract_details.contract_type == 5">
                                </div>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_downpayment_amount'].touched && editForm.controls['new_downpayment_amount'].errors?.['required']">
                                    Enter a valid Downpayment amount</p>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_downpayment_amount'].errors?.['max']">Enter the
                                    Downpayment amount less than
                                    {{this.currency_logo}}{{maxCheckValue(contract_details.remaining_principal+(editForm.controls['additional_finance_amount'].value))}}
                                </p>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_downpayment_amount'].errors?.['min']">Enter the
                                    Downpayment amount greater than or equal to 0</p>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14 mb-0">New Interest: </label>
                                    <input type="text" class="input_field" required [min]="doctor_settings.min_interest_rate"
                                        [max]="doctor_settings.max_interest_rate" name="new_interest_rate"
                                        formControlName="new_interest_rate" (change)="interestChanged()" [ngClass]="{'disabled-input': isContractDisabled()}" />
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_interest_rate'].touched && editForm.controls['new_interest_rate'].errors?.['required']">
                                    Enter a
                                    valid rate of interest</p>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_interest_rate'].errors?.['max']">Enter the rate of
                                    interest
                                    less than {{doctor_settings.max_interest_rate}}</p>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_interest_rate'].errors?.['min']">Enter the rate of
                                    interest
                                    greater than or equal to {{doctor_settings.min_interest_rate}}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="fs-14 mb-0">New Term:
                                    <a tooltip="New Remaining Payments" class="padd_r5 ml-2 fa-text-info">
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </label>
                                <div class="input-group">
                                    <input type="number" class="input_field" required
                                        min="{{(doctor_settings.minimum_tenure>contract_details.recovered_payments)?(doctor_settings.minimum_tenure-contract_details.recovered_payments):1}}"
                                        [max]="doctor_settings.maximum_tenure-contract_details.number_of_payments+contract_details.remaining_payments"
                                        name="new_term" formControlName="new_term"
                                        (change)="CalculateNewRecurringAmount()">
                                </div>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_term'].touched && editForm.controls['new_term'].errors?.['required']">
                                    Enter a valid term</p>
                                <p class="text-danger fs-10 m-0" *ngIf="editForm.controls['new_term'].errors?.['max']">Enter
                                    the term less than
                                    {{doctor_settings.maximum_tenure-contract_details.number_of_payments+contract_details.remaining_payments}}
                                </p>
                                <p class="text-danger fs-10 m-0" *ngIf="editForm.controls['new_term'].errors?.['min']">Enter
                                    the term greater than or
                                    equal to
                                    {{(doctor_settings.minimum_tenure>contract_details.recovered_payments)?(doctor_settings.minimum_tenure-contract_details.recovered_payments):1}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="fs-14 mb-0">New Financed Amount:
                                    <a tooltip="New Financed Amount = Remaining Principal + Additional Finance - Downpayment"
                                        class="padd_r5 ml-2 fa-text-info">
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </label>
                                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                   <span class="bg-transparent border-0 h-100 hw-40 text-center">
                                       {{this.currency_logo}}
                                   </span>
                                    <input class="input_field border-0 border-start m-0 flex-fill rounded-0" required name="new_financed_amount"
                                        formControlName="new_financed_amount" readonly>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label class="fs-14 mb-0">New Recurring Amount:</label>
                                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                    <span class="bg-transparent border-0 h-100 hw-40 text-center">
                                        {{this.currency_logo}}
                                    </span>
                                    <input type="number"  class="input_field border-0 border-start m-0 flex-fill rounded-0" name="new_recurring_amount"
                                    formControlName="new_recurring_amount" [min]="1" readonly>
                                </div>
                                <p class="text-danger fs-10 m-0"
                                    *ngIf="editForm.controls['new_recurring_amount'].errors?.['min']">Recurring amount
                                    can not be
                                    less than {{this.currency_logo}}1</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="text-center text-danger can-edit-message mt-2" *ngIf="financed_error">
            {{financed_error}}
        </div>
    </div>
    <div class="card card-body p-3 grey-background" *ngIf="type === 2">
        <div class="card-detail">
            <div>
                <div class="row">
                    <div class="col-sm-6 heading"></div>
                    <div class="col-sm-3 text-center old-detail heading-column">Old Contract Details </div>
                    <div class="col-sm-3 text-center heading-column">New Contract Details</div>
                </div>
            </div>
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Service Amount </div>
                    <div class="col-sm-3 text-center old-detail">
                        {{this.currency_logo}}{{contract_details.treatment_amount|number:'0.2-2'}}
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{this.currency_logo}}{{contract_details.treatment_amount +
                        (editForm.controls['additional_finance_amount'].value) |number:'0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Financed Amount </div>
                    <div class="col-sm-3 text-center old-detail">
                        {{this.currency_logo}}{{contract_details.financed_amount|number:'0.2-2'}}
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{this.currency_logo}}{{contract_details.financed_amount +
                        (editForm.controls['additional_finance_amount'].value) -
                        (editForm.controls['new_downpayment_amount'].value)|number:'0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Downpayment Amount </div>
                    <div class="col-sm-3 text-center old-detail">
                        {{this.currency_logo}}{{contract_details.downpayment_amount|number:'0.2-2'}}
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{this.currency_logo}}{{(editForm.controls['new_downpayment_amount'].value) +
                        contract_details.downpayment_amount|number:'0.2-2'}}
                    </div>
                </div>
            </div>

            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Interest Rate</div>
                    <div class="col-sm-3 text-center old-detail">
                        {{contract_details.interest_rate_to_show || contract_details.interest_rate|number:'0.2-2'}} %
                        <sup>{{!contract_details.interest_rate_to_show || (contract_details.interest_rate ==
                            contract_details.interest_rate_to_show) ? (contract_details.interest_rate>0?'C':'') :
                            'F'}}</sup>
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{(editForm.controls['new_interest_rate'].value)|number:'0.2-2'}} %
                        <sup>{{contract_details.interest_type}}</sup>
                    </div>
                </div>
            </div>
            <!-- <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Term </div>
                    <div class="col-sm-3 text-center old-detail">{{contract_details.number_of_payments}} months</div>
                    <div class="col-sm-3 text-center text-bold">{{(editForm.controls['new_term'].value)}} months</div>
                </div>
            </div> -->
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Total Payments </div>
                    <div class="col-sm-3 text-center old-detail">{{contract_details.number_of_payments}}
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{contract_details.number_of_payments - contract_details.remaining_payments +
                        (editForm.controls['new_term'].value)}}
                    </div>
                </div>
            </div>
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Remaining Payments </div>
                    <div class="col-sm-3 text-center old-detail">{{contract_details.remaining_payments}} </div>
                    <div class="col-sm-3 text-center text-bold">{{(editForm.controls['new_term'].value)}} </div>
                </div>
            </div>
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Recurring Amount </div>
                    <div class="col-sm-3 text-center old-detail">
                        {{this.currency_logo}}{{contract_details.recurring_amount}}
                        <span *ngIf="_shared.getTransactionFee(contract_details,contract_details.recurring_amount)">+
                            {{_shared.getTransactionFee(contract_details,contract_details.recurring_amount)}}</span>
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{this.currency_logo}}{{(editForm.controls['new_recurring_amount'].value ?
                        editForm.controls['new_recurring_amount'].value : contract_details.new_recurring_amount)}}
                        <span
                            *ngIf="_shared.getTransactionFee(contract_details,(editForm.controls['new_recurring_amount'].value))">
                            +
                            {{_shared.getTransactionFee(contract_details,(editForm.controls['new_recurring_amount'].value
                            ? editForm.controls['new_recurring_amount'].value :
                            contract_details.new_recurring_amount))}}</span>
                    </div>
                </div>
            </div>
            <div class="card-detailed-row">
                <div class="row">
                    <div class="col-sm-6 heading">Total Principal Amount </div>
                    <div class="col-sm-3 text-center old-detail">
                        {{this.currency_logo}}{{contract_details.principal_per_recurring *
                        contract_details.remaining_payments|number:'0.2-2'}}
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{this.currency_logo}}{{(editForm.controls['new_financed_amount'].value)|number:'0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="card-detailed-row" *ngIf="contract_details.new_advance_interest_amount">
                <div class="row">
                    <div class="col-sm-6 heading">Upfront Fee </div>
                    <div class="col-sm-3 text-center old-detail">
                        {{this.currency_logo}}{{contract_details.advance_interest_amount |number:'0.2-2'}}
                    </div>
                    <div class="col-sm-3 text-center text-bold">
                        {{this.currency_logo}}{{(contract_details.new_advance_interest_amount)?(contract_details.new_advance_interest_amount
                        |number:'0.2-2'):0.00}}
                    </div>
                </div>
            </div>
            <p class="row">F: Flat Interest, C: Compound Interest</p>
        </div>
    </div>
    <div class="card card-body p-2" *ngIf="type === 3">
        <div class="row" *ngIf="(editForm.controls['new_downpayment_amount'].value) > 0">
            <div class="col-sm-12 text-center payment-amount">
                Downpayment Amount:
                <h3>{{this.currency_logo}}{{(editForm.controls['new_downpayment_amount'].value)}}</h3>
            </div>
        </div>
        <div class="row"
            *ngIf="contract_details.new_advance_interest_amount > contract_details.advance_interest_amount">
            <div class="col-sm-12 text-center payment-amount">
                Remaining Payable Upfront Amount:
                <h3>{{this.currency_logo}}{{contract_details.new_advance_interest_amount -
                    contract_details.advance_interest_amount | number:'0.2-2'}}
                    +
                    {{_shared.getTransactionFee(contract_details,contract_details.new_advance_interest_amount -
                    contract_details.advance_interest_amount)}}
                </h3>
            </div>
        </div>
        <div class="form-group col-12" [ngClass]="{'pt-15': !(editForm.controls['new_downpayment_amount'].value)}">
            <label>Add Notes*</label>
            <textarea formControlName="notes" required type="text" class="input_field" trim="blur"></textarea>
            <!-- <p class="text-danger" *ngIf="true">Please enter the refund details</p> -->
        </div>
        <div class="col-12 text-left d-flex">
            <span class="d-flex">
                <input type="checkbox" formControlName="send_customer_notification" id="send_customer_notification" (change)="sendNotification($event)" class="secondary-checkbox"/>
                <label class="me-1" for="send_customer_notification"></label>Send notification to customer
            </span>
        </div>
        <div class="form-group col-12"
            *ngIf="(editForm.controls['new_downpayment_amount'].value) > 0 || (contract_details.new_advance_interest_amount > contract_details.advance_interest_amount)">
            <div class="text-center py-3"
                *ngIf="contract_details && contract_details.show_ach==1 && bank_accounts && bank_accounts.length>0 && bank_accounts[0].bank_status!='new'">
                <label class="m-t-10 col-lg-12 text-center"> Pay using customer's connected bank account</label>
            </div>
            <div class="col-12 mr-2" style="max-height:250px;overflow-y:auto"
                *ngIf="contract_details && contract_details.show_ach==1 && bank_accounts && bank_accounts.length>0 && bank_accounts[0].bank_status!='new'">
                <div *ngFor="let card of bank_accounts">
                    <div class="row border px-2 py-3 mb-2" *ngIf="card.payment_source_type==1">
                        <p class="m-0 col-sm-6 col-12"><i class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}}
                        </p>
                        <div class="col-sm-6 col-12 text-end">
                            <a (click)="Next(card)" tappable *ngIf="card.bank_status=='succeeded'"><u>Pay</u></a>
                            <p class="m-0 text-danger " *ngIf="card.bank_status=='reauthenticate'">Reauthentication
                                Required
                        </div>

                    </div>
                </div>

            </div>
            <div class="col-lg-12 pl-0"
                *ngIf="contract_details && contract_details.show_ach==1 && bank_accounts && bank_accounts.length>0 && bank_accounts[0].bank_status!='new'">
                <p class="text-muted m-b-0">*Note</p>
                <ul>
                    <li class="text-muted">Please make sure you have the appropriate funds available in your account.
                    </li>
                    <li class="text-muted">Processing time may take up to 7 business days.</li>
                    <li class="text-muted">In the event of a failed transaction, you may be subject to late fees and/or
                        failed transaction fees.</li>
                    <li class="text-muted">Your Next payment is due for {{contract_details.next_payment_date |
                        date:"MM/dd/yyyy": '+0000'}}. If the funds are not realized for the payment done through the
                        Bank before the next payment date we will attempt it.</li>
                </ul>
            </div>
            <div class="text-center py-3">
                <p
                    *ngIf="contract_details && contract_details.show_ach==1 && bank_accounts && bank_accounts.length>0 && bank_accounts[0].bank_status!='new'">
                    <b>OR</b>
                </p>
                <label class="m-t-10 col-lg-12 text-center"> Pay using customer's card(s)</label>
            </div>
            <div class="col-12 mr-2" style="max-height:250px;overflow-y:auto">
                <div *ngFor="let card of contract_details.payment_sources">
                    <div class="row border px-2 py-3 mb-2" *ngIf="card.payment_source_type!=1 ">
                        <p class="m-0 col-sm-6 col-12"><i
                                class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}</p>
                        <div class="col-sm-6 col-12 text-end">
                            <span class="m-0 col-6 " style="color:#36af47;"
                                *ngIf="card.source_id==contract_details.source_id">
                                <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                            <a (click)="Next(card)" tappable><u>Pay</u></a>

                        </div>
                        <p class="col-12 bg-danger" *ngIf="card.last_error">{{card.last_error||''}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer pl-0" *ngIf="type == 0">
        <div class="w-100 d-flex justify-content-end align-items-center">
            <button class="btn btn-outline me-2" (click)="bsModalRef.hide();">Cancel</button>


            <button class="btn btn-primary" (click)="type = 1;" style="min-width: 75px;">Ok</button>

        </div>
    </div>
    <div class="modal-footer pl-0" [ngClass]="{'grey-background': type === 2}" *ngIf="type != 0">
        <div class="row full-width">
            <div class="col-sm-6">
                <button class="btn btn-outline me-2 pull-left" (click)="type=type-1" *ngIf="type>1">Back</button>
            </div>
            <div class="col-sm-6 text-end">
                <button class="btn btn-primary" (click)="Next()" *ngIf="type !== 3"
                    [disabled]="type === 1 && (!editForm.valid || !(editForm.controls['new_recurring_amount'].value))">Next</button>
                <button class="btn btn-primary" (click)="Next()"
                    *ngIf="type === 3 && !(contract_details.new_advance_interest_amount > contract_details.advance_interest_amount)"
                    [disabled]="!editForm.get('notes')?.touched || editForm.get('notes')?.invalid">Save</button>
            </div>
        </div>
    </div>
</form>