import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../api.service';
import { SharedService } from '../../shared.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {

  public data: any;
  notes: any;
  note: any;

  images: any = [];
  selectedFile: any;
  uploading!: boolean;
  fileSubscription: any[] = [];
  imageURLs: any = [];
  fileProgress: any = [];
  @ViewChild('imageElement') imgElement!: ElementRef;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  notesForm!: UntypedFormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private _sanitizer: DomSanitizer,
    private _api: ApiService,
    public _shared: SharedService,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.notesForm = this._fb.group({
      notes: ['']
    })
  }

  hideModal() {
    this.passEntry.emit({success: false});
    this.bsModalRef.hide();
  }

  handleAddNotes() {

    if (!this.notesForm.value.notes || (this.notesForm.value.notes && !this.notesForm.value.notes.length)) {
      this._shared.customPopups('Please enter valid notes', 1);
      return;
    }
    let params = this.notesForm.value;
    console.log('this.data.type',this.data.type)
    switch (this.data.type) {
      case 1:
        params['doctor_id'] = this.data.id;
        break;
      case 2:
        params['patient_id'] = this.data.id;
        break;
      case 3:
        params['partner_id'] = this.data.id;
        break;
      case 4:
        params['contract_id'] = this.data.id;
        break;
        case 7:
        params['preapproval_leads'] = this.data.id;
        break;
      default:
        return ;
    }
    if (!params.notes || params.notes.length == 0 || params.notes.trim().length == 0) {
      this._shared.customPopups('Please enter a notes', 1);
    }
    if (this.images && this.images.length > 0) {
      params['docs_urls'] = this.images.map((img: any) => img.image);
    }
    if (this.uploading) {
      this._shared.customPopups('Please wait while files are uploading', 2);
      return;
    }

    this._api.api(params, 'customer/add_hidden_note')
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          this.passEntry.emit({success: true});
          this.hideModal();
        }
      })
  }

  fileSelect(event: any) {
    console.log(event);
    if (this.images && this.images.length > 10) {
      this._shared.customPopups('Can not upload more than 10 files', 2);
      return;
    }
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      for (let file of event.target.files) {
        let selectedFile = { file: file };
        if ((selectedFile.file.size / 1024 / 1024) > 10) {
          this._shared.customPopups('File size must be less than 10MB', 2);
          return;
        }
        if (selectedFile.file.type.indexOf('image/') === 0 || selectedFile.file.type.indexOf('application/') === 0) {
          let index = this.images.findIndex((img: any) => img.file.mediaFile.name == selectedFile.file.name);
          const reader = this.readFiles(selectedFile);
          if (index < 0) {
            this.images.push({ file: reader });
            this.uploadFile(reader, this.images.length - 1);
            this.imgElement.nativeElement.value = '';
          }
        } else {
          this._shared.customPopups('Please select valid file', 2);
        }
      }
    }
  }

  readFiles(file: any) {
    file.mediaFile = file.file;
    file.file = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.mediaFile));
    return file;
  }

  uploadFile(item: any, index?: any) {
    this.uploading = true;
    this.images[index].uploading = true;
    const formData = new FormData();
    formData.append('file', item.mediaFile);
    this.images[index].uploadSub = this._api.uploadApi(formData, 'assignments/upload_csr_doc', {
      reportProgress: true,
      observe: 'events'
    }).pipe(
        map((event: HttpEvent<any>) => {
          let response = [];
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / (event && event.total ? event.total : 1));
            response.push({ status: 'progress', message: percentDone });
          }
          if (event.type === HttpEventType.Response) {
            response.push(event.body);
          }
          return response;
        })
      )
      .subscribe(d => {
        this.images[index].subs = d;
        this.fileSubscription = d;
        let success = d.every((item: any) => {
          return item.status !== 'progress'
        });
        if (success) {
          this.uploading = false;
          this.images[index].uploading = false;
          d.forEach((item: any) => {
            this.imageURLs.push(item.images[0]);
            this.images[index].image = item.images[0];
          })

        } else {
          this.uploading = true;
          this.images[index].uploading = true;
        }
      });
  }

  deletFile(file: any, index: any) {
    this._api.deleteServerFile(file, 'user/delete_hard_request_doc')
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          this.images.splice(index, 1);
        }
      })
  }

  cancelImageUpload(file: any, index: any) {
    this.images[index].uploadSub.unsubscribe();
    this.images[index].uploading = false;
    this.images.splice(index, 1);
    this.images.every((item: any) => this.uploading = item.uploading);
  }

}
