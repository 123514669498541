<div class="editor">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
    <ngx-editor [editor]="editor" [(ngModel)]="html" [disabled]="false" (ngModelChange)="onEditorChange($event)" [placeholder]="'Type here...'"></ngx-editor>
    
    <!-- CodeMirror 6 for code editing -->
    <!-- <div #codemirror></div> CodeMirror editor will be initialized here -->
    <div class="editor-container" *ngIf="editHtml">
        <ngx-codemirror
          [options]="{
            lineNumbers: true,
            mode: 'javascript',
            theme: 'default',
            tabSize: 2,
            smartIndent: true,
            lineWrapping: true,
            readonly: false
          }" 
          [(ngModel)]="html">
        </ngx-codemirror>
      </div>
</div>

