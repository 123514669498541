import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { Filters } from 'src/app/components/shared/interface.component';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-business-leads',
  templateUrl: './business-leads.component.html',
  styleUrls: ['./business-leads.component.scss'],
})
export class BusinessLeadsComponent implements OnInit {
  totalRecords: number = 0;
  business_leads: any = [];
  resData: any = {
    pageNumber: 1,
  };
  dates: any = {
    start_date: '',
    end_date: '',
  };
  dateRangeValue: any;
  minDate: any = new Date(2024, 2, 5);
  maxDate: any;
  show_filters: boolean = false;
  timezone_subscription: any;
  public filters: Array<Filters> = [];
  search: any = '';
  timezone: any = localStorage.getItem('timezone');
  timezoneName: any = localStorage.getItem('timezoneName');
  status: any;
  paramSubscription: any = {};

  constructor(
    public _shared: SharedService,
    public _local: localStorageService,
    public _api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public filterService: FiltersService
  ) {}

  ngOnInit() {
    //TODO: Pending below
    // this.mainService.getMessage().subscribe((value: any) => {
    //   this.business_leads_info();
    // });
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.paramSubscription = this.route.queryParams.subscribe((data: any) => {
      this.resData.pageNumber =
        data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
      (this.search = data.search || ''),
        (this.dates.start_date = data.startDate);
      this.dates.end_date = data.endDate;
      this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
      if (this.dates.start_date)
        this.dateRangeValue = [this.dates.start_date, this.dates.end_date];
      this.show_filters = data.showFilterTab
        ? data.showFilterTab
        : this.show_filters;
      this.business_leads_info();
    });

    this.filters = this.filterService.getBusinessLeadsFilter();
  }

  business_leads_info() {
    let data = {
      limit: 10,
      offset: (this.resData.pageNumber - 1) * 10,
      search_string: this.search,
      start_date: this.dates.start_date,
      end_date: this.dates.end_date,
      filter: this.status,
      is_dummy: parseInt(this._local.customGetItem('is_dummy'), 10) ? 1 : 0,
    };
    this._api.api(data, 'customer/business_leads_list', 'post').subscribe(
      (data: any) => {
        if (!data.is_error) {
          this.totalRecords = data.number_of_records;
          this.business_leads = data.paignated_result;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  pageChanged(event: any) {
    this.resData.pageNumber = event.page;
    this.router.navigate(['/app/patients/customers-leads'], {
      queryParams: { page: this.resData.pageNumber },
      queryParamsHandling: 'merge',
    });
  }

  filterValues(event: any) {
    this.resData.pageNumber = 1;
    this.dates.start_date = this.datePipe.transform(
      event.data.startDate,
      'yyyy-MM-dd'
    );
    this.dates.end_date = this.datePipe.transform(
      event.data.endDate,
      'yyyy-MM-dd'
    );
    this.search = event.data.search || null;
    this.status = event.data.status || 0;
    this.modifyQueryParams();
  }
  resetFilters(event: any) {
    this.resData.pageNumber = 1;
    this.search = '';
    this.status = 0;
    this.modifyQueryParams();
  }

  modifyQueryParams() {
    this.router.navigate(['app/patients/customers-leads'], {
      queryParams: {
        page: this.resData.pageNumber,
        startDate: this.dates.start_date,
        endDate: this.dates.end_date,
        search: this.search,
        status: this.status,
        showFilterTab: this.show_filters,
      },
      queryParamsHandling: 'merge',
    });
  }

  showFilter(value: any) {
    this.show_filters = value;
  }

  leads_detail(data: any) {
    this.router.navigate([
      '/app/patients/leads/details/' + data.application_id,
    ]);
  }
  exportXLSX(ext: any) {
    let data = {
      limit: this.totalRecords,
      offset: (this.resData.pageNumber - 1) * 10,
      search_string: this.search,
      start_date: this.dates.start_date,
      end_date: this.dates.end_date,
      filter: this.status,
    };
    this._api.api(data, 'business_leads_list', 'post').subscribe(
      (data: any) => {
        let list = [];
        let leads_list;
        list[0] = {
          application_id: 'Application ID',
          approved_amount: 'Approved Amount($)',
          service: 'Service',
          customer_email: 'Customer Email',
          customer_mobile: 'Customer Mobile',
          customer_name: 'Customer Name',
          doctor_name: 'Business Owner Name',
          doctor_email: 'Business Owner Email',
          contract_id: 'Contract Id',
          resend_link_date: 'Resend link Date and Time',
          follow_up_date_time_utc: 'Follow-up Date and Time',
          status: 'Lead Status',
        };
        leads_list = data.paignated_result;
        for (var i = 0; i < leads_list.length; i++) {
          list[i + 1] = {};
          list[i + 1].application_id = leads_list[i].application_id;
          list[i + 1].approved_amount = leads_list[i].approved_amount;
          list[i + 1].service = leads_list[i].service;
          list[i + 1].customer_name =
            leads_list[i].customer_first_name +
            ' ' +
            leads_list[i].customer_last_name;
          list[i + 1].customer_email = leads_list[i].customer_email
            ? leads_list[i].customer_email
            : '-';
          list[i + 1].customer_mobile = leads_list[i].customer_mobile
            ? leads_list[i].customer_mobile
            : '-';
          list[i + 1].doctor_name =
            (leads_list[i].title ? leads_list[i].title + ' ' : '') +
            leads_list[i].doctor_name;
          list[i + 1].doctor_email = leads_list[i].doctor_email;
          list[i + 1].contract_id = leads_list[i].contract_id
            ? leads_list[i].contract_id
            : '-';

          list[i + 1].resend_link_date = (
            leads_list[i].resend_link_date
              ? this.datePipe.transform(
                  leads_list[i].resend_link_date,
                  'MM-dd-yyyy hh:mm a',
                  this.timezone
                )
              : '-'
          ) as string;
          list[i + 1].follow_up_date_time_utc = (
            leads_list[i].follow_up_date_time_utc
              ? this.datePipe.transform(
                  leads_list[i].follow_up_date_time_utc,
                  'MM-dd-yyyy hh:mm a',
                  this.timezone
                )
              : '-'
          ) as string;
          list[i + 1].status = leads_list[i].status;
        }
        if (ext == 0) {
          this._shared.exportAsExcelFile(list, 'businesses', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'businesses', '.csv');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy() {
    if (this.timezone_subscription) {
      this.timezone_subscription.unsubscribe();
    }
    if (this.paramSubscription){
      this.paramSubscription.unsubscribe();
    }
  }
}
