import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { Filters } from 'src/app/components/shared/interface.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent {
  private unsubscribe$ = new Subject<void>();

  country_id: any = null;
  country_subscription: any = null;

  filter_label = 0;
  patient_type = 0;
  is_dummy: any = this._local.customGetItem('is_dummy') || 0;
  patients: any;
  search_string: any = '';
  dates: any = {
    start_date: '',
    end_date: '',
  };
  timezone_subscription: any;
  hadSearched: boolean = false;
  resData: any = {
    pageNumber: 1,
  };
  subscription: any;
  dateRangeValue: any;
  minDate: any = new Date(2024, 0, 1);
  maxDate: any;
  resetBtn: boolean = false;
  timezone: any = this._local.customGetItem('timezone');
  timezoneName: any = this._local.customGetItem('timezoneName');
  sortValue: { by: string; order: string; label: string } = {
    by: 'date_registered',
    order: 'DESC',
    label: 'Registration Date',
  };
  sort: any = 1;
  show_filters: boolean = false;
  public filters: Array<Filters> = [];
  filter_added: boolean = false;
  paramSubscription: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public filterService: FiltersService,
    private _api: ApiService,
    public _shared: SharedService,
    private _local: localStorageService
  ) {}

  ngOnInit() {
    this.country_id = this._local.customGetItem('country_id');

    // this.subscription = this._shared
    //   .getMessage()
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((value: any) => {
    //     this.is_dummy = value;
    //     this.getPatients();
    //   });

    // this.country_subscription = this._shared
    //   .getCurrentCountry()
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((data) => {
    //     if (data) {
    //       this.country_id = data.country_id;
    //       this.getPatients();
    //     }
    //   });

    this.timezone = this._shared.getTimezone() || '';
    this.timezoneName = this._shared.getTimezoneName();
    this.filters = this.filterService.getCustomerFiltersArray();

    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.dates.start_date = this.datePipe.transform(
      this.dateRangeValue[0],
      'yyyy-MM-dd'
    );
    this.dates.end_date = this.datePipe.transform(
      this.dateRangeValue[1],
      'yyyy-MM-dd'
    );

    this.getPatients();

    this.paramSubscription = this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.show_filters = data.showFilterTab || this.show_filters;
        this.filter_label =
          data.type && parseInt(data.type) > 0 ? parseInt(data.type) : 0;
        this.patient_type =
          data.status && parseInt(data.status) > 0 ? parseInt(data.status) : 0;
        this.sortValue.by = data.by || 'date_registered';
        this.sortValue.order = data.order || 'DESC';
        this.search_string = data.search || this.search_string;
        if (!data.startDate && !data.endDate) {
          this.dates.start_date = this.datePipe.transform(
            this.dateRangeValue[0],
            'yyyy-MM-dd'
          );
          this.dates.end_date = this.datePipe.transform(
            this.dateRangeValue[1],
            'yyyy-MM-dd'
          );
        } else {
          this.dateRangeValue = [
            new Date(data.startDate),
            new Date(data.endDate),
          ];
        }
        this.resData.pageNumber =
          data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
        this.getPatients();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.paramSubscription){
      this.paramSubscription.unsubscribe();
    }
  }

  dateChanged(event: any) {
    this.dates.start_date = this.datePipe.transform(event[0], 'yyyy-MM-dd');
    this.dates.end_date = this.datePipe.transform(event[1], 'yyyy-MM-dd');
  }

  getPatients() {
    let data = {
      limit: 10,
      offset: (this.resData.pageNumber - 1) * 10,
      // is_dummy: this.is_dummy,
      filter: this.filter_label,
      patient_type: this.patient_type,
      start_date: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
      sort_by: this.sortValue.by,
      sort_order: this.sortValue.order,
      search_string: this.search_string || '',
      country_id: this.country_id,
    };
    if (!this.search_string || this.search_string.length === 0) {
      delete data.search_string;
    }
    this._api.api(data, 'customer/patient_list_V2', 'post').subscribe(
      (data: any) => {
        this.patients = data.data;
        this.resData.totalRecords = data.count;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  filterValues(event: any) {
    this.resData.pageNumber = 1;
    this.dates.start_date = this.datePipe.transform(
      event.data.startDate,
      'yyyy-MM-dd'
    );
    this.dates.end_date = this.datePipe.transform(
      event.data.endDate,
      'yyyy-MM-dd'
    );
    this.patient_type = event.data.status;
    this.filter_label = event.data.type;
    this.sort = event.data.sortBy;
    this.search_string = event.data.search;
    switch (parseInt(event.data.sortBy)) {
      case 1:
        this.sortValue = {
          by: 'date_registered',
          order: 'DESC',
          label: 'Registration Date',
        };
        break;
      case 2:
        this.sortValue = {
          by: 'patient_first_name',
          order: 'DESC',
          label: 'First Name',
        };
        break;
      case 3:
        this.sortValue = {
          by: 'patient_last_name',
          order: 'DESC',
          label: 'Last Name',
        };
        break;
      case 4:
        this.sortValue = {
          by: 'patient_code',
          order: 'DESC',
          label: 'Customer Code',
        };
        break;
      case 5:
        this.sortValue = {
          by: 'date_of_birth',
          order: 'DESC',
          label: 'Date Of Birth',
        };
        break;
      case 6:
        this.sortValue = { by: 'patient_email', order: 'DESC', label: 'Email' };
        break;
    }
    this.modifyQueryParams();
  }

  resetFilters(event: any) {
    this.resData.pageNumber = 1;
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.dates.start_date = this.datePipe.transform(
      this.dateRangeValue[0],
      'yyyy-MM-dd'
    );
    this.dates.end_date = this.datePipe.transform(
      this.dateRangeValue[1],
      'yyyy-MM-dd'
    );
    this.sortValue = {
      by: 'date_registered',
      order: 'DESC',
      label: 'Registration Date',
    };
    this.patient_type = 0;
    this.filter_label = 0;
    this.sort = 1;
    this.search_string = '';
    this.modifyQueryParams();
  }

  modifyQueryParams() {
    this.router.navigate(['/app/customers'], {
      queryParams: {
        page: this.resData.pageNumber,
        startDate: this.dates.start_date,
        endDate: this.dates.end_date,
        by: this.sortValue.by,
        order: this.sortValue.order,
        status: this.patient_type,
        type: this.filter_label,
        search: this.search_string,
        showFilterTab: this.show_filters,
        sortBy: this.sort,
      },
      queryParamsHandling: 'merge',
    });
  }

  showFilter(value: any) {
    this.show_filters = value;
  }

  pageChanged(event: any) {
    this.resData.pageNumber = event.page;
    this.router.navigate(['/app/customers'], {
      queryParams: { page: this.resData.pageNumber },
      queryParamsHandling: 'merge',
    });
  }

  exportXLSX(ext: any) {
    let data: any = {
      limit: this.resData.totalRecords,
      offset: 0,
      // is_dummy: this.is_dummy,
      filter: this.filter_label,
      patient_type: this.patient_type,
      sort_by: this.sortValue.by,
      start_date: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
      sort_order: this.sortValue.order,
      search_string: '',
    };
    if (this.search_string && this.search_string.length > 0) {
      data['search_string'] = this.search_string;
    } else {
      delete data.search_string;
    }
    this._api.api(data, 'customer/patient_list_V2', 'post').subscribe(
      (data: any) => {
        let list = [];
        let patients = data.data;

        list[0] = {
          date_registered: 'Date Registered',
          patient_name: 'Name',
          patient_email: 'Email',
          patient_mobile: 'Phone',
          date_of_birth: 'Date of Birth',
          patient_address: 'Address',
          patient_ssn: 'SSN',
          patient_code: 'Customer Code',
          is_email_verified: 'Email Verified',
        };

        for (let i = 0; i < patients.length; i++) {
          list[i + 1] = {};
          list[i + 1].date_registered = (
            patients[i].date_registered
              ? this.datePipe.transform(
                  patients[i].date_registered,
                  'MM-dd-yyyy',
                  this.timezone
                )
              : '-'
          ) as string;
          list[i + 1].patient_name =
            (patients[i].title ? patients[i].title + ' ' : '') +
            `${patients[i].patient_first_name} ${patients[i].patient_last_name}`;
          list[i + 1].patient_email = patients[i].patient_email;
          list[i + 1].patient_mobile = patients[i].patient_mobile;
          list[i + 1].date_of_birth = (
            patients[i].date_of_birth
              ? this.datePipe.transform(
                  patients[i].date_of_birth,
                  'MM-dd-yyyy',
                  '+0000'
                )
              : '-'
          ) as string;
          list[i + 1].patient_address = patients[i].patient_address
            ? patients[i].patient_address +
              (patients[i].city ? ', ' + patients[i].city : '') +
              (patients[i].state_name ? ', ' + patients[i].state_name : '') +
              (patients[i].zip ? ', ' + patients[i].zip : '')
            : '-';
          list[i + 1].patient_ssn =
            patients[i].patient_ssn && patients[i].patient_ssn != null
              ? '* * * * *' + patients[i].patient_ssn.substr(-4)
              : '';
          list[i + 1].patient_code = patients[i].patient_code;
          list[i + 1].is_email_verified =
            patients[i].is_email_verified == 1 ? 'Yes' : 'No';
        }

        if (ext == 0) {
          this._shared.exportAsExcelFile(list, 'customers', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'customers', '.csv');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
