<ng-container *ngIf="_proposal.modalTypes.resendProposalModal == modalType">
    <div class="modal-dialog modal-md  modal-dialog-centered ">
        <div class="modal-content border-0">
            <!-- <form> -->
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">
                    Resend Proposal Email
                </h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="modalHide()" class="cursor hw-24">
            </div>
            <div class="modal-body m-3">
                <div class="row">
                    <h4 class="fs-18 fw-600 mb-3">Are you sure, you want to resend the proposal to the customer(s)?</h4>
                    <p class="fs-14 fw-400 mb-2" *ngIf="_proposal.splitRequest && _proposal.splitRequest.patient_email">
                        Customer Email: {{_proposal.splitRequest.patient_email}}
                    </p>
                    <p class="fs-14 fw-400" *ngIf="_proposal.splitRequest && _proposal.splitRequest.patient_mobile">
                        Customer Mobile: {{_proposal.splitRequest.patient_mobile}}
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col pr-1">
                        <button class="btn btn-outline" (click)="modalHide()">No</button>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary" (click)="resendEmail()">Yes</button>
                    </div>
                </div>
            </div>
            <!-- </form> -->
        </div>
    </div>
</ng-container>

<ng-container *ngIf="_proposal.modalTypes.plansDetailModal == modalType">
    <div class="modal-dialog modal-lg  modal-dialog-centered " *ngIf="_proposal.splitRequest.contract_type != 6">
        <div class="modal-content border-0">
            <form>
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18" *ngIf="!extra_data.view_track_status">
                        Financing Plans
                    </h4>
                    <h4 class="modal-title text-white fs-18" *ngIf="extra_data.view_track_status">
                        Proposal Status
                    </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="modalHide()" class="cursor hw-24">
                </div>
                <div class="modal-body m-3" *ngIf="!extra_data.view_track_status">
                    <div class="row" *ngIf="_proposal.plansDetail && _proposal.plansDetail.length>0">
                        <div [ngClass]="{'col-6': _proposal.plansDetail && _proposal.plansDetail.length > 1, 'col-12':  _proposal.plansDetail && _proposal.plansDetail.length == 1}"
                            *ngFor="let plan of _proposal.plansDetail" style="box-shadow:none">
                            <div class="card border plan-box">
                                <div class="card-header">
                                    <div class="mb-0 pl-3 pr-3 row justify-content-between">
                                        <div>
                                            <h4>{{plan.month}} months</h4>
                                        </div>
                                        <div>
                                            <span
                                                *ngIf="_proposal.splitRequest.number_of_payments == plan.month && _proposal.splitRequest.apf_id != 0 && _proposal.splitRequest.contract_id == 0"
                                                class="badge bg-warning ml-auto pull-right">Plan Selected,
                                                Financing Details Pending</span>
                                            <span
                                                *ngIf="_proposal.splitRequest.number_of_payments == plan.month && _proposal.splitRequest.apf_id != 0 && _proposal.splitRequest.contract_id != 0"
                                                class="badge bg-success ml-auto pull-right">Selected</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="text-center" style="color: black;">
                                        <h3>{{currency_data.display_logo}}{{plan.recurring_amount | number: '1.2-2'}}
                                        </h3>
                                        <h4>per month</h4>
                                    </div>
                                    <div>
                                        <span>Estimated Service Amount:</span>
                                        <span class="pull-right">{{currency_data.display_logo}}{{plan.treatment_amount |
                                            number:
                                            '1.2-2'}}</span>
                                    </div>
                                    <div>
                                        <span>Down Payment:</span>
                                        <span
                                            class="pull-right">{{currency_data.display_logo}}{{plan.downpayment?plan.downpayment:plan.downpayment_amount}}</span>
                                    </div>
                                    <div>
                                        <span>Estimated Financed Amount:</span>
                                        <span class="pull-right">{{currency_data.display_logo}}{{(plan.treatment_amount
                                            -
                                            (plan.downpayment?plan.downpayment:plan.downpayment_amount)) | number:
                                            '1.2-2'}}</span>
                                    </div>
                                    <div>
                                        <span
                                            *ngIf="_proposal.splitRequest.contract_type == 2 || _proposal.splitRequest.contract_type == 4 || _proposal.splitRequest.contract_type == 5 || _proposal.splitRequest.contract_type == 6">
                                            Upfront Payment:</span>
                                        <span
                                            *ngIf="_proposal.splitRequest.contract_type == 1 || _proposal.splitRequest.contract_type == 3">
                                            First Recurring Due Today:
                                        </span>
                                        <span
                                            class="pull-right">{{currency_data.display_logo}}{{plan.advance_interest_amount
                                            |
                                            number: '1.2-2'}}</span>
                                    </div>
                                    <div>
                                        <span>Interest Rate:</span>
                                        <span
                                            class="pull-right">{{plan.interest_rate_to_show?plan.interest_rate_to_show:plan.interest_rate}}%
                                            <sup>{{!plan.interest_rate_to_show || (plan.interest_rate ==
                                                plan.interest_rate_to_show) ? (plan.interest_rate>0?'C':'') :
                                                'F'}}</sup>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Transaction Fee:</span>
                                        <span *ngIf="plan.getTransactionFee"
                                            class="pull-right">{{plan.getTransactionFee}}
                                            per payment</span>
                                    </div>
                                    <div>
                                        <span>Enrollment Fee:</span>
                                        <span class="pull-right"> {{currency_data.display_logo}}{{plan.enrollment_fee ?
                                            plan.enrollment_fee : 0}} </span>
                                    </div>
                                    <div>
                                        <span> Total Payable Amount:</span>
                                        <span
                                            class="pull-right">{{currency_data.display_logo}}{{plan.amount?plan.amount:plan.remaining_amount
                                            | number: '1.2-2'}}</span>
                                    </div>
                                    <div>
                                        <span><strong>Total Payable Today:</strong></span>
                                        <span class="pull-right"><strong>{{currency_data.display_logo}}{{plan.payable_amount_today
                                                |
                                                number: '1.2-2'}}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="col-sm-12 m-0">F: Flat Interest, C: Compound Interest</p>
                    </div>
                </div>
                <div class="modal-body m-3"
                    *ngIf="extra_data.view_track_status && extra_data.status_track_value && extra_data.status_track_value.length>0"
                    style="max-height:calc(100vh - 204px);overflow: auto;">
                    <div class="tracking_status_container border-left pl-5 pr-4 pb-3"
                        [ngClass]="{'border-left': ( status_data.reminder_type!=6 && !last),'upcoming_tracking':status_data.reminder_type==6}"
                        *ngFor="let status_data of extra_data.status_track_value;let last = last;">
                        <p class="mb-1">{{status_data.reminder_message}}</p>
                        <p class="m-0">{{status_data.reminder_date_message + ' '}} {{status_data.date_sent|
                            date:'MM/dd/yyyy (hh:mm a)'}}</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-dialog modal-lg  modal-dialog-centered " *ngIf="_proposal.splitRequest.contract_type == 6"
        [ngClass]="{'modal-sm': _proposal.plansDetail && _proposal.plansDetail.length == 1, 'modal-lg':  _proposal.plansDetail && _proposal.plansDetail.length > 1}">
        <div class="modal-content border-0">
            <form>
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">
                        Financing Plans
                    </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="modalHide()" class="cursor hw-24">
                </div>
                <div class="modal-body m-3">
                    <div class="row" *ngIf="_proposal.plansDetail && _proposal.plansDetail.length>0">
                        <div [ngClass]="{'col-6': _proposal.plansDetail && _proposal.plansDetail.length > 1, 'col-12':  _proposal.plansDetail && _proposal.plansDetail.length == 1}"
                            *ngFor="let plan of _proposal.plansDetail" style="box-shadow:none">
                            <div class="card border plan-box">
                                <div class="card-header">
                                    <div class="mb-0 pl-3 pr-3 row justify-content-between">
                                        <div>
                                            <h4>{{plan.month}} months</h4>
                                        </div>
                                        <div>
                                            <span
                                                *ngIf="_proposal.splitRequest.number_of_payments == plan.month && _proposal.splitRequest.apf_id != 0 && _proposal.splitRequest.contract_id == 0"
                                                class="badge bg-warning ml-auto pull-right">Plan Selected,
                                                Financing Details Pending</span>
                                            <span
                                                *ngIf="_proposal.splitRequest.number_of_payments == plan.month && _proposal.splitRequest.apf_id != 0 && _proposal.splitRequest.contract_id != 0"
                                                class="badge bg-success ml-auto pull-right">Selected</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div style="border-bottom: 1px solid #e3e1e1;">
                                        <div>
                                            <span style="font-family: Lato;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 700;">During Deferred Duration</span>
                                            <h3 style="font-family: Lato;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 24px;">
                                                {{currency_data.display_logo}}{{plan.recurring_amount | number:
                                                '1.2-2'}} /month</h3>
                                        </div>
                                        <div class="d-flex justify-content-between mb-2" style="
                                            font-family: Lato;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 700;">
                                            <span>Interest Rate:</span>
                                            <span>No Interest</span>
                                        </div>
                                    </div>
                                    <div style="border-bottom: 1px solid #e3e1e1;" class="mt-2 mb-2">
                                        <div>
                                            <span style="font-family: Lato;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 700;">After Deferred Duration</span>
                                            <h3 style="font-family: Lato;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 24px;">
                                                {{currency_data.display_logo}}{{plan.next_recurring_amount |
                                                number: '1.2-2'}}/month</h3>
                                        </div>
                                        <div class="d-flex justify-content-between mb-2" style="
                                            font-family: Lato;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 700;">
                                            <span>Interest Rate:</span>
                                            <span
                                                class="pull-right">{{plan.interest_rate_to_show?plan.interest_rate_to_show:plan.interest_rate}}%
                                                <sup>{{!plan.interest_rate_to_show || (plan.interest_rate ==
                                                    plan.interest_rate_to_show) ? 'C' : 'F'}}</sup>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="estimate mt-2">
                                        <span>Estimated Service Amount:</span>
                                        <span class="pull-right">{{currency_data.display_logo}}{{plan.treatment_amount |
                                            number:
                                            '1.2-2'}}</span>
                                    </div>
                                    <div class="estimate">
                                        <span>Down Payment:</span>
                                        <span
                                            class="pull-right">{{currency_data.display_logo}}{{plan.downpayment?plan.downpayment:plan.downpayment_amount}}</span>
                                    </div>
                                    <div class="estimate">
                                        <span>Estimated Financed Amount:</span>
                                        <span class="pull-right">{{currency_data.display_logo}}{{(plan.treatment_amount
                                            -
                                            (plan.downpayment?plan.downpayment:plan.downpayment_amount)) | number:
                                            '1.2-2'}}</span>
                                    </div>
                                    <div class="estimate">
                                        <span
                                            *ngIf="_proposal.splitRequest.contract_type == 2 || _proposal.splitRequest.contract_type == 4 || _proposal.splitRequest.contract_type == 6">Upfront
                                            Payment:</span>
                                        <span
                                            *ngIf="_proposal.splitRequest.contract_type == 1 || _proposal.splitRequest.contract_type == 3">First
                                            Recurring Due
                                            Today:</span>
                                        <span
                                            class="pull-right">{{currency_data.display_logo}}{{plan.advance_interest_amount
                                            |
                                            number: '1.2-2'}}</span>
                                    </div>
                                    <!-- <div class="estimate">
                                            <span>Interest Rate:</span>
                                            <span
                                                class="pull-right">{{plan.interest_rate_to_show?plan.interest_rate_to_show:plan.interest_rate}}%
                                                <sup>{{!plan.interest_rate_to_show || (plan.interest_rate ==
                                                    plan.interest_rate_to_show) ? 'C' : 'F'}}</sup>
                                            </span>
                                        </div> -->
                                    <div class="estimate">
                                        <span>Transaction Fee:</span>
                                        <span *ngIf="plan.getTransactionFee"
                                            class="pull-right">{{plan.getTransactionFee}}
                                            per payment</span>
                                    </div>
                                    <div class="estimate">
                                        <span>Enrollment Fee:</span>
                                        <span class="pull-right"> {{currency_data.display_logo}}{{plan.enrollment_fee ?
                                            plan.enrollment_fee : 0}} </span>
                                    </div>
                                    <div class="estimate">
                                        <span> Total Payable Amount:</span>
                                        <span
                                            class="pull-right">{{currency_data.display_logo}}{{plan.amount?plan.amount:plan.remaining_amount
                                            | number: '1.2-2'}}</span>
                                    </div>
                                    <div class="estimate">
                                        <span><strong>Total Payable Today:</strong></span>
                                        <span class="pull-right"><strong>{{currency_data.display_logo}}{{plan.payable_amount_today
                                                |
                                                number: '1.2-2'}}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="col-sm-12 m-0">F: Flat Interest, C: Compound Interest</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="_proposal.modalTypes.paymentSettingsModal == modalType">
    <div class="modal-dialog modal-lg  modal-dialog-centered "
        [ngClass]="{'modal-sm': _proposal.plansDetail && _proposal.plansDetail.length == 1, 'modal-lg':  _proposal.plansDetail && _proposal.plansDetail.length > 1}">
        <div class="modal-content border-0">
            <form *ngIf="_proposal.selected_req && _proposal.selected_req['selected_payment_matrix']">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Contract Payment Settings</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="modalHide()" class="cursor hw-24">
                </div>
                <div class="modal-body m-3">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label>Primary Payment Method*</label>
                            <select required
                                [(ngModel)]="_proposal.selected_req['selected_payment_matrix']['primary_type']"
                                name="primary_type" class="form-control" style="height: 40px;">
                                <option value="1">Bank</option>
                                <option value="2">Card</option>
                                <option value="3">Any</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-6">
                            <label>Recurring Payment Method*</label>
                            <select required
                                [(ngModel)]="_proposal.selected_req['selected_payment_matrix'].recurring_type"
                                name="recurring_type" class="form-control" style="height: 40px;">
                                <option value="1">Bank</option>
                                <option value="2">Card</option>
                                <option value="3">Any</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-6">
                            <label>Backup Payment Method*</label>
                            <select required [(ngModel)]="_proposal.selected_req['selected_payment_matrix'].backup_type"
                                name="backup_type" class="form-control" style="height: 40px;">
                                <option value="1">Bank</option>
                                <option value="2">Card</option>
                                <option value="3">Any</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-12">
                            <div class="row">
                                <div class="col-sm-10">
                                    <label>Is Backup card mandatory to customer?</label>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group mt-1">
                                        <label class="switch switch-sm switch-success ml-1 mr-1">
                                            <input type="checkbox"
                                                [(ngModel)]="_proposal.selected_req['selected_payment_matrix'].backup_required"
                                                name="backup_required" />
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-12">
                            <div class="row">
                                <div class="col-sm-10">
                                    <label>Unblock Contract creation for Overdue state?</label>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group mt-1">
                                        <label class="switch switch-sm switch-success ml-1 mr-1">
                                            <input type="checkbox"
                                                [(ngModel)]="_proposal.selected_req.skip_overdue_contract_check"
                                                (change)="toggleBlockContractForOverdue()" name="skip_overdue_check" />
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-default" (click)="modalHide()">Cancel</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-primary" (click)="editPaymentSettings()">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="_proposal.modalTypes.deleteProposal == modalType">
    <div class="modal-dialog modal-lg  modal-dialog-centered ">
        <div class="modal-content border-0">
            <div
                class="modal-header modal-header bg-primary d-flex align-items-center justify-content-between text-white fs-18">
                Delete Proposal
            </div>
            <div class="modal-body m-3">
                <p class="fs-14 fw400 mb-2">
                    Are you sure you want to delete this proposal ?
                    Notes:
                </p>
                <textarea class="input_field w-100" placeholder="Please enter notes"
                    [(ngModel)]="extra_data.notes"></textarea>
            </div>
            <div class="modal-footer">
                <div class="d-flex">
                    <button class="btn btn-outline me-2" (click)="modalHide()">Cancel</button>
                    <button class="btn btn-primary" (click)="deletePropopsal()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="_proposal.modalTypes.stop_resume_reminder == modalType">
    <div class="modal-dialog modal-lg  modal-dialog-centered ">
        <div class="modal-content border-0">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">
                    Confirmation
                </h4>
                <!-- <img src="assets/images/icons/white-cross.svg" alt="" (click)="modalHide()" class="cursor hw-24"> -->
            </div>
            <div class="modal-body m-3">
                <p class="fs-14 fw-400 mb-2">
                    Are you sure you want to {{extra_data.is_stop==1 ? 'stop' : 'Resume' }} this proposal reminder
                    {{extra_data.stop_to==1 ? 'Email':'SMS'}} ?
                </p>
                <textarea placeholder="Please enter notes" [(ngModel)]="extra_data.notes"
                    class="input_field"></textarea>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col">
                        <button class="btn btn-outline" (click)="modalHide()">Cancel</button>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary" (click)="stopResume()">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>