import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { currency_data } from "src/app/components/shared/interface.component";
import { ProposalService } from "../proposal.service";
import { ApiService } from "src/app/components/shared/api.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedService } from "src/app/components/shared/shared.service";
import { localStorageService } from "src/app/components/shared/localstorage.service";

@Component({
  selector: 'app-proposal-list-modal',
  templateUrl: './proposal-list-modal.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule,ReactiveFormsModule]
})

export class ProposalModalClassComponent {
  modalType: string = '';
  currency_data: currency_data = {
    bank_support_type: ['us_bank_account'],
    currency_id: 1,
    currency_code: "US Dollar",
    display_logo: "$"
  };
  parentType: string = this._proposal.parentType.proposal_list;
  extra_data: any = {
    allSelected: '',
    view_track_status: 0,
    status_track_value: '',
    request_id: '',
    notes: '',
    split_request_id: '',
    stop_to: 0,
    is_stop: 0
  }
  public deleteProposalform!: UntypedFormGroup;
  reasonList:any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor( private _fb: UntypedFormBuilder,public _proposal: ProposalService, private _api: ApiService, public bsModalRef: BsModalRef, public _shared: SharedService,public _local : localStorageService) { 

  }

  ngOnInit(): void {
 
    if (this._proposal.modalTypes.deleteProposal == this.modalType){
      // this.reasonList = this._local.customGetItem("delete_reason")
      this.reasonList = this._local.customGetItem("delete_reasons")
      this.deleteProposalform = this._fb.group({
        reasons: ['',Validators.required],
        notes:['',Validators.required],
        reasonNote:['']
      });
    }
  }
  async resendEmail() {
    if (this._proposal.splitRequest == 0) {
      this.sendBulkProposalMails();
      return;
    }
    let params = {
      split_req_id: this._proposal.splitRequest['split_req_id'],
      doctor_id: this._proposal.splitRequest.doctor_id
    };
    // hidemodal here
    this._api.api(params, 'finance/edit_proposal')
      .subscribe((data: any) => {
        if (data && !data.is_error) {
          this.passEntry.emit({ success: true, data });
          this._shared.customPopups("Successfully send proposal email",3);
          this.modalHide();
        } else {
          this.passEntry.emit({ success: false, data });
        }
      }, (error: any) => {
        console.log(error)
      }
      )
  }

  modalHide() {
    this.bsModalRef.hide();
  }

  cancel() {
    this.passEntry.emit({success: false});
    this.modalHide();
  }

  toggleBlockContractForOverdue() {
    let params = {
      split_req_id: this._proposal.selected_req['split_req_id'],
      skip_overdue_contract_check: this._proposal.selected_req.skip_overdue_contract_check ? 1 : 0,
    };
    this._api.api(params, 'edit_split_overdue_check')
      .subscribe((data: any) => {
      }, (error: any) => {
        console.log(error)
      })
  }

  editPaymentSettings() {
    let params = {
      split_req_id: this._proposal.selected_req['split_req_id'],
      primary_type: this._proposal.selected_req['selected_payment_matrix']['primary_type'],
      backup_type: this._proposal.selected_req['selected_payment_matrix']['backup_type'],
      recurring_type: this._proposal.selected_req['selected_payment_matrix']['recurring_type'],
      backup_required: this._proposal.selected_req['selected_payment_matrix']['backup_required'] ? 1 : 0,
    };
    this._api.api(params, 'business/edit_split_payment_settings')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.passEntry.emit({ success: true, data });
          this.modalHide()
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  deletePropopsal() {
    if (this.deleteProposalform.value.notes.trim() == "" && this.deleteProposalform.value.reasonNote.trim() == "") {
      this.deleteProposalform.controls['notes'].setValue("")
      this.deleteProposalform.controls['reasonNote'].setValue("")
      this._shared.customPopups("Please enter a valid note and Reason",1)
      return
    }
   
    if (this.deleteProposalform.value.notes.trim() == "") {
      this.deleteProposalform.controls['notes'].setValue("")
      this._shared.customPopups("Please enter a valid note",1)
      return
    }
    if (this.deleteProposalform.value.reasonNote.trim() == "" && this.deleteProposalform.controls['reasons'].value == 'Other') {
      this.deleteProposalform.controls['reasonNote'].setValue("")
      this._shared.customPopups("Please enter a valid Reason",1)
      return
    }
    const selectedValue = this.deleteProposalform.get('reasons')?.value; 
    const selectedReason = this.reasonList.filter((reason: any)=>{
        return reason.reason_notes === selectedValue
    })   
    const data = {
      split_req_id : this.extra_data.request_id,
      reason_id : selectedReason[0].reason_id,
      reason_notes: selectedReason[0].reason_id == 6 ? this.deleteProposalform.value.reasonNote : selectedReason[0].reason_notes,
      notes:this.deleteProposalform.value.notes
    } 
    this._api.api(data, 'finance/delete_proposal')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.passEntry.emit({ success: true, data });
          this.modalHide()
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  stopResume() {
    if (!this.extra_data.notes || (this.extra_data.notes && !(this.extra_data.notes.trim()))) {
      this.passEntry.emit({ success: false, err: 'Please enter notes' });
      return;
    }

    let params = {
      split_req_id: this.extra_data.split_id,
      notes: this.extra_data.notes,
      stop_reminder: this.extra_data.is_stop,
      stop_email: this.extra_data.stop_to == 1 ? 1 : null,
      stop_sms: this.extra_data.stop_to == 2 ? 1 : null
    };
    this._api.api(params, 'business/stop_resume_proposal_reminder')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.passEntry.emit({ success: true, data });
          this.modalHide()
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  sendBulkProposalMails() {
    let params = {
      split_req_ids: this._proposal.split_req_ids,
    };

    this._api.api(params, 'finance/bulk_resend_proposals')
      .subscribe((data: any) => {
        if (data && !data.is_error) {
          this.passEntry.emit({ success: true, data });
          this.modalHide();
        } else {
          this.passEntry.emit({ success: false, data });
        }
      }, (error: any) => {
        console.log(error)
      })
  }
}
