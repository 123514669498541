<div *ngIf="paymentProcessInfo">
	<div class="p-3">
		<h4 class="p-0 mb-4 col-md-10">Debt Recovery Details</h4>
		<div class="card">
			<div class="card-header  border-bottom">
				<div class="row py-1 align-items-start">
					<div class="col-md-4 col-sm-4">
						<h3 *ngIf="paymentProcessInfo.status == 1">Payment requested to customer</h3>
						<h3 *ngIf="paymentProcessInfo.status == 2">Payment ID:
							{{paymentProcessInfo.payment_process_code}}</h3>
						<h3 *ngIf="paymentProcessInfo.status == 3">Payment Failed
							<p class="text-danger">{{paymentProcessInfo.stripe_error || ''}}</p>
						</h3>
						<h3 *ngIf=" paymentProcessInfo.paid_full_payment_status == 'REVERSED'">Payment Refunded</h3>
						<h3 *ngIf="paymentProcessInfo.status == 5">Payment declined by customer</h3>
						<h3 *ngIf="paymentProcessInfo.status == 7">Payment Initiated</h3>
						<span class="badge badge-danger"
							*ngIf="paymentProcessInfo.dispute_id && paymentProcessInfo.dispute_id.length>0">
							Payment Disputed
						</span>
						<!-- <h3>Payment Refunded</h3> -->
					</div>
					<div class="col-md-2 col-sm-4">
						<div class="greenDiv" *ngIf="!_shared.routerAuth.restricted_buttons.refund">

							<h4 class="text-center">Finance Mutual Balance</h4>
							<h4 class="text-center">
								{{fm_balance ? '$'+ (fm_balance | number:'1.2-2'):'$0.00'}}<i
									class="fa fa-refresh ml-1" [ngClass]="{'animated-loader': balance_loader}"
									(click)="balance_loader=true;getAccountsReceivableInfo(paymentProcessInfo.split_req_id)"
									style="cursor: pointer;"></i>

							</h4>
						</div>
					</div>
					<div class="col-md-6 col-sm-4">
						<h3 class="m-0 text-end font-size-20">
							<span *ngIf="paymentProcessInfo?.status == 1" class="text-warning">Pending</span>
							<span *ngIf=" paymentProcessInfo.paid_full_payment_status == 'SUCCESS' && !paymentProcessInfo?.dispute_id"
								class="text-success">Success</span>
							<span *ngIf=" paymentProcessInfo.paid_full_payment_status == 'FAILED'" class="text-danger">Failed</span>
							<span *ngIf=" paymentProcessInfo.paid_full_payment_status == 'REVERSED' && !paymentProcessInfo?.dispute_id"
								class="text-danger">Refunded</span>
							<span *ngIf="paymentProcessInfo?.status == 5" class="text-danger">Declined</span>
							<span *ngIf=" paymentProcessInfo.paid_full_payment_status == 'INITIATED'" class="text-warning">Initiated</span>
							<span *ngIf="paymentProcessInfo?.dispute_id && paymentProcessInfo?.dispute_id!=null"
								class="text-danger">Disputed </span>
						</h3>
					</div>
				</div>
			</div>

			<div class="card-body">
				<div class="row">
					<div class="col-md-8">
						<div class="card card-body border payment-process mb-0">
							<div class="row">
								<div class="col-sm-6">
									<label>Business Name</label>
									<h6>
										<a [routerLink]="'/app/business/details/'+paymentProcessInfo.doctor_id"
											class="h4 text-primary text-bold">
											{{paymentProcessInfo.practice_name ? paymentProcessInfo.practice_name :
											(paymentProcessInfo.doctor_title+paymentProcessInfo.doctor_first_name+'
											'+paymentProcessInfo.doctor_last_name)}}

										</a>
									</h6>
								</div>
								<div class="col-sm-6">
									<label>Customer Name</label>
									<h6>
										{{paymentProcessInfo.patient_first_name && paymentProcessInfo.patient_last_name
										?
										paymentProcessInfo.patient_first_name + " " +
										paymentProcessInfo.patient_last_name :
										'-'}}

									</h6>
								</div>
								<div class="col-sm-6">
									<label>Payment ID</label>
									<h6>
										{{paymentProcessInfo.payment_id || '-'}}

									</h6>
								</div>
								<div class="col-sm-6">
									<label>Invoice Sent Date</label>
									<h6>
										{{paymentProcessInfo.date_added | date:'MM-dd-yyyy': timezone}}

									</h6>

								</div>
								<div class="col-sm-6">
									<label>Customer Email</label>
									<h6>
										{{paymentProcessInfo.patient_email || '-'}}

									</h6>

								</div>
								<div class="col-sm-6">
									<label>Customer Phone Number</label>
									<h6>{{paymentProcessInfo.patient_mobile_country_code && paymentProcessInfo.patient_mobile != null ? paymentProcessInfo.patient_mobile_country_code + "-" +
										paymentProcessInfo.patient_mobile : '-'}}</h6>

								</div>
								<div class="col-sm-6">
									<label>Payment Status</label>
									<h6>
										<span *ngIf="paymentProcessInfo.status == 1" class="text-warning">Pending</span>
										<span
											*ngIf="paymentProcessInfo.proposal_status == 'PAID_FULL' && !paymentProcessInfo.dispute_id"
											class="text-success">Success</span>
										<span *ngIf="paymentProcessInfo.status == 3" class="text-danger">Failed</span>
										<span *ngIf="paymentProcessInfo.status == 4 && !paymentProcessInfo.dispute_id"
											class="text-danger">Refunded</span>
										<span *ngIf="paymentProcessInfo.status == 5" class="text-danger">Declined</span>
										<span *ngIf="paymentProcessInfo.status == 7"
											class="text-warning">Initiated</span>
										<span
											*ngIf="paymentProcessInfo.dispute_id && paymentProcessInfo.dispute_id!=null"
											class="text-danger"> Disputed </span>

									</h6>

								</div>
								<div class="col-sm-6" *ngIf="paymentProcessInfo.last4">
									<label>Payment Method</label>
									<h6>
										<span class="" *ngIf="paymentProcessInfo.payment_source_type!=1"><i
												class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{paymentProcessInfo.last4}}</span>
										<span class="" *ngIf="paymentProcessInfo.payment_source_type==1"><i
												class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{paymentProcessInfo.last4}}
										</span>

									</h6>
								</div>
								<div class="col-sm-6">
									<label>Amount Owed</label>
									<h6>
										${{(paymentProcessInfo.debt_amount | number:'1.2-2')}}

									</h6>
								</div>
								<div class="col-sm-6">
									<label>Minimum Settlement Expected</label>
									<span class="d-flex">
										<h6>
											${{(paymentProcessInfo.debt_min_recovery_amount | number:'1.2-2')}}


										</h6>
										<p class="ms-2">(at
											{{paymentProcessInfo.discount_percent>0?paymentProcessInfo.discount_percent+'%':'NO'}}
											discount)</p>
									</span>
								</div>
								<div class="col-sm-6">
									<label>Settlement Amount Agreed</label>
									<span class="d-flex">
										<h6>
											${{(paymentProcessInfo.treatment_amount | number:'1.2-2')}}

										</h6>
										<p class="ms-2">(at
											{{paymentProcessInfo.discount_to_customer>0?paymentProcessInfo.discount_to_customer+'%':'NO'}}
											discount)</p>
									</span>
								</div>
								<div class="col-sm-6">
									<label>Amount Paid by Customer with Transaction</label>
									<h6>
										${{paymentProcessInfo.treatment_amount| number:'1.2-2' }} + ${{
										paymentProcessInfo.treatment_amount_transaction_fee | number:'1.2-2'}}
									</h6>
								</div>

								<div class="col-sm-6">
									<label>Business Transaction Amount</label>
									<h6>${{(paymentProcessInfo.doctor_transaction_fee | number:'1.2-2') || 0}}</h6>
								</div>
								<div class="col-sm-6">
									<label>Amount Received by Business</label>
									<div class="d-flex align-items-center">

										<h6 class="mb-0">
											{{paymentProcessInfo.amount_transfer_to_doctor ? '$'+
											(paymentProcessInfo.amount_transfer_to_doctor | number:'1.2-2'):'-'}}

										</h6>
										<span class="ms-2 pointer"
											*ngIf="paymentProcessInfo.payment_status!='Missed' && paymentProcessInfo.payment_status!='Future' && paymentProcessInfo.payment_status!='Pending' && paymentProcessInfo.charge_status!='Missed' && paymentProcessInfo.charge_status!='Dispute Awaited' && paymentProcessInfo.charge_status!='Dispute Lost'"
											data-placement="top" data-container="body" tooltip="View More">
											<!-- <i class="fa fa-eye" id="{{i}}_eye"
											(click)="setRefundData(paymentProcessInfo);refundModal.show();"
											*ngIf="!_shared.routerAuth.restricted_buttons.refund"></i> -->
										</span>
									</div>
								</div>
								<div class="col-sm-6">
									<label>Additional Amount Recovered</label>
									<h6 style="color:#00be8f;">{{ ( paymentProcessInfo.treatment_amount -
										paymentProcessInfo.debt_min_recovery_amount)>0 ? '$'+
										(( paymentProcessInfo.treatment_amount -
										paymentProcessInfo.debt_min_recovery_amount)
										| number:'1.2-2'):'$0'}}</h6>
									<p style="max-width: 150px;">
										<i *ngIf="paymentProcessInfo.ar_ez_discount_denefits_share_percent>0">*Finance
											mutual facilitates the process by retaining
											{{paymentProcessInfo.ar_ez_discount_denefits_share_percent}}% of
											any additional amount recovered.</i>
									</p>
								</div>
								<div class="col-sm-6" *ngIf="paymentProcessInfo.date_added">
									<label>Payment Date</label>
									<h6 class="hw-50 w-50">
										{{paymentProcessInfo.date_added | date:'MM-dd-yyyy': timezone}}
								
									</h6>
								</div>
								<div class="col-sm-6" *ngIf="paymentProcessInfo.patient_signature!=null">
									<p class="fs-14 fw-600 m-0 text-capitalize">
										Customer’s signature
									</p>
									<div
										class="d-flex align-items-center justify-content-center border radius-8 overflow-hidden mb-4 w-90 hw-90">
										<img [src]="paymentProcessInfo.patient_signature" alt="" class="h-100">
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card card-body border payment-process"
							*ngIf="paymentProcessInfo.contract_service_record && paymentProcessInfo.contract_service_record.length>0">

							<div class="row">
								<div class="col-12">
									<div class="table-responsive"
										*ngIf="paymentProcessInfo.contract_service_record && paymentProcessInfo.contract_service_record.length>0">

										<table class="table">
											<thead>
												<tr>
													<th>Service</th>
													<th class="text-right">Price</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let service of paymentProcessInfo.contract_service_record">
													<td>{{service.service}}</td>

													<td class="text-right">
														{{service.price?'$'+(service.price | number:'1.2-2'):'-'}}</td>

												</tr>
												<tr>
													<td>Discount </td>
													<td class="text-right text-bold">
														{{paymentProcessInfo.discount_to_customer != null ? paymentProcessInfo.discount_to_customer + '%' : "-" }}

													</td>
												</tr>
												<tr>
													<td>Transaction Fee </td>
													<td class="text-right text-bold">
														{{'$'+(paymentProcessInfo.treatment_amount_transaction_fee|
														number:'1.2-2')}}

													</td>
												</tr>
												<tr>
													<td>Total Payable By Customer</td>
													<td class="text-right text-bold">
														${{tottal_payable_by_customer | number:'1.2-2'}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div class="card card-body border payment-process"
							*ngIf="paymentProcessInfo.documents && paymentProcessInfo.documents.length>0">
							<div class="row">
								<div class="col-12">
									<div class="table-responsive">
										<table class="table">
											<!-- <thead>
													<tr>
														<th>Service</th>
														<th class="text-right">Price</th>
													</tr>
												</thead> -->
											<h4>Uploaded Documents</h4>
											<tbody>
												<tr *ngFor="let document of paymentProcessInfo.documents">
													<td class="normal-td">{{document.doc_name}}</td>
													<td class="normal-td">
														<a target="_blank" href="{{document.doc_url}}">
															<i class="fa fa-eye"></i>
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<div class="d-flex  p-3 border-top mt-4">
					<button class="btn btn-light border rounded-pill me-2" (click)="location.back()">Back</button>

					<div *ngIf="paymentProcessInfo.is_deleted == 0">
						<button class="btn btn-danger me-2 rounded-pill" (click)="deletePaymentModal()"
							*ngIf="paymentProcessInfo.status != 'SUCCESS' && paymentProcessInfo.status != 'INITIATED'">Delete</button>
						<button class="btn btn-danger me-2 rounded-pill" (click)="resendLinkModalOpen()"
							*ngIf="paymentProcessInfo.status == 1">Resend Link</button>
						<a class="btn btn-primary me-2 rounded-pill"
							*ngIf="paymentProcessInfo.proposal_status == 'PAID_FULL' && paymentProcessInfo.paid_full_payment_status == 'SUCCESS'"
							href="{{paymentProcessInfo.payment_receipt}}" target="_blank">
							Download Receipt
						</a>
						<button class="btn btn-primary me-2 rounded-pill"
							*ngIf="paymentProcessInfo.proposal_status == 'PAID_FULL' && paymentProcessInfo.paid_full_payment_status == 'SUCCESS'"
							(click)="sendReceiptLinkModalOpen()">Send Receipt Link</button>
						<button class="btn btn-primary me-2 rounded-pill"
							*ngIf="paymentProcessInfo.proposal_status == 'PAID_FULL' &&  paymentProcessInfo.paid_full_payment_status != 'REVERSED'"
							(click)="refundPaymentModal()" [disabled]="paymentProcessInfo && paymentProcessInfo.paid_full_payment_status == 'REVERSED'">Refund</button>
						<!-- 				
						<button class="btn btn-primary mr-1" *ngIf="paymentProcessInfo.is_plan == 1" (click)="plansModal()">View
							Plans</button> -->
						<button class="btn btn-primary me-2 rounded-pill" (click)="addContractNotesModal()">Add
							Note</button>
						<!-- <button class="btn btn-primary mr-2" (click)="DisputeTicketStatus.show()"
							*ngIf="paymentProcessInfo.dispute_id && paymentProcessInfo.dispute_id.length>0 && paymentProcessInfo.show_dispute_button">Change
							Dispute Ticket Status</button> -->
					</div>
				</div>
			</div>
			<div class="card card-body border p-2">
				<div class="mb-2 border-bottom">
					<button class="btn rounded-0 rounded-top" [ngClass]="{'btn-primary':showNewNotes , 'bg-light' : !showNewNotes}"
						(click)="showNewNotes = true">New Notes</button>
					<button class="btn rounded-0 rounded-top " [ngClass]="{'btn-primary':!showNewNotes, 'bg-light' : showNewNotes}"
						(click)="showNewNotes = false">Google Sheet Notes</button>
				</div>
				<div *ngIf="showNewNotes" style="max-height:500px;overflow:auto;">
					<div *ngIf="paymentProcessInfo && paymentProcessNotes && paymentProcessNotes.length">

						<app-export-csr-notes label="Export Notes" [notes]="paymentProcessNotes"
							[profile]="paymentProcessInfo" [type]="6"></app-export-csr-notes>
					</div>

						<div class="p-0">
							<div class="b">
								<!-- <div class="card-header">{{timezone?'(Time in ' + timezoneName+')':''}}</div> -->
								<div class="card-body">
									<div class="text-center border bg-lightgrey p-4  rounded"
										*ngIf="paymentProcessNotes && paymentProcessNotes.length == 0">
										<span class="fa fa-exclamation-triangle fa-5x"></span>
										<p class="m-0 mt-2">Notes not added yet!</p>
									</div>
									<!-- {{allNoteList | json}} -->
									<div class="b mb-1 p-2 rounded note-item" *ngFor="let ppnote of paymentProcessNotes">
										<div class="d-flex align-items-center mb-1">
											<i tappable class="fa fa-sign-in mr-2" [tooltip]="'Incoming Call'" *ngIf="ppnote.contact_type==1"></i>
											<i tappable class="fa fa-sign-out mr-2" [tooltip]="'Outgoing Call'" *ngIf="ppnote.contact_type==2"></i>
											<i tappable class="fa fa-envelope mr-2" [tooltip]="'Email'" *ngIf="ppnote.contact_type==3"></i>
											<i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Text'" *ngIf="ppnote.contact_type==4"></i>
											<i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Chat'" *ngIf="ppnote.contact_type==5"></i>
											<i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Verification Call'"
												*ngIf="ppnote.contact_type==6"></i>
											<i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Potential Business'"
												*ngIf="ppnote.contact_type==7"></i>
											<i tappable class="fa fa-commenting-o mr-2" [tooltip]="'Validation'" *ngIf="ppnote.contact_type==8"></i>
											<p class="m-0">
												<strong class="admin_name">{{ppnote.admin_name || ppnote.name}}</strong>
												{{ppnote.date_added | date:'medium':timezone}}
												<span *ngIf="ppnote.is_issue == 1" class="badge badge-warning ml-2">high
													priority</span>
												<a *ngIf="ppnote && ppnote.req_id && !ppnote.ach_id && ppnote.action_type != 116 && ppnote.action_type != 182"
													href="javascript:void(0)" [routerLink]="['/app/assignments/my-assignments', ppnote.req_id]">View
													Information</a>
											</p>
										</div>
								
										<div class="speech-bubble show-enter">
											<p style="margin-bottom:0;">
												<span *ngIf="ppnote && ppnote.action || ppnote.action_type == 162 || ppnote.action_type == 182"
													class="pr-1"><strong>Action:</strong>
													{{ppnote.action || ppnote.action_value}}</span>
												<span *ngIf="ppnote && ppnote.amount" class="pr-1"><br /><strong>Amount:</strong>
													${{ppnote.amount | number}}</span>
												<span *ngIf="ppnote && ppnote.payment_mode" class="pr-1"><br /><strong>Payment Mode:</strong>
													{{ppnote.payment_mode}}</span>
												<span *ngIf="ppnote && ppnote.missing_count" class="pr-1"><strong>Missing
														Count:</strong>
													{{ppnote.missing_count}}</span>
												<span *ngIf="ppnote && ppnote.next_payment_date" class="pr-1"><br /><strong>Next Payment
														Date:</strong>
													{{ppnote.next_payment_date | date:'MM/dd/yyyy':'+0000'}}</span>
												<span *ngIf="ppnote && ppnote.bankruptcy_status" class="pr-1"><br /><strong>Bankruptcy
														Status:</strong>
													{{ppnote.bankruptcy_status}}</span>
											</p>
											<p class="m-0">
												<span *ngIf="ppnote && ppnote.contact_type !== 0 && ppnote.contact_type_value"
													class="show-enter"><strong>Contact Type:</strong>
													{{ ppnote.contact_type_value.name }}</span>
											</p>
											<p class="m-0">
												<span *ngIf="ppnote && ppnote.contact_result !== 0 && ppnote.contact_result_value"
													class="show-enter"><strong>Contact Result:</strong>
													{{ ppnote.contact_result_value.name}}</span>
											</p>
											<p *ngIf="!ppnote.ach_id">
												<span *ngIf="ppnote && (ppnote.notes || ppnote.note)" class="show-enter"><strong>Notes:</strong>
													{{ppnote.notes || ppnote.note}}</span>
											</p>
											<p *ngIf="ppnote.ach_id">
												<span *ngIf="ppnote && (ppnote.notes || ppnote.note)" class="show-enter"><strong>Notes:</strong>
													{{ppnote.notes || ppnote.note}} {{ppnote.initiated_date |
													date:'MM/dd/yyyy':timezone}}</span>
											</p>
											<div *ngIf="ppnote && ppnote.file_url && ppnote.file_url.length>0"
												class="d-flex align-items-center">
												<a tappable [href]="ppnote.file_url" target="_blank"
													class="d-flex align-items-center justify-content-center m-1 border bg-dark"
													 style="height: 80px; width: 80px;">
													<img [src]="ppnote.thumb_url" alt="" class="img-fluid"
													(error)="onImageError($event)">
												</a>
												<!-- *ngFor="let doc of ppnote.file_url" -->
											</div>
								
										</div>
										<p *ngIf="ppnote && ppnote.assigned_name || ppnote.followup_date">
											<i tappable class="fa fa-user mr-2" [tooltip]="'Assigned to'"></i><b>
												{{ppnote.assigned_name?
												ppnote.assigned_name :
												'Not Assigned Yet'}}</b>
											|
											<i tappable class="fa fa-calendar mr-2"
												[tooltip]="'Follow up'"></i><b>{{ppnote.followup_date?(ppnote.followup_date
												| date:'MM/dd/yyyy hh:mm:ss aaa': timezone):'Not Available'}}</b>
											<br>
										</p>
										<p *ngIf="ppnote.is_deleted" class="text-danger m-0">This note has been deleted.</p>
									</div>
								</div>

					</div>
				</div> 
			<ng-template #emptyNewNotes>
					<div class="col-12 p-4 border text-center">
						<i class="fa fa-exclamation-triangle fa-5x"></i>
						<p>No notes added yet!</p>
						<p><a href="javascript:void(0)" (click)="addContractNotesModal()">Click here</a> to add a new
							note.</p>
					</div>
				</ng-template>
		</div>

									<!-- <div class="card card-body border p-2">
			<div class="mb-2 border-bottom">
			  <button class="btn rounded-0 rounded-top" [ngClass]="{'btn-primary':showNewNotes}"
				(click)="showNewNotes = true">New Notes</button>
			  <button class="btn rounded-0 rounded-top" [ngClass]="{'btn-primary':!showNewNotes}"
				(click)="showNewNotes = false">Google Sheet Notes</button>
			</div>
			<div *ngIf="showNewNotes" style="max-height:500px;overflow:auto;">
			  <div *ngIf="paymentProcessInfo && paymentProcessNotes && paymentProcessNotes.length; else emptyNewNotes">
		
				<app-export-csr-notes label="Export Notes" [notes]="paymentProcessNotes"
				  [profile]="paymentProcessInfo" [type]="6"></app-export-csr-notes>
		
				<div class="p-0">
				  <div class="b">
					<div class="card-header">{{timezone?'(Time in ' + timezoneName+')':''}}</div>
					<div class="card-body">
					  <div class="text-center border bg-secondary p-4  rounded" *ngIf="paymentProcessNotes && paymentProcessNotes.length == 0">
						<span class="fa fa-exclamation-triangle fa-5x"></span>
						<p class="m-0 mt-2">Notes not added yet!</p>
					  </div>
					  <div class="b mb-1 p-2 rounded note-item" *ngFor="let ppnote of paymentProcessNotes">
						<div class="d-flex align-items-center mb-1">
						  <p class="m-0">
							<strong class="admin_name">{{ppnote.name?ppnote.name:'N.A.'}}</strong>
							{{ppnote.date_added | date:'medium':timezone}}
						  </p>
						</div>
					</div>
				</div>
				<ng-template #emptyNewNotes>
					<div class="col-12 p-4 border text-center">
						<i class="fa fa-exclamation-triangle fa-5x"></i>
						<p>No notes added yet!</p>
						<p><a href="javascript:void(0)" (click)="addContractNotesModal()">Click here</a> to add a new note.</p> 
					</div>
				</ng-template>
			</div>
		  </div> -->
								</div>
							</div>
						</div>

						<!-- <div bsModal #deletePaymentInfoModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-md  modal-dialog-centered customModal">
		<div class="modal-content">
			<form #deletePaymentInfoForm="ngForm" (ngSubmit)="deletePaymentInfo(deletePaymentInfoModal)">
				<div class="modal-header">
					<h4 class="modal-title">
						Delete Payment Info
					</h4>
					<button type="button" class="close" aria-label="Close"
						(click)="deletePaymentInfoModal.hide();">&times;</button>
				</div>
				<div class="modal-body">
					<div class="col-12 pl-0">
						<h4 style="font-weight: 500;">Are you sure you want to delete this payment info?</h4>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-default" (click)="deletePaymentInfoModal.hide();">No</button>
					<button class="btn btn-primary">Yes</button>
				</div>
			</form>
		</div>
	</div>
</div>

<div bsModal #resendLinkModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-md  modal-dialog-centered customModal">
		<div class="modal-content">
			<form #resendLinkForm="ngForm" (ngSubmit)="resendLinkFn(resendLinkModal)">
				<div class="modal-header">
					<h4 class="modal-title">
						Resend Payment Request Link
					</h4>
					<button type="button" class="close" aria-label="Close"
						(click)="resendLinkModal.hide();resendLinkForm.resetForm();">&times;</button>
				</div>
				<div class="modal-body">
					<div class="col-12 pl-0">
						<h4 style="font-weight: 500;">Are you sure, you want to resend the payment request link to the
							following contact information?</h4>
						<div class="form-group">
							<input type="email" email class="form-control"
								[ngClass]="{'has-error':email.invalid && email.touched}" placeholder="Email Address"
								[(ngModel)]="user_email1" #email="ngModel" name="email">
							<p *ngIf="email.invalid && email.touched" class="text-danger">Please enter a valid email</p>
						</div>
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text" id="inputGroupPrepend3">+1</span>
								</div>
								<input type="text" maxlength="15" minlength="12" #phone="ngModel" class="form-control"
									[(ngModel)]="user_phone1" name="phone" type="text"
									[textMask]="{mask: mask , guide:false}">
							</div>
							<p *ngIf="phone.invalid && phone.touched" class="text-danger">Please enter a valid mobile
								number
							</p>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-primary" type="submit">Resend</button>
				</div>
			</form>
		</div>
	</div>
</div>

<div bsModal #sendReceiptLinkModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-md  modal-dialog-centered customModal">
		<div class="modal-content">
			<form #sendReceiptLinkForm="ngForm"
				(ngSubmit)="sendReceiptLinkFn(sendReceiptLinkForm,sendReceiptLinkModal)">
				<div class="modal-header">
					<h4 class="modal-title">
						Send Receipt Link
					</h4>
					<button type="button" class="close" aria-label="Close"
						(click)="sendReceiptLinkModal.hide();sendReceiptLinkForm.resetForm();">&times;</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<input type="email" email class="form-control"
							[ngClass]="{'has-error':email.invalid && email.touched}" placeholder="Email Address"
							[(ngModel)]="user_email" #email="ngModel" name="email">
						<p *ngIf="email.invalid && email.touched" class="text-danger">Please enter a valid email</p>
					</div>
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text" id="inputGroupPrepend3">+1</span>
							</div>
							<input type="text" maxlength="15" minlength="12" #phone="ngModel" class="form-control"
								[(ngModel)]="user_phone" name="phone" type="text"
								[textMask]="{mask: mask , guide:false}">
						</div>
						<p *ngIf="phone.invalid && phone.touched" class="text-danger">Please enter a valid mobile number
						</p>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-primary" type="submit">Send</button>
				</div>
			</form>
		</div>
	</div>
</div>

<div bsModal #refundPaymentInfoModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-md  modal-dialog-centered customModal">
		<div class="modal-content">
			<form #refundPaymenForm="ngForm" (ngSubmit)="refundPaymentInfo(refundPaymentInfoModal)">
				<div class="modal-header">
					<h4 class="modal-title">
						Refund Payment
					</h4>
					<button type="button" class="close" aria-label="Close"
						(click)="refundPaymentInfoModal.hide();">&times;</button>
				</div>
				<div class="modal-body" *ngIf="paymentProcessInfo">
					<div class="col-12 pl-0">
						<h4 style="font-weight: 500;">
							Are you sure you want to refund
							${{paymentProcessInfo.amount + paymentProcessInfo.patient_transaction_fee | number:'1.2-2'}}
							<span *ngIf="paymentProcessInfo.name" class="f-s-18"> to {{paymentProcessInfo.name}}</span>
							<span *ngIf="paymentProcessInfo.patient_email || paymentProcessInfo.patient_mobile"
								class="f-s-18"> at
								{{paymentProcessInfo.patient_email || paymentProcessInfo.patient_mobile}}</span>
						</h4>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-default" (click)="refundPaymentInfoModal.hide();">No</button>
					<button class="btn btn-primary">Yes</button>
				</div>
			</form>
		</div>
	</div>
</div>

<div bsModal #plansModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal" tabindex="-1"
	role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg  modal-dialog-centered" style="max-width: 90%!important;">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">
					Plans
				</h4>
				<button type="button" class="close" aria-label="Close" (click)="plansModal.hide();">&times;</button>
			</div>
			<div class="modal-body"
				*ngIf="paymentProcessInfo && paymentProcessInfo.plan_array && paymentProcessInfo.plan_array.length>0">
				<div class="row">
					<div class="col-12 col-sm-6 col-md-4" *ngFor="let plan of paymentProcessInfo.plan_array">
						<div class="card border border-primary">
							<div class="card-header bg-primary text-center">
								<h3 class="card-title">{{plan.month}} months</h3>
							</div>
							<div class="card-body">
								<div class="text-center">
									<span
										style="font-size:26px !important;">{{plan.recurring_amount|currency:'$'}}</span>
									<h4 class="m-0"> per month</h4>
								</div>
								<div>

									<div class="row">
										<div class="col-7">
											<span>Interest Rate:</span>
										</div>
										<div class="col-5 text-right">
											<span>{{plan.interest_rate_to_show || plan.interest_rate}}%
												<sup>{{!plan.interest_rate_to_show || (plan.interest_rate ==
													plan.interest_rate_to_show) ? (plan.interest_rate>0?'C':'') :
													'F'}}</sup>
											</span>
										</div>
									</div>
									<div class="row">
										<div class="col-6">
											<span>Transaction Fee:</span>
										</div>
										<div class="col-6 text-right">

											<span
												*ngIf="GetTransactionFee(paymentProcessInfo, plan.advance_interest_amount?plan.advance_interest_amount:plan.recurring_amount)">{{GetTransactionFee(paymentProcessInfo,
												plan.advance_interest_amount?plan.advance_interest_amount:plan.recurring_amount)}}
												per payment</span>
										</div>
									</div>
									<div class="row">
										<div class="col-7">
											<span>Upfront Payment:</span>

										</div>
										<div class="col-5 text-right">
											<span>
												{{plan.advance_interest_amount|currency:'$'}}
											</span>
										</div>
									</div>
									<div class="row">
										<div class="col-8">
											<span class="text-bold">Total Payable Today:
											</span>
										</div>
										<div class="col-4 text-right">
											<span>{{(plan.payable_amount_today || 0)|currency:'$'}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="m-0">F: Flat Interest, C: Compound Interest</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-default" (click)="plansModal.hide();">Ok</button>
			</div>
		</div>
	</div>
</div> -->

						<!-- <div bsModal #DisputeTicketStatus="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal" tabindex="-1"
	role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
	(onShow)="getDocumentsToUpload();innerOpened=false" (onHide)="partner_documets_upload = 0">
	<div class="modal-dialog modal-md  modal-dialog-centered">
		<div class="modal-content">

			<form #DisputecontractSharee="ngForm"
				(ngSubmit)="ChangeDisputeTicket(DisputecontractSharee,DisputeTicketStatus)">

				<div class="modal-header">
					<h4 class="modal-title">Contract Dispute Ticket Note</h4>
					<button type="button" class="close" aria-label="Close"
						(click)="DisputeTicketStatus.hide();">&times;</button>
				</div>

				<div class="modal-body">
					<div class="form-group col-12">

						<div class="">
							<div class="d-flex align-items-center justify-content-between fs-16 f-c-p-1  mb-2">
								<!-- <h6 class="mb-0 fw-800">Upload Documents (Optional)</h6> <span class="fw-600 fs-14" *ngIf="contract">contract Id: {{contract.contract_code}}</span> -->
						<!-- 
								<span class="fs-16 fw-700 f-c-p-1">Submitted Documents</span>
								<div class="mt-1">
									<div class="d-flex align-items-center">
										<span class="f-c-p-1 fs-16">No</span>
										<label class="check_switch switch switch-md ml-1 mr-1 mb-0">
											<input type="checkbox" [(ngModel)]="partner_documets_upload"
												name="partner_documets_upload" />
											<span></span>
										</label>
										<span class="f-c-p-1 fs-16">Yes</span>
									</div>
								</div> -->
						<!-- 
							</div>
							<div class="title_dispute_popup f-c-p-7 fs-14 mb-3">
								Change dispute requested documents to Verified status
							</div>

							<div class="card_upload_docs">
								<accordion>
									<accordion-group [panelClass]="'px-0 custom_accord business-inner-cards'"
										heading="Requested Document(s)" [isOpen]="innerOpened"
										class="px-0 f-c-p-7 fs-16 fw-700">
										<div class="fs-14 f-c-p-7 mb-3">Max file size: 10MB | Supported files: pdf, png,
											jpeg, jpg</div>
										<div style="max-height: 300px;overflow-x: auto;"
											class="pr-3 container_upload_docs">
											<ng-container *ngFor="let doc of disputeDocs;let i = index;">
												<div class="d-flex mb-3"
													[ngClass]="[(doc && doc.mannual_file && disputeDocs.length == (i + 1)) ? 'pb-4 align-items-end justify-content-between': 'align-items-center justify-content-between']">
													<span class="pr-3 d-flex">
														<span>{{i+1}}.</span>
														<span class="d-inline-block ml-3 fw-500 f-c-p-1"
															*ngIf="doc && !doc.mannual_file">
															{{doc.document_name}}
														</span>
														<div *ngIf="doc && doc.mannual_file"
															style="width: 100% !important; min-width: 250px;"
															class="fs-14 ml-3 f-c-p-1 d-flex align-items-start w-100">
															<span class="d-inline-block flex-grow-1"> -->
						<!-- <input type="text" name="file_name"
																	placeholder="Please enter document name"
																	maxlength="500"
																	class="fw-500 f-c-p-1 input_custom_file border-bottom w-100"
																	[(ngModel)]="doc.file_name" /></span>
														</div>
													</span>
													<ng-container *ngIf="doc && !doc.mannual_file">
														<label [for]="i+'input_upload'" class="button_upload mb-0"
															*ngIf="doc.is_uploaded == 0">
															<input type="file" class="d-none" name=""
																[id]="i+'input_upload'" accept=".pdf, .png, .jpeg, .jpg"
																(change)="uploadDocs($event,doc)">
															<span *ngIf="doc.is_uploaded == 0">Upload</span>
														</label>
														<span
															*ngIf="(doc.is_uploaded == 1 || doc.need_more_documents == 1) && doc.doc_url"
															class="d-flex align-items-center">
															<a [href]="doc.doc_url" target="_blank"
																class="mb-0 button_upload">
																<i class="fa fa-eye"></i>
															</a>
															<label class="button_upload ml-3 mb-0"
																(click)="deleteDisputeDoc(doc)">
																<i class="fa fa-trash"></i>
															</label>
														</span>
													</ng-container> -->

						<!-- <div class="" style="max-width: min-content;"
														*ngIf="doc && doc.mannual_file == 1">
														<label [for]="'manual' + i"
															class="pointer fs-14 mb-0 button-login mr-0 mb-1"
															(click)="checkDocUapdated(doc)"
															style="background: #FAFAFA;padding: 3px 16px;color: #596775;border: 1px solid #C3C8CD;border-radius: 6px;">Upload
															<input type="file" name="" [id]="'manual' + i" minlength="2"
																*ngIf="doc.file_name" class="d-none" [disabled]=""
																(change)="uploadDocs($event,doc);"
																accept=".pdf, .png, .jpeg, .jpg"
																[value]="doc.document_file">
														</label>
														<label [for]="'manual' + i" class="pointer fs-14 mb-0"
															(click)="disputeDocs.pop()"
															style="background: #FAFAFA;padding: 3px 16px;color: #596775;border: 1px solid #C3C8CD;border-radius: 6px;min-width: -webkit-fill-available;">Delete
														</label>
													</div>
												</div>
											</ng-container>
										</div>
										<div class="py-2" *ngIf="!checkShowAddButton()">
											<button button-login type="button"
												*ngIf="disputeDocs.length < 10 && !checkMannualDoc()"
												class="py-2 pointer" (click)="addMoreDocument()"
												style="box-shadow: unset !important;border: 1px solid #1581E5;color: #1581E5;border-radius: 10px;padding-inline: 20px;background-color: white;">+
												Add More Documents</button> <span class="fs-13 f-c-p-7 px-3">You can add
												upto 10 documents
											</span> -->
						<!-- </div>
									</accordion-group>
								</accordion>
							</div>

						</div>

						<p>
							<label for="">Notes* <strong><i
										style="font-size: 12px;font-weight: 400;"></i></strong></label>
							<textarea class="form-control" rows="4" name="contract_notes" trim="true" required
								ngModel></textarea>
						</p>
					</div>
				</div>

				<div class="modal-footer text-right">
					<button type="button" class="btn btn-outline-secondary mr-4" -->
						<!-- (click)="DisputeTicketStatus.hide()">Cancel</button>
					<button type="submit" class="btn btn-primary" [disabled]="!partner_documets_upload">Confirm</button>
				</div>

			</form>

		</div>

	</div> -->

						<!-- </div> -->

						<!--Refund and Reverse-->
						<!-- <div bsModal #refundModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade" tabindex="-1"
	role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered customModal">
		<div class="modal-content" *ngIf="paymentProcessInfo">
			<form #refundForm="ngForm">
				<div class="modal-header refund_header">
					<h4 class="modal-title">Payment Details</h4>
					<button type="button" class="close close_btn" aria-label="Close"
						(click)="refundModal.hide();refundForm.resetForm()">&times;</button>
				</div>
				<!-- {{ current_payment_data | json }} -->
						<!-- <div class="modal-body" style="max-height: 500px;overflow-y: auto;"> -->
						<!-- <div class="row">
						<div class="col-md-6 col-xs-6"
							*ngIf="!_shared.routerAuth.restricted_buttons.refund_customer && current_payment_data.pt_name!='First Payment Reversed' && current_payment_data.payment_type!=5">
							<h4 class="mb-0">Amount Refunded</h4>
							<h5 class="mb-0">The amount refunded to customer:
								<strong>{{current_payment_data.amount_refunded ? (current_payment_data.amount_refunded |
									currency) : '$0.00'}}</strong>
							</h5>
							<h5>Refunded Date : <strong>{{current_payment_data && current_payment_data.refunded_date ?
									(current_payment_data.refunded_date | date:'MM/dd/yyyy': timezone):
									'N.A.'}}</strong></h5>
						</div>
						<div class="col-md-6 col-xs-6">
							<h4 class="mb-0">Amount Reversed</h4>
							<h5 class="mb-0">This is the amount reversed from Business/Partner (If applicable) :
								<strong>{{current_payment_data.total_amount_reversed ?
									(current_payment_data.total_amount_reversed | currency) : '$0.00'}}</strong>
							</h5>
							<h5 class="mb-0">Business Reversed Date : <strong>{{current_payment_data &&
									current_payment_data.doctor_reversed_date ?
									(current_payment_data.doctor_reversed_date | date:'MM/dd/yyyy': timezone):
									'N.A.'}}</strong> </h5>
							<h5>Partner Reversed Date : <strong>{{current_payment_data &&
									current_payment_data.partner_reversed_date ?
									(current_payment_data.partner_reversed_date | date:'MM/dd/yyyy': timezone):
									'N.A.'}}</strong></h5>
						</div>
					</div> -->
						<!-- <h4>
						Partner Share
					</h4> -->
						<!-- <div class="d-flex align-items-center">
						<h4>Total Amount:</h4>
						<h5 class="ml-3">
							{{paymentProcessInfo.amount | currency : '$'}}
						</h5>

					</div>
					<div class="d-flex align-items-center">
						<h4>Business Share:</h4>
						<h5 class="ml-3">
							{{paymentProcessInfo.amount_transfer_to_doctor | currency : '$'}}
						</h5>
					</div>

					<div class="d-flex align-items-center">
						<h4>Partner Share:</h4>
						<h5 class="ml-3">
							{{paymentProcessInfo.total_amount_partner | currency : '$'}}
						</h5> -->
						<!-- </div> -->

						<!-- 
					<hr>
					<div class="form-group">
						<div class="c-radio"> -->
						<!-- <label (click)="resetReverseData()"
								*ngIf="!_shared.routerAuth.restricted_buttons.refund_customer && current_payment_data.pt_name!='First Payment Reversed' && current_payment_data.payment_type!=5">
								<input checked="" name="pType" type="radio" value="1" [(ngModel)]="refund.reverse_type">
								<span class="fa fa-circle"></span>Refund to Customer(s)
							</label> -->
						<!-- <label class="ml-2" (click)="resetReverseData()"
								*ngIf="!_shared.routerAuth.restricted_buttons.reverse_business && current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0">
								<input checked="" name="pType" type="radio" value="3" [(ngModel)]="refund.reverse_type">
								<span class="fa fa-circle"></span>Reverse from Business
							</label>

						</div>
					</div>
					<div *ngIf="refund.reverse_type== 4 && !_shared.routerAuth.restricted_buttons.reverse_partner">
						<div *ngIf="current_payment_data.partner_transfers && current_payment_data.partner_transfers.length >0"
							class="pl-3">
							<div class="form-group">

							</div>
							<div class="form-group" -->
						<!-- *ngFor="let partner of current_payment_data.partner_transfers; let i = index;">
								<div class="col-12 pl-0">
									<span class="partner_name"><strong>{{i+1}}. Name : </strong><a
											[routerLink]="['/app/partners/profile',partner.partner_id]">{{partner.title}}
											{{partner.partner_name}}</a></span><br />
									<span><strong>Total Amount : </strong>{{partner.amount | currency}}<span>
											({{partner.share_type == 1 ?
											'Recurring share':
											'Fixed Share'
											}} {{partner.share_from_doctor == 1 ? 'from business' :
											'from Denefits'}})</span></span><br />
									<hr> -->
						<!-- <span><strong>Amount Reversed: </strong>{{partner.amount_reversed |
										currency}}</span><br /><br /> -->

						<!-- </div> -->
						<!-- <div class="row">
									<div class="col-8">
										<label>Amount Reverse(USD)*</label>
										<input type="text" [(ngModel)]="refund['amount_data'][i] "
											#reverseAmount="ngModel" name="reverseAmount_{{i}}" class="form-control"
											[min]="0" [max]="partner.amount"
											(change)="setTransactionData(partner.transfer_id,refund['amount_data'][i],partner.partner_email, partner.papa_id)"
											appTwoDigitDecimaNumber>

										<p class="text-danger"
											*ngIf="(reverseAmount.touched || reverseAmount.dirty) && reverseAmount.errors?.max">
											Partner share
											cannot be
											greater than {{partner.amount | currency}}</p>
										<p class="text-danger"
											*ngIf="(reverseAmount.touched || reverseAmount.dirty) && reverseAmount.errors?.min">
											Partner share
											should be greater than 0
									</div>
								</div> -->
						<!-- <div class="row">
									<div class="col-8">
										<label>Add Notes*</label>
										<textarea [(ngModel)]="refund['notes_detail'][i]" name="refundDetails"
											#refundDetails="ngModel" required type="text" class="form-control"
											trim="blur"></textarea>

									</div>
								</div> -->
						<!-- <div class="col-6">
									<button class="btn btn-primary refund-action mt-4"
										(click)="reverseShares(refundForm, refundModal, 4, refund['amount_data'][i], refund['notes_detail'][i], partner.transfer_id )"
										*ngIf="partner.transfer_id"
										[disabled]="!refund['amount_data'][i] || reverseAmount.invalid">Reverse</button>
									<button class="btn btn-primary refund-action mt-4"
										(click)="reverseForUnpaidPartners(refundForm, refundModal)"
										*ngIf="!partner.transfer_id"
										[disabled]="!refund['amount_data'][i] || reverseAmount.invalid || refundDetails.invalid">Reverse</button>
								</div> -->
						<!-- </div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
				</div>
			</form>
		</div>
	</div>
</div> -->