<div class="mb-0 text-end mt-4 p-2 pb-0">
  <button (click)="collapseHandler(true)" class="btn btn-primary me-2  radius-40"
    [ngClass]="{'btn-primary':opened}">Expand All
  </button>
  <button (click)="collapseHandler(false)" class="btn btn-primary radius-40" [ngClass]="{'btn-primary':opened}">Collapse
    All
  </button>
</div>

<accordion class="p-2">
  <!-- <accordion-group heading="Contracts with 10% Platform Fee" [isOpen]="opened" [panelClass]="'dashboard-cards'"
      *ngIf="!_shared.routerAuth.restricted_buttons.ten_contract_stats">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2" [routerLink]="'/app/contracts/manage-contracts'" [queryParams]="{type:0, statusFilter:'1,2,4,5,7', extraFilter:8}">
            <h3>{{dashboard.ten_percent_contracts ? dashboard.ten_percent_contracts : '0'}}</h3>
            <h4 class="m-0">Total Contracts With 10% Platform Fee</h4>  
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/contracts/manage-contracts'" [queryParams]="{type:0, extraFilter:8, sD:today_start_date, eD:today_end_date}">
            <h3>{{dashboard.ten_percent_today_contracts ? dashboard.ten_percent_today_contracts : '0'}}</h3>
            <h4 class="m-0">10% Platform Fee Contracts Today</h4>
          </div>
        </div>
      </div>
    </accordion-group> -->
  <accordion-group class="mb-3 accordian-wrapper" heading="Financed Contracts (Overall)" [isOpen]="opened"
    [panelClass]="'dashboard-cards'" *ngIf="!_shared.routerAuth.restricted_buttons.financed_contract_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-1 p-2" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:1, statusFilter:'0',extraFilter:0}">
          <h3>{{dashboard.financed_contracts ? dashboard.financed_contracts : '0'}}</h3>
          <h4 class="m-0">Total Financed Contracts</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-2 p-2 highlighted-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:0, sD:today_start_date, eD:today_end_date,extraFilter:0}">
          <!-- 1,2,4,5,6,7 -->
          <h3>{{dashboard.financed_contracts_today ? dashboard.financed_contracts_today : '0'}}</h3>
          <h4 class="m-0">Financed Contracts Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/contracts/list'" [queryParams]="{type: 3, statusFilter:'3'}">
          <h3>{{dashboard.overdue_accounts ? dashboard.overdue_accounts : '0'}}</h3>
          <h4 class="mb-0">Total Overdue Contracts</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-4 p-2" [routerLink]="'/app/contracts/legal'">
            <h3>{{dashboard.legal_overdue_contracts ? dashboard.legal_overdue_contracts : '0'}}</h3>
            <h4 class="m-0">Total Legal Contracts</h4>
          </div>
        </div> -->
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-5 p-2 errored-div" [routerLink]="'/app/errors'">
          <h3>{{dashboard.financing_errors ? dashboard.financing_errors : '0'}}</h3>
          <h4 class="m-0">Financing Errors (24 hours)</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2">
          <h3>{{dashboard.late_fee ? _shared.countryWiseDataFixing(dashboard.late_fee) : '0'}}</h3>
          <h4 class="m-0">Total Late Fee</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-7 p-2">
          <h3>{{dashboard.late_fee_count ? dashboard.late_fee_count : '0'}}</h3>
          <h4 class="m-0">Late Fee Count</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-8 p-2 highlighted-div" [routerLink]="'/app/fees/late'">
            <h3>{{country_sign}}{{dashboard.late_fee_today ? dashboard.late_fee_today : '0' | number :'1.1-3'}}</h3>
            <h4 class="m-0">Late Fee Today</h4>
          </div>
        </div> -->
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2">
          <h3>{{dashboard.late_fee_count_today ? dashboard.late_fee_count_today : '0'}}</h3>
          <h4 class="m-0">Late Fee Today Count</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-5 p-2" [routerLink]="'/app/contracts/manage-contracts'" [queryParams]="{type: 0}">
            <h3>{{country_sign}}{{dashboard.financed_amount ? dashboard.financed_amount : '0' | number :'1.1-3'}}</h3>
            <h4 class="m-0">Total Financed Amount</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-7 p-2" [routerLink]="'/app/contracts/manage-contracts'"
            [queryParams]="{type:0, sD:today_start_date, eD:today_end_date}">
            <h3>{{country_sign}}{{dashboard.financed_amount_today ? dashboard.financed_amount_today : '0' | number
              :'1.1-3'}}</h3>
            <h4 class="m-0">Financed Amount Today</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2">
            <h3>{{country_sign}}{{dashboard.first_recurring_fee ? dashboard.first_recurring_fee : '0' | number
              :'1.1-3'}}</h3>
            <h4 class="m-0">Total Reserved First Recurring</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div">
            <h3>{{country_sign}}{{dashboard.today_first_recurring_fee ? dashboard.today_first_recurring_fee : '0' |
              number :'1.1-3'}}</h3>
            <h4 class="m-0">Reserved First Recurring Today</h4>
          </div>
        </div> -->
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-5 p-2 errored-div" [routerLink]="'/app/errors/split-errors'">
          <h3>{{dashboard.split_errors ? dashboard.split_errors : '0'}}</h3>
          <h4 class="m-0">Split Proposal Errors (24 hours)</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-7 p-2" [routerLink]="'/app/contract-changes/guaranteed-changes'"
          [queryParams]="{filter: 1, sD: minDates, eD: today_end_date}">
          <h3>{{pp_dashboard.total_non_guaranteed_gp_status_change}}</h3>
          <h4 class="m-0">GP to Non-GP</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-7 p-2" [routerLink]="'/app/contract-changes/guaranteed-changes'"
          [queryParams]="{filter: 2, sD: minDates, eD: today_end_date}">
          <h3>{{pp_dashboard.total_guaranteed_gp_status_change}}</h3>
          <h4 class="m-0">Non-GP to GP</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div">
          <h3>{{dashboard.pay_over_time_services ? dashboard.pay_over_time_services : '0'}}</h3>
          <h4 class="m-0">Web Connect Services</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div">
          <h3>{{dashboard.pay_over_time_contracts ? dashboard.pay_over_time_contracts : '0'}}</h3>
          <h4 class="m-0">Web Connect Total Contracts</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div">
            <h3>{{country_sign}}{{dashboard.sum_of_pay_over_time_financed_amount ?
              dashboard.sum_of_pay_over_time_financed_amount : '0' | number :'1.1-3'}}</h3>
            <h4 class="m-0">Web Connect Total Financed Amount</h4>
          </div>
        </div> -->
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div">
          <h3>{{dashboard.pay_over_time_contracts_today ? dashboard.pay_over_time_contracts_today : '0'}}</h3>
          <h4 class="m-0">Web Connect Contracts Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 errored-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:1, sD:today_start_date, eD:today_end_date,extraFilter:36}">
          <h3>{{dashboard.pause_payment || '0'}}</h3>
          <h4 class="m-0">Pause Payments</h4>
        </div>
      </div>

      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div">
            <h3>{{country_sign}}{{dashboard.pay_over_time_financed_amount_today ?
              dashboard.pay_over_time_financed_amount_today : '0' | number :'1.1-3'}}</h3>
            <h4 class="m-0">Web Connect Financed Amount Today</h4>
          </div>
        </div> -->
    </div>
  </accordion-group>

  <accordion-group heading="Secure Mutual Financing Contracts" [isOpen]="opened" [panelClass]="'dashboard-cards'"
    *ngIf="!_shared.routerAuth.restricted_buttons.zero_contract_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:1, statusFilter:'0', extraFilter:9}">
          <h3>{{dashboard.zero_percent_contracts ? dashboard.zero_percent_contracts : '0'}}</h3>
          <h4 class="m-0">Total Contracts with Secure Mutual Financing</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:0, extraFilter:9, sD:today_start_date, eD:today_end_date, statusFilter:'1,2,4,5,6,7'}">
          <h3>{{dashboard.zero_percent_today_contracts ? dashboard.zero_percent_today_contracts : '0'}}</h3>
          <h4 class="m-0">Secure Mutual Financing Contracts Today</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2">
            <h3>{{country_sign}}{{(dashboard.upfront_fee ? dashboard.upfront_fee : '0') | number :'1.1-3'}}</h3>
            <h4 class="m-0">Total Upfront Fees</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div">
            <h3>{{country_sign}}{{dashboard.today_upfront_fee ? dashboard.today_upfront_fee : '0' | number :'1.1-3'}}
            </h3>
            <h4 class="m-0">Upfront Fees Today</h4>
          </div>
        </div> -->
    </div>
  </accordion-group>

  <accordion-group class="mb-3 accordian-wrapper" heading="ZeroFi Financing Contracts" [isOpen]="opened"
    [panelClass]="'dashboard-cards'" *ngIf="!_shared.routerAuth.restricted_buttons.zero_contract_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:1, statusFilter:'1,2,4,5,7', extraFilter:35}">
          <h3>{{dashboard && dashboard.zero_reserve_plan_total_contracts || 0}}</h3>
          <h4 class="m-0">Total Contracts with Zero Reserve Financing</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:0, extraFilter:35, sD:today_start_date, eD:today_end_date, statusFilter:'1,2,4,5,6,7'}">
          <h3>{{dashboard && dashboard.zero_reserve_today_contracts || 0}}</h3>
          <h4 class="m-0">Zero Reserve Financing Contracts Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2">
          <h3>{{dashboard && dashboard.zero_reserve_upfront_fee?dashboard.zero_reserve_upfront_fee:'0'}}</h3>
          <h4 class="m-0">Total Upfront Fees</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div">
          <h3>{{dashboard && dashboard.zero_reserve_today_upfront_fee}}</h3>
          <h4 class="m-0">Upfront Fees Today</h4>
        </div>
      </div>
    </div>
  </accordion-group>


  <!-- <accordion-group heading="SimpleeFi Contracts" [isOpen]="opened" [panelClass]="'dashboard-cards'"
      *ngIf="!_shared.routerAuth.restricted_buttons.zero_contract_stats">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2" [routerLink]="'/app/contracts/manage-contracts'"
            [queryParams]="{type:0, statusFilter:'1,2,4,5,7', extraFilter:27}">
            <h3>{{dashboard.ez_total_contracts ? dashboard.ez_total_contracts : '0'}}</h3>
            <h4 class="m-0">Total Contracts with SimpleeFi</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/contracts/manage-contracts'"
            [queryParams]="{type:8, extraFilter:27, sD:today_start_date, eD:today_end_date, statusFilter:'1,2,4,5,6,7'}">
            <h3>{{dashboard.ez_today_contracts ? dashboard.ez_today_contracts : '0'}}</h3>
            <h4 class="m-0">SimpleeFi Contracts Today</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2">
            <h3>{{country_sign}}{{dashboard.ez_upfront_fee ? dashboard.ez_upfront_fee : '0' | number :'1.1-3'}}</h3>
            <h4 class="m-0">Total Upfront Fees</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div">
            <h3>{{country_sign}}{{dashboard.ez_today_upfront_fee ? dashboard.ez_today_upfront_fee : '0' | number
              :'1.1-3'}}</h3>
            <h4 class="m-0">Upfront Fees Today</h4>
          </div>
        </div>
      </div>
    </accordion-group> -->


  <accordion-group class="mb-3 accordian-wrapper" heading="SimpleeFi Contracts (With Deferred Duration)"
    [isOpen]="opened" [panelClass]="'dashboard-cards'"
    *ngIf="!_shared.routerAuth.restricted_buttons.zero_contract_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:1, statusFilter:'1,2,4,5,7', extraFilter:33}">
          <h3>{{dashboard.di_total_contracts ? dashboard.di_total_contracts : '0'}}</h3>
          <h4 class="m-0">Total Contracts with SimpleeFi</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:0, extraFilter:33, sD:today_start_date, eD:today_end_date, statusFilter:'1,2,4,5,6,7'}">
          <h3>{{dashboard.di_today_contracts ? dashboard.di_today_contracts : '0'}}</h3>
          <h4 class="m-0">SimpleeFi Contracts Today</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2">
            <h3>{{country_sign}}{{dashboard.di_upfront_fee ? dashboard.di_upfront_fee : '0' | number :'1.1-3'}}</h3>
            <h4 class="m-0">Total Upfront Fees</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div">
            <h3>{{country_sign}}{{dashboard.di_today_upfront_fee ? dashboard.di_today_upfront_fee : '0' | number
              :'1.1-3'}}</h3>
            <h4 class="m-0">Upfront Fees Today</h4>
          </div>
        </div> -->
    </div>
  </accordion-group>



  <accordion-group heading=" Max Mutual Financing Contracts" [isOpen]="opened" [panelClass]="'dashboard-cards'"
    *ngIf="!_shared.routerAuth.restricted_buttons.new_ten_contract_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:1, extraFilter:26, statusFilter:'1,2,4,5,7'}">
          <h3>{{dashboard.new_ten_percent_contracts ? dashboard.new_ten_percent_contracts : '0'}}</h3>
          <h4 class="m-0">Total Contracts With Max Mutual Financing</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:0, extraFilter:26, sD:today_start_date, eD:today_end_date}">
          <h3>{{dashboard.new_ten_percent_today_contracts ? dashboard.new_ten_percent_today_contracts : '0'}}</h3>
          <h4 class="m-0"> Max Mutual Financing Contracts Today</h4>
        </div>
      </div>
    </div>
  </accordion-group>



  <accordion-group class="mb-3 accordian-wrapper" heading="Proposal Requests" [isOpen]="opened"
    [panelClass]="'dashboard-cards'" *ngIf="!_shared.routerAuth.restricted_buttons.split_request_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-1 p-2" [routerLink]="'/app/proposal/list'">
          <h3>{{dashboard.total_split_requests ? dashboard.total_split_requests : '0'}}</h3>
          <h4 class="m-0">Total Requests Sent</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-2 p-2 highlighted-div" [routerLink]="'/app/proposal/list'"
          [queryParams]="{startDate: today_start_date, endDate: today_end_date}">
          <h3>{{dashboard.today_split_requests ? dashboard.today_split_requests : '0'}}</h3>
          <h4 class="m-0">Total Requests Sent Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-7 p-2" [routerLink]="'/app/proposal/list'" [queryParams]="{status: 4}">
          <h3>{{dashboard.contract_financed_requests ? dashboard.contract_financed_requests : '0'}}</h3>
          <h4 class="m-0">Total Contracts Financed</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-8 p-2 highlighted-div normal-div">
          <h3>{{dashboard.today_contract_financed_requests ? dashboard.today_contract_financed_requests : '0'}}</h3>
          <h4 class="m-0">Total Contracts Financed Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 errored-div" [routerLink]="'/app/proposal/list'" [queryParams]="{status: 2}">
          <h3>{{dashboard.link_opened_requests ? dashboard.link_opened_requests : '0'}}</h3>
          <h4 class="mb-0">Total Link Opened</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-5 p-2 errored-div" [routerLink]="'/app/proposal/list'" [queryParams]="{status: 3}">
          <h3>{{dashboard.plan_selected_requests ? dashboard.plan_selected_requests : '0'}}</h3>
          <h4 class="m-0">Total Plan Selected</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 errored-div" [routerLink]="'/app/proposal/list'"
          [queryParams]="{status: 3, startDate: today_start_date, endDate: today_end_date, by: 'plan_selected_date', order: 'DESC', 'sortBy': 6}">
          <h3>{{dashboard.today_plan_selected_requests ? dashboard.today_plan_selected_requests : '0'}}</h3>
          <h4 class="m-0">Total Plan Selected Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 normal-div">
          <h3>{{dashboard.deleted_split_requests ? dashboard.deleted_split_requests : '0'}}</h3>
          <h4 class="m-0">Total Deleted Requests</h4>
        </div>
      </div>
    </div>
  </accordion-group>

  <accordion-group class="mb-3 accordian-wrapper" heading="Businesses" [isOpen]="opened"
    [panelClass]="'dashboard-cards card-primary'" *ngIf="!_shared.routerAuth.restricted_buttons.doctor_stats">
    <div class="row ">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-1 p-2" [routerLink]="'/app/business/list'"
          [queryParams]="{status: 1, country_id: country_id}">
          <h3>{{dashboard.active_doctors ? dashboard.active_doctors : '0'}}</h3>
          <h4 class="m-0">Total Businesses</h4>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-2 p-2" [routerLink]="'/app/doctors/manage'"
          [queryParams]="{action: 16, startDate:today_start_date, endDate:today_end_date}">
          <h3>{{dashboard.active_doctors_today ? dashboard.active_doctors_today : '0'}}</h3>
          <h4 class="m-0">Businesses Registered Today</h4>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 errored-div" [routerLink]="'/app/doctors/manage'"
          [queryParams]="{action:5, status:1, type:21}">
          <h3>{{dashboard.total_disabled_accounts ? dashboard.total_disabled_accounts : '0'}}</h3>
          <h4 class="m-0">Transfers Disabled Accounts</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-5 p-2 highlighted-div" [routerLink]="'/app/doctors/manage'" [queryParams]="{type: 15}">
          <h3>{{dashboard.active_doctors_this_month ? dashboard.active_doctors_this_month : '0'}}</h3>
          <h4 class="m-0">Active Businesses This Month</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div" [routerLink]="'/app/doctors/manage'" [queryParams]="{type: 14}">
          <h3>{{dashboard.active_doctors_this_month_30 ? dashboard.active_doctors_this_month_30 : '0'}}</h3>
          <h4 class="m-0">Active Businesses In Last 30 Days</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div" [routerLink]="'/app/doctors/manage'"
          [queryParams]="{type: 4, status:1}">
          <h3>{{dashboard.total_business_without_bank ? dashboard.total_business_without_bank : '0'}}</h3>
          <h4 class="m-0">Businesses Without Bank Account</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div" [routerLink]="'/app/doctors/manage'"
          [queryParams]="{type: 23, status:1}">
          <h3>{{dashboard.total_business_without_training ? dashboard.total_business_without_training : '0'}}</h3>
          <h4 class="m-0">Businesses With No Training</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div" [routerLink]="'/app/doctors/manage'"
          [queryParams]="{type: 13, status:1}">
          <h3>{{dashboard.total_business_without_approved ? dashboard.total_business_without_approved : '0'}}</h3>
          <h4 class="m-0">Businesses Not Approved</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div">
          <h3>{{dashboard.total_no_of_free ? dashboard.total_no_of_free : '0'}}</h3>
          <h4 class="m-0">Free Subscription</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div">
          <h3>{{dashboard.total_no_of_Platinum ? dashboard.total_no_of_Platinum : '0'}}</h3>
          <h4 class="m-0">Platinum Subscription</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div">
          <h3>{{dashboard.total_no_of_Gold ? dashboard.total_no_of_Gold : '0'}}</h3>
          <h4 class="m-0">Gold Subscription</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-4 p-2 highlighted-div">
          <h3>{{dashboard.total_no_of_Silver ? dashboard.total_no_of_Silver : '0'}}</h3>
          <h4 class="m-0">Sliver Subscription</h4>
        </div>
      </div>
    </div>
  </accordion-group>

  <accordion-group class="mb-3 accordian-wrapper" heading="Customers" [isOpen]="opened" [panelClass]="'dashboard-cards'"
    *ngIf="!_shared.routerAuth.restricted_buttons.patient_stats">
    <div class="row ">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2" [routerLink]="'/app/customers'" [queryParams]="{filter:1, type:1, status:2}">
          <h3>{{dashboard.active_patients ? dashboard.active_patients : '0'}}</h3>
          <h4 class="m-0">Customers with Contract</h4>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-7 p-2" [routerLink]="'/app/customers'" [queryParams]="{filter:2, type:2, status:2}">
          <h3>{{dashboard.patients_without_contracts ? dashboard.patients_without_contracts : '0'}}</h3>
          <h4 class="m-0">Customers without Contract</h4>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-8 p-2" [routerLink]="'/app/customers'"
          [queryParams]="{startDate:today_start_date, endDate:today_end_date}">
          <h3>{{dashboard.new_patients_today ? dashboard.new_patients_today : '0'}}</h3>
          <h4 class="m-0">Customers Registered Today</h4>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-8 p-2">
          <h3>{{dashboard.self_verified_customers ? dashboard.self_verified_customers : '0'}}</h3>
          <h4 class="m-0">Self Verified Customers</h4>
        </div>
      </div>
    </div>
  </accordion-group>

  <accordion-group class="mb-3 accordian-wrapper" heading="Claims" [isOpen]="opened" [panelClass]="'dashboard-cards'"
    *ngIf="!_shared.routerAuth.restricted_buttons.claim_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-1 p-2" [routerLink]="'/app/claims'">
          <h3>{{dashboard.claim_requests ? dashboard.claim_requests : '0'}}</h3>
          <h4 class="m-0">Total Claim Requests</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-1 p-2 highlighted-div" [routerLink]="'/app/claims'"
          [queryParams]="{filterBy:0, isOrp:-1, isInstant:-1, startDate: today_start_date, endDate: today_end_date}">
          <h3>{{dashboard.claim_requests_today ? dashboard.claim_requests_today : '0'}}</h3>
          <h4 class="m-0">Claim Requests Today</h4>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-1 p-2" [routerLink]="'/app/claims'">
            <h3>{{country_sign}}{{dashboard.claim_request_amount ? dashboard.claim_request_amount : '0' | number
              :'1.1-3'}}</h3>
            <h4 class="m-0">Total Claimed Amount</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-1 p-2" [routerLink]="'/app/claims'"
            [queryParams]="{filterBy:0, isOrp:-1, isInstant:-1, startDate: today_start_date, endDate: today_end_date}">
            <h3>{{country_sign}}{{dashboard.claim_request_amount_today ? dashboard.claim_request_amount_today : '0' |
              number :'1.1-3'}}</h3>
            <h4 class="m-0">Claimed Amount Today</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-1 p-2" [routerLink]="'/app/claims'" [queryParams]="{filterBy:2}">
            <h3>{{country_sign}}{{dashboard.claim_request_paid ? dashboard.claim_request_paid : '0' | number :'1.1-3'}}
            </h3>
            <h4 class="m-0">Total Claim Paid</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-1 p-2">
            <h3>{{country_sign}}{{dashboard.claim_request_paid_today ? dashboard.claim_request_paid_today : '0' |
              number :'1.1-3'}}</h3>
            <h4 class="m-0">Claim Paid Today</h4>
          </div>
        </div> -->
    </div>
  </accordion-group>

  <!-- <accordion-group class="mb-3 accordian-wrapper" heading="Partners" [isOpen]="opened" [panelClass]="'dashboard-cards card-info'"
      *ngIf="!_shared.routerAuth.restricted_buttons.partner_stats">
      <div class="row ">
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-4 p-2" [routerLink]="'/app/partners'" [queryParams]="{status:0}">
            <h3>{{dashboard.active_partners ? dashboard.active_partners : '0'}}</h3>
            <h4 class="m-0">Active Partners</h4>
          </div>
        </div>
  
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-5 p-2 highlighted-div" [routerLink]="'/app/partners'"
            [queryParams]="{start_date: today_start_date, end_date: today_end_date, filter:0, status:-1,showFilterTab:true}">
            <h3>{{dashboard.new_partners_today ? dashboard.new_partners_today : '0'}}</h3>
            <h4 class="m-0">Partner Registered Today</h4>
          </div>
        </div>
      </div>
    </accordion-group>
  
    <accordion-group class="mb-3 accordian-wrapper" heading="Process Payments " [isOpen]="opened" [panelClass]="'dashboard-cards card-info'">
      <div class="row ">
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2" [routerLink]="'/app/payment-processing/list'"
            [queryParams]="{status:'2', sD: today_start_date, eD: today_end_date}">
            <h3>{{pp_dashboard.today_pp_payments}}</h3>
            <h4 class="m-0">Today's Processed Payments</h4>
          </div>
        </div>
     <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/payment-processing/list'"
            [queryParams]="{status:'1,2,3,4,5', sD: today_start_date, eD: today_end_date}">
            <h3>{{country_sign}}{{pp_dashboard.today_pp_amount | number :'1.1-3'}}</h3>
            <h4 class="m-0">Today's Amount Processed</h4>
          </div>
        </div> -->
  <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div normal-div">
            <h3>{{pp_dashboard.last_month_pp_payments}}</h3>
            <h4 class="m-0">Payments Processed Last Month</h4>
          </div>
        </div> -->
  <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div normal-div">
            <h3>{{country_sign}}{{pp_dashboard.last_month_pp_amount | number :'1.1-3'}}</h3>
            <h4 class="m-0">Amount Processed in Last Month</h4>
          </div>
        </div> -->
  <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2" [routerLink]="'/app/payment-processing/list'" [queryParams]="{status: 2}">
            <h3>{{pp_dashboard.total_pp_payments}}</h3>
            <h4 class="m-0">Total Payments Processed</h4>
          </div>
        </div> -->
  <!-- <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/payment-processing/list'"
            [queryParams]="{status: 2}">
            <h3>{{country_sign}}{{pp_dashboard.total_pp_amount | number :'1.1-3'}}</h3>
            <h4 class="m-0">Total Amount Processed</h4>
          </div>
        </div> -->
  <!-- </div>
    </accordion-group> -->

  <!-- <accordion-group heading="Debt recovery" [isOpen]="opened" [panelClass]="'dashboard-cards card-info'">
      <div class="row ">
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2" [routerLink]="'/app/accounts-receivable/list'"
            [queryParams]="{status:'2', sD: today_start_date, eD: today_end_date}">
            <h3>{{pp_dashboard.today_ar_payments}}</h3>
            <h4 class="m-0">Today's Processed Payments</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/accounts-receivable/list'"
            [queryParams]="{status:'1,2,3,4,5,6', sD: today_start_date, eD: today_end_date}">
            <h3>{{country_sign}}{{pp_dashboard.today_ar_amount | number :'1.1-3'}}</h3>
            <h4 class="m-0">Today's Amount Processed</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div normal-div">
            <h3>{{pp_dashboard.last_month_ar_payments}}</h3>
            <h4 class="m-0">Payments Processed Last Month</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div highlighted-div normal-div">
            <h3>{{country_sign}}{{pp_dashboard.last_month_ar_amount | number :'1.1-3'}}</h3>
            <h4 class="m-0">Amount Processed in Last Month</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2" [routerLink]="'/app/accounts-receivable/list'" [queryParams]="{status: 2}">
            <h3>{{pp_dashboard.total_ar_payments}}</h3>
            <h4 class="m-0">Total Payments Processed</h4>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12 pb-3">
          <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/accounts-receivable/list'"
            [queryParams]="{status: 2}">
            <h3>{{country_sign}}{{pp_dashboard.total_ar_amount | number :'1.1-3'}}</h3>
            <h4 class="m-0">Total Amount Processed</h4>
          </div>
        </div>
      </div>
    </accordion-group> -->

  <accordion-group heading="Debt Recovery" class="mb-3 accordian-wrapper" [isOpen]="opened"
    [panelClass]="'dashboard-cards'" *ngIf="!_shared.routerAuth.restricted_buttons.split_request_stats">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 normal-div">
          <h3>{{dashboard.ar_v2_total_debt_recovered}}</h3>
          <h4 class="m-0">Total Debt Recovered</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div normal-div">
          <h3>{{dashboard.ar_v2_total_debt_recovered_today}}</h3>
          <h4 class="m-0"> Total Debt Recovered Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-7 p-2" [routerLink]="'/app/debt-recovery/list'"
          [queryParams]="{status:0, activeStatus:-1, by:'date_added' ,order:'DESC', sortBy:1, ProposalType:1}">
          <h3>{{dashboard.ar_v2_total_proposals}}</h3>
          <h4 class="m-0">Total Debt Recoveries Initiated</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-8 p-2 highlighted-div" [routerLink]="'/app/debt-recovery/list'"
          [queryParams]="{status:0, activeStatus:-1, by:'date_added' ,order:'DESC', sortBy:1, ProposalType:1, startDate: today_start_date, endDate: today_end_date}">
          <h3>{{dashboard.ar_v2_total_proposals_today}}</h3>
          <h4 class="m-0">Total Debt Recoveries Initiated Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/debt-recovery/list'"
          [queryParams]="{status:10, page:1,last_resent:0,ProposalType:0}">
          <h3>{{dashboard.ar_v2_total_paid_off}}</h3>
          <h4 class="m-0">Total Payment paid off</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div" [routerLink]="'/app/debt-recovery/list'"
          [queryParams]="{status:2, page:0, sD: today_start_date, eD: today_end_date}">
          <h3>{{dashboard.ar_v2_total_paid_off_today}}</h3>
          <h4 class="m-0">Total Payment paid off Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:0, sort:1, page:1, extraFilter:29}">
          <h3>{{dashboard.ar_v2_total_contracts}}</h3>
          <h4 class="mb-0">Total Debt Recovery Contracts </h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <!-- [queryParams]="{type:0, sort:1, page:1,extraFilter:29, sD: today_start_date, eD: today_end_date}"> -->
        <div class="card bg-5 p-2 highlighted-div" [routerLink]="'/app/contracts/list'"
          [queryParams]="{type:8,extraFilter:29, sD: today_start_date, eD: today_end_date}">
          <h3>{{dashboard.ar_v2_total_contracts_today}}</h3>
          <h4 class="m-0">Total Debt Recovery Contracts Today</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 highlighted-div normal-div">
          <h3>{{dashboard.ar_v2_delivered_emails}}</h3>
          <h4 class="m-0">Emails Reminders Delivered</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 errored-div normal-div">
          <h3>{{dashboard.ar_v2_failed_emails}}</h3>
          <h4 class="m-0">Emails Reminders Failed</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 highlighted-div normal-div">
          <h3>{{dashboard.ar_v2_delivered_sms}}</h3>
          <h4 class="m-0">SMS Reminders Delivered</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 errored-div normal-div">
          <h3>{{dashboard.ar_v2_failed_sms}}</h3>
          <h4 class="m-0">SMS Reminders Failed</h4>
        </div>
      </div>
    </div>
  </accordion-group>
  <accordion-group class="mb-3 accordian-wrapper" heading="Demand Letters" [isOpen]="opened"
    [panelClass]="'dashboard-cards'" *ngIf="dashboard['demand_letter_stats_data']">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 normal-div">
          <h3>{{dashboard && dashboard.demand_letter_stats_data && dashboard.demand_letter_stats_data.total}}</h3>
          <h4 class="m-0">Letters Dispatched</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 highlighted-div normal-div">
          <h3>{{dashboard && dashboard.demand_letter_stats_data && dashboard.demand_letter_stats_data.scanned}}</h3>
          <h4 class="m-0">QR code Scanned</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-3 p-2 normal-div">
          <h3>{{dashboard && dashboard.demand_letter_stats_data && dashboard.demand_letter_stats_data.not_scanned}}
          </h3>
          <h4 class="m-0">QR code not Scanned</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 highlighted-div normal-div">
          <h4 class="m-0">{{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.active_contracts}} Active Contracts</h4>
          <h6 class="m-0">Amount Processed: {{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.active_total_amt.toFixed(2)}}</h6>
          <h6 class="m-0">Paid to business: {{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.active_total_pod.toFixed(2)}}</h6>
          <h6 class="m-0">Paid to partner: {{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.active_total_pop.toFixed(2)}}</h6>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 normal-div">
          <h3>{{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.overdue_contracts}}</h3>
          <h4 class="m-0">Overdue Contracts</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 highlighted-div normal-div">
          <h4 class="m-0">{{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.completed_contracts}} Completed Contracts</h4>
          <h6 class="m-0">Amount Processed: {{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.complete_total_amt.toFixed(2)}}</h6>
          <h6 class="m-0">Paid to business: {{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.complete_total_pod.toFixed(2)}}</h6>
          <h6 class="m-0">Paid to partner: {{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.complete_total_pop.toFixed(2)}}</h6>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 normal-div">
          <h3>{{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.cancelled_contracts}}</h3>
          <h4 class="m-0">Cancelled Contracts</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 highlighted-div normal-div">
          <h3>{{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.total_settlement_contracts}}</h3>
          <h4 class="m-0">Total Settlement Contracts</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb-3">
        <div class="card bg-6 p-2 normal-div">
          <h3>${{dashboard && dashboard.demand_letter_stats_data &&
            dashboard.demand_letter_stats_data.settlement_financed_amount.toFixed(2)}}</h3>
          <h4 class="m-0">Settlement Financed Amount</h4>
        </div>
      </div>
    </div>
  </accordion-group>
</accordion>