import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MainService } from '../main.service';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-google-zip',
  templateUrl: './google-zip.component.html',
  styleUrls: ['./google-zip.component.scss']
})
export class GoogleZipComponent implements OnInit {
  country_id: any;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() defaultzip = {};
  @Input() address = '';
  @Input() disabled = false;
  @Input() country_code: any;
  @Input() comp_class = '';
  options1: any;
  @ViewChild("addressItem") addressItem! : NgxGpAutocompleteDirective;
  paramSubscription: any = {};

  constructor(
    private _main: MainService,
    private route: ActivatedRoute,
  ) { 
    this.paramSubscription = this.route.queryParams.subscribe((data: any) => {
      this.country_id = data['country_id']
    });
    this._main.country_data$.subscribe((country_data: any) => {
      this.country_code = country_data.find((item: any) => item.country_id == this.country_id)?.country_code;
    });
  }

  ngOnInit() {
    if (this.country_code != '') {
      this.options1 = {
        componentRestrictions: { country: [this.country_code] },
        fields: ['address_components', 'adr_address', 'geometry', 'name', 'formatted_address'],
        types: []
      };
    }
  }

  handleAddressChange(item: any) {
    this.setAddress.emit(this.zipObjectCovert(item))
  }

  removeZipCode() {
    this.address = '';
    this.setAddress.emit('');
  }

  zipObjectCovert(item?: any) {
    const desire_object: any = {};
    let temp: any
    desire_object.place_id = item.place_id
    desire_object.item = item
    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_code') || inner.types.includes('plus_code'));
    temp = temp && temp.long_name;
    desire_object.zip = temp;
    desire_object.zipCode = temp;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('locality'));
    temp = temp || item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_2')) || item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_town')) || item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('neighborhood')) || item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_3')) || item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('sublocality')) || item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('sublocality_level_1'));

    temp = temp && temp.long_name;
    desire_object.city = temp;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_1'));
    desire_object.state_name = temp && temp.long_name;
    desire_object.state_code = temp && temp.short_name;
    desire_object.State = temp && temp.short_name;


    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('country'));
    desire_object.country_name = temp && temp.long_name;
    desire_object.country_code = temp && temp.short_name;

    if (item.geometry && item.geometry.location) {
      desire_object.latitude = item.geometry.location.lat();
      desire_object.longitude = item.geometry.location.lng();
    }
    return desire_object
  }

  ngOnDestroy() {
    if (this.paramSubscription){
      this.paramSubscription.unsubscribe();
    }
  }
}
