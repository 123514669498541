<!--<div bsModal #apptModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-event-name">-->
  <div class="modal-dialog modal-lg">
    <div *ngIf="selectedAppt" class="modal-content">
      <div class="modal-header new_modal">
        <h4 id="dialog-event-name" class="modal-title pull-left">{{submitTrainingViewTitle()}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideTrainingModal()">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <form (ngSubmit)="addUpdateTraining()">
        <div class="modal-body">
          <div>
            <div class="form-group" *ngIf="selectedAppt.viewType !== 'newTraining'">
              <h4>Trainee Details: {{selectedAppt.training_doctor_partner_type == 'doctor' ? 'Business' : selectedAppt.training_doctor_partner_type}}{{selectedAppt.doctor_partner_type}} </h4>
              <div class="row" *ngIf="selectedAppt.training_doctor_partner_type == 'doctor'">
                <div class="col-4">
                    Booked For:<b class="ml-1"> Business</b>
                </div>
                <div class="col-4">Name:
                    <span class="text-muted" *ngIf="selectedAppt.selectedDoctorId!==0">
                  <b
                    class="ml-1"><a [routerLink]="'/app/doctors/profile/'+selectedAppt.selectedDoctorId" (click)="hideTrainingModal()">{{selectedAppt.selectedDoctor.doctor_title + ' '+ selectedAppt.selectedDoctor.doctor_name}}</a></b>
                    </span>
                    <span *ngIf="selectedAppt.selectedDoctorId===0"><b
                      class="ml-1">{{selectedAppt.selectedDoctor.doctor_name}}</b></span>
                </div>
                <div class="col-4">Email:
                  <b
                    class="ml-1" style="word-break: break-all">{{selectedAppt.selectedDoctor.doctor_emails}}</b>
                </div>
                <div class="col-4">
                  Business Name:<b class="ml-1">{{selectedAppt.selectedDoctor.practice_name?selectedAppt.selectedDoctor.practice_name:'N.A.'}}</b>
                </div>
                <div class="col-4">
                  Phone:<b class="ml-1">
                    {{selectedAppt.selectedDoctor.practice_mobile?(selectedAppt.selectedDoctor.practice_mobile):'N.A.'}}
                  </b>
                </div>
              </div>
              <div class="row" *ngIf="selectedAppt.training_doctor_partner_type == 'partner'">
                <div class="col-4">
                    Booked For:<b class="ml-1"> Partner</b>
                </div>
                <div class="col-4">Name:
                    <span class="text-muted" *ngIf="selectedAppt.selectedDoctorId!==0">
                  <b
                    class="ml-1"><a [routerLink]="'/app/partners/profile/'+selectedAppt.selectedDoctorId" (click)="hideTrainingModal()">
                      {{selectedAppt.selectedDoctor.partner_name || selectedAppt.selectedDoctor.doctor_name}}</a></b>
                    </span>
                    <span *ngIf="selectedAppt.selectedDoctorId===0"><b
                      class="ml-1">{{selectedAppt.selectedDoctor.doctor_name}}</b></span>
                </div>
                <div class="col-4">Email:
                  <b
                    class="ml-1" style="word-break: break-all">{{selectedAppt.selectedDoctor.partner_email || selectedAppt.selectedDoctor.doctor_emails}}</b>
                </div>
                <div class="col-4">
                  Phone:<b class="ml-1">
                    {{selectedAppt.selectedDoctor.partner_mobile?(selectedAppt.selectedDoctor.partner_mobile):selectedAppt.selectedDoctor.practice_mobile?(selectedAppt.selectedDoctor.practice_mobile):'N.A.'}}
                  </b>
                </div>
              </div>
              <br/><br/>
              <h4>Training Info: </h4>
              <div class="row">
                <div class="col-4">
                  Schedule
                  Date:<b
                    class="ml-1">{{selectedAppt.schedule_date}}</b>
                </div>
                <div class="col-4">
                  Schedule Time:<b class="ml-1">{{selectedAppt.schedule_time}}</b>
                </div>
                <!--<div class="col-4">
                  Timezone:<b class="ml-1">{{selectedAppt.offset}}</b>
                </div>-->
                <div class="col-4">
                  Duration:<b class="ml-1">{{selectedAppt.training_duration + ' mins'}}</b>
                </div>
                <div class="col-4">
                  Type of Training:
                  <b class="ml-1">{{selectedAppt.selectedTrainingObj ? selectedAppt.selectedTrainingObj.name : ''}}</b>
                </div>
                <div class="col-4">
                    Assigned to:
                    <b class="ml-1">{{selectedAppt.excludeAdmin.admin_name + ' ('+selectedAppt.excludeAdmin.admin_email+')'}}</b>
                  </div>
                  <div class="col-4">
                    Status:
                    <b class="ml-1">{{selectedAppt.training_status_name}}</b>
                  </div>
                  <div class="col-4" *ngIf="selectedAppt.eventId">
                    <a href="javascript:void(0)" [routerLink]="'/app/training/details/'+ selectedAppt.eventId" (click)="hideTrainingModal()">
                      View All Details
                    </a>
                  </div>
                <!--<div class="col-4">
                  <a href="javascript:void(0)" [routerLink]="'/app/training/details/'+ selectedAppt.training_id">
                    View All Details
                  </a>
                </div>-->
              </div>
            </div>
            <div class="row" *ngIf="selectedAppt.viewType === 'newTraining'">
              <label class="past-training">Do you want to book past training ?</label>
              <div class="switch">
                <label>
                  <input class="form-control" type="checkbox" [(ngModel)]="trainingSwitch" name="trainingSwitch">
                  <span class="lever"></span>
                </label>
              </div>
            </div>
            <hr>
            <div class="row align-items-center">
                <div class="col-4" *ngIf="!trainingSwitch && (selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining')">
                    <div class="form-group">
                      <label>Schedule Date : </label>
                      <input class="form-control datepicker_field" name="aptDate" [(ngModel)]="selectedAppt.date" bsDatepicker (ngModelChange)="trainingDateChanged()" [minDate]="getMinDate()" type='text' readonly  [bsConfig]="{  containerClass: 'theme-blue' }" />
                    </div>
                </div>
                <div class="col-4" *ngIf="trainingSwitch">
                  <div class="form-group">
                    <label>Schedule Date : </label>
                    <input class="form-control datepicker_field" name="selectedAppt.date" [(ngModel)]="selectedAppt.date" bsDatepicker [maxDate]="getPastMinDate()" type='text' readonly  [bsConfig]="{  containerClass: 'theme-blue' }" />
                  </div>
               </div>
              <!--<div class="col-4">
                <label for="">Time*</label>
                <timepicker required [(ngModel)]="selectedAppt.time" #aptTime="ngModel" name="aptTime"></timepicker>
              </div>-->
              <div class="col-4" *ngIf="selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining'">
                <div class="form-group">
                  <label for="">Type of Training*</label>
                  <select required class="form-control" [(ngModel)]="selectedAppt.selectedTraining" name="aptTraining" (change)="onTrainingSelect()">
                    <option *ngFor="let type of selectedAppt.trainingTypes" [value]="type.type_id">{{type.name}}</option>
                  </select>
                </div>
              </div>
              <!-- <div class="w-100"></div> -->
              <div class="col-4 form-group" *ngIf="selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining'">
                <label for="timezone">Timezone*</label>
                <select name="aptTimezone"  class="form-control"
                  [(ngModel)]="selectedAppt.timezone" (change)="trainingTimezoneChanged()">
                  <option *ngFor="let timezone of timezones" [value]="timezone.code+'****'+timezone.tzOffset">{{timezone.name}} (GMT{{timezone.timezoneString}})</option>
                </select>
              </div>
              <div class="col-4 form-group" *ngIf="!trainingSwitch && (selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining')">
                  <label for="timezone">Training Duration*</label>
                  <select name="aptDuration" class="form-control"
                    [(ngModel)]="selectedAppt.trainingDuration" (change)="trainingDurationChanged()">
                    <option *ngFor="let trainingDuration of trainingDurations" [value]="trainingDuration">{{trainingDuration}} Minutes</option>
                  </select>
                </div>
                <div class="col-4 form-group" *ngIf="trainingSwitch && (selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining')">
                  <label for="timezone">Training Duration*</label>
                  <select name="aptDuration" class="form-control"
                    [(ngModel)]="selectedAppt.trainingDuration">
                    <option *ngFor="let trainingDuration of trainingDurations" [value]="trainingDuration">{{trainingDuration}} Minutes</option>
                  </select>
                </div>
                <div class="col-4 form-group" *ngIf="selectedAppt.viewType !== 'updateTrainingNotes' && selectedAppt.viewType !== 'newTraining'">
                  <label for="status">Status*</label>
                  <select name="aptStatus" [(ngModel)]="selectedAppt.status" id="status" class="form-control" 
                  (change)="trainingStatusChanged()">
                    <!--<option value="1">New Registration</option>-->
                    <ng-container *ngFor="let trainingStatus of trainingStatusTypes">
                      <option *ngIf="shouldShowOption(trainingStatus.training_status_id, selectedAppt)" [value]="trainingStatus.training_status_id">{{trainingStatus && trainingStatus.training_status_name}}</option>
                    </ng-container>
                  </select>
                </div>
                <div class="col-4 form-group" *ngIf="selectedAppt.viewType !== 'updateTrainingNotes' && selectedAppt.viewType !== 'newTraining' && (selectedAppt.status == 4 || selectedAppt.status == 9)">
                  <label for="">Follow Up*</label>
                  <div class="input-group">
                    <input required [(ngModel)]="selectedAppt.followup_date" name="followupDate"
                      class="form-control" bsDatepicker readonly #dp="bsDatepicker" [bsConfig]="dateConfig" [minDate]="today"
                      style="background: #ffffff;" placeholder="Choose a date">
                    <div class="input-group-append">
                      <button type="button" (click)="dp.toggle()" class="btn rounded"><i class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>
                <div class="col-4 form-group checkbox c-checkbox mt-4" *ngIf="!trainingSwitch && (!selectedAppt.out_of_system || selectedAppt.viewType === 'editTraining')">
                  <label>
                    <input #sendEmail [(ngModel)]="selectedAppt.send_email" name="sendEmail" type="checkbox">
                    <span class="fa fa-check"></span>Send Email
                  </label>
                </div>
            </div>

            <div class="w-100"></div>
            <div class="row align-items-center form-group" *ngIf="!trainingSwitch && (selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining')">
                <div class="col-12">Select Training Time*</div>
                <div class="col-12 no_available_time_error" *ngIf="!selectedAppt.availableStartTimes.length">No slots available for selected date.</div>
                <div class="col-2" *ngFor="let availableStartTime of selectedAppt.availableStartTimes; let i = index;">
                  <button type="button" class="btn available_start_time"
                  [ngClass]="selectedAppt.selectedAvailableStartTime ? selectedAppt.selectedAvailableStartTime.timeAmPm ===  availableStartTime.timeAmPm ? 'btn-primary' : 'btn-outline-secondary' : 'btn-outline-secondary'"
                  (click)="selectTrainingTime(availableStartTime)">{{availableStartTime.timeAmPm}}</button>
                </div>
            </div>
            <div class="row align-items-center form-group" *ngIf="trainingSwitch">
              <div class="col-12">Select Training Time*</div><br>
                <div class="col-6 past-hours">
                  <ngx-select [items]="hours" optionValueField="value" optionTextField="value" name="value"
                    [allowClear]="false" placeholder="HH" [(ngModel)]="pastTrainingTime.hour">
                    <ng-template ngx-select-option-selected let-option>
                      {{option.data.value}}
                    </ng-template>
                    <ng-template ngx-select-option-not-found>
                      Not found
                    </ng-template>
                  </ngx-select>
                </div>
                <div class="col-6">
                  <ngx-select [items]="minutes" optionValueField="value" optionTextField="value" name="value"
                    [allowClear]="false" placeholder="MM" [(ngModel)]="pastTrainingTime.minutes">
                    <ng-template ngx-select-option-selected let-option>
                      {{option.data.value}}
                    </ng-template>
                    <ng-template ngx-select-option-not-found>
                      Not found
                    </ng-template>
                  </ngx-select>
                </div>
            </div>  

            <div class="row align-items-center" *ngIf="selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining'">
                <div class="col-4 form-group" *ngIf="selectedAppt.availableAdminUsers.length">
                    <div class="form-group">
                        <label>CSR Name*</label>
                          <ngx-select [items]="selectedAppt.availableAdminUsers" optionValueField="admin_id" optionTextField="admin_name"
                            [allowClear]="true" (select)="selectApptAdmin()" (typed)="fetchAvailableAdminList($event)" name="selectedAdmin" [(ngModel)]="selectedAppt.selectedAdminId"
                            [placeholder]="'Select CSR'" [disabled]="selectedAppt.assign_automatic">
                          <ng-template ngx-select-option-selected let-option>
                            {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
                          </ng-template>
                          <ng-template ngx-select-option let-option let-text="text">
                            {{option.data.admin_name}} <br>
                            <small>{{option.data.admin_email}}</small>
                          </ng-template>
                          <ng-template ngx-select-option-not-found>
                            Not found
                          </ng-template>
                        </ngx-select>
                      </div>
                </div>
                <div class="checkbox c-checkbox mt-1 col-4" *ngIf="selectedAppt.availableAdminUsers.length">
                    <label>
                      <input #assign_automatic [(ngModel)]="selectedAppt.assign_automatic" name="assign_automatic" type="checkbox"  (ngModelChange)="assignAutomaticChanged()" />
                      <span class="fa fa-check"></span>Assign Automatically
                    </label>
                </div>
            </div>

              <div class="w-100"></div>
              <div class="row align-items-center form-group" *ngIf="selectedAppt.viewType === 'newTraining'">
                
                <div class="col-8">
                  <label>Book Training For</label>
                  <div>
                    <label class="c-radio form-group">
                      <input checked="checked" [(ngModel)]="selectedAppt.training_doctor_partner_type"
                        (ngModelChange)="resetTraineeSelection()"
                        name="training_doctor_partner_type" type="radio" value="doctor">
                      <span class="fa fa-circle"></span>Business
                    </label>
                    <label class="c-radio form-group">
                      <input name="training_doctor_partner_type" [(ngModel)]="selectedAppt.training_doctor_partner_type"
                        type="radio" value="partner" (ngModelChange)="resetTraineeSelection()">
                      <span class="fa fa-circle"></span>Partner
                    </label>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-4" *ngIf="!trainingSwitch">
                  <div class="checkbox c-checkbox mt-1 form-group">
                    <label>
                      <input #out_of_system [(ngModel)]="selectedAppt.out_of_system" name="out_of_system" type="checkbox"  (ngModelChange)="outOfSystemChanged()" />
                      <span class="fa fa-check"></span>Unregistered <i class="fa fa-info-circle mt-1" data-container="body" data-placement="top"
                      tooltip="Please select this option to schedule training for unregistered Business"></i>
                    </label>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-12 pl-0" *ngIf="!selectedAppt.out_of_system">
                  <div class="col-6" *ngIf="selectedAppt.training_doctor_partner_type == 'doctor'">
                    <div class="form-group">
                      <label>Business Name*</label>
                        <ngx-select 
                        [items]="selectedAppt.doctors" optionValueField="doctor_id" optionTextField="doctor_name"
                        [allowClear]="false" (select)="selectTrainingDoctor($event)" 
                        (typed)="fetchDoctorList($event)" name="trainingSelectedDoctor" [(ngModel)]="selectedAppt.selectedDoctorId" readonly>
                            <ng-template ngx-select-option-selected let-option>
                              {{option.data.practice_name?option.data.practice_name:'-'}}
                            </ng-template>
                            <ng-template ngx-select-option let-option let-text="text">
                                <h5 class="m-0">{{option.data.doctor_name}}</h5>
                                <small class="m-0">
                                  Business: {{option.data.practice_name}} | Email: {{option.data.doctor_email}}
                                </small>
                            </ng-template>
                            <ng-template ngx-select-option-not-found>
                              Not found
                            </ng-template>
                          </ngx-select> 
                    </div>
                  </div>
                  <div class="col-6" *ngIf="selectedAppt.training_doctor_partner_type == 'partner'">
                    <div class="form-group">
                      <label>Partner Name*</label>
                        <ngx-select [items]="selectedAppt.doctors" optionValueField="partner_id" optionTextField="partner_name"
                          [allowClear]="false" (select)="selectTrainingDoctor($event)" (typed)="fetchDoctorList($event)"
                          name="trainingSelectedDoctor" [(ngModel)]="selectedAppt.selectedDoctorId" readonly>
                          <ng-template ngx-select-option-selected let-option>
                            {{option.data.partner_name?option.data.partner_name:'-'}}
                          </ng-template>
                          <ng-template ngx-select-option let-option let-text="text">
                            <h5 class="m-0">{{option.data.partner_name}}</h5>
                            <small class="m-0">
                              {{option.data.partner_email}}
                            </small>
                          </ng-template>
                          <ng-template ngx-select-option-not-found>
                            Not found
                          </ng-template>
                        </ngx-select>
                    </div>
                  </div>
                </div>
                
                <div class="col-6" *ngIf="selectedAppt.out_of_system && selectedAppt.training_doctor_partner_type == 'doctor'">
                  <div class="form-group">
                    <label>Business Name</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedAppt.selectedDoctor.practice_name" practice name="aptPracticeName">
                    <p class="text-danger"
                      *ngIf="selectedAppt.out_of_system && (selectedAppt.selectedDoctor.practice_name ? selectedAppt.selectedDoctor.practice_name.length > 256 : false)">Please enter valid Business name.
                      </p>
                  </div>
                </div>
                <!--<span *ngIf="!selectedAppt.out_of_system && selectedAppt.selectedDoctor ">-->
                  <div class="col-6" *ngIf="(!selectedAppt.out_of_system && selectedAppt.selectedDoctor) || selectedAppt.out_of_system">
                    <div class="form-group">
                      <label for="">Contact Name*</label>
                      <input [disabled]="!selectedAppt.out_of_system" type="text" class="form-control" contact [(ngModel)]="selectedAppt.selectedDoctor.doctor_name" name="aptContactName" trim="true">
                      <p class="text-danger"
                      *ngIf="selectedAppt.out_of_system && (!selectedAppt.selectedDoctor.doctor_name || selectedAppt.selectedDoctor.doctor_name.length > 256)">Please enter valid contact name.
                      </p>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="(!selectedAppt.out_of_system && selectedAppt.selectedDoctor) || selectedAppt.out_of_system">
                    <div class="form-group">
                      <label for="">Phone Number</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <select name="mobile_code" class="input-group-text p-1"  [(ngModel)]="selectedMobileCode" (change)="changeMobileCode(selectedMobileCode)">
                            <option *ngFor="let option of mobile_code_arr; let i =index" [value]="option" >
                              <span>{{option}}</span>
                            </option>
                          </select>
                        </div>
                        <input [disabled]="!selectedAppt.out_of_system" phone type="text" class="form-control"
                        [(ngModel)]="selectedAppt.selectedDoctor.doctor_mobile" name="aptPhone" />
                      </div>
                      <p class="text-danger" *ngIf="selectedAppt.out_of_system && (selectedAppt.selectedDoctor.doctor_mobile ? !isPhoneNumberValid(selectedAppt.selectedDoctor.doctor_mobile) : false)">Please enter a valid phone number.
                      </p>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="(!selectedAppt.out_of_system && selectedAppt.selectedDoctor) || selectedAppt.out_of_system">
                    <div class="form-group">
                      <label for="">Email Address*</label>
                      <input [disabled]="!selectedAppt.out_of_system" type="text" email name="aptEmail" class="form-control" [(ngModel)]="selectedAppt.selectedDoctor.doctor_emails" />
                      <p class="text-danger" *ngIf="selectedAppt.out_of_system && (!selectedAppt.selectedDoctor.doctor_emails || !isValidEmail(selectedAppt.selectedDoctor.doctor_emails))">Please enter a valid email.
                      </p>
                    </div>
                  </div>
                <!--</span>-->
                <!--<div class ="col-12" *ngIf="selectedAppt.out_of_system">
                  <div class="form-group">
                    <label>Enter Email (Press enter to add):</label>
                    <input type="text" class="form-control" email [(ngModel)]="doctor_emails" #cEmail="ngModel" name="cEmail"
                    (keyup.enter)="addEmails($event,addAppointmentForm)" trim="blur" />
                    <p class="text-danger" *ngIf="cEmail.invalid && (cEmail.dirty || cEmail.touched)">Please enter a valid email.
                    </p>
                  </div>
                  <div>
                    <label *ngIf="doctorEmails && doctorEmails.length > 0">Selected Emails: </label>
                    <h3>
                      <span class="ml-1 badge text-large badge-primary" *ngFor="let email of doctorEmails">{{email}}
                        &nbsp;<i class="fa fa-close" tappable (click)="removeEmail(email)"></i>
                      </span>
                    </h3>
                    <label *ngIf="!doctorEmails || doctorEmails.length == 0">No Email added yet</label>
                  </div>
                </div>-->
              </div>
              <div class="row align-items-center form-group" *ngIf="selectedAppt.viewType === 'newTraining' || selectedAppt.viewType === 'editTraining'">
                <div class="col-6">
                  <div class="form-group">
                    <label for="">Meeting Link</label>
                    <input type="text" name="aptMeetingLink" class="form-control" [(ngModel)]="selectedAppt.trainingMeetingLink" />
                    <p class="text-danger" *ngIf="(selectedAppt.trainingMeetingLink && !isValidUrl(selectedAppt.trainingMeetingLink))">Please enter a valid link.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
              <div class="col-12 form-group">
                <label for="note">Note*</label>
                <textarea name="aptNote" required [(ngModel)]="selectedAppt.trainingNote" id="" cols="30" rows="4"
                  class="form-control"></textarea>
              </div>
              </div>
              <!--<div class="row align-items-center">

              </div>-->
          </div>

        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="text-right">
              <button type="submit" [disabled]="!enableDisableSubmitTrainingButton()" class="btn btn-primary">{{submitTrainingButtonTitle()}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
<!--</div>-->