<div class="p-3">


<div class="d-flex align-items-center justify-content-between">
  <h3 class="m-0 fs-24 fw-700 text-secondary mb-3">Customer Contracts</h3>

  <div class="d-flex align-items-center">
    <a
      class="btn btn-primary me-2"
      [routerLink]="['/app/customers']"
      *ngIf="this.previousUrl == '/app/customers' || !this.previousUrl"
    >
      Go to Customers
    </a>
    <button
      class="btn btn-primary"
      [routerLink]="['/app/customers/profile', id]"
      *ngIf="this.previousUrl != '/app/customers' && this.previousUrl != '/'"
    >
      Back
    </button>
  </div>
</div>
<div class="mb-2 border-bottom">
  <button
    class="tab"
    [class.active]="contractType == 1"
    [routerLink]="'/app/customers/contracts/' + id"
    [queryParams]="{ contractType: 1 }"
  >
    Active
  </button>
  <button
    class="tab"
    [class.active]="contractType == 3"
    [routerLink]="'/app/customers/contracts/' + id"
    [queryParams]="{ contractType: 3 }"
  >
    Overdue
  </button>
  <button
    class="tab"
    [class.active]="contractType == 4"
    [routerLink]="'/app/customers/contracts/' + id"
    [queryParams]="{ contractType: 4 }"
  >
    Completed
  </button>
  <button
    class="tab"
    [class.active]="contractType == 5"
    [routerLink]="'/app/customers/contracts/' + id"
    [queryParams]="{ contractType: 5 }"
  >
    Canceled
  </button>
</div>
<div class="row card card-body">
  <div class="row m-0">
    <div class="col-sm-4 col-md-3">
      <div class="input-group">
        <input
          type="text"
          (keyup.enter)="onSearch()"          
          class="form-control"
          placeholder="Search (press enter to search)"
        />
        <button
          class="input-group-append btn rounded"
          *ngIf="search_string"
          (click)="onSearchClear()"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
    </div>
    <div class="col-sm-4 col-md-4 py-2 text-md-center">
      <span class="count">
        Total Contracts:
        {{ resData && resData.totalRecords ? resData.totalRecords : 0 }}
      </span>
    </div>
    <div
      class="col-sm-4 col-md-5 text-center"
      *ngIf="!_shared.routerAuth.restricted_buttons.export"
    >
      <div class="btn-group pull-right" dropdown>
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn btn-primary dropdown-toggle"
          aria-controls="dropdown-basic"
        >
          Export <span class="caret"></span>
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportXLSX(0)">
              Export XLSX
            </button>
          </li>
          <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportXLSX(1)">
              Export CSV
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="table-responsive"
    *ngIf="contracts && contracts.length > 0; else emptyContracts"
  >
    <table class="table">
      <thead>
        <tr>
          <th>S No.</th>
          <th>Contract Code</th>
          <th>Financed Amount</th>
          <th>Remaining Amount</th>
          <th>Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contract of contracts; let i = index">
          <td>{{ (resData.pageNumber - 1) * 10 + (i + 1) }}</td>
          <td>
            <a
              href="javascript:void(0)"
              [routerLink]="'/app/contracts/details/' + contract.contract_id"
              >{{ contract.contract_code }}</a
            >
            <br />
            <!-- <span class="badge badge-warning" *ngIf="contract.contract_type == 1">10% Platform Fee</span> -->
            <span
              class="badge badge-success"
              *ngIf="contract.contract_type == 2"
              >Secure Mutual</span
            >
            <span
              class="badge badge-primary"
              *ngIf="contract.contract_type == 3"
              > Max Mutual</span
            >
            <span
              class="badge badge-primary"
              *ngIf="contract.contract_type == 4"
              >{{ contract.ez_plan_new ? "ZeroFi" : "SimpleeFi" }}</span
            >
            <span
              class="badge badge-primary"
              *ngIf="contract.contract_type == 6"
              >SimpleeFi (With Deferred Interest)</span
            >
            <span
              class="badge badge-primary"
              *ngIf="contract.contract_type == 5"
              >Debt Recovery Payment Plan</span
            >
          </td>
          <td>
            {{ contract.display_logo
            }}{{ contract.financed_amount ? contract.financed_amount : "-" }}
          </td>
          <td>
            {{ contract.display_logo
            }}{{ contract.remaining_amount ? contract.remaining_amount : "-" }}
          </td>
          <td>{{ contract.contract_date | date : "MM-dd-yyyy" : "+0000" }}</td>
          <td>
            <span
              *ngIf="
                contract.contract_status == 1 || contract.contract_status == 2
              "
            >
              Active</span
            >
            <span *ngIf="contract.contract_status == 3"> Overdue</span>
            <span *ngIf="contract.contract_status == 4"> Completed</span>
            <span *ngIf="contract.contract_status == 5"> Canceled</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="border-top" *ngIf="contracts && contracts.length">
    <div class="row align-items-center mt-2">
      <div class="col">
        <p class="text-bold">
          Showing {{ 1 + (resData.pageNumber - 1) * 10 }} to
          {{ contracts.length + (resData.pageNumber - 1) * 10 }} of
          {{ resData.totalRecords || "all" }}
        </p>
      </div>
      <div class="col">
        <pagination
          class="float-right pagination-sm"
          [totalItems]="resData.totalRecords"
          [maxSize]="5"
          [boundaryLinks]="true"
          [rotate]="false"
          (pageChanged)="pageChanged($event)"
          [(ngModel)]="resData.pageNumber"
        >
        </pagination>
      </div>
    </div>
  </div>

  <ng-template #emptyContracts>
    <div class="d-flex align-items-center justify-content-center flex-column">
      <p>
        <i class="fa fa-exclamation-triangle fa-5x"></i>
      </p>
      <p>No Contracts Found</p>
    </div>
  </ng-template>
</div>
</div>
