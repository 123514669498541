import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class CustomerContractsComponent {
  constructor(
    private _router: ActivatedRoute,
    private datePipe: DatePipe,
    public _shared: SharedService,
    private _local: localStorageService,
    private _api: ApiService
  ) {}

  is_dummy: any = parseInt(this._local.customGetItem('is_dummy'), 10) ? 1 : 0;
  contracts: any;
  resData: any = {
    pageNumber: 1,
  };
  id: any;
  timezone: any = '';
  timezoneName: any = '';
  contractType: number = 1;
  search_string: string = '';
  previousUrl: string = '';

  ngOnInit() {
    this.timezone = this._local.customGetItem('timezone');
    this.timezoneName = this._local.customGetItem('timezoneName');

    this.id = +(this._router.snapshot.paramMap.get('id') || 0);
    // console.log(this.id);
    this._router.url.subscribe((url: any) => {
      this._router.queryParams.subscribe((data: any) => {
        console.log('data.type--', data);
        if (data && data.contractType) {
          this.contractType = data.contractType;
        } else {
          this.contractType = 1;
        }
        this.getContracts(0, this.id);
      });
    });

    //this.previousUrl = this.mainService.getPreviousUrl();
    // console.log("previousroute--",this.mainService.getPreviousUrl(),this.previousUrl)
  }

  getContracts(offset: any, id: any) {
    let data = {
      offset: offset,
      limit: 10,
      patient_id: id,
      is_dummy: this.is_dummy,
      filter: this.contractType == 1 ? '1,2' : this.contractType,
      search_string: this.search_string,
    };
    this._api.api(data, 'customer/patient_contracts', 'post').subscribe(
      (data: any) => {
        // console.log(data);
        this.contracts = data.data;
        this.resData.totalRecords = data.count;
      },
      (error: any) => {}
    );
  }

  pageChanged(event: any) {
    this.resData.pageNumber = event.page;
    let offset = (event.page - 1) * 10;
    this.getContracts(offset, this.id);
  }

  goBack() {
    //this.location.back();
  }

  onSearch() {
    this.resData.pageNumber = 1;
    if (this.search_string.trim()) {
      this.getContracts(0, this.id);
    }
  }

  onSearchClear() {
    this.search_string = '';
    this.resData.pageNumber = 1;
    this.getContracts(0, this.id);
  }

  exportXLSX(ext: any) {
    let data = {
      offset: 0,
      limit: this.resData.totalRecords,
      patient_id: this.id,
      is_dummy: this.is_dummy,
      filter: this.contractType == 1 ? '1,2' : this.contractType,
      search_string: this.search_string,
    };
    this._api.api(data, 'customer/patient_contracts', 'post').subscribe(
      (data: any) => {
        let list = [];
        let contracts = data.data;
        list[0] = {
          contract_code: 'Contract Code',
          financed_amount: 'Financed Amount',
          remaining_amount: 'Remaining Amount',
          contract_date: 'Date',
          contract_status: 'Status',
        };
        for (var i = 0; i < contracts.length; i++) {
          list[i + 1] = {};
          list[i + 1].contract_date =
            this.datePipe.transform(
              contracts[i].contract_date,
              'MM-dd-yyyy',
              '+0000'
            ) || '-';
          list[i + 1].contract_code = contracts[i].contract_code || '-';
          list[i + 1].financed_amount = contracts[i].financed_amount
            ? '$' + contracts[i].financed_amount
            : '-';
          list[i + 1].remaining_amount = contracts[i].remaining_amount
            ? '$' + contracts[i].remaining_amount
            : '-';
          switch (parseInt(contracts[i].contract_status)) {
            case 1:
              list[i + 1].contract_status = 'Active';
              break;
            case 2:
              list[i + 1].contract_status = 'Active';
              break;
            case 3:
              list[i + 1].contract_status = 'Overdue';
              break;
            case 4:
              list[i + 1].contract_status = 'Completed';
              break;
            case 5:
              list[i + 1].contract_status = 'Canceled';
              break;
            default:
              list[i + 1].contract_status = '-';
              break;
          }
        }

        if (ext == 0) {
          this._shared.exportAsExcelFile(list, 'customer_contracts', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'customer_contracts', '.csv');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
