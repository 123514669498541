import { Injectable, OnInit } from '@angular/core';
import { ToasterService } from './toaster/toaster.service';
import { md5 } from './md5.service';
import { localStorageService } from './localstorage.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  Observable,
  Subject,
} from 'rxjs';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MainService } from './main.service';
import { RouteAuthService } from './route-auth.service';
import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { utils } from 'xlsx';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as ExcelJS from "exceljs";
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public timezone: string = '';
  public timezoneName: string = '';
  public selected_country$: BehaviorSubject<any> = new BehaviorSubject({});
  private key_sort_by = '';
  public mask = '';
  public noteadded: boolean = false;
  signaturePadBase64 = '';

  public timezone$: BehaviorSubject<any> = new BehaviorSubject('-700');
  public timezoneName$: BehaviorSubject<any> = new BehaviorSubject('');
  public zone$: BehaviorSubject<any> = new BehaviorSubject('');
  public isDummy$: BehaviorSubject<any> = new BehaviorSubject([]);
  public paymentSourceType = {
    CARD: 3,
    BANK: 1,
  };
  timeoutError: any;
  constructor(
    private _toaster: ToasterService,
    private _local: localStorageService,
    private _main: MainService,
    public routerAuth: RouteAuthService
  ) {
    this.device_id();
  }
  modalTypes = {
    pictureModal: 'pictureModal'
  };

  customPopups(message: string, flag: Number) {
    if (flag == 1) {
      this._toaster.showToaster('Error', message, 'error');
    } else if (flag == 2) {
      this._toaster.showToaster('Warning', message, 'warning');
    } else {
      this._toaster.showToaster('Success', message, 'success');
    }
  }

  crypto(tokenFromUI: string) {
    return md5(tokenFromUI);
  }

  updateMasking(format: '') {
    return format.replace(/#/g, '0');
  }

  guid() {
    const a = new Date();
    const nav = window.navigator;
    const screen = window.screen;
    let guid = nav.mimeTypes.length.toString();
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    guid += a.getTime();
    return guid;
  }

  device_id() {
    if (!this._local.customGetItem('user')) {
      const guidLog = this.guid();
      this._local.customSetItem('user', guidLog);
    }
  }

  getMaxDate(timezone: string) {
    if (timezone) {
      var offset =
        parseInt((parseInt(timezone) / 100).toFixed()) +
        (parseInt(timezone) % 100) / 60;
      var d, utc, nd;
      d = new Date();
      utc = d.getTime() + d.getTimezoneOffset() * 60000;
      nd = new Date(utc + 3600000 * offset);
      return nd;
    } else {
      return new Date();
    }
  }

  updateDummyLive(): Observable<any> {
    return combineLatest([
      this.isDummy$.asObservable(),
      this.timezone$.asObservable(),
      this.selected_country$.asObservable(),
    ]).pipe(
      // Ensure it only emits when values change to avoid multiple calls
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      )
    );
  }

  getTimezone() {
    // let timezone: any = '';
    // this.timezone$.subscribe((timeZone) => {
    //   timezone = timeZone;
    // });
    console.log("gfgdfg",this.timezone);
    
    return this.timezone;
  }

  getTimezoneName() {
    // this.timezoneName$.subscribe((timeZone) => {
    //   timezoneName = timeZone;
    // });
    return this.timezoneName;
  }

  getTimezoneZone() {
    let zone: any = '';
    this.zone$.subscribe((zone) => {
      zone = zone;
    });
    return zone;
  }

  getTimezoneList() {
    let timezoneList: any = '';
    this._main.timezoneList$.subscribe((timezone_list) => {
      timezoneList = timezone_list;
    });
    return timezoneList;
  }

  getAdminList(): Array<[]> {
    let admin_list: any = [];
    this._main.global_admin_list$.subscribe((adminList: any) => {
      admin_list = adminList;
    });
    return admin_list;
  }

  getGovernmentDocs(): Array<[]> {
    let gov_docs: any = [];
    this._main.government_docs$.subscribe((govDocs: any) => {
      gov_docs = govDocs;
    });
    return gov_docs;
  }

  getlanguage_data(): Observable<any> {
    return this._main.language_data$.asObservable();
  }

  getCountryData(): countryType[] {
    let countriesData: any = [];
    this._main.country_data$.subscribe((country_data: any) => {
      countriesData = country_data;
    });
    return countriesData;
  }

  getCurrencyData(): Array<[]> {
    let currenciesData: any = [];
    this._main.currency_data$.subscribe((currency_data: any) => {
      currenciesData = currency_data;
    });
    return currenciesData;
  }

  getMobileCodeArr() {
    let mobileCodeArr: any = [];
    this._main.mobile_code_arr$.subscribe((mobile_code_arr: any) => {
      mobileCodeArr = mobile_code_arr;
    });
    return mobileCodeArr;
  }

  getExactDate(d: Date) {
    return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
  }

  getSalutations() {
    let salutation: any = [];
    this._main.salutations$.subscribe((salutations: any) => {
      salutation = salutations;
    });
    return salutation;
  }

  getIndustryList() {
    let industryList: any = [];
    this._main.industryList$.subscribe((industries: any) => {
      industryList = industries;
    });
    return industryList;
  }

  getStripeKey() {
    let stripeKey: any = '';
    this._main.stripeKey$.subscribe((stripe_key: any) => {
      stripeKey = stripe_key;
    });
    return stripeKey;
  }

  sortByFun(data: Array<[]>, key_sort_by: string): [][] {
    this.key_sort_by = key_sort_by;
    return data.sort((a: any, b: any) => a[key_sort_by] - b[key_sort_by]);
  }

  compare(a: any, b: any) {
    if (a && a[this.key_sort_by] < b && b[this.key_sort_by]) return -1;
    if (a && a[this.key_sort_by] > b && b[this.key_sort_by]) return 1;
    return 0;
  }

  getfollowupDateTimeInUTCToday(date_time: Date, timezone: string) {
    var offset =
      parseInt((parseInt(timezone) / 100).toFixed()) +
      (parseInt(timezone) % 100) / 60;
    var utc, followup_time;
    utc = new Date(date_time).getTime();
    followup_time = moment(new Date(utc - 3600000 * offset));
    return followup_time.format('YYYY-MM-DD HH:mm:00');
  }

  getfollowupDateTimeInUTC(date_time: string, timezone: any) {
    var offset =
      parseInt((parseInt(timezone) / 100).toFixed()) +
      (parseInt(timezone) % 100) / 60;
    var utc, followup_time;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      date_time = moment(date_time).format('YYYY/MM/DD HH:mm:00');
      utc = new Date(date_time.replace(/-/g, '/')).getTime();
    } else {
      utc = new Date(date_time).getTime();
    }
    followup_time = moment(new Date(utc - 3600000 * offset));

    if (isSafari) {
      return followup_time.format('YYYY/MM/DD HH:mm:00');
    }
    return followup_time.format('YYYY-MM-DD HH:mm:00');
  }

  getTransactionFee(contract: any, comparable_value: any) {
    let text = '';
    if (comparable_value < contract.threshold_amount) {
      if (contract.recurring_extra_percent) {
        text += `${contract.recurring_extra_percent.toFixed(2)}%`;
      }
      if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.recurring_extra_amount) {
        text += `$${contract.recurring_extra_amount.toFixed(2)}`;
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        text += `${contract.threshold_recurring_extra_percent.toFixed(2)}%`;
      }
      if (
        contract.threshold_recurring_extra_amount &&
        contract.threshold_recurring_extra_percent
      ) {
        text += ' + ';
      }
      if (contract.threshold_recurring_extra_amount) {
        text += `$${contract.threshold_recurring_extra_amount.toFixed(2)}`;
      }
    }
    return text;
  }

  public exportAsExcelFile(
    json: any[] | any,
    excelFileName: string,
    ext: string
  ): void {
    try {

      console.log("test");
      
      console.log('Exporting:', excelFileName, 'Extension:', ext);
      console.log('Input Data:', json);
  
      let workbook: XLSX.WorkBook;
  
      if (Array.isArray(json) && json.length > 0 && json[0].list && json[0].list1) {
       
        let dataList = json[0].list;
        let dataList1 = json[0].list1;
        if (ext == '.csv') {
          dataList = this.formatPhoneNumbersForCSV(dataList); // Ensure phone numbers remain intact in CSV
          dataList1 = this.formatPhoneNumbersForCSV(dataList1); // Ensure phone numbers remain intact in CSV
        }
        const worksheet = utils.json_to_sheet(dataList, { skipHeader:true });
        const worksheet2 = utils.json_to_sheet(dataList1);
  
        workbook = {
          Sheets: { summary: worksheet, Report: worksheet2 },
          SheetNames: ['summary', 'Report'],
        };
      } else if (Array.isArray(json) && json.length) {
        if (ext == '.csv') {
          json = this.formatPhoneNumbersForCSV(json); // Ensure phone numbers remain intact in CSV
        }
        const worksheet = utils.json_to_sheet(json ,{skipHeader:true});  
        workbook = {
          Sheets: { summary: worksheet },
          SheetNames: ['summary'],
        };
      } else {
        throw new Error('Invalid input data format. Expected array or array with list and list1.');
      }
  
      const bookType = ext === '.xlsx' ? 'xlsx' : ext === '.csv' ? 'csv' : null;
      if (!bookType) {
        throw new Error('Unsupported file extension.');
      }
  
      if (ext === '.csv') {
        let excelBuffer: any = XLSX.write(workbook, { bookType, type: 'string' });
        excelBuffer = '\uFEFF' + excelBuffer;
        this.saveAsExcelFile(excelBuffer, excelFileName, ext);
      }else {
        const excelBuffer: any = XLSX.write(workbook, { bookType, type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName, ext);
      }
    } catch (error: any) {
      console.error('Error exporting file:', error.message);
    }
  }
  

  private saveAsExcelFile(
    buffer: any,
    fileName: string,
    extension: string
  ): void {
    console.log(fileName);
    if (extension == '.csv'){
      // buffer = '\uFEFF' + buffer;
      const blob = new Blob([buffer], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(blob, fileName + '_export' + extension);
    }else {
      const data: Blob = new Blob([buffer]);
      FileSaver.saveAs(data, fileName + '_export' + extension);
    }
  }

  getClaimStatus(claim_status_id: number) {
    let claim_status_array = [
      { id: '-1', value: '-', color: '' },
      { id: '0', value: 'Not Claimed', color: '#e5b319' },
      { id: '1', value: 'Submitted', color: '#e5841c' },
      { id: '2', value: 'Conditionally Approved', color: '#4cd02e' },
      { id: '3', value: 'Denied', color: '#ee6060' },
      { id: '4', value: 'On Hold', color: '#000000' },
      { id: '5', value: 'Paid', color: '#19870e' },
      { id: '6', value: 'Need More Document(s)', color: '#ee6060' },
      { id: '7', value: 'Documents uploaded', color: '#4cd02e' },
      { id: '8', value: 'Unpaid', color: '#18a5b7' },
      { id: '9', value: 'Removed From Unpaid', color: '#ee6060' },
      { id: '10', value: 'Expired : Manually', color: '#ee6060' },
      { id: '12', value: 'Unpaid Pending', color: '#18a5b7' },
    ];
    let claim_status = claim_status_array.find((obj: any) => {
      return obj.id == claim_status_id;
    });
    return claim_status ? claim_status : '';
  }

  urlParams(data: any) {
    const body = new HttpParams({ encoder: new customQueryEncoder() })
      .set('param1', 'value1')
      .set('param2', 'value2');
    Object.keys(data).forEach((key) => {
      body.set(key, data[key]);
    });
    return body.toString();
  }

  // uploadApi(_data: any, url: any, flag: any, method?: string): Observable<any> {
  //   let api_url = this._local.customGetItem('LiveAdminBaseUrl');
  //   if (!api_url) {
  //     api_url = environment.baseUrl;
  //   }
  //   return this.http.post<any>(`${api_url}${url}`, _data).pipe(
  //     timeout(60000),
  //     map((data: any) => {
  //       if (data.flag === 377) {
  //         this._api.loginRedirect();
  //         return false;
  //       }
  //       if (data.flag === 4) {
  //         this._api.loginRedirect();
  //         if (data && data.override_text) {
  //           this._toaster.showToaster('error', data.override_text, 'error');
  //         }
  //         return false;
  //       }
  //       if (data && flag) {
  //         if (data.err) {
  //           if (data.override_text) {
  //             this.customPopups(data.override_text, 1);
  //           } else {
  //             this.customPopups(data.err, 1);
  //           }
  //         } else {
  //           this.customPopups(data.is_error, data.flag);
  //         }
  //       }
  //       return data;
  //     }),
  //     catchError((error: any) => {
  //       if (error instanceof TimeoutError) {
  //         if (!this.timeoutError) {
  //           this.timeoutError = true;
  //           this.customPopups('Request Timeout', 1);
  //         }
  //         return throwError({ error: 'Timeout Exception' });
  //       }
  //       if (error.status === 0) {
  //         if (!this.timeoutError) {
  //           this.customPopups('Unable to fetch data from server', 1);
  //         }
  //       }
  //       return throwError(error);
  //     })
  //   );
  // }

  // getZipcode(params: any) {
  //   if (params.zipcode.length > 3 && params.zipcode.length <= 6) {
  //     params['country_id'] = 1;
  //     return this.http
  //       .post<any>(
  //         `${this._local.customGetItem('LiveAdminBaseUrl')}get_zipcode`,
  //         this.urlParams(params),
  //         { headers: this._api.headers }
  //       )
  //       .pipe(
  //         map((data: any) => {
  //           return data;
  //         }),
  //         catchError((error: any) => {
  //           return throwError(error);
  //         })
  //       );
  //   }
  //   return null;
  // }

  // getZip(val: any, search_canada?: any) {
  //   return new Promise((resolve, reject) => {
  //     let params: any = { zipcode: val };
  //     if (search_canada == 1) {
  //       params['search_canada'] = 1;
  //     }
  //     this.getZipcode(params)?.subscribe(
  //       (data: any) => {
  //         resolve(data.zip_info);
  //       },
  //       (error: any) => {
  //         resolve([]);
  //       }
  //     );
  //   });
  // }
  countryWiseDataFixing(data?: any) {
    const fixing =
      data && data.toString().includes('.') ? data.toFixed(2) : data;
    return fixing;
  }

  base64ToBlob(base64String: string) {
    const dataURI = base64String;
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {
      type: 'image/jpeg'
    });
    return blob;
  }
  
  dashboardMenuExist(menuList: any) {
    return new Promise((resolve, reject) => {
      if (menuList && menuList.length) {
        const item = menuList.filter((menu: any) => (menu.menu_name + '').includes('Dashboard'));
        if (item && item.length) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });
  }

  formatPhoneNumbersForCSV (data: any[]) {
    return data.map((row) => {
      const newRow = { ...row };
      Object.keys(newRow).forEach((key) => {
        if (typeof newRow[key] === 'string' && newRow[key].startsWith('+')) {
          newRow[key] = `\t${newRow[key]}`;  // Add a tab to force text formatting
        }
      });
      return newRow;
    });
  }

  public exportAsExcelFileV2(json1: any[], excelFileName: string, ext: string, col_name?: string): void {
    var workbook = new ExcelJS.Workbook();
    var worksheet = workbook.addWorksheet('Sheet1'); // Add a worksheet

    // Assuming json1 is an array of objects where each object represents a row of data
    json1.forEach((row, rowIndex) => {
      Object.keys(row).forEach((col, colIndex) => {
        if (colIndex === 0) {
          // Set header in the first column (A)
          worksheet.getCell(rowIndex + 1, colIndex + 1).value = row[col];
        // tslint:disable-next-line:max-line-length
        } else if (col_name && col === col_name) { // Check if it's column L (12th column, assuming zero-indexed) /** new update, removed index check for make the code more dynamic*/
          const cell = worksheet.getCell(rowIndex + 1, colIndex + 1); // rowIndex + 1 to adjust for header row
          if (rowIndex == 0) {
            cell.value =  row[col]; // hyperlink: row[col] // Assuming row[col] contains the URL
            // cell.style = {
            //   font: {
            //     color: { argb: '000000' }, // Blue color (ARGB format)
            //   }
            // };
          } else {
            cell.value = {
              text: row[col],
              hyperlink: row[col] // Assuming row[col] contains the URL
            };
            cell.style = {
              font: {
                color: { argb: 'FF0000FF' }, // Blue color (ARGB format)
                underline: true,
              }
            };
          }
          console.log(`Cell ${cell.address} font:`, cell.font);
        } else {
          worksheet.getCell(rowIndex + 1, colIndex + 1).value = row[col]; // Set other columns normally
        }
      });
    });

    workbook.xlsx.writeBuffer().then((buffer: any = Buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${excelFileName}${ext}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }).catch((error: any) => {
      console.error('Error generating Excel file:', error);
    });
  }
}

export enum verifyType {
  Doctor = 'Doctor',
  Partner = 'Partner',
  Patient = 'Patient',
}

export interface countryType {
  country_id: number;
  cocu_id: number;
  display_logo: '';
  country_name: '';
  country_phone_format: ''
}

export interface timezoneType {
  tz_id: number;
  name: string;
  offset: string;
  val: string
}

export interface currencyType {
  cocu_id: number;
  currency_id: number;
  currency_name: string;
  display_logo: string;
}

class customQueryEncoder implements HttpParameterCodec {
  decodeKey(key: string): string {
    throw new Error('Method not implemented.');
  }
  decodeValue(value: string): string {
    throw new Error('Method not implemented.');
  }
  encodeKey(k: string): string {
    return this.encode(k);
  }
  encodeValue(v: string): string {
    return this.encode(v);
  }

  private encode(v: string): string {
    return (
      encodeURIComponent(v)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/gi, '$')
        .replace(/%2C/gi, ',')
        .replace(/%3B/gi, ';')
        // .replace(/%2B/gi, '+')
        .replace(/%3D/gi, '=')
        .replace(/%3F/gi, '?')
        .replace(/%2F/gi, '/')
    );
    // .replace(/%3D/gi, '/');
  }

  
}
export const passwordValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    if (!password) {
      return null;
    }

    const minLength = /.{6,12}/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    const valid = minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
    return !valid ? {
      passwordStrength: {
        requirements: `Password must be 6-12 characters long, and include at least one uppercase letter (A-Z), one lowercase letter (a-z), one number (0-9), and one special character (!@#$%^&*).`
      }
    } : null;
  };
}
