import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as momentTz from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Filters } from 'src/app/components/shared/interface.component';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { DebtRecoveryModalComponent } from '../debt-recovery-modal/debt-recovery-modal.component';
import { DebtRecoveryService } from '../debt-recovery.service';
@Component({
  selector: 'app-debt-recovery-list',
  templateUrl: './debt-recovery-list.component.html',
  styleUrl: './debt-recovery-list.component.scss'
})
export class DebtRecoveryListComponent implements OnInit, OnDestroy {

  env = environment;
  dateRangeValue: any;
  minDate: any = new Date(2024, 0, 1);
  maxDate: any;
  resetBtn: any;
  search: string = '';
  searched: boolean = false;
  splitAppList: any;
  timezone_subscription: any;
  is_dummy: any = this._local.customGetItem('is_dummy') ? this._local.customGetItem('is_dummy') : 0;
  subscription: any;
  subscription1: any;
  filter: any = {
    start_date: '',
    end_date: '',
    is_expired: -1,
    status_filter: 0,
    ProposalType: 0// filter for AR 
  };
  hadSearched = false;
  sort: any = 1;
  sortValue: { by: string, order: string, label: string } = {
    by: 'date_added',
    order: 'DESC',
    label: 'Registration Date'
  };
  resData: any = {
    pageNumber: 1
  };
  totalDefaultCount: any;
  timezone: any = this._shared.getTimezone();
  timezoneName: any = this._shared.getTimezoneName();
  timezoneZone: any = this._shared.getTimezoneZone();
  plansDetail: any = [];
  splitRequest: any;
  show_filters: boolean = false;
  public filters: Array<Filters> = [];
  filter_added: boolean = false;
  selected_req: any = {};
  skip_overdue_check: any;
  display_logo: any;
  contract_type: any;
  country_id: any = this._local.customGetItem('country_id');
  country_subscription: any;
  view_track_status = 0;
  status_track_value = [];
  baseUrlCopyLink: any;
  allSelected = false;
  isAnySelected = false;
  split_req_ids = [];
  apiHitInProgress = false;
  lastResend = false;
  sendAll = false;
  isSendAllAllowed = true;
  failedProposals = [];
  succededProposals = [];
  isSendAllAllowedToAdmin = false;

  constructor(
    public _main: MainService,
    public _shared: SharedService,
    private datePipe: DatePipe,
    public router: Router,
    public route: ActivatedRoute,
    public DecimalPipe: DecimalPipe,
    public filterService: FiltersService,
    public _api: ApiService,
    public _local: localStorageService,
    public modalService: BsModalService,
    public _debtRecovery: DebtRecoveryService
  ) { }

  ngOnInit() {
    this.isSendAllAllowedToAdmin = [1, 4].includes(this._local.customGetItem('admin_profile')['role_id']) || this._local.customGetItem('admin_profile')['boss'];
    this.filters = this.filterService.getProposalFiltersArray(1);
    this.timezone_subscription = this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
      if (isDummy >= 0 || (selected_country && selected_country.length > 0)) {
        this.is_dummy = isDummy;
        this.timezone = timezone;
        this.getSplitAppList();
      }
      this.timezoneName = this._shared.getTimezoneName();
      this.timezoneZone = this._shared.getTimezoneZone();
      this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
      this.dateRangeValue = [this.minDate, this.maxDate];
      this.filter.start_date = this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd");
      this.filter.end_date = this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd");
      this.resData.pageNumber = 1;
      this.filters[1].value = new Date(this.filter.start_date);
      this.filters[2].value = new Date(this.filter.end_date);
      this.country_id = selected_country;
    });

    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.filter.start_date = this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd");
    this.filter.end_date = this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd");

    this.subscription1 = this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.show_filters = data.showFilterTab ? data.showFilterTab : this.show_filters;
        if (!data.startDate && !data.endDate) {
          this.filter.start_date = this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd");
          this.filter.end_date = this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd");
        } else {
          this.dateRangeValue = [this._shared.getExactDate(new Date(data.startDate)), this._shared.getExactDate(new Date(data.endDate))];
        }
        this.filter.status_filter = data.status && parseInt(data.status) > 0 ? parseInt(data.status) : 0;
        this.filter.is_expired = data.activeStatus && parseInt(data.activeStatus) != -1 ? parseInt(data.activeStatus) : -1;
        this.search = data.search ? data.search : this.search;
        this.sortValue.by = data.by ? data.by : 'date_added';
        this.sortValue.order = data.order ? data.order : 'DESC';
        this.resData.pageNumber = data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
        this.filter.ProposalType = data.ProposalType;
        this.filter.last_resent = data.last_resent;
        this.filter.reminder = data.reminder;
        this.getSplitAppList();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.timezone_subscription) {
      this.timezone_subscription.unsubscribe();
    }
    if (this.country_subscription) {
      this.country_subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  getSplitAppList() {
    let params: any = {
      offset: (this.resData.pageNumber - 1) * 10,
      limit: 10,
      is_dummy: this.is_dummy,
      search_string: this.search,
      status_filter: this.filter.status_filter,
      // is_expired: this._main.disabled_filter_status_arr.includes(this.filter.status_filter.toString()) ? -1 : this.filter.is_expired,
      sort_by: this.sortValue.by,
      sort_order: this.sortValue.order,
      min_date_added: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      max_date_added: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
      country_id: this.country_id,
      is_debt_contract: window.location.pathname.includes('/app/debt-recovery/list') ? 0 : 1,
      ProposalType: this.filter.ProposalType,
      last_resent: this.filter.last_resent,
      reminder: this.filter.reminder,
    };
    if (this.search && this.search.length > 0) {
      params['search_string'] = this.search;
    } else {
      delete params.search_string;
    }
    this._api.api(params, "finance/get_debt_recovery_list", 'get').subscribe(
      (data: any) => {
        this.baseUrlCopyLink = data['copy_link_base_url'] || '';
        if (data.data && data.data.length) {
          data.data.forEach((item: any) => {
            item.contract_current_status = 'Proposal Sent';
            item.can_delete = 1;
            switch (true) {
              /*sent requests to patients  */
              case (item.doctor_status == 1 && item.patient_status != 2):
                item.contract_current_status = 'Proposal Sent';
                item.can_delete = 1;
                break;
              /*requests which are completed by patient with card information. */
              case (item.doctor_status == 1 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Submitted to Business';
                item.can_delete = 0;
                break;
              /*approved requests or (financed requests). */
              case (item.doctor_status == 2 && item.patient_status == 2):
                item.contract_current_status = 'Customer Financed';
                item.can_delete = 0;
                break;
              /*rejected requests. */
              case (item.doctor_status == 3 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Rejected by Business';
                item.can_delete = 0;
                break;
              default:
                item.contract_current_status = 'Proposal Sent';
                item.can_delete = 1;
            }
            if (!item.patient_name) {
              if (item.title || item.patient_first_name || item.patient_last_name) {
                item.patient_name = item.title + ' ' + item.patient_first_name + ' ' + item.patient_last_name;
              } else if (item.ap_title || item.ap_patient_first_name || item.ap_patient_last_name) {
                item.patient_name = item.ap_title + ' ' + item.ap_patient_first_name + ' ' + item.ap_patient_last_name;
              }
            }
          });
        }
        this.splitAppList = data.data;
        if (this.splitAppList && this.splitAppList.length) {
          this.splitAppList.forEach((item: any) => {
            if (item.is_expired == 0 && item.contract_current_status === 'Proposal Sent') {
              item['is_selected'] = false;
            }
          });
        }
        this.updateSelectionStatus();
        this.resData.totalRecords = data.number_of_records;
        this.resData.number_of_pages = data.number_of_pages;
        this.totalDefaultCount = data.number_of_records;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  filterValues(event: any) {
    this.resData.pageNumber = 1;
    this.filter.start_date = this.datePipe.transform(event.data.startDate, "yyyy-MM-dd");
    this.filter.end_date = this.datePipe.transform(event.data.endDate, "yyyy-MM-dd");
    this.filter.status_filter = event.data.status;
    this.filter.is_expired = event.data.activeStatus;
    this.sort = event.data.sortBy;
    this.search = event.data.search;
    this.filter.ProposalType = event.data.ProposalType,
      this.filter.last_resent = event.data.last_resent;
    this.filter.reminder = event.data.reminder;
    // if (+this.filter.last_resent) {
    //   this.sort = '7';
    // } else {
    //   this.sort = '1';
    // }
    // this.filters.filter(filter => {
    //   Object.keys(event.data).forEach(key => {
    //     if (filter.control === 'sortBy') {
    //       filter.value = this.sort;
    //     } else if (filter.control === key) {
    //       filter.value = event.data[key];
    //     }
    //   });
    // });
    // this.filters = JSON.parse(JSON.stringify(this.filters));
    switch (parseInt(this.sort)) {
      case 1:
        this.sortValue = { by: 'date_added', order: 'DESC', label: 'Request Date' };
        break;
      case 2:
        this.sortValue = { by: 'patient_name', order: 'ASC', label: 'Customer Name A-Z' };
        break;
      case 3:
        this.sortValue = { by: 'patient_name', order: 'DESC', label: 'Customer Name Z-A' };
        break;
      case 4:
        this.sortValue = { by: 'doctor_name', order: 'ASC', label: 'Business Owner Name A-Z' };
        break;
      case 5:
        this.sortValue = { by: 'doctor_name', order: 'DESC', label: 'Business Owner Name Z-A' };
        break;
      case 6:
        this.sortValue = { by: 'plan_selected_date', order: 'DESC', label: 'Plan Selected Date' };
        break;
      case 7:
        this.sortValue = { by: 'last_email_sent', order: 'DESC', label: 'Resent Date' };
        break;
      case 8:
        this.sortValue = { by: 'last_action', order: 'DESC', label: 'Action Date' };
        break;
      default:
        this.sortValue = { by: 'date_added', order: 'DESC', label: 'Last Sent' };
        break;
    }
    this.modifyQueryParams();
  }

  resetFilters(event: any) {
    this.resData.pageNumber = 1;
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.filter.start_date = this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd");
    this.filter.end_date = this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd");
    this.filter.status_filter = 0;
    this.filter.is_expired = -1;
    this.filter.ProposalType = 0;
    this.sortValue = { by: 'date_added', order: 'DESC', label: 'Request Date' };
    this.sort = 1;
    this.search = '';
    this.filter.last_resent = '0';
    this.filter.reminder = '0';
    this.modifyQueryParams();
  }

  modifyQueryParams() {
    this.router.navigate([], {
      queryParams: {
        page: this.resData.pageNumber,
        startDate: this.datePipe.transform(this.filter.start_date, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.filter.end_date, 'yyyy-MM-dd'),
        status: this.filter.status_filter,
        by: this.sortValue.by,
        order: this.sortValue.order,
        sortBy: this.sort,
        search: this.search,
        showFilterTab: this.show_filters,
        ProposalType: this.filter.ProposalType,
        last_resent: this.filter.last_resent,
        reminder: this.filter.reminder
      },
      queryParamsHandling: "merge"
    });
  }

  showFilter(value: any) {
    this.show_filters = value;
  }

  pageChanged(event: any) {
    this.allSelected = false;
    this.resData.pageNumber = event.page;
    this.filter.offset = (event.page - 1) * 10;
    this.updateSelectionStatus();
    this.router.navigate([], { queryParams: { page: this.resData.pageNumber }, queryParamsHandling: 'merge' });
  }

  goToContract(contract_id: any) {
    this.router.navigate(['/app/contracts/details/' + contract_id]);
  }

  goToPaymentProcess(PPr_id: any) {
    this.router.navigate(['/app/debt-recovery/details/' + PPr_id]);
  }

  gotoproposaldetails(split_req_id: any) {
    this.router.navigate(['/app/debt-recovery/details/' + split_req_id]);
  }

  deleteRequest(request_id: any) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Please enter a note about your update`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      // inputValue = inputValue && inputValue.trim();
      if (inputValue === '' || inputValue.length == 0) {
        this._shared.customPopups(' You need to write something! ', 1);
        return false;
      }
      if (inputValue && inputValue.success) {
        // swal.close();
        let data = {
          split_req_id: request_id
        };
        this._api.api(data, 'finance/delete_proposal')
          .subscribe((data: any) => {
            this.getSplitAppList();
          }, (error: any) => {
            console.log(error);
          })
      }
      return true;
    });
  }

  openPlansDetail(split_req: any) {
    this.splitRequest = split_req;
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure, you want to resend the proposal to the customer(s)?`,
          hideNotes: true,
          modal_heading: 'Resend Proposal Email',
          data: [{ patient_email: this.splitRequest.patient_email, patient_mobile: this.splitRequest.patient_mobile }]
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      // inputValue = inputValue && inputValue.trim();
      if (inputValue && inputValue.success) {
        const split_req_id_arr: any = [];
        this.failedProposals = [];
        this.splitAppList.filter((item: any) => {
          if (item.is_selected) {
            split_req_id_arr.push((item.split_req_id).toString());
          }
        });
        if (!split_req_id_arr.includes(this.splitRequest['split_req_id']) && this.splitRequest) {
          split_req_id_arr.push((this.splitRequest['split_req_id']).toString());
        }
        if (this.sendAll) {
          this.getAllProposalIds();
        }
        let params = {
          split_req_id_arr: this.sendAll ? this.split_req_ids : split_req_id_arr,
          // doctor_id: this.splitRequest.doctor_id
        };
        this.apiHitInProgress = true;
        // modal.hide();
        this._api.api(params, 'batch_resend_split_plans')
          .subscribe((data: any) => {
            this.apiHitInProgress = false;
            if (data && typeof data == 'string') data = JSON.parse(data);
            if (data.is_error == 0) {
              if (data.batch_status) {
                if (data.batch_status.failed) {
                  this.failedProposals = data.batch_status.failed;
                }
                if (data.batch_status.success) {
                  this.succededProposals = data.batch_status.success;
                  this._shared.customPopups('Proposal Reminder(s) sent successfully', 0);
                }
                if (this.allSelected) {
                  this.updateSelection(0, 'all');
                }
                this.isAnySelected = false;
              }
              if ((data.code && data.code === 202) || (data.batch_status && data.batch_status.failed && data.batch_status.failed.length)) {
                // this.sendAllSuccess.show();
              }
              this.sendAll = false;
              this.getSplitAppList();
            } else {
              this._shared.customPopups(data.err, 1);
            }
          }, (error: any) => {
            console.log(error)
          })
      }

    });
    // modal.show();
  }

  viewDetails(split_req: any, from_ar = 0) {
    this.splitRequest = split_req;
    this.view_track_status = from_ar;
    let params = {
      split_req_id: this.splitRequest.split_req_id,
    };
    this._api.api(params, 'finance/get_debt_recovery_proposal_details', 'get')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        if (data.is_error == 0 && data.split_request_data && data.split_request_data[0]) {
          this.display_logo = data.split_request_data[0].display_logo
          this.contract_type = data.split_request_data[0].contract_type
          this.plansDetail = data.split_request_data[0]['plans_array'];
          this.plansDetail.forEach((plan: any) => {
            plan.recurring_amount = parseFloat(plan.recurring_amount);
            plan.recurring_extra_amount = data.split_request_data[0].recurring_extra_amount;
            plan.recurring_extra_percent = data.split_request_data[0].recurring_extra_percent;
            plan.threshold_amount = data.split_request_data[0].threshold_amount;
            plan.threshold_recurring_extra_amount = data.split_request_data[0].threshold_recurring_extra_amount;
            plan.threshold_recurring_extra_percent = data.split_request_data[0].threshold_recurring_extra_percent;
            plan.treatment_amount = data.split_request_data[0].treatment_amount;
            plan.downpayment_amount = data.split_request_data[0].downpayment_amount;

            if (data.split_request_data[0].contract_type === 1) {
              if (data.split_request_data[0] && data.split_request_data[0].payment_reserve_on_pay) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else if (data.split_request_data[0].contract_type === 3) {
              if (data.split_request_data[0] && data.split_request_data[0].payment_reserve_on_pay_new_ten) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else {
              plan.advance_interest_amount = plan.advance_interest_amount ? parseFloat(parseFloat(
                plan.advance_interest_amount
              ).toFixed(2)) : 0;
            }
            plan.month = parseInt(plan.month);
            let fixed_flat_fee = data.split_request_data[0].enrollment_enabled ? 0 : plan.enrollment_fee;
            plan.payable_amount_today = parseFloat(plan.downpayment_amount) + this.getCalculatedTransactionFee(plan, plan.downpayment_amount)
              + parseFloat(plan.advance_interest_amount) + this.getCalculatedTransactionFee(plan, plan.advance_interest_amount)
              + fixed_flat_fee;
          });
          data.split_request_data[0].plans_array = this.plansDetail;
          const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
            initialState: {
              modalType: this._debtRecovery.modalTypes.detailsModal,
              details_extra_data: {
                label: 'Proposal Status',
                data: data.split_request_data,
                view_track_status: this.view_track_status
              },
            },
            class: 'modal-dialog-centered',
          });
          modelRef.content?.passEntry.subscribe((inputValue) => {
            return true;
          });
          // modal.show();
          this.status_track_value = data.split_request_data[0]['tracking_reminders']
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  getCalculatedTransactionFee(contract: any, comparable_value: any) {
    comparable_value = parseFloat(comparable_value);
    contract.recurring_extra_amount = parseFloat(contract.recurring_extra_amount);
    contract.recurring_extra_percent = parseFloat(contract.recurring_extra_percent);
    contract.threshold_amount = parseFloat(contract.threshold_amount);
    contract.threshold_recurring_extra_amount = parseFloat(contract.threshold_recurring_extra_amount);
    contract.threshold_recurring_extra_percent = parseFloat(contract.threshold_recurring_extra_percent);
    let transaction_fee = 0;
    if (comparable_value === 0) {
      return 0
    } else if ((comparable_value < contract.threshold_amount) || (contract.threshold_amount === undefined && contract.recurring_extra_amount !== undefined)) {
      if (contract.recurring_extra_percent) {
        transaction_fee += (comparable_value * contract.recurring_extra_percent) / 100;
      }
      if (contract.recurring_extra_amount) {
        transaction_fee += contract.recurring_extra_amount;
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        transaction_fee += (comparable_value * contract.threshold_recurring_extra_percent) / 100;
      }
      if (contract.threshold_recurring_extra_amount) {
        transaction_fee += contract.threshold_recurring_extra_amount;
      }
    }
    return transaction_fee;
  }

  exportXLSX(ext: any, filters?: any) {
    let is_debt_contract = window.location.pathname.includes('/app/debt-recovery/list') ? 0 : 1;
    let data: any = {
      offset: 0,
      is_dummy: this.is_dummy,
      country_id: this.country_id,
      is_debt_contract,
      is_export: 1
    };
    if (filters == 1) {
      data['search_string'] = this.search || '';
      data['status_filter'] = this.filter.status_filter || 0;
      data['limit'] = this.resData.totalRecords;
      data['min_date_added'] = this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd');
      data['max_date_added'] = this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd');
      data['sort_by'] = this.sortValue.by || "date_added";
      data['sort_order'] = this.sortValue.order || "DESC";
      data['reminder'] = this.filter.reminder;
    }
    else {
      data['search_string'] = '';
      data['status_filter'] = 0;
      data['is_expired'] = -1;
      data['limit'] = this.totalDefaultCount;
      data['min_date_added'] = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');
      data['max_date_added'] = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');
      data['sort_by'] = "date_added";
      data['sort_order'] = "DESC";
      data['reminder'] = this.filter.reminder;
    }
    let show_proposal_link = false;
    this._api.api(data, 'finance/get_debt_recovery_list', 'get')
      .subscribe((data: any) => {
        show_proposal_link = data.show_proposal_link;
        if (data.data && data.data.length) {
          data.data.forEach((item: any) => {
            item.contract_current_status = 'Proposal Sent';
            switch (true) {
              /*sent requests to patients  */
              case (item.doctor_status == 1 && item.patient_status != 2):
                item.contract_current_status = 'Proposal Sent';
                break;
              /*requests which are completed by patient with card information. */
              case (item.doctor_status == 1 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Submitted to Business';
                break;
              /*approved requests or (financed requests). */
              case (item.doctor_status == 2 && item.patient_status == 2):
                item.contract_current_status = 'Customer Financed';
                break;
              /*rejected requests. */
              case (item.doctor_status == 3 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Rejected by Business';
                break;
              default:
                item.contract_current_status = 'Proposal Sent';
            }
          });
        }
        let list: any = [];
        let list1 = [];
        let requestsList = data.data;
        list[0] = {
          patient_name: 'Customer Name',
          patient_email: 'Customer Email',
          patient_address: 'Customer Address',
          patient_mobile: 'Customer Phone',
          doctor_name: 'Business Name',
          doctor_email: 'Business Email',
          contract_id: 'Contract ID',
          contract_type: 'Contract Type',
          current_status: 'Current Status',
          email_status: 'Email Status',
          is_sms_failed: 'SMS Status',
          stop_reminder_email: 'Email Reminder',
          stop_reminder_sms: 'SMS Reminder',
          date_added: 'Request Date',
          start_date: 'Proposal Start Date',
          end_date: 'Proposal End Date',
          plan_created_date: 'Contract Creation/Payoff Date',
          proposal_type: 'Proposal Type',
          proposal_resent: 'Proposal Resent',
          last_resent: 'Last  Resent',
          settings_changed_count: 'Settings Changed Count',
          last_action: 'Last Action',
          csr_notes: 'Notes',
          is_call_made: 'Call Made Yes or No',
          is_voice_call_made: 'Voice Mail Yes or No',
          is_text_sent: 'Text Sent Yes or No',
          is_email_sent: 'Email Sent Yes or No',
          followup_date: 'Follow up date',
          assigned_to: 'Assigned to',
          contract_creation_id: 'Contract Creation',
          service_amount: 'Service Amount',
          downpayment_amount: 'Downpayment Amount',
          terms: 'Terms',
          services: 'Services'
        };
        if (is_debt_contract > 0) {
          list[0].debt_duration = 'Debt Duration';
          list[0].payoff_amount = 'Payoff Amount';
          list[0].contract_id = 'Payment/Contract ID';
        }
        if (show_proposal_link) {
          list[0]['proposal_link'] = 'Link';
        }
        for (let i = 0; i < requestsList.length; i++) {
          list[i + 1] = {};
          list[i + 1].patient_name = requestsList[i].patient_name ? requestsList[i].patient_name : '-';
          list[i + 1].patient_email = requestsList[i].patient_email ? requestsList[i].patient_email : '-';
          list[i + 1].patient_address = requestsList[i].patient_address ? requestsList[i].patient_address : '-';
          // if (ext == 0) {
          // tslint:disable-next-line: max-line-length
          list[i + 1].patient_mobile = requestsList[i].patient_mobile ? (requestsList[i].patient_mobile_country_code ? (ext == 0 ? `${requestsList[i].patient_mobile_country_code}-` : `(${requestsList[i].patient_mobile_country_code})-`) : '') + requestsList[i].patient_mobile : '-';
          // } else {
          //   list[i + 1].patient_mobile = requestsList[i].patient_mobile ? requestsList[i].patient_mobile.substring(3) : '-';
          // }
          list[i + 1].doctor_name = requestsList[i].practice_name ? requestsList[i].practice_name : (requestsList[i].doctor_name ? requestsList[i].doctor_name : '-');
          list[i + 1].doctor_email = requestsList[i].practice_email ? requestsList[i].practice_email : (requestsList[i].doctor_email ? requestsList[i].doctor_email : '-');
          list[i + 1].contract_type = requestsList[i].contract_type == 1 ? "10% Service Fee" : requestsList[i].contract_type == 3 ? "KeepeeFi" : requestsList[i].contract_type == 4 ? (requestsList[i].ez_plan_new ? 'ZeroFi Financing' : 'SimpleeFi Financing') : requestsList[i].contract_type == 6 ? 'SimpleeFi (With Deferred Interest)' : requestsList[i].contract_type == 5 ? (requestsList[i].payment_id ? 'Payoff' : 'Credee Bill Payment Plan') : 'CredeeFi';
          if (is_debt_contract > 0) {
            list[i + 1].current_status = requestsList[i].split_status_for_ar ? requestsList[i].split_status_for_ar : '-';
            list[i + 1].email_status = requestsList[i].proposal_status_for_ar ? requestsList[i].proposal_status_for_ar : '-';
            list[i + 1].contract_id = requestsList[i].contract_code ? requestsList[i].contract_code : (requestsList[i].payment_process_code ? requestsList[i].payment_process_code : '-');
            list[i + 1].debt_duration = requestsList[i].debt_duration || '-';
            list[i + 1].payoff_amount = requestsList[i].payoff_amount ? requestsList[i].payoff_amount.toFixed(2) : '-';
            // if (requestsList[i].proposal_status_details) {
            //   const proposal_status = requestsList[i].proposal_status_details.map(el => {
            //     // Check if date_sent needs conversion
            //     if (!el.date_sent.includes('T')) {
            //       el.date_sent = new Date(el.date_sent + ' UTC').toISOString();
            //     }

            //     // Format and convert date to timezone
            //     const formattedDate = momentTz(el.date_sent).tz(this.timezoneZone).format('MM/DD/YYYY (hh:mm a)');

            //     return {
            //       label: el.reminder_message,
            //       value: `${el.reminder_date_message} ${formattedDate}`
            //     };
            //   });
            //   list[i + 1].proposal_status_details = proposal_status;
            // } else {
            //   list[i + 1].proposal_status_details = '-';
            // }
          } else {
            list[i + 1].current_status = requestsList[i].split_status ? requestsList[i].split_status : '-';
            list[i + 1].email_status = requestsList[i].proposal_status ? requestsList[i].proposal_status : '-';
            list[i + 1].contract_id = requestsList[i].contract_code ? requestsList[i].contract_code : '-';
          }
          list[i + 1].is_sms_failed = (requestsList[i].is_sms_failed && requestsList[i].is_sms_failed > 0) ? 'Not Delivered' : 'Delivered';
          list[i + 1].stop_reminder_email = (requestsList[i].stop_reminder && (requestsList[i].stop_reminder == 1 || requestsList[i].stop_reminder == 3)) ? 'Off' : 'On';
          list[i + 1].stop_reminder_sms = (requestsList[i].stop_reminder && (requestsList[i].stop_reminder == 2 || requestsList[i].stop_reminder == 3)) ? 'Off' : 'On';
          list[i + 1].date_added = requestsList[i].date_added ? this.datePipe.transform(requestsList[i].date_added, 'MM/dd/yyyy', this.timezone) : '-';
          list[i + 1].start_date = (requestsList[i].start_date && requestsList[i].start_date != '0000-00-00 00:00:00') ? this.datePipe.transform(requestsList[i].start_date, 'MM/dd/yyyy', '+0000') : '-';
          list[i + 1].end_date = (requestsList[i].end_date && requestsList[i].end_date != '0000-00-00 00:00:00') ? this.datePipe.transform(requestsList[i].end_date, 'MM/dd/yyyy', '+0000') : '-';
          list[i + 1].plan_created_date = '-';
          if (requestsList[i].plan_created_date && requestsList[i].plan_created_date != '' && requestsList[i].plan_created_date != null) {
            list[i + 1].plan_created_date = this.datePipe.transform(requestsList[i].plan_created_date, 'MM/dd/yyyy', this.timezone);
          } else if (requestsList[i].ppr_date_added && requestsList[i].ppr_date_added != '' && requestsList[i].ppr_date_added != null) {
            list[i + 1].plan_created_date = this.datePipe.transform(requestsList[i].ppr_date_added, 'MM/dd/yyyy', this.timezone)
          }

          list[i + 1].proposal_type = requestsList[i].request_from ? 'Send Payment Link' : 'Send Proposal';
          list[i + 1].csr_notes = (requestsList[i].csr_notes && requestsList[i].csr_notes != '') ? requestsList[i].csr_notes : '-';
          list[i + 1].is_call_made = (requestsList[i].is_call_made && requestsList[i].is_call_made > 0) ? 'Yes' : 'No';
          list[i + 1].is_voice_call_made = (requestsList[i].is_voice_call_made && requestsList[i].is_voice_call_made > 0) ? 'Yes' : 'No';
          list[i + 1].is_text_sent = (requestsList[i].is_text_sent && requestsList[i].is_text_sent > 0) ? 'Yes' : 'No';
          list[i + 1].is_email_sent = (requestsList[i].is_email_sent && requestsList[i].is_email_sent > 0) ? 'Yes' : 'No';
          list[i + 1].followup_date = (requestsList[i].followup_date && requestsList[i].followup_date != '') ? this.datePipe.transform(requestsList[i].followup_date, 'MM/dd/yyyy', '+0000') : '-';
          list[i + 1].assigned_to = (requestsList[i].assigned_to && requestsList[i].assigned_to != '') ? requestsList[i].assigned_to : '-';

          list[i + 1].proposal_resent = requestsList[i].resend_email_count || '-';
          // tslint:disable-next-line:max-line-length
          list[i + 1].last_resent = requestsList[i].last_email_sent ? momentTz(requestsList[i].last_email_sent).tz(this.timezoneZone).format('MM/DD/YYYY hh:mm A') : '-';
          list[i + 1].settings_changed_count = requestsList[i].settings_changed_count || '-';
          list[i + 1].last_action = requestsList[i].last_action ? momentTz(requestsList[i].last_action).tz(this.timezoneZone).format('MM/DD/YYYY hh:mm A') : '-';
          // list[i + 1].proposal_status_details = requestsList[i].proposal_status_details ? JSON.stringify(requestsList[i].proposal_status_details) : '-';
          list[i + 1].contract_creation_id = requestsList[i].contract_id != 0 ? 'Contract Created' : 'No Contract Created';
          list[i + 1].service_amount = requestsList[i].service_amount || '-';
          list[i + 1].downpayment_amount = requestsList[i].downpayment_amount || '-';
          list[i + 1].terms = requestsList[i].terms || '-';
          list[i + 1].services = requestsList[i].services || '-';
          if (show_proposal_link) {
            list[i + 1].proposal_link = requestsList[i].contract_patient_token ? `${this.baseUrlCopyLink}${requestsList[i].contract_patient_token}` : '-';
          }
        }
        var excelFileName = 'split_requests';
        if (is_debt_contract > 0) {
          excelFileName = 'CredeeBill Proposal';
          list1 = [{
            'title': 'Row Labels',
            'value': 'Count'
          },
          {
            'title': 'Payment Completed',
            'value': data.total_completed
          },
          {
            'title': 'Plan Created',
            'value': data.total_plan_created
          },
          {
            'title': 'Plan Selected',
            'value': data.total_plan_selected
          },
          {
            'title': 'Link Opened',
            'value': data.total_link_opened
          },
          {
            'title': 'Request Sent',
            'value': data.total_request_sent
          },
          {
            'title': 'Grand Total',
            'value': data.ar_grand_total
          }
          ];
        } else {
          excelFileName = 'Credee Proposal Requests';
          list1 = [{
            'title': 'Row Labels',
            'value': 'Count'
          },
          {
            'title': 'Plan Created',
            'value': data.total_plan_created
          }, {
            'title': 'Plan Selected',
            'value': data.total_plan_selected
          },
          {
            'title': 'Link Opened',
            'value': data.total_link_opened
          },
          {
            'title': 'Request Sent',
            'value': data.total_request_sent
          },
          {
            'title': 'Rejected Request',
            'value': data.total_rejected
          },
          {
            'title': 'Grand Total',
            'value': data.pp_grand_total
          }
          ];
        }
        if (ext == 0) {
          // this._shared.exportAsExcelFile(list, list1, excelFileName, '.xlsx', { proposal_link: { type: 'hyperlink', width: 55 } });
        }
        else if (ext == 1) {
          // this._shared.exportAsExcelFile(list, list1, excelFileName, '.csv', { proposal_link: { type: 'hyperlink', width: 55 } });
        }
      }, (error: any) => {
        console.log(error);
      });
  }

  ViewPaymentSettings(split_req: any) {
    this.splitRequest = split_req;
    let params = {
      split_req_id: this.splitRequest.split_req_id,
    };
    this._api.api(params, 'finance/get_debt_recovery_proposal_details', 'get')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        this.selected_req = data.split_request_data[0];
        this.skip_overdue_check = data.split_request_data[0].skip_overdue_contract_check;
        if (this.selected_req.payment_matrix_array) {
          this.selected_req.payment_matrix_array = JSON.parse(this.selected_req.payment_matrix_array);
          this.selected_req['payment_matrix_array']['backup_required'] = this.selected_req['payment_matrix_array']['backup_required'] == 1 ? true : false;
        }
        if (this.selected_req) {
          console.log(this.selected_req['payment_matrix_array']);
          const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
            initialState: {
              modalType: this._debtRecovery.modalTypes.PaymentSettingsModal,
              payment_extra_data: {
                label: 'Proposal Status',
                data: data.split_request_data,
                // view_track_status: this.view_track_status
              },
            },
            class: 'modal-dialog-centered',
          });
          modelRef.content?.passEntry.subscribe((inputValue) => {
            return true;
          });
          // modal.show();
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  stop_resume_reminder(eve: any, split_id: any, stop_to: any) {
    let is_stop = eve.target.checked ? 0 : 1
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to ${is_stop == 1 ? 'stop' : 'Resume'} this proposal reminder ${stop_to == 1 ? 'Email' : 'SMS'} ?`,
          // hideNotes: true,
          modal_heading: 'Confirmation',
          // data: [{ patient_email: this.splitRequest.patient_email, patient_mobile: this.splitRequest.patient_mobile}]
        },
      },
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      // inputValue = inputValue && inputValue.trim();
      if (inputValue === '' || inputValue.length == 0) {
        // swal.showInputError(' You need to write something! ');
        return false;
      }
      if (inputValue && inputValue.success) {
        // swal.close();
        let data = {
          split_req_id: split_id,
          notes: inputValue.notes,
          stop_reminder: is_stop,
          stop_email: stop_to == 1 ? 1 : null,
          stop_sms: stop_to == 2 ? 1 : null
        };
        this._api.api(data, 'business/stop_resume_proposal_reminder')
          .subscribe((data: any) => {
            this.getSplitAppList();
          }, (error: any) => {
            console.log(error);
          })
        return true;
      } else {
        let temp = this.splitAppList;
        this.splitAppList = '';
        setTimeout(() => {
          this.splitAppList = temp;
        }, 0);
        return true;
      }
    });
  }

  copyLink(data: any) {
    if (this.baseUrlCopyLink) {
      const textToCopy = `${this.baseUrlCopyLink}${data.contract_patient_token}`;
      const isTextCopied = this._main.copyToClipboard(textToCopy);
      // tslint:disable-next-line:max-line-length
      isTextCopied ? this._shared.customPopups('Link copied to clipboard', 0) : this._shared.customPopups('Unable to copy to clipboard', 1);
      return;
    }
  }

  updateSelection(index: number, type = 'single') {
    if (type === 'all') {
      this.allSelected = !this.allSelected;
      if (this.splitAppList && this.splitAppList.length) {
        this.splitAppList.forEach((item: any) => {
          if (item.is_expired == 0 && item.contract_current_status === 'Proposal Sent') {
            item['is_selected'] = this.allSelected;
          }
        });
      }
      // this.allSelected = this.splitAppList.every(item => item.is_selected);
      this.isAnySelected = this.splitAppList.some((item: any) => item.is_selected);
      return;
    } else {
      this.splitAppList[index]['is_selected'] = this.splitAppList[index]['is_selected'];
      setTimeout(() => {
        this.allSelected = this.splitAppList.every((item: any) => item.is_selected);
        this.isAnySelected = this.splitAppList.some((item: any) => item.is_selected);
      }, 0);
    }
  }

  sendProposalToAll() {
    this.sendAll = true;
    this.openPlansDetail(0);
  }

  getAllProposalIds() {
    return new Promise((resolve, reject) => {
      let params: any = {
        offset: (this.resData.pageNumber - 1) * 10,
        limit: 10,
        is_dummy: this.is_dummy,
        search_string: this.search,
        status_filter: this.filter.status_filter,
        sort_by: this.sortValue.by,
        sort_order: this.sortValue.order,
        min_date_added: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
        max_date_added: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
        country_id: this.country_id,
        is_debt_contract: window.location.pathname.includes('/app/debt-recovery/list') ? 0 : 1,
        ProposalType: this.filter.ProposalType,
        last_resent: this.filter.last_resent,
        reminder: this.filter.reminder,
        send_all: 1
      };
      if (this.search && this.search.length > 0) {
        params['search_string'] = this.search;
      } else {
        delete params.search_string;
      }
      this._api.api(params, "finance/get_proposal_list", 'get').subscribe((send_proposal: any) => {
        if (send_proposal && send_proposal.split_req_id_all && send_proposal.split_req_id_all.length) {
          this.split_req_ids = send_proposal.split_req_id_all;
          resolve(this.split_req_ids);
        }
      });
    });
  }

  updateSelectionStatus() {
    this.isAnySelected = this.splitAppList.some((item: any) => item.is_selected);
    this.allSelected = this.splitAppList.every((item: any) => item.is_selected);
  }

}
