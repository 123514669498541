import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SharedService } from '../../shared.service';
import { ApiService } from '../../api.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  term: string = '';
  searched_string = '';
  subscription: any;
  emptyData!: boolean;
  results: any = {
    partners: [],
    patients: [],
    doctors: [],
    contracts: [],
    payments: [],
    ar: [],
    proposal: [],
    pa_leads: [],
  };
  show: any = {
    doctor: true,
    contract: true,
    patient: true,
    partner: false,
    payments: true,
    ar: true,
    proposal: true,
    pa_leads: false,
  };

  constructor(
    private route: ActivatedRoute,
    public location: Location,
    public router: Router,
    private _shared: SharedService,
    private _api: ApiService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((data: any) => {
      console.log(data.search)
      this.term = data.search;
      this.handleSearch();
    });
  }

  search(){
    this.router.navigate(['/app/search'], {queryParams: {search: this.term}})
  }

  handleSearch() {
    if (!this.term) {
      this._shared.customPopups('Enter a value to search', 2);
      return;
    }
    let data = {
      search_string: this.term
    };
    this._api.api(data, 'dashboard/search', 'get')
      .subscribe((res: any) => {
        console.log(res);
        this.results.partners = res.partner_list;
        this.results.doctors = res.doctor_list;
        this.results.patients = res.patient_list;
        this.results.contracts = res.pf_payments;
        this.results.payments = res.payments_list;
        this.results.ar = res.ar_list;
        this.results.proposal = res.proposal_list;
        this.results.pa_leads = res.pa_leads;
        if (
          this.results &&
          (!this.results.partners || !this.results.partners.length) &&
          (!this.results.doctors || !this.results.doctors.length) &&
          (!this.results.patients || !this.results.patients.length) &&
          (!this.results.contracts || !this.results.contracts.length) &&
          (!this.results.payments || !this.results.payments.length) &&
          (!this.results.ar || !this.results.ar.length) &&
          (!this.results.proposal || !this.results.proposal.length) &&
          (!this.results.pa_leads || !this.results.pa_leads.length)
        ) {
          this.emptyData = true;
          this._shared.customPopups('No results found!', 1);
        } else {
          this.searched_string = this.term;
          this.emptyData = false;
        }
      });
  }
  viewPartner(partner_id: any) {
    // MainService.customSetItem("partnerList", partner_id);
    this.router.navigate(['app', 'partners', 'profile', partner_id]);
  }
}
