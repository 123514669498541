import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculation-details',
  // standalone: true,
  // imports: [],
  templateUrl: './calculation-details.component.html',
  styleUrl: './calculation-details.component.scss'
})
export class CalculationDetailsComponent implements OnInit {
  @Input() content: any;
  calculation_details: any;
  constructor() { }

  ngOnInit(): void {
    this.calculation_details = this.content;
  }
}
