import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { PasswordStrengthService, PasswordSpaceValidator } from 'src/app/components/shared/password-strength.service';
import { passwordValidator, SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit{
  public newPasswordForm: UntypedFormGroup;
  passwordStrength = 'Empty';
  validator: number[] = [];
  minLength : boolean = false;
  hasUpperCase: boolean = false;
  hasMaxChar: boolean = false;
  hasNumber : boolean = false;
  hasSpecialChar : boolean = false;
  hidePwd = false;
  hideConfirmPwd = false;
  showPassTooltip  = false
  totalStrength: number = 0;
  constructor(private _fb: UntypedFormBuilder, private _api: ApiService, private _local: localStorageService, public _shared: SharedService, private _router: Router, private main: MainService,private passwordStrengthService: PasswordStrengthService) {
    this.newPasswordForm = this._fb.group({
      new_password: ['', [Validators.required, Validators.maxLength(50), passwordValidator(), PasswordSpaceValidator.cannotContainSpace]],
      confirm_password: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    console.log('in');
    this.newPasswordForm.get('new_password')?.valueChanges.subscribe(password => {
      console.log('New Password:', password);
      // Contains uppercase letters (A-Z)
    if ((/[A-Z]/.test(password)) && (/[a-z]/.test(password))) {
      this.hasUpperCase = true;
    }else{
      this.hasUpperCase = false;
    }

    // Contains numbers (0-9)
    if (/[0-9]/.test(password)) {
      this.hasNumber = true;
    }else{
      this.hasNumber = false;
    }

    // Contains special characters (!@#$%^&*)
    if (/[!@#$%^&*]/.test(password)) {
      this.hasSpecialChar = true;
    }else{
        this.hasSpecialChar = false;
      }

    if (password.length >= 6 ) {
      this.hasMaxChar = true;
    }else{
      this.hasMaxChar = false;
    }
    this.totalStrength = (Number(this.hasUpperCase) + Number(this.hasNumber) + Number(this.hasSpecialChar) + Number(this.hasMaxChar))
      this.passwordStrength = this.passwordStrengthService.calculatePasswordStrength(password);
    });
    
    console.log(this.totalStrength)
  }

  submit() {
    // if (this.newPasswordForm.invalid) {
    //   this.newPasswordForm.markAsDirty();
    //   return;
    // }

    if (this.newPasswordForm.value.new_password != this.newPasswordForm.value.confirm_password) {
      this._shared.customPopups('New password and Confirm password should be same', 1);
      return
    }
    if (this.newPasswordForm.controls['new_password'].errors?.['cannotContainSpace'] ){
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return
    }
    if (this.newPasswordForm.controls['new_password'].value != this.newPasswordForm.controls['confirm_password'].value) {
      this._shared.customPopups('Passwords did not match', 2);
      return;
    }

    if (this.newPasswordForm.controls['new_password'].value.length < 6) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[A-Z]/.test(this.newPasswordForm.controls['new_password'].value)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[a-z]/.test(this.newPasswordForm.controls['new_password'].value)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[0-9]/.test(this.newPasswordForm.controls['new_password'].value)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }
    
    if (!/[!@#$%^&*]/.test(this.newPasswordForm.controls['new_password'].value)) {
      this._shared.customPopups('Password must contain at least 6 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character. Spaces are not allowed.', 2);
      return;
    }

    const data = {
      reset_password_token: this._local.customGetItem('reset_password_token'),
      admin_email: this._local.customGetItem('reset_email').replace(/\s/g, '').toLowerCase(),
      new_password: this._shared.crypto(this.newPasswordForm.value.new_password),
    }

    this._api.api(data, 'auth/change_password').subscribe(response => {
        if (!response.is_error) {
          this._local.customClearAllItems();
          this._local.customSetItem('token', response.access_token);
          this._local.customSetItem('admin_profile', response.admin_profile ? response.admin_profile[0] : {});
          this.main.fetchAdminList();
          if (response.menu && response.menu.length) {
            this.main.menu$.next(response.menu);
          }
          this._shared.dashboardMenuExist(response.menu).then(res => {
            if (res) {
              this._router.navigate(['app', 'dashboard']);
            } else {
              this._router.navigate([response.menu[0]['link']]);
            }
          })
        }
    })
  }

}
