<h3 id="list" class="m-0 fs-24 fw-700 text-secondary mb-3">Manage Contracts</h3>
<div class="mb-2 border-bottom">
  <button class="tab fs-14" [class.active]="contractsType==0" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:0, page: 0}" queryParamsHandling="merge">Today's Contracts ({{ counts && counts.todays_contract
    ?
    counts.todays_contract.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==1" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:1,page: 0}" queryParamsHandling="merge">All Contracts ({{ counts && counts.all_contracts ?
    counts.all_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==2" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:2, page: 0}" queryParamsHandling="merge">Active ({{ counts && counts.active_contracts ?
    counts.active_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==3" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:3, page: 0}" queryParamsHandling="merge">Overdue ({{ counts && counts.overdue_contracts ?
    counts.overdue_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==4" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:4, page: 0}" queryParamsHandling="merge">Completed ({{ counts && counts.completed_contracts ?
    counts.completed_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==5" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:5, page: 0}" queryParamsHandling="merge">Canceled ({{ counts && counts.cancelled_contracts ?
    counts.cancelled_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==6" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:6, page: 0}" queryParamsHandling="merge">Legal ({{ counts && counts.legal_contracts ?
    counts.legal_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==7" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:7, page: 0}" queryParamsHandling="merge">Disputed Treatments ({{ counts &&
    counts.disputed_treatments_contracts ? counts.disputed_treatments_contracts.count : 0 }})</button>
  <button class="tab fs-14" [class.active]="contractsType==8" [routerLink]="'/app/contracts/list'"
    [queryParams]="{type:8, page: 0}" queryParamsHandling="merge">Bankruptcy ({{ counts && counts.bankruptcy_contracts ?
    counts.bankruptcy_contracts.count : 0 }})</button>
</div>