import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/components/shared/main.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { Location, DatePipe } from '@angular/common';
import { SharedService } from 'src/app/components/shared/shared.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DebtRecoveryModalComponent } from '../debt-recovery-modal/debt-recovery-modal.component';
import { DebtRecoveryService } from '../debt-recovery.service';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { AddNotesComponent } from 'src/app/components/shared/add-notes/add-notes.component';


@Component({
  selector: 'app-debt-recovery-payment-component',
  templateUrl: './debt-recovery-payment-component.component.html',
  styleUrls: ['./debt-recovery-payment-component.component.scss']
})
export class DebtRecoveryPaymentComponentComponent {
  previousUrl: any;
  paymentProcessNotes :any;
  id: string | null | undefined;
  paymentProcessInfo: any;
  balance_loader: boolean | undefined;
  doctor_balance :any;
  timezone : any;  
	showNewNotes: boolean = true;
  tottal_payable_by_customer:any;
  allNoteList: any;
  fm_balance:any;
  constructor(private route: ActivatedRoute,
    private _api: ApiService,
    public mainService: MainService,
    public _shared : SharedService,
    public location: Location,
    public modalService: BsModalService,    
    public _debtRecovery: DebtRecoveryService,    
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.timezone = this._shared.getTimezone();
    this.getAccountsReceivableInfo(this.id);
  }
  getAccountsReceivableInfo(id: any) {
    let data = {
      id: id
    };
    this._api.api(data, '/finance/get_payment_process_data', 'post').subscribe((data: any) => {

      let note_array = [];
      this.paymentProcessInfo = data.result;
      this.fm_balance = data.fm_balance
      note_array = [...data.pf_notes, ...data.csr_notes, ...data.ach_notes];

      // this.allNoteList = _.sortBy(note_array, function (node) {
      //   return - (new Date(node.date_added).getTime());
      // });
      // let contactTypeMap = new Map<number, any>(this.contact_types.map((type: any): [number, any] => [type.value, type]));
      // this.allNoteList.forEach((note: any) => {
      //   let contactType = contactTypeMap.get(note.contact_type);
      //   if (contactType) {
      //     note.contact_type_value = contactType;
      //     note.contact_result_value = contactType.contact_results.find((result: any) => note.contact_result === result.value);
      //   }
      // });			
      this.paymentProcessNotes = note_array

      if (typeof this.paymentProcessInfo.contract_service_record === 'string') {
        this.paymentProcessInfo.contract_service_record = JSON.parse(this.paymentProcessInfo.contract_service_record);
      }
      this.tottal_payable_by_customer = this.paymentProcessInfo.treatment_amount_transaction_fee + this.paymentProcessInfo.treatment_amount

      console.log(this.paymentProcessInfo);


      // this.getDoctorBalance(this.paymentProcessInfo.doctor_id);

    }, (error: any) => {
      console.log(error);
    })
  }

  getDoctorBalance(id: any) {
    let data = {
      // access_token: localStorage.getItem('token'),
      doctor_id: id
    };
    this._api.api(data, '/finance/fetch_doctor_balance', 'get').subscribe((data: any) => {
      this.balance_loader = false;

      this.doctor_balance = data.doctor_balance;


    }, (error: any) => {
      this.balance_loader = false;
      console.log(error);
    })
  }

  deletePaymentModal() {
    const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
      initialState: {  
        modalType: this._debtRecovery.modalTypes.deleteProposalmodal,     
        extra_data:{
          paymentProcessInfo:this.id
        }
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.router.navigate(['/app/debt-recovery/list']);
      }
    })
	}

  

  resendLinkModalOpen(){
    const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
      initialState: {
        modalType: this._debtRecovery.modalTypes.resendLinkModal,
        extra_data: {
          paymentProcessInfo: this.paymentProcessInfo ,
        },
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getAccountsReceivableInfo(this.id);
      }
    })
  }
  sendReceiptLinkModalOpen(){
    const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
      initialState: {
        modalType: this._debtRecovery.modalTypes.sendReceiptLinkModal,
        extra_data: {
          paymentProcessInfo: this.paymentProcessInfo ,
        },
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {       
        this.getAccountsReceivableInfo(this.id);
        this._shared.customPopups("successfully send link",3)
      }
    })
  }

  refundPaymentModal() {
    if (parseFloat(this.paymentProcessInfo.treatment_amount + this.paymentProcessInfo.treatment_amount_transaction_fee) > parseFloat(this.fm_balance)) {
			this._shared.customPopups( 'You do not have sufficient balance to refund the payment.', 1);
			return;
		}
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Please confirm how you want to process this refund:`,
          hideNotes : true,
          data:  this.paymentProcessInfo,
          refundType:true
        },
      },
      class: 'modal-dialog-centered',
    });

    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        let data = {
          split_req_id : this.paymentProcessInfo.split_req_id,
          contract_patient_token : this.paymentProcessInfo.contract_patient_token,
          with_transaction_fee: response.with_transaction_fee
        }
        this._api.api(data, 'finance/payment_process_reverse_transfer').subscribe({
          next : (data) => {    
            if (data.original_text) {
              this._shared.customPopups(data.original_text, 1);
            } else{
              this.getAccountsReceivableInfo(this.id);
            }
            
          },
          error : (data) => {
            
          }
        }) 
      }
    })
	}

  // plansModal(){
  //   const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
  //     initialState: {
  //       modalType: this._debtRecovery.modalTypes.viewPlans,
  //       extra_data: {
  //         paymentProcessInfo: this.paymentProcessInfo ,
  //       },
  //     },
  //     class: 'modal-dialog-centered',
  //   });

  //   modelRef.content?.passEntry.subscribe(response => {
  //     if (response.success) {
  //       this.getAccountsReceivableInfo(this.id);
  //     }
  //   })
  // }
  addContractNotesModal() {
  
    this.paymentProcessInfo.assign_type = 5;
    let initialState = {
      data: this.paymentProcessInfo,
    };
    this.mainService.noteadded = false;
    const bsModalRef = this.modalService.show(AddNotesComponent, {
      initialState,
      class: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true,
    });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.mainService.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getAccountsReceivableInfo(this.id);
          count = 1;
        }
      }
    });
  }

  onImageError(event: any) {
    
    event.target.src =' assets/images/png/attachment.png';
  }
  
}
