import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { localStorageService } from './localstorage.service';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  headers: any = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  });

  constructor(
    public http: HttpClient,
    private _shared: SharedService,
    private _local: localStorageService,
    private _api: ApiService
  ) {}

  modalTypes = {
    profileForm: 'profileForm',
    addNewAddressForm: 'addNewAddressForm',
    appDisappForm: 'appDisappForm',
    updateEmailForm: 'updateEmailForm',
    addCard: 'addCard',
    verifyBankForm: 'verifyBankForm',
    appAchForm: 'appAchForm',
    updateBankForm: 'updateBankForm',
    addBankLinkForm: 'addBankLinkForm',
    verifyBankLinkForm: 'verifyBankLinkForm',
    makeDefaultAddressForm: 'makeDefaultAddressForm',
    addAlternateEmailForm: 'addAlternateEmailForm',
    addAlternatePhoneForm: 'addAlternatePhoneForm',
    bankLinkForm: 'bankLinkForm',
    appContractForm: 'appContractForm',
    changePaymentSettingsForm: 'changePaymentSettingsForm',
    reAuthEmailForm: 'reAuthEmailForm',
    fullSSNForm: 'fullSSNForm',
    customerDetails: 'customerDetails',
    approval_code: 'approval_code',
  };

  api(data: any, url: any, flag: any) {
    let api_url = this._local.customGetItem('LiveAdminBaseUrl');
    if (!api_url) {
      api_url = environment.baseUrl;
    }
    return this.http
      .post<any>(`${api_url}${url}`, this._shared.urlParams(data), {
        headers: this.headers,
      })
      .pipe(
        map((data: any) => {
          if (data.flag == 4) {
            if (data && data.override_text) {
              this._shared.customPopups(data.override_text, 1);
            }
            this._api.loginRedirect();
            return false;
          }
          if (data && flag) {
            this._shared.customPopups(data.is_error, data.flag);
          }
          return data;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  updatePatientProfile(data: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data;' });
    let api_url = this._local.customGetItem('LiveAdminBaseUrl');
    if (!api_url) {
      api_url = environment.baseUrl;
    }
    return this.http
      .post<any>(`${api_url}edit_patient_profile_picture`, data)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
