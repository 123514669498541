import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/components/shared/api.service';
import { EditContractModalComponent } from 'src/app/components/shared/edit-contract-modal/edit-contract-modal.component';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { Filters } from 'src/app/components/shared/interface.component';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ContractService } from '../contracts.service';
@Component({
  selector: 'app-contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrl: './contracts-list.component.scss'
})
export class ContractsListComponent implements OnDestroy, OnInit {
  minDate: Date = new Date(2024, 0, 1);
  maxDate: Date = new Date();
  dateRangeValue: any;
  contracts: any;
  public contractsType: number = this._contract.defaultContractType;
  dataArguements: any = { limit: 10, offset: 0, search_string: "" };
  industry: any = [];
  industryselect: any;
  subindustry: any;
  subindustryselect: any;
  searched: boolean = false;
  sort: any = 1;
  sortValue: { by: string; order: string; label: string } = { by: "date_added", order: "DESC", label: "Registration Date" };
  resData: any = { pageNumber: 1, totalRecords: 0 };
  startDate: any;
  endDate: any;
  filterEnabled: boolean = true;
  timezone: any = '';
  timezoneName: any = '';
  pf_status_filter: any = [];
  subscription: any;
  subscription1: any;
  timezone_subscription: any;
  filters: any;
  extra_filter: any = [];
  industry_filter: any = [];
  speciality_filter: any = [];
  loader: boolean = false;
  dropdownList: any = [];
  selectedItems: any = [];
  selectedIndustries: any = [];
  counts: any;
  statusList: any = [];
  selectedTab: any = [];
  tabNow: number = 0;
  selectedType: any = 0;
  afterTabChange = true;
  afterResetButton = true;
  show_filters: boolean = false;
  public allContractFilters: Array<Filters> = [];
  current_admin = {};
  generateLinkDetails: any = {
    selectedContract: {},
    addCardUrl: '',
    email: ''
  }

  @Input() range: any;
  country_subscription: any;
  todays_max_date = new Date();
  todays_min_date = new Date(this._shared.getMaxDate(this.timezone));

  @ViewChild(ModalDirective, { static: false }) exportContractsModal!: ModalDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public changeDetect: ChangeDetectorRef,
    private _local: localStorageService,
    private _api: ApiService,
    public _shared: SharedService,
    private modalService: BsModalService,
    private filterService: FiltersService,
    private _contract: ContractService

  ) {
    this.current_admin = this._local.customGetItem('profileData') && JSON.parse(this._local.customGetItem('profileData')) ? JSON.parse(this._local.customGetItem('profileData'))[0] : {};
  }

  ngOnInit() {
    if (!window.location.href.includes('type')) {
      this.router.navigate([], { queryParams: { type: this.contractsType }, queryParamsHandling: 'merge' });
    }

    this.timezoneName = this._shared.getTimezoneName();
    this.timezone = this._shared.getTimezone();
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.todays_max_date = new Date();
    this.todays_min_date = new Date(this._shared.getMaxDate(this.timezone));
    setTimeout(() => {
      this.allContractFilters = this.filterService.allContractFiltersArray();
      this.dateRangeValue = [this.minDate, new Date(this._shared.getMaxDate(this.timezone))];
      this.allContractFilters.forEach(element => {
        if (element.control == "industryFilter") {
          let industryFilterValue: any = [];
          this.industry.forEach((element: any) => {
            industryFilterValue.push({
              label: element.ind_name,
              value: element.ind_id
            })
          });
          element.options = industryFilterValue;
        }
      });
    }, 2000)
    this.displayDateRange(this.dateRangeValue);
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];

    this.statusList = [
      { idValue: "1", statusValue: "Active" },
      { idValue: "2", statusValue: "Overdue" },
      { idValue: "3", statusValue: "Canceled" },
      { idValue: "4", statusValue: "Completed" },
      { idValue: "5", statusValue: "Legal" },
      { idValue: "6", statusValue: "Disputed Treatment" },
      { idValue: "7", statusValue: "Bankruptcy" }
    ];

    this.dropdownList = [
      { idValue: "1", filterValue: "Customers Above 70 Years" },
      { idValue: "2", filterValue: "Finanaced Amount upto $3000 for more than 24 months" },
      { idValue: "3", filterValue: "Enrollment Fees not deducted" },
      { idValue: "4", filterValue: "Protected Payments" },
      { idValue: "5", filterValue: "Non-Protected Payments" },
      { idValue: "6", filterValue: "Do not disturb" },
      { idValue: "9", filterValue: "CredeeFi Financing" },
      { idValue: "11", filterValue: "Verification Pending" },
      { idValue: "12", filterValue: "Verification Completed" },
      { idValue: "13", filterValue: "Customer without personal Info" }
    ];

    this.subscription1 = this.route.queryParams.subscribe((data: any) => {
      if (data && Object.keys(data) && Object.keys(data).length) {
        if (data.type && this.tabNow != data.type) {
          this.tabNow = data.type
          this.allContractFilters = this.filterService.allContractFiltersArray();
        }

        if (data.type == 3) { this.sort = 8 };
        if (data.type == 4) { this.sort = 9 };
        if (data.sort > 0) {
          this.sort = data.sort;
          this.sortValues(data.sort);
        }
        if (data.type == "2" || data.type == "5") {
          let index7 = this.dropdownList.findIndex((x: any) => x.idValue == '7');
          if (index7 == -1) {
            this.dropdownList.push({ idValue: "7", filterValue: "Customers who missed a payment less than $75" });
          }
        } else {
          this.dropdownList.forEach((item: any, index: any) => {
            if (item.idValue == "7") {
              this.dropdownList.splice(index, 1);
            }
          })
        }
        if (data && data.statusFilter && data.statusFilter.includes("3")) {
          if (this.dropdownList.filter((item: any) => item.idValue == "10").length == 0) {
            this.dropdownList.push({ idValue: "10", filterValue: "Contracts has reserved payment" });
          }
        } else {
          this.dropdownList = this.dropdownList.filter((item: any) => item.idValue != "10");
        }
        let timezone = this._shared.getTimezoneName() ? this._shared.getTimezoneName() : 'PST';
        this.dateRangeValue[0] = data.sD ? new Date(new Date(data.sD).toUTCString().substr(0, 25)) : this.minDate;
        this.dateRangeValue[1] = data.eD ? new Date(new Date(data.eD).toUTCString().substr(0, 25)) : this.maxDate;
        this.afterTabChange = false;

        setTimeout(() => {
          this.afterTabChange = true;
        }, 0);
        this.afterResetButton = false;
        this.selectedTab = data.statusFilter;
        this.selectedItems = data.extraFilter;
        this.industryselect = data.industryFilter;
        this.subindustryselect = data.subIndustryFilter;
        this.contractsType = data.type && Number(data.type) ? data.type : this.contractsType;
        this.pf_status_filter = data.statusFilter;
        this.extra_filter = data.extraFilter;
        this.industry_filter = data.industryFilter;
        this.speciality_filter = data.subIndustryFilter;
        this.resData.pageNumber = data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
        this.dataArguements.offset = this.resData.pageNumber ? (this.resData.pageNumber - 1) * 10 : 0;
        this.dataArguements.search_string = data.search;
        this.selectedType = data.type;
        this.getContracts();
        this.getContractsCount();
      }
    });

  }

  ngOnDestroy(): void {
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }

    if (this.country_subscription) {
      this.country_subscription.unsubscribe();
    }
  }

  sortValues(value: any) {
    this.sort = parseInt(value);
    switch (this.sort) {
      case 1:
        this.sortValue = { by: "date_added", order: "DESC", label: "Registration Date" };
        break;
      case 2:
        this.sortValue = { by: "patient_name", order: "ASC", label: "Customer Name A-Z" };
        break;
      case 3:
        this.sortValue = { by: "patient_name", order: "DESC", label: "Customer Name Z-A" };
        break;
      case 4:
        this.sortValue = { by: "doctor_name", order: "ASC", label: "Business Owner Name A-Z" };
        break;
      case 5:
        this.sortValue = { by: "doctor_name", order: "DESC", label: "Business Owner Name Z-A" };
        break;
      case 6:
        this.sortValue = { by: "cancelled_date", order: "DESC", label: "Cancelation Date" };
        break;
      case 7:
        this.sortValue = { by: "cancelled_date", order: "DESC", label: "Completion Date" };
        break;
      default:
        this.sortValue = { by: "date_added", order: "DESC", label: "Registration Date" };
        break;
    }
  }

  displayDateRange(range?: any, reset?: any) {
    this.dateRangeValue = range;
    this.startDate = this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd");
    this.resData.pageNumber = 1;
    this.router.navigate(["/app/contracts/list"], {
      queryParams: { sD: this.startDate, eD: this.endDate, page: this.resData.pageNumber },
      queryParamsHandling: "merge"
    });
  }

  getContracts(industry_list?: any) {
    this.route.queryParams.subscribe((data: any) => {
      if (data.type) {
        this.contractsType = data.type;
      }
    });
    let data = {
      access_token: this._local.customGetItem("adminToken"),
      limit: this.dataArguements.limit,
      offset: this.dataArguements.offset,
      pf_status:
        this.pf_status_filter && this.pf_status_filter.length > 0
          ? this.pf_status_filter
          : this.contractsType
            ? this.contractsType
            : 0,
      extra_filter: this.extra_filter || 0,
      search_string: this.dataArguements.search_string,
      sort_by: this.sortValue.by,
      sort_order: this.sortValue.order,
      start_date: this.contractsType != this._contract.defaultContractType ? this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd") : this.datePipe.transform(this.todays_min_date, "yyyy-MM-dd"),
      end_date: this.contractsType != this._contract.defaultContractType ? this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd") : this.datePipe.transform(this.todays_max_date, "yyyy-MM-dd"),
      industry_filter: JSON.stringify(this.industry_filter),
      speciality_filter: JSON.stringify(this.speciality_filter),
    };
    if (this.dataArguements.search_string && this.dataArguements.search_string.length > 0) {
      data["search_string"] = this.dataArguements.search_string.trim();
      this.searched = true;
    } else {
      delete data["search_string"];
    }
    this.contracts = [];
    this._api.api(data, "finance/get_contract_list", 'get').subscribe(
      (data: any) => {
        this.contracts = data.pf_payments;
        if (this.contracts) {
          this.contracts.forEach((o: { doctor_dnd: number; dnd_doctor_email: string; doctor_email: string; dnd: number; dnd_patient_email: string; patient_email: string; }) => {
            if (o.doctor_dnd == 1) {
              o.dnd_doctor_email = o.doctor_email ? (o.doctor_email.slice(0, 1) + "****************" + o.doctor_email.slice(-1)) : "N.A.";
            }
            if (o.dnd == 1) {
              o.dnd_patient_email = o.patient_email ? (o.patient_email.slice(0, 1) + "****************" + o.patient_email.slice(-1)) : "N.A.";
            }
          });
        }
        this.resData.totalRecords = data.number_of_records;
        this.resData.number_of_pages = data.number_of_pages;
        this.industry = this._shared.getIndustryList();
        if (this.industry && this.industry.length && !industry_list) {
          this.industry.forEach((item: any, index: any) => {
            item.ind_id = item.ind_id.toString();
          });
          for (let i = 0; i < this.allContractFilters.length; i++) {
            const element = this.allContractFilters[i];
            if (element.control == "industryFilter") {
              let industryFilterValue: { label: any; value: any; }[] = []
              this.industry.forEach((element: { ind_name: any; ind_id: any; }) => {
                industryFilterValue.push({
                  label: element.ind_name,
                  value: element.ind_id
                })
              });
              this.allContractFilters[i].options = industryFilterValue;
            }
          }
        }
      }
    );
  }

  makeLegal(contract: any) {


    // swal({
    //   title: 'Confirmation',
    //   text: `Are you sure you want to switch the status of this contract to Legal ?`,
    //   type: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#5d9cec',
    //   confirmButtonText: `Confirm`,
    //   cancelButtonText: 'Cancel',
    //   closeOnConfirm: true,
    //   closeOnCancel: true
    // }, (isConfirm: any) => {
    //   if (isConfirm) {
    //     let data = {
    //       access_token: this._local.customGetItem('adminToken'),
    //       pf_id: contract.pf_id,
    //       legal_status: 1
    //     };
    //     this._api.api(data, 'change_pf_legal_status')
    //       .subscribe((data: any) => {
    //         this.getContracts();
    //         this.getContractsCount()
    //       }, (error: any) => {
    //         console.log(error);

    //       })
    //   }
    // });
  }

  makeOverdue(contract: any) {
    // swal({
    //   title: 'Confirmation',
    //   text: `Are you sure you want to switch the status of this contract to Overdue ?`,
    //   type: 'input',
    //   showCancelButton: true,
    //   confirmButtonColor: '#5d9cec',
    //   confirmButtonText: `Confirm`,
    //   cancelButtonText: 'Cancel',
    //   closeOnConfirm: false,
    //   closeOnCancel: true
    // }, (inputValue: string) => {
    //   inputValue = inputValue && inputValue.trim();
    //   if (inputValue === '') {
    //     // swal.showInputError(' You need to write something! ');
    //     // return false;
    //   }
    //   if (inputValue) {
    //     const data = {
    //       access_token: this._local.customGetItem('adminToken'),
    //       pf_id: contract.pf_id,
    //       legal_status: 0,
    //       cd_payment_type: 24,
    //       name: this._local.customGetItem('admin_name'),
    //       notes: inputValue,
    //     };
    //     swal.close();
    //     this._api.api(data, 'change_pf_legal_status')
    //       .subscribe((data: any) => {
    //         this.getContracts();
    //         this.getContractsCount()  
    //       }, (error: any) => {
    //         console.log(error);

    //       });
    //   }
    // });
  }

  generateLink(contract: any, modal: any) {
    this.generateLinkDetails.selectedContract = contract;
    this.generateLinkDetails.email = contract.patient_email
    let data = {
      contract_id: contract.contract_id
    };
    this._api.api(data, 'finance/generate_link', 'get')
      .subscribe((data: any) => {
        console.log(data);
        this.generateLinkDetails.addCardUrl = data.link ? data.link : '';
        modal.show();
      }, (error: any) => {
        console.log(error);
      })
  }

  getContractsCount() {
    let data = {
      access_token: this._local.customGetItem("adminToken"),
      start_date: this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd"),
      end_date: this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd")
    }
    this._api.api(data, "finance/get_contracts_count", 'get').subscribe(
      (data: any) => {
        this.counts = data.data;
      }
    );

  }
  OpenEditContractPopup(contract: any) {
    let initialState = {
      contract_details: contract
    }
    this.modalService.show(EditContractModalComponent, { initialState, class: 'modal-lg modal-dialog-centered', ignoreBackdropClick: true });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (reason === 'true') {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getContracts();
        }
      }
    })
  }

  getRecentContracts() {
    let data = {
      access_token: this._local.customGetItem("adminToken"),
      limit: this.dataArguements.limit,
      offset: this.dataArguements.offset
    };
    this.loader = true;
    this.contracts = [];
    this._api.api(data, "get_recent_contracts_list").subscribe(
      (data: any) => {
        this.loader = false;
        this.contracts = data.pf_payments;
        if (this.contracts) {
          this.contracts.forEach((contract: any) => {
            if (contract.doctor_dnd == 1) {
              contract.dnd_doctor_email = contract.doctor_email ? (contract.doctor_email.slice(0, 1) + "****************" + contract.doctor_email.slice(-1)) : "N.A.";
            }
            if (contract.dnd == 1) {
              contract.dnd_patient_email = contract.patient_email ? (contract.patient_email.slice(0, 1) + "****************" + contract.patient_email.slice(-1)) : "N.A.";
            }
          });
        }
        this.resData.totalRecords = data.number_of_records;
        this.resData.number_of_pages = data.number_of_pages;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }

  applyFilters() {
    this.resData.pageNumber = 1;
    let obj = {
      sort: this.sort,
      page: this.resData.pageNumber,
      statusFilter: this.selectedTab,
      extraFilter: this.selectedItems,
      industryFilter: this.industryselect,
      subIndustryFilter: this.subindustryselect,
      search: this.dataArguements.search_string
    }
    if (obj.sort <= 0) { delete obj.sort };
    if (obj.page <= 0) { delete obj.page };
    this.router.navigate(["/app/contracts/list"], {
      queryParams: obj,
      queryParamsHandling: "merge"
    });

  }

  resetSearch() {
    this.resData.pageNumber = 1;
    this.dataArguements.offset = 0;
    this.dataArguements.search_string = "";
    this.applyFilters();
  }

  resetFilters(event: any) {
    this.resData.pageNumber = 1;
    this.dataArguements.offset = 0;
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.selectedItems = [];
    this.subindustryselect = [];
    this.industryselect = [];
    this.selectedTab = [];
    this.sort = 1;
    this.dataArguements.search_string = "";
    this.modifyQueryParams();
  }

  showFilter(value: any) {
    this.show_filters = value;
  }

  filterValues(event: any) {
    this.resData.pageNumber = 1;
    this.dateRangeValue = [event.data.startDate, event.data.endDate];
    this.selectedTab = event.data.contractStatus;
    this.sort = event.data.sortBy;
    this.selectedItems = event.data.filterBy;
    this.industryselect = event.data.industryFilter;
    this.subindustryselect = event.data.subIndustryFilter;
    this.dataArguements.search_string = event.data.search;
    this.modifyQueryParams();
  }

  modifyQueryParams() {
    let params = {
      sort: this.sort,
      page: this.resData.pageNumber,
      statusFilter: this.selectedTab,
      extraFilter: this.selectedItems,
      industryFilter: this.industryselect,
      subIndustryFilter: this.subindustryselect,
      search: this.dataArguements.search_string,
      sD: this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd"),
      eD: this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd")
    }
    this.router.navigate(["/app/contracts/list"], {
      queryParams: params,
      queryParamsHandling: "merge"
    });
    this.applyFilters();
  }

  industryFilter(event: any) {
    if (event.length == 0) {
      this.industry_filter = [];
      this.subindustry = [];
    } else {
      this.filterEnabled = true;
      this.dataArguements.offset = 0;
      this.industry_filter = [];
      event.forEach((item: any, index: any) => {
        this.industry_filter.indexOf(item.value) === -1 ? this.industry_filter.push(item.value) : 0;
      });
      this.getSubIndustry();
    }
  }

  getSubIndustry() {
    let data = {
      industry_filter: this.industry_filter
    };
    this.loader = true;
    this._api.api(data, "business/get_specialities").subscribe(
      (data: any) => {
        this.loader = false;
        if (data && data.data) {
          data.data.forEach((item: any, index: any) => {
            item.doctor_speciality = item.doctor_speciality.toString();
          });
          this.subindustry = data.data;
        }
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }
}
