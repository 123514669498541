<div class="p-3">
   
    <div class="d-flex justify-content-between mb-4">
        <div>
            <p class="m-0 fs-24 fw-700 text-secondary">Contract Settlement Payment</p>
        </div>
        <div>
            <span class="bg-primary rounded py-2 px-3 fs-18 fw-600"> Total Payments: {{resData.totalRecords?resData.totalRecords:0}}</span>
        </div>
    </div>
    
    <div class="row m-0 align-items-center mb-2" [hidden]="!show_filters">
        <app-filters class="row m-0 w-100" [filters]="filters" (finalValues)="filterValues($event)" (resetAllFilters)="resetFilters($event)">
        </app-filters>
    </div>
    <div class="d-flex justify-content-end mb-4">
        <div>
            <button (click)="showFilter(!show_filters)" type="button" class="btn rounded-pill py-1 px-2 me-3 " [ngClass]="{'btn-primary':show_filters, 'btn-outline': !show_filters}" >
                <span class="fs-16 me-1">{{show_filters ? "Hide Filters" : "Show Filters"}}</span>
                <i class="fa fa-filter"></i>
            </button>
        </div>
    
        <div class="btn-group" dropdown>
            <button *ngIf="contracts && contracts.length" id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle rounded-pill py-1 px-2"
                aria-controls="dropdown-basic">
                Export <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem">
                    <button class="dropdown-item" tappable (click)="exportXLSX(0)">Export XLSX</button>
                </li>
                <li role="menuitem">
                    <button class="dropdown-item" tappable (click)="exportXLSX(1)">Export CSV</button>
                </li>
            </ul>
        </div>
    </div>
   

  <div class="card card-body">
    <div class="table-responsive" *ngIf="contracts && contracts.length">
        <table class="table table-striped table-bordered table-responsive" >
            <thead>
                <tr>
                    <th>S No.</th>
                    <th style="padding: 0px 50px 15px 10px;">Contract Code</th>
                    <th style="padding: 0px 110px 15px 100px;">Customer Details</th>
                    <th style="padding: 0px 50px 15px 10px;">Business Details</th>
                    <th style="padding: 0px 50px 15px 10px;">Payment Count Details</th>
                    <th style="padding: 0px 100px 15px 50px;">Finance Details</th>
                    <th style="padding: 0px 50px 15px 10px;">Settled By </th>
                    <th style="padding: 0px 50px 15px 10px;">Payment ID</th>
                    <th style="padding: 0px 20px 15px 10px;">Amount</th>
                    <th>Payment Date</th>
                    <th>Payment status</th>
                    <!-- <th>TimeZone</th> -->
                </tr>
            </thead>
    
            <tbody>
                <tr *ngFor="let contract of contracts; let i=index">
                    <td>{{((resData.pageNumber-1)*10)+(i+1)}}</td>
                    <td style="padding-top:35px"><a class="padd_r5" [routerLink]="'/app/contracts/details/'+contract.contract_id">
                        {{contract.contract_code}}
                    </a>
                    <br/>
                    <!-- <span class="badge bg-warning" *ngIf="contract.contract_type == 1">10% Platform Fee</span> -->
                    <span class="badge bg-success" *ngIf="contract.contract_type == 2">CredeeFi</span>
                    <span class="badge bg-primary" *ngIf="contract.contract_type == 3">KeepeeFi</span>
                    <span class="badge bg-primary" *ngIf="contract.contract_type == 4">{{contract.ez_plan_new ? 'ZeroFi Financing':
                        'SimpleeFi Financing'}}</span>
                    <span class="badge bg-primary" *ngIf="contract.contract_type == 6">SimpleeFi (With Deferred Interest)</span>
                    <span class="badge bg-primary" *ngIf="contract.contract_type == 5">Credee Bill Payment Plan</span>
                    <!-- <span class="badge bg-success" *ngIf="contract.contract_type == 4"> Processed Payments</span>
                    <span class="badge bg-success" *ngIf="contract.contract_type == 5">Payments with Credee Bill</span> -->
                    </td>
                    <td style="padding-top:40px">
                        <a [routerLink]="['/app/customers/profile',contract.patient_id]" style="color: #0A6B89 !important">{{contract.patient_name}}</a>
                        <p>{{contract.patient_email}}</p>
                    </td>
                    <td style="padding-top: 50px;">  
                        <a [routerLink]="'/app/business/details/'+contract.doctor_id" class="mb-3" style="color: #0A6B89 !important">{{contract.doctor_name?contract.doctor_name:'-'}}</a>
                        <div *ngIf="contract.is_active==2; else active">
                            <p [ngClass]="(contract.is_active==2)?'not_active':''" >Inactive</p>
                        </div>
                        <ng-template #active>
                        <p [ngClass]="(contract.is_active==1)?'active':''">Active</p>
                        </ng-template>
                    </td>
    
                    <td style="padding-top: 20px;">
                        <p> Total Payments : {{contract.number_of_payments}}</p>
                        <p> Paid payments : {{contract.paid_payments}}</p>
                        <p> Remaining payments : {{contract.remaining_payments}}</p>
                        <p> Missing Payments: {{contract.missing_payments}}</p>
                    </td>
                    <td>
                        <p>Total Financed Amount: {{contract.financed_amount | currency: display_logo}}</p>
                        <p>Downpayment Amount: {{contract.downpayment_amount | currency: display_logo}}</p>
                        <p>Paid Amount: {{contract.paid_amount | currency: display_logo}}</p>
                        <p>Remaining Amount: {{contract.remaining_amount | currency: display_logo}}</p>
                    </td>
                    <td><a  [routerLink]="'/app/business/details/'+contract.denefits_doctor_id" style="color: #0A6B89 !important">{{contract.denfits_doctor_name}}</a></td>
                    <td>
                        <a  [routerLink]="'/app/payment-processing/'+contract.ppr_id" class="mb-3" style="color: #0A6B89 !important">{{contract.payment_process_code || '-'}}</a>
                    </td>
                    <td>
                        <p>{{contract.payment_id_amount | currency: display_logo}}</p>
                    </td>
                    <td>
                        <p>{{contract.date_added | date : 'MM-dd-yyyy':timezone}}</p>
                    </td>
                    <td style="padding: 0px 26px 0px 25px;">
                        <p *ngIf="contract.status==2" [ngClass]="(contract.status==2)?'active':''">Success</p>
                        <p *ngIf="contract.status==7"  [ngClass]="(contract.status==7)?'pending_pay':''">Initiated</p>
                        <p *ngIf="contract.status==3" [ngClass]="(contract.status==3)?'not_active':''">Failed</p>
                    </td>
                    <!-- <td>{{contract.date_added | date:'MM-dd-yyyy': timezone}}</td> -->
                </tr>
            </tbody>
            
        </table>
    </div>
    
    <div class="row m-0 mt-3 border-top pt-2 align-items-center" *ngIf="contracts && contracts.length">
        <div class="col-md-6 col-sm-12">
            <p class="text-bold">
                Showing {{(1+(resData.pageNumber - 1) * 10)}} to {{(contracts.length + (resData.pageNumber - 1) * 10)}}
                of {{resData.totalRecords || 'all'}}
            </p>
        </div>
        <div class="col-md-6 col-sm-12 mt-3">
            <pagination class="pagination-sm float-md-right" [totalItems]="resData.totalRecords" [maxSize]="5"
                [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
                [(ngModel)]="resData.pageNumber">
            </pagination>
        </div>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="!contracts || (contracts && !contracts.length)">
        <img src="assets/images/svg/patients.svg">
        <h4 style="color:#8592a5">No data Found</h4>
      </div>

</div>
</div>
