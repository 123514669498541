<div class="modal-dialog modal-md  modal-dialog-centered customModal">
  <div class="modal-content border-0">
    <div class="modal-header  d-flex justify-content-between align-items-center bg-primary ">
      <h4 class="modal-title fs-18 text-white">
        {{ extra_data.modal_heading ? extra_data.modal_heading :'Confirmation'}}
      </h4>
      <img src="assets/images/icons/white-cross.svg" alt="" (click)="hide()" class="cursor hw-24">
    </div>
    <ng-container *ngIf="!extra_data.confirmationType">
      <div class="modal-body">
        <form [formGroup]="notesForm" class="w-100">
          <div class="col-12 pl-0">
            <h4 class="fs-18 fw-500"> {{extra_data.label}} </h4>
            <p *ngIf="extra_data.data && extra_data.data.length && extra_data.data[0].patient_email">
              Customer Email: {{extra_data.data[0].patient_email}}
            </p>
            <p *ngIf="extra_data.data && extra_data.data.length && extra_data.data[0].patient_mobile">
              Customer Mobile: {{extra_data.data[0].patient_mobile}}
            </p>
          </div>
          <div class="me-2" *ngIf="extra_data.refundType">
            <div>
              <input tappable class="secondary-radio" type="radio" 
                (change)="tiggleWithTransactionFee(false)" value="0" id="refundAmountOnly" name="refundOption">
              <label tappable class="fs-14 fw-400 m-0"
                for="refundAmountOnly">Refund Payment Amount Only ${{extra_data.data.treatment_amount |number:'1.2-2'}}</label>
            </div>
            <div class="me-2" *ngIf="extra_data.refundType">
              <input tappable class="secondary-radio" type="radio" 
                (change)="tiggleWithTransactionFee(true)" value="1" id="refundWithFee" name="refundOption">
              <label tappable class="fs-14 fw-400 m-0"
                for="refundWithFee">Refund Payment Amount + Transaction Fee that is ${{(extra_data.data.treatment_amount + extra_data.data.treatment_amount_transaction_fee)| number:'1.2-2'}} </label>
            </div>
            
          </div>
          <div *ngIf="!extra_data.hideNotes" class="form-group">
            <label class="fs-14 fw-40">Note*</label>
            <textarea required formControlName="notes" name="notes" class="input_field" trim="true"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="hide()">No</button>
        <button class="btn btn-primary" (click)="confirm()">Yes</button>
      </div>
    </ng-container>

    <ng-container *ngIf="extra_data.confirmationType">
      <div class="modal-content border-0">

        <div class="modal-body">
          <form [formGroup]="resendLinkForm">
            <div class="col-12 pl-0">
              <h4 class="fs-18 fw-500">Are you sure, you want to resend the micro deposit link to the
                following contact information?</h4>
              <div class="form-group mb-3">
                <span class="fs-14 fw-400 m-0"> Email : </span>
                <input type="email" class="input_field "
                  [class.has-error]="resendLinkForm.get('user_email1')?.invalid && resendLinkForm.get('user_email1')?.touched"
                  placeholder="Email Address" formControlName="user_email1" />
                <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                  *ngIf="(resendLinkForm.controls['user_email1'].touched && resendLinkForm.controls['user_email1'].invalid)">

                  {{resendLinkForm.controls['user_email1'].errors ? 'Please enter a valid Email' : ''}} </p>
              </div>
              <div class="form-group">
                <span class="fs-14 fw-400 m-0"> Phone : </span>
                <div class="input-group ">
                  <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                    <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center"
                      id="inputGroupPrepend3">+1</span>
                    <input type="text" class="input_field border-0 border-start m-0 flex-fill rounded-0"
                      formControlName="user_phone1" [mask]="mask" placeholder="Phone Number" />
                  </div>
                  <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                    *ngIf="(resendLinkForm.controls['user_phone1'].touched && resendLinkForm.controls['user_phone1'].invalid)">
                    {{resendLinkForm.controls['user_phone1'].errors ? 'Please enter a valid Phone Number' : ''}} </p>
                </div>

              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline" (click)="hide()">Cancel</button>
          <button class="btn btn-primary" (click)="onSubmit()" type="button">Resend</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
