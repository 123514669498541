<div style="font-weight: 600;font-size: 17px;" class="pb-2">
    <div class="d-flex align-items-center justify-content-between">
        <h4 class="modal-title fs-18">Payment Method</h4>
        <img src="assets/images/icons/cross-icon.svg" alt="" (click)="hide()" class="cursor hw-24">
    </div>
</div>

<!-- card/bank element -->
<div style="border: none;padding: 0;" class="-stripe-card" id="payment-element"></div>

<!-- wallets option if enabled -->
<div id="payment_request_button"></div>

<!-- Address/Billing Element -->
<div *ngIf="!bankType" class="col-md-12">
    <div style="font-weight: 600;font-size: 17px;margin-top: 20px;">
        <label>Billing details</label>
    </div>
    <div id="address-element"></div>
</div>

<div class="d-flex justify-content-center">
    <button (click)="confirmPaymentSetUp()" button-primary-auth class="mb-2 mt-3 px-5 w-auto btn btn-primary">Add</button>
</div>
