<div class="modal-header bg-primary d-flex align-items-center justify-content-between">
  <h4 class="modal-title text-white fs-18">Verify {{data.verify_type}}</h4>
  <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
</div>
<form [formGroup]="verifyForm" (ngSubmit)="handleVerification()" *ngIf="verifyForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6 col-sm-12 mb-3" *ngIf="data && data.verify_type == verifyType.Doctor">
        <div class="p-0">
          <div class="checkbox c-checkbox">
            <span>
              <input formControlName="website_verification" type="checkbox" id="website_verification" class="secondary-checkbox" (change)="onCheckChange(1)">
              <label class="fs-14 fw-400 m-0" for="website_verification">Business Website URL</label>
            </span>
          </div>
          <div class="d-flex align-items-center input_field p-0 overflow-hidden">
              <button class="bg-transparent border-0 h-100 hw-40">
                <i class="fa fa-globe"></i>
              </button>
            <input type="text" class="input_field border-0 border-start m-0 flex-fill rounded-0" formControlName="practice_url"
              [readonly]="!verifyForm.controls['website_verification'].value" />
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 mb-3" *ngIf="data && data.verify_type == verifyType.Doctor">
        <div class="p-0">
          <div class="checkbox c-checkbox">
            <input formControlName="email_verification" id="email_verification" class="secondary-checkbox" type="checkbox" (change)="onCheckChange(2)">
            <label  class="fs-14 fw-400 m-0" for="email_verification">Email Address*</label>
          </div>
          <div class="d-flex align-items-center input_field p-0 overflow-hidden">
              <button class="bg-transparent border-0 h-100 hw-40">
                <i class="fa fa-envelope"></i>
              </button>
            <input [readonly]="!verifyForm.controls['email_verification'].value" formControlName="doctor_email" email
              required class="input_field border-0 border-start m-0 flex-fill rounded-0" type="email" autocomplete="none">
          </div>
          <p class="text-danger fs-12"
            *ngIf="(verifyForm.controls['doctor_email'].touched || verifyForm.controls['doctor_email'].dirty) && verifyForm.controls['doctor_email'].invalid">
            Please enter valid email.</p>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 mb-3">
        <div class="p-0">
          <div class="checkbox c-checkbox">
            <input formControlName="phone_number_verification"  id="phone_number_verification" class="secondary-checkbox"type="checkbox" (change)="onCheckChange(3)">
            <label  class="fs-14 fw-400 m-0" for="phone_number_verification">  Phone Number <span *ngIf="data && data.verify_type == verifyType.Doctor" class="m-0">*</span> </label>
          </div>
          <!--Business Mobile-->
          <div *ngIf="data && data.verify_type == verifyType.Doctor">
            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                <button class="bg-transparent border-0 h-100 hw-40" *ngIf="profile.doctor_mobile_country_code">{{profile.doctor_mobile_country_code}}</button>
              <input [mask]="_shared.mask"  class="input_field border-0 border-start m-0 flex-fill rounded-0" formControlName="doctor_mobile" required
                name="doctor_mobile" type="text" autocomplete="none"
                [readonly]="!verifyForm.controls['phone_number_verification'].value">
            </div>
            <p class="text-danger fs-12"
              *ngIf="(verifyForm.controls['doctor_mobile'].touched || verifyForm.controls['doctor_mobile'].dirty) && verifyForm.controls['doctor_mobile'].invalid">
              Please enter valid phone number.</p>
          </div>
          <!--Patient Mobile-->
          <div *ngIf="data && data.verify_type == verifyType.Patient">
            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
              <button class="bg-transparent border-0 h-100 hw-40" *ngIf="profile.patient_mobile_country_code">{{profile.patient_mobile_country_code}}</button>
              <input [mask]="_shared.mask" class="input_field border-0 border-start m-0 flex-fill rounded-0" formControlName="patient_mobile" required
                type="number" autocomplete="none"
                [readonly]="!verifyForm.controls['phone_number_verification'].value">
            </div>
            <p class="text-danger fs-12"
              *ngIf="(verifyForm.controls['patient_mobile'].touched || verifyForm.controls['patient_mobile'].dirty) && verifyForm.controls['patient_mobile'].invalid">
              Please enter valid phone number.</p>
          </div>
          <!--Partner Mobile-->
          <div *ngIf="data && data.verify_type == verifyType.Partner">
            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
           
                <button class="bg-transparent border-0 h-100 hw-40" *ngIf="profile.partner_mobile_country_code">{{profile.partner_mobile_country_code}}</button>
             
              <input [mask]="_shared.mask" class="input_field border-0 border-start m-0 flex-fill rounded-0" formControlName="partner_mobile" required
                name="partner_mobile" type="number" autocomplete="none"
                [readonly]="!verifyForm.controls['phone_number_verification'].value">
            </div>
            <p class="text-danger fs-12"
              *ngIf="(verifyForm.controls['partner_mobile'].touched || verifyForm.controls['partner_mobile'].dirty) && verifyForm.controls['partner_mobile'].invalid">
              Please enter valid phone number.</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 mb-3">
        <div class="p-0">
          <div class="checkbox c-checkbox">
            <input formControlName="id_verification" id="id_verification" class="secondary-checkbox" name="id" type="checkbox">
            <label  class="fs-14 fw-400 m-0" for="id_verification"> Photo ID</label>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 mb-3">
        <div class="p-0">
          <div class="checkbox c-checkbox">
            <input formControlName="people_search_verification" id="people_search_verification" class="secondary-checkbox" name="people" type="checkbox">
            <label  class="fs-14 fw-400 m-0" for="people_search_verification">People Search Completed and Verified</label>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label class="fs-14 fw-400 m-0">Note*</label>
          <textarea class="input_field" required rows="4" formControlName="notes" trim="true"></textarea>
          <p *ngIf="(verifyForm.controls['notes'].touched || verifyForm.controls['notes'].dirty) && verifyForm.controls['notes'].invalid"
            class="text-danger fs-12">
            Please enter the notes.
          </p>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer text-end">
    <div>
      <button type="submit" class="btn btn-primary" [disabled]="verifyForm.invalid">Verify</button>
    </div>
  </div>
</form>