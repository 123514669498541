import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './components/shared/main-layout/main-layout.component';
import { PagesModule } from './components/pages/pages.module';
import { SharedModule } from './components/shared/shared.module';
import { MainService } from './components/shared/main.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorService } from './components/shared/interceptor.service';
import { SharedService } from './components/shared/shared.service';
import { ToasterComponent } from './components/shared/toaster/toaster.component';
import { ToasterContainerComponent } from './components/shared/toaster/toaster-container-component';
import { ApiService } from './components/shared/api.service';
import { ToasterService } from './components/shared/toaster/toaster.service';
import { NavsearchComponent } from './components/shared/main-layout/navsearch/navsearch.component';
import { SearchComponent } from './components/shared/main-layout/search/search.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ToasterContainerComponent,
    // NavsearchComponent,
    // SearchComponent,
    ToasterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    PagesModule,
    SharedModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    SharedService,
    MainService,
    ApiService, ToasterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
