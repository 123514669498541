<header class="fs-24 fw-600 ms-2 pt-2">Search</header>
<form class="form form-inline mb-4 ms-2" role="search" (submit)="search()">
  <div class="form-group col-md-4 col-sm-12 p-0 d-flex">
    <input [(ngModel)]="term" name="searchTerm" class="form-control rounded-0 col-md-6 col-sm-12 " type="text" id="search_input"
      placeholder="Type and click on search" autocomplete="off" />
    <button type="submit" class="btn btn-primary btn-sm p-1 mx-2  rounded-0" id="search_btn">Search</button>
      <button class="btn btn-primary  " (click)="location.back()">Back</button>
  </div>
 
  
</form>

<div class="d-flex align-items-center ps-2 pb-2 ">
  <label class="mr-4 fw-500">Display Results for:</label>

  <label class="mr-2" tappable [ngClass]="{'active-card':show.doctor}">
    <input type="checkbox" class="d-none" name="doctor" [(ngModel)]="show.doctor">Business
  </label>
  <!-- <label class="mr-2" tappable [ngClass]="{'active-card':show.partner}">
    <input type="checkbox" class="d-none" name="partner" [(ngModel)]="show.partner">Partner
  </label> -->
  <label class="mr-2" tappable [ngClass]="{'active-card':show.patient}">
    <input type="checkbox" class="d-none" name="patient" [(ngModel)]="show.patient">Customer
  </label>
  <label class="mr-2" tappable [ngClass]="{'active-card':show.contract}">
    <input type="checkbox" class="d-none" name="contract" [(ngModel)]="show.contract">Contract
  </label>
  <!-- <label class="mr-2" tappable [ngClass]="{'active-card':show.payments}">
    <input type="checkbox" class="d-none" name="contract" [(ngModel)]="show.payments">Payment Processing
  </label> -->
  <!-- <label class="mr-2" tappable [ngClass]="{'active-card':show.ar}">
    <input type="checkbox" class="d-none" name="contract" [(ngModel)]="show.ar">Crdee Bill
  </label> -->
  <label class="mr-2" tappable [ngClass]="{'active-card':show.proposal}">
    <input type="checkbox" class="d-none" name="contract" [(ngModel)]="show.proposal">Proposal
  </label>
  <!-- <label class="mr-2" tappable [ngClass]="{'active-card':show.pa_leads}">
    <input type="checkbox" class="d-none" name="contract" [(ngModel)]="show.pa_leads">Pre-approval Leads
  </label> -->
</div>

<div class="card card-default p-2"
  *ngIf="show && !show.doctor && !show.partner && !show.patient && !show.contract && !show.payments && !show.ar">
  Please select atleast one filter to see results;
</div>

<div class="card card-default" *ngIf="emptyData">
  <div class="card-header">Search - "{{term?term:''}}"</div>
  <div class="card-body">
    <p class="text-center">No Result Found!</p>
  </div>
</div>

<div class="row">
  <div class="col-md-6 col-sm-12"
    *ngIf="(show && show.doctor) && (results && results.doctors && results.doctors.length)">
    <div class="card card-default p-2">
      <h3>Businesses</h3>
      <div class="p-2 row">
        <div class="col-md-12 mb-2" *ngFor="let doctor of results.doctors"
          [routerLink]="'/app/business/details/'+doctor.doctor_id">
          <div class="col-12 py-2 border rounded clickable-item px-2 ">
            <h3 class="my-1 text-primary">
              {{doctor.doctor_name ? doctor.doctor_name : 'N/A'}}
            </h3>
            <div class="row">
              <div class="col-6 text-label">
                <span class="text-muted">Email:</span>
                <span [innerHTML]="doctor.doctor_email | highlight:searched_string"></span>
              </div>
              <div class="col-6 text-label"><span class="text-muted">Phone:</span>
                {{doctor.doctor_mobile}}</div>
              <div class="col-6 text-label">
                <span class="text-muted">Business Name:</span>
                <span [innerHTML]="doctor.practice_name | highlight:searched_string"></span>
              </div>
              <div class="col-6 text-label">
                <span class="text-muted">Business Email:</span>
                <span [innerHTML]="doctor.practice_email | highlight:searched_string"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-12"
    *ngIf="(show && show.contract) && (results && results.contracts && results.contracts.length)">
    <div class="card card-default p-2">
      <h3>Contracts</h3>
      <div class="p-2 row">
        <div class="col-md-12 mb-2" *ngFor="let contract of results.contracts"
          [routerLink]="'/app/contracts/details/'+contract.contract_id" id="contract_details">
          <div class="col-12 py-2 border rounded clickable-item px-2 ">
            <h3 class="my-1 text-primary">
              <span [innerHTML]="contract.contract_code | highlight:searched_string" class="h3 text-bold"></span>
              <span *ngIf="contract.pf_status<3" class="badge badge-primary float-right">Active</span>
              <span *ngIf="contract.pf_status==3" class="badge badge-warning float-right">Overdue</span>
              <span *ngIf="contract.pf_status==4" class="badge badge-success float-right">Completed</span>
              <span *ngIf="contract.pf_status==5" class="badge badge-danger float-right">Canceled</span>
            </h3>
            <div class="row">
              <div class="col-6 text-label"><span class="text-muted">Business Owner Name:</span>
                {{contract.doctor_title}} <span [innerHTML]="contract.doctor_name | highlight:searched_string:'capital'"></span>
              </div>
              <div class="col-6 text-label"><span class="text-muted">Business Owner Email:</span>
                <span [innerHTML]="contract.doctor_email | highlight:searched_string"></span>
              </div>
              <div class="col-6 text-label"><span class="text-muted">Customer Name:</span>
                <span [innerHTML]="contract.patient_name | highlight:searched_string:'capital'"></span>
              </div>
              <div class="col-6 text-label"><span class="text-muted">Customer Email:</span>
                <span [innerHTML]="contract.patient_email | highlight:searched_string"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-12"
    *ngIf="(show && show.partner) &&(results && results.partners && results.partners.length)">
    <div class="card card-default p-2">
      <h3>Partners</h3>
      <div class="p-2 row">
        <div class="col-md-12 mb-2" *ngFor="let partner of results.partners" (click)="viewPartner(partner.partner_id)">
          <div class="col-12 py-2 border rounded clickable-item px-2 ">
            <h3 class="my-1 text-primary">{{partner.title}}
              <span class="h3 text-bold" [innerHTML]="partner.partner_name | highlight:searched_string:'capital'"></span>
            </h3>
            <div class="row">
              <div class="col-6 text-label"><span class="text-muted">Email:</span>
                <span [innerHTML]="partner.partner_email | highlight:searched_string"></span>
              </div>
              <div class="col-6 text-label"><span class="text-muted">Phone:</span>
                {{partner.partner_mobile}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-12"
    *ngIf="(show && show.patient) &&(results && results.patients && results.patients.length)">
    <div class="card card-default p-2">
      <h3>Customers</h3>
      <div class="p-2 row">
        <div class="col-md-12 mb-2" *ngFor="let patient of results.patients"
          [routerLink]="'/app/customers/profile/'+patient.patient_id">
          <div class="col-12 py-2 border rounded clickable-item px-2 ">
            <h3 class="my-1 text-primary">{{patient.patient_title}}
              <span class="h3 text-bold" [innerHTML]="patient.patient_name | highlight:searched_string:'capital'"></span>
            </h3>
            <div class="row">
              <div class="col-6 text-label"><span class="text-muted">Email:</span>
                <span [innerHTML]="patient.patient_email | highlight:searched_string"></span></div>
              <div class="col-6 text-label"><span class="text-muted">Phone:</span>
                {{patient.patient_mobile}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-12"
    *ngIf="(show && show.payments) &&(results && results.payments && results.payments.length)">
    <div class="card card-default p-2">
      <h3>Payments Processed</h3>
      <div class="p-2 row">
        <div class="col-md-12 mb-2" *ngFor="let payment of results.payments"
          [routerLink]="'/app/payment-processing/'+payment.id">
          <div class="col-12 py-2 border rounded clickable-item px-2 ">
            <h3 class="my-1 text-primary">
              <span class="h3 text-bold" [innerHTML]="payment.patient_name | highlight:searched_string:'capital'"></span>
              <span *ngIf="payment.status==1" class="badge badge-warning float-right">Pending</span>
              <span *ngIf="payment.status==2" class="badge badge-success float-right">Success</span>
              <span *ngIf="payment.status==3" class="badge badge-danger float-right">Failed</span>
              <span *ngIf="payment.status==4" class="badge badge-info float-right">Refunded</span>
              <span *ngIf="payment.status==5" class="badge badge-danger float-right">Declined</span>
            </h3>
            <div class="row">
              <div class="col-6 text-label">
                <span class="text-muted">Email:</span>
                <span [innerHTML]="payment.patient_email | highlight:searched_string"></span>
              </div>
              <div class="col-6 text-label">
                <span class="text-muted">Phone:</span>
                {{payment.patient_mobile ? (payment.patient_mobile): 'N/A'}}
              </div>
              <div class="col-12 text-label">
                  <span class="text-muted">Payment ID:</span>
                  <span *ngIf="payment.payment_process_code"
                    [innerHTML]="payment.payment_process_code | highlight:searched_string"></span>
                  <span *ngIf="!payment.payment_process_code">N/A</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-12"
    *ngIf="(show && show.ar) &&(results && results.ar && results.ar.length)">
    <ng-container *ngIf="results.ar as proposal_ar">
      <ng-container *ngTemplateOutlet="proposals_listing; context: {data: {list: proposal_ar, heading: 'Debt recovery', link: '/app/debt-recovery/details/'}}" ></ng-container>
    </ng-container>
    <div class="card card-default p-2">
      <h3>Debt recovery</h3>
      <div class="p-2 row">
        <div class="col-md-12 mb-2" *ngFor="let payment of results.ar"
          [routerLink]="'/app/debt-recovery/details/'+payment.split_req_id">
          <div class="col-12 py-2 border rounded clickable-item px-2 ">
            <h3 class="my-1 text-primary">
              <span class="h3 text-bold" [innerHTML]="payment.patient_name | highlight:searched_string:'capital'"></span>
              <span *ngIf="payment.status==1" class="badge badge-warning float-right">Pending</span>
              <span *ngIf="payment.status==2" class="badge badge-success float-right">Success</span>
              <span *ngIf="payment.status==3" class="badge badge-danger float-right">Failed</span>
              <span *ngIf="payment.status==4" class="badge badge-info float-right">Refunded</span>
              <span *ngIf="payment.status==5" class="badge badge-danger float-right">Declined</span>
            </h3>
            <div class="row">
              <div class="col-6 text-label"><span class="text-muted">Email:</span>
                <span [innerHTML]="payment.patient_email | highlight:searched_string"></span></div>
              <div class="col-6 text-label"><span class="text-muted">Phone:</span>
                {{payment.patient_mobile ? (payment.patient_mobile): 'N/A'}}</div>
              <div class="col-12 text-label">
                <span class="text-muted">Payment ID:</span>
                <span *ngIf="payment.payment_process_code"
                  [innerHTML]="payment.payment_process_code | highlight:searched_string"></span>
                <span *ngIf="!payment.payment_process_code">N/A</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-12"
  *ngIf="(show && show.proposal) &&(results && results.proposal && results.proposal.length)">
  <ng-container *ngIf="results.proposal as proposal">
    <ng-container *ngTemplateOutlet="proposals_listing; context: {data: {list: proposal, heading: 'Proposal', link: '/app/debt-recovery/details/'}}" ></ng-container>
  </ng-container>
  </div>
  
  <div class="col-md-6 col-sm-12"
  *ngIf="(show && show.pa_leads) &&(results && results.pa_leads && results.pa_leads.length)">
  <div class="card card-default p-2">
    <h3>Pre-approval Leads</h3>
    <div class="p-2 row">
      <div class="col-md-12 mb-2" *ngFor="let lead of results.pa_leads"
        [routerLink]="'/app/patients/customers-leads/details/'+lead.application_id">
        <div class="col-12 py-2 border rounded clickable-item px-2 ">
          <h3 class="my-1 text-primary">
            <span [innerHTML]="lead.application_id | highlight:searched_string" class="h3 text-bold"></span>
          </h3>
          <div class="row">
            <div class="col-6 text-label"><span class="text-muted">Business Owner Name:</span>
              <span [innerHTML]="(lead.doctor_first_name + lead.doctor_last_name) | highlight:searched_string"></span>
            </div>
            <div class="col-6 text-label"><span class="text-muted">Business Owner Email:</span>
              <span [innerHTML]="lead.doctor_email | highlight:searched_string"></span>
            </div>
            <div class="col-6 text-label"><span class="text-muted">Customer Name:</span>
              <span [innerHTML]="lead.full_cust_name | highlight:searched_string"></span>
            </div>
            <div class="col-6 text-label"><span class="text-muted">Customer Email:</span>
              <span [innerHTML]="lead.customer_email | highlight:searched_string"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<ng-template #proposals_listing let-data="data">
  <div class="card card-default p-2">
    <h3>{{data.heading}}</h3>
    <div class="p-2 row">
      <div class="col-md-12 mb-2" *ngFor="let payment of data.list"
        [routerLink]="data.link+payment.split_req_id">
        <div class="col-12 py-2 border rounded clickable-item px-2 ">
          <h3 class="my-1 text-primary">
            <span class="h3 text-bold" [innerHTML]="payment.patient_name | highlight:searched_string:'capital'"></span>
            <span *ngIf="payment.status==1" class="badge badge-warning float-right">Pending</span>
            <span *ngIf="payment.status==2" class="badge badge-success float-right">Success</span>
            <span *ngIf="payment.status==3" class="badge badge-danger float-right">Failed</span>
            <span *ngIf="payment.status==4" class="badge badge-info float-right">Refunded</span>
            <span *ngIf="payment.status==5" class="badge badge-danger float-right">Declined</span>
          </h3>
          <div class="row">
            <div class="col-6 text-label"><span class="text-muted">Email:</span>
              <span [innerHTML]="payment.patient_email | highlight:searched_string"></span></div>
            <div class="col-6 text-label"><span class="text-muted">Phone:</span>
              {{payment.patient_mobile ? (payment.patient_mobile): 'N/A'}}</div>
            <div class="col-12 text-label">
              <span class="text-muted">Payment ID:</span>
              <span *ngIf="payment.payment_process_code"
                [innerHTML]="payment.payment_process_code | highlight:searched_string"></span>
              <span *ngIf="!payment.payment_process_code">N/A</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
