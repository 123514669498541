import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddNotesComponent } from '../../../shared/add-notes/add-notes.component';
import * as moment from 'moment';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { CustomerService } from 'src/app/components/shared/customers.service';
import { CustomerModalComponent } from '../customer-modal/customer-modal.component';
import { UpdateCustomerModalComponent } from 'src/app/components/shared/customer-update/customer-update.component';
@Component({
  selector: 'app-business-lead-details',
  templateUrl: './business-lead-details.component.html',
  styleUrls: ['./business-lead-details.component.scss'],
})
export class BusinessLeadDetailsComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  [x: string]: any;
  fullSSNForm!: UntypedFormGroup;

  country_info: any = [];
  currency_info: any = [];
  application_id: string = '';
  leads_data: any;
  timezone: any = localStorage.getItem('timezone');
  empZipCodeData: any = {};
  customerDetails: any = {};
  office_zipObj: any = {};
  modalType = '';
  email_regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  // url_regex: any = new RegExp(/^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/);
  url_regex: any = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

  phone_mask: any = '000-000-0000';
  link_type = 2;
  password: any;
  showPwd: boolean = false;
  note: any;
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  show_full_ssn = false;
  is_website_input_focus: boolean = false;
  allowed_payback_methods = [
    'Family',
    'Spouse',
    'Guardian',
    'Siblings',
    'Acquaintance',
    'Siblings',
    'Social Security Benefits',
    'Crowdfunding/Fundraising',
    'Others',
  ];
  zipPostalCode: any;
  empZipCode: any;
  office_zip: string = '';
  customerDob: string = '';
  zipLocCode: number = 0;
  step = 1;
  SameAddress: any;
  customerInfo: any = [];
  edit_customer_step = 2;

  invalid_input_class = {};
  load_notes: boolean = false;
  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public _local: localStorageService,
    public _shared: SharedService,
    private router: Router,
    private _route: ActivatedRoute,
    public location: Location,
    public _fb: FormBuilder,
    private datePipe: DatePipe,
    public _api: ApiService,
    public _customer: CustomerService
  ) {}

  ngOnInit() {
    this.application_id = this._route.snapshot.paramMap.get('id') as string;
    this.get_leads_info();
    this.timezone = this._shared.timezone;

    if (this.modalType == this._customer.modalTypes.fullSSNForm) {
      this.fullSSNForm = this._fb.group({
        password: [],
        note: [],
      });
    }
  }

  // hide modal without any action
  cancel() {
    this.passEntry.emit({ success: false });
    this.hide();
  }

  // hide modal common function
  hide() {
    this.bsModalRef.hide();
  }

  get_leads_info() {
    let data = {
      application_id: this.application_id,
    };
    this._api.api(data, 'leads/leads_details', 'post').subscribe(
      (data: any) => {
        if (!data.is_error) {
          if (data && data.result && data.result.length > 0) {
            this.leads_data = data.result[0];
            this.country_info = this.leads_data.country_info.country;
            this.currency_info =
              this.leads_data.country_info.currency &&
              this.leads_data.country_info.currency.length
                ? this.leads_data.country_info.currency[0]
                : null;
            if (this.leads_data && this.leads_data.ssn) {
              this.leads_data.temp_ssn = this.leads_data.ssn;
              this.leads_data.ssn =
                '* * * * *' + this.leads_data.ssn.substr(-4);
            }
            this.updateMasking(this.country_info?.country_phone_format);
            this.getContractDetails(this.leads_data);
          }
        } else if (data.is_error == 1) {
          if (data.override_text) {
            this._shared.customPopups(data.override_text, 1);
          } else {
            this._shared.customPopups(data.is_error, 3);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  time_in_format_utc_current(time: any) {
    if (time) {
      let now = new Date(
        `${this.datePipe.transform(
          new Date(new Date().setHours(0, 0, 0, 0)),
          'MM/dd/yyyy'
        )} ${time}`
      );
      return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    } else {
      return;
    }
  }

  EditCustomer() {
    const modelRef = this.modalService.show(UpdateCustomerModalComponent, {
      initialState: {
        modalType: this._customer.modalTypes.customerDetails,
        leads_data: this.leads_data,
        customerInfo: this.customerInfo,
        currency_info: this.currency_info,
      },
      class: 'modal-dialog-centered',
    });
  }

  addContractNotesModal() {
    let tempdata = {
      assign_type: 7,
      preapproval_lead_id: this.leads_data.application_id,
    };
    let initialState = {
      data: tempdata,
    };
    this._shared.noteadded = false;
    const bsModalRef = this.modalService.show(AddNotesComponent, {
      initialState,
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this._shared.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.get_leads_info();
          count = 1;
          this.load_notes = false;
        }
      }
    });
  }
  deleteRequest() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete this Alternate Email?`,
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue: any) => {
      inputValue =
        inputValue && inputValue.notes ? inputValue.notes.trim() : null;

      if (inputValue === '' || inputValue.length == 0) {
        // swal.showInputError(' You need to write something! ');
        this._shared.customPopups(' You need to write something! ', 1);
        return false;
      }
      if (inputValue) {
        // swal.close();
        let data: any = {
          application_id: this.application_id,
          hideLoader: true,
          doctor_id: this.leads_data.doctor_id,
          notes: inputValue,
        };
        this._api.api(data, 'delete_leads', 'delete').subscribe(
          (data: any) => {
            if (data.is_error == 0) {
              this.location.back();
              this.load_notes = false;
            } else if (data.is_error == 1) {
              this._shared.customPopups(data.err, 1);
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      }

      return true;
    });
  }

  focusIn(event: FocusEvent) {
    this.is_website_input_focus = true;
  }

  focusOut(event: FocusEvent) {
    this.is_website_input_focus = false;
  }
  public getContractDetails(response: any) {
    this.customerInfo = response;
    this.zipCode.city_id =
      this.customerInfo && this.customerInfo.customer_city_id;
    this.empZipCodeData.city_id =
      this.customerInfo && this.customerInfo.employer_city_id;
    let dob = null;
    if (response.date_of_birth) {
      dob = new Date(response.date_of_birth);
    }
    console.log(response, '>>>');
    this.customerDetails.setValue({
      gender: (response && response.customer_gender) || '',
      customerFirstName: (response && response.customer_first_name) || '',
      customerLastName: (response && response.customer_last_name) || '',
      customerDob: dob || '',
      socailSecuityNumber: response.ssn
        ? '*****' + response.ssn.substring(response.ssn.length - 4)
        : '',
      phoneNumber:
        response && response.customer_mobile ? response.customer_mobile : '',
      emailAddress: response.customer_email || '',
      dlNumber: response.dl_number || '',
      address:
        response && response.customer_address ? response.customer_address : '',
      zipPostalCode: response.customer_zip || '',
      state: response.customer_state || '',
      city: response.customer_city || '',
      employed: this.checkuserEmployed(),
      empName: response.employer_name || '',
      empPhoneNumber:
        response && response.employer_mobile ? response.employer_mobile : '',
      empZipCode: response.employer_zip || '',
      empAddress: response.employer_address || '',
      empState: response.employer_state || '',
      empCity: response.employer_city || '',
      empAnnualIncome: response.annual_income || '',
      document: response.dl_doc,
      office_address: response.office_address || '',
      office_state: response.office_state || '',
      office_zip: response.office_zip || '',
      office_city: response.office_city || '',
      office_city_id: response.office_city_id || '',
      check_office: false,
      payment_source_reference: '',
      payment_source: '',
    });
    this.customerDetails.get('city')!.disable();
    if (response && response.office_city_id == response.customer_city_id) {
      this.SameAddress = true;
    } else {
      this.SameAddress = false;
    }
    if (response.ssn) {
      this.customerDetails.controls['socailSecuityNumber'].disable();
    }
    if (dob) {
      this.customerDob = `${dob}`;
    }
    if (response.customer_zip) {
      this.zipPostalCode = response.customer_zip;
      this.zipCode = {
        city_id: response.customer_city_id,
        zip: response.customer_zip,
        state_name: response.customer_state,
        city: response.customer_city,
      };
    }
    if (response.employer_zip) {
      this.empZipCode = response.employer_zip;
      this.empZipCodeData = {
        city_id: response.employer_city_id,
        zip: response.employer_zip,
        state_name: response.employer_state,
        city: response.employer_city,
      };
    }

    if (response && response.office_zip) {
      this.office_zip = response.office_zip;
      this.office_zipObj = {
        city_id: response.office_city_id,
        zip: response.office_zip,
        state_name: response.office_state,
        city: response.office_city,
      };
    }

    let employment = this.checkuserEmployed();
    response.employed = employment || '';
    if (response.employed || this.checkuserEmployed() !== '') {
      this.changeFeilds(employment);
    }
  }

  /* Get Zip Code */
  zipCode: any = {
    city_id: '',
  };

  checkuserEmployed() {
    let employed: any = '';
    if (this.customerInfo) {
      if (
        this.customerInfo.employement_type &&
        this.customerInfo.employement_type == 1
      ) {
        employed = 0;
      } else if (
        this.customerInfo.employement_type &&
        this.customerInfo.employement_type == 2
      ) {
        employed = 1;
      } else if (
        this.customerInfo.employement_type &&
        this.customerInfo.employement_type == 3
      ) {
        employed = 2;
      }

      this.customerInfo['employed'] = employed;
    }
    return employed;
  }
  changeFeilds(i: any) {
    try {
      if (i) {
      }
      this.customerDetails.get('employed')!.setValue(i);
      if (i == 0 || i == '0') {
        this.customerDetails.addControl(
          'zipPostalCode',
          this._fb.control(
            (this.customerInfo && this.customerInfo.customer_zip) || ''
          )
        );
        this.customerDetails.addControl(
          'empZipCode',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_zip) || ''
          )
        );
        this.empZipCode =
          this.customerInfo && this.customerInfo.employer_zip
            ? this.customerInfo && this.customerInfo.employer_zip
            : '';
        this.customerDetails.controls['empZipCode'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'empName',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_name) || ''
          )
        );
        this.customerDetails.controls['empName'].setValidators([
          Validators.required,
        ]);

        this.customerDetails.addControl(
          'empPhoneNumber',
          this._fb.control(
            this.customerInfo && this.customerInfo.employer_mobile
              ? this.customerInfo &&
                  this.mobile_formate(this.customerInfo.employer_mobile)
              : ''
          )
        );
        // this.customerDetails.controls.empPhoneNumber.setValidators([Validators.required]);

        this.customerDetails.addControl(
          'empAddress',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_address) || ''
          )
        );
        // this.customerDetails.controls.empAddress.setValidators([Validators.required]);

        this.customerDetails.addControl(
          'empAnnualIncome',
          this._fb.control(
            this.customerInfo && this.customerInfo.annual_income > 0
              ? this.customerInfo.annual_income
              : ''
          )
        );
        this.customerDetails.controls['empAnnualIncome'].setValidators([
          Validators.required,
        ]);

        this.customerDetails.addControl(
          'empState',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_state) || ''
          )
        );
        this.customerDetails.addControl(
          'empCity',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_city) || ''
          )
        );
        this.customerDetails.controls['empCity'].setValidators([
          Validators.required,
        ]);

        // remove other controls
        if (this.customerDetails.controls['office_address'] !== undefined) {
          // this.customerDetails.removeControl('office_address');
          // this.customerDetails.removeControl('office_city');
          // this.customerDetails.removeControl('office_city_id');
          // this.customerDetails.removeControl('office_state');
          // this.customerDetails.removeControl('office_zip');
          // this.customerDetails.removeControl('profession');
          // this.customerDetails.removeControl('annual_income');
          this.customerDetails.get('check_office')!.setValue(false);
          this.office_zip = '';
        } else {
          // this.customerDetails.removeControl('payment_source');
        }
      }
      if (i == 1 || i == '1') {
        // this.customerDetails.addControl('payment_source', this.fb.control(this.allowed_payback_methods.includes(this.customerInfo.payback_methods) ? this.customerInfo.payback_methods : !this.customerInfo.payback_methods ? '' : 'Others', [Validators.required]));
        this.customerDetails
          .get('payment_source')!
          .setValue(
            this.allowed_payback_methods.includes(
              this.customerInfo.payback_methods
            )
              ? this.customerInfo.payback_methods
              : !this.customerInfo.payback_methods
              ? ''
              : 'Others'
          );

        if (
          !this.allowed_payback_methods.includes(
            this.customerInfo.payback_methods
          )
        ) {
          // this.customerDetails.controls.payment_source_reference.setValidators(Validators.required);
          this.customerDetails
            .get('payment_source_reference')!
            .setValue(this.customerInfo.payback_methods);
        }

        if (this.customerDetails.controls['office_address'] !== undefined) {
          // this.customerDetails.removeControl('office_address');
          // this.customerDetails.removeControl('office_city');
          // this.customerDetails.removeControl('office_city_id');
          // this.customerDetails.removeControl('office_state');
          // this.customerDetails.removeControl('office_zip');
          // this.customerDetails.removeControl('profession');
          // this.customerDetails.removeControl('annual_income');
          this.customerDetails.get('check_office')!.setValue(false);
          this.office_zip = '';
        }
        if (this.customerDetails.controls['empAddress'] !== undefined) {
          // this.customerDetails.removeControl('empZipCode');
          // this.customerDetails.removeControl('empName');
          // this.customerDetails.removeControl('empPhoneNumber');
          // this.customerDetails.removeControl('empAddress');
          // this.customerDetails.removeControl('empAnnualIncome');
        }
      }
      if (i == 2 || i == '2') {
        this.customerDetails.addControl(
          'office_address',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_address) || ''
          )
        );
        this.customerDetails.controls['office_address'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'profession',
          this._fb.control(
            (this.customerInfo && this.customerInfo.profession) || ''
          )
        );
        this.customerDetails.controls['profession'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'office_city',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_city) || ''
          )
        );
        this.customerDetails.controls['office_city'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'office_city_id',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_city_id) || ''
          )
        );
        // this.customerDetails.controls.office_city_id.setValidators([Validators.required]);
        this.customerDetails.addControl(
          'office_state',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_state) || ''
          )
        );
        this.customerDetails.controls['office_state'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'office_zip',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_zip) || ''
          )
        );
        this.customerDetails.controls['office_zip'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'annual_income',
          this._fb.control(
            (this.customerInfo && this.customerInfo.annual_income) || ''
          )
        );
        if (this.customerDetails.value.annual_income == 0) {
          this.customerDetails.get('annual_income')!.setValue('');
        }
        this.customerDetails.controls['annual_income'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.get('office_city')?.disable();
        this.customerDetails.get('office_state')?.disable();
        let zip = {
          zip: this.customerInfo && this.customerInfo.office_zip,
          state_name: this.customerInfo && this.customerInfo.office_state,
          city_id: this.customerInfo && this.customerInfo.office_city_id,
          city: this.customerInfo && this.customerInfo.office_city,
        };

        this.getOfficeZipselected(zip);

        // remove other controls
        if (this.customerDetails.controls['empAddress'] !== undefined) {
          // this.customerDetails.removeControl('empZipCode');
          // this.customerDetails.removeControl('empName');
          // this.customerDetails.removeControl('empPhoneNumber');
          // this.customerDetails.removeControl('empAddress');
          // this.customerDetails.removeControl('empAnnualIncome');
          // this.customerDetails.removeControl('empState');
          // this.customerDetails.removeControl('empCity');
        }
      }

      if (i === '1' || i === 1 || i === 2 || i === '2') {
        if (this.customerDetails.controls['empAddress'] !== undefined) {
          // this.customerDetails.removeControl('empZipCode');
          // this.customerDetails.removeControl('empName');
          // this.customerDetails.removeControl('empPhoneNumber');
          // this.customerDetails.removeControl('empAddress');
          // this.customerDetails.removeControl('empAnnualIncome');
        }
        if (i === 0 || i === '0' || i === '2' || i === 2) {
          // this.customerDetails.removeControl('payment_source')
        }
      }
      this.customerDetails.updateValueAndValidity();
    } catch (error) {
      console.log(error);
    }
  }

  validateDateOfBirth(customerDetails: any) {
    return new Promise((resolve, reject) => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      let customerDob = customerDetails.get('customerDob').value;
      customerDob = moment(this.customerDob).format('MM-DD-YYYY');
      console.log(customerDob, 'customerDob');

      let valueSet: any;
      if (!customerDob || customerDob.trim() === '') {
        resolve({ futureDate: true });
        return;
      }

      if (customerDob.includes('-') || customerDob.includes('/')) {
        const dobParts = customerDob.split(/[-/]/);
        const change =
          dobParts[0].padStart(2, '0') +
          '/' +
          dobParts[1].padStart(2, '0') +
          '/' +
          dobParts[2];
        valueSet = change;
      } else {
        const dobParts = [
          customerDob.slice(0, 2),
          customerDob.slice(2, 4),
          customerDob.slice(4),
        ];
        const change = dobParts.join('/');
        valueSet = change;
        // customerDetails.controls.customerDob.setValue(change);
      }

      const dobParts = valueSet && valueSet.split('/');
      const month = parseInt(dobParts[0]);
      const day = parseInt(dobParts[1]);
      const year = parseInt(dobParts[2]);
      if (
        isNaN(month) ||
        isNaN(day) ||
        isNaN(year) ||
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31 ||
        year < 1900 ||
        year > currentYear
      ) {
        resolve({ futureDate: true });
        return;
      }

      const dobDate = new Date(year, month - 1, day);

      if (
        dobDate.getDate() !== day ||
        dobDate.getMonth() + 1 !== month ||
        dobDate.getFullYear() !== year
      ) {
        resolve({ futureDate: true });
        return;
      }

      if (dobDate > currentDate) {
        resolve({ futureDate: true });
        return;
      }

      const minDobDate = new Date(
        currentYear - 120,
        currentMonth - 1,
        currentDay
      );
      if (dobDate < minDobDate) {
        resolve({ futureDate: true });
        return;
      }

      resolve({ futureDate: false });
      // 04/23/1904
    });
  }
  omit_special_char(event: { charCode: any }) {
    let k;
    k = event.charCode; //k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  enterNumberOnly(event: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  async add_address(data: any) {
    if (!data) {
    } else {
      return new Promise((resolve, reject) => {
        this._api
          .api(data, `add_address`, 'post')
          .subscribe((response: any) => {
            if (!response.is_error) {
              if (response && response.data) {
                if (
                  response &&
                  response.data &&
                  response.data.office_data &&
                  response.data.office_data[0]
                ) {
                  this.office_zipObj =
                    response &&
                    response.data &&
                    response.data.office_data &&
                    response.data.office_data[0];
                  this.getOfficeZipselected(this.office_zipObj);
                }
                if (
                  response &&
                  response.data &&
                  response.data.user_data &&
                  response.data.user_data[0]
                ) {
                  this.zipCode =
                    response &&
                    response.data &&
                    response.data.office_data &&
                    response.data.user_data[0];
                  this.getZipselected(this.zipCode);
                }
                if (
                  response &&
                  response.data &&
                  response.data.emp_data &&
                  response.data.emp_data[0]
                ) {
                  this.empZipCodeData =
                    response &&
                    response.data &&
                    response.data.office_data &&
                    response.data.emp_data[0];
                  this.getEmpZipselected(this.empZipCodeData);
                }
              }
              resolve(true);
            } else {
              reject(true);
              console.log(response.override_text);
            }
          });
      });
    }

    return true;
  }

  setvaluesResendCode() {
    // this.approval_code.controls['request_panel_link'].setValue(
    //   this.leads_data.pre_approval_link
    // );
    // this.approval_code
    //   .get('mobile')!
    //   .patchValue(this.leads_data.customer_mobile);
    // this.approval_code.controls['email'].setValue(
    //   this.leads_data.customer_email
    // );

    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customer.modalTypes.approval_code,
        extra_data: {
          country_info: this.country_info,
          link_type: this.link_type,
        },
      },
      class: 'modal-dialog-centered',
    });

    // modal.show();
  }
  mobile_formate(m_number: any) {
    if (m_number.includes('+1-')) {
      m_number = m_number.slice(3);
    }
    m_number = m_number.replaceAll('-', '');
    m_number = m_number.split('');
    m_number.splice(3, 0, '-');
    m_number.splice(7, 0, '-');
    return m_number.join('');
  }

  validate_ssn() {
    const invalid_ssn = [
      '002281852',
      '042103580',
      '062360749',
      '078051120',
      '095073645',
      '128036045',
      '135016629',
      '141186941',
      '165167999',
      '165187999',
      '165207999',
      '212099999',
      '165227999',
      '306302348',
      '165247999',
      '189092294',
      '308125070',
      '212097694',
      '549241889',
      '468288779',
    ];

    if (this.customerDetails.get('socailSecuityNumber')!.value) {
      let patient_ssn = String(
        this.customerDetails.get('socailSecuityNumber')!.value
      );
      if (
        patient_ssn.trim().length != 9 ||
        patient_ssn.slice(5, 9) == '0000' ||
        patient_ssn.slice(3, 5) == '00' ||
        /^(.)\1+$/.test(patient_ssn) ||
        /[a-zA-Z]/.test(patient_ssn) ||
        patient_ssn.slice(0, 3) == '000' ||
        patient_ssn.slice(0, 3) == '666' ||
        invalid_ssn.indexOf(patient_ssn) >= 0 ||
        (parseInt(patient_ssn) >= 987654320 &&
          parseInt(patient_ssn) <= 987654329)
      ) {
        this.customerDetails
          .get('socailSecuityNumber')!
          .setErrors({ invalidSSN: true });
        this.customerDetails.updateValueAndValidity();
      }
    }
  }

  verifyFullSSN() {
    if (!this.fullSSNForm.value.password) {
      this._shared.customPopups('Please enter a valid password.', 2);
      return false;
    }
    if (!this.fullSSNForm.value.note) {
      this._shared.customPopups('Please enter a valid note.', 2);
      return false;
    }
    let params: any = {
      application_id: this.leads_data.application_id,
      admin_password: this.fullSSNForm.value.password,
      note: this.fullSSNForm.value.note,
    };
    this._api.api(params, 'view_complete_ssn_leads', 'post').subscribe(
      (data: any) => {
        if (data.is_error == 0) {
          this.show_full_ssn = true;
          // modal.hide();
          this.load_notes = false;
        } else {
          if (data.override_text || data.err) {
            this._shared.customPopups(data.override_text, 2);
          } else {
            this._shared.customPopups(data.is_error, 2);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
    return true;
  }

  updateMasking(format: '') {
    format = format ? format : '';
    this.phone_mask = format.replace(/#/g, '0');
  }

  getOfficeZipselected(zipObj: any, from_google_api = 0) {
    if (zipObj) {
      this.office_zipObj = zipObj;
      this.office_zip = zipObj.zip;
      this.customerDetails.controls['office_zip'].setValue(
        this.office_zipObj.zip || ''
      );
      this.customerDetails.controls['office_state'].setValue(
        this.office_zipObj.state_name || ''
      );
      this.customerDetails.controls['office_city'].setValue(
        this.office_zipObj.city || ''
      );
      this.customerDetails.controls['office_city_id'].setValue(
        this.office_zipObj.city_id || ''
      );
    } else {
      this.office_zip = '';
      this.customerDetails.controls['office_zip'].setValue('');
      this.customerDetails.controls['office_state'].setValue('');
      this.customerDetails.controls['office_city'].setValue('');
      this.customerDetails.controls['office_city_id'].setValue('');
    }
  }

  getZipselected(zipObj: string, from_google_api = 0) {
    try {
      this.zipCode = zipObj;
      if (zipObj === '') {
        this.zipPostalCode = '';
        this.customerDetails.controls['zipPostalCode'].setValue('');
        this.customerDetails.controls['city'].setValue('');
        this.customerDetails.controls['state'].setValue('');
        this.customerDetails.markAsDirty();
      } else {
        this.zipPostalCode = this.zipCode.zip;
        this.customerDetails.controls['zipPostalCode'].setValue(
          this.zipCode.zip
        );
        this.customerDetails.controls['state'].setValue(
          this.zipCode.state_name
        );
        this.customerDetails.controls['city'].setValue(this.zipCode.city);
        this.customerDetails.markAsDirty();
      }
    } catch (err) {
      console.log(err);
    }
  }

  getEmpZipselected(zipObj: any, from_google_api = 0) {
    if (zipObj == '') {
      this.empZipCode = '';
      this.customerDetails.controls['empZipCode']!.setValue('');
      this.customerDetails.controls['empState']!.setValue('');
      this.customerDetails.controls['empCity']!.setValue('');
    } else {
      this.empZipCode = zipObj.zip;
      this.empZipCodeData = zipObj;
      this.customerDetails.controls['empZipCode'].setValue(zipObj.zip);
      this.customerDetails.controls['empState'].setValue(zipObj.state_name);
      this.customerDetails.controls['empCity'].setValue(zipObj.city);
    }
  }

  fullSSNModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customer.modalTypes.fullSSNForm,
        extra_data: {},
      },
      class: 'modal-dialog-centered',
    });
    //modelRef.content?.passEntry.subscribe((inputValue) => {
  }

  resendLinkModal() {
    const modelRef = this.modalService.show(CustomerModalComponent, {
      initialState: {
        modalType: this._customer.modalTypes.fullSSNForm,
        extra_data: {},
      },
      class: 'modal-dialog-centered',
    });
    //modelRef.content?.passEntry.subscribe((inputValue) => {
  }
}
