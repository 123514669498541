<div class="p-3" *ngIf="contract">
    <h3 class="m-0 fs-24 fw-700 text-secondary mb-3">Personal Information(Contract ID:{{contract.contract_code}})
    </h3>
    <div class="card p-4">
        <div class="d-flex align-items-center mb-3">
            <span class="fs-20 fw-600">Customer Information &nbsp;&nbsp;</span>
            <span class="text-danger fs-14 fw-600">(Note: </span>&nbsp;
            <span class="fs-14 fw-400 m-0">Please check the checkboxes below if you want to update the same
                information in customer profile.)</span>
        </div>
        <form [formGroup]="editPersonalInfoForm">
            <div class="row ">
                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Customer First Name*</label>
                    <input type="text" required formControlName="patient_first_name" class="input_field" trim="blur">
                    <span class="mat-error text-danger w-100 d-block mb-0 fs-14"
                        *ngIf="editPersonalInfoForm.controls['patient_first_name'].touched && editPersonalInfoForm.controls['patient_first_name'].invalid">
                        Please enter valid customer first name.
                    </span>
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Customer Last Name*</label>
                    <input type="text" required formControlName="patient_last_name" name="patient_last_name"
                        class="input_field" maxlength="30" minlength="2" trim="blur">
                    <span class="mat-error text-danger w-100 d-block mb-0 fs-14"
                        *ngIf="editPersonalInfoForm.controls['patient_last_name'].touched && editPersonalInfoForm.controls['patient_last_name'].invalid">
                        Please enter valid customer last name.
                    </span>
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Customer Date of Birth</label>
                    <input formControlName="date_of_birth" class="input_field" bsDatepicker [bsConfig]="dateConfig"
                        [maxDate]="maxDate">
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Social Security Number <span
                            *ngIf="contract.patient_ssn">({{editPersonalInfoForm.value.patient_ssn}})</span></label>
                    <input type="text" formControlName="patient_ssn" name="patient_ssn" class="input_field"
                        [maxlength]="9" [minlength]="9" trim="blur" readonly>
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Customer Email Address*</label>
                    <input type="email" class="input_field" required formControlName="patient_email"
                        name="patient_email" readonly>
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Phone Number</label>
                    <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                        <span
                            class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center">{{
                            contract.patient_mobile_country_code }}</span>
                        <input type="text" class="input_field border-0 border-start m-0 flex-fill rounded-0"
                            formControlName="patient_mobile" name="patient_mobile">

                    </div>
                    <span class="mat-error text-danger w-100 d-block mb-0 fs-14"
                        *ngIf="editPersonalInfoForm.controls['patient_mobile'].touched && editPersonalInfoForm.controls['patient_mobile'].invalid">
                        Please enter valid phone number
                    </span>
                </div>

                <div class="col-sm-12 mb-2">
                    <label class="fs-14 fw-400 mb-0">Address</label>
                    <app-google-zip class="w-100" (setAddress)="getZipselected($event)" id="zipPostalCode"
                        name="zipPostalCode" [address]="editPersonalInfoForm.controls['patient_address'].value">
                    </app-google-zip>
                    <span class="mat-error text-danger w-100 d-block mb-0 fs-14"
                        *ngIf="editPersonalInfoForm.controls['patient_address'].touched && editPersonalInfoForm.controls['patient_address'].invalid">
                        Please enter valid address
                    </span>
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">Zip / Postal Code</label>
                    <input type="text" class="input_field" formControlName="patient_zip" name="zip" readonly
                        trim="blur">
                </div>

                <div class="col-sm-4 mb-2">
                    <label class="fs-14 fw-400 mb-0">State / Province</label>
                    <input type="text" class="input_field" formControlName="patient_state" name="patient_state" readonly
                        trim="blur">
                </div>

                <div class="col-sm-4 mb-2">
                    <div class="">
                        <label class="fs-14 fw-400 mb-0">City</label>
                        <input type="text" class="input_field" formControlName="patient_city" readonly trim="blur">
                    </div>
                </div>

                <div class="col-sm-6 mb-2">
                    <label class="fs-14 fw-400 mb-0">Note</label>
                    <textarea trim="blur" class="input_field" required rows="3" formControlName="notes"
                        name="note_follow_up"></textarea>
                    <span class="mat-error text-danger w-100 d-block mb-0 fs-14"
                        *ngIf="editPersonalInfoForm.controls['notes'].touched && editPersonalInfoForm.controls['notes'].invalid">
                        Please enter valid notes
                    </span>
                </div>
            </div>


            <div class="mt-3">
                <input class="secondary-checkbox" type="checkbox" name="checkbox"
                    formControlName="customer_profile_update" id="flexCheckChecked">
                <label for="flexCheckChecked" class="fs-14 fw-500">
                    Update the same information in Customer Profile.
                </label>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <div class="mb-2">
                    <input class="secondary-checkbox" type="checkbox" name="checkbox1"
                        formControlName="all_customer_profile_update" id="flexCheckChecked1">
                    <label for="flexCheckChecked1" class="fs-14 fw-500">
                        Update the same information in the other Contracts of this customer.
                    </label>
                </div>

                <div class="">
                    <button type="button" class=" btn btn-outline m-1" (click)="location.back()">Cancel</button>
                    <button type="submit" class=" btn btn-primary m-1"
                        (click)="updateContractPersonalInfo()">Save</button>
                </div>
            </div>
        </form>

    </div>
</div>