<div class="btn-group mb-2" dropdown>
    <button id="button-basic" dropdownToggle type="button" class="btn btn-primary rounded dropdown-toggle"
        aria-controls="dropdown-basic">
        Export <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu animated flipInX custom-dropdown"
        role="menu" aria-labelledby="button-basic">
        <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportReport(0)">{{label}} XLSX</button>
        </li>
        <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportReport(1)">{{label}} CSV</button>
        </li>
      </ul>
    </div>