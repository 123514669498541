import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/components/shared/shared.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { ContractService } from '../contracts.service';
import { MainService } from 'src/app/components/shared/main.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { Filters } from 'src/app/components/shared/interface.component';
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
    selector: 'app-settled-contracts',
    templateUrl: './settled-contracts.component.html',
    styleUrls: ['./settled-contracts.component.scss']
})
export class SettledContractsComponent implements OnInit, OnDestroy {
    contracts: any;
    resData: any = {
        pageNumber: 1,
        totalRecords: 0
    };
    reqData: any = {
        payment_type: 0,
        payment_from: 1,
        financing_type: 0
    };

    sortValue: { by: string, order: string, label: string } = {
        by: 'date_added',
        order: 'DESC',
        label: 'Registration Date'
    };
    params: any
    users: any = []
    show_filters: boolean = false
    public filters: Array<Filters> = [];
    sort: any = 1;
    search_string = '';
    is_active: any = ""
    timezone: any = this._shared.getTimezone();
    timezoneName: any = this._shared.getTimezoneName();
    timezone_subscription: any;
    country_id: any = this._local.customGetItem('country_id');
    display_logo: any;
    country_subscription: any;
    paramSubscription: any = {};

    constructor(
        private datePipe: DatePipe,
        public mainService: MainService,
        public router: Router,
        public route: ActivatedRoute,
        public filterService: FiltersService,
        public _shared: SharedService,
        public _local: localStorageService,
        public _api: ApiService
    ) { }

    ngOnInit() {
        this.filters = this.filterService.SettlementListingArray();
        this.paramSubscription = this.route.queryParams.subscribe((data: any) => {
            if (data) {
                this.is_active = data.is_active
                this.show_filters = data.showFilterTab ? data.showFilterTab : this.show_filters;
                this.search_string = data.search ? data.search : this.search_string
                this.sortValue.by = data.by ? data.by : 'date_added'
                this.sortValue.order = data.order ? data.order : 'DESC'
                this.reqData.payment_type = data.pT && parseInt(data.pT) ? parseInt(data.pT) : 2;
                this.reqData.financing_type = data.fT && parseInt(data.fT) ? parseInt(data.fT) : 0;
                this.resData.pageNumber = data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
                this.timezone = data.timezone;
                this.timezoneName = this._shared.getTimezoneName();
                this.country_id = data.selected_country;
                this.getSetlledContracts();
                this.fetchDisplayLogo();
            }
        });
        // this.country_subscription = this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
        //     if (isDummy >= 0 || (selected_country && selected_country.length > 0)) {
        //         this.timezone = timezone;
        //         this.timezoneName = this._shared.getTimezoneName();
        //         this.country_id = selected_country;
        //         this.resData.pageNumber = 1;
        //         this.getSetlledContracts();
        //         this.fetchDisplayLogo();
        //     }
        // });
    }

    getSetlledContracts() {
        let data: any = {
            offset: (this.resData.pageNumber - 1) * 10,
            limit: 10,
            flag: this.reqData.financing_type,
            payment_type: this.reqData.payment_type,
            payment_from: this.reqData.payment_from,
            sort_by: this.sortValue.by,
            sort_order: this.sortValue.order,
            is_active: this.is_active,
            country_id: this.country_id
        };
        if (this.search_string) {
            data['search_string'] = this.search_string
        }
        this._api.api(data, 'finance/get_contract_settlement_payments', 'get')
            .subscribe((data: any) => {
                if (data && data.flag == 200) {
                    this.contracts = data.data
                    this.resData.totalRecords = data.number_of_records;
                    this.resData.number_of_pages = data.number_of_pages;
                } else {
                    this._shared.customPopups(data.original_text, data.flag);
                }
            });
    }

    pageChanged(event: any) {
        this.resData.pageNumber = event.page;
        this.router.navigate(['/app/contracts/settle-payments'], { queryParams: { page: this.resData.pageNumber }, queryParamsHandling: 'merge' });
    }


    filterValues(event: any) {
        this.is_active = event.data.is_active
        this.resData.pageNumber = 1;
        if (event.data.financing_type == 7) {
            event.data.financing_type = 4;
            event.data.ez_plan_new = 1;
        } else {
            event.data.ez_plan_new = 0;
        }
        this.reqData.financing_type = event.data.financing_type;
        this.reqData.payment_type = event.data.payment_type;
        this.sort = event.data.sortBy;
        this.search_string = event.data.search;
        switch (parseInt(this.sort)) {
            case 1:
                this.sortValue = { by: 'date_added', order: 'DESC', label: 'Old Registered contracts first' };
                break;
            case 2:
                this.sortValue = { by: 'date_added', order: 'ASC', label: 'Newly Registered contracts first' };
                break;
            case 3:
                this.sortValue = { by: 'remaining_amount', order: 'DESC', label: 'High to Low Remaining Amount' };
                break;
            case 4:
                this.sortValue = { by: 'remaining_amount', order: 'ASC', label: 'Low to High Remaining Amount' };
                break;
            default:
                this.sortValue = { by: 'date_added', order: 'DESC', label: 'Registration Date' };
                break;
        }
        this.modifyQueryParams();
    }

    resetFilters(event: any) {
        this.resData.pageNumber = 1;
        this.reqData.financing_type = 0;
        this.reqData.payment_type = 0;
        this.sortValue = { by: 'date_added', order: 'DESC', label: 'Registration Date' };
        this.sort = 1;
        this.search_string = '';
        this.is_active = 0;
        this.modifyQueryParams();
    }


    modifyQueryParams() {
        this.router.navigate(["/app/contracts/settle-payments"], {
            queryParams: {
                page: this.resData.pageNumber,
                fT: this.reqData.financing_type,
                pT: this.reqData.payment_type,
                by: this.sortValue.by,
                order: this.sortValue.order,
                sortBy: this.sort,
                search: this.search_string,
                showFilterTab: this.show_filters,
                is_active: this.is_active,
                ez_plan: this.reqData.ez_plan_new
            },
            queryParamsHandling: "merge"
        });
    }

    showFilter(value: any) {
        this.show_filters = value;
    }

    exportXLSX(ext: any) {
        let data = {
            status: this.reqData.financing_type,
            payment_type: this.reqData.payment_type,
            payment_from: this.reqData.payment_from,
            offset: 0,
            limit: this.resData.totalRecords,
            // is_dummy: this.is_dummy,
            sort_by: this.sortValue.by,
            sort_order: this.sortValue.order,
            search_string: this.search_string
        };
        this._api.api(data, 'finance/get_contract_settlement_payments', 'get')
            .subscribe((data: any) => {
                let list: any = [];
                let settled_payments = data.data;
                console.log(settled_payments, "hello");

                list[0] = {
                    // contract_id: 'Contract ID',
                    // doctor_name: 'Business Owner Name',
                    // practice_name: 'Business Name',
                    // agent_name: 'Agent Name',
                    // agent_email: 'Agent Email',
                    // partner_name: 'Partner Name',
                    // patient_name: 'Customer Name',
                    // patient_email: 'Customer Email',
                    // patient_mobile: 'Customer Mobile',
                    // patient_address: 'Customer Address',
                    // date_added: 'Contract Sign Up Date',
                    // financed_amount: 'Finance Amount($)',
                    // treatment_amount: 'Service Amount($)',
                    // number_of_payments: 'Number of Payments',
                    // missing_payments: 'Number of Missing Payments',
                    // remaining_payments: 'Number of Remaining Payments',
                    // recurring_amount: 'Recurring Payment($)',
                    // downpayment_amount: 'Down Payment Amount($)',
                    // interest_rate: 'Interest Rate(%)',
                    // settlement_amount: 'Settlement Amount($)',
                    // settlement_date: 'Settlement Date',
                    // paid_to_doctor: 'Paid to Business($)',
                    // can_be_paid: 'Remaining Balance($)',
                    // is_guaranteed: 'Protect Payment(Yes/No)',
                    // payment_status: 'Contract Status',
                    // next_payment_date: 'Next Recurring Payment Date',
                    // speciality: 'Specialty',
                    // bankrupt_stop_payment: 'Bankruptcy Filed',
                    // contract_type: 'Contract Type'
                    contract_code: 'Contract Code',
                    contract_type: 'Contract Type',
                    patient_name: 'Customer Name',
                    patient_email: 'Customer Email',
                    doctor_name: 'Business Owner Name',
                    financed_amount: 'Financed Amount',
                    downpayment_amount: 'Downpayment Amount',
                    number_of_payments: 'Total Number Of Payment',
                    paid_amount: 'Paid Amount',
                    paid_payments: 'Paid Payment',
                    remaining_amount: 'Remaining Amount',
                    remaining_payments: 'Remaining Payments',
                    patient_payoff_amount: 'Payoff Amount',
                    payment_id_amount: 'Amount',
                    date_added: 'Payment Date',
                    denfits_doctor_name: 'Settled By',
                    // date_added: 'Contract Sign Up Date',
                    // doctor_id: 'Doctor ID',
                    // settlement_amount: 'Settlement Amount'
                };

                for (var i = 0; i < settled_payments.length; i++) {
                    list[i + 1] = {};
                    list[i + 1].contract_code = settled_payments[i].contract_code;
                    list[i + 1].contract_type = settled_payments[i].contract_type == 1 ? '10% Platform Fee' : settled_payments[i].contract_type == 3 ? ' Max Mutual Financing' : 'Secure Mutual Financing';
                    list[i + 1].patient_name = settled_payments[i].patient_name ? settled_payments[i].patient_name : "-";
                    list[i + 1].patient_email = settled_payments[i].patient_email ? settled_payments[i].patient_email : "-";
                    list[i + 1].doctor_name = settled_payments[i].doctor_name ? settled_payments[i].doctor_name : "";
                    list[i + 1].financed_amount = settled_payments[i].financed_amount;
                    list[i + 1].downpayment_amount = settled_payments[i].downpayment_amount;
                    list[i + 1].number_of_payments = settled_payments[i].number_of_payments;
                    list[i + 1].paid_amount = settled_payments[i].paid_amount;
                    list[i + 1].paid_payments = settled_payments[i].paid_payments;
                    // list[i + 1].date_added = this.datePipe.transform(settled_payments[i].date_added, 'MM-dd-yyyy', this.timezone);
                    // list[i + 1].doctor_id = settled_payments[i].doctor_id;
                    list[i + 1].remaining_amount = settled_payments[i].remaining_amount;
                    list[i + 1].remaining_payments = settled_payments[i].remaining_payments;
                    list[i + 1].patient_payoff_amount = settled_payments[i].patient_payoff_amount;
                    // list[i + 1].settlement_amount = settled_payments[i].settlement_amount;
                    list[i + 1].payment_id_amount = settled_payments[i].payment_id_amount ? settled_payments[i].payment_id_amount : "-";
                    list[i + 1].date_added = this.datePipe.transform(settled_payments[i].date_added, 'MM-dd-yyyy', this.timezone);
                    list[i + 1].denfits_doctor_name = settled_payments[i].denfits_doctor_name ? settled_payments[i].denfits_doctor_name : "-";

                    // list[i + 1].doctor_name = (settled_payments[i].doctor_title ? settled_payments[i].doctor_title + ' ' : '') + settled_payments[i].doctor_first_name + " " + settled_payments[i].doctor_last_name;
                    // list[i + 1].practice_name = settled_payments[i].practice_name || '-';
                    // list[i + 1].agent_name = settled_payments[i].agent_title ? settled_payments[i].agent_title + ' ' + settled_payments[i].agent_name : (settled_payments[i].agent_name || '-');
                    // list[i + 1].agent_email = settled_payments[i].agent_email || '-';
                    // list[i + 1].partner_name = settled_payments[i].partner_title ? settled_payments[i].partner_title + ' ' + settled_payments[i].partner_name : (settled_payments[i].partner_name || '-');
                    // list[i + 1].patient_name = (settled_payments[i].title ? settled_payments[i].title + ' ' : '') + settled_payments[i].patient_first_name + " " + settled_payments[i].patient_last_name;
                    // if (settled_payments[i].dnd == 1 && this._shared.routerAuth.restricted_buttons.show_contact_in_dnd) {
                    //     list[i + 1].patient_email = settled_payments[i].patient_email ? (settled_payments[i].patient_email.slice(0, 1) + "****************" + settled_payments[i].patient_email.slice(-1)) : "N.A.";
                    // } else {
                    //     list[i + 1].patient_email = settled_payments[i].patient_email;
                    // }

                    // if (settled_payments[i].dnd == 1 && this._shared.routerAuth.restricted_buttons.show_contact_in_dnd) {
                    //     list[i + 1].patient_mobile = settled_payments[i].patient_mobile ? (settled_payments[i].patient_mobile.substr(3, 1) + ' * * * * * * * * ' + settled_payments[i].patient_mobile.substr(12, 1)) : "N.A.";
                    // } else {
                    //     if (ext == 0) {
                    //         list[i + 1].patient_mobile = settled_payments[i].patient_mobile ? settled_payments[i].patient_mobile : '-';
                    //     } else {
                    //         list[i + 1].patient_mobile = settled_payments[i].patient_mobile ? settled_payments[i].patient_mobile.substring(3) : '-';
                    //     }
                    // }
                    // list[i + 1].patient_address = settled_payments[i].patient_address;
                    // list[i + 1].date_added = this.datePipe.transform(settled_payments[i].date_added, 'MM-dd-yyyy', this.timezone);
                    // list[i + 1].financed_amount = settled_payments[i].financed_amount;
                    // list[i + 1].treatment_amount = settled_payments[i].treatment_amount;
                    // list[i + 1].number_of_payments = settled_payments[i].number_of_payments;
                    // list[i + 1].missing_payments = settled_payments[i].missing_payments;
                    // list[i + 1].remaining_payments = settled_payments[i].remaining_payments;
                    // list[i + 1].recurring_amount = settled_payments[i].recurring_amount;
                    // list[i + 1].downpayment_amount = settled_payments[i].downpayment_amount;
                    // list[i + 1].interest_rate = settled_payments[i].interest_rate_to_show || settled_payments[i].interest_rate;
                    // list[i + 1].settlement_amount = settled_payments[i].settlement_amount;
                    // list[i + 1].settlement_date = this.datePipe.transform(settled_payments[i].settlement_date, 'MM-dd-yyyy', this.timezone);
                    // list[i + 1].paid_to_doctor = settled_payments[i].paid_to_doctor.toFixed(2);
                    // list[i + 1].can_be_paid = settled_payments[i].can_be_paid.toFixed(2);
                    // list[i + 1].is_guaranteed = (settled_payments[i].is_guaranteed == 1 || settled_payments[i].is_guaranteed == 0) ? 'Yes' : 'No';
                    // if (settled_payments[i].pf_status < 3) {
                    //     list[i + 1].payment_status = 'Active'
                    // }
                    // if (settled_payments[i].pf_status == 3) {
                    //     list[i + 1].payment_status = 'Overdue'
                    // }
                    // if (settled_payments[i].pf_status == 4) {
                    //     list[i + 1].payment_status = 'Completed'
                    // }
                    // if (settled_payments[i].pf_status == 5) {
                    //     list[i + 1].payment_status = 'Cancelled'
                    // }

                    // list[i + 1].next_payment_date = this.datePipe.transform(settled_payments[i].next_payment_date, 'MM-dd-yyyy', '+0000');
                    // list[i + 1].speciality = settled_payments[i].speciality_name || '-'
                    // list[i + 1].bankrupt_stop_payment = settled_payments[i].bankrupt_stop_payment == 1 ? 'Yes' : 'No';
                    // list[i + 1].contract_type = settled_payments[i].contract_type == 1 ? '10% Platform Fee' : settled_payments[i].contract_type == 3 ? ' Max Mutual Financing' :'Secure Mutual Financing';
                }
                if (ext == 0) {
                    this._shared.exportAsExcelFile(list, 'contract_settlement_payments', '.xlsx');
                } else if (ext == 1) {
                    this._shared.exportAsExcelFile(list, 'contract_settlement_payments', '.csv');
                }
            }, (error: any) => {
                console.log(error);
            })
    }

    ngOnDestroy(): void {
        if (this.country_subscription) {
            this.country_subscription.unsubscribe();
        }
        if (this.paramSubscription){
            this.paramSubscription.unsubscribe();
        }
    }

    fetchDisplayLogo() {
        const adminSelectedCountry = this._local.customGetItem('country_id');
        if (adminSelectedCountry) {
            const country_data = JSON.parse(adminSelectedCountry);
            this.display_logo = country_data['display_logo'];
        }
    }
}
