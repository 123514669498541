import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostBinding,
  Host
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location, DatePipe } from "@angular/common";
import { AddNotesComponent } from "../../../shared/add-notes/add-notes.component";
import * as momentTz from 'moment-timezone';
import { SharedService } from "src/app/components/shared/shared.service";
import { MainService } from "src/app/components/shared/main.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { localStorageService } from "src/app/components/shared/localstorage.service";
import { ApiService } from "src/app/components/shared/api.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { ConfirmationModalComponent } from "src/app/components/shared/confirmation-modal/confirmation-modal.component";
import { DebtRecoveryModalComponent } from "../debt-recovery-modal/debt-recovery-modal.component";
import { DebtRecoveryService } from "../debt-recovery.service";
@Component({
  selector: 'app-debt-recovery-details',
  templateUrl: './debt-recovery-details.component.html',
  styleUrls: ['./debt-recovery-details.component.scss']
})
export class DebtRecoveryDetailsComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  id: any;
  splitInfo: any = [];
  timezone: any = this._shared.getTimezone();
  timezoneName: any = this._shared.getTimezoneName();
  plansDetail: any = [];
  is_ar_view = window.location.pathname.includes('/app/debt-recovery/') ? 1 : 0;
  load_notes = false;
  baseUrlCopyLink: any;
  new_expiry_date = '';
  min_date = new Date(new Date().setDate(new Date().getDate() + 1));
  admin_notes = '';

  constructor(private route: ActivatedRoute,
    public _shared: SharedService,
    public _main: MainService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public router: Router,
    public cd: ChangeDetectorRef,
    public _local: localStorageService,
    public _api: ApiService,
    public _debtRecovery: DebtRecoveryService
  ) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', placement: 'bottom', selectFromOtherMonth: true });
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.route.queryParams.subscribe((data: any) => {
      // if (isDummy >= 0 || (selected_country && selected_country.length > 0)) {
        this.timezone = data.timezone;
        this.timezoneName = this._shared.getTimezoneName();
        // this.getSplitDetails(this.id);
      // }
    });
    this.getSplitDetails(this.id);
  }

  getSplitDetails(id: any) {
    let data = {
      split_req_id: id,
      is_debt_contract: window.location.pathname.includes('/app/contracts/split-details/') ? 0 : 1
    };
    this._api.api(data, "finance/get_debt_recovery_proposal_details", 'get').subscribe(
      (response: any) => {
        if (response.is_error) {
          this._shared.customPopups(response.override_text || response.err, response.is_error);
        } else {
          this.splitInfo = response.split_request_data[0];
          if (this.splitInfo.is_arv2) {
            if (window.location.pathname.includes('/app/contracts/split-details/')) {
              this.router.navigate([`/app/accounts-receivable/details/${this.splitInfo.split_req_id}`]);
            }
          } else {
            if (window.location.pathname.includes('/app/accounts-receivable/details/')) {
              this.router.navigate([`/app/contracts/split-details/${this.splitInfo.split_req_id}`]);
            }
          }
          this.plansDetail = response.split_request_data[0]['plans_array'];
          this.baseUrlCopyLink = response['copy_link_base_url'] || '';
          this.plansDetail.forEach((plan: any) => {
            plan.recurring_amount = parseFloat(plan.recurring_amount);
            plan.recurring_extra_amount = response.split_request_data[0].recurring_extra_amount;
            plan.recurring_extra_percent = response.split_request_data[0].recurring_extra_percent;
            plan.threshold_amount = response.split_request_data[0].threshold_amount;
            plan.threshold_recurring_extra_amount = response.split_request_data[0].threshold_recurring_extra_amount;
            plan.threshold_recurring_extra_percent = response.split_request_data[0].threshold_recurring_extra_percent;
            plan.treatment_amount = response.split_request_data[0].treatment_amount;
            plan.downpayment_amount = response.split_request_data[0].downpayment_amount;

            if (response.split_request_data[0].contract_type === 1) {
              if (response.split_request_data[0] && response.split_request_data[0].payment_reserve_on_pay) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else if (response.split_request_data[0].contract_type === 3) {
              if (response.split_request_data[0] && response.split_request_data[0].payment_reserve_on_pay_new_ten) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else {
              plan.advance_interest_amount = plan.advance_interest_amount ? parseFloat(parseFloat(
                plan.advance_interest_amount
              ).toFixed(2)) : 0;
            }
            plan.month = parseInt(plan.month);
            let fixed_flat_fee = response.split_request_data[0].enrollment_enabled ? 0 : plan.enrollment_fee;
            plan.payable_amount_today = parseFloat(plan.downpayment_amount) + this.getCalculatedTransactionFee(plan, plan.downpayment_amount)
              + parseFloat(plan.advance_interest_amount) + this.getCalculatedTransactionFee(plan, plan.advance_interest_amount)
              + fixed_flat_fee;
          });
          this.load_notes = false;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  GetTransactionFee(contract: any, comparable_value: any) {
    comparable_value = parseFloat(comparable_value);
    contract.recurring_extra_amount = parseFloat(contract.recurring_extra_amount);
    contract.recurring_extra_percent = parseFloat(contract.recurring_extra_percent);
    contract.threshold_amount = parseFloat(contract.threshold_amount);
    contract.threshold_recurring_extra_amount = parseFloat(contract.threshold_recurring_extra_amount);
    contract.threshold_recurring_extra_percent = parseFloat(contract.threshold_recurring_extra_percent);
    let text = '';
    if (comparable_value < contract.threshold_amount) {
      if (contract.recurring_extra_percent) {
        text += `${parseFloat(contract.recurring_extra_percent).toFixed(2)}%`
      }
      if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.recurring_extra_amount) {
        text += `$${parseFloat(contract.recurring_extra_amount).toFixed(2)}`
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        text += `${parseFloat(contract.threshold_recurring_extra_percent).toFixed(2)}%`
      }
      if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.threshold_recurring_extra_amount) {
        text += `$${parseFloat(contract.threshold_recurring_extra_amount).toFixed(2)}`
      }
    }
    return text;
  }

  getCalculatedTransactionFee(contract: any, comparable_value: any) {
    comparable_value = parseFloat(comparable_value);
    contract.recurring_extra_amount = parseFloat(contract.recurring_extra_amount);
    contract.recurring_extra_percent = parseFloat(contract.recurring_extra_percent);
    contract.threshold_amount = parseFloat(contract.threshold_amount);
    contract.threshold_recurring_extra_amount = parseFloat(contract.threshold_recurring_extra_amount);
    contract.threshold_recurring_extra_percent = parseFloat(contract.threshold_recurring_extra_percent);
    let transaction_fee = 0;
    if (comparable_value === 0) {
      return 0
    } else if ((comparable_value < contract.threshold_amount) || (contract.threshold_amount === undefined && contract.recurring_extra_amount !== undefined)) {
      if (contract.recurring_extra_percent) {
        transaction_fee += (comparable_value * contract.recurring_extra_percent) / 100;
      }
      if (contract.recurring_extra_amount) {
        transaction_fee += contract.recurring_extra_amount;
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        transaction_fee += (comparable_value * contract.threshold_recurring_extra_percent) / 100;
      }
      if (contract.threshold_recurring_extra_amount) {
        transaction_fee += contract.threshold_recurring_extra_amount;
      }
    }
    return transaction_fee;
  }

  addContractNotesModal() {
    let tempdata = {
      assign_type: 6,
      proposal_info: [{ contract_patient_token: this.splitInfo.contract_patient_token }]
    };
    let initialState = {
      data: tempdata
    };
    this._main.noteadded = false;
    this.bsModalRef = this.modalService.show(AddNotesComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this._main.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getSplitDetails(this.id);
          count = 1;
        }
      }
    })
  };

  stop_resume_reminder(eve: any, stop_to: any) {
    let is_stop = eve.target.checked ? 0 : 1
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to ${is_stop == 1 ? 'stop' : 'Resume'} this proposal reminder ${stop_to == 1 ? 'Email' : 'SMS'} ?`,
          modal_heading: 'Confirmation',
        },
      },
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      if (inputValue === '' || inputValue.length == 0) {
        return false;
      }
      if (inputValue && inputValue.success) {
        let data = {
          split_req_id: this.splitInfo.split_req_id,
          notes: inputValue.notes,
          stop_reminder: is_stop,
          stop_email: stop_to == 1 ? 1 : null,
          stop_sms: stop_to == 2 ? 1 : null
        };
        this._api.api(data, 'business/stop_resume_proposal_reminder')
          .subscribe((data: any) => {
            this.getSplitDetails(this.id);
          }, (error: any) => {
            console.log(error);
          })
        return true;
      } else {
        let temp = this.splitInfo;
        this.splitInfo = '';
        setTimeout(() => {
          this.splitInfo = temp;
        }, 0);
        return true;
      }
    });

  }

  goToContract(contract_id: any) {
    this.router.navigate(['/app/contracts/details/' + contract_id]);
  }

  goToPaymentProcess(PPr_id: any) {
    this.router.navigate(['/app/accounts-receivable/payment/' + PPr_id]);
  }

  gotopaymentLink() {
    window.open(`${this.splitInfo.ar_payment_link}${this.splitInfo.contract_patient_token}`, "_blank");
  }

  toggleBlockContractForOverdue() {
    let params = {
      access_token: localStorage.getItem('adminToken'),
      split_req_id: this.splitInfo['split_req_id'],
      skip_overdue_contract_check: this.splitInfo.skip_overdue_contract_check ? 1 : 0,
    };
    this._api.api(params, 'edit_split_overdue_check')
      .subscribe((data: any) => {
      }, (error: any) => {
        console.log(error)
      })
  }

  editPaymentSettings(modal: any) {
    let params = {
      split_req_id: this.splitInfo['split_req_id'],
      primary_type: this.splitInfo['selected_payment_matrix']['primary_type'],
      backup_type: this.splitInfo['selected_payment_matrix']['backup_type'],
      recurring_type: this.splitInfo['selected_payment_matrix']['recurring_type'],
      backup_required: this.splitInfo['selected_payment_matrix']['backup_required'] ? 1 : 0,
    };
    this._api.api(params, 'business/edit_split_payment_settings')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        if (data.is_error == 0) {
          this.getSplitDetails(this.id);
          modal.hide();
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  resendEmail(modal: any) {
    let params = {
      access_token: localStorage.getItem('adminToken'),
      split_req_id: this.splitInfo.split_req_id,
      doctor_id: this.splitInfo.doctor_id
    };
    this._api.api(params, 'resend_split_plans')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        if (data.is_error == 0) {
          if (data.email_sent == 1) {
            this._shared.customPopups('Proposal resend  successfully', 0);
            this.getSplitDetails(this.id);
            modal.hide();
          }
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  deleteRequest() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure you want to delete this proposal?`,
          modal_heading: `Confirmation`
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      // inputValue = inputValue && inputValue.trim();
      if (inputValue === '' || inputValue.length == 0) {
        this._shared.customPopups(' You need to write something! ', 1);
        return false;
      }
      if (inputValue && inputValue.success) {
        let data = {
          split_req_id: this.splitInfo.split_req_id
        };
        this._api.api(data, 'finance/delete_proposal')
          .subscribe((data: any) => {
            this.router.navigate(["/app/debt-recovery/list"])
            // this.getSplitDetails(this.id);
          }, (error: any) => {
            console.log(error);
          })
      }
      return true;
    });
  }

  copyLink() {
    if (this.baseUrlCopyLink) {
      const textToCopy = `${this.baseUrlCopyLink}${this.splitInfo.contract_patient_token}`;
      const isTextCopied = this._main.copyToClipboard(textToCopy);
      // tslint:disable-next-line:max-line-length
      isTextCopied ? this._shared.customPopups('Link copied to clipboard', 0) : this._shared.customPopups('Unable to copy to clipboard', 1);
      return;
    }
  }

  dateOnChanges(e: any) {
    console.log('dateOnChanges', e);
    this.new_expiry_date = e;
  }

  editExpiryDate(modal: any) {
    try {
      const timezone = this._shared.getTimezone();
      this.admin_notes = this.admin_notes && this.admin_notes.trim();
      if (!this.new_expiry_date || !this.admin_notes) {
        // tslint:disable-next-line:max-line-length
        const msg = !this.new_expiry_date && !this.admin_notes ? 'Please enter details' : (!this.new_expiry_date ? 'Please select a date.' : 'Please add notes.');
        return this._shared.customPopups(msg, 2);
      }
      const new_date = momentTz(this.new_expiry_date).tz(timezone).utc().format('YYYY-MM-DD HH:mm:ss');
      const params = {
        expiry_date: new_date,
        admin_notes: this.admin_notes,
        id: this.splitInfo.split_req_id
      };

      this._api.api(params, 'update_expiry_date')
        .subscribe((data: any) => {
          if (data && typeof data === 'string') { data = JSON.parse(data); }
          if (+data['is_error'] === 0) {
            this._shared.customPopups(data.message || 'Expiry date updated successfully', 0);
            this.resetExpiryModalData();
            this.getSplitDetails(this.id);
            modal.hide();
          }
        }, (error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

  }

  resetExpiryModalData() {
    this.new_expiry_date = '';
    this.admin_notes = '';
  }

  ResendPlanModal() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: `Are you sure, you want to resend the proposal to the customer(s)?`,
          hideNotes: true,
          modal_heading: 'Resend Proposal Email',
          data: [{ patient_email: this.splitInfo.patient_email, patient_mobile: this.splitInfo.patient_mobile }]
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      if (inputValue && inputValue.success) {
        let params = {
          split_req_id: this.splitInfo.split_req_id,
          doctor_id: this.splitInfo.doctor_id
        };
        this._api.api(params, 'batch_resend_split_plans')
          .subscribe((data: any) => {
            if (data && typeof data == 'string') data = JSON.parse(data);
            if (data.is_error == 0) {
              if (data.email_sent == 1) {
                this._shared.customPopups('Proposal resend  successfully', 0);
                this.getSplitDetails(this.id);
              }
            }
          }, (error: any) => {
            console.log(error)
          })
      }

    });
  }

  PaymentSettingsModal() {
    const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
      initialState: {
        modalType: this._debtRecovery.modalTypes.PaymentSettingsModal,
        payment_extra_data: {
          label: 'Proposal Status',
          data: [this.splitInfo],
          // view_track_status: this.view_track_status
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      return true;
    });
  }

  editExpiryDateModal() {
    const modelRef = this.modalService.show(DebtRecoveryModalComponent, {
      initialState: {
        modalType: this._debtRecovery.modalTypes.editExpiryDateModal,
        expiry_extra_data: {
          label: `Update Proposal Expiry Date`,
          split_req_id: this.splitInfo.split_req_id
        },
      },
      class: 'modal-dialog-centered',
    });
    modelRef.content?.passEntry.subscribe((inputValue) => {
      if (inputValue && inputValue.success) {
        this.getSplitDetails(this.id)
      }
      return true;
    });
  }
}
