import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-mismatch-cardholder',
  templateUrl: './mismatch-cardholder.component.html',
  styleUrl: './mismatch-cardholder.component.scss'
})
export class MismatchCardholderComponent implements OnInit {

  cardHolderNameMismatchListing: any;
  resData: any = { pageNumber: 1, totalRecords: 20 };
  dataArguements: any = { limit: 10, page: 1, search_string: "" };
  loader: Boolean = true;
  timezone: any;
  timezoneName: any;
  timezone_subscription: any;
  country_id: any;
  paramSubscription: any;
  subscription: any;

  constructor(
    private _shared: SharedService,
    public mainService: MainService,
    private _api: ApiService,
    public filterService: FiltersService,
    private _local: localStorageService,
    private _router: Router,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();
    this.paramSubscription = this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.callListingFunc(data);
      }
    });
    this.subscription = this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
      if (isDummy >= 0 || (selected_country && selected_country.length > 0)) {
        this.timezone = timezone;
        this.country_id = selected_country;
        if (this.resData.pageNumber === 1) {
          this.callListingFunc({ page: 0 });
          return;
        }
      }
    });
  }

  callListingFunc(data: any) {
    this.resData.pageNumber = data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
    this.dataArguements.offset = this.resData.pageNumber ? (this.resData.pageNumber - 1) * 10 : 0;
    this.dataArguements.page = this.resData.pageNumber;
    this.getListings();
  }

  getListings() {
    let data = {
      limit: this.dataArguements.limit,
      offset: (this.resData.pageNumber - 1) * 10,
      country_id: this.country_id || 1,
      page: this.dataArguements.page,

    };
    this._api.api(data, "finance/cardholder_name_mismatch_listings", "get").subscribe(
      (data: any) => {
        this.loader = false;
        this.cardHolderNameMismatchListing = data.list;
        this.resData.totalRecords = data.totalRecords;
      },
      (error: any) => {
        this.loader = false;
      }
    );
  }

  pageChanged(event: any) {
    console.log(event);
    this.resData.pageNumber = event.page;
    this.dataArguements.page = event.page;
    this.dataArguements.offset = (event.page - 1) * 10;
    this._router.navigate(["/app/contracts/mismatch-cardholdername-listing"], {
      queryParams: { page: this.resData.pageNumber },
      queryParamsHandling: "merge"
    });
  }

}
