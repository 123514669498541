import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange, ElementRef } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';

@Component({
    selector: 'app-navsearch',
    templateUrl: './navsearch.component.html',
    styleUrls: ['./navsearch.component.scss']
})
export class NavsearchComponent implements OnInit, OnChanges {

    @Input() visible!: boolean;
    @Output() onclose = new EventEmitter<boolean>();
    term: string = '';
    subscription: any;
    results: any = {
        partners: [],
        patients: [],
        doctors: [],
        contracts: []
    }

    constructor(
        public elem: ElementRef,
        private router: Router
    ) { }

    ngOnInit() {
    }

    closeNavSearch() {
        this.visible = false;
        this.onclose.emit();
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['visible'].currentValue === true) {
            this.elem.nativeElement.querySelector('input').focus();
        }
    }

    handleForm() {
        this.closeNavSearch();
        this.router.navigate(['/app/search'], { queryParams: { search: this.term } });
        this.term = '';
    }
}
