<ng-container *ngIf="modalType == _shared.modalTypes.pictureModal">
    <div class="modal-dialog modal-md">
      <div class="modal-content border-0">
        <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
          <h4 class="modal-title text-white fs-18">Crop Profile Image</h4>
      <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()"  class="cursor hw-24">
      </div>
        <div class="modal-body">
          <div class="image-cropper-container">
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="cropArea">
                      <image-cropper
                        style="max-height: 300px"
                        [imageChangedEvent]="extra_data.imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="1 / 1"
                        [cropperMinHeight]="100"
                        [cropperMinWidth]="100"
                        [output]="'blob'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                      >
                      </image-cropper>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <div class="result" *ngIf="croppedImage">
                      <p class="m-0 fs-14 fw-400">Cropped image</p>
                      <img [src]="croppedImage" class="img-fluid" />
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-primary mb-2" (click)="updateAgentProfile()">
            Save
          </button>
        </div>
      </div>
    </div>
  </ng-container>