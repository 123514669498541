import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { Filters } from 'src/app/components/shared/interface.component';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-disputes',
  templateUrl: './customer-disputes.component.html',
  styleUrl: './customer-disputes.component.scss'
})
export class CustomerDisputesComponent {

  dateRangeValue: any;
  minDate: any = new Date(2024, 0, 1);
  maxDate: any;
  resetBtn: boolean = false;
  search: string = '';
  customer_disputes: any;
  paramSubscription: any;
  is_dummy: any;
  filter: any = {
    start_date: '',
    end_date: '',
    status_filter: -1,
  }

  resData: any = {
    pageNumber: 1
  }
  timezone: any;
  timezoneName: any;
  plansDetail: any = [];
  splitRequest: any;
  show_filters: boolean = false;

  public filters: Array<Filters> = [];

  filter_added: boolean = false;
  selected_req = {};
  skip_overdue_check: any;
  country_id: any;
  subscription: any;


  constructor(
    private _shared: SharedService,


    public mainService: MainService,
    private _api: ApiService,
    public filterService: FiltersService,
    private _local: localStorageService,
    private _router: Router,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.timezoneName = this._shared.getTimezoneName();
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate]
    this.filters = this.filterService.getCustomerDisputesFiltersArray();
    this.subscription = this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
      if (isDummy >= 0 || (selected_country && selected_country.length > 0)) {
        this.timezone = timezone;
        this.is_dummy = isDummy;
        this.country_id = selected_country;
        this.getSplitAppList();
      }
    });

    this.paramSubscription = this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.filter.status_filter = data.status && parseInt(data.status) > -1 ? parseInt(data.status) : -1;
        this.search = data.search ? data.search : ''
        this.resData.pageNumber = data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
        this.dateRangeValue[0] = data.sD ? new Date(new Date(data.sD).toUTCString().substr(0, 25)) : this.minDate;
        this.dateRangeValue[1] = data.eD ? new Date(new Date(data.eD).toUTCString().substr(0, 25)) : this.maxDate;
        this.getSplitAppList();
      }
    });
  }

  getSplitAppList() {
    let params = {

      offset: (this.resData.pageNumber - 1) * 10,
      limit: 10,
      search_string: this.search,
      start_date: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
      status_filter: this.filter.status_filter,
    }

    this._api.api(params, "finance/get_customer_disputes_list", "get").subscribe({
      next: (data: any) => {
        this.customer_disputes = data.disputes;
        this.resData.totalRecords = data.count;
        this.resData.number_of_pages = data.number_of_pages;
      },
      error: (error) => {
        console.log("error", error);

      }
    })
  }

  filterValues(event: any) {
    this.resData.pageNumber = 1;
    this.filter.start_date = this.datePipe.transform(event.data.startDate, "yyyy-MM-dd");
    this.filter.end_date = this.datePipe.transform(event.data.endDate, "yyyy-MM-dd");
    this.filter.status_filter = event.data.status;
    this.search = event.data.search;
    this.modifyQueryParams();
  }


  pageChanged(event: any) {
    this.resData.pageNumber = event.page;
    this.filter.offset = (event.page - 1) * 10;
    this._router.navigate(['/app/contracts/customers-disputed'], { queryParams: { page: this.resData.pageNumber }, queryParamsHandling: 'merge' });
  }
  resetFilters(event: any) {
    this.resData.pageNumber = 1;
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.filter.start_date = this.datePipe.transform(this.dateRangeValue[0], "yyyy-MM-dd");
    this.filter.end_date = this.datePipe.transform(this.dateRangeValue[1], "yyyy-MM-dd");
    this.filter.status_filter = -1;
    this.search = '';
    this.modifyQueryParams();
  }
  modifyQueryParams() {
    this._router.navigate(["/app/contracts/customers-disputed"], {
      queryParams: {
        page: this.resData.pageNumber,
        startDate: this.datePipe.transform(this.filter.start_date, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.filter.end_date, 'yyyy-MM-dd'),
        status: this.filter.status_filter,
        search: this.search,
        showFilterTab: this.show_filters
      },
      queryParamsHandling: "merge"
    });
  }
  showFilter(value: any) {
    this.show_filters = value;
  }

  exportXLSX(ext: any) {
    const _data = {
      offset: 0,

    };
    this._api.api(_data, 'finance/get_customer_disputes_list', "get")
      .subscribe((data: any) => {
        const list: any = [];
        const contracts = data.disputes;
        list[0] = {
          ticket_id: 'Ticket ID',
          ticket_link: 'Ticket Link',
          disputed_date: 'Reported On',
          contract_code: 'Contract ID/Payment ID',
          disputed_reason: 'Problem Due To',
          contract_type: 'Contract Type',
          contract_status: 'Contract Status',
          patient_name: 'Customer Name',
          patient_email: 'Customer Email',
          patient_mobile: 'Customer Mobile',
          pt_name: 'Payment Name',
          amount_deducted: 'Amount Deducted($)',
          payment_date: 'Payment Date',
          reason: 'Notes',
          status: 'Assignment Status',
        };
        for (var i = 0; i < contracts.length; i++) {
          list[i + 1] = {};
          list[i + 1].ticket_id = contracts[i].csr_req_id;
          list[i + 1].disputed_reason = contracts[i].disputed_reason || '-';
          list[i + 1].ticket_link = environment.baseUrl + 'app/users/assignments/' + contracts[i].csr_req_id;
          list[i + 1].disputed_date = this.datePipe.transform(contracts[i].disputed_date, 'MM-dd-yyyy HH:mm a', this.timezone);
          list[i + 1].contract_code = contracts[i].is_ppr == 1 ? contracts[i].payment_process_code : contracts[i].contract_code;
          list[i + 1].contract_type = contracts[i].contract_type == 1 ? '10% Platform Fee' : contracts[i].contract_type == 4 ? (contracts[i].ez_plan_new ? 'ZeroFi Financing' : 'SimpleeFi Financing') : contracts[i].contract_type == 5 ? 'Credee Bill Payment Plan' : 'CredeeFi Financing';
          list[i + 1].contract_status = contracts[i].contract_status || 'N.A.';
          list[i + 1].patient_name = contracts[i].patient_name || 'N.A.';
          list[i + 1].patient_email = contracts[i].patient_email || 'N.A.';
          list[i + 1].patient_mobile = contracts[i].patient_mobile || 'N.A.';
          list[i + 1].amount_deducted = contracts[i].amount_deducted || 'N.A.';
          list[i + 1].pt_name = contracts[i].pt_name || 'Other';
          list[i + 1].reason = contracts[i].reason || '-';
          list[i + 1].payment_date = this.datePipe.transform(contracts[i].payment_date, "MM-dd-yyyy", this.timezone) || "N.A.";
          list[i + 1].status = contracts[i].status == 0 ? 'Pending' : contracts[i].status == 1 ? 'Closed' : contracts[i].status == 2 ? 'Reopen' : contracts[i].status == 3 ? 'Follow Up' : '-'
        }
        if (ext == 0) {
          this._shared.exportAsExcelFile(list, 'customer_problems', '.xlsx');
        }
        else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'customer_problems', '.csv');
        }
      });
  }
}
