<ng-container
    *ngIf="modalType == _contract.modalTypes.bankruptcyModal || modalType == _contract.modalTypes.m2rReportModal">
    <div class="modal-dialog modal-md  modal-dialog-centered">
        <div class="modal-content border-0">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">{{modalType == _contract.modalTypes.bankruptcyModal ?
                        'Bankruptcy Filed' :
                        'Report to credit bureau'}}</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body" *ngIf="extra_data.contract">
                    <div>
                        <span>
                            <input id="disable" formControlName="disableEnable" type="radio" [value]="0"
                                class="secondary-radio">
                            <label for="disable" class="fs-14 fw-400 m-0 me-3">Disable</label>
                        </span>
                        <span>
                            <input id="enable" formControlName="disableEnable" type="radio" [value]="1"
                                class="secondary-radio">
                            <label for="enable" class="fs-14 fw-400 m-0">Enable</label>
                        </span>
                    </div>
                    <div class="mt-3">
                        <label for="" class="fs-14 fw-400 m-0">Notes*</label>
                        <textarea class="input_field" rows="4" trim="blur" formControlName="notes"></textarea>
                        <p class="text-danger fs-14 m-0"
                            *ngIf="form.controls['notes'].invalid && (form.controls['notes'].dirty||form.controls['notes'].touched)">
                            Please enter the notes.
                        </p>
                    </div>
                </div>
                <div class="d-flex p-3 border-top">
                    <button type="button" class="btn btn-outline me-3" (click)="cancel()">Cancel</button>
                    <button type="submit" class="btn btn-primary fw-600" [disabled]="form.invalid">Submit</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.activateModal">
    <div class="modal-dialog modal-md modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Activate extra_data.contract</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <div>
                    <label class="fs-14 fw-400 mb-2">Recurring date (MM/DD/YYYY)*</label>
                    <input readonly placeholder="Click here to choose date"
                        [(ngModel)]="extra_data.contract.revive_payment_date" name="next_payment_date" required
                        type="text" bsDatepicker class="form-control" [minDate]="minDate" [bsConfig]="bsConfig">
                    <!-- <p class="text-danger fs-14 m-0"
                        *ngIf="next_payment_date.invalid && (next_payment_date.touched || next_payment_date.dirty)">
                        *Please choose new recurring date.</p> -->
                </div>
                <div>
                    <label class="fs-14 fw-600 m-0">Note*</label>
                    <textarea required [(ngModel)]="note" name="notes" class="input_field" trim="blur"
                        trim="blur"></textarea>
                    <!-- <p class="text-danger fs-14 m-0" *ngIf="(notes.touched || notes.dirty) && notes.invalid">
                        Please enter note.</p> -->
                </div>
                <div class="text-center py-2"
                    *ngIf="extra_data.contract && extra_data.contract.show_ach==1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='new'">
                    <label class="col-lg-12 text-center fs-14 fw-400 mb-2"> Pay using Customer's connected bank
                        account</label>
                </div>
                <div class="col-12 mr-2 overflow-auto" style="max-height:250px;"
                    *ngIf="extra_data.contract && extra_data.contract.show_ach==1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='new'">
                    <div *ngFor="let card of extra_data.contract.bank_accounts">
                        <div class="row border px-2 py-3 mb-2" *ngIf="card.payment_source_type==1">
                            <p class="m-0 col-sm-6 col-12"><i
                                    class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}}</p>
                            <div class="col-sm-6 col-12 text-end">
                                <span class="m-0 mr-2" style="color:#B45207"
                                    *ngIf="card.source_id == extra_data.contract.secondary_source_id && card.source_id != extra_data.contract.source_id">Backup
                                    Card</span>
                                <a (click)="reviveContractFn(card)" tappable
                                    *ngIf="card.bank_status=='succeeded'"><u>Pay</u></a>
                                <p class="m-0 text-danger fs-14 m-0 " *ngIf="card.bank_status=='reauthenticate'">
                                    Reauthentication Required </p>
                            </div>
                        </div>
                    </div>
                </div>
                <span
                    *ngIf="extra_data.contract && extra_data.contract.show_ach==1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='new'">
                    <b>*Note:</b>
                    <ul class="m-0 p-0">
                        <li>Please make sure you have the appropriate funds available in your account.</li>
                        <li>Processing time may take up to 7 business days.</li>
                    </ul>
                </span>
                <div class="text-center py-2">
                    <p
                        *ngIf="extra_data.contract && extra_data.contract.show_ach==1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='new'">
                        <b>OR</b>
                    </p>
                    <label class="  col-lg-12 text-center"> Pay using Customer's card(s)</label>
                </div>
                <div class="col-12 mr-2 overflow-auto" style="max-height:250px;">
                    <div *ngFor="let card of extra_data.contract.payment_sources">
                        <div class="row border px-2 py-3 mb-2" *ngIf="card.payment_source_type!=1">
                            <p class="m-0 col-sm-6 col-12"><i
                                    class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}</p>
                            <div class="col-sm-6 col-12 text-end">
                                <span class="m-0 mr-2" style="color:#B45207"
                                    *ngIf="card.source_id == extra_data.contract.secondary_source_id && card.source_id != extra_data.contract.source_id">Backup
                                    Card</span>
                                <span class="m-0 col-6 " style="color:#36af47;"
                                    *ngIf="card.source_id==extra_data.contract.source_id">
                                    <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                <a (click)="reviveContractFn(card)" tappable><u>Pay</u></a>
                            </div>
                            <p class="col-12 bg-danger" *ngIf="card.last_error">{{card.last_error||''}}</p>
                        </div>
                    </div>
                </div>
                <!-- <p>Are you sure you want to deduct <b>payoff</b> for this extra_data.contract ?</p> -->
                <span>
                    <b>*Note:</b>
                    <ul class="m-0 p-0">
                        <li>You will be charged a {{extra_data.contract.display_logo}}1 or 1% (whichever is higher) as
                            extra transaction fee if you
                            use a non-{{extra_data.countryData.country_code}}
                            debit/credit card.</li>
                        <li>Correct amount will be reflected in 2 to 3 hours.</li>
                    </ul>
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.chargeLateFeeModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <form [formGroup]="chargeLateFeeForm" (ngSubmit)="chargeLateFee()">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Charge Late Fee</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="  col-sm-3">
                            <label class="fs-14 fw-400 mb-2">Late Fee Count</label>
                            <input class="form-control"
                                [max]="extra_data.contract.missing_payments?extra_data.contract.missing_payments:0"
                                type="number" formControlName="lateFeeCount" required min="1"
                                (change)="onLateFeeChange()" />
                            <p class="text-danger fs-14 m-0"
                                *ngIf="(chargeLateFeeForm.controls['lateFeeCount'].value > extra_data.contract.missing_payments|| chargeLateFeeForm.controls['lateFeeCount'].invalid)">
                                Please enter a valid late fee count
                            </p>
                        </div>
                        <p class="col-sm-1 d-none d-sm-block pt-5">*</p>
                        <div class=" col-sm-3">
                            <label class="fs-14 fw-400 mb-2">Late Fee Charge</label>
                            <input class="form-control" type="number" formControlName="lateFeeCharge" min="1"
                                [max]="extra_data.contract.late_fee" (ngModelChange)="onLateFeeChange()" required />
                            <p class="text-danger fs-14 m-0"
                                *ngIf="(chargeLateFeeForm.controls['lateFeeCharge']>extra_data.contract.late_fee || chargeLateFeeForm.controls['lateFeeCharge'].invalid)">
                                Please enter a valid amount</p>
                        </div>
                        <p class="col-sm-1 d-none d-sm-block pt-5">=</p>
                        <div class="  col-sm-3">
                            <label class="fs-14 fw-400 mb-2">Total Late Fee Count</label>
                            <input class="form-control" type="number" formControlName="totalLateFee" readonly />
                        </div>
                    </div>
                    <div class="  mb-2">
                        <label class="fs-14 fw-600 m-0">Note</label>
                        <textarea required formControlName="note" class="input_field" trim="true"></textarea>
                        <p class="text-danger fs-14 m-0" *ngIf="chargeLateFeeForm.controls['note'].invalid">
                            Please enter note.</p>
                        <!-- <p class="text-danger fs-14 m-0" *ngIf="chargeLateFeeForm.submitted && notes.errors?.minlength">Please enter note with
              at least 20 characters</p> -->
                    </div>
                    <div *ngIf="chargeLateFeeForm.controls['totalLateFee'].value > 0">
                        <div class="  mb-2">
                            <label class="col-12 pl-0 mb-2">Pay
                                {{extra_data.currencyData.display_logo}}{{chargeLateFeeForm.controls['totalLateFee'].value}}
                                From*</label>
                            <span class="col-md-3 pl-0" tappable>
                                <input required formControlName="payment_for" type="radio" value="2"
                                    (ngModelChange)="fetchStripeBalance('recurring', chargeLateFeeForm.controls['payment_for'].value, chargeLateFeeForm.controls['payment_mode'].value, chargeLateFeeForm)"
                                    id="payment_mode_customer" class="secondary-radio">
                                <label class="fs-14" for="payment_mode_customer">Customer</label>
                            </span>
                            <span tappable
                                *ngIf="extra_data.contract && extra_data.contract.contract_type && extra_data.contract.contract_type!=3">
                                <input required formControlName="payment_for" type="radio" value="1"
                                    (ngModelChange)="fetchStripeBalance('recurring', chargeLateFeeForm.controls['payment_for'].value, chargeLateFeeForm.controls['payment_mode'].value, chargeLateFeeForm)"
                                    id="payment_mode_business" class="secondary-radio">
                                <label for="payment_mode_business" class="fs-14">Business</label>
                            </span>
                        </div>
                        <div class="mb-2">
                            <span class="fs-14" *ngIf="chargeLateFeeForm.controls['payment_for'].value == 2">Customer
                                would pay
                                {{extra_data.currencyData.display_logo}}{{chargeLateFeeForm.controls['totalLateFee'].value}}</span>
                            <span class="fs-14" *ngIf="chargeLateFeeForm.controls['payment_for'].value == 1">Business
                                would pay
                                {{extra_data.currencyData.display_logo}}{{chargeLateFeeForm.controls['totalLateFee'].value}}</span>
                        </div>
                        <div class="mb-2">
                            <label class="col-12 pl-0 fs-14 fw-400 mb-2">Payment Mode*</label>
                            <span class="  col-md-2 pl-0" tappable
                                *ngIf="chargeLateFeeForm.controls['payment_for'].value!=1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.show_ach==1">
                                <input required formControlName="payment_mode" id="payment_mode_bank" type="radio"
                                    value="4" class="secondary-radio"
                                    (change)="fetchStripeBalance('recurring', chargeLateFeeForm.controls['payment_for'].value, chargeLateFeeForm.controls['payment_mode'].value, chargeLateFeeForm)">
                                <label for="payment_mode_bank" class="fs-14">Bank</label>
                            </span>
                            <span class="  col-md-2 pl-0" tappable>
                                <input required formControlName="payment_mode" id="payment_mode_card" type="radio"
                                    value="1" class="secondary-radio"
                                    (change)="fetchStripeBalance('recurring', chargeLateFeeForm.controls['payment_for'].value, chargeLateFeeForm.controls['payment_mode'].value, chargeLateFeeForm)">
                                <label for="payment_mode_card" class="fs-14">Card</label>
                            </span>

                            <span *ngIf="chargeLateFeeForm.controls['payment_for'].value != 2" tappable>
                                <input required formControlName="payment_mode" type="radio" value="2"
                                    class="secondary-radio"
                                    (change)="fetchStripeBalance('recurring', chargeLateFeeForm.controls['payment_for'].value, chargeLateFeeForm.controls['payment_mode'].value, chargeLateFeeForm)"
                                    id="payment_mode_stripe">
                                <label class="fs-14" for="payment_mode_stripe">Stripe</label>
                            </span>
                        </div>
                        <div
                            *ngIf="chargeLateFeeForm.controls['payment_mode'].value==2 && chargeLateFeeForm.controls['payment_for'].value == 1">
                            <div class="row">
                                <div class="col-md-3 pr-0">
                                    <label class="fs-14 fw-400 mb-2">Stripe
                                        Balance({{extra_data.currencyData.display_logo}})</label>
                                    <input class="form-control " type="number" formControlName="stripe_balance"
                                        name="stripeBalance" readonly />
                                </div>
                                <div class="col-3 text-end" style="margin-top:30px;">
                                    <button class="btn btn-primary" type="button"
                                        (click)="fetchStripeBalance('recurring', chargeLateFeeForm.controls['payment_for'].value, chargeLateFeeForm.controls['payment_mode'].value, chargeLateFeeForm)">Check</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="chargeLateFeeForm.controls['payment_mode'].value==1">
                            <label class="fs-14 fw-400 mb-2">Card Details</label>
                            <div class="card card-body border">
                                <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                                    <div *ngFor="let card of extra_data.recurring.all_cards; let i= index"
                                        class="col-12 cursor">
                                        <div
                                            *ngIf="extra_data.recurring && extra_data.recurring.all_cards.length>0 && card.payment_source_type != 1">
                                            <div class="row border rounded p-2 mb-2 mr-t m-0"
                                                [ngClass]="{'highlight' : extra_data.recurring.source_id==card.source_id}">
                                                <p class="col-6 mb-0"><i
                                                        class="fa fa-credit-card fs-14"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                                </p>
                                                <p class="col-5 p-0 d-flex justify-content-between fs-14">{{card.brand}}
                                                    <span class="m-0 p-0 d-flex align-items-center"
                                                        style="color:#36af47"
                                                        *ngIf="card.source_id==extra_data.contract.source_id">
                                                        <i
                                                            class="fa fa-check-circle fs-14"></i>&nbsp;&nbsp;Default</span>
                                                    <span class="m-0 p-0 d-flex align-items-center"
                                                        style="color:#36af47" *ngIf="card.is_default == 1">
                                                        <i
                                                            class="fa fa-check-circle fs-14"></i>&nbsp;&nbsp;Default</span>
                                                </p>
                                                <p class="col-1 p-0 text-end fs-14"
                                                    (click)="extra_data.recurring.source_id=card.source_id;"
                                                    *ngIf="extra_data.recurring.source_id==card.source_id">Selected</p>
                                                <div class="col-1 p-0 text-end"
                                                    *ngIf="extra_data.recurring.source_id!=card.source_id">
                                                    <button
                                                        class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                                        (click)="extra_data.recurring.source_id=card.source_id;">Select</button>
                                                </div>
                                                <p class="col-12 fs-14"
                                                    [ngClass]="{'text-danger fs-14 m-0':card.source_id!=extra_data.contract.source_id}"
                                                    *ngIf="card.last_error!='null' && card.last_error!=null">
                                                    {{card.last_error.split('=')[0] ||''}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        *ngIf="!extra_data.recurring.all_cards || extra_data.recurring.all_cards.length==0">No
                                        Card Added
                                        Yet</span>
                                </div>
                                <div class="col-md-12 text-end pr-0 mt-4">
                                    <button (click)="showAddCardDivForEditRecurring()"
                                        class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                        tappable>Pay
                                        with New Card</button>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="chargeLateFeeForm.controls['payment_mode'].value==4">
                            <label class="fs-14 fw-400 mb-2">Bank Details</label>
                            <div class="card card-body border">
                                <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                                    <div *ngFor="let card of extra_data.contract.bank_accounts; let i= index"
                                        class="col-12 cursor">
                                        <div
                                            *ngIf="extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && card.payment_source_type == 1">
                                            <div class="row border rounded p-2 mb-2 mr-t m-0"
                                                [ngClass]="{'highlight' : extra_data.recurring.source_id==card.source_id}">
                                                <p class="col-6 mb-0 fs-14"><i
                                                        class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}}
                                                </p>
                                                <p class="col-5 p-0 d-flex justify-content-between">{{card.brand}}
                                                    <span class="m-0 p-0 d-flex align-items-center"
                                                        style="color:#36af47"></span>
                                                </p>
                                                <p class="col-1 p-0 text-end"
                                                    (click)="extra_data.recurring.source_id=card.source_id;"
                                                    *ngIf="extra_data.recurring.source_id==card.source_id && card.bank_status=='succeeded'">
                                                    Selected</p>
                                                <div class="col-1 p-0 text-end"
                                                    *ngIf="extra_data.recurring.source_id!=card.source_id && card.bank_status=='succeeded'">
                                                    <button
                                                        class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                                        (click)="extra_data.recurring.source_id=card.source_id;">Select</button>
                                                </div>
                                                <p class="col-4 p-0 text-end text-danger fs-14 m-0 "
                                                    *ngIf="card.bank_status=='reauthenticate'">Reauthentication Required
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0" *ngIf="chargeLateFeeForm.controls['payment_mode'].value==4">
                            <p class="text-muted m-b-0">*Note</p>
                            <ul>
                                <li class="text-muted fs-14 fw-400">Please make sure you have the appropriate funds
                                    available in your
                                    account.</li>
                                <li class="text-muted fs-14 fw-400">Processing time may take up to 7 business days.</li>
                                <li class="text-muted fs-14 fw-400">In the event of a failed transaction, you may be
                                    subject to late
                                    fees and/or failed transaction fees.</li>
                                <li class="text-muted fs-14 fw-400">Your Next payment is due for
                                    {{extra_data.contract.next_payment_date |
                                    date:"MM/dd/yyyy": '+0000'}}. If the funds are not realized for the payment done
                                    through the Bank before the next payment date we will attempt it.</li>
                            </ul>
                        </div>
                        <div class="col-12 p-0">
                            <div class="  col-12  pl-0">
                                <span class="">
                                    <input type="checkbox" id="show_existing_card" class="secondary-checkbox"
                                        (change)="resetCardData()" />
                                    <label class="fs-14" for="show_existing_card">Show Existing Card</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <p class="fs-14 fw-400 ">Are you sure you want to <b>charge this late fee</b> for this contract ?
                    </p>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel();note=''">No</button>
                    <button class="btn btn-primary"
                        [disabled]="chargeLateFeeForm.invalid || (chargeLateFeeForm.controls['payment_mode'].value==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='succeeded')  || (extra_data.recurring && chargeLateFeeForm.controls['payment_mode'].value!=2  && (!extra_data.recurring.source_id))">Pay</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.editRecurringModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <form [formGroup]="editRecurringDateForm" (ngSubmit)="editRecurringContract()">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Edit Recurring Date</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="  mb-3">
                            <label class="fs-14 fw-400 m-0">Choose the new recurring date for payments
                                (MM/DD/YYYY)*</label>
                            <input class="input_field" bsDatepicker [bsConfig]="bsConfig"
                                formControlName="recurringDate" (ngModelChange)="onDateChange()" />
                            <p class="text-danger fs-14 m-0 m-0"
                                *ngIf="(editRecurringDateForm.controls['recurringDate'].touched || editRecurringDateForm.controls['recurringDate'].dirty) && editRecurringDateForm.controls['recurringDate'].invalid">
                                *Please choose new recurring date.
                            </p>
                        </div>
                        <div class="  mb-3">
                            <label class="fs-14 fw-400 m-0">Edit Recurring Date
                                Amount({{extra_data.countryData.country_code}})*</label>
                            <input [min]="0" class="input_field" type="number" formControlName="editRecurringAmount"
                                (ngModelChange)="onLateFeeDateChange()" />
                        </div>
                        <div class="  mb-3"
                            *ngIf="extra_data.contract && (extra_data.contract.missing_payments && extra_data.contract.pf_status == 3) || this.extra_data.recurring.late_fee > 0;">
                            <label class="fs-14 fw-400 m-0">Enter the late fees for
                                {{extra_data.contract.missing_payments || '0'}}
                                month(s) ({{extra_data.contract.display_logo}})</label>
                            <!-- <input [readonly]="!waiver.allow_waiver" type="text" required [(ngModel)]="recurring.late_fee" #lateFee="ngModel" name="lateFee" -->
                            <input type="number" required formControlName="late_fee" class="input_field"
                                (ngModelChange)="onLateFeeDateChange()">
                        </div>

                        <div class="  mb-3">
                            <label class="fs-14 fw-400 m-0">Total Amount({{extra_data.currencyData.display_logo}})
                                ({{editRecurringDateForm.controls['editRecurringAmount'].value}} +
                                {{editRecurringDateForm.controls['late_fee'].value}})</label>
                            <input type="text" required formControlName="total_amount" class="input_field" readonly
                                step="0.01">
                        </div>
                        <div class="row mb-3">
                            <div class="col-6 text-left">
                                <label class="">
                                    <input type="checkbox" id="sendNotification"
                                        formControlName="send_customer_notification" class="secondary-checkbox" />
                                    <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                        customer</label>
                                </label>
                            </div>
                            <div class="col-6 text-left">
                                <div
                                    *ngIf="extra_data.contract.contract_type == 6 && extra_data.contract.is_contract_deferred == 1 && !extra_data.recurring.hideBtn">
                                    <label class="">
                                        <input type="checkbox" formControlName="continue_deferred_benefits"
                                            class="secondary-checkbox" />
                                        <label for="continue_deferred_benefits" class="fs-14 fw-400 m-0 mb">Continue
                                            Deferred Benefits</label>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="  mb-3">
                        <label class="fs-14 fw-600 m-0">Note</label>
                        <textarea required formControlName="note" class="input_field" trim="true"></textarea>
                        <p class="text-danger fs-14 m-0 m-0" *ngIf="editRecurringDateForm.controls['note'].invalid">
                            Please enter note.</p>
                        <!-- <p class="text-danger fs-14 m-0 m-0" *ngIf="editRecurringDateForm.submitted && notes.errors?.minlength">Please enter note with
              at least 20 characters</p> -->
                    </div>
                    <div *ngIf="editRecurringDateForm.controls['total_amount'].value > 0">



                        <div>
                            <label class="col-12 pl-0 mt-2 mb-2">Pay
                                {{extra_data.currencyData.display_logo}}{{editRecurringDateForm.controls['total_amount'].value}}
                                From*</label>
                        </div>

                        <div>
                            <span>
                                <input id="customer" formControlName="payment_for" type="radio" [value]="2"
                                    class="secondary-radio"
                                    (ngModelChange)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <label for="customer" class="fs-14">Customer</label>
                            </span>
                            <span
                                *ngIf="extra_data.contract && extra_data.contract.contract_type && extra_data.contract.contract_type!=3">
                                <input id="Business" formControlName="payment_for" type="radio" [value]="1"
                                    class="secondary-radio"
                                    (ngModelChange)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <label for="Business" class="fs-14">Business</label>
                            </span>
                        </div>


                        <!-- <div>
                            <label class="col-12 pl-0">Pay
                                {{extra_data.currencyData.display_logo}}{{editRecurringDateForm.controls['total_amount'].value}}
                                From*</label>
                            <label class="  col-md-3 pl-0" tappable>
                                <input required formControlName="payment_for" type="radio" value="2"
                                    (ngModelChange)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <span class="fa fa-circle"></span>Customer
                            </label>
                            <label class=" " tappable
                                *ngIf="extra_data.contract && extra_data.contract.contract_type && extra_data.contract.contract_type!=3">
                                <input required formControlName="payment_for" type="radio" value="1"
                                    (ngModelChange)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <span class="fa fa-circle"></span>Business
                            </label>
                        </div> -->
                        <div class="mt-1 mb-3 d-inline-block">
                            <span *ngIf="editRecurringDateForm.controls['payment_for'].value == 2">Customer would pay
                                {{extra_data.currencyData.display_logo}}{{editRecurringDateForm.controls['total_amount'].value}}</span>
                            <span *ngIf="editRecurringDateForm.controls['payment_for'].value == 1">Business would pay
                                {{extra_data.currencyData.display_logo}}{{editRecurringDateForm.controls['total_amount'].value}}</span>
                        </div>

                        <div class="mt-3 mb-2"><label class="col-12 pl-0">Payment Mode*</label></div>
                        <div class="mb-3">
                            <span
                                *ngIf="editRecurringDateForm.controls['payment_for'].value!=1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.show_ach==1">
                                <input id="bank" formControlName="payment_mode" type="radio" [value]="4"
                                    class="secondary-radio"
                                    (change)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <label for="bank" class="fs-14">Bank</label>
                            </span>
                            <span>
                                <input id="card" formControlName="payment_mode" type="radio" [value]="1"
                                    class="secondary-radio"
                                    (change)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <label for="card" class="fs-14">Card</label>
                            </span>
                            <span *ngIf="editRecurringDateForm.controls['payment_for'].value != 2">
                                <input id="stripe" formControlName="payment_mode" type="radio" [value]="2"
                                    class="secondary-radio"
                                    (change)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <label for="stripe" class="fs-14">Stripe</label>
                            </span>
                        </div>

                        <!-- <div>
                            <label class="col-12 pl-0">Payment Mode*</label>
                            <label class="  col-md-2 pl-0" tappable
                                *ngIf="editRecurringDateForm.controls['payment_for'].value!=1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.show_ach==1">
                                <input required formControlName="payment_mode" type="radio" value="4"
                                    (change)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <span class="fa fa-circle"></span>Bank
                            </label>
                            <label class="  col-md-2 pl-0" tappable>
                                <input required formControlName="payment_mode" type="radio" value="1"
                                    (change)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <span class="fa fa-circle"></span>Card
                            </label>

                            <label class=" " *ngIf="editRecurringDateForm.controls['payment_for'].value != 2" tappable>
                                <input required formControlName="payment_mode" type="radio" value="2"
                                    (change)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">
                                <span class="fa fa-circle"></span>Stripe
                            </label>
                        </div> -->
                        <div
                            *ngIf="editRecurringDateForm.controls['payment_mode'].value==2 && editRecurringDateForm.controls['payment_for'].value == 1">
                            <div class="row">
                                <div class="col-md-3 pr-0">
                                    <label>Stripe Balance({{extra_data.currencyData.display_logo}})</label>
                                    <input class="form-control " type="number" formControlName="stripe_balance"
                                        name="stripeBalance" readonly />
                                </div>
                                <div class="col-3 text-end" style="margin-top:30px;">
                                    <button class="btn btn-primary" type="button"
                                        (click)="fetchStripeBalance('recurring', editRecurringDateForm.controls['payment_for'].value, editRecurringDateForm.controls['payment_mode'].value, editRecurringDateForm)">Check</button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="editRecurringDateForm.controls['payment_mode'].value==1">
                            <label>Card Details</label>
                            <div class="card card-body border" style="padding: 7px;">
                                <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                                    <div *ngFor="let card of extra_data.recurring.all_cards; let i= index"
                                        class="col-12 cursor">
                                        <div
                                            *ngIf="extra_data.recurring && extra_data.recurring.all_cards.length>0 && card.payment_source_type != 1">
                                            <div class="row border rounded p-2 mb-2 mr-t"
                                                [ngClass]="{'highlight' : extra_data.recurring.source_id==card.source_id}">
                                                <p class="col-5 mb-0"><i
                                                        class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                                </p>
                                                <p class="col-5 p-0 d-flex justify-content-between">{{card.brand}}
                                                    <span class="m-0 p-0 d-flex align-items-center"
                                                        style="color:#36af47"
                                                        *ngIf="card.source_id==extra_data.contract.source_id">
                                                        <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                                    <span class="m-0 p-0 d-flex align-items-center"
                                                        style="color:#36af47" *ngIf="card.is_default == 1">
                                                        <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                                </p>
                                                <p class="col-2 rounded-pill text-end mb-0" style="padding: 8px 10px !important;
                                                text-align: center !important;
                                                background: #035503;
                                                color: white;" (click)="extra_data.recurring.source_id=card.source_id;"
                                                    *ngIf="extra_data.recurring.source_id==card.source_id">Selected</p>
                                                <div class="col-2 p-0 text-end"
                                                    *ngIf="extra_data.recurring.source_id!=card.source_id">
                                                    <button
                                                        class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                                        (click)="extra_data.recurring.source_id=card.source_id;">Select</button>
                                                </div>
                                                <p class="col-12"
                                                    [ngClass]="{'text-danger fs-14 m-0':card.source_id!=extra_data.contract.source_id}"
                                                    *ngIf="card.last_error!='null' && card.last_error!=null">
                                                    {{card.last_error.split('=')[0] ||''}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        *ngIf="!extra_data.recurring.all_cards || extra_data.recurring.all_cards.length==0">No
                                        Card Added Yet</span>
                                </div>
                                <div class="col-md-12 text-end pr-0 mt-4">
                                    <button (click)="showAddCardDivForEditRecurring()"
                                        class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                        tappable>Pay with New Card</button>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="editRecurringDateForm.controls['payment_mode'].value==4">
                            <label>Bank Details</label>
                            <div class="card card-body border" style="padding: 7px;">
                                <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                                    <div *ngFor="let card of extra_data.contract.bank_accounts; let i= index"
                                        class="col-12 cursor">
                                        <div
                                            *ngIf="extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && card.payment_source_type == 1">
                                            <div class="row border rounded p-2 mb-2 mr-t"
                                                [ngClass]="{'highlight' : extra_data.recurring.source_id==card.source_id}">
                                                <p class="col-6 mb-0"><i
                                                        class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}}
                                                </p>
                                                <p class="col-5 p-0 d-flex justify-content-between">{{card.brand}}
                                                    <span class="m-0 p-0 d-flex align-items-center"
                                                        style="color:#36af47"></span>
                                                </p>
                                                <p class="col-1 p-0 text-end"
                                                    (click)="extra_data.recurring.source_id=card.source_id;"
                                                    *ngIf="extra_data.recurring.source_id==card.source_id && card.bank_status=='succeeded'">
                                                    Selected</p>
                                                <div class="col-1 p-0 text-end"
                                                    *ngIf="extra_data.recurring.source_id!=card.source_id && card.bank_status=='succeeded'">
                                                    <button
                                                        class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                                        (click)="extra_data.recurring.source_id=card.source_id;">Select</button>
                                                </div>
                                                <p class="col-4 p-0 text-end text-danger fs-14 m-0 "
                                                    *ngIf="card.bank_status=='reauthenticate'">Reauthentication Required
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 pl-0" *ngIf="editRecurringDateForm.controls['payment_mode'].value==4">
                            <p class="text-muted m-b-0">*Note</p>
                            <ul>
                                <li class="text-muted">Please make sure you have the appropriate funds available in your
                                    account.</li>
                                <li class="text-muted">Processing time may take up to 7 business days.</li>
                                <li class="text-muted">In the event of a failed transaction, you may be subject to late
                                    fees and/or failed transaction fees.</li>
                                <li class="text-muted">Your Next payment is due for
                                    {{extra_data.contract.next_payment_date |
                                    date:"MM/dd/yyyy": '+0000'}}. If the funds are not realized for the payment done
                                    through the Bank before the next payment date we will attempt it.</li>
                            </ul>
                        </div>
                        <!-- <div class="col-12 p-0">
                            <div class="  col-12  pl-0">
                                <span class="">
                                    <input type="checkbox" id="show_existing_card" class="secondary-checkbox"
                                        (change)="resetCardData()" />
                                    <label class="fs-14" for="show_existing_card">Show Existing Card</label>
                                </span>
                            </div>
                        </div> -->
                    </div>
                    <!-- <p>Are you sure you want to <b>charge this late fee</b> for this contract ?</p> -->

                </div>

                <div class="modal-footer"><button type="button" class="btn btn-outline"
                        (click)="cancel();note=''">No</button>
                    <button type="submit" class="btn btn-primary"
                        [disabled]="editRecurringDateForm.invalid || (editRecurringDateForm.controls['payment_mode'].value==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='succeeded')  || (extra_data.recurring && editRecurringDateForm.controls['payment_mode'].value!=2  && (!extra_data.recurring.source_id))">
                        Pay
                    </button>

                    <button type="submit" class="btn btn-primary"
                        *ngIf="!(editRecurringDateForm.controls['total_amount'].value>0)"
                        [disabled]="editRecurringDateForm.invalid">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.partialPaymentModal">
    <div class="modal-dialog modal-lg modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Charge Partial Payment</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <form [formGroup]="partialPaymentForm" (submit)="partialPay()">
                <div class="modal-body">

                    <div>
                        <label>Partial Payment to be charged</label>
                        <span *ngIf="partialPaymentForm.controls['partial_amount'].value" class="text-bold">
                            ( Extra
                            <span
                                *ngIf="_contract.GetTransactionFee(extra_data.contract,partialPaymentForm.controls['partial_amount'].value)">{{_contract.GetTransactionFee(extra_data.contract,partialPaymentForm.controls['partial_amount'].value)}}</span>
                            transaction fees will be charged)*
                        </span>
                        <input required formControlName="partial_amount" class="input_field" type="number" min="5.00"
                            step="0.01">
                        <p class="text-danger fs-14 m-0"
                            *ngIf="partialPaymentForm.controls['partial_amount'].invalid && (partialPaymentForm.controls['partial_amount'].dirty || partialPaymentForm.controls['partial_amount'].touched)">
                            Please enter a valid amount. (Minimum partial amount
                            {{extra_data.currencyData.display_logo}}5)</p>
                    </div>
                    <div class="row py-2">
                        <div class="col-12 text-left">
                            <label class="">
                                <input type="checkbox" id="sendNotification"
                                    formControlName="send_customer_notification" class="secondary-checkbox" />
                                <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                    customer</label>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="fs-14 fw-600 m-0">Note*</label>
                        <textarea required formControlName="note" class="input_field" trim="true"></textarea>
                        <p class="text-danger fs-14 m-0" *ngIf="partialPaymentForm.controls['note'].invalid">
                            Please enter note.</p>
                        <!-- <p class="text-danger fs-14 m-0" *ngIf="(notes.touched || notes.dirty) && notes.errors?.minlength">Please enter note
              with at least 20 characters</p> -->
                    </div>
                    <app-payment-sources (sendData)="assignData($event)"
                        [contract]="extra_data.contract"></app-payment-sources>

                    <p class="fs-14 fw-500 text-grey mb-2">Are you sure you want to <strong>charge this partial
                            payment</strong> for this contract ? </p>
                    <span>
                        <span class="fs-14 fw-600 text-grey mb-2">*Note:</span>
                        <ul class="ps-3">
                            <li class="fs-14 fw-500 text-grey mb-2">You will be charged a
                                {{extra_data.currencyData.display_logo}}1 or 1% (whichever is
                                higher) as extra transaction fee if you use a
                                non-{{extra_data.countryData.country_code}} debit/credit card.</li>
                            <li class="fs-14 fw-500 text-grey mb-2">Correct amount will be reflected in 2 to 3 hours.
                            </li>
                        </ul>
                    </span>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="cancel()" class="btn btn-outline">Cancel</button>
                    <button type="submit" class="btn btn-primary"
                        [disabled]="partialPaymentForm.invalid || (extra_data.contract.payment_mode==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='succeeded')  || (extra_data.contract.bank_accounts && !source_id)">Pay</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.payMoreModal">
    <div class="modal-dialog modal-lg modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class=" fs-18">Charge Pay More</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <form [formGroup]="partialPaymentForm" (submit)="payMore()">
                <div class="modal-body">

                    <div>
                        <label>Pay More to be charged</label>
                        <span class="text-bold pl-2">
                            (Payable Amount: Between {{extra_data.currencyData.display_logo}}{{minAmountToPay | number:
                            '0.2-2'}} to {{extra_data.currencyData.display_logo}}{{maxAmountToPay | number: '0.2-2'}})
                        </span>
                        <input required formControlName="partial_amount" class="form-control" type="number" [min]="5"
                            step="0.01">
                        <p class="text-danger fs-14 m-0"
                            *ngIf="partialPaymentForm.controls['partial_amount'].touched && partialPaymentForm.controls['partial_amount'].invalid">
                            Please enter a valid amount.</p>
                        <p class="text-danger fs-14 m-0"
                            *ngIf="partialPaymentForm.value['partial_amount'] && (partialPaymentForm.value['partial_amount'] < minAmountToPay || partialPaymentForm.value['partial_amount'] > maxAmountToPay)">
                            Pleas enter amount in between {{extra_data.currencyData.display_logo}}{{minAmountToPay |
                            number: '0.2-2'}} to {{extra_data.currencyData.display_logo}}{{maxAmountToPay | number:
                            '0.2-2'}}.</p>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <label class="">
                                <input type="checkbox" id="sendNotification"
                                    formControlName="send_customer_notification" class="secondary-checkbox" />
                                <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                    customer</label>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="fs-14 fw-600 m-0">Note*</label>
                        <textarea required formControlName="note" class="input_field" trim="true"></textarea>
                        <p class="text-danger fs-14 m-0"
                            *ngIf="partialPaymentForm.controls['note'].touched && partialPaymentForm.controls['note'].invalid ">
                            Please enter note.</p>
                        <!-- <p class="text-danger fs-14 m-0" *ngIf="(notes.touched || notes.dirty) && notes.errors?.minlength">Please enter note
              with at least 20 characters</p> -->
                    </div>
                    <app-payment-sources (sendData)="assignData($event)"
                        [contract]="extra_data.contract"></app-payment-sources>

                    <p>Are you sure you want to <strong>charge this payment</strong> for this contract ? </p>
                    <p class="p-t-10 small">
                        <span>
                            <b>*Note:</b>
                            <ul style="padding-inline-start: 15px;">
                                <li>You will be charged a {{extra_data.currencyData.display_logo}}1 or 1% (whichever is
                                    higher) as extra transaction fee if
                                    you use a non-{{extra_data.countryData.country_code}} debit/credit card.</li>
                                <li>Correct amount will be reflected in 2 to 3 hours.</li>
                            </ul>
                        </span>
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="hide()" class="btn btn-outline">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary"
                        [disabled]="partialPaymentForm.invalid || (extra_data.contract.payment_mode==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='succeeded')  || (extra_data.contract.bank_accounts && !source_id)">Pay</button>
                    <!-- <button type="submit" class="btn btn-primary" [disabled]="partialPaymentForm.invalid">Confirm</button> -->
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.payOffModal">
    <div class="modal-dialog modal-lg modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Pay Off</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <form [formGroup]="payOffForm" (submit)="payOff()">
                <div class="modal-body">

                    <div class="  mb-3">
                        <label class="fs-14 fw-600 m-0">Note*</label>
                        <textarea required formControlName="note" class="input_field" trim="true"></textarea>
                        <p class="text-danger fs-14 m-0"
                            *ngIf="payOffForm.controls['note'].touched && payOffForm.controls['note'].invalid ">
                            Please enter note.
                        </p>
                    </div>
                    <div class="row ">
                        <div class="col-12 text-left">
                            <label class="">
                                <input type="checkbox" id="sendNotification"
                                    formControlName="send_customer_notification" class="secondary-checkbox" />
                                <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                    customer</label>
                            </label>
                        </div>
                    </div>
                    <app-payment-sources (sendData)="assignData($event)"
                        [contract]="extra_data.contract"></app-payment-sources>

                    <p class="p-t-10 small">
                        <span>
                            <b class="fs-14 fw-400 mb-2">*Note:</b>
                            <ul style="padding-inline-start: 15px;">
                                <li class="fs-14">You will be charged a {{extra_data.currencyData.display_logo}}1 or 1%
                                    (whichever is higher) as extra transaction fee if
                                    you use a non-{{extra_data.countryData.country_code}} debit/credit card.</li>
                                <li class="fs-14">Correct amount will be reflected in 2 to 3 hours.</li>
                            </ul>
                        </span>
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="hide()" class="btn btn-outline">Cancel</button>

                    <button type="submit" class="btn btn-primary"
                        [disabled]="payOffForm.invalid || (extra_data.contract.payment_mode==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='succeeded')  || !source_id">Pay</button>
                    <!-- <button type="submit" class="btn btn-primary" [disabled]="payOffForm.invalid">Confirm</button> -->
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.contractUpdateModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Edit Contract Financial Values</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_treatment_amount"
                                        name="edit_treatment_amount" id="treatment_amount" class="secondary-checkbox"
                                        type="checkbox" (change)="setDefaultValues(1)">
                                    <label for="treatment_amount" class="fs-14">Service
                                        Amount({{extra_data.countryData.country_code}}{{extra_data.contract.treatment_amount|number:'0.2-2'}})</label>
                                </label>
                            </div>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">
                                    {{extra_data.countryData.country_code}}
                                </span>
                                <input type="number" [min]="0"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0"
                                    name="treatment_amount" [(ngModel)]="contract_changes.treatment_amount"
                                    [readonly]="!contract_changes.edit_treatment_amount" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_finance_amount" name="edit_finance_amount"
                                        id="finance_amount" class="secondary-checkbox" type="checkbox"
                                        (change)="setDefaultValues(2)">
                                    <label for="finance_amount" class="fs-14">Finance
                                        Amount({{extra_data.countryData.country_code}}{{extra_data.contract.financed_amount|number:'0.2-2'}})</label>
                                </label>
                            </div>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">
                                    {{extra_data.countryData.country_code}}
                                </span>
                                <input type="number" [min]="0"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0"
                                    name="finance_amount" [(ngModel)]="contract_changes.finance_amount"
                                    [readonly]="!contract_changes.edit_finance_amount" />
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_downpayment_amount"
                                        id="downpayment_amount" class="secondary-checkbox"
                                        name="edit_downpayment_amount" type="checkbox" (change)="setDefaultValues(3)">
                                    <label for="downpayment_amount" class="fs-14">
                                        Downpayment
                                        Amount({{extra_data.countryData.country_code}}{{extra_data.contract.downpayment_amount|number:'0.2-2'}})
                                    </label>
                                </label>
                            </div>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">
                                    {{extra_data.countryData.country_code}}
                                </span>
                                <input type="number" [min]="0"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0"
                                    name="downpayment_amount" [(ngModel)]="contract_changes.downpayment_amount"
                                    [readonly]="!contract_changes.edit_downpayment_amount" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_recurring_amount" id="recurring_amount"
                                        class="secondary-checkbox" name="edit_recurring_amount" type="checkbox"
                                        (change)="setDefaultValues(4)">
                                    <label for="recurring_amount" class="fs-14">
                                        Recurring
                                        Amount({{extra_data.countryData.country_code}}{{extra_data.contract.recurring_amount|number:'0.2-2'}})
                                    </label>
                                </label>
                            </div>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">
                                    {{extra_data.countryData.country_code}}
                                </span>
                                <input type="number" [min]="0"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0"
                                    name="recurring_amount" [(ngModel)]="contract_changes.recurring_amount"
                                    [readonly]="!contract_changes.edit_recurring_amount" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_number_of_payments"
                                        id="edit_number_of_payments" class="secondary-checkbox"
                                        name="edit_number_of_payments" type="checkbox" (change)="setDefaultValues(5)">
                                    <label for="edit_number_of_payments" class="fs-14">Total Number of
                                        Payments({{extra_data.contract.number_of_payments}} months)</label>
                                </label>
                            </div>
                            <div class="input-group">
                                <input type="number" [min]="0" class="form-control" name="number_of_payments"
                                    [(ngModel)]="contract_changes.number_of_payments"
                                    [readonly]="!contract_changes.edit_number_of_payments" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_remaining_payments"
                                        id="edit_remaining_payments" class="secondary-checkbox"
                                        name="edit_remaining_payments" type="checkbox" (change)="setDefaultValues(6)">
                                    <label for="edit_remaining_payments" class="fs-14">Remaining
                                        Payments({{extra_data.contract.remaining_payments}} months)</label>
                                </label>
                            </div>
                            <div class="input-group">
                                <input type="number" [min]="0" class="form-control" name="remaining_payments"
                                    [(ngModel)]="contract_changes.remaining_payments"
                                    [readonly]="!contract_changes.edit_remaining_payments" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_principal_per_recurring"
                                        id="edit_principal_per_recurring" class="secondary-checkbox"
                                        name="edit_principal_per_recurring" type="checkbox"
                                        (change)="setDefaultValues(9)">
                                    <label for="edit_principal_per_recurring" class="fs-14">
                                        Principal per Recurring
                                        Amount({{extra_data.countryData.country_code}}{{extra_data.contract.principal_per_recurring|number:'0.2-2'}})
                                    </label>
                                </label>
                            </div>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">
                                    {{extra_data.countryData.country_code}}
                                </span>
                                <input type="number" [min]="0"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0"
                                    name="principal_per_recurring"
                                    [(ngModel)]="contract_changes.principal_per_recurring"
                                    [readonly]="!contract_changes.edit_principal_per_recurring" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_payment_date" name="edit_payment_date"
                                        id="edit_payment_date" class="secondary-checkbox" type="checkbox"
                                        (change)="setDefaultValues(7)">
                                    <label for="edit_payment_date" class="fs-14">
                                        Next Payment Date({{extra_data.contract.next_payment_date | date:"MM/dd/yyyy":
                                        '+0000'}})
                                    </label>
                                </label>
                            </div>
                            <div class="input-group">
                                <input readonly placeholder="Click here to choose date"
                                    [(ngModel)]="contract_changes.payment_date" name="payment_date" required type="text"
                                    bsDatepicker class="form-control" [disabled]="!contract_changes.edit_payment_date"
                                    [bsConfig]="bsConfig">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mb-4">
                            <div class="checkbox c-checkbox mb-2">
                                <label>
                                    <input [(ngModel)]="contract_changes.edit_pf_status" name="edit_pf_status"
                                        id="edit_pf_status" class="secondary-checkbox" type="checkbox"
                                        (change)="setDefaultValues(8)">
                                    <label for="edit_pf_status">PF Status({{extra_data.contract.pf_status}})</label>
                                </label>
                            </div>
                            <div class="input-group">
                                <input type="number" [min]="0" class="form-control" name="pf_status"
                                    [(ngModel)]="contract_changes.pf_status"
                                    [readonly]="!contract_changes.edit_pf_status" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12"
                        *ngIf="contract_changes.pf_status == 4 || contract_changes.pf_status == 5">
                        <div class="mb-4">
                            <label class="fs-14 fw-600 m-0">{{contract_changes.pf_status == 4 ? 'Completed Date' :
                                'Cancelled Date'}}
                            </label>
                            <div class="input-group">
                                <input readonly placeholder="Click here to choose date"
                                    [(ngModel)]="contract_changes.cancelled_date" name="cancelled_date" required
                                    type="text" bsDatepicker class="input_field" [bsConfig]="bsConfig">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12"
                        *ngIf="(contract_changes.pf_status == 4 || contract_changes.pf_status == 5) && extra_data.contract.one_paid_to_denefits == 1 && (extra_data.contract.paid_by_denefits == 0 || extra_data.contract.paid_by_denefits == 2)">
                        <div class="mb-4">
                            <div class="input-group mb-2">
                                <label class=" active">
                                    <input type="checkbox" [checked]="contract_changes.reverse_first_recurring"
                                        id="reverse_first_recurring" class="secondary-checkbox"
                                        [(ngModel)]="contract_changes.reverse_first_recurring"
                                        name="reverse_first_recurring" />
                                    <label for="reverse_first_recurring"> Reverse First Payment</label>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div>
                            <label class="fs-14 fw-600 m-0">Note*</label>
                            <textarea name="notes" class="input_field" required rows="4"
                                [(ngModel)]="contract_changes.notes" trim="true"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-outline" (click)="cancel()">Cancel</button>
                    </div>
                    <div class="col-6 text-end">
                        <button type="submit" class="btn btn-primary" (click)="updateContractDetails()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.cancelContractModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <form [formGroup]="cancelContractForm" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18 fw-500">Cancel Contract</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 class="fs-18 fw-500">Are you sure you want to cancel this contract?</h4>
                    </div>
                    <div class="  mt-3 mb-2"
                        *ngIf="extra_data.contract && extra_data.contract['one_paid_to_denefits']==1 && extra_data.contract['paid_by_denefits']==0">
                        <label class="">
                            <input type="checkbox" formControlName="reserve_payment" id="payment_reserve"
                                class="secondary-checkbox" name="payment_reserve" />
                            <label for="payment_reserve" class="fs-14 fw-400">Return 1st reserve payment of the contract
                                to Business</label>
                        </label>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <label class="mb-2">
                                <label class="">
                                    <input type="checkbox" id="sendNotification"
                                        formControlName="send_customer_notification" class="secondary-checkbox" />
                                    <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                        customer</label>
                                </label>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required formControlName="notes" name="notes" class="input_field"
                            trim="true"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">No</button>
                    <button class="btn btn-primary" (click)="cancelContractFn()">Yes</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.makeProtectedNonProtected">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <form [formGroup]="makeProtectedNonProtected" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18"> Confirmation</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 class="fs-16 fw-500">Are you sure you want to make this contract
                            {{extra_data.contract.is_guaranteed == 2 || extra_data.contract.is_guaranteed == 4 ?
                            'Protected' : 'Non-Protected'}} ?</h4>
                    </div>
                    <div>
                        <label class="fs-14 fw-400 mb-2"><b>Note*</b></label>
                        <textarea required formControlName="notes" name="notes" class="input_field"
                            trim="true"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">No</button>
                    <button class="btn btn-primary" (click)="makeProtectedNonProtectedFn()">Yes</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.nextRecAmountModal">
    <div class="modal-dialog customModal">

        <form [formGroup]="nextRecAmountForm">
            <div class="modal-content border-0" *ngIf="extra_data.contract">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Make Payment</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 p-2 mb-4"
                        *ngIf="(extra_data.contract.missing_payments > 0 && extra_data.contract.missing_payments >= extra_data.contract.number_of_missing_payments)">
                        <label class="fs-14 fw-400 m-0">Enter the late fees for one missing payment
                            ({{extra_data.currencyData.display_logo}})*</label>
                        <input class="input_field" required type="number" [min]="0" [max]="extra_data.contract.late_fee"
                            formControlName="late_fee" name="late_fee" />
                        <!-- <p class="text-danger fs-14 m-0"
                            *ngIf="(late_fee.dirty || late_fee.touched) && late_fee.errors?.max">
                            Amount cannot be greater than {{extra_data.contract.late_fee}}</p>
                        <p class="text-danger fs-14 m-0"
                            *ngIf="(late_fee.dirty || late_fee.touched) && late_fee.errors?.min">
                            Enter amount should be greater than or equal to 0</p> -->
                    </div>
                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea placeholder="Enter your notes" class="input_field" cols="30" rows="5"
                            formControlName="notes" name="notes" required></textarea>
                        <p class="text-danger fs-14 m-0"
                            *ngIf="(nextRecAmountForm.controls['notes'].touched && !nextRecAmountForm.controls['notes'].value)">
                            Enter a valid Note</p>
                        <!-- <p class="text-danger fs-14 m-0" *ngIf="(noteModel.dirty || noteModel.touched) && noteModel.errors?.minlength">Please
                    enter note with at least 20 characters</p> -->
                    </div>
                    <div class="row pt-2">
                        <div class="col-12 text-left">
                            <label class="">
                                <input type="checkbox" id="sendNotification"
                                    formControlName="send_customer_notification" class="secondary-checkbox" />
                                <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                    customer</label>
                            </label>
                        </div>
                    </div>

                    <app-payment-sources (sendData)="assignData($event)" [contract]="extra_data.contract"
                        [checkDisableAction]="true">
                    </app-payment-sources>

                    <div class="text-center py-3"
                        *ngIf="extra_data.contract.disable_action && extra_data.contract.disable_action.length == 0">
                        <p><b>OR</b></p>
                    </div>
                    <div class="text-center"
                        *ngIf="extra_data.contract.disable_action && extra_data.contract.disable_action.length == 0">
                        <button (click)="showAddCardDivForEditRecurring()" class="btn btn-primary">Add New Card &
                            Pay</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">Cancel
                    </button>

                    <button type="button" class="btn btn-primary" (click)="payNextRecurringNow()"
                        [disabled]="(extra_data.contract.payment_mode==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status!='succeeded') || (extra_data.contract.bank_accounts && !source_id)">Pay
                    </button>

                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.generateAddCardLink">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Add Card Link</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <p class="fs-14 ">The following link can be shared with the Customer to add their card for the contract.
                </p>
                <p class="fs-14 "><b>{{extra_data.contract.link || 'Link Not Available'}}</b></p>
                <div class="  mb-3">
                    <label class="fs-14 fw-400 m-0">Customer Email*</label>
                    <input type="text" class="input_field" [(ngModel)]="extra_data.contract.patient_email"
                        trim="blur" />
                </div>
                <div>
                    <label class="fs-14 fw-400 m-0">Notes*</label>
                    <textarea required [(ngModel)]="extra_data.contract.notes" class="input_field"
                        trim="blur"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline" ngxClipboard [cbContent]="extra_data.contract.link"
                (cbOnSuccess)="copied($event)">Copy</button>
                <button class="btn btn-primary" (click)="sendAddCardLinkEmail()">Send</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.generateContributionLink">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Contribution Link</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <p class="fs-14">The following link can be shared with Customer’s Friends to contribute to total
                    remaining amount
                    for their contract
                    <br> <span class="text-primary">{{extra_data.contract.contract_code}}</span>
                </p>
                <p class="fs-14"><b>{{extra_data.contract.contributionUrl || 'Link Not Available'}}</b></p>

                <div class="col-12 mb-3 languge-part">
                    <label for="" class="w-100 fs-14 fw-400 m-0">Select Language</label>
                    <div class="custom-form m-h-56">
                        <select class="input_field col-12 language-chnge" [(ngModel)]="selectedLanguage">
                            <option *ngFor="let lang of extra_data.contract.language_data; let i=index"
                                [value]="lang.lan_code">
                                {{lang.lan_name}}</option>
                        </select>
                        <label for="text" class="label-text m-0">
                            <span class="f-c-p-3" style="font-size: 12px; bottom: -15px;"
                                [ngClass]="{'d-none': selectedLanguage}">
                                Select Language
                            </span>
                        </label>
                    </div>
                </div>

                <div>
                    <label class="fs-14 fw-400 m-0">Enter Email (Press enter to add):</label>
                    <input type="text" class="input_field" [(ngModel)]="extra_data.contract.patient_email" trim="blur"
                        (keypress)="addEmails($event)" />
                </div>
                <!-- <p class="text-danger fs-14 m-0">Please enter a valid email.
                </p> -->

                <div>
                    <label *ngIf="contributionEmails.length > 0">Selected Emails: </label>
                    <h3>
                        <span class="ml-1 badge text-large badge bg-primary" style="color: black;"
                            *ngFor="let email of contributionEmails">{{email}}
                            &nbsp;<i class="fa fa-close" tappable (click)="removeEmail(email)"> Close</i>
                        </span>
                    </h3>
                    <label *ngIf="contributionEmails.length == 0" class="fs-14 fw-400">No Email added yet</label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline">Copy</button>
                <button type="button" class="btn btn-primary" (click)="sendContLink()">Send</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.moveToUnpaidFirstRecurringModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Confirmation</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <p>Are you sure you want to move the reserved payment to unpaid?</p>
                <div>
                    <label class="fs-14 fw-400 m-0">Notes*</label>
                    <textarea required [(ngModel)]="extra_data.contract.notes" class="input_field"
                        trim="blur"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline">Cancel</button>
                <button class="btn btn-primary" (click)="moveToUnpaidFirstRecurringFn()">Confirm</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.makePaymentDisputed">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Confirmation</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <p>Are you sure you want to make this {{extra_data.contract.dispute_payment_status == 0 ?
                    'payment/service disputed' : 'payment/service resolved'}} disputed ?</p>
                <div>
                    <label class="fs-14 fw-400 m-0">Notes*</label>
                    <textarea required [(ngModel)]="extra_data.contract.notes" class="input_field"
                        trim="blur"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline">Cancel</button>
                <button class="btn btn-primary" (click)="makePaymentDisputedFn()">Confirm</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.changeTransactionFeeThresholdModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <form [formGroup]="changeTransactionFeeThresholdForm">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Change Transaction Fee</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <label class="fs-14 fw-400 m-0">Transaction Fees(%)</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden mb-0">
                                <input formControlName="recurring_extra_percent"
                                    class="input_field border-0 border-end m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" max="100" maxlength="5">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">%</span>
                            </div>
                            <small class="fs-10 fw-400">Range: 0 to 100</small>
                        </div>
                        <div class="  col-sm-6 mb-2">
                            <label class="fs-14 fw-400 m-0">Transaction
                                Fees({{extra_data.currencyData.display_logo}})</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden mb-0">
                                <span
                                    class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">{{extra_data.currencyData.display_logo}}

                                </span>

                                <input formControlName="recurring_extra_amount"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" max="100" maxlength="5">
                            </div>
                            <small class="fs-10 fw-400">Range: 0 to 100</small>
                        </div>
                        <div class="col-sm-12 mb-2">
                            <label class="fs-14 fw-400 m-0">Threshold Transaction Amount</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden mb-0">
                                <span
                                    class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">{{extra_data.currencyData.display_logo}}</span>

                                <input formControlName="threshold_amount"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="100" max="50000" maxlength="5">
                            </div>
                            <small class="fs-10 fw-400">Range: 100 to 50,000</small>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <label class="fs-14 fw-400 m-0">Transaction Fees(%) above Threshold</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden mb-0">
                                <input formControlName="threshold_recurring_extra_percent"
                                    class="input_field border-0 border-end m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" max="100" maxlength="5">

                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">%</span>
                            </div>
                            <small class="fs-10 fw-400">Range: 0 to 100</small>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <label class="fs-14 fw-400 m-0">Transaction Fees({{extra_data.currencyData.display_logo}})
                                above
                                Threshold</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden mb-0">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">%</span>
                                <input formControlName="threshold_recurring_extra_amount"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" max="100" maxlength="5">
                            </div>
                            <small class="fs-10 fw-400">Range: 0 to 100</small>
                        </div>
                        <div class="col-12">
                            <label class="fs-14 fw-600 m-0">Note*</label>
                            <textarea required formControlName="notes" name="notes" class="input_field"
                                trim="true"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline me-3" (click)="cancel()">No</button>
                    <button (click)="changeTransactionFee()" class="btn btn-primary">Yes</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.getOrSendMoneyToBusinessModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <form [formGroup]="getOrSendMoneyToBusinessForm">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">
                        {{extra_data.contract['moneyType'] == 1 ? "Get Money From Business/Partner" : "SendMoney to
                        Business/Partner"}}
                    </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="  col-sm-12 mb-2">
                            <label class="  col-md-3 pl-0">
                                <input required formControlName="isBusinessOrPartnerSend" id="money_business"
                                    class="secondary-radio" type="radio" value="doctor"
                                    (change)="changePartnerDoctorSendMoney()">
                                <label for="money_business" fs-14>Business</label>
                            </label>
                            <label
                                *ngIf="extra_data.contract.partner_details && extra_data.contract.partner_details.length > 0"
                                class="secondary-radio" class="  col-md-3">
                                <input required formControlName="isBusinessOrPartnerSend" id="money_partner"
                                    type="radio" value="partner" (change)="changePartnerDoctorSendMoney()">
                                <label for="money_partner" fs-14>Partner</label>
                            </label>
                        </div>
                        <div *ngIf="(getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == 'partner')"
                            class="  col-sm-12 mb-2">
                            <label style="margin-bottom: 10px;" class="fs-14 fw-400 mb-2">Choose Partner:</label>
                            <div *ngFor="let element of extra_data.contract.partner_details;" class="row">
                                <div class="checkbox c-checkbox   col-sm-6 m-2">
                                    <label>
                                        <input name=element.partner_id type="checkbox" class="secondary-checkbox"
                                            value="{{extra_data.contract.partnerListAmount[element.partner_id]['is_selected']}}"
                                            (change)="changeTransferPartnerList(element.partner_id)">
                                        <label class="fs-14 mb-2">{{element.partner_name}}</label>
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label
                                        *ngIf="extra_data.contract.partnerListAmount[element.partner_id]['is_selected']">
                                        <input style="max-width:80%" name="{{element.partner_id}}"
                                            [(ngModel)]="extra_data.contract.partnerListAmount[element.partner_id]['amount']"
                                            [ngModelOptions]="{standalone: true}" class="input_field" type="text"
                                            step="0.1" min="0.1">
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == 'doctor'"
                            class="col-sm-12 mb-2">
                            <label class="fs-14 fw-400 m-0">Amount</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span
                                    class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">{{extra_data.currencyData.display_logo}}</span>
                                <input formControlName="amount"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" maxlength="5">
                            </div>
                        </div>
                        <div class="  col-sm-12 mb-2">
                            <label class="fs-14 fw-400 m-0">Amount Deducted(to be shown)</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span
                                    class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">{{extra_data.currencyData.display_logo}}</span>
                                <input formControlName="amount_deducted"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" maxlength="5">
                            </div>
                        </div>
                        <div class="  col-sm-12 m-0 mb-2">
                            <div class="checkbox c-checkbox   m-0 mb-2">
                                <label>
                                    <input name="show_to_customer" type="checkbox" id="show_to_customer"
                                        class="secondary-checkbox" formControlName="show_to_customer">
                                    <label for="show_to_customer">Show payment to the customer?</label>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-12 mb-2" *ngIf="getOrSendMoneyToBusinessForm.value.show_to_customer">
                            <label class="fs-14 fw-400 m-0">Payment type for Customer</label>
                            <select formControlName="customer_pt_name" required class="input_field">
                                <option value="Cheque Payment">Cheque Payment</option>
                                <option value="Payment Received in Office">Payment Received in Office</option>
                                <option value="Cash Adjustments">Cash Adjustments</option>
                                <option value="Adjustments">Adjustments</option>
                                <option value="Payment Reconciliation">Payment Reconciliation</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div class="  col-sm-12 mb-2">
                            <label class="fs-14 fw-400 m-0">Payment type for Business</label>
                            <select formControlName="business_pt_name" required class="input_field">
                                <option value="Cheque Payment">Cheque Payment</option>
                                <option value="Payment Received in Office">Payment Received in Office</option>
                                <option value="Cash Adjustments">Cash Adjustments</option>
                                <option value="Adjustments">Adjustments</option>
                                <option value="Payment Reconciliation">Payment Reconciliation</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div *ngIf="(extra_data.contract['moneyType'] == 2 && getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == 'doctor' && extra_data.contract.partner_details && extra_data.contract.partner_details.length > 0)"
                            class="checkbox c-checkbox   col-sm-12 mb-2">
                            <label>
                                <input name=linkPartnerWithDoctorSend type="checkbox" value="linkPartnerWithDoctorSend"
                                    formControlName="linkPartnerWithDoctorSend">
                                <span class="fa fa-check"></span>Send money to partner with this transaction
                            </label>
                        </div>
                        <div *ngIf="(getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == 'doctor') && getOrSendMoneyToBusinessForm.value.linkPartnerWithDoctorSend"
                            class="  col-sm-12 mb-2">
                            <label style="margin-bottom: 10px;">Choose Partner:</label>
                            <div *ngFor="let element of extra_data.contract.partner_details;" class="row">
                                <div class="checkbox c-checkbox   col-sm-6 m-2">
                                    <label>
                                        <input type="checkbox"
                                            value="{{extra_data.contract.partnerListAmount[element.partner_id]['is_selected']}}"
                                            (change)="changeTransferPartnerList(element.partner_id)">
                                        <span class="fa fa-check"></span>{{element.partner_name}}
                                    </label>
                                </div>
                                <div class="col-sm-4">
                                    <label
                                        *ngIf="extra_data.contract.partnerListAmount[element.partner_id]['is_selected']">
                                        <input style="max-width:80%"
                                            [(ngModel)]="extra_data.contract.partnerListAmount[element.partner_id]['amount']"
                                            [ngModelOptions]="{standalone: true}" class="input_field" type="text"
                                            step="0.1" min="0.1">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required formControlName="notes" name="notes" class="input_field"
                            trim="true"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline mr-3" (click)="cancel()">No</button>
                    <button (click)="getAndSendMoneyToBusiness()" class="btn btn-primary">Yes</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.paySettledModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Pay To Business</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="extra_data.contract">
                    <div class="col-lg-3">
                        <div>
                            <label>Connected Account Fee</label>
                            <div class="input-group">
                                <span class="input_field">{{extra_data.contract.payout_fee_percent}}</span>
                                <span class="input-group-addon">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div>
                            <label>Inactive Business Fees</label>
                            <div class="input-group">
                                <span class="input_field">{{extra_data.contract.inactive_fee_percent}}</span>
                                <span class="input-group-addon">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div>
                            <label>Credee Fee</label>
                            <div class="input-group">
                                <span class="input_field">{{extra_data.contract.guarantee_percent}}</span>
                                <span class="input-group-addon">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div>
                            <label>Collection Fee</label>
                            <div class="input-group">
                                <input class="input_field" [(ngModel)]="extra_data.contract.collection_percent" required
                                    (change)="calculateReceivingAmountToDoc()" />
                                <span class="input-group-addon">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div>
                            <label>Enter the principle amount you want to pay to Business:</label>
                            <input type="number" required [(ngModel)]="extra_data.contract.doctorAmount"
                                placeholder="Enter amount here..." class="input_field"
                                (change)="calculateReceivingAmountToDoc()">
                        </div>
                    </div>
                </div>
                <div>
                    <p *ngIf="extra_data.contract.receiveAmount >= 0">
                        <small><b>Business will receive {{extra_data.contract.receiveAmount}} (Principle Amount -
                                [Finance Mutual Fee + Collection Fee + Connected Account Fee + Inactive Business
                                Fees])</b></small>
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline" (click)="cancel()">Cancel</button>
                <button (click)="paySettledPaymentToDoctor()" class="btn btn-primary">Confirm</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.sendContractModal">
    <div class="modal-dialog modal-md  modal-dialog-centered">
        <div class="modal-content border-0">
            <form [formGroup]="sendContractForm">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Send Contract Email</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body" *ngIf="extra_data.contract">
                    <div>
                        <p class="fs-14 fw-400 mb-2">With whom you want to share the contract details?</p>
                        <div class="row mb-2">
                            <div class="col-4 text-left">
                                <label class=" ">
                                    <input required formControlName="send_download_email" type="radio"
                                        class="secondary-radio" id="downnload_email_customer" [value]="2"
                                        (change)="emailContentChanged(sendContractForm.value.send_download_email)">
                                    <label for="downnload_email_customer" class="fs-14 ">Customer</label>
                                </label>
                            </div>
                            <div class="col-4 text-left">
                                <label class=" ">
                                    <input required formControlName="send_download_email" type="radio"
                                        class="secondary-radio" id="downnload_email_business" [value]="1"
                                        (change)="emailContentChanged(sendContractForm.value.send_download_email)">
                                    <label for="downnload_email_business" class="fs-14 ">Business</label>
                                </label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 text-left">
                                <label class="">
                                    <input type="checkbox" formControlName="add_consent_form" id="send_consent_form"
                                        name="consent_form" class="secondary-checkbox" />
                                    <label class="fs-14" for="send_consent_form">Send Consent Form</label>
                                </label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <label class="fs-14 fw-400 m-0">Email Address*</label>
                                <div class="input-group">
                                    <input class="input_field" required formControlName="download_email"
                                        name="download_email">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="" class="fs-14 fw-400 m-0">Notes*</label>
                                <textarea class="input_field" rows="4" name="email_notes" trim="blur" required
                                    formControlName="notes">
                                    </textarea>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-flex">
                        <button type="button" class="btn btn-outlinet mr-3" (click)="cancel()">Cancel</button>
                        <button type="submit" class="btn btn-primary" (click)="sendContract()">Send</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.contractVerificationEmailModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <form [formGroup]="contractVerificationEmailForm" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">
                        {{contractVerificationEmailForm.controls['selected_verification'].value ==
                        contractVerificationTypes['selected_first_comm'] ? 'Send 1st Communication to Business' :
                        contractVerificationEmailForm.controls['selected_verification'].value ==
                        contractVerificationTypes['selected_final_comm'] ? 'Send Final Communication to Business' :
                        contractVerificationEmailForm.controls['selected_verification'].value ==
                        contractVerificationTypes['send_contract'] ? 'Send Contract Verified Email' : 'Contract
                        Verification Email'}}
                    </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <label class="cursor mb-2" (click)="emailContentChangedContractVerification()" class="w-100"><input
                            [value]="contractVerificationTypes['send_contract']" id="send_contract"
                            class="secondary-radio" formControlName="selected_verification" style="margin-right: 9px;"
                            [disabled]="extra_data.contract.download_contract == 1" type="radio" />
                        <label for="send_contract" class="fs-14"> Send ContractVerified Email </label>
                    </label>
                    <label class="cursor mb-2" class="w-100"><input
                            [value]="contractVerificationTypes['selected_first_comm']" id="selected_first_comm"
                            class="secondary-radio" formControlName="selected_verification" style="margin-right: 9px;"
                            [disabled]="extra_data.contract.download_contract == 1" type="radio" />
                        <label for="selected_first_comm" class="fs-14">Send 1st Communication to Business </label>
                    </label>
                    <label class="cursor mb-2" class="w-100"><input
                            [value]="contractVerificationTypes['selected_final_comm']" id="selected_final_comm"
                            class="secondary-radio" formControlName="selected_verification" style="margin-right: 9px;"
                            [disabled]="extra_data.contract.download_contract == 1" type="radio" />
                        <label for="selected_final_comm" class="fs-14">Send Final Communication to Business</label>
                    </label>

                    <div
                        *ngIf="contractVerificationEmailForm.controls['selected_verification'].value == contractVerificationTypes['send_contract']">
                        <div class="  col-12 mb-2">
                            <label class="fs-14 fw-400 mb-2">Customer Email Address*</label>
                            <div class="input-group">
                                <input class="input_field" required formControlName="download_email">
                            </div>
                        </div>
                    </div>

                    <div class="  col-12">
                        <label for="" class="fs-14 fw-400 m-0">Notes*</label>
                        <textarea class="input_field" rows="4" trim="blur" required formControlName="notes"></textarea>
                    </div>
                </div>

                <div class="modal-footer"
                    *ngIf="(contractVerificationEmailForm.controls['selected_verification'].value == contractVerificationTypes['send_contract']) || (contractVerificationEmailForm.controls['selected_verification'].value == contractVerificationTypes['selected_first_comm']) || (contractVerificationEmailForm.controls['selected_verification'].value == contractVerificationTypes['selected_final_comm'])">
                    <div class="d-flex">
                        <button type="button" class="btn btn-outline me-3" (click)="cancel()">Cancel</button>
                        <button type="submit" class="btn btn-primary"
                            (click)="sendVerificationIntimation()">Send</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.downloadContractModal">
    <div class="modal-dialog modal-md  modal-dialog-centered">
        <div class="modal-content border-0">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Download Contract</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body" *ngIf="extra_data.contract">
                <div>
                    <p class="fs-14 fw-400 mb-2">Do you wish to download the contract for the selected individual?</p>
                    <div class="row">
                        <div class="col-4 text-left" *ngIf="extra_data.contract.can_admin_download">
                            <label class=" ">
                                <input required [(ngModel)]="extra_data.contract.download_contract_for" type="radio"
                                    [value]="0" id="download_for_admin" class="secondary-radio">
                                <label for="download_for_admin" class="fs-14">For Admin</label>
                            </label>
                        </div>
                        <div class="col-4 text-left">
                            <label class=" ">
                                <input required [(ngModel)]="extra_data.contract.download_contract_for" type="radio"
                                    [value]="1" id="download_for_business" class="secondary-radio">
                                <label for="download_for_business" class="fs-14">For Business</label>
                            </label>
                        </div>
                        <div class="col-4 text-left">
                            <label class=" ">
                                <input required [(ngModel)]="extra_data.contract.download_contract_for" type="radio"
                                    [value]="2" id="download_for_customer" class="secondary-radio">
                                <label for="download_for_customer" class="fs-14">For Customer</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <div>
                    <button (click)="downloadContract()" class="btn btn-primary">Download</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.reminderTemplateModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content border-0">
            <form [formGroup]="reminderTemplateForm">
                <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                    <h4 class="modal-title text-white fs-18">Send Reminder
                        <span class="badge p-2 bg-danger fs-14"
                            *ngIf="extra_data.contract && extra_data.contract.bankrupt_stop_payment == 1">
                            {{extra_data.contract && extra_data.contract.bankrupt_stop_payment == 1 ?'Bankruptcy
                            Filed':''}}
                        </span>
                        <span class="badge p-2 bg-danger ml-1 fs-14"
                            *ngIf="extra_data.contract && extra_data.contract.dnd == 1">
                            <img src='./assets/img/white.svg' class="dnd" style="height:13px;" /> Do not Disturb
                        </span>
                    </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>

                <div class="modal-body">
                    <div class="col-md-12">
                        <span class="text-warning fs-14">
                            Note: Text Reminder cannot be sent as the phone number of the Customer is invalid </span>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h4 *ngIf="extra_data.contract && extra_data.contract['reminder_data'] && extra_data.contract['reminder_data'].fetchReminderTemplate[0] && extra_data.contract['reminder_data'].fetchReminderTemplate[0].email_sent==1"
                                class="text-danger fs-14 m-0"><i class="fa fa-check" aria-hidden="true"></i>Email
                                Already Sent
                            </h4>
                        </div>
                        <div class="col-md-12">
                            <h4 *ngIf="extra_data.contract && extra_data.contract['reminder_data'].fetchReminderTemplate[0] && extra_data.contract['reminder_data'].fetchReminderTemplate[0].text_sent==1"
                                class="text-danger fs-14 m-0"><i class="fa fa-check" aria-hidden="true"></i>Text Already
                                Sent
                            </h4>
                        </div>
                    </div>
                    <div class="reminder-section row border mt-10" style="margin: 10px auto; padding: 10px 0"
                        *ngIf="extra_data.contract && extra_data.contract.pf_status===3 && extra_data.contract.reminder_data?.fetchReminderTemplate && extra_data.contract.reminder_data?.fetchReminderTemplate.length > 0;else noTemplate">
                        <div class="  mb-0 col-md-6">
                            <label for="reminder_type fs-14 fw-400 mb-2">Reminder Type</label>
                            <select id="reminder_type" class="input_field" formControlName="reminder_id">
                                <option value="{{item.reminder_template_id}}"
                                    *ngFor="let item of extra_data.contract['reminder_data'].fetchReminderTemplate">
                                    {{item.reminder_template_name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3 text-center"
                            *ngIf="extra_data.contract && extra_data.contract['reminder_data'].fetchReminderTemplate[0]  && extra_data.contract['reminder_data'].fetchReminderTemplate[0].mobile_template">
                            <input type="checkbox" id="mobile_reminder" name="mobile_reminder"
                                class="secondary-checkbox" formControlName="mobile"
                                [disabled]="(extra_data.contract && extra_data.contract['reminder_data'].fetchReminderTemplate[0] && extra_data.contract['reminder_data'].fetchReminderTemplate[0].text_sent!=0)">
                            <label for="mobile_reminder" class="fs-14">Text</label>
                        </div>
                        <div class="col-md-3 text-center"
                            *ngIf="extra_data.contract && extra_data.contract['reminder_data'].fetchReminderTemplate[0] && extra_data.contract['reminder_data'].fetchReminderTemplate[0].email_template">
                            <input type="checkbox" id="email_reminder" class="secondary-checkbox"
                                formControlName="email"
                                [disabled]="extra_data.contract && extra_data.contract['reminder_data'].fetchReminderTemplate[0] && extra_data.contract['reminder_data'].fetchReminderTemplate[0].email_sent!=0">
                            <label for="email_reminder" class="fs-14">Email</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <label class="">
                                <input type="checkbox" id="sendNotification"
                                    formControlName="send_customer_notification" class="secondary-checkbox" />
                                <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Send notification to
                                    customer</label>
                            </label>
                        </div>
                    </div>
                </div>
                <ng-template #noTemplate>No Template available.</ng-template>
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="reminderTemplateFn()">Send</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.pausePaymentSMS">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                <h4 class="modal-title text-white fs-18">
                    <span *ngIf="extra_data.contract['cronType'] == 'sms'">
                        {{extra_data.contract.cronPayment && extra_data.contract.cronPayment.sms_status == 1 &&
                        extra_data.contract.cronPayment.sms_availability_status == 1 ? 'Stop SMS Activity' : 'Resume SMS
                        Activity'}}
                    </span>
                    <span *ngIf="extra_data.contract['cronType'] == 'payment'">
                        {{extra_data.contract.cronPayment && extra_data.contract.cronPayment.is_payments_paused == 1
                        ?'Enable Cron Payment Activity' :extra_data.contract.cronPayment &&
                        extra_data.contract.cronPayment.is_payments_paused == 0? 'Disable Cron Payment Activity':''}}
                    </span>
                </h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <div>
                    <label class="fs-14 fw-400 mb-2">Notes*</label>
                    <textarea required [(ngModel)]="extra_data.contract.notes" class="input_field"
                        trim="blur"></textarea>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <!-- <button class="btnsecondary">Cancel</button> -->
                <button class="btn btn-primary" (click)="pausePaymentSMS()">Confirm</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.openAddBankLinkModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <div class="modal-content border-0">
            <form [formGroup]="addBankLinkForm" (submit)="addBankLinkFn()">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Send Add Bank Link</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4>Are you sure, you want to send the add bank account link to the following contact
                            information?</h4>
                        <div>
                            <label>Email Address*</label>
                            <input type="email" required email class="input_field"
                                [ngClass]="{'has-error':addBankLinkForm.controls['email'].invalid && addBankLinkForm.controls['email'].touched}"
                                placeholder="Email Address" formControlName="email">
                            <p *ngIf="addBankLinkForm.controls['email'].invalid && addBankLinkForm.controls['email'].touched"
                                class="text-danger">Please enter a valid email</p>
                        </div>
                        <div>
                            <label class="fs-14 fw-400 m-0">Mobile Number*</label>
                            <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                <span class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center">
                                    {{extra_data.countryData.mobile_code}}
                                </span>
                                <input formControlName="mobile"
                                    class="input_field border-0 border-start m-0 flex-fill rounded-0" type="number"
                                    step="0.01" min="0" maxlength="5">
                            </div>
                        </div>
                        <div>
                            <label class="fs-14 fw-400 m-0">Notes*</label>
                            <textarea required formControlName="notes" class="input_field" trim="true"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary mr-3" type="submit">Send</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.openContractSettlementModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal contract-settlement-modal">
        <div class="modal-content border-0">
            <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                <h4 class="modal-title text-white fs-18">
                    Settlement
                </h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="inner-data settlment-amount"
                *ngIf="extra_data.contract.paid_settlement_amount && extra_data.contract.paid_settlement_amount.length > 0">
                <ul class="m-0">These payments were made by the customer</ul>
                <div class="row">
                    <div class="col-md-4" *ngFor="let value of extra_data.contract.paid_settlement_amount">
                        <ul class="m-0">
                            <li> {{value.payment_process_code }} :
                                {{extra_data.currencyData.country_code}}{{value.amount | number :
                                '1.2-2'}}assets/img/PNG/fileeye.png
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="!showPaymentLinkScreen && !showAddArramgement && !viewArrangements">
                    <div class="inner-data">
                        <ul class="row">
                            <ng-container *ngFor="let value of extra_data.contract.settlement_values">
                                <li class="col-4 fs-14 fw-400 mb-2"> {{value.label }} :
                                    {{extra_data.currencyData.country_code}}{{value.amount |
                                    number : '1.2-2'}}</li>
                            </ng-container>
                        </ul>
                    </div>

                    <form [formGroup]="contractSettlementForm">
                        <div class="mb-2">
                            <label class="fs-14 fw-400 m-0 w-100">Enter Amount</label>
                            <input type="number" formControlName="amount" placeholder="Enter Amount"
                                class="input_field">
                        </div>

                        <div class="mb-2">
                            <label class="fs-14 fw-400 m-0 w-100">
                                Payment Description <i tappable class="fa fa-info-circle"
                                    tooltip=" This Payment Description display to the customer"></i>
                            </label>
                            <textarea cols="30" rows="5" placeholder="Reason" name="reason"
                                formControlName="description" class="input_field"></textarea>
                        </div>

                        <div class="mb-2">
                            <label class="fs-14 fw-400 m-0">
                                Note
                            </label>
                            <textarea cols="30" rows="5" placeholder="Note" name="note" formControlName="notes"
                                class="input_field"></textarea>
                        </div>
                    </form>

                    <div class="mb-4 w-100 d-flex align-items-center justify-content-between" *ngIf="!showNewcard">

                        <button class="btn btn-primary py-2 px-3 rounded-pill"
                            [disabled]=" (contractSettlementForm.value.description && contractSettlementForm.value.description.trim() =='') || contractSettlementForm.value.notes.trim() =='' || !contractSettlementForm.value.amount"
                            (click)="showNewcard=true">Pay Now</button>

                        <button class="btn btn-primary py-2 px-3 rounded-pill"
                            [disabled]="(contractSettlementForm.value.description && contractSettlementForm.value.description.trim() =='') || contractSettlementForm.value.notes.trim() =='' || !contractSettlementForm.value.amount"
                            (click)="showPaymentLinkScreen = true">Send Payment Link </button>

                        <button class="btn btn-primary py-2 px-3 rounded-pill"
                            [disabled]="(contractSettlementForm.value.description && contractSettlementForm.value.description.trim() =='') || contractSettlementForm.value.notes.trim() =='' || !contractSettlementForm.value.amount"
                            (click)="showAddArramgement = true">Add Arrangement</button>

                        <button class="btn btn-primary py-2 px-3 rounded-pill" type="button"
                            (click)="getSettlementArrangement()">View
                            Arrangement</button>

                    </div>
                    <ng-container *ngIf="showNewcard">
                        <!-- <div class="row" *ngIf="paySettlementLast4 == ' '">
                                                            <div class="payment col-md-4">
                                                                <button type="button" (click)="openAddCardModal(i);">+ Add Card</button>
                                                            </div>
                                                            <div class="payment col-md-4">
                                                                <button type="button" (click)="openExistingCardModal(i);">Use Existing Card</button>
                                                            </div>
                                                            <div class="payment col-md-4">
                                                                <button [disabled]="reason_value && reason_value.trim() =='' || note_value.trim() =='' || is_valid_amt"
                                                                    (click)="settlementArrangment();">Add Arrangement</button>
                                                            </div>
                                                        </div>
                                                        <div class="  col-md-4" *ngIf="paySettlementLast4 != ' '">
                                                            <p class="col-12 mb-0 text-left"><i
                                                                    class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{paySettlementLast4}}
                                                            </p>
                                                        </div>
                                                        <div class="pay_button" *ngIf="paySettlementLast4 != ' '">
                                                            <button [disabled]="is_valid_amt"
                                                                (click)="payment_amount(paySettlementCard, paySettlementMetadata);">Pay
                                                                Now</button>
                                                        </div> -->
                    </ng-container>

                    <div class=" bottom-data">
                        <div class="button12">
                            <button class="btn btn-outline" type="button" (click)="cancel()">Cancel</button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="showPaymentLinkScreen">
                    <div class="modal-body body">
                        <div class="name-top same">
                            <input type="text" name='name' placeholder="Name" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="extra_data.contract.patient_name">
                        </div>

                        <div class="email-middle same">
                            <input type="email" name="email" placeholder="Email Address"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="extra_data.contract.patient_email"
                                required>
                        </div>
                        <div class="text-bottom same">
                            <span> {{extra_data.countryData.country_code}} </span>
                            <input cols="30" rows="10" name="textarea" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="extra_data.contract.patient_mobile" />
                        </div>
                    </div>

                    <div class="modal-footer footer">
                        <button type="button" (click)="showPaymentLinkScreen = false">Cancel</button>
                        <button (click)="sendPaymentLink()">Send</button>
                    </div>
                </ng-container>

                <ng-container *ngIf="showAddArramgement">
                    <div class="modal-header ">
                        <h4 class="modal-title"> Add Arrangement </h4>
                        <p class="modal-title mt-1 text-center w-50"> Note: Contract Settlemenet Amount
                            {{extra_data.countryData.country_code}}{{contractSettlementForm.value.amount}}</p>
                    </div>
                    <div class="modal-body" *ngFor="let multiple_item of multiplepayment; let i=index">
                        <div class="row">
                            <div class="  col-md-4">
                                <label><b>Arrangement Date*</b></label>
                                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                                    <input required readonly type="text" #dp="bsDatepicker" bsDatepicker
                                        class="input_field border-0 border-start m-0 flex-fill rounded-0"
                                        placement="top" [(ngModel)]="multiple_item.settlement_arrangement_date"
                                        name="settlement_arrangement_date-{{i}}" [bsConfig]="bsConfig">

                                    <button type="button"
                                        class="bg-transparent border-0 h-100 hw-40 fs-16 fw-600 text-center"
                                        (click)="dp.toggle()">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="  col-md-3">
                                <label><b>Arrangement Amount</b></label>
                                <div class="input-group">
                                    <input required type="number" class="form-control"
                                        [(ngModel)]="multiple_item.settlement_amount_value" placeholder="Enter Amount">
                                </div>
                            </div>
                            <!-- <div class="  col-md-2 mt-10" *ngIf="!multiple_item.settlement_card">
                                    <button type="button" (click)="openAddCardModal(i);" class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent add-card-arrangement">+ Add
                                        Card</button>
                                </div>-->
                            <div class="  col-md-1 mt-10 row" *ngIf="!multiple_item.settlement_card">
                                <button type="button" (click)="show_existing_card = true"
                                    class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent add-card-arrangement">Use
                                    Existing Card</button>
                            </div>
                            <div class="  col-md-4 mt-10" *ngIf="multiple_item.settlement_card">
                                <p class="col-12 mb-0 mt-4"><i
                                        class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{multiple_item.last4}}
                                </p>
                            </div>
                        </div>
                        <div *ngIf="show_existing_card">
                            <div *ngFor="let card of extra_data.contract.payment_sources_list; let i= index"
                                class="col-12 cursor">
                                <div
                                    *ngIf="extra_data.contract.payment_sources_list && extra_data.contract.payment_sources_list.length>0 && card.payment_source_type != 1">
                                    <div class="row border rounded p-2 mb-2 mr-t"
                                        [ngClass]="{'arrangement-selected-card-highlight' : multiplepayment[i]['source_id']==card.source_id}">
                                        <p class="col-6 mb-0"><i
                                                class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                        </p>
                                        <p class="col-3 p-0 d-flex align-items-center">
                                            {{card.brand}}
                                        </p>
                                        <p class="col-3 p-0 text-end"
                                            (click)="multiplepayment[i]['source_id']=card.source_id;"
                                            *ngIf="multiplepayment[i]['source_id']==card.source_id">Selected</p>
                                        <div class="col-3 p-0 text-end"
                                            *ngIf="multiplepayment[i]['source_id']!=card.source_id">

                                            <span class="m-0 mr-2 p-0 "
                                                *ngIf="card.source_id==extra_data.contract.source_id">
                                                <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                            <span class="m-0 mr-2 p-0" *ngIf="card.is_default == 1">
                                                <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                            <button style="font-size: 12px;"
                                                class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                                (click)="addExistingCard(card.source_id, card.last4, i);">Select</button>
                                        </div>
                                        <p class="col-12"
                                            [ngClass]="{'text-danger':card.source_id!=extra_data.contract.source_id}"
                                            *ngIf="card.last_error!='null' && card.last_error!=null">
                                            {{card.last_error.split('=')[0] ||''}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="col-12">Time(PST)*</label>
                            <label *ngFor="let singleTimeSlot of extra_data.contract.arrangementTimeSlot"
                                class="  col-md-3 arrangement-time"
                                [ngClass]="{'checked-label': extra_data.contract.arrangement_time_slot_type == singleTimeSlot.slot_type}">
                                <input required name="settlement_arrangement_time_slot_type-{{i}}"
                                    [(ngModel)]="multiple_item.settlement_arrangement_time_slot_type"
                                    class="arrangement-time" type="radio" value="{{singleTimeSlot.slot_type}}">
                                <span class="fa fa-circle"></span>{{singleTimeSlot.slot_name}}
                                {{singleTimeSlot.slot_time}}
                            </label>
                            <i *ngIf="multiplepayment.length > 1" class="fa fa-trash ml-2 fs-20 cursor"
                                (click)="deleteMultiple(i)"></i>
                            <i *ngIf="i+1 == multiplepayment.length" class="fa fa-plus ml-5 fs-20 cursor"
                                (click)="addMultiple()"></i>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" (click)="settlementArrangementContractFn()">Add
                            Arrangement</button>
                        <!-- <button type="button" (click)="getSettlementArrangement()" class="btn btn-primary">View
                                Arrangement</button> -->
                        <button (click)="showAddArramgement = false">Cancel</button>
                    </div>
                </ng-container>

                <ng-container *ngIf="viewArrangements">
                    <table class="table" *ngIf="settlement_data && settlement_data.length>0">
                        <thead class="text-center">
                            <tr>
                                <th style="font-size: 11px; width: 20px;">No.</th>
                                <th style="font-size: 11px; width: 90px;">Admin Name</th>
                                <th style="font-size: 11px; width: 100px;">Date Added ({{timezoneName}})</th>
                                <th style="font-size: 11px; width: 100px;">Arrangement Date (PST)</th>
                                <th style="font-size: 11px; width: 120px;">Amount</th>
                                <th style="font-size: 11px; width: 115px;">Notes</th>
                                <th style="font-size: 11px; width: 80px;">Status</th>
                                <th style="font-size: 11px; width: 99px;">Action</th>
                                <!-- <th style="font-size: 11px; width: 90px;">Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of settlement_data; let i = index;">
                                <td class="text-center">{{i+1}}</td>
                                <td class="text-center">{{data['admin_name'] || '-' }}</td>
                                <td class="text-center">{{data['date_added'] | date: 'MM/dd/yyyy, hh:mm:ss aaa':
                                    timezone || '-' }}</td>
                                <td class="text-center">{{data['settlement_date'] || '-' }}</td>
                                <td class="text-center">{{data['settlement_amount']}}</td>
                                <td class="text-center">{{data['note']}}</td>
                                <td class="text-center">{{data['settlement_status'] || '-' }}</td>
                                <td>
                                    <button
                                        [disabled]="data['settlement_status'] == 'Inactive' || data['settlement_status'] == 'Executed'"
                                        class="btn btn-primary"
                                        (click)="deleteSettlementArrangement(data['id'])">Inactive
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-responsive table-scroll-250 text-center"
                        *ngIf="!settlement_data || settlement_data.length==0">
                        <table class="table">
                            <thead></thead>
                            <tbody>
                                <tr> <img src="assets/img/SVG/empty/patients.svg">
                                    <h4 style="color:#8592a5">No Data Found</h4>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button (click)="viewArrangements = false">Cancel</button>
                </ng-container>

            </div>

        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.chargeContractModalOpen">
    <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content border-0">
            <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                <h4 class="modal-title text-white fs-18">Charge Contract</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body" *ngIf="extra_data.contract">
                <div class="  mb-3">
                    <label class="fs-14 fw-400 mb-2">Charge Amount({{extra_data.countryData.country_code}})*</label>
                    <input class="form-control col-md-3" readonly [min]="1" type="number"
                        [(ngModel)]="extra_data.charge.cancellationCharge" required />
                </div>
                <div class="  mb-3">
                    <label class="fs-14 fw-400 mb-2" for="">Notes*</label>
                    <textarea class="form-control" rows="3" trim="blur" required [(ngModel)]="extra_data.charge.note"
                        maxlength="400"></textarea>
                    <!-- <p class="text-danger"
                        *ngIf="chargeCancelNotes.invalid && (chargeCancelNotes.dirty||chargeCancelNotes.touched)">
                        Please enter the notes.
                    </p> -->
                </div>
                <div class="  mb-3">
                    <label class="fs-14 fw-400 mb-2">Payment From*</label><br>
                    <label class="  col-md-2 pl-0">
                        <input required [(ngModel)]="extra_data.charge.payment_for" id="from_business"
                            class="secondary-radio" type="radio" value="1"
                            (ngModelChange)="fetchStripeBalance('charge',extra_data.charge.payment_for,extra_data.charge.payment_mode)">
                        <label for="from_business" class="fs-14">Business</label>
                    </label>
                    <label class=" " class="fs-14">
                        <input required [(ngModel)]="extra_data.charge.payment_for" id="from_customer"
                            class="secondary-radio" type="radio" value="2"
                            (ngModelChange)="fetchStripeBalance('charge',extra_data.charge.payment_for,extra_data.charge.payment_mode)">
                        <label for="from_customer">Customer</label>
                    </label>
                </div>
                <div class="  mb-3">
                    <label class="col-12 pl-0 fs-14 fw-400 mb-2">Payment Mode*</label> <br>
                    <label class="   col-md-2 pl-0"
                        *ngIf="extra_data.charge.payment_for!=1 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.show_ach==1">
                        <input required [(ngModel)]="extra_data.charge.payment_mode" id="mode_Bank"
                            class="secondary-radio" type="radio" value="4"
                            (ngModelChange)="fetchStripeBalance('charge',extra_data.charge.payment_for,extra_data.charge.payment_mode)">
                        <label for="mode_Bank" class="fs-14">Bank</label>
                    </label>
                    <label class="  col-md-2 pl-0">
                        <input required [(ngModel)]="extra_data.charge.payment_mode" id="mode_Card"
                            class="secondary-radio" type="radio" value="1"
                            (ngModelChange)="fetchStripeBalance('charge',extra_data.charge.payment_for,extra_data.charge.payment_mode)">
                        <label for="mode_Card" class="fs-14">Card</label>
                    </label>
                    <label class=" " *ngIf="extra_data.charge.payment_for!=2">
                        <input required [(ngModel)]="extra_data.charge.payment_mode" id="mode_Stripe"
                            class="secondary-radio" #paymentMode="ngModel" name="paymentMode" type="radio" value="2"
                            (ngModelChange)="fetchStripeBalance('charge',extra_data.charge.payment_for,extra_data.charge.payment_mode)">
                        <label for="mode_Stripe" class="fs-14">Stripe</label>
                    </label>


                </div>
                <div *ngIf="extra_data.charge.payment_mode==2 && extra_data.charge.payment_for==1">
                    <div class="row">
                        <div class="col-md-3 pr-0">
                            <label class="fs-14 fw-400 mb-2">Stripe
                                Balance({{extra_data.currencyData.display_logo}})</label>
                            <input class="form-control " type="number" [(ngModel)]="extra_data.charge.stripe_balance"
                                #stripeBalance="ngModel" name="stripeBalance" readonly />
                        </div>
                        <div class="col-3 text-end mt-4">
                            <button class="btn btn-primary" type="button"
                                (click)="fetchStripeBalance('charge',extra_data.charge.payment_for,extra_data.charge.payment_mode)">Check</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="extra_data.charge.payment_mode==1">
                    <label class="fs-14 fw-400 mb-2">Card Details</label>
                    <div class="card card-body border p-2 mb-3">
                        <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                            <div *ngFor="let card of extra_data.charge.all_cards; let i= index" class="col-12 cursor">
                                <div
                                    *ngIf="extra_data.charge && extra_data.charge.all_cards.length>0 && card.payment_source_type != 1">
                                    <div class="row border rounded p-2 mb-2 mr-t m-0"
                                        [ngClass]="{'highlight' : extra_data.charge.source_id==card.source_id}">
                                        <p class="col-6 mb-0"><i
                                                class="fa fa-credit-card fs-14"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                        </p>
                                        <p class="col-2 p-0">{{card.brand}}</p>

                                        <p class="col-4 p-0 text-end fs-14 fw-600"
                                            (click)="extra_data.charge.source_id=card.source_id;"
                                            *ngIf="extra_data.charge.source_id==card.source_id">Choose</p>
                                        <div class="col-4 p-0 text-end"
                                            *ngIf="extra_data.charge.source_id!=card.source_id">
                                            <button
                                                class="py-1 px-2 fw-600 fs-14 theme-border-sm theme-text rounded-pill theme-border-md bg-white"
                                                (click)="extra_data.charge.source_id=card.source_id;">Choose</button>
                                        </div>
                                        <p class="col-12"
                                            [ngClass]="{'text-danger':card.source_id!=extra_data.contract.source_id}"
                                            *ngIf="card.last_error!='null' && card.last_error!=null">
                                            {{card.last_error.split('=')[0] ||''}}</p>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="!extra_data.charge.all_cards || extra_data.charge.all_cards.length==0"
                                class="fs-14 fw-400 mb-2">No Card Added Yet</span>
                        </div>
                        <div class="col-md-12 text-end pr-0 mt-4">
                            <!-- <button (click)="showAddCardDiv();AddStripeElement('cancel_contract')"
                                class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent" tappable>Pay
                                with New Card</button> -->
                            <button (click)="showAddCardDivForEditRecurring()"
                                class="py-1 px-2 fw-600 fs-14 theme-border-sm theme-text rounded-pill theme-border-md bg-white"
                                tappable>Pay
                                with New Card</button>
                        </div>

                    </div>
                </div>
                <div *ngIf="extra_data.charge.payment_mode==4">
                    <label class="fs-14 fw-400 mb-2">Bank Details</label>
                    <div class="card card-body border p-2">
                        <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                            <div *ngFor="let card of extra_data.contract.bank_accounts; let i= index"
                                class="col-12 cursor">
                                <div
                                    *ngIf="extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && card.payment_source_type == 1">
                                    <div class="row border rounded p-2 mb-2 mr-t m-0"
                                        [ngClass]="{'highlight' : extra_data.charge.source_id==card.source_id}">
                                        <p class="col-4 mb-0"><i
                                                class="fa fa-bank fs-14"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}}
                                        </p>
                                        <p class="col-4 p-0 d-flex justify-content-between">{{card.brand}}
                                            <span class="m-0 p-0 d-flex align-items-center"
                                                style="color:#36af47"></span>
                                        </p>
                                        <p class="col-4 p-0 text-end fs-14 fw-600"
                                            (click)="extra_data.charge.source_id=card.source_id;"
                                            *ngIf="extra_data.charge.source_id==card.source_id && card.bank_status=='succeeded'">
                                            Choose</p>
                                        <div class="col-4 p-0 text-end"
                                            *ngIf="extra_data.charge.source_id!=card.source_id && card.bank_status=='succeeded'">
                                            <button
                                                class="py-1 px-2 fw-600 fs-14 theme-border-sm theme-text rounded-pill theme-border-md bg-white"
                                                (click)="extra_data.charge.source_id=card.source_id;">Choose</button>
                                        </div>
                                        <p class="col-4 p-0 text-end text-danger fs-14 fw-600"
                                            *ngIf="card.bank_status=='reauthenticate'">Reauthentication Required</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 pl-0" *ngIf="extra_data.charge.payment_mode==4">
                    <p class="text-muted m-b-0">*Note</p>
                    <ul>
                        <li class="text-muted fs-14 fw-400">Please make sure you have the appropriate funds available in
                            your
                            account.</li>
                        <li class="text-muted fs-14 fw-400">Processing time may take up to 7 business days.</li>
                        <li class="text-muted fs-14 fw-400">In the event of a failed transaction, you may be subject to
                            late fees
                            and/or failed transaction fees.</li>
                        <li class="text-muted fs-14 fw-400">Your Next payment is due for
                            {{extra_data.contract.next_payment_date |
                            date:"MM/dd/yyyy": '+0000'}}. If the funds are not realized for the payment done through
                            the Bank before the next payment date we will attempt it.</li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex">
                    <button type="button" class="btn btn-outline me-1" (click)="cancel()">Cancel</button>
                    <button (click)="chargeCancelContract()" type="submit" class="btn btn-primary"
                        [disabled]="(extra_data.charge['payment_mode']==4 && extra_data.contract.bank_accounts && extra_data.contract.bank_accounts.length>0 && extra_data.contract.bank_accounts[0].bank_status =='reauthenticate') || (extra_data.charge && extra_data.charge['payment_mode']!=2 && (!extra_data.charge.source_id))">Pay</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.arrangementModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0">
            <form [formGroup]="arrangementForm" (submit)="arrangementContractFn();">
                <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                    <h4 class="modal-title text-white fs-18"> Add Arrangement </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="  col-md-4">
                        <label><b>Arrangement Date*</b></label>
                        <div class="input-group">
                            <input required readonly type="text" #dp="bsDatepicker" bsDatepicker class="form-control"
                                formControlName="arrangement_date" [bsConfig]="bsConfig">

                            <div class="input-group-append">
                                <button type="button" class="btn btn-outline rounded" (click)="dp.toggle()"><i
                                        class="fa fa-calendar"></i></button>
                            </div>
                        </div>
                        <p class="text-danger"
                            *ngIf="arrangementForm.controls['arrangement_date'].touched && arrangementForm.controls['arrangement_date'].errors && arrangementForm.controls['arrangement_date'].errors['required']">
                            Please select the arrangement date.
                        </p>
                    </div>

                    <div>
                        <label class="col-12">Time(PST)*</label>
                        <label *ngFor="let singleTimeSlot of extra_data.contract.arrangementTimeSlot"
                            class="  col-md-3 arrangement-time"
                            [ngClass]="{'checked-label': arrangementForm.controls['arrangement_time_slot_type'].value == singleTimeSlot.slot_type}">
                            <input required name="arrangement_time_slot_type"
                                formControlName="arrangement_time_slot_type" class="arrangement-time" type="radio"
                                value="{{singleTimeSlot.slot_type}}">
                            <span class="fa fa-circle"></span>{{singleTimeSlot.slot_name}} {{singleTimeSlot.slot_time}}
                        </label>
                    </div>
                    <div class="row col-sm-12">
                        <div class="col-sm-5">
                            <b>No. of Pending Payments</b>
                        </div>
                        <div class="col-sm-2">
                            <!-- <b>OR</b> -->
                            <label class="switch switch-sm m-0">
                                <input type="checkbox" formControlName="onOffCustomAmount"
                                    (change)="onOffCustomAmountFn($event)" />
                                <span></span>
                            </label>
                        </div>
                        <div class="col-sm-5">
                            <b>Custom Amount</b>
                        </div>
                    </div>
                    <!-- <div class="row"><br/></div> -->
                    <div class="row   grid-divider mt-3">
                        <div class="col-sm-6">
                            <div class="col-padding">
                                <div class="row">
                                    <div class="col-5">
                                        <input max="extra_data.contract.remaining_payments" [readonly]="readOnlyNoOfRec"
                                            class="form-control" numbersOnly
                                            formControlName="arrangement_number_recurring"
                                            (ngModelChange)="paymentRecuringNumber(1)" type="number">
                                    </div> <b>Payments</b> <br />
                                    <p class="col-7" style="color: red;"
                                        *ngIf="arrangementForm.controls['arrangement_number_recurring'].value">
                                        <!-- {{contract | json}} -->
                                        Arrangements cannot be more than remaining payments ({{
                                        extra_data.contract.remaining_payments }})
                                    </p>
                                </div>

                                <div class="ms-3"
                                    *ngIf="extra_data.contract && arrangementForm.controls['arrangement_number_recurring'].value">
                                    <div class="row">
                                        <b>(<span>{{(extra_data.contract.recurring_amount || 0 )|currency}}</span>
                                            <span
                                                *ngIf="_contract.GetTransactionFeeContractDetails(extra_data.contract,extra_data.contract.recurring_amount, arrangementForm.controls['arrangement_number_recurring'].value)">+
                                                {{_contract.GetTransactionFeeContractDetails(extra_data.contract,extra_data.contract.recurring_amount,
                                                arrangementForm.controls['arrangement_number_recurring'].value)}}</span>)
                                            X
                                            {{arrangementForm.controls['arrangement_number_recurring'].value}} =
                                            {{extra_data.currencyData.display_logo}}{{(_contract.totalRecurringAmount).toFixed(2)}}
                                        </b>
                                    </div>
                                    <div class="row">
                                        <span
                                            *ngIf="extra_data.contract.missing_payments && extra_data.contract.missing_payments >=1">
                                            <b>Late Fee:
                                                {{extra_data.currencyData.display_logo}}{{extra_data.contract.late_fee |
                                                number}} x {{contractMissingPayment}}=
                                                {{extra_data.currencyData.display_logo}}{{extra_data.contract.late_fee*contractMissingPayment}}</b><br />
                                        </span>
                                    </div>
                                    <div class="row">
                                        <b>Total Amount:
                                            {{extra_data.currencyData.display_logo}}{{(_contract.totalRecurringAmount+(extra_data.contract.late_fee*contractMissingPayment)).toFixed(2)}}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="col-padding">
                                <div class="row">
                                    <b
                                        style=" margin-left: 45px; line-height:30px; font-size:17px; padding-right:4px">{{extra_data.currencyData.display_logo}}</b>
                                    <div class="">
                                        <input (input)="paymentRecuringNumber(2)" [readonly]="readOnlyCustomAmount"
                                            class="form-control" formControlName="arrangement_partial_amount"
                                            type="number">
                                    </div> <b class="ms-1">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip="This payment will treated as a partial payment."></i></b>
                                </div>
                                <div>
                                    <p class="col-7 text-danger ms-4"
                                        *ngIf="arrangementForm.controls['arrangement_partial_amount'].value && arrangementForm.controls['arrangement_partial_amount'].value > extra_data.contract.remaining_amount">
                                        <!-- {{contract | json}} -->
                                        Custom amount cannot be more than total remaining amount
                                        ({{extra_data.currencyData.display_logo}}{{
                                        extra_data.contract.remaining_amount}})
                                    </p>
                                    <p class="col-7 text-danger ms-4"
                                        *ngIf="arrangementForm.controls['arrangement_partial_amount'].value && arrangementForm.controls['arrangement_partial_amount'].value < 1">
                                        <!-- {{contract | json}} -->
                                        Custom amount cannot be less than
                                        {{extra_data.currencyData.display_logo}}1
                                    </p>
                                </div>

                                <div class="col-md-12 row"
                                    *ngIf="extra_data.contract && arrangementForm.controls['arrangement_partial_amount'].value && (arrangementForm.controls['arrangement_partial_amount'].value <= extra_data.contract.remaining_amount &&  arrangementForm.controls['arrangement_partial_amount'].value > 1)">
                                    <div style="margin-left: 40px;">
                                        <b><span>{{(arrangementForm.controls['arrangement_partial_amount'].value || 0
                                                )|currency}}</span>
                                            <span
                                                *ngIf="_contract.GetTransactionFeeContractDetails(extra_data.contract,arrangementForm.controls['arrangement_partial_amount'].value, arrangementForm.controls['arrangement_number_recurring'].value)">+
                                                {{_contract.GetTransactionFeeContractDetails(extra_data.contract,arrangementForm.controls['arrangement_partial_amount'].value,
                                                arrangementForm.controls['arrangement_number_recurring'].value)}}</span>
                                            <br />
                                            Total :
                                            {{extra_data.currencyData.display_logo}}{{(_contract.totalCustomArrangementPayable).toFixed(2)}}
                                        </b>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row col-sm-12">
                        <div class="col-sm-10">
                            <label>Customer Card Details* <br><small>(NOTE: This card will only be applicable for one
                                    time arrangement
                                    payment. This will not make your card default for future recurring.)
                                </small></label>
                        </div>
                        <div class="col-sm-2">
                            <button (click)=" showAddCardDivForEditRecurring()"
                                class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent add-card-arrangement"
                                tappable>+ Add Card</button>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                    </div>
                    <div *ngIf="extra_data.contract.payment_sources_list">
                        <div class="card card-body border p-2">
                            <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                                <div *ngFor="let card of extra_data.contract.payment_sources_list; let i= index"
                                    class="col-12 cursor">
                                    <div
                                        *ngIf="extra_data.contract.payment_sources_list && extra_data.contract.payment_sources_list.length>0 && card.payment_source_type != 1">
                                        <div class="row border rounded p-2 mb-2 mr-t"
                                            [ngClass]="{'arrangement-selected-card-highlight' : source_id==card.source_id}">
                                            <p class="col-6 mb-0"><i
                                                    class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                            </p>
                                            <p class="col-3 p-0 d-flex align-items-center">
                                                {{card.brand}}

                                            </p>
                                            <p class="col-3 p-0 text-end" (click)="source_id=card.source_id;"
                                                *ngIf="source_id==card.source_id">Selected</p>
                                            <div class="col-3 p-0 text-end" *ngIf="source_id!=card.source_id">

                                                <span class="m-0 mr-2 p-0 "
                                                    *ngIf="card.source_id==extra_data.contract.source_id">
                                                    <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                                <span class="m-0 mr-2 p-0" *ngIf="card.is_default == 1">
                                                    <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default</span>
                                                <button
                                                    class="theme-border-sm theme-text py-1 px-2 rounded-pill fs-14 fw-600 bg-transparent"
                                                    (click)="source_id=card.source_id;">Select</button>
                                            </div>
                                            <p class="col-12"
                                                [ngClass]="{'text-danger':card.source_id!=extra_data.contract.source_id}"
                                                *ngIf="card.last_error!='null' && card.last_error!=null">
                                                {{card.last_error.split('=')[0] ||''}}</p>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    *ngIf="!extra_data.contract.payment_sources_list || extra_data.contract.payment_sources_list.length==0">No
                                    Card Added
                                    Yet</span>
                            </div>

                        </div>
                    </div>

                    <div>
                        <label><b>Notes*</b></label>
                        <textarea required formControlName="notes" class="form-control" trim="true"></textarea>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-primary">Add</button>
                    <!-- <button type="button" class="btn btn-outline" (click)="getContractArrangments()">View
                        Arrangements</button> -->
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.viewArrangementsModal">
    <div class="modal-dialog modal-lg modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                <h4 class="modal-title text-white fs-18">Arrangements</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <table class="table"
                *ngIf="extra_data.contract.settlement_data && extra_data.contract.settlement_data.length>0">
                <thead class="text-center">
                    <tr>
                        <th style="font-size: 11px; width: 20px;">No.</th>
                        <th style="font-size: 11px; width: 90px;">Admin Name</th>
                        <th style="font-size: 11px; width: 100px;">Date Added ({{timezoneName}})</th>
                        <th style="font-size: 11px; width: 100px;">Arrangement Date (PST)</th>
                        <th style="font-size: 11px; width: 120px;">Amount</th>
                        <th style="font-size: 11px; width: 115px;">Notes</th>
                        <th style="font-size: 11px; width: 80px;">Status</th>
                        <th style="font-size: 11px; width: 99px;">Action</th>
                        <!-- <th style="font-size: 11px; width: 90px;">Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of extra_data.contract.settlement_data; let i = index;">
                        <td class="text-center">{{i+1}}</td>
                        <td class="text-center">{{data['admin_name'] || '-' }}</td>
                        <td class="text-center">{{data['date_added'] | date: 'MM/dd/yyyy, hh:mm:ss aaa': timezone || '-'
                            }}</td>
                        <td class="text-center">{{data['settlement_date'] || '-' }}</td>
                        <td class="text-center">{{data['settlement_amount']}}</td>
                        <td class="text-center">{{data['note']}}</td>
                        <td class="text-center">{{data['settlement_status'] || '-' }}</td>
                        <td>
                            <button
                                [disabled]="data['settlement_status'] == 'Inactive' || data['settlement_status'] == 'Executed'"
                                class="btn btn-primary" (click)="deleteSettlementArrangement(data['id'])">Inactive
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-responsive table-scroll-250 text-center"
                *ngIf="!extra_data.contract.settlement_data || extra_data.contract.settlement_data.length==0">
                <table class="table">
                    <thead></thead>
                    <tbody>
                        <tr> <img src="assets/img/SVG/empty/patients.svg">
                            <h4 style="color:#8592a5">No Data Found</h4>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <button (click)="cancel()">Cancel</button> -->
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.cardPreferenceModal">
    <div class="modal-dialog modal-lg modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <div class="modal-header bg-primary d-flex justify-content-between align-items-center">
                <h4 class="modal-title text-white fs-18">Payment Method</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body px-4">
                <div class="col-12 p-0">
                    <div>
                        <div class="row m-0 p-3 mb-2 bg-lightgrey radius-4 border">
                            <div class="col-7 p-0">
                                <h4 class="m-0 fs-18">Select Cards/Bank</h4>
                            </div>
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-6 text-center fs-14"
                                        [ngClass]="{'col-5': extra_data.contract.allMethods.length>4, 'col-6': extra_data.contract.allMethods.length<=4}">
                                        Default</div>
                                    <div class="col-6 text-center">
                                        <span class=" m-0">
                                            <input type="checkbox" class="secondary-checkbox"
                                                [(ngModel)]="choose_backup" id="choose_backup"
                                                (change)="disableBackupMethod(choose_backup);" />
                                            <label class="fs-14 fw-600" for="choose_backup">Backup</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0" style="max-height:250px;overflow-y:auto">
                    <div *ngIf="extra_data.contract.allMethods.length>0">
                        <div *ngFor="let card of extra_data.contract.allMethods; let i = index"
                            class="row mx-3 align-items-center">
                            <ng-container
                                *ngIf="card.source_id > 0 && (!card.status || (card.status && card.status=='verified'))">
                                <div class="col-7 p-0">
                                    <div class="row border rounded p-2 mb-2 mr-t bg-primary">
                                        <p *ngIf="card.payment_source_type!=1" class="mt-1 pl-2 col-6"><i
                                                class="fa fa-credit-card me-1"></i>XXXX-XXXX-XXXX-{{card.last4}}
                                        </p>
                                        <p *ngIf="card.payment_source_type==1" class="mt-1 pl-2 col-6"><i
                                                class="fa fa-bank me-1"></i>XXXX-XXXX-{{card.last4}}
                                        </p>
                                        <p class="col-2">
                                            <span
                                                [style.color]="_contract.currentYear>card.exp_year || ( _contract.currentMonth>card.exp_month && _contract.currentYear===card.exp_year )?'red':''"
                                                class="fa mt-1  ps-3 pt-1"
                                                *ngIf="card.exp_month<10 && card.payment_source_type!=1">
                                                <i></i>0{{card.exp_month}}/{{card.exp_year}}
                                            </span>
                                            <span
                                                [style.color]="  _contract.currentYear>card.exp_year || ( _contract.currentMonth>card.exp_month && _contract.currentYear===card.exp_year )?'red':''"
                                                class="fa mt-1  pl-s pt-1"
                                                *ngIf="card.exp_month>=10 && card.payment_source_type!=1">
                                                <i></i>{{card.exp_month}}/{{card.exp_year}}
                                            </span>
                                        </p>
                                        <p class="m-0 text-end col mt-1"
                                            *ngIf="card.source_id == extra_data.contract.source_id || card.source_id == extra_data.contract.secondary_source_id">
                                            {{card.source_id == extra_data.contract.source_id ? 'Default Method' :
                                            card.source_id == extra_data.contract.secondary_source_id ? 'Backup Method'
                                            : ''}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="row">
                                        <div class="col-6 text-center">
                                            <span class=" ">
                                                <input class="secondary-radio" name="new_source_id" required
                                                    [(ngModel)]="new_source_id" type="radio" [value]="card.source_id"
                                                    id="new_source_id{{i}}">
                                                <label class="fs-14 fs-400 m-0" for="new_source_id{{i}}"></label>
                                            </span>
                                        </div>
                                        <div class="col-6 text-center">
                                            <span class=" ">
                                                <input class="secondary-radio" name="new_secondary_source_id" required
                                                    [(ngModel)]="new_secondary_source_id" type="radio"
                                                    [value]="card.source_id"
                                                    [attr.disabled]="choose_backup ? null : true"
                                                    id="new_secondary_source_id{{i}}">
                                                <label class="fs-14 fs-400 m-0"
                                                    for="new_secondary_source_id{{i}}"></label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="  mt-3">
                    <label class="fs-14 fw-600">Note*</label>
                    <textarea required [(ngModel)]="extra_data.contract.notes" class="input_field" trim="blur"
                        trim="blur"></textarea>
                </div>
                <!-- <div class="  col-12 pl-0"
                    *ngIf="!_shared.routerAuth.restricted_buttons.option_to_deduct_one_dollar">
                    <label class=" m-0">
                        <input type="checkbox" [(ngModel)]="deduct_one_dollar" name="deduct_one_dollar" />
                        <span class="fa fa-check"></span>Deduct {{this.mainService.currency_data.country_code}}1 for
                        authorization
                    </label>
                </div> -->
                <span>
                    <b class="fs-14 fw-600">*Note:</b>
                    <ul class="m-0 p-0 ms-4 fs-14 fw-400">
                        <li>You will be charged a {{extra_data.currencyData.display_logo}}1 or 1% (whichever is higher)
                            as extra transaction fee if you
                            use a non-{{extra_data.currencyData.display_logo}}
                            debit/credit card.</li>
                        <li *ngIf="extra_data.contract.deduct_one_dollar">{{extra_data.countryData.country_code}}1 will
                            be
                            deducted and reversed to check the Card
                            Authorization.</li>
                    </ul>
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline" (click)="reset()">Reset</button>
                <button type="button" class="btn btn-primary" (click)="changePaymentMethods()">Save</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.chargeDoctorModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <form [formGroup]="chargeDoctorform" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18 fw-500"> Charge Business Confirmation </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 class="fs-18 fw-500">Are you sure you want to charge the Business for this contract?</h4>
                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Contract Special Notes :
                            {{extra_data.contract.contract_special_notes ? extra_data.contract.contract_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Business Special Notes :
                            {{extra_data.contract.doctor_special_notes ? extra_data.contract.doctor_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class="row">
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Business DND : {{extra_data.contract.Business_dnd != 0 ?
                                "Yes" : "No" }} </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer DND : {{extra_data.contract.dnd != 0 ? "Yes" :
                                "No"}}
                            </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer Bankruptcy :
                                {{extra_data.contract.bankrupt_stop_payment != 0 ? "Yes" : "No"}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6">

                        <div class=" mb-3">
                            <label class="fs-14 fw-600 m-0">Business Stripe Balance</label>
                            <input [readonly]="!onOffCustomAmount" class="input_field" type="number"
                                formControlName="Business_stripe_balance" name="Business_stripe_balance"
                                #BusinessStripeBalance>
                        </div>

                    </div>

                    <div class="row col-sm-12">
                        <div class="col-sm-2.5">
                            <span *ngIf="!onOffCustomAmount" class="fs-14 fw-600 m-0">Suggested Amount</span>
                            <span *ngIf="onOffCustomAmount" class="fs-14 fw-600 m-0">Enter Amount</span>
                        </div>
                        <div class="col-sm-2">
                            <label class="switch switch-sm m-0">
                                <input type="checkbox" name="onOffCustomAmount" [(ngModel)]="onOffCustomAmount"
                                    (change)="OnOffButton($event)" />
                                <span></span>
                            </label>
                        </div>
                    </div>

                    <div class="row form-group grid-divider mt-3">
                        <div class="col-sm-6">
                            <div class="col-padding">
                                <div class="row">
                                    <div class="col-12">
                                        <input [readonly]="!onOffCustomAmount" class="form-control" type="number"
                                            formControlName="manual_amount" name="manual_amount"
                                            (ngModelChange)="setTwoNumberDecimal(manual_amount)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="col-padding">
                                <div class="row">
                                    <b style=" line-height: 33px; margin-left: 4px; ">
                                        <i *ngIf="!onOffCustomAmount" class="fa fa-info-circle" data-container="body"
                                            data-placement="top"
                                            tooltip="The amount is calculated by Credee based on Disputed amount/Transaction fees/Dispute Fees "></i>
                                        <i *ngIf="onOffCustomAmount" class="fa fa-info-circle" data-container="body"
                                            data-placement="top"
                                            tooltip="Manually enter an amount to charge the Business"></i>
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="col-7" style="color: red;"
                        *ngIf="(( 0 >= chargeDoctorform.value.manual_amount ) || (chargeDoctorform.value.manual_amount > extra_data.contract.maxManualAmount)) && chargeDoctorform.value.manual_amount!=null && onOffCustomAmount">
                        Amount should be between $1 and ${{extra_data.contract.maxManualAmount}}
                    </p>
                    <div class="form-group"
                        *ngIf="extra_data.contract && extra_data.contract.is_in_unpaid_section && extra_data.contract.is_in_unpaid_section == 1">
                        <span>
                            <input type="checkbox" class="secondary-checkbox" name="is_remove_from_unpaid"
                                formControlName="is_remove_from_unpaid" id="is_remove_from_unpaid" />
                            <label for="is_remove_from_unpaid" class="fs-14 fw-400 m-0">Remove Business share from
                                unpaid</label>
                        </span>
                    </div>
                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required name="notes" formControlName="notes" class="form-control"
                            trim="true"></textarea>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <label class="mb-2">

                                <input type="checkbox" id="sendNotification" formControlName="skip_email"
                                    class="secondary-checkbox" />
                                <label for="sendNotification" class="fs-14 fw-400 m-0 mb">Do not send email to
                                    Business</label>

                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">No</button>
                    <button class="btn btn-primary" (click)="chargeDoctorfn()">Yes</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.sendWarningMail">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <form [formGroup]="sendWarningMailform" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18 fw-500"> Send Warning Dispute Email Confirmation </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 class="fs-18 fw-500">Are you sure you want to send warning dispute email for this contract ?
                        </h4>
                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Contract Special Notes :
                            {{extra_data.contract.contract_special_notes ? extra_data.contract.contract_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Business Special Notes :
                            {{extra_data.contract.doctor_special_notes ? extra_data.contract.doctor_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class="row">
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Business DND : {{extra_data.contract.Business_dnd != 0 ?
                                "Yes" : "No" }} </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer DND : {{extra_data.contract.dnd != 0 ? "Yes" :
                                "No"}}
                            </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer Bankruptcy :
                                {{extra_data.contract.bankrupt_stop_payment != 0 ? "Yes" : "No"}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6">

                        <div class=" mb-3">
                            <label class="fs-14 fw-600 m-0">Business Stripe Balance</label>
                            <input readonly="true" class="input_field bg-lightgrey" type="number"
                                formControlName="Business_stripe_balance" name="Business_stripe_balance">
                        </div>

                    </div>


                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required name="notes" formControlName="notes" class="form-control"
                            trim="true"></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">No</button>
                    <button class="btn btn-primary" (click)="sendWarningMailfn()">Yes</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.chargeDispute">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <form [formGroup]="chargeDisputeform" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18 fw-500"> Charge Dispute Fee from Customer Account
                        Confirmation </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">

                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Contract Special Notes :
                            {{extra_data.contract.contract_special_notes ? extra_data.contract.contract_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Business Special Notes :
                            {{extra_data.contract.doctor_special_notes ? extra_data.contract.doctor_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class="row">
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Business DND : {{extra_data.contract.Business_dnd != 0 ?
                                "Yes" : "No" }} </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer DND : {{extra_data.contract.dnd != 0 ? "Yes" :
                                "No"}}
                            </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer Bankruptcy :
                                {{extra_data.contract.bankrupt_stop_payment != 0 ? "Yes" : "No"}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6">

                        <div class=" mb-3">
                            <label class="fs-14 fw-600 m-0">Business Stripe Balance</label>
                            <input readonly="true" class="input_field bg-lightgrey" type="number"
                                formControlName="Business_stripe_balance" name="Business_stripe_balance">
                        </div>

                    </div>


                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required name="notes" formControlName="notes" class="form-control"
                            trim="true"></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">cancel</button>
                    <button class="btn btn-primary" (click)="chargeDisputefn()">Send</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.refundPopmodal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <form [formGroup]="refundPopform" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18 fw-500"> Refund Business Confirmation </h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 class="fs-18 fw-500">Are you sure you want to charge the dispute fee from the Customer
                            account for this contract ?</h4>
                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Contract Special Notes :
                            {{extra_data.contract.contract_special_notes ? extra_data.contract.contract_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Business Special Notes :
                            {{extra_data.contract.doctor_special_notes ? extra_data.contract.doctor_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class="row">
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Business DND : {{extra_data.contract.Business_dnd != 0 ?
                                "Yes" : "No" }} </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer DND : {{extra_data.contract.dnd != 0 ? "Yes" :
                                "No"}}
                            </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer Bankruptcy :
                                {{extra_data.contract.bankrupt_stop_payment != 0 ? "Yes" : "No"}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6">

                        <div class=" mb-3">
                            <label class="fs-14 fw-600 m-0">Business Stripe Balance</label>
                            <input readonly="true" class="input_field bg-lightgrey" type="number"
                                formControlName="Business_stripe_balance" name="Business_stripe_balance">
                        </div>

                    </div>


                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required name="notes" formControlName="notes" class="form-control"
                            trim="true"></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">cancel</button>
                    <button class="btn btn-primary" (click)="refundPopfn()">Send</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.moveRemoveUnpaidmodal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <form [formGroup]="refundPopform" class="w-100">
            <div class="modal-content border-0">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18 fw-500"> {{extra_data.contract.move_to_unpaid == 0 ? 'Move
                        to Unpaid' : 'Remove from Unpaid'}}</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 class="fs-18 fw-500">Are you sure you want this payment to
                            {{extra_data.contract.move_to_unpaid == 0 ? 'Move to Unpaid' : 'Remove from Unpaid'}} </h4>
                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Contract Special Notes :
                            {{extra_data.contract.contract_special_notes ? extra_data.contract.contract_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class=" col-12 mt-3 mb-2">

                        <label class="fs-14 fw-600 m-0">Business Special Notes :
                            {{extra_data.contract.doctor_special_notes ? extra_data.contract.doctor_special_notes :
                            'N.A' }}
                        </label>

                    </div>
                    <div class="row">
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Business DND : {{extra_data.contract.Business_dnd != 0 ?
                                "Yes" : "No" }} </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer DND : {{extra_data.contract.dnd != 0 ? "Yes" :
                                "No"}}
                            </label>
                        </div>
                        <div class="form-group col-12 mb-2">
                            <label class="fs-14 fw-600 m-0">Customer Bankruptcy :
                                {{extra_data.contract.bankrupt_stop_payment != 0 ? "Yes" : "No"}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6">

                        <div class=" mb-3">
                            <label class="fs-14 fw-600 m-0">Business Stripe Balance</label>
                            <input readonly="true" class="input_field bg-lightgrey" type="number"
                                formControlName="Business_stripe_balance" name="Business_stripe_balance">
                        </div>

                    </div>


                    <div>
                        <label class="fs-14 fw-400 m-0">Note*</label>
                        <textarea required name="notes" formControlName="notes" class="form-control"
                            trim="true"></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="cancel()">cancel</button>
                    <button class="btn btn-primary" (click)="moveRemoveUnpaidPopFn()">Send</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>


<ng-container *ngIf="modalType == _contract.modalTypes.editServiceBreakdownModel">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <div class="modal-content border-0">
            <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                <h4 class="modal-title text-white fs-18">Edit Contract Service Breakdown </h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
            </div>
            <div class="modal-body">
                <div class="py-3">
                    <div>
                        <label for="" class="fs-14 fw-400 m-0">Service Name</label>
                        <div class="d-flex align-item-center input_field p-0">
                            <ul #serviceContent
                                class="p-0 decoration-none  overflow-auto d-flex align-items-center hide-scroll-bar m-0 service-list"
                                *ngIf="serviceBreakDowns && serviceBreakDowns.length">
                                <li *ngFor="let item of serviceBreakDowns; let i = index"
                                    class="teeeest px-2 py-1 radius-4 mx-1 d-flex align-items-center">
                                    <span>{{item.service}} </span>
                                    <img style="margin-top: 1px;" src="assets/images/icons/cross-icon.svg" alt=""
                                        class="hw-16 cursor" (click)="removeService(i)">
                                </li>
                            </ul>
                            <span class="d-flex flex-fill align-items-center ps-2 border-start">
                                <input type="text" class="flex-fill border-0 input_field m-0" placeholder="Enter Srvice Name"
                                    [(ngModel)]="serviceName" (keyup.enter)="addNewService()" >
                                <img src="assets/images/icons/corner-down-left.png" alt="" class="hw-16">
                            </span>
                        </div>
                    </div>
                    <span class="fs-14 fw-400 m-0 font-italic mt-3">
                        *You can add multiple services by just pressing enter.
                    </span>
                </div>

                <div>
                    <label class="fs-14 fw-400 m-0">Note*</label>
                    <textarea required name="notes" class="input_field" trim="true"
                        [(ngModel)]="editServiceNotes"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary mr-3" type="submit" (click)="updateServices()">Update</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.dndModal">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="extra_data.contract">
            <form [formGroup]="dndForm" (ngSubmit)="submitDNDForm()">
                <div class="modal-header theme-green-bg d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Confirmation</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="cancel()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to {{extra_data.contract.dnd == 0 ? 'enable' : 'disable'}} Do Not Disturb ?
                    </p>

                    <div>
                        <span>
                            <input id="contract" formControlName="contractCustomerType" type="radio" [value]="0"
                                class="secondary-radio">
                            <label for="contract" class="fs-14">This Contract Only</label>
                        </span>
                        <span>
                            <input id="customer" formControlName="contractCustomerType" type="radio" [value]="1"
                                class="secondary-radio">
                            <label for="customer" class="fs-14">Customer Profile</label>
                        </span>
                    </div>

                    <div class="mt-3">
                        <label>Notes*</label>
                        <textarea required formControlName="notes" class="form-control" trim="blur"></textarea>
                    </div>

                    <div>
                        <p class="fs-14 mt-3">
                            Note:If you choose Customer profile then it will apply on Customer profile as well as on all
                            contracts
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline">Cancel</button>
                    <button class="btn btn-primary" type="submit">Confirm</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="modalType == _contract.modalTypes.addNotesModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <div class="modal-content border-0">
            <form [formGroup]="addNotesForm" (ngSubmit)="addNotes()">
                <div class="modal-header bg-primary d-flex align-items-center justify-content-between">
                    <h4 class="modal-title text-white fs-18">Add Note</h4>
                    <img src="assets/images/icons/white-cross.svg" alt="" (click)="hide()" class="cursor hw-24">
                </div>
                <div class="modal-body">
                    <div class="form-group mb-0">
                        <label class="fs-14 fw-400 m-0">Name</label>
                        <input type="text" required formControlName="name" name="name" class="input_field" trim="blur">
                        <p class="text-danger"
                            *ngIf="addNotesForm.get('name')?.touched && addNotesForm.get('name')?.invalid">Please enter
                            note
                            name.</p>
                    </div>
                    <div class="form-group mb-0">
                        <label class="fs-14 fw-400 m-0">Notes</label>
                        <textarea required formControlName="notes" name="notes" class="input_field"
                            trim="blur"></textarea>
                    </div>
                    <p class="text-danger"
                        *ngIf="addNotesForm.get('notes')?.touched && addNotesForm.get('notes')?.invalid">
                        Please add notes.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="hide()">Cancel</button>
                    <button class="btn btn-primary" [disabled]="addNotesForm.invalid">Save</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>