<ng-container *ngIf="leads_data">
  <h3 class="fs-18" (click)="location.back()" style="cursor: pointer">
    &#60; Back
  </h3>
  <h1>Lead Details</h1>
  <div style="background-color: #ffffff; padding: 10px" class="mb-5">
    <p>
      <b style="color: black">{{ leads_data.status }}</b>
      <b
        style="color: black; cursor: pointer"
        *ngIf="
          leads_data.status_id == 4 &&
          leads_data.patient_financing &&
          leads_data.patient_financing.length
        "
      >
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        Contract ID:
        <a
          class="padd_r5"
          [routerLink]="
            '/app/contracts/' + leads_data.patient_financing[0].contract_id
          "
        >
          {{ leads_data.patient_financing[0].contract_code }}
        </a></b
      >
      <span *ngIf="leads_data.status_id == 3">
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        <b style="color: black">{{
          leads_data.resend_link_data.length > 0
            ? "Application Sent"
            : "Application not Sent"
        }}</b>
      </span>
      <span
        *ngIf="
          leads_data.status_id == 3 &&
          leads_data.resend_link_data &&
          leads_data.resend_link_data[0]
        "
      >
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        Application Sent date :<b>
          {{
            leads_data.resend_link_data[0].date_added
              | date : "MM-dd-yyyy" : timezone
          }}</b
        >
      </span>
      <span *ngIf="leads_data.status_id == 2">
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        <b style="color: black">{{
          leads_data.followup[0].lead_type == 1
            ? "Cold
          Lead"
            : leads_data.followup[0].lead_type == 2
            ? "Warm
          Lead"
            : leads_data.followup[0].lead_type == 3
            ? "Hot
          Lead"
            : ""
        }}</b>
      </span>
      <span *ngIf="leads_data.status_id == 2">
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        Follow-up scheduled :<b>
          {{
            leads_data.followup[0].follow_up_date_time_utc
              | date : "MM-dd-yyyy" : timezone
          }}</b
        >
        at
        <b>{{
          leads_data.followup[0].follow_up_date_time_utc
            | date : "h:mm a" : timezone
        }}</b>
      </span>
      <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
      <span>
        Lead request date:
        {{ leads_data.date_added | date : "MM-dd-yyyy" : timezone }}</span
      >
      <span
        *ngIf="
          leads_data.status_id == 4 &&
          leads_data.patient_financing &&
          leads_data.patient_financing.length
        "
      >
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        Converted on:
        {{
          leads_data.patient_financing[0].date_added
            | date : "MM-dd-yyyy" : timezone
        }}
      </span>
      <span class="mt-lg-2 mt-xl-0">
        <span style="color: #e5e5e5"> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
        Application ID:
        <b style="color: black"> {{ leads_data.application_id }}</b>
      </span>
    </p>
    <div class="text-right">
      <button
        class="btn btn-primary rounded my-2 ml-2 blink"
        (click)="setvaluesResendCode()"
        *ngIf="leads_data.status_id != 4"
      >
        Resend Application
      </button>
      <button
        class="btn btn-primary rounded my-2 ml-2 blink"
        (click)="EditCustomer()"
        *ngIf="leads_data.status_id != 4"
      >
        Edit details
      </button>
      <button
        class="btn btn-primary rounded my-2 ml-2 blink"
        (click)="addContractNotesModal()"
      >
        Add Notes
      </button>
      <button
        class="btn btn-danger rounded my-2 ml-2 blink"
        (click)="deleteRequest()"
        *ngIf="leads_data.status_id != 4"
      >
        Delete
      </button>
    </div>

    <h2 class="m-2">
      {{
        (leads_data.customer_first_name ? leads_data.customer_first_name : "") +
          " " +
          (leads_data.customer_last_name ? leads_data.customer_last_name : "")
      }}
    </h2>
    <p class="mx-2">
      <span class="mr-5" *ngIf="leads_data.customer_email">{{
        leads_data.customer_email
      }}</span>
      <span *ngIf="leads_data.customer_mobile">{{
        leads_data.customer_mobile
          ? country_info &&
            country_info.mobile_code + "-" + leads_data.customer_mobile
          : ""
      }}</span>
    </p>
    <div class="border row p-3 my-3 mx-2">
      <h3 id="manage-doctors" class="col-12 p-0 m-0 fs-16 mb-3">
        Business Owner Details
      </h3>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Name</span>
        <h3 class="fs-14">
          {{ leads_data.doctor_name || leads_data.practice_name }}
        </h3>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Email</span>
        <h3 class="fs-14">
          <a
            class="padd_r5"
            [routerLink]="'/app/doctors/profile/' + leads_data.doctor_id"
            >{{ leads_data.doctor_email }}</a
          >
        </h3>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Phone</span>
        <h3 class="fs-14">
          {{
            leads_data.doctor_mobile
              ? country_info &&
                country_info.mobile_code + "-" + leads_data.doctor_mobile
              : leads_data.practice_mobile
              ? country_info &&
                country_info.mobile_code + "-" + leads_data.practice_mobile
              : "-"
          }}
        </h3>
      </div>
    </div>
    <div class="border row p-3 my-3 mx-2">
      <h3 id="manage-doctors" class="col-12 p-0 m-0 fs-16 mb-3">
        Personal Details
      </h3>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>DL Number</span>
        <h3 class="fs-14">{{ leads_data.dl_number || "-" }}</h3>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Date of birth</span>
        <h3 class="fs-14">
          {{ (leads_data.date_of_birth | date : "MM-dd-yyyy") || "-" }}
        </h3>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>SSN</span>
        <div class="d-flex">
          <h3 class="fs-14" *ngIf="!show_full_ssn">
            {{ leads_data && leads_data.ssn ? leads_data.ssn : "-" }}
          </h3>
          <h3 class="fs-14" *ngIf="show_full_ssn">
            {{ leads_data && leads_data.temp_ssn ? leads_data.temp_ssn : "-" }}
          </h3>
          <span
            class="eyebtn"
            type="button"
            *ngIf="
              leads_data?.ssn &&
              !show_full_ssn &&
              !_shared?.routerAuth?.restricted_buttons?.show_full_ssn
            "
            (click)="fullSSNModal()"
          >
            <i class="fa fa-eye"></i>
          </span>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Gender</span>
        <h3 class="fs-14">
          {{
            leads_data.customer_gender == 1
              ? "Male"
              : leads_data.customer_gender == 2
              ? "Female"
              : leads_data.customer_gender == 3
              ? "Others"
              : "-"
          }}
        </h3>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Address</span>
        <h3 class="fs-14">
          {{ leads_data.customer_address
          }}{{ leads_data.customer_city ? ", " + leads_data.customer_city : ""
          }}{{
            leads_data.customer_state ? ", " + leads_data.customer_state : ""
          }}{{ leads_data.customer_zip ? ", " + leads_data.customer_zip : ""
          }}{{
            leads_data.customer_country_code
              ? ", " + leads_data.customer_country_code
              : ""
          }}
        </h3>

        <h3 class="fs-14" *ngIf="!leads_data.customer_address">-</h3>
      </div>
    </div>
    <div class="border row p-3 my-3 mx-2">
      <h3 id="manage-doctors" class="col-12 p-0 m-0 fs-16 mb-3">
        Employment Details
      </h3>
      <h3 *ngIf="!leads_data.employement_type" class="fs-14">-</h3>
      <ng-container *ngIf="leads_data.employement_type == 1">
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Employer Name</span>
          <h3 class="fs-14">
            {{ leads_data.employer_name ? leads_data.employer_name : "-" }}
          </h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Employer Phone</span>
          <h3 class="fs-14">
            {{
              leads_data.employer_mobile
                ? country_info &&
                  country_info.mobile_code + "-" + leads_data.employer_mobile
                : "-"
            }}
          </h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Employer Address</span>
          <h3
            class="fs-14"
            *ngIf="
              leads_data.employer_address &&
              leads_data.employer_city &&
              leads_data.employer_state &&
              leads_data.employer_zip
            "
          >
            {{ leads_data.employer_address
            }}{{
              leads_data.employer_city ? ", " + leads_data.employer_city : ""
            }}{{
              leads_data.employer_state ? ", " + leads_data.employer_state : ""
            }}{{ leads_data.employer_zip ? ", " + leads_data.employer_zip : ""
            }}{{
              leads_data.employer_country_code
                ? ", " + leads_data.employer_country_code
                : ""
            }}
          </h3>
          <h3
            class="fs-14"
            *ngIf="
              !(
                leads_data.employer_address &&
                leads_data.employer_city &&
                leads_data.employer_state &&
                leads_data.employer_zip
              )
            "
          >
            -
          </h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Annual Income</span>
          <h3 class="fs-14">
            {{
              leads_data.annual_income
                ? (leads_data.annual_income | currency)
                : "-"
            }}
          </h3>
        </div>
      </ng-container>
      <ng-container *ngIf="leads_data.employement_type == 2">
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Employment Information</span>
          <h3 class="fs-14">NO</h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Payback Sources</span>
          <h3 class="fs-14">
            {{ leads_data.payback_methods ? leads_data.payback_methods : "-" }}
          </h3>
        </div>
      </ng-container>
      <ng-container *ngIf="leads_data.employement_type == 3">
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Employment Information</span>
          <h3 class="fs-14">Self Employed</h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Profession</span>
          <h3 class="fs-14">{{ leads_data.profession || "-" }}</h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Office Address</span>
          <h3 class="fs-14">
            {{ leads_data.office_address
            }}{{ leads_data.office_city ? ", " + leads_data.office_city : ""
            }}{{ leads_data.office_state ? ", " + leads_data.office_state : ""
            }}{{ leads_data.office_zip ? ", " + leads_data.office_zip : ""
            }}{{
              leads_data.office_country_code
                ? ", " + leads_data.office_country_code
                : ""
            }}
          </h3>
          <h3 class="fs-14" *ngIf="!leads_data.office_address">-</h3>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
          <span>Annual Income</span>
          <h3 class="fs-14">
            {{
              leads_data.annual_income
                ? (leads_data.annual_income | currency)
                : "-"
            }}
          </h3>
        </div>
      </ng-container>
    </div>

    <div class="border row p-3 my-3 mx-2">
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Required Services</span>
        <h3 class="fs-14">{{ leads_data.service || "-" }}</h3>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 p-0">
        <span>Best Time To Contact</span>
        <h3 class="fs-14">
          {{ leads_data.time_to_contact ? leads_data.time_to_contact : "-" }}
        </h3>
      </div>
    </div>
    <div *ngIf="leads_data && leads_data.business_logs">
      <div
        class="notes"
        *ngFor="let item of leads_data.business_logs; let i = index"
      >
        <div class="notes_topbar">
          <span>
            {{ item.text }}
            <b> {{ item.date_to_sort | date : "MM/dd/yyyy" : timezone }}</b> at
            <b class="notranslate">{{
              item.date_to_sort | date : "h:mm a" : timezone
            }}</b>
            <b *ngIf="item.admin_id"> (By Admin)</b></span
          >
        </div>
        <div class="notes__box" *ngIf="item.notes">
          <p class="mb-0">
            Note: <span> {{ item.notes }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="!leads_data">
      <img src="./assets/img/SVG/empty/patients.svg" />
      <h4 style="color: #8592a5">No data Found</h4>
    </div>
  </div>
</ng-container>

<!--All NOtes-->

<div
  class="text-center"
  *ngIf="leads_data && leads_data.application_id && !load_notes"
>
  <button
    class="btn btn-primary rounded my-2 blink"
    (click)="load_notes = true"
  >
    View notes
  </button>
</div>
<app-common-notes-listing
  [notesId]="leads_data.application_id"
  [type]="'preapproval_leads'"
  [data]="leads_data"
  *ngIf="leads_data && load_notes"
></app-common-notes-listing>

<ng-container *ngIf="modalType == _customer.modalTypes.fullSSNForm">
  <div class="modal-dialog modal-md">
    <div class="modal-content border-0">
      <form [formGroup]="fullSSNForm" (ngSubmit)="verifyFullSSN()">
        <div class="modal-header">
          <h4 class="modal-title">View Complete SSN</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="">Password</label>
            <div class="input-group">
              <input
                class="form-control"
                [type]="showPwd ? 'text' : 'password'"
                formControlName="password"
                placeholder="Password"
                required
              />
              <div
                class="input-group-append"
                tappable
                (click)="showPwd = !showPwd"
              >
                <div class="input-group-text">
                  <i class="fa fa-eye" *ngIf="showPwd"></i>
                  <i class="fa fa-eye-slash" *ngIf="!showPwd"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Note:</label>
            <textarea
              class="form-control"
              formControlName="note"
              cols="30"
              rows="4"
              trim="true"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div class="ml-auto">
              <button
                type="button"
                class="btn btn-outline-secondary mr-4"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="fullSSNForm.invalid"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
