<h3 class="m-0 fs-24 fw-700 text-secondary mb-3">Assignments</h3>
<div class="mb-2 border-bottom">
  <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/assignments/my-assignments'">My Assignments</button>
  <button class="tab fs-14" [class.active]="assignmentType==4" [routerLink]="'/app/assignments'" [queryParams]="{type:4}">Contracts</button>
  <button class="tab fs-14" [class.active]="assignmentType==1" [routerLink]="'/app/assignments'" [queryParams]="{type:1}">Businesses</button>
  <button class="tab fs-14" [class.active]="assignmentType==2" [routerLink]="'/app/assignments'" [queryParams]="{type:2}">Customers</button>
  <!-- <button class="tab fs-14" [class.active]="assignmentType==3" [routerLink]="'/app/assignments'" [queryParams]="{type:3}">Partners</button> -->
  <!-- <button class="tab" [class.active]="assignmentType==9" [routerLink]="'/app/assignments'" [queryParams]="{type:7}">Payment Processing</button> -->
  <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/assignments'" *ngIf="!_shared.routerAuth.restricted_buttons.proposal_requests" [queryParams]="{type:8}">Debt Recovery</button>
  <button class="tab fs-14" [class.active]="assignmentType==6" [routerLink]="'/app/assignments'" [queryParams]="{type:6}">Callback Requests</button>
  <button class="tab fs-14" [class.active]="assignmentType==5" [routerLink]="'/app/assignments'" [queryParams]="{type:5}">Inquiries</button>
  <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/training/training-list'">Trainings</button>
  <!-- <button class="tab" routerLinkActive="active" [routerLink]="'/app/leads/lead-assignments'">Leads</button> -->
  <!-- <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/marketing'">Marketing Requests</button> -->
  <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/assignments'" *ngIf="!_shared.routerAuth.restricted_buttons.proposal_requests" [queryParams]="{type:13}">Proposal Requests</button>
  <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/internal-tickets'" *ngIf="!_shared.routerAuth.restricted_buttons.internal_tickets">Internal Tickets</button>
  <button class="tab fs-14" routerLinkActive="active" [routerLink]="'/app/assignments/phase-ticket'">Phase Tickets</button>
  <!-- <button class="tab fs-14" [class.active]="assignmentType==12" [routerLink]="'/app/assignments'" [queryParams]="{type:12}">Pre-Approval Leads</button> -->
  <!-- <button class="tab" routerLinkActive="active" [routerLink]="'/app/assignments/subscription'" [queryParams]="{type:10}">Subscription</button> -->
  <!-- <button class="tab" routerLinkActive="active" [routerLink]="'/app/enquiries'">Enquiries</button> -->
</div>