import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from './components/shared/toaster/toaster.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { localStorageService } from './components/shared/localstorage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription!: Subscription;
  constructor(private router: Router,private route: ActivatedRoute, private _toastr: ToasterService, private _local: localStorageService) { }
  ngOnInit(): void {
    this.routerSubscription = this.router.events.
      pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this._toastr.hideAllToasters();
      }
      if (event instanceof NavigationEnd && event.url && !event.url.includes('is_dummy')) {
        // console.log(this._local.customGetItem('is_dummy'));
        // console.log(this._local.customGetItem('country_id'));
        // console.log(this._local.customGetItem('timezone'));
        this.updateQueryParams(this._local.customGetItem('is_dummy'), this._local.customGetItem('country_id') || 1, this._local.customGetItem('timezone'));
      }
    });
  }

  // Method to add or update the query parameters
  updateQueryParams(is_dummy: string, country_id: string, timezone: string): void {
    // Get the existing query params from the current route
    const queryParams = { ...this.route.snapshot.queryParams };

    // Update the dummy and country_selected parameters
    queryParams['is_dummy'] = is_dummy;
    queryParams['country_id'] = country_id;
    queryParams['timezone'] = timezone;

    // Use router to update the query params without reloading the page
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',  // Merge with existing query params
      replaceUrl: true               // Replace URL in history without adding a new entry
    });
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
