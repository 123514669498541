import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ContractService } from '../contracts.service';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonModule, DatePipe } from '@angular/common';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ConfirmationModalComponent } from 'src/app/components/shared/confirmation-modal/confirmation-modal.component';
import { GenerateStripeElementComponent } from 'src/app/components/shared/generate-stripe-element/generate-stripe-element.component';
import { PaymentSourcesComponent } from 'src/app/components/shared/payment-sources/payment-sources.component';
import { ClipboardModule } from 'ngx-clipboard';

@Component({
  selector: 'app-contract-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BsDatepickerModule, PaymentSourcesComponent, ClipboardModule],
  templateUrl: './contract-modal.component.html',
  styleUrl: './contract-modal.component.scss'
})

export class ContractModalComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  modalType = '';
  public form!: UntypedFormGroup;
  public chargeLateFeeForm!: UntypedFormGroup;
  public editRecurringDateForm!: UntypedFormGroup;
  public partialPaymentForm!: UntypedFormGroup;
  public payOffForm!: UntypedFormGroup;
  public cancelContractForm!: UntypedFormGroup;
  public chargeDoctorform!: UntypedFormGroup;
  public sendWarningMailform!: UntypedFormGroup;
  public refundPopform!: UntypedFormGroup;
  public chargeDisputeform!: UntypedFormGroup;
  public moveRemoveUnpaidform!: UntypedFormGroup;
  public makeProtectedNonProtected!: UntypedFormGroup;
  public nextRecAmountForm!: UntypedFormGroup;
  public changeTransactionFeeThresholdForm!: UntypedFormGroup;
  public getOrSendMoneyToBusinessForm!: UntypedFormGroup;
  public sendContractForm!: UntypedFormGroup;
  public contractVerificationEmailForm!: UntypedFormGroup;
  public reminderTemplateForm!: UntypedFormGroup;
  public addBankLinkForm!: UntypedFormGroup;
  public contractSettlementForm!: UntypedFormGroup;
  public arrangementForm!: UntypedFormGroup;
  public dndForm!: UntypedFormGroup;
  public addNotesForm!: UntypedFormGroup;

  public setup_id = '';
  public setUpCard: any;
  public extra_data!: modal_data;
  note = '';
  minDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
  actual_edit_recurring_amount = 0;
  dnd_apply_on = 0;
  minAmountToPay = 0;
  maxAmountToPay = 0;
  skip_email: any;

  is_remove_from_unpaid: any;
  payment_source_type = 0;
  source_id = 0;
  contract_changes: any = {
    edit_treatment_amount: 0,
    treatment_amount: null,
    edit_finance_amount: 0,
    finance_amount: null,
    edit_number_of_payments: 0,
    number_of_payments: null,
    edit_remaining_payments: 0,
    remaining_payments: null,
    edit_recurring_amount: 0,
    recurring_amount: null,
    edit_principal_per_recurring: 0,
    principal_per_recurring: null,
    edit_downpayment_amount: 0,
    downpayment_amount: null,
    edit_payment_date: 0,

    payment_date: null,
    edit_pf_status: 0,
    pf_status: null,
    cancelled_date: null,
    reverse_first_recurring: 0,
    notes: ''
  };
  contributionEmails: any = [];
  selectedLanguage = 'en';
  contractVerificationTypes = {
    send_contract: 'send_contract',
    selected_first_comm: 'selected_first_comm',
    selected_final_comm: ''
  }
  timezone: string = '';
  Business_stripe_balance: any;
  timezoneName: string = '';

  // settlement variables
  showNewcard = false;

  onOffCustomAmount: any = 0;
  manual_amount: any;

  settlement_data = [];
  showPaymentLinkScreen = false;
  showAddArramgement = false;
  multiplepayment = [{ "settlement_arrangement_date": new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), "settlement_amount_value": 0, "settlement_arrangement_time_slot_type": '', "source_id": 0, "stripe_token": '', "stripe_metadata": '', "settlement_card": false, "last4": 0 }]
  show_existing_card = false;
  viewArrangements = false;
  new_source_id = 0;
  new_secondary_source_id = 0;
  choose_backup = false;
  editServiceForm: any;
  serviceBreakDowns: { service: string }[] = [];
  serviceName: string = ''
  editServiceNotes: string = '';

  @ViewChild('serviceContent', { read: ElementRef }) public serviceContent!: ElementRef<any>;

  constructor(public _contract: ContractService, private _fb: FormBuilder, public bsModalRef: BsModalRef, private _local: localStorageService, private _shared: SharedService, private _api: ApiService, private _datePipe: DatePipe, private modalService: BsModalService) {
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement: 'bottom'
    });
  }

  ngOnInit(): void {
    if (this.modalType == this._contract.modalTypes.bankruptcyModal || this.modalType == this._contract.modalTypes.m2rReportModal) {
      this.form = this._fb.group({
        notes: ['', Validators.required],
        disableEnable: [this.modalType == this._contract.modalTypes.bankruptcyModal ? this.extra_data.contract.bankrupt_stop_payment : 0, Validators.required]
      })
    }

    if (this.modalType == this._contract.modalTypes.dndModal) {
      this.dndForm = this._fb.group({
        notes: ['', Validators.required],
        contractCustomerType: [0, Validators.required]
      })
    }

    if (this.modalType == this._contract.modalTypes.chargeLateFeeModal) {
      this.chargeLateFeeForm = this._fb.group({
        lateFeeCount: [this.extra_data.contract.missing_payments, Validators.required],
        lateFeeCharge: [this.extra_data.contract.late_fee, Validators.required],
        totalLateFee: [((this.extra_data.contract.missing_payments * this.extra_data.contract.late_fee) || 0), Validators.required],
        note: ['', Validators.required],
        payment_for: [this.extra_data.recurring.payment_for, Validators.required],
        payment_mode: [this.extra_data.recurring.payment_mode, Validators.required],
        stripe_balance: [this.extra_data.recurring.stripe_balance],
      })
    }

    if (this.modalType == this._contract.modalTypes.editRecurringModal) {
      this.editRecurringDateForm = this._fb.group({
        recurringDate: ['', Validators.required],
        editRecurringAmount: [0, Validators.required],
        late_fee: [0, Validators.required],
        total_amount: [0, Validators.required],
        send_customer_notification: [false],
        continue_deferred_benefits: [false],
        note: ['', Validators.required],
        payment_for: [0, Validators.required],
        payment_mode: [0, Validators.required],
        stripe_balance: [this.extra_data.recurring.stripe_balance],
      })
    }

    if (this.modalType == this._contract.modalTypes.partialPaymentModal || this.modalType == this._contract.modalTypes.payMoreModal) {
      this.partialPaymentForm = this._fb.group({
        note: ['', Validators.required],
        partial_amount: [0, Validators.required],
        send_customer_notification: [false]
      })
      this.minAmountToPay = this.extra_data.contract && this.extra_data.contract.recurring_amount * (this.extra_data.contract && this.extra_data.contract.doctor_settings && this.extra_data.contract.doctor_settings[0] && this.extra_data.contract.doctor_settings[0].di_pay_more_percentage / 100);

      this.maxAmountToPay = (this.extra_data.contract && (this.extra_data.contract.recurring_amount * this.extra_data.contract.remaining_payments));
    }

    if (this.modalType == this._contract.modalTypes.payOffModal) {
      this.payOffForm = this._fb.group({
        note: ['', Validators.required],
        send_customer_notification: [false]
      })
    }

    if (this.modalType == this._contract.modalTypes.cancelContractModal) {
      this.cancelContractForm = this._fb.group({
        reserve_payment: [true],
        send_customer_notification: [false],
        notes: ['']
      })
    }


    if (this.modalType == this._contract.modalTypes.chargeDoctorModal) {
      this.chargeDoctorform = this._fb.group({
        Business_stripe_balance: [this.extra_data.contract.stripe_balance],
        is_remove_from_unpaid: [0],
        manual_amount: [this.extra_data.contract.charge_from_doctor, Validators.required],
        notes: ['', Validators.required],
        skip_email: [0]
      })
    }

    if (this.modalType == this._contract.modalTypes.sendWarningMail) {
      this.sendWarningMailform = this._fb.group({
        Business_stripe_balance: [this.extra_data.contract.stripe_balance],
        notes: ['', Validators.required],

      })
    }

    if (this.modalType == this._contract.modalTypes.chargeDispute) {
      this.chargeDisputeform = this._fb.group({
        Business_stripe_balance: [this.extra_data.contract.stripe_balance],
        notes: ['', Validators.required],

      })
    }

    if (this.modalType == this._contract.modalTypes.refundPopmodal) {
      this.refundPopform = this._fb.group({
        Business_stripe_balance: [this.extra_data.contract.stripe_balance],
        notes: ['', Validators.required],

      })
    }

    if (this.modalType == this._contract.modalTypes.moveRemoveUnpaidmodal) {
      this.moveRemoveUnpaidform = this._fb.group({

        Business_stripe_balance: [this.extra_data.contract.stripe_balance],
        notes: ['', Validators.required],

      })
    }

    if (this.modalType == this._contract.modalTypes.makeProtectedNonProtected) {
      this.makeProtectedNonProtected = this._fb.group({
        notes: ['']
      })
    }

    if (this.modalType == this._contract.modalTypes.nextRecAmountModal) {
      this.nextRecAmountForm = this._fb.group({
        notes: ['', Validators.required],
        late_fee: [],
        send_customer_notification: [false]
      })
    }

    if (this.modalType == this._contract.modalTypes.changeTransactionFeeThresholdModal) {
      this.changeTransactionFeeThresholdForm = this._fb.group({
        notes: ['', Validators.required],
        recurring_extra_amount: [this.extra_data.contract.recurring_extra_amount || 0],
        recurring_extra_percent: [this.extra_data.contract.recurring_extra_percent || 0],
        threshold_amount: [this.extra_data.contract.threshold_amount || 0],
        threshold_recurring_extra_amount: [this.extra_data.contract.threshold_recurring_extra_amount || 0],
        threshold_recurring_extra_percent: [this.extra_data.contract.threshold_recurring_extra_percent || 0]
      })
    }

    if (this.modalType == this._contract.modalTypes.getOrSendMoneyToBusinessModal) {
      this.getOrSendMoneyToBusinessForm = this._fb.group({
        isBusinessOrPartnerSend: ['doctor'],
        amount: [0],
        amount_deducted: [0],
        show_to_customer: [true],
        customer_pt_name: [''],
        business_pt_name: [''],
        linkPartnerWithDoctorSend: [false],
        notes: ['']
      })
    }

    if (this.modalType == this._contract.modalTypes.sendContractModal) {
      this.sendContractForm = this._fb.group({
        notes: [''],
        send_download_email: [0],
        add_consent_form: [false],
        download_email: ['']
      })
    }

    if (this.modalType == this._contract.modalTypes.contractVerificationEmailModal) {
      this.contractVerificationEmailForm = this._fb.group({
        selected_verification: [this.contractVerificationTypes.send_contract],
        download_email: [''],
        notes: ['']
      })
    }

    if (this.modalType == this._contract.modalTypes.reminderTemplateModal) {
      this.reminderTemplateForm = this._fb.group({
        reminder_id: [''],
        mobile: [''],
        email: [''],
        send_customer_notification: [false],
      })
    }

    if (this.modalType == this._contract.modalTypes.openAddBankLinkModal) {
      this.addBankLinkForm = this._fb.group({
        mobile: [this.extra_data.contract['patient_mobile']],
        email: [this.extra_data.contract['patient_email']],
        notes: ['', Validators.required],
      })
    }

    if (this.modalType == this._contract.modalTypes.openContractSettlementModal) {
      this.contractSettlementForm = this._fb.group({
        amount: [this.extra_data.contract['amount']],
        description: [this.extra_data.contract['description']],
        notes: ['', Validators.required],
      })
    }

    if (this.modalType == this._contract.modalTypes.arrangementModal) {
      this.arrangementForm = this._fb.group({
        arrangement_date: [this.extra_data.today_date || ''],
        arrangement_time_slot_type: [''],
        onOffCustomAmount: [false],
        arrangement_number_recurring: [0],
        arrangement_partial_amount: [0],
        arrangement: [''],
        notes: ['', Validators.required]
      });
    }

    if (this.modalType == this._contract.modalTypes.editServiceBreakdownModel) {
      this.serviceBreakDowns = this.extra_data.contract['service_record']
    }

    if (this.modalType == this._contract.modalTypes.cardPreferenceModal) {
      this.reset();
    }

    if (this.modalType == this._contract.modalTypes.addNotesModal) {
      this.addNotesForm = this._fb.group({
        name: ['', Validators.required],
        notes: ['', Validators.required]
      });
    }
  }

  submitDNDForm() {
    const params = {
      pf_id: this.extra_data.contract.pf_id,
      dnd: this.extra_data.contract.dnd == 1 ? 0 : 1,
      notes: this.dndForm.value.notes,
      cd_payment_type: this.extra_data.contract.dnd == 0 ? 38 : 39,
      action_type: this.extra_data.contract.dnd == 1 ? 2 : 1,
      dnd_apply_on: this.dndForm.value.contractCustomerType,
      contract_id: this.extra_data.contract.contract_id,
      patient_id: this.extra_data.contract.patient_id
    };
    console.log(this.dndForm.value);
    this._api.api(params, 'finance/toggle_dnd_contract')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.passEntry.emit({ success: true });
          this.hide();
        }
      })
  }

  onLateFeeChange() {
    this.chargeLateFeeForm.patchValue({
      totalLateFee: this.chargeLateFeeForm.controls['lateFeeCount'].value * this.chargeLateFeeForm.controls['lateFeeCharge'].value
    })
  }

  // hide modal common function
  hide() {
    this.bsModalRef.hide()
  }

  // hide modal without any action
  cancel() {
    this.passEntry.emit({ success: false });
    this.hide();
  }

  resetCardData(type: 'recurring' | 'charge' = 'recurring') {
    this.extra_data[type]['source_id'] = undefined;
    this.extra_data[type]['recCardName'] = undefined;
    this.extra_data[type]['card_number'] = undefined;
    this.extra_data[type]['card_month'] = undefined;
    this.extra_data[type]['card_year'] = undefined;
    this.extra_data[type]['recCardCVV'] = undefined;
    this.extra_data[type]['new_address1'] = undefined;
    this.extra_data[type]['new_address2'] = undefined;
    this.extra_data[type]['zip_code'] = undefined;
    this.extra_data[type]['state'] = undefined;
    this.extra_data[type]['city'] = undefined;
  }

  // bankruptcyFiled modal api function
  submit() {
    let params: any = {
      status: this.form.value.disableEnable,
      contract_id: this.extra_data.contract.contract_id,
      notes: this.form.value.notes,
      cd_payment_type: 26,
      name: this._local.customGetItem('admin_name')
    };
    let apiName = 'finance/bankrupt_stop_payment';
    if (this.modalType == this._contract.modalTypes.m2rReportModal) {
      apiName = 'finance/report_to_credit_bureaus';
      params = {
        m2r_report_status: this.form.value.m2rReportStatus === 0 ? '0' : '1',
        contract_id: this.extra_data.contract.contract_id,
        notes: this.form.value.notes,
      };
    }
    this._api.api(params, apiName)
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      })
  }

  onLateFeeDateChange() {
    this.editRecurringDateForm.patchValue({
      total_amount: parseFloat(this.editRecurringDateForm.controls['editRecurringAmount'].value + this.editRecurringDateForm.controls['late_fee'].value).toFixed(2)
    })
  }

  // pay_pf_contract api
  reviveContractFn(card: any) {
    if (!this.note || this.note.trim().length == 0) {
      this._shared.customPopups('Please enter a valid notes.', 2)
      return;
    }
    const data = {
      contract_id: parseInt(this.extra_data.contract.contract_id),
      source_id: card.source_id,
      notes: this.note,
      start_date: this._datePipe.transform(this.extra_data.contract.revive_payment_date, 'yyyy-MM-dd'),
      payment_source_type: card.payment_source_type
    };
    this._api.api(data, 'pay_pf')
      .subscribe((response: any) => {
        if (response.is_error === 0) {
          if (card.payment_source_type == 1) {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          } else {
            this._shared.customPopups('Payment Charged Successfully', 0);
          }
          this.hide();
          this.passEntry.emit({ success: true });
        } else {
          this._shared.customPopups(response.original_text, 0);
        }
      });
  }

  fetchStripeBalance(colType: 'recurring' | 'charge', type: number, mode: number, formName?: FormGroup) {
    if (type == 2 && mode == 1) {
      this.extra_data[colType]['all_cards'] = [];
      this.extra_data[colType]['all_cards'] = this.extra_data.contract['payment_sources'];
      this.extra_data[colType]['paymentMode'] = 1;
      this.extra_data[colType].patient_check_address = undefined;
      this.extra_data[colType].doctor_check_address = undefined;
      this.resetCardData(colType);
    } else if (type == 1 && mode == 1) {
      this.extra_data[colType]['all_cards'] = [];
      this.extra_data[colType]['paymentMode'] = 2;
      this.extra_data[colType].patient_check_address = undefined;
      this.extra_data[colType].doctor_check_address = undefined;
      this.resetCardData(colType);
      let data = {
        doctor_id: this.extra_data.contract.doctor_id
      };
      this._api.api(data, 'business/list_all_cards', 'get')
        .subscribe((data: any) => {
          if (data.is_error == 0) {
            this.extra_data[colType]['all_cards'] = data.data;
          }
        })
    } else if (type == 1 && mode == 2) {
      this.extra_data[colType]['paymentMode'] = 3;
      this.resetCardData(colType);
      this.extra_data[colType].patient_check_address = undefined;
      this.extra_data[colType].doctor_check_address = undefined;
      let data = {
        doctor_id: this.extra_data.contract.doctor_id
      };
      this._api.api(data, 'finance/fetch_doctor_balance', 'get')
        .subscribe((data: any) => {
          if (data.is_error == 0) {
            this.extra_data[colType]['stripe_balance'] = data.doctor_balance;
            if (formName) {
              formName.patchValue({
                stripe_balance: data.doctor_balance
              })
            }
          }
        })
    } else if (type == 2 && mode == 4) {
      this.extra_data[colType]['paymentMode'] = 4;
    } else {
      // console.log("11111")
      this.extra_data[colType]['paymentMode'] = undefined;
      this.extra_data[colType].patient_check_address = undefined;
      this.extra_data[colType].doctor_check_address = undefined;
      this.resetCardData(colType);
    }
  }

  //  Charge Late Fee
  chargeLateFee() {
    if (this.chargeLateFeeForm.controls['lateFeeCount'].value > this.extra_data.contract.missing_payments || this.chargeLateFeeForm.controls['lateFeeCharge'].value > this.extra_data.contract.late_fee) {
      return false;
    }
    if (this.chargeLateFeeForm.controls['lateFeeCount'].value < 1 || parseFloat(this.chargeLateFeeForm.controls['lateFeeCount'].value) % 1 != 0) {
      this._shared.customPopups('Please enter a valid late fee count', 2);
      return false;
    }
    if (!this.chargeLateFeeForm.controls['lateFeeCharge'].value || this.chargeLateFeeForm.controls['lateFeeCharge'].value < 0) {
      this._shared.customPopups('Please enter a valid late fee charge', 2);
      return false;
    }

    if (this.chargeLateFeeForm.controls['payment_for'].value == 2 && (!this.chargeLateFeeForm.controls['payment_mode'].value || this.chargeLateFeeForm.controls['payment_mode'].value == 2)) {
      this._shared.customPopups('Please select payment mode', 2);
      return false;
    }
    if (this.chargeLateFeeForm.controls['payment_for'].value == 1 && !this.chargeLateFeeForm.controls['payment_mode'].value) {
      this._shared.customPopups('Please select payment mode', 2);
      return false;
    }
    if ((this.chargeLateFeeForm.controls['payment_for'].value == 1 || this.chargeLateFeeForm.controls['payment_for'].value == 2) && ((this.chargeLateFeeForm.controls['payment_mode'].value == 1 && !this.extra_data.recurring.source_id) && (this.chargeLateFeeForm.controls['payment_mode'].value == 1))) {
      this._shared.customPopups('Please select card or pay with new card', 2);
      return false;
    }
    const data = {
      pf_id: this.extra_data.contract.pf_id,
      missing_count: this.chargeLateFeeForm.controls['lateFeeCount'].value,
      amount: this.chargeLateFeeForm.controls['lateFeeCharge'].value,
      notes: this.chargeLateFeeForm.controls['note'].value,
      cd_payment_type: 7,
      name: this._local.customGetItem('admin_name'),
      payment_mode: parseInt(this.extra_data.recurring.paymentMode),      /////payment_mode  ->  paymentMode
      source_id: this.extra_data.recurring.paymentMode != 3 ? parseInt(this.extra_data.recurring.source_id) : undefined,
    };
    if (this.extra_data.recurring.paymentMode == 4) {
      data.source_id = parseInt(this.extra_data.recurring.source_id);
    }
    this.chargeLateFeeApiFn(data);
    return;
  }

  // Charge Contract
  chargeLateFeeApiFn(data: any) {
    // return;
    this._api.api(data, 'business/deduct_missing_payments_late_fees')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.extra_data.recurring['source_id'] = null;
          if (this.extra_data.recurring['paymentMode'] != 4) {
            this._shared.customPopups('Late Fee Charged Successfully', 0);
          } else {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          }
        }
        this.hide();
        this.passEntry.emit({ success: true });
      })
  }

  showAddCardDivForEditRecurring() {
    const modelRef = this.modalService.show(GenerateStripeElementComponent, {
      initialState: {
        stripeData: {
          patient_profile: this.extra_data.contract,
          payment_source_type: this._shared.paymentSourceType.CARD,
          currency_data: this.extra_data.currencyData,
          contract_id: this.extra_data.contract.contract_id,
          pf_id: this.extra_data.contract.pf_id
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })

    modelRef.content?.returnStripeData.subscribe(stripeResp => {
      if (stripeResp.success) {
        if (this.modalType == this._contract.modalTypes.chargeLateFeeModal) {
          this.extra_data.recurring.all_cards.push(stripeResp.selectedCard);
          this.extra_data.recurring['source_id'] = stripeResp.selectedCard.source_id;
          // this.chargeLateFee();
        } else if (this.modalType == this._contract.modalTypes.editRecurringModal) {
          this.extra_data.recurring.all_cards.push(stripeResp.selectedCard);
          this.extra_data.recurring['source_id'] = stripeResp.selectedCard.source_id;
          this.editRecurringContract();
        } else if (this.modalType == this._contract.modalTypes.arrangementModal) {
          this.extra_data.contract.payment_sources_list.push(stripeResp.selectedCard);
          this.source_id = stripeResp.selectedCard.source_id;
          this.arrangementContractFn();
        } else if (this.modalType == this._contract.modalTypes.nextRecAmountModal) {
          this.source_id = stripeResp.selectedCard.source_id;
          this.extra_data.contract.payment_sources.push(stripeResp.selectedCard);
        } else if (this.modalType == this._contract.modalTypes.chargeContractModalOpen) {
          this.extra_data.charge.all_cards.push(stripeResp.selectedCard);
          this.extra_data.charge.source_id = stripeResp.selectedCard.source_id;
        }
      }
    })
  }

  onDateChange() {
    let params = {
      contract_id: this.extra_data.contract.contract_id,
      new_recurring_date: this._datePipe.transform(this.editRecurringDateForm.controls['recurringDate'].value, 'yyyy-MM-dd')
    };
    const d1 = moment(this.editRecurringDateForm.controls['recurringDate'].value).startOf('day');
    const d2 = moment(this.extra_data.contract.next_payment_date).endOf('day');
    this.extra_data.recurring['hideBtn'] = d1.isBefore(d2);
    this._api.api(params, 'finance/check_edit_recurring_date_amount')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.actual_edit_recurring_amount = data.amount;
          const lateFee = data.late_fee_ob && data.late_fee_ob.late_fee ? (data.late_fee_ob.late_fee * data.late_fee_ob.missing_payments) : this.extra_data.recurring.late_fee;
          this.editRecurringDateForm.patchValue({
            editRecurringAmount: data.amount,
            late_fee: lateFee,
            total_amount: parseFloat((data.amount + lateFee).toFixed(2))
          })
        } else {
          this.editRecurringDateForm.patchValue({
            editRecurringAmount: 0,
            payment_mode: 0,
            payment_for: 0,
          })
          this._shared.customPopups(data.error, 1);
        }
      })
  }

  // edit recurring contract data function wrapper for new card added 
  editRecurringContractFn(token_data: any) {
    let data = {
      contract_id: this.extra_data.contract.contract_id,
      stripe_token: token_data.token || this.extra_data.recurring.stripe_token,
      payment_mode: parseInt(this.extra_data.recurring['paymentMode']),
      note: this.editRecurringDateForm.value.note,
      amount: this.editRecurringDateForm.value['editRecurringAmount'],
      cd_payment_type: 10,
      next_payment_date: this._datePipe.transform(this.editRecurringDateForm.value['recurringDate'], 'yyyy-MM-dd'),
      pf_id: this.extra_data.contract.pf_id,
      name: this._local.customGetItem('admin_name'),
      notes: this.editRecurringDateForm.value.note,
      late_fee: this.editRecurringDateForm.value.lateFee ? this.editRecurringDateForm.value.lateFee : 0,
    };
    this.editRecurringFn(data);
  }
  // edit recurring contract data function
  editRecurringContract() {
    let data;
    if (this.editRecurringDateForm.value['total_amount'] > 0) {
      if (this.editRecurringDateForm.value['payment_for'] == 2 && (!this.editRecurringDateForm.value['payment_mode'] || this.editRecurringDateForm.value['payment_mode'] == 2)) {
        this._shared.customPopups('Please select payment mode', 2);
        return false;
      }
      if (this.editRecurringDateForm.value['payment_for'] == 1 && !this.editRecurringDateForm.value['payment_mode']) {
        this._shared.customPopups('Please select payment mode', 2);
        return false;
      }
      if ((this.editRecurringDateForm.value['payment_for'] == 1 || this.editRecurringDateForm.value['payment_for'] == 2) && ((this.editRecurringDateForm.value['payment_mode'] == 1 && !this.extra_data) && !this.extra_data['recurring']['source_id'])) {
        this._shared.customPopups('Please select card or pay with new card', 2);
        return false;
      }
    } else {
      this.editRecurringDateForm.value['paymentMode'] = 0;
    }


    // if (this.editRecurringDateForm.value['payment_mode'] == 1) {
    //   return;
    // } else {
    data = {
      contract_id: this.extra_data.contract.contract_id,
      payment_mode: parseInt(this.extra_data.recurring['paymentMode']),
      notes: this.editRecurringDateForm.value['note'],
      amount: this.editRecurringDateForm.value['editRecurringAmount'],
      source_id: this.extra_data.recurring['paymentMode'] != 3 ? parseInt(this.extra_data.recurring.source_id) : 0,
      cd_payment_type: 10,
      send_customer_notification: this.editRecurringDateForm.value.send_customer_notification ? 1 : 0,
      continue_deferred_benefits: this.editRecurringDateForm.value.continue_deferred_benefits ? 1 : 0,
      next_payment_date: this._datePipe.transform(this.editRecurringDateForm.value.recurringDate, 'yyyy-MM-dd'),
      pf_id: this.extra_data.contract.pf_id,
      name: this._local.customGetItem('admin_name'),
      late_fee: this.editRecurringDateForm.value.lateFee ? this.editRecurringDateForm.value.lateFee : 0
    };
    if (data.payment_mode == 4) {
      data.source_id = parseInt(this.extra_data.recurring.source_id);
    }
    this.editRecurringFn(data);
    return;
    // }
  }

  editRecurringFn(data: any) {
    data['actual_amount'] = this.actual_edit_recurring_amount
    this._api.api(data, 'finance/edit_contract_recurring_date')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          if (this.extra_data.recurring['paymentMode'] != 4) {
            this._shared.customPopups('Recurring Date Edited Successfully', 0);
          } else {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          }
          this.hide();
          this.passEntry.emit({ success: true });
        } else if (data.is_error == 1 && data.original_text) {
          this._shared.customPopups(data.original_text, data.is_error);
        }
      })
  }

  // Charge Partial Payment
  partialPay() {
    const partialAmount = this.partialPaymentForm.controls['partial_amount'].value;
    let transaction_fee = 0;
    if (partialAmount >= this.extra_data.contract.threshold_amount) {
      transaction_fee = ((partialAmount / 100) * this.extra_data.contract.threshold_recurring_extra_percent) + this.extra_data.contract.threshold_recurring_extra_amount;
    } else {
      transaction_fee = ((partialAmount / 100) * this.extra_data.contract.recurring_extra_percent) + this.extra_data.contract.recurring_extra_amount;
    }

    if (!partialAmount) {
      this._shared.customPopups('Please enter valid partial amount', 2)
      return false;
    }
    if (partialAmount > this.extra_data.contract.remaining_amount) {
      this._shared.customPopups('Partial payment cannot be greater than the total remaining amount', 2)
      return false;
    }
    const data = {
      contract_id: this.extra_data.contract.contract_id,
      // amount: partialAmount, // remove key as threshold settings
      source_id: this.source_id,
      notes: this.partialPaymentForm.controls['note'].value,
      cd_payment_type: 11,
      name: this._local.customGetItem('admin_name'),
      pf_id: this.extra_data.contract.pf_id,
      actual_amount: partialAmount,
      transaction_fee: transaction_fee,
      send_customer_notification: this.partialPaymentForm.controls['send_customer_notification'].value ? 1 : 0,
      payment_source_type: this.payment_source_type,
      is_not_partial: 0
    };
    this._api.api(data, 'finance/partial_payment')
      .subscribe((response: any) => {
        if (response.is_error == 0) {
          if (this.extra_data.contract.bank_accounts.partialPayConfPaymentSourceType == 1) {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          } else {
            this._shared.customPopups('Payment Charged Successfully', 0);
          }
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
    return;
  }

  // pay more cotract charge
  payMore() {
    let total_amount_more = (this.extra_data.contract.recurring_amount * this.extra_data.contract.remaining_payments);

    const partialAmount = this.partialPaymentForm.value.partial_amount;
    let transaction_fee = 0;
    if (partialAmount >= this.extra_data.contract.threshold_amount) {
      transaction_fee = ((partialAmount / 100) * this.extra_data.contract.threshold_recurring_extra_percent) + this.extra_data.contract.threshold_recurring_extra_amount;
    } else {
      transaction_fee = ((partialAmount / 100) * this.extra_data.contract.recurring_extra_percent) + this.extra_data.contract.recurring_extra_amount;
    }

    if (!partialAmount) {
      this._shared.customPopups('Please enter valid pay more ', 2)
      return false;
    }
    if (partialAmount > this.extra_data.contract.remaining_amount && this.extra_data.contract.contract_type != 6) {
      this._shared.customPopups('Pay More payment cannot be greater than the total remaining amount', 2);
      return false;
    }

    if (partialAmount > this.extra_data.contract.patient_payoff_amount && this.extra_data.contract.contract_type == 6) {
      this._shared.customPopups('Pay More payment cannot be greater than the total remaining amount', 2);
      return false;
    }

    var data = {};
    let apiUrl = '';
    if (Math.floor(partialAmount) !== Math.floor(total_amount_more)) {
      data = {
        contract_id: this.extra_data.contract.contract_id,
        source_id: this.source_id,
        notes: this.partialPaymentForm.value.note,
        cd_payment_type: 11,
        name: this._local.customGetItem('admin_name'),
        pf_id: this.extra_data.contract.pf_id,
        actual_amount: partialAmount,
        transaction_fee: transaction_fee,
        send_customer_notification: this.partialPaymentForm.value.send_customer_notification.value ? 1 : 0,
        payment_source_type: this.payment_source_type,
        is_not_partial: 1
      };
      apiUrl = 'partial_payment';
    } else {
      const data = {
        pf_id: parseInt(this.extra_data.contract.pf_id),
        source_id: this.source_id,
        charge_note: this.partialPaymentForm.controls['note'].value,
        admin_id: parseInt(this._local.customGetItem('admin_id')),
        cd_payment_type: 18,
        name: this._local.customGetItem('admin_name'),
        send_customer_notification: this.partialPaymentForm.value.send_customer_notification.value ? 1 : 0,
        notes: this.partialPaymentForm.controls['note'].value,
        payment_source_type: this.payment_source_type,
      };
      apiUrl = 'pay_off';
    }

    this._api.api(data, 'finance/' + apiUrl)
      .subscribe((response: any) => {
        if (response.is_error == 0) {
          if (this.extra_data.contract.bank_accounts.partialPayConfPaymentSourceType == 1) {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          } else {
            this._shared.customPopups('Payment Charged Successfully', 0);
          }
          this.hide();
          this.passEntry.emit({ success: true });
        } else {
          this._shared.customPopups(response.original_text, 1);
          return;
        }
      }, (error: any) => {
        console.log(error);
      });
    return
  }

  //  charge payoff
  payOff() {
    if (this.extra_data.contract.stripe_account_id === 0) {
      this._shared.customPopups('Banking Details Required! Contact support@financemutual.com or 833-336-3348', 2);
      return false;
    }
    const data = {
      pf_id: parseInt(this.extra_data.contract.pf_id),
      source_id: this.source_id,
      charge_note: this.payOffForm.controls['note'].value,
      admin_id: parseInt(this._local.customGetItem('admin_id')),
      cd_payment_type: 18,
      name: this._local.customGetItem('admin_name'),
      send_customer_notification: this.payOffForm.value.send_customer_notification.value ? 1 : 0,
      notes: this.payOffForm.controls['note'].value,
      payment_source_type: this.payment_source_type,
    };
    this._api.api(data, 'finance/payoff_contract')
      .subscribe((response: any) => {
        if (response.is_error === 0) {
          if (this.extra_data.contract.bank_accounts.chargePayOffPaymentSourceType == 1) {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          } else {
            this._shared.customPopups('Payment Charged Successfully', 0);
          }
          this.hide();
          this.passEntry.emit({ success: true });
        } else {
          this._shared.customPopups(response.original_text, 1);
        }
        return;
      }
      );
    return;
  }

  updateContractDetails() {
    let params: any = {
      contract_id: this.extra_data.contract.contract_id
    }
    if (this.contract_changes.edit_treatment_amount) {
      if (!this.contract_changes.treatment_amount) {
        this._shared.customPopups('Please enter a valid service amount.', 1);
        return false;
      }
      params['treatment_amount'] = parseFloat((this.contract_changes.treatment_amount).toFixed(2));
    }
    if (this.contract_changes.edit_finance_amount) {
      if (!this.contract_changes.finance_amount) {
        this._shared.customPopups('Please enter a valid finance amount.', 1);
        return false;
      }
      params['finance_amount'] = parseFloat((this.contract_changes.finance_amount).toFixed(2));
    }
    if (this.contract_changes.edit_recurring_amount) {
      if (!this.contract_changes.recurring_amount) {
        this._shared.customPopups('Please enter a valid recurring amount.', 1);
        return false;
      }
      params['recurring_amount'] = parseFloat((this.contract_changes.recurring_amount).toFixed(2));
    }
    if (this.contract_changes.edit_downpayment_amount) {
      if (!this.contract_changes.downpayment_amount) {
        this._shared.customPopups('Please enter a valid downpayment amount.', 1);
        return false;
      }
      params['downpayment_amount'] = parseFloat((this.contract_changes.downpayment_amount).toFixed(2));
    }
    if (this.contract_changes.edit_principal_per_recurring) {
      if (!this.contract_changes.principal_per_recurring) {
        this._shared.customPopups('Please enter a valid principal per recurring amount.', 1);
        return false;
      }
      params['principal_per_recurring'] = parseFloat((this.contract_changes.principal_per_recurring).toFixed(2));
    }
    if (this.contract_changes.edit_number_of_payments) {
      if (!this.contract_changes.number_of_payments) {
        this._shared.customPopups('Please enter a valid value for total payments.', 1);
        return false;
      }
      params['number_of_payments'] = parseFloat((this.contract_changes.number_of_payments).toFixed(2));
    }
    if (this.contract_changes.edit_remaining_payments) {
      if (!this.contract_changes.remaining_payments && this.contract_changes.remaining_payments != 0) {
        this._shared.customPopups('Please enter a valid value for remaining payments.', 1);
        return false;
      }
      params['remaining_payments'] = parseFloat((this.contract_changes.remaining_payments).toFixed(2));
    }
    if (this.contract_changes.edit_payment_date) {
      if (!this.contract_changes.payment_date) {
        this._shared.customPopups('Please enter a valid payment date.', 1);
        return false;
      }
      params['payment_date'] = this._datePipe.transform(this.contract_changes.payment_date, 'yyyy-MM-dd');
    }
    if (this.contract_changes.edit_pf_status) {
      if (!this.contract_changes.pf_status) {
        this._shared.customPopups('Please enter a valid payment date.', 1);
        return false;
      }
      params['pf_status'] = parseInt(this.contract_changes.pf_status);
      if (params['pf_status'] < 1 || params['pf_status'] > 5) {
        this._shared.customPopups('Please enter a valid status value.', 1);
        return false;
      }
      if (this.contract_changes.pf_status == 4 || this.contract_changes.pf_status == 5) {
        if (!this.contract_changes.cancelled_date) {
          if (this.contract_changes.pf_status == 4) {
            this._shared.customPopups('Please enter a valid completed date.', 1);
          } else {
            this._shared.customPopups('Please enter a valid cancelled date.', 1);
          }
          return false;
        }
        params['cancelled_date'] = this._datePipe.transform(this.contract_changes.cancelled_date, 'yyyy-MM-dd');
        params['reverse_first_recurring'] = this.contract_changes.reverse_first_recurring ? 1 : 0;
      }
    }
    if (!this.contract_changes.notes || !this.contract_changes.notes.trim().length) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    params['notes'] = this.contract_changes.notes
    console.log(params);
    this._api.api(params, 'finance/update_contract_details')
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
        if (data.is_error == 1) {
          if (data.original_text) {
            this._shared.customPopups(data.original_text, data.is_error);
          } else {
            this._shared.customPopups(data.flag, data.is_error);
          }
        }
      });
    return;
  }

  setDefaultValues(key: number) {
    switch (key) {
      case 1: if (this.contract_changes.edit_treatment_amount) {
        this.contract_changes.treatment_amount = this.extra_data.contract.treatment_amount;
      } else {
        this.contract_changes.treatment_amount = null;
      }
        break;
      case 2: if (this.contract_changes.edit_finance_amount) {
        this.contract_changes.finance_amount = this.extra_data.contract.financed_amount;
      } else {
        this.contract_changes.finance_amount = null;
      }
        break;
      case 3: if (this.contract_changes.edit_downpayment_amount) {
        this.contract_changes.downpayment_amount = this.extra_data.contract.downpayment_amount;
      } else {
        this.contract_changes.downpayment_amount = null;
      }
        break;
      case 4: if (this.contract_changes.edit_recurring_amount) {
        this.contract_changes.recurring_amount = this.extra_data.contract.recurring_amount;
      } else {
        this.contract_changes.recurring_amount = null;
      }
        break;
      case 5: if (this.contract_changes.edit_number_of_payments) {
        this.contract_changes.number_of_payments = this.extra_data.contract.number_of_payments;
      } else {
        this.contract_changes.number_of_payments = null;
      }
        break;
      case 6: if (this.contract_changes.edit_remaining_payments) {
        this.contract_changes.remaining_payments = this.extra_data.contract.remaining_payments;
      } else {
        this.contract_changes.remaining_payments = null;
      }
        break;
      case 7: if (!this.contract_changes.edit_payment_date) {
        // this.contract_changes.payment_date = new Date(this.extra_data.contract.next_payment_date);
        // } else {
        this.contract_changes.payment_date = null;
      }
        break;
      case 8: if (this.contract_changes.edit_pf_status) {
        this.contract_changes.pf_status = this.extra_data.contract.pf_status;
      } else {
        this.contract_changes.pf_status = null;
      }
        break;
      case 9: if (this.contract_changes.edit_principal_per_recurring) {
        this.contract_changes.principal_per_recurring = this.extra_data.contract.principal_per_recurring;
      } else {
        this.contract_changes.principal_per_recurring = null;
      }
        break;
    }
  }

  cancelContractFn() {
    const data = {
      pf_id: this.extra_data.contract.pf_id,
      cd_payment_type: 19,
      name: this._local.customGetItem('admin_name'),
      send_customer_notification: this.cancelContractForm.value.send_customer_notification ? 1 : 0,
      notes: this.cancelContractForm.value.notes,
      reverse_first_payment: this.cancelContractForm.value.reserve_payment ? 1 : 0
    };
    this._api.api(data, 'finance/cancel_contract')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  makeProtectedNonProtectedFn() {
    let data = {
      pf_id: this.extra_data.contract.pf_id,
      is_guaranteed: (this.extra_data.contract.is_guaranteed == 0 || this.extra_data.contract.is_guaranteed == 1 ? 2 : 1),
      notes: this.makeProtectedNonProtected.value.notes,
      cd_payment_type: (this.extra_data.contract.is_guaranteed == 0 || this.extra_data.contract.is_guaranteed == 1 ? 13 : 12),
    };
    this._api.api(data, 'toggle_is_guaranteed')
      .subscribe((response: any) => {

        if (typeof response === 'string') {
          response = JSON.parse(response);
        }
        if (response.is_error == 1) {
          if (response.original_text) {
            this._shared.customPopups(response.original_text, 2);
          } else {
            this._shared.customPopups(response.flag, response.is_error);
          }
        } else {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  // Next Recurring Amount
  payNextRecurringNow() {
    if (!this.nextRecAmountForm.value.notes || (this.nextRecAmountForm.value.notes && !this.nextRecAmountForm.value.notes.trim())) {
      this._shared.customPopups('Please enter valid notes', 2)
      return false;
    }
    if (this.nextRecAmountForm.value.late_fee > this.extra_data.contract.late_fee) {
      this._shared.customPopups('Maximum late fees allowed for this contract is $' + this.extra_data.contract.late_fee, 2)
      return false;
    }
    if (this.extra_data.contract.stripe_account_id == 0) {
      this._shared.customPopups('The Business hasn\'t added his bank account yet', 2);
      return false;
    }

    if (!this.setup_id && !this.source_id) {
      this._shared.customPopups('Please add new card or select card', 2);
      return false;
    }

    let data = {
      pf_id: this.extra_data.contract.pf_id,
      save_card: 0,
      setup_id: this.setup_id ? this.setup_id : undefined,
      source_id: this.source_id ? this.source_id : undefined,
      payment_source_type: this._shared.paymentSourceType.CARD,
      late_fee: (this.extra_data.contract.missing_payments > 0 && this.extra_data.contract.missing_payments >= this.extra_data.contract.number_of_missing_payments) ? this.nextRecAmountForm.value.late_fee : 0 || 0,
      charge_note: this.nextRecAmountForm.value.notes || '',
      cd_payment_type: 2,
      send_customer_notification: this.nextRecAmountForm.value.send_customer_notification ? 1 : 0,
      notes: this.nextRecAmountForm.value.notes
    };
    this._api.api(data, 'finance/charge_patient')
      .subscribe((data: any) => {
        console.log(data);
        if (data && data.is_error == 0) {
          if (this.extra_data.contract.bank_accounts.makePaymentSourceType == 1) {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          } else {
            this._shared.customPopups('Payment Charged Successfully', 0);
          }

          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
    return;
  }

  // Send Generated Link Email
  sendAddCardLinkEmail() {
    if (!this.extra_data.contract.notes || this.extra_data.contract.notes.trim().length == 0) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return false;
    }
    const data = {
      email_link: this.extra_data.contract.link,
      email_id: this.extra_data.contract.patient_email,
      contract_id: this.extra_data.contract.contract_id,
      notes: this.extra_data.contract.notes,
      cd_payment_type: 14
    };
    this._api.api(data, 'finance/send_email')
      .subscribe((response: any) => {
        if (response && !response.is_error) {
          this._shared.customPopups('Email sent successfully', 0);
          this.hide();
          this.passEntry.emit({ success: true });
        } else {
          this._shared.customPopups(response.original_text || response.err, response.is_error);
        }
      });
    return;
  }

  addEmails(event: any) {
    if (event.keyCode == 13) {
      if (this.extra_data.contract.patient_email && this.extra_data.contract.patient_email.trim() && this.extra_data.contract.patient_email.trim().length) {
        let index = this.contributionEmails.findIndex((x: any) => x == this.extra_data.contract.patient_email);
        if (index == -1) {
          this.contributionEmails.push(this.extra_data.contract.patient_email)
          this.extra_data.contract.patient_email = '';
        } else {
          this._shared.customPopups('Email already added', 2);
        }
        console.log(this.contributionEmails);
      } else {
        this._shared.customPopups('Please enter valid email', 2);
      }
    }
  }

  removeEmail(email: any) {
    let index = this.contributionEmails.findIndex((x: any) => x == email);
    if (index > -1) {
      this.contributionEmails.splice(index, 1);
    }
  }

  // ssend contribuition link
  sendContLink() {
    if (this.contributionEmails.length === 0) {
      this._shared.customPopups('Please enter email address', 2);
      return;
    }
    if (!this.selectedLanguage) {
      this._shared.customPopups('Please select a language', 2)
      return;
    }

    if (this.contributionEmails.length > 0) {

      let data = {
        email_link: this.extra_data.contract.contributionUrl + '&lang=' + this.selectedLanguage,
        email_id: this.contributionEmails,
        contract_id: this.extra_data.contract.contract_id,
        language_preffered: this.selectedLanguage
      };
      console.log('emailssss', this.contributionEmails)
      if (this.contributionEmails && this.contributionEmails.length) {
        this._api.api(data, 'finance/send_social_email')
          .subscribe((data: any) => {
            if (data.log == 'success') {
              this._shared.customPopups(data.flag, data.is_error);
              this.contributionEmails = [];
              this.hide();
              this.passEntry.emit({ success: true });
            } else {
              if (data.original_text || data.err) {
                this._shared.customPopups(data.original_text || data.err, data.is_error);
              } else {
                this._shared.customPopups(data.flag, data.is_error);
              }
            }
          }, (error: any) => {
            console.log(error);
          })
      } else {
        this._shared.customPopups('Please enter email address', 2);
      }
    }
  }

  moveToUnpaidFirstRecurringFn() {
    if (!this.extra_data.contract.notes || this.extra_data.contract.notes.trim().length == 0) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return;
    }
    const data = {
      pf_id: this.extra_data.contract.pf_id,
      contract_id: this.extra_data.contract.contract_id,
      notes: this.extra_data.contract.notes,
      cd_payment_type: 41
    };
    this._api.api(data, 'finance/move_reverse_payment_to_unpaid')
      .subscribe((response: any) => {
        if (response.is_error == 1) {
          if (response.original_text) {
            this._shared.customPopups(response.original_text, response.is_error);
          } else {
            this._shared.customPopups(response.flag, response.is_error);
          }
        } else {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  makePaymentDisputedFn() {
    if (!this.extra_data.contract.notes || this.extra_data.contract.notes.trim().length == 0) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return;
    }
    const data = {
      pf_id: this.extra_data.contract.pf_id,
      contract_id: this.extra_data.contract.contract_id,
      notes: this.extra_data.contract.notes,
      cd_payment_type: 41,
      status: this.extra_data.contract.dispute_payment_status == 0 ? 1 : 0
    };
    this._api.api(data, 'finance/toggle_dispute_isguaranteed')
      .subscribe((response: any) => {
        if (response.is_error == 1) {
          if (response.original_text) {
            this._shared.customPopups(response.original_text, response.is_error);
          } else {
            this._shared.customPopups(response.flag, response.is_error);
          }
        } else {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  doNotDisturb() {

    let params = {
      pf_id: this.extra_data.contract.pf_id,
      dnd: this.extra_data.contract.dnd == 1 ? 0 : 1,
      contract_id: this.extra_data.contract.contract_id,
      notes: this.extra_data.contract.notes,
      cd_payment_type: this.extra_data.contract.dnd == 1 ? 38 : 39,
      status: this.extra_data.contract.dispute_payment_status == 0 ? 1 : 0,
      action_type: this.extra_data.contract.dnd == 1 ? 'dnd_status_disabled' : 'dnd_status_enabled',
      dnd_apply_on: this.dnd_apply_on,
      patient_id: this.extra_data.contract.patient_id
    };

    this._api.api(params, 'finance/toggle_dnd_contract')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      })
  }

  changeTransactionFee() {
    if (this.changeTransactionFeeThresholdForm.value.recurring_extra_amount === null || this.changeTransactionFeeThresholdForm.value.recurring_extra_amount < 0) {
      this._shared.customPopups('Please enter a valid transaction fees($)', 2);
      return;
    }
    if (this.changeTransactionFeeThresholdForm.value.recurring_extra_percent === null || this.changeTransactionFeeThresholdForm.value.recurring_extra_percent < 0) {
      this._shared.customPopups('Please enter a valid transaction fees(%)', 2);
      return;
    }
    if (!this.changeTransactionFeeThresholdForm.value.threshold_amount || this.changeTransactionFeeThresholdForm.value.threshold_amount < 0) {
      this._shared.customPopups('Please enter a valid threshold transaction amount', 2);
      return;
    }
    if (this.changeTransactionFeeThresholdForm.value.threshold_recurring_extra_amount === null || this.changeTransactionFeeThresholdForm.value.threshold_recurring_extra_amount < 0) {
      this._shared.customPopups('Please enter a valid transaction fees($) above threshold', 2);
      return;
    }
    if (this.changeTransactionFeeThresholdForm.value.threshold_recurring_extra_percent === null || this.changeTransactionFeeThresholdForm.value.threshold_recurring_extra_percent < 0) {
      this._shared.customPopups('Please enter a valid transaction fees(%) above threshold', 2);
      return;
    }
    if (!(this.changeTransactionFeeThresholdForm.value.recurring_extra_amount || this.changeTransactionFeeThresholdForm.value.recurring_extra_percent)) {
      this._shared.customPopups('Please enter a valid amount for any transaction fees', 2);
      return;
    }
    if (!(this.changeTransactionFeeThresholdForm.value.threshold_recurring_extra_amount || this.changeTransactionFeeThresholdForm.value.threshold_recurring_extra_percent)) {
      this._shared.customPopups('Please enter a valid amount for any transaction fees above threshold', 2);
      return;
    }
    if (!this.changeTransactionFeeThresholdForm.value.notes || !this.changeTransactionFeeThresholdForm.value.notes.trim()) {
      this._shared.customPopups('Please enter a valid note', 2);
      return;
    }
    const data = this.changeTransactionFeeThresholdForm.value;
    data['contract_id'] = this.extra_data.contract.contract_id;
    this._api.api(data, 'finance/change_transaction_fee')
      .subscribe((data: any) => {
        if (!data.is_error) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  // get or send money transaction
  changePartnerDoctorSendMoney() {
    this.getOrSendMoneyToBusinessForm.patchValue({
      notes: '',
      amount: 0,
      linkPartnerWithDoctorSend: false
    })
    if (this.extra_data.contract.partner_details && this.extra_data.contract.partner_details.length) {
      this.extra_data.contract.partner_details.forEach((element: any) => {
        this.extra_data.contract.partnerListAmount[element.partner_id] = {
          partner_id: element.partner_id,
          amount: 0,
          is_selected: false
        }
      });
    }
  }

  changeTransferPartnerList(id?: any) {
    this.extra_data.contract.partnerListAmount[id]['is_selected'] = !this.extra_data.contract.partnerListAmount[id]['is_selected']
  }

  getAndSendMoneyToBusiness() {
    let linkedPartnerIds: any = [];
    if (this.extra_data.contract.partner_details && this.extra_data.contract.partner_details.length) {
      this.extra_data.contract.partner_details.forEach((element: any) => {
        if (this.extra_data.contract.partnerListAmount[element.partner_id].is_selected) {
          linkedPartnerIds.push({
            partner_id: this.extra_data.contract.partnerListAmount[element.partner_id].partner_id,
            amount: this.extra_data.contract.partnerListAmount[element.partner_id].amount
          })
        }
      });
    }
    let params: any = {
      contract_id: this.extra_data.contract['contract_id'],
      amount: this.getOrSendMoneyToBusinessForm.value.amount || 0,
      person_type: this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend,
      notes: this.getOrSendMoneyToBusinessForm.value.notes
    };
    if (linkedPartnerIds.length && this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "partner") {
      params['partner_ids'] = JSON.stringify(linkedPartnerIds);
    } else if (linkedPartnerIds.length && this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "doctor") {
      params['link_partner_ids'] = JSON.stringify(linkedPartnerIds);
    }
    if (this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "partner" && !(linkedPartnerIds && linkedPartnerIds.length)) {
      this._shared.customPopups("Please select atleast one partner for the transfer", 2);
      return;
    }
    if (this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "doctor" && this.getOrSendMoneyToBusinessForm.value.linkPartnerWithDoctorSend && !(linkedPartnerIds && linkedPartnerIds.length)) {
      this._shared.customPopups("Please select atleast one partner for the transfer", 2);
      return;
    }
    if (this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "partner") {
      let checkAmount = 0;
      linkedPartnerIds.forEach((element: any) => {
        if (element.amount <= 0) {
          checkAmount = 1
        }
      });
      if (checkAmount) {
        this._shared.customPopups("Please enter valid amount", 2);
        return;
      }
    }
    if (this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "doctor" && this.getOrSendMoneyToBusinessForm.value.linkPartnerWithDoctorSend) {
      let checkAmount = 0;
      linkedPartnerIds.forEach((element: any) => {
        if (element.amount <= 0) {
          checkAmount = 1
        }
      });
      if (checkAmount) {
        this._shared.customPopups("Please enter valid amount for partners", 2);
        return;
      }
    }
    if (this.getOrSendMoneyToBusinessForm.value.show_to_customer && (!this.getOrSendMoneyToBusinessForm.value.customer_pt_name || !this.getOrSendMoneyToBusinessForm.value.customer_pt_name.trim().length)) {
      this._shared.customPopups("Please enter valid payment type for customer", 2);
      return;
    }
    if (!this.getOrSendMoneyToBusinessForm.value.business_pt_name || !this.getOrSendMoneyToBusinessForm.value.business_pt_name.trim().length) {
      this._shared.customPopups("Please enter valid payment type for business", 2);
      return;
    }
    if (this.getOrSendMoneyToBusinessForm.value.amount_deducted === "" || this.getOrSendMoneyToBusinessForm.value.amount_deducted === null || !(Number(this.getOrSendMoneyToBusinessForm.value.amount_deducted) >= 0)) {
      this._shared.customPopups("Please enter valid amount deducted", 2);
      return;
    }
    params['customer_pt_name'] = this.getOrSendMoneyToBusinessForm.value.customer_pt_name;
    params['show_to_customer'] = this.getOrSendMoneyToBusinessForm.value.show_to_customer ? 1 : 0;
    params['business_pt_name'] = this.getOrSendMoneyToBusinessForm.value.business_pt_name;
    params['amount_deducted'] = this.getOrSendMoneyToBusinessForm.value.amount_deducted;
    if (this.getOrSendMoneyToBusinessForm.value.isBusinessOrPartnerSend == "doctor" && params.amount <= 0) {
      this._shared.customPopups("Please enter valid amount", 2);
      return;
    }
    if (params && params.note && params.note.length && params.note.length > 500) {
      this._shared.customPopups('Note cannot exceed more than 500 characters', 2);
      return;
    }

    if (params.customer_pt_name.length == 0) {
      delete params['customer_pt_name'];
    }

    let url = '';
    if (this.getOrSendMoneyToBusinessForm.value.moneyValue == 1) {
      url = 'finance/get_money_from_doctor';
    } else {
      url = 'finance/send_money_to_doctor';
    }
    this._api.api(params, url)
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
        if (data.is_error == 1) {
          if (data.original_text) {
            this._shared.customPopups(data.original_text, data.is_error);
          } else {
            this._shared.customPopups(data.flag, data.is_error);
          }
        }
      });
  }

  // payment settle amount functions
  calculateReceivingAmountToDoc() {
    if (this.extra_data.contract.doctorAmount) {
      var percentage = this.extra_data.contract.deductions.reduce((sum: any, n: any) => {
        return sum + parseFloat(n.percent || 0);
      }, 0);
      if (this.extra_data.contract.partnerShareFromDoctor) {
        percentage += parseFloat(this.extra_data.contract.partnerShareFromDoctor);
      }
      console.log(percentage, this.extra_data.contract.doctorAmount)
      this.extra_data.contract.receiveAmount = this.extra_data.contract.doctorAmount - ((this.extra_data.contract.doctorAmount / 100) * percentage);
    } else {
      this.extra_data.contract.receiveAmount = 0;
    }
  };

  paySettledPaymentToDoctor() {
    if (!this.extra_data.contract.settlement_amount) {
      this._shared.customPopups('This contract is not a settled contract', 1);
      return;
    }
    if (this.extra_data.contract.max_claim_amount == 0) {
      this._shared.customPopups('The settlement amount is already paid to the business.', 1);
      return;
    }
    if (this.extra_data.contract.max_claim_amount && this.extra_data.contract.doctorAmount > this.extra_data.contract.max_claim_amount) {
      this._shared.customPopups('The principal amount should be less than or equal to $' + this.extra_data.contract.max_claim_amount + ' for the guaranteed payments.', 1);
      return;
    }
    let data = {
      guaranteed_amount: this.extra_data.contract.doctorAmount,
      collection_percent: parseFloat(this.extra_data.contract.collection_percent),
      contract_id: this.extra_data.contract.contract_id
    };
    console.log(data);
    this._api.api(data, 'finance/settled_payment_to_doctor')
      .subscribe((data: any) => {
        if (data.is_error === 0) {
          this._shared.customPopups('Move to unpaid successfully', 1);
          this.passEntry.emit({ success: true });
        }
      });
  }

  // sendContract email

  emailContentChanged(email_from: any) {
    if (email_from == 1) {
      this.sendContractForm.patchValue({
        download_email: this.extra_data.contract.doctor_email
      });
    } else if (email_from == 2) {
      this.sendContractForm.patchValue({
        download_email: this.extra_data.contract.patient_email
      });
    }
  }

  sendContract() {
    if (this.sendContractForm.value.send_download_email != 1 && this.sendContractForm.value.send_download_email != 2) {
      this._shared.customPopups('Please select the user to whom the email is to be sent', 1);
      return;
    }
    if (!this.sendContractForm.value.download_email) {
      this._shared.customPopups('Please enter a valid email', 1);
      return;
    }
    let email_pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email_pattern.test(this.sendContractForm.value.download_email)) {
      this._shared.customPopups('Please enter a valid email', 1);
      return;
    }
    if (this.sendContractForm.value.notes.trim() == '') {
      this._shared.customPopups('Please enter a valid note', 1);
      return;
    }
    let dataParams = {
      contract_id: this.extra_data.contract.contract_id,
      for_user: this.sendContractForm.value.send_download_email,
      timezone: this._shared.getTimezone(),
      notes: this.sendContractForm.value.notes,
      download_email: this.sendContractForm.value.download_email,
      add_consent_form: this.sendContractForm.value.add_consent_form ? 1 : 0
    };
    this._api.api(dataParams, 'finance/send_download_contract_pdf')
      .subscribe((data: any) => {
        if (data.original_text || data.err) {
          this._shared.customPopups(data.original_text || data.err, 1);
        } else {
          this._shared.customPopups('Email sent successfully', 0);
          this.hide();
          this.passEntry.emit({ success: true });
        }
      })
  }

  // send verification contract email
  emailContentChangedContractVerification() {
    this.contractVerificationEmailForm.patchValue({
      download_email: this.extra_data.contract.patient_email
    })
  }

  sendVerificationIntimation() {
    if (this.contractVerificationEmailForm.value.notes.trim() == '') {
      this._shared.customPopups('Please enter a valid note', 1);
      return
    }
    let dataParams: any = {
      contract_id: this.extra_data.contract.contract_id,
      timezone: this._shared.getTimezone(),
      notes: this.contractVerificationEmailForm.value.notes
    }
    if (this.contractVerificationEmailForm.value.selected_verification == this.contractVerificationTypes.selected_final_comm || this.contractVerificationEmailForm.value.selected_verification == this.contractVerificationTypes.selected_first_comm) {
      dataParams['intimation'] = this.contractVerificationEmailForm.value.selected_verification == this.contractVerificationTypes.selected_final_comm ? 2 : 1
      this._api.api(dataParams, 'finance/send_contract_intimation').subscribe((data: any) => {
        if (data.original_text || data.err) {
          this._shared.customPopups(data.original_text, 1);
        } else {
          this.hide();
          this._shared.customPopups('Email sent successfully', 0);
          this.passEntry.emit({ success: true });
        }
      })
    } else if (this.contractVerificationEmailForm.value.selected_verification == this.contractVerificationTypes.send_contract) {
      dataParams['download_email'] = this.contractVerificationEmailForm.value.download_email;
      dataParams['for_user'] = 2; // chck if needed
      dataParams['add_consent_form'] = this.extra_data.contract.add_consent_form ? 1 : 0;
      this._api.api(dataParams, 'finance/send_download_customer_pdf').subscribe((data: any) => {
        if (data && !data.is_error) {
          this.hide();
          this._shared.customPopups('Email sent successfully', 0);
          this.passEntry.emit({ success: true });
        } else {
          this._shared.customPopups(data.original_text || data.err, 1);
        }
      })
    }
  }

  downloadContract() {
    let dataParams = {
      contract_id: this.extra_data.contract.contract_id,
      pf_id: this.extra_data.contract.pf_id,
      for_user: this.extra_data.contract.download_contract_for
    };
    this._api.downloadApi(dataParams, 'finance/download_contract_pdf', 0)
      .subscribe((data: any) => {
        console.log(data);
        this.hide();
      })
  }

  reminderTemplateFn() {
    const _this = this;
    const { ring_central_client_id, ring_central_state_id, ring_central_redirect_uri }: any = environment;
    let ring_central_pk;
    // this.mainService.ring_central_key.subscribe((key: string) => {
    //   ring_central_pk = key;
    // });
    let auth_url = '';
    if (environment.production) {
      auth_url = `https://platform.ringcentral.com/restapi/oauth/authorize?client_id=${ring_central_pk}&redirect_uri=${ring_central_redirect_uri}&response_type=code&state=${ring_central_state_id}&prompt=login%26sso`;
    } else {
      auth_url = `https://platform.devtest.ringcentral.com/restapi/oauth/authorize?client_id=${ring_central_pk}&redirect_uri=${ring_central_redirect_uri}&response_type=code&state=${ring_central_state_id}&prompt=login%26sso`;
    }
    let mobile_template, email_template;
    if ((this.reminderTemplateForm.value.reminder_data.mobile || this.reminderTemplateForm.value.reminder_data.email) && this.extra_data.contract.reminder_data) {
      this.extra_data.contract.reminder_data.fetchReminderTemplate.forEach((data: any) => {
        if (Number(data.reminder_template_id) === Number(this.reminderTemplateForm.value.reminder_data.reminder_id)) {
          mobile_template = data.mobile_template;
          email_template = data.email_template;
        }
      });
    }
    if (this.reminderTemplateForm.value.reminder_data.reminder_id && (this.reminderTemplateForm.value.reminder_data.email || this.reminderTemplateForm.value.reminder_data.mobile)) {
      this._api.api(
        {
          contract_id: this.extra_data.contract.contract_id,
          send_customer_notification: this.reminderTemplateForm.value.send_customer_notification ? 1 : 0,
          reminder_type: this.reminderTemplateForm.value.reminder_data.mobile && this.reminderTemplateForm.value.reminder_data.email ? 3 : this.reminderTemplateForm.value.reminder_data.mobile ? 1 : 2,
          email_template: this.reminderTemplateForm.value.reminder_data.email ? email_template : undefined,
          mobile_template: this.reminderTemplateForm.value.reminder_data.mobile ? mobile_template : undefined
        },
        'finance/manual_reminder_contract')
        .subscribe(data => {
          if (data.is_error && (data.flag === 400 || data.flag === 202)) {
            if (data.flag === 202) {
              this._shared.customPopups('Email sent successfully but SMS failed.  ' +
                'You will be redirected to authorize again in 1 seconds to authorize your account again.', 0);
            } else {
              this._shared.customPopups('Please authorise your Ring Central account and try again later', 1);
            }
            setTimeout(() => {
              const newWin = window.open(auth_url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
              if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                _this._shared.customPopups('Browser popups are blocked. Please allow it and try again.', 1);
              }
            }, 1000);
          } else if (data.is_error) {
            this._shared.customPopups(data.err, 1);
          } else {
            this._shared.customPopups('Reminder sent successfully', 0);
          }
          setTimeout(() => {
            this.passEntry.emit({ success: true })
          }, 2000);
          this.hide();
        });
    }
  }

  pausePaymentSMS() {
    if (this.extra_data.contract.notes.trim() == '') {
      this._shared.customPopups('Please enter a valid note', 1);
      return
    }
    let dataParams: any = {
      notes: this.extra_data.contract.notes,
      contract_id: this.extra_data.contract.contract_id
    }
    let api = 'finance/pause_contract_payments';
    if (this.extra_data.contract['cronType'] == 'payment') {
      dataParams['status'] = this.extra_data.contract.cronPayment.is_payments_paused == 0 ? 1 : 0;
    } else {
      dataParams['status'] = this.extra_data.contract.cronPayment.sms_availability_status == 0 ? 1 : 0;
      api = 'finance/resume_sending_sms';
    }
    this._api.api(dataParams, api).subscribe((data: any) => {
      if (data.original_text || data.err) {
        this._shared.customPopups(data.original_text, 1);
      } else {
        this.hide();
        this._shared.customPopups('Email sent successfully', 0);
        this.passEntry.emit({ success: true });
      }
    })

  }

  addBankLinkFn() {
    var mobile = this.addBankLinkForm.value.mobile ? this.addBankLinkForm.value.mobile.replace(/-/g, '') : this.extra_data.contract['patient_mobile'] ? this.extra_data.contract['patient_mobile'].split('-')[1] : '';
    if (mobile && mobile.length < 9) {
      this._shared.customPopups('Please enter valid phone number', 1);
      return;
    }
    if (!this.addBankLinkForm.value.email) {
      this._shared.customPopups('Please enter valid email', 1);
      return;
    }
    if (!mobile) {
      this._shared.customPopups('Please enter valid phone number', 1);
      return;
    }
    if (!this.addBankLinkForm.value.notes || !this.addBankLinkForm.value.notes.trim().length) {
      this._shared.customPopups('Please enter a valid note.', 1);
      return;
    }
    const data = {
      patient_id: this.extra_data.contract['patient_id'],
      email: this.addBankLinkForm.value.email,
      mobile,
      cd_payment_type: 46,
      notes: this.addBankLinkForm.value.notes,
      contract_id: this.extra_data.contract.contract_id,
      pf_id: this.extra_data.contract.pf_id,
      mobile_country_code: this.extra_data.countryData.mobile_code
    };
    this._api.api(data, 'send_add_bank_link')
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          if (data.flag == 552) {
            this._shared.customPopups(data.data, 0);
          } else {
            this._shared.customPopups('Link has been sent successfully.', 0)
          }
          this.hide();
        }
        if (data.is_error == 1) {
          if (data.original_text) {
            this._shared.customPopups(data.original_text, data.is_error);
          } else {
            this._shared.customPopups(data.flag, data.is_error);
          }
        }
      });
  }

  // settlement modal functions
  getSettlementArrangement() {
    let api_data = {
      contract_id: this.extra_data.contract.contract_id
    };
    this._api.api(api_data, 'finance/view_settlement_arrangement', 'get')
      .subscribe((data: any) => {
        if (data.is_error == 0 && data.data && data.data.length > 0) {
          this.settlement_data = data.data;
          this.viewArrangements = true;
        }
      });
  }

  sendPaymentLink() {
    if (!this.extra_data.contract.patient_name || (this.extra_data.contract.patient_name && !this.extra_data.contract.patient_name.trim().length)) {
      this._shared.customPopups('Please enter valid name', 1);
      return;
    }

    if (!this.extra_data.contract.patient_email || (this.extra_data.contract.patient_email && !this.extra_data.contract.patient_email.trim().length)) {
      this._shared.customPopups('Please enter valid email', 1);
      return;
    }

    if (this.extra_data.contract.patient_mobilw) {
      this._shared.customPopups('Please enter valid number', 1);
      return;
    }

    let url = "finance/send_settlement_payment_process_link"
    let link_data = {
      amount: this.contractSettlementForm.value.amount,
      doctor_transaction_fee: 0,
      patient_transaction_fee: 0,
      email: this.extra_data.contract.patient_email,
      mobile: this.extra_data.contract.patient_mobile,
      mobile_country_code: this.extra_data.countryData.country_code || '+1',
      name: this.extra_data.contract.patient_name,
      reason: this.contractSettlementForm.value.description,
      note: this.contractSettlementForm.value.notes,
      payout_type: 1,
      settlement_contract_id: this.extra_data.contract.contract_id,
    }
    this._api.api(link_data, url)
      .subscribe((res: any) => {
        if (res.is_error == 0) {
          this.hide()
          this._shared.customPopups('Payment link has been sent to the customer email.', 0);
          this.passEntry.emit({ success: true });
        } else {
          this._shared.customPopups(res.err, 1);
        }
      })

  }

  addMultiple() {
    this.multiplepayment.push({ "settlement_arrangement_date": new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), "settlement_amount_value": 0, "settlement_arrangement_time_slot_type": '', "source_id": 0, "stripe_token": '', "stripe_metadata": '', "settlement_card": false, "last4": 0 });
  }

  deleteMultiple(deleted_index: number) {
    this.multiplepayment = this.multiplepayment.filter((item, index) => {
      return deleted_index !== index
    });
  }

  settlementArrangementContractFn() {
    for (let i = 0; i < this.multiplepayment.length; i++) {
      if (this.multiplepayment[i].settlement_arrangement_date == null) {
        this._shared.customPopups('Please enter a valid date.', 1);
        return;
      }
      if (this.multiplepayment[i].settlement_amount_value == null || this.multiplepayment[i].settlement_amount_value < 1) {
        this._shared.customPopups('Please enter a valid amount.', 1);
        return;
      }
      if (this.multiplepayment[i].source_id == 0 && (this.multiplepayment[i].stripe_token == ' ' || !this.multiplepayment[i].stripe_metadata)) {
        this._shared.customPopups('Please add customer card.', 1);
        return;
      }
      if (this.multiplepayment[i].settlement_arrangement_time_slot_type == null || this.multiplepayment[i].settlement_arrangement_time_slot_type == undefined || this.multiplepayment[i].settlement_arrangement_time_slot_type == "") {
        this._shared.customPopups('Please select time slot.', 1);
        return;
      }
    }
    let payments = [];
    let total_amount = 0;
    let dates: number[] = [];
    let months: number[] = [];
    for (let i = 0; i < this.multiplepayment.length; i++) {
      let current_date = new Date(this.multiplepayment[i].settlement_arrangement_date);
      dates.push(current_date.getDate());
      months.push(current_date.getMonth());
      payments.push({
        "settlement_date": this.multiplepayment[i].settlement_arrangement_date,
        "settlement_amount": this.multiplepayment[i].settlement_amount_value,
        "settlement_slot_type": this.multiplepayment[i].settlement_arrangement_time_slot_type,
        "setup_id": this.multiplepayment[i].stripe_token,
        "stripe_metadata": this.multiplepayment[i].stripe_metadata,
        "source_id": this.multiplepayment[i].source_id || 0
      });
      total_amount += this.multiplepayment[i].settlement_amount_value;
    }
    if (dates.some((val, i) => dates.indexOf(val) !== i) && months.some((val, i) => months.indexOf(val) !== i)) {
      this._shared.customPopups('One payment allowed on one day', 1);
      return;
    }
    let amount = this.contractSettlementForm.value.amount;
    console.log('amount', amount);
    console.log('total_amount', total_amount);
    if (total_amount < amount) {
      this._shared.customPopups(`Total amount should not be less than $${amount}`, 1);
      return;
    }
    if (total_amount > amount) {
      this._shared.customPopups(`Total amount should not be greater than $${amount}`, 1);
      return;
    }
    let api_data = {
      contract_id: this.extra_data.contract.contract_id,
      multiplePay: JSON.stringify(payments),
      reason: this.contractSettlementForm.value.description,
      note: this.contractSettlementForm.value.notes,
      name: this.extra_data.contract.patient_name,
      patient_id: this.extra_data.contract.patient_id
    };
    this._api.api(api_data, 'finance/add_multiple_payments')
      .subscribe((data: any) => {
        if (data.is_error == 0 && data.flag == 213) {
          this._shared.customPopups('Arrangement added successfully.', 0);
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  addExistingCard(source_id: number, last4: number, index: number) {
    this.multiplepayment[index].source_id = source_id;
    this.multiplepayment[index].settlement_card = true;
    this.multiplepayment[index].last4 = last4;
  }

  deleteSettlementArrangement(id: number) {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {})
    modelRef.content?.passEntry.subscribe((response: any) => {
      if (response.success && response.notes) {
        let data = {
          settlement_id: id,
          notes: response.notes,
          contract_id: this.extra_data.contract.contract_id
        };
        this._api.api(data, 'finance/inactive_settlement_arrangement').subscribe(apiResponse => {
          if (apiResponse.is_error == 1) {
            if (apiResponse.original_text) {
              this._shared.customPopups(apiResponse.original_text, 2);
            } else {
              this._shared.customPopups(apiResponse.flag, apiResponse.is_error);
            }
          } else {
            this.viewArrangements = false;
          }
        })
      }
    })
  }

  chargeCancelContract() {
    let data;
    if (this.extra_data.charge['payment_for'] == 2 && (!this.extra_data.charge['payment_mode'] || this.extra_data.charge['payment_mode'] == 2)) {
      this._shared.customPopups('Please select payment mode', 2);
      return;
    }
    if (this.extra_data.charge['payment_for'] == 1 && !this.extra_data.charge['payment_mode']) {
      this._shared.customPopups('Please select payment mode', 2);
      return;
    }

    if (this.extra_data.charge['payment_mode'] == 1) {
      // this.GetStripeToken('cancel_contract');
    } else {
      if ((this.extra_data.charge['payment_for'] == 1 || this.extra_data.charge['payment_for'] == 2) && this.extra_data.charge['payment_mode'] == 1 && !this.extra_data.charge['source_id']) {
        this._shared.customPopups('Please select card or pay with new card', 2);
        return;
      }
      data = {
        contract_id: this.extra_data.contract.contract_id,
        payment_mode: parseInt(this.extra_data.charge['paymentMode']),
        note: this.extra_data.charge['note'],
        amount: this.extra_data.charge['cancellationCharge'],
        source_id: this.extra_data.charge['paymentMode'] != 3 ? parseInt(this.extra_data.charge.source_id) : undefined,
        cd_payment_type: 36,
        pf_id: this.extra_data.contract.pf_id,
        notes: this.extra_data.charge['note'],
      };
      if (data.payment_mode == 4) {
        data.source_id = parseInt(this.extra_data.charge.source_id);
      }
      this.chargeCancellationFn(data);
    }
  }

  chargeCancellationFn(data: any) {
    this._api.api(data, 'finance/charge_cancellation_fee')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          if (this.extra_data.charge['paymentMode'] != 4) {
            this._shared.customPopups('Contract Charged Successfully', 0);
          } else {
            this._shared.customPopups('Payment Initiated Successfully', 0);
          }
          this.passEntry.emit({ success: true })
          this.hide();
        } else if (data.is_error == 1 && data.original_text) {
          this._shared.customPopups(data.original_text, data.is_error);
        }
      }
      )
  }


  readOnlyNoOfRec = false;
  readOnlyCustomAmount = true;
  arrangement_partial_amount = '';
  contractMissingPayment: any;
  onOffCustomAmountFn(event: Event,) {
    var checkStatus = this.arrangementForm.controls['onOffCustomAmount'].value ? 1 : 0;
    if (checkStatus && checkStatus == 1) {
      this.readOnlyNoOfRec = true;
      this.readOnlyCustomAmount = false;
      this.arrangementForm.patchValue({
        arrangement_number_recurring: 0,
        onOffCustomAmount: 1
      });
    } else {
      this.readOnlyCustomAmount = true;
      this.readOnlyNoOfRec = false;
      this.arrangementForm.patchValue({ arrangement_partial_amount: '', onOffCustomAmount: 0 });
    }
  }

  paymentRecuringNumber(type: any, recurring_amount = null) {
    var type_of_number = this.arrangementForm.value.arrangement_number_recurring && this.arrangementForm.value.arrangement_number_recurring != null ? this.arrangementForm.value.arrangement_number_recurring.toString() : null;
    if (type_of_number && type_of_number.includes('.')) {
      this.arrangementForm.patchValue({
        arrangement_number_recurring: parseInt(type_of_number.replace('.', ''))
      });
    }
    if (type && type == 1) {
      if (this.arrangementForm.value.arrangement_number_recurring > this.extra_data.contract.remaining_payments) {
        // this.isRecurringValid = true;
        return;
      } else {
        // this.isRecurringValid = false;
      }
      this._contract.GetTransactionFeeContractDetails(this.extra_data.contract, recurring_amount);
      if (this.arrangement_partial_amount && this.arrangement_partial_amount != '') {
        this.arrangement_partial_amount = '';
      }

      let lateFeePaid = this.extra_data.contract.number_of_missing_payments - this.extra_data.contract.missing_payments
      if (lateFeePaid < 0) {
        lateFeePaid = 0;
      }
      if (this.arrangementForm.value.arrangement_number_recurring > lateFeePaid) {
        this.contractMissingPayment = this.arrangementForm.value.arrangement_number_recurring - lateFeePaid
      } else {
        this.contractMissingPayment = 0
      }
    }
    if (type && type == 2) {
      this._contract.GetTransactionFeeContractDetails(this.extra_data.contract, this.arrangementForm.value.arrangement_number_recurring, this.arrangementForm.value.ara);
      if (this.arrangementForm.value.arrangement_number_recurring && this.arrangementForm.value.arrangement_number_recurring != '') {
        this.arrangementForm.patchValue({
          arrangement_number_recurring: 0
        });
      }
    }
  }

  arrangementContractFn() {
    if (this.arrangementForm.value.arrangement_partial_amount && this.arrangementForm.value.arrangement_number_recurring && this.arrangementForm.value.arrangement_partial_amount != '' && this.arrangementForm.value.arrangement_number_recurring != '') {
      this._shared.customPopups('Please Select Recurring or Partial Payment.', 2);
      return;
    }
    let arrangement_payment_type;
    if (this.arrangementForm.value.arrangement_number_recurring && this.arrangementForm.value.arrangement_number_recurring != '') {
      arrangement_payment_type = 1;   // payment type is recurring 
    } else {
      arrangement_payment_type = 2;   // payment type is partial
    }
    // if (this.card_div) {
    //   this.GetStripeToken('add_arrangement_schedule');
    // } else {
    let data = {
      arrangement_date: this._datePipe.transform(this.arrangementForm.value.arrangement_date, 'yyyy-MM-dd'),
      arrangement_note: this.arrangementForm.value.notes,
      arrangement_slot_type: this.arrangementForm.value.arrangement_time_slot_type,
      partial_amount: this.arrangementForm.value.arrangement_partial_amount,
      number_of_recurring: this.arrangementForm.value.arrangement_number_recurring,
      contract_id: this.extra_data.contract.contract_id,
      source_id: this.source_id,
      arrangement_payment_type: arrangement_payment_type,
    };
    this.addArrangementFn(data);
    // }
  }

  addArrangementFn(data: any) {
    if ((!data.source_id) || (!data.partial_amount && !data.number_of_recurring) || data.arrangement_date == undefined || data.arrangement_date == '' || data.arrangement_note == undefined || data.arrangement_note == '' || data.arrangement_slot_type == undefined || data.arrangement_slot_type == '') {
      return;
    } else {
      // this.isArrangementError = false;
    }

    if (data.number_of_recurring > this.extra_data.contract.remaining_payments) {
      // this.isRecurringValid = true;
      return;
    } else {
      // this.isRecurringValid = false;
    }

    if (data.partial_amount > this.extra_data.contract.remaining_amount) {
      this._shared.customPopups('Custom amount cannot be greater than the total remaining amount', 2)
      return;
    }
    this._api.api(data, 'payment/add_arrangement')
      .subscribe((res: any) => {
        if (res.is_error === 0) {
          // modal.hide();
          const new_arrangement_date = new Date();
          new_arrangement_date.setDate(new_arrangement_date.getDate() + 1);
          this.arrangementForm.patchValue({
            arrangement: new_arrangement_date
          });
          this._shared.customPopups('Arrangement added successfully.', res.is_error);
          this.source_id = 0;
          this.passEntry.emit({ success: true });
          this.hide();
        } else {
        }
      });
  }

  disableBackupMethod(type: any) {
    if (type) {
      this.extra_data.contract.choose_backup = type;
    } else {
      this.extra_data.contract.choose_backup = type;
    }
  }

  changePaymentMethods() {
    if (!this.extra_data.contract.notes || this.extra_data.contract.notes.trim().length == 0) {
      this._shared.customPopups('Please enter a valid notes.', 2)
      return;
    }
    if (!this.new_source_id) {
      this._shared.customPopups('Please select a card for the recurring payment.', 2)
      return;
    }
    if (this.new_source_id == this.new_secondary_source_id) {
      this._shared.customPopups('Default and backup payment methods should be different.', 2)
      return;
    }
    if (!this.new_secondary_source_id && this.extra_data.contract.choose_backup) {
      this._shared.customPopups('Please select a card for the backup payment.', 2)
      return;
    }
    if (this.new_source_id && this.new_secondary_source_id) {
      var selected_recurring_card = this.extra_data.contract.allMethods.filter((card: any) => card.source_id == this.new_source_id)
      var selected_backup_card = this.extra_data.contract.allMethods.filter((card: any) => card.source_id == this.new_secondary_source_id)
      if (selected_recurring_card && selected_recurring_card[0] && selected_backup_card && selected_backup_card[0] && selected_recurring_card[0].fingerprint && selected_backup_card[0].fingerprint && selected_recurring_card[0].fingerprint == selected_backup_card[0].fingerprint) {
        this._shared.customPopups('Default and backup payment methods should be different.', 2)
        return;
      }
    }
    var data: any = {
      contract_id: parseInt(this.extra_data.contract.contract_id),
      source_id: this.new_source_id,
      deduct_one_dollar: this.extra_data.contract.deduct_one_dollar ? 1 : 0,
      notes: this.extra_data.contract.notes
    };
    if (this.new_secondary_source_id && this.extra_data.contract.choose_backup) {

      data['secondary_source_id'] = this.new_secondary_source_id;

      if (this.new_secondary_source_id < 0) {
        this.new_secondary_source_id
        data['secondary_source_id'] = 0
      }
    }
    this._api.api(data, 'finance/change_payment_methods')
      .subscribe((response: any) => {
        if (response.is_error === 0) {
          this._shared.customPopups('Payment methods updated successfully', 0);
          this.passEntry.emit({ success: true });
          this.cancel();
        } else {
          if (response.original_text || response.err) {
            this._shared.customPopups(response.original_text || response.err, response.is_error);
          } else {
            this._shared.customPopups(response.flag, response.is_error);
          }
        }
      });
  }

  reset() {
    this.new_source_id = this.extra_data.contract.new_source_id;
    this.new_secondary_source_id = this.extra_data.contract.new_secondary_source_id;
    this.choose_backup = this.extra_data.contract.choose_backup;
  }

  chargeDoctorfn() {

    if (this.onOffCustomAmount && !this.chargeDoctorform.value.manual_amount) {
      this._shared.customPopups('Please enter an amount', 1);
      return;
    }
    if (!this.chargeDoctorform.value.notes) {
      this._shared.customPopups('Please enter notes', 1);
      return;
    }
    if ((this.chargeDoctorform.value.manual_amount < 1) || (this.chargeDoctorform.value.manual_amount > this.extra_data.contract.maxManualAmount)) {
      return;
    }

    const data = {
      dispute_id: this.extra_data.contract._id,
      cd_payment_type: 20,
      name: this._local.customGetItem('admin_name'),
      contract_id: this.extra_data.contract.contract_id,
      notes: this.chargeDoctorform.value.notes,
      manual_amount: this.chargeDoctorform.value.manual_amount || null,
      is_remove_from_unpaid: this.chargeDoctorform.value.is_remove_from_unpaid ? 1 : 0,
      skip_email: this.chargeDoctorform.value.skip_email ? 1 : 0,
    };


    this._api.api(data, 'charge_doctor')   //// not done 
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  OnOffButton(event: any) {
    var checkStatus = event.target['checked'] ? 1 : 0
    if (checkStatus && checkStatus == 1) {
      this.manual_amount = null;
      this.onOffCustomAmount = 1;
    } else {
      this.onOffCustomAmount = 0;
      this.manual_amount = parseFloat((this.extra_data.contract.charge_from_doctor).toFixed(2));
    }
  }

  setTwoNumberDecimal(el: any) {

    var decimal = el.value.toString().split(".");
    if (decimal[1] && decimal[1].length > 2) {
      var dec = decimal[1].substring(0, 2);
      var num = decimal[0] + '.' + dec;
      el.value = parseFloat(num).toFixed(2);
    }
  };

  sendWarningMailfn() {
    if (!this.sendWarningMailform.value.notes) {
      this._shared.customPopups('Please enter notes', 1);
      return;
    }

    const data = {
      dispute_id: this.extra_data.contract._id,
      cd_payment_type: 21,
      name: this._local.customGetItem('admin_name'),
      contract_id: this.extra_data.contract.contract_id,
      notes: this.sendWarningMailform.value.notes,

    };


    this._api.api(data, 'take_back_dispute_email')  //// not done 
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  chargeDisputefn() {
    if (!this.chargeDisputeform.value.notes) {
      this._shared.customPopups('Please enter notes', 1);
      return;
    }

    const data = {
      dispute_id: this.extra_data.contract._id,
      cd_payment_type: 22,
      name: this._local.customGetItem('admin_name'),
      contract_id: this.extra_data.contract.contract_id,
      notes: this.chargeDisputeform.value.notes,

    };
    this._api.api(data, 'charge_Dispute')   ////not done
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  refundPopfn() {
    if (!this.refundPopform.value.notes) {
      this._shared.customPopups('Please enter notes', 1);
      return;
    }
    const data = {
      dispute_id: this.extra_data.contract._id,
      cd_payment_type: 23,
      name: this._local.customGetItem('admin_name'),
      contract_id: this.extra_data.contract.contract_id,
      notes: this.refundPopform.value.notes,

    };
    this._api.api(data, 'refund_doctor')   ////not done
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  moveRemoveUnpaidPopFn() {
    if (!this.moveRemoveUnpaidform.value.notes) {
      this._shared.customPopups('Please enter notes', 1);
      return;
    }
    const data = {
      dispute_id: this.extra_data.contract._id,


    };
    this._api.api(data, 'finance/toggle_unpaid_dispute')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.hide();
          this.passEntry.emit({ success: true });
        }
      });
  }

  assignData(event: any) {
    this.source_id = event.source_id;
    this.payment_source_type = event.payment_source_type;
  }

  addNewService() {
    if (this.serviceName && this.serviceName.trim().length > 0) {
      if (!this.serviceBreakDowns.find(s => s.service == this.serviceName)){
        this.serviceBreakDowns.push({
          service: this.serviceName.trim(),
        });
      }
      this.serviceName = '';
      setTimeout(() => {
        this.serviceContent.nativeElement.scrollTo({ left: (this.serviceContent.nativeElement.scrollLeft + 1500), behavior: 'smooth' });
      })
    }
  }

  removeService(index: number) {
    this.serviceBreakDowns.splice(index, 1);
  }

  updateServices() {
    let data = {
      contract_id: this.extra_data.contract.contract_id,
      contract_service_record: this.serviceBreakDowns,
      notes: this.editServiceNotes
    }
    this._api.api(data, 'finance/update_contract_service').subscribe((data: any) => {
      if (data) {
        this.serviceBreakDowns = data.data;
        this.hide();
        this.passEntry.emit({ success: true });
      }
    });
  }

  addNotes() {
    let data = {
      // access_token: MainService.customGetItem('adminToken'),
      pf_id: this.extra_data.contract.pf_id,
      name: this.addNotesForm.value.name,
      notes: this.addNotesForm.value.notes
    };
    console.log(data);
    if (this.addNotesForm.valid) {
      this._api.api(data, 'finance/add_note')
        .subscribe((data: any) => {
          console.log(data);
          if (data.is_error == 0) {
            this.hide();
            this.passEntry.emit({ success: true });
            // this.load_notes = false;
            // modal.hide();
            // form.reset();
            // this.cd.detectChanges();
          }
        }, (error: any) => {
          console.log(error);
        })
    }
  }

  copied(event: any) {
    if (event.isSuccess) {
      this._shared.customPopups('Link Copied Successfully', 0)
    }
  }

}



interface modal_data {
  contract: any,
  countryData: any,
  currencyData: any,
  recurring?: any,
  download_contract?: number,
  charge?: any,
  today_date?: any
}


