<div class="p-3">
  
<div class="d-flex justify-content-between align-items-center mb-3">
  <h3 class="m-0 fs-24 fw-700 text-secondary">Manage Customers</h3>
    <span class="count rounded py-2 bg-primary py-2 px-3 fs-18 fw-600">
      Total Customers: {{ resData.totalRecords || 0 }}
    </span>
</div>

<div class="row m-0 align-items-center mb-2" *ngIf="show_filters">
  <app-filters
    class="row m-0 w-100"
    [filters]="filters"
    (finalValues)="filterValues($event)"
    (resetAllFilters)="resetFilters($event)"
  >
  </app-filters>
</div>

<div class="card">
  <div class="d-flex justify-content-between align-items-center m-0 p-2">
    <div class="">
      <p class="fs-18 fw-600 text-grey">
        {{ dateRangeValue?.[0] ? (dateRangeValue[0] | date : 'mediumDate') : '' }}
        to
        {{ dateRangeValue?.[1] ? (dateRangeValue[1] | date : 'mediumDate') : '' }}
      </p>
    </div>

    <div class="">
      <button
        (click)="showFilter(!show_filters)"
        type="button"
        class="btn rounded-pill py-1 px-2 me-3"
        [ngClass]="{
          'btn-primary': show_filters,
          'btn-outline': !show_filters
        }"
      >
        <span class="fs-16">
          {{ show_filters ? "Hide Filters" : "Show Filters" }}
        </span>
        <i class="fa fa-filter"></i>
      </button>
      <div class="btn-group ml-2" dropdown>
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn btn-primary rounded-pill py-1 px-2 dropdown-toggle"
          aria-controls="dropdown-basic"
        >
          Export <span class="caret"></span>
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-right"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportXLSX(0)">
              Export XLSX
            </button>
          </li>
          <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportXLSX(1)">
              Export CSV
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>S No.</th>
          <th>Registration Date</th>
          <th style="min-width: 150px">Name</th>
          <th style="min-width: 150px">Email</th>
          <th style="min-width: 150px">Phone</th>
          <th style="min-width: 120px">Date of Birth</th>
          <th style="min-width: 200px">Address</th>
          <th>SSN</th>
          <th>Customer Code</th>
          <th *ngIf="filter_label === 0">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let patient of patients; let i = index">
          <td>{{ (resData.pageNumber - 1) * 10 + (i + 1) }}</td>
          <td>
            {{ patient.date_registered | date : "MM-dd-yyyy" : timezone }}
          </td>
          <td>
            <a
              class="text-primary"
              [routerLink]="['/app/customers/profile', patient.patient_id]"
            >
              {{
                (patient.title ? patient.title + " " : "") +
                  patient.patient_first_name +
                  " " +
                  (patient.patient_last_name || "")
              }}
            </a>
          </td>
          <td>{{ patient.patient_email || "-" }}</td>
          <td>
            {{
              patient.patient_mobile
                ? patient.patient_mobile_country_code +
                  "-" +
                  patient.patient_mobile
                : ""
            }}
          </td>
          <td>
            {{
              patient.date_of_birth
                ? (patient.date_of_birth | date : "MM-dd-yyyy" : "+0000")
                : "-"
            }}
          </td>
          <td>
            {{
              patient.patient_address
                ? patient.patient_address +
                  (patient.city ? ", " + patient.city : "") +
                  (patient.state_name ? ", " + patient.state_name : "") +
                  (patient.zip ? ", " + patient.zip : "")
                : "-"
            }}
          </td>
          <td>
            {{
              patient.patient_ssn
                ? "*****" + (patient.patient_ssn | slice : 5 : 9)
                : "-"
            }}
          </td>
          <td>{{ patient.patient_code || "-" }}</td>
          <td *ngIf="filter_label === 0">
            <a
              tooltip="View Profile"
              class="pl-2"
              [routerLink]="['/app/customers/profile', patient.patient_id]"
            >
              <i class="fa fa-user"></i>
            </a>
            <a
              tooltip="View Contracts"
              class="pl-2"
              [routerLink]="'/app/customers/contracts/' + patient.patient_id"
              [queryParams]="{ contractType: 1 }"
            >
              <i class="fa fa-file-o"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="border-top" *ngIf="patients?.length">
    <div class="d-flex justify-content-between m-0 align-items-center mt-2 p-2">
      <div class="">
        <p class="font-weight-bold">
          Showing {{ 1 + (resData.pageNumber - 1) * 10 }} to
          {{ patients.length + (resData.pageNumber - 1) * 10 }} of
          {{ resData.totalRecords || "all" }}
        </p>
      </div>
      <div class="">
        <pagination
          class="float-right pagination-sm"
          [totalItems]="resData.totalRecords"
          [maxSize]="5"
          [boundaryLinks]="true"
          [rotate]="false"
          (pageChanged)="pageChanged($event)"
          [(ngModel)]="resData.pageNumber"
        >
        </pagination>
      </div>
    </div>
  </div>
</div>

</div>