<div class="new-password-wrapper">
    <div class="new-password-wrapper-inner vh-100">
        <div class="new-password-header px-3 py-2">
            <img src="assets\images\icons\FM-logo.svg" alt="">
        </div>
        <div class="new-password-body d-flex align-items-center justify-content-center">
            <form [formGroup]="newPasswordForm">
                <div class="new-password-form w-100">
                    <div class="mb-3">
                        <div [ngClass]="[ (newPasswordForm.controls['new_password'].touched && newPasswordForm.controls['new_password'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
                            class="">
                            <div class="position-relative">
                                <label for="text" class="label-name fs-14 w-100">
                                    New password
                                    <span style="
                                    box-shadow: 1px 1px 5px #000;
                                " class="position-absolute d-block bg-white px-3 py-3 mnw-300 info-tooltip border-tooltip radius-16"
                                        *ngIf="showPassTooltip">
                                        <p style="padding: 0 0 0 12px !important;margin-bottom: 0px; font-size: 16px;"
                                            class="m-0 fs-12 fw-700 m-0 p-0">Password must contain</p>
                                        <div class="d-flex align-items-center justify-content-between my-2"
                                            style="padding-left: 10px;">
                                        </div>
                                        <ul class="tooltip-list" style="text-align: left;font-size: 14px;">
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasMaxChar}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasMaxChar}"><img
                                                        [src]="hasMaxChar ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Atleast 6 characters</li>
                                            <li style="display: flex;align-items: start;"
                                            [ngClass]="{'text-decoration-line-through color-167': hasUpperCase}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasUpperCase}"><img
                                                        [src]="hasUpperCase ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Upper and lower case</li>
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasSpecialChar}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasSpecialChar}"><img
                                                        [src]="hasSpecialChar ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Add a symbol (e.g.!&#64;#$%^&*)</li>
                                            <li style="display: flex;align-items: start;"
                                                [ngClass]="{'text-decoration-line-through color-167': hasNumber}"
                                                class="text-nowrap text-decoration-none fs-14 color-22 mt-1"><span
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    class="me-2 hw-16"
                                                    [ngClass]="{'p-1 bg-blue-206': hasNumber}"><img
                                                        [src]="hasNumber ? 'assets/images/icons/tick-blue.png' : 'assets/images/icons/blue-circle.png'"
                                                        alt=""></span>Include a number (0-9) </li>
                                        </ul>
                                    </span>
                                </label>
                            </div>
                            <div class="position-relative">
                                <input [type]="!hidePwd ? 'password':'text'" class="input_field_pass w-100" required
                                    formControlName="new_password" placeholder="Enter New Password"
                                    (focus)="showPassTooltip = true" (blur)="showPassTooltip = false;" />
                                <img [src]="hidePwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                                    alt="" class="position-absolute eye-icon cursor" (click)="hidePwd = !hidePwd">
                            </div>
                        </div>
                        <!-- <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                            *ngIf="(newPasswordForm.controls['new_password'].touched && newPasswordForm.controls['new_password'].invalid)">
                            {{newPasswordForm.controls['new_password'].errors && (hasMaxChar === false) ? 'Password must
                            be at least 6 characters long.' : (newPasswordForm.controls['new_password'].errors &&
                            (hasUpperCase === false) ? 'Password must contain at least one uppercase and one lowercase letter.' : (newPasswordForm.controls['new_password'].errors &&
                            (hasSpecialChar === false) ? 'Password must contain at least one special character.' : (newPasswordForm.controls['new_password'].errors &&
                            (hasNumber === false) ? 'Password must contain at least one number.' : ('Please
                            enter a valid Password'))))}}
                        </p> -->
                    </div>

                    <div class="mb-3">
                        <div [ngClass]="[ (newPasswordForm.controls['confirm_password'].touched && newPasswordForm.controls['confirm_password'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
                            class="">
                            <label for="text" class="label-name fs-14 w-100">
                                Confirm password
                            </label>
                            <div class="position-relative">
                                <input [type]="!hideConfirmPwd ? 'password':'text'" class="input_field_pass w-100"
                                    required formControlName="confirm_password" placeholder="Re-Enter Your New Password" />
                                <img [src]="hideConfirmPwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                                    alt="" class="position-absolute eye-icon cursor"
                                    (click)="hideConfirmPwd = !hideConfirmPwd">
                            </div>
                        </div>
                        <!-- <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                            *ngIf="(newPasswordForm.controls['confirm_password'].touched && newPasswordForm.controls['confirm_password'].invalid)">
                            {{newPasswordForm.controls['confirm_password'].errors ? 'Please enter a valid Password' :
                            ''}}
                        </p> -->
                    </div>

                    <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600 me-3"
                        (click)="submit()">Submit</button>
                    <button class=" px-4 mb-3 btn btn-outline fs-16 fw-600" [routerLink]="'/login'">Login</button>
                </div>
            </form>
        </div>
    </div>
</div>