import { AfterViewInit, Component, ElementRef, Input, OnDestroy,Output, OnInit, ViewChild ,EventEmitter} from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import { EditorState } from '@codemirror/state';
import { html } from '@codemirror/lang-html';
import { EditorView, ViewPlugin, ViewUpdate  } from '@codemirror/view';
// import { basicSetup } from '@codemirror/basic-setup';

@Component({
  selector: 'app-editor-codemirror',
  templateUrl: './editor-codemirror.component.html',
  styleUrl: './editor-codemirror.component.scss'
})
export class EditorCodemirrorComponent implements OnInit, AfterViewInit, OnDestroy {
  
  editor!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  
  @Input() html!: string;  // Shared content for ngx-editor and CodeMirror
  @Input() editHtml!: any;  // Shared content for ngx-editor and CodeMirror
  @Output() contentUpdated = new EventEmitter<string>();
  @ViewChild('codemirror', { static: true }) codemirrorElement!: ElementRef;
  codemirrorView!: EditorView;
  private isUpdating = false;

  constructor () {}

  ngOnInit(): void {
    this.editor = new Editor();
    
  }

  ngAfterViewInit(): void {
    // Initialize CodeMirror 6 inside a div
    this.codemirrorView = new EditorView({
      state: EditorState.create({
        doc: this.html,  // Initial content for CodeMirror
        extensions: [
          // basicSetup,
          html(),          // Language mode for HTML
          this.syncHtmlWithCodeMirror(),
        ]
      }),
      parent: this.codemirrorElement && this.codemirrorElement.nativeElement // Attach to the DOM element
    });

    // Listen for changes in CodeMirror
    // this.codemirrorView.dispatch({
    //   changes: { from: 0, to: this.codemirrorView.state.doc.length, insert: this.html }
    // });
  }

  // Create an extension to synchronize changes from CodeMirror to the shared HTML property
  private syncHtmlWithCodeMirror() {
    const _this = this;
    return ViewPlugin.fromClass(
      class {
        constructor(private view: EditorView) {
          // Initialize with the current document content
          this.updateHtml(view);
        }

        update(update: ViewUpdate) {
          if (update.docChanged && !_this.isUpdating) {
            this.updateHtml(update.view);
          }
        }

        updateHtml(view: EditorView) {
          const newDoc = view.state.doc.toString();
          if (newDoc !== _this.html) {
            _this.isUpdating = true;
            // Update shared content if it has changed
            _this.html = newDoc; 
            // Update ngx-editor content
            setTimeout(() => {
              _this.updateNgxEditorContent(newDoc);
              _this.isUpdating = false; // Allow further updates
            });
          }
        }
      }
    );
  }

  // Update ngx-editor content from CodeMirror
  updateNgxEditorContent(content: string): void {
    // this.html = content; // Update the local variable
    this.editor.setContent(content); // Set the content in ngx-editor
  }

  onEditorChange(content: string): void {
    this.contentUpdated.emit(content)// Update shared content on ngx-editor change
    if (this.codemirrorView && !this.isUpdating) {
      this.isUpdating = true;
      this.codemirrorView.dispatch({
        changes: { from: 0, to: this.codemirrorView.state.doc.length, insert: this.html }
      });
      this.isUpdating = false;
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy(); // Clean up editor on destroy
  }
}
