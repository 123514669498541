<div class="modal-header new_modal d-flex align-items-center justify-content-between bg-primary">
    <h4 class="modal-title text-white fs-18">Add a Note</h4>

    <img src="assets/images/icons/white-cross.svg" alt="" (click)="hideModal()" class="cursor hw-24">
</div>
<div class="modal-body" *ngIf="notes">
    <div class="row">
        <div class="col-6">
            <div class="form-group mb-2">
                <label class="fs-14 fw-400">Type of Contact</label>
                <select required class="input_field" name="contactType" id="contactType" [(ngModel)]="notes.contact_type"
                    (change)="updateField('type_of_call', $event)">
                    <option value="0" selected disabled >Select Contact Type</option>
                    <option *ngFor="let t_o_c of type_of_call_arr" [value]="t_o_c.contact_id">{{t_o_c.contact_name}}</option>
                    <!-- <option value="0" selected disabled>Select Contact Type</option>
                    <option value="1">Incoming</option>
                    <option value="2">Outgoing</option>
                    <option value="3">Email</option>
                    <option value="4">Text</option>
                    <option value="5">Chat</option>
                    <option value="6">Verification Call</option>
                    <option value="7">Potential Business</option>
                    <option value="8">Validation</option> -->
                </select>
            </div>
        </div>
        <div class="col-6" *ngIf="![6, 7, 8].includes(notes.type_of_call)">
            <div class="form-group mb-2">
                <label class="fs-14 fw-400">Result of Contact</label>
                <select class="input_field" name="contactResult" id="contactResult" [required]="notes.contact_type == 2" [(ngModel)]="notes.contact_result" [disabled]="!notes.contact_type || notes.contact_type == '9'" (change)="updateField('result_of_contact', $event)">
                    <option value="0" selected disabled >Select Contact Result</option>
                    <option *ngFor="let r_o_c of result_of_contact_arr" [value]="r_o_c.result_id">{{r_o_c.result_value}}</option>
                    <!-- <option value="1">Voicemail</option>
                    <option value="2">No Answer</option>
                    <option value="3">Voicemail Full</option>
                    <option value="4">Answered</option> -->
                </select>
            </div>
        </div>
        <div class="col-6" *ngIf="data.assign_type == 4">
            <div class="form-group mb-2">
                <label class="fs-14 fw-400">Approval Needed for</label>
                <select class="input_field" name="contactResult" required [(ngModel)]="notes.request_type">
                    <option *ngFor="let request of data.contractInfo[0].csr_request_types" [value]="request.type">{{request.name}}
                    </option>
                </select>
            </div>
        </div>


        <div class="col-12">
            <div class="form-group mb-2">
                <label class="fs-14 fw-400 mb-2">Send Request to </label>
                <ngx-select [items]="globalAdminList" optionValueField="admin_id" optionTextField="admin_name"
                    (select)="selectAdmin($event)" [allowClear]="true" (selectionChanges)="adminClear($event)"
                    [placeholder]="notes.assigned_name || 'please enter send request to'">
                    <ng-template ngx-select-option-selected let-option>
                        {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
                    </ng-template>
                    <ng-template ngx-select-option let-option let-text="text">
                        {{option.data.admin_name}} <br>
                        <small>{{option.data.admin_email}}</small>
                    </ng-template>
                    <ng-template ngx-select-option-not-found>
                        Not found
                    </ng-template>
                </ngx-select>


            </div>
        </div>

        <div [ngClass]="{'col-4': data.assign_type == 4, 'col-6': data.assign_type != 4}">
            <div class="form-group">
                <label class="fs-14 fw-400">
                    Priority*
                    <i class="ml-1 fa fa-info-circle" tooltip="Set the priority for the note."></i>
                </label>
                <select [(ngModel)]="notes.priority" required class="input_field" name="priority">
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                </select>
            </div>
        </div>

        <div [ngClass]="{'col-4': data.assign_type == 4, 'col-6': data.assign_type != 4}">
            <div class="form-group">
                <label class="fs-14 fw-400">
                    Status*
                    <!-- <i class="ml-1 fa fa-info-circle" tooltip="Set the status for the note."></i> -->
                </label>
                <select [(ngModel)]="notes.status" (change)="onChangeStatus($event)" required class="input_field"
                    name="status">
                    <option value="" disabled>Please Select</option>
                    <option value="0" selected>Pending</option>
                    <option value="1">Closed</option>
                    <option value="2">Re-Open</option>
                    <option value="3">Follow Up</option>
                </select>
            </div>
        </div>

        <div class="form-group col-6 mb-2" style="position: relative; padding-top: 5px;" *ngIf="statusClosed">
            <label class="fs-14 fw-400" for="">Dialed Number</label>
            <div class=" d-flex align-items-center input_field p-0 overflow-hidden">
                <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center"  *ngIf="contactNumber">+1 </span>
                <!-- <input class="input_color" type="number" name="contact_number1" [ngClass]="{contact_class: contactNumber}" [(ngModel)]="contactNumber" placeholder="Type your number" style="width: 100%;
                            border: 1px solid #dde6e9;
                            padding: 6px 24px;
                            border-radius: 4px;"  onKeyPress="if(this.value.length==10) return false;"> -->
                <input class="input_field border-0 border-start m-0 flex-fill rounded-0" type="text" name="contact_number1" [ngClass]="{contact_class: contactNumber}"
                    [(ngModel)]="contactNumber" placeholder="Type your number" [mask]="_shared.mask" 
                    onKeyPress="if(isNaN(String.fromCharCode(event.keyCode))) return false;">
            </div>
        </div>


        <div class="col-6" *ngIf="statusClosed">
            <label class="fs-14 fw-400">Followup Date*</label>
            <div class=" d-flex align-items-center input_field p-0 overflow-hidden">
                <input readonly type="text" #dp="bsDatepicker" bsDatepicker class="input_field border-0 border-end m-0 flex-fill rounded-0" name="followupDate"
                    [(ngModel)]="notes.followup_date" [minDate]="minDate" required [bsConfig]="bsConfig"
                    placeholder="MM/DD/YYYY">
                    <button class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center" (click)="dp.toggle()"
                        [attr.aria-expanded]="dp.isOpen" type="button"><i class="fa fa-calendar"></i></button>
            </div>
        </div>
        <div class="col-6" *ngIf="statusClosed">
            <label class="fs-14 fw-400 mb-2">Followup Time({{timezoneName}})*
                <i class="ml-1 fa fa-info-circle" tooltip="Please set followup time in 24 hour format."></i>
            </label>
            <div class="row">
                <div class="col-6">
                    <ngx-select [items]="hours" optionValueField="value" optionTextField="value" name="value"
                        [allowClear]="false" placeholder="HH" [(ngModel)]="notes.followup_hour">
                        <ng-template ngx-select-option-selected let-option>
                            {{option.data.value}}
                        </ng-template>
                        <ng-template ngx-select-option-not-found>
                            Not found
                        </ng-template>
                    </ngx-select>
                </div>
                <div class="col-6">
                    <ngx-select [items]="minutes" optionValueField="value" optionTextField="value" name="value"
                        [allowClear]="false" placeholder="MM" [(ngModel)]="notes.followup_minutes">
                        <ng-template ngx-select-option-selected let-option>
                            {{option.data.value}}
                        </ng-template>
                        <ng-template ngx-select-option-not-found>
                            Not found
                        </ng-template>
                    </ngx-select>
                </div>
            </div>
        </div>
        <div class="w-100 my-1"></div>
        <div class="col-12">
            <div class="form-group">
                <label class="fs-14 fw-400">Notes*</label>
                <textarea required name="notes" [(ngModel)]="note" class="input_field"
                    placeholder="Enter notes here" rows="4"></textarea>
            </div>
        </div>
        <div class="w-100 my-1"></div>
        <div class="col-6">
            <div class="px-3">

                    <label class="fs-14 fw-400 mb-2">Customer Feelings towards Finance Mutual</label>
               
              
                    <div class="d-flex align-items-center">
                    <a href="javascript:void(0)" (click)="selectMood(1)" class="text-muted me-2"
                        [ngClass]="{'text-success':notes.customer_status == 1}">
                        <i class="fa fa-smile-o fa-3x" tooltip="Happy"></i>
                    </a>
               
           
                    <a href="javascript:void(0)" (click)="selectMood(2)" class="text-muted"
                        [ngClass]="{'text-warning':notes.customer_status == 2}">
                        <i class="fa fa-frown-o fa-3x" tooltip="Mad"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="text-end">
                <label for="images" class="btn btn-primary rounded">Attach Image/Document</label>
                <input type="file" name="" id="images" #imageElement
                    accept=".gif, .jpeg, .jpg, .png, .doc,.docx,application/msword, .pdf, .xls,.xlsx, .csv"
                    (change)="fileSelect($event)" class="d-none">
            </div>

            <div *ngFor="let file of images; let i=index">
                <div *ngIf="file.subs && file.subs[0]">
                    <div class="d-flex" *ngIf="file.subs[0].message">
                        <progressbar [value]="file.subs[0].message" type="warning" [striped]="true">
                            {{file.subs[0].message}}%</progressbar>
                        <i class="fa fa-close ml-2" tappable tooltip="Cancel upload"
                            (click)="cancelImageUpload(file, i)"></i>
                    </div>


                    <div *ngIf="file.subs[0].images && file.subs[0].images[0]"
                        class="d-flex justify-content-between align-items-center border mb-1">
                        <span>
                            <i class="fa fa-trash text-danger mr-2" (click)="deletFile(file.image, i)"
                                tooltip="Delete uploaded file" tappable></i>
                            <a [href]="file.image" target="_blank">Click here to view</a>
                        </span>
                        <i class="fa fa-check text-success" tooltip="File Uploaded"></i>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button type="submit" class="btn btn-primary" (click)="handleAddNotes()">Add</button>
    </div>
</div>