import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrl: './signature-pad.component.scss'
})
export class SignaturePadComponent implements AfterViewInit {
  @ViewChild('signaturePad', { static: true }) signaturePadElement!: ElementRef<HTMLCanvasElement>;
  dimensions: any = {
    width: 580,
    height: 180
  };
  signaturePad!: SignaturePad;

  constructor(private _shared: SharedService) {}

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)',
    });
    this.signaturePad.addEventListener('endStroke', this.onCompletedDrawing.bind(this));
    const id = document.getElementById('signature-pad');
    if (id) {
      this.dimensions = id.getBoundingClientRect();
      console.log(this.dimensions)
    }
  }

  onCompletedDrawing(): void {
    if (this.signaturePad.isEmpty()) {
      // alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL();
      this._shared.signaturePadBase64 = dataURL;
    }
  }

  clear(): void {
    this.signaturePad.clear();
    this._shared.signaturePadBase64 = '';
  }
}
