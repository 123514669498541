import { Injectable, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
// import { filter } from 'rxjs/operators';

@Injectable(
  { providedIn: 'root' }
)
export class RouteAuthService {
  routerSubscription: any;
  main_menu: any;
  public restricted_buttons = this.resetRestrictedButtons() ;

  constructor(public router: Router) {
    this.resetRestrictedButtons();
  }

  searchPath(_menu: Array<any>, path: string) {
    const menu = [..._menu];
    let has_path = false;
    if (path === '/login') {
      return true;
    }
    menu.forEach(item => {
      /*only check path upto 2 levels. eg /app/doctors*/
      let path_limit_arr = path.split('/').filter(d => d && d !== '');
      let path_limit = path_limit_arr.length;
      if (Number(path_limit_arr[path_limit_arr.length - 1])) {
        path_limit -= path_limit;
        path_limit_arr = path_limit_arr.slice(0, path_limit);
        path_limit_arr.push('*');
      }
      const loop_path = item.path.split('/');
      const find_path = path.split('/');
      const intersection_of_paths = [...loop_path.filter((d: any) => !find_path.includes(d)), ...find_path.filter(d => !loop_path.includes(d))];
      if ((intersection_of_paths.length === 0)
        || (intersection_of_paths.length === 2 && intersection_of_paths[0] === '*')
        || (intersection_of_paths.length === 1 && Number(intersection_of_paths[0]))) {
        has_path = true;
      } else {
        if (item.path === '/' + path_limit_arr.join('/')) {
          has_path = true;
        } else if (item.submenu) {
          if (this.searchPath(item.submenu, path)) {
            has_path = true;
          }
        }
      }
    });
    return has_path;
  }

  searchRestricted(menu: Array<any>, path: string, button: string): any {
    let restricted = false;

    if (menu) {
      for (const item of menu) {
        let path_limit_arr = path.split('/').filter(d => d && d !== '');
        let path_limit = path_limit_arr.length;
        // console.log(path_limit,path_limit_arr)
        if (Number(path_limit_arr[path_limit_arr.length - 1])) {
          path_limit -= 1;
          path_limit_arr = path_limit_arr.slice(0, path_limit);
          path_limit_arr.push('*');
        }
        // console.log(path_limit, item.path === '/' + path_limit_arr.join('/'), item.path , '/' + path_limit_arr.join('/'), path_limit_arr);
        /*only check path upto 2 levels. eg /app/doctors*/
        if (item.path === '/' + path_limit_arr.join('/') && item.restricted_items) {
          restricted = item.restricted_items.split(',').includes(button);
        } else if (item.submenu) {
          restricted = this.searchRestricted(item.submenu, path, button);
          // console.log("222",restricted)
        }

        if (restricted) {
          break;
        }
      }
    }
    return restricted;
  }

  routeAuth(main_menu: any) {
    const _this = this;
    _this.main_menu = main_menu;
    /*  if (_this.routerSubscription) {
        _this.routerSubscription.unsubscribe();
      }*/
    _this.routerSubscription = _this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        _this.resetRestrictedButtons();
        if (_this.main_menu) {
          for (const key in _this.restricted_buttons) {
            if (_this.restricted_buttons.hasOwnProperty(key)) {
              const result = _this.searchRestricted(_this.main_menu, _this.router.routerState.snapshot.url.split('?')[0], key);
              // console.log(result, key, _this.router.routerState.snapshot.url);
              _this.restricted_buttons[key] = result;
            }
          }
          // console.log(_this.restricted_buttons, 'are the restricted buttons',_this.main_menu, event.url.split('?')[0]);
          if (!_this.searchPath(_this.main_menu, event.url.split('?')[0])) {
            _this.router.navigate(['/404']);
          }
        }
      }
    })
    // _this.routerSubscription = _this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    //   .subscribe((event: NavigationEnd) => {
    //     _this.resetRestrictedButtons();
    //     if (_this.main_menu) {
    //       for (const key in _this.restricted_buttons) {
    //         if (_this.restricted_buttons.hasOwnProperty(key)) {
    //           const result = _this.searchRestricted(_this.main_menu, _this.router.routerState.snapshot.url.split('?')[0], key);
    //           // console.log(result, key, _this.router.routerState.snapshot.url);
    //           _this.restricted_buttons[key] = result;
    //         }
    //       }
    //       // console.log(_this.restricted_buttons, 'are the restricted buttons',_this.main_menu, event.url.split('?')[0]);
    //       if (!_this.searchPath(_this.main_menu, event.url.split('?')[0])) {
    //         _this.router.navigate(['/404']);
    //       }
    //     }
    //   })     
    // )
    if (main_menu) {
      // tslint:disable-next-line:forin
      for (const key in _this.restricted_buttons) {
        // if (key == 'option_to_deduct_one_dollar') {
        //   _this.restricted_buttons[key] = true;
        //   return;
        // }
        if (_this.restricted_buttons.hasOwnProperty(key)) {
          _this.restricted_buttons[key] = _this.searchRestricted(main_menu, _this.router.url.split('?')[0], key);
        }
      }
      if (!_this.searchPath(main_menu, _this.router.url.split('?')[0])) {
        _this.router.navigate(['/404']);
      }
    }
  }

  private resetRestrictedButtons(): any {
    return this.restricted_buttons = {
      export: false,
      total_fee: false,
      today_follow_up: false,
      upcoming_followups: false,
      overdue_followups: false,
      unassigned_lead: false,
      today_duplicate: false,
      total_duplicate: false,
      total_deleted: false,
      add: false,
      follow_up: false,
      assign_multiple_leads: false,
      assign_single_lead: false,
      follow_up_start: false,
      follow_up_end: false,
      follow_up_column: false,
      spoke_to_column: false,
      delete: false,
      delete_all: false,
      import: true,
      download_sample_file: false,
      today_lead: false,
      edit: false,
      assigned_leads: false,
      admin_list: false,
      reactivate: false,
      doctor: false,
      contract: false,
      patient: false,
      partner: false,
      marketing_assignment: false,
      training_assignment: false,
      lead_assignment: false,
      request_review: false,
      payment_actions: false,
      refund: false,
      add_schedule: false,
      edit_schedule: false,
      delete_schedule: false,
      sort: false,
      change_status: false,
      remove_from_unpaid: false,
      answer: false,
      approve: false,
      reject: false,
      contract_calculation_stats: false,
      view: false,
      need_doc: false,
      move_to_unpaid: false,
      special_move_to_unpaid: false,
      note: false,
      view_claim: false,
      change_claim_visibility: false,
      edit_contract: false,
      edit_contract_values: false,
      edit_claim_service_breakdown: false,
      edit_contract_service_breakdown: false,
      expired: false,
      payout_settings: false,
      transaction_fees: false,
      partner_referral: false,
      refund_customer: false,
      reverse_denefits: false,
      stripe_balance: false,
      kpi_stats: false,
      reverse_business: false,
      reverse_all: false,
      reverse_partner: false,
      refund_late_fee: false,
      doctor_stats: false,
      partner_stats: false,
      patient_stats: false,
      claim_stats: false,
      financed_contract_stats: false,
      split_request_stats: false,
      zero_contract_stats: false,
      ten_contract_stats: false,
      new_ten_contract_stats: false,
      financed_amount_stats: false,
      patient_card_delete: false,
      show_contact_in_dnd: false,
      move_to_unpaid_cancelled_contract: false,
      payment_arrangement_inactive: false,
      can_settle_contract: false,
      can_pay_settled_balance: false,
      special_notes: false,
      hidden_notes: false,
      dnd: false,
      show_full_ssn: false,
      settlement: false,
      reauth_email: false,
      partner_settings: false,
      activate_contract: false,
      generic_platform_fees: false,
      generic_transaction_fees: false,
      generic_type_of_financing: false,
      generic_financial_settings: false,
      generic_required_breakdown: false,
      generic_contract_settings: false,
      generic_payout_schedule_option: false,
      can_admin_download: false,
      edit_contract_settings: false,
      no_fee_threshold_settings: false,
      no_fee_rate_of_interest: false,
      no_fee_financial_settings: false,
      payment_matrix_settings: false,
      pp_matrix_settings: false,
      ten_percent_settings: false,

      ez_threshold_settings: false,
      ez_rate_of_interest: false,
      ez_financial_settings: false,

      pp_enable: false,
      pp_reverse: false,
      pp_three_d: false,
      pp_settings: false,
      pp_threshold_settings: false,
      pp_instant_payout: false,
      pp_options: false,

      edit_recurring_date_settings: false,

      ar_enable: false,
      ar_reverse: false,
      ar_gp: false,
      ar_three_d: false,
      ar_settings: false,
      ar_threshold_settings: false,
      ar_instant_payout: false,
      ar_options: false,

      option_to_deduct_one_dollar: false,

      gp2_settings: false,
      instant_claim_setting: false,
      skip_seconadry_card: false,
      payment_table_settings: false,
      add_bank: false,
      verify_bank: false,
      add_bank_link: false,
      verify_bank_link: false,
      check_balance: false,
      get_money_from_business: false,
      send_money_to_business: false,
      missing_payments: false,
      future_payments: false,
      gp_payments: false,
      csr_payment_table: false,
      manager_payment_table: false,
      admin_payment_table: false,
      contract_stats: false,
      superadmin_payment_table: false,
      reverse_gp: false,
      gp_action: false,
      stripe_denefits_fee: false,
      customer_document: false,
      block_contract_creation: false,
      gp_reverse:false,
      send_contract_email: false,
      collection_fee:false,
      orp_payments:false,
      // waiver_settings: false,
      // bypass_waiver_limit: false
      transfer_money_from_business_connect: false,
      delete_bank:false,
      denefits_share_settings:false,
      late_fee_buss_share_settings: false,
      change_payment_date_buss_share_settings:false,
      send_email_connected_bank: false,
      default_financial_settings:false,
      internal_tickets:false,
      proposal_requests:false,
      my_assignments_admin_list: false,
      view_asset_report:false,
      create_liability_report:false,
      view_ach_balance: false,
      create_asset_report:false,
      change_phase_status:false,
      phase_list_select_all: false,
      phase_list_assign_to: false,
      phase_list_assign_ticket: false,
      other_details: false,
      dynamic_cancel_fee: false,
      ach_required: false,
      total_leads: false,
      payment_matrix: false,
      disputed_settings: false,
      ar_ez_financial_settings: false,
      ar_ez_rate_of_interest: false,
      ar_ez_threshold_settings: false,
      collection_share_settings: false,
      max_downpayment_percent: false,
      new_ten_financial_settings: false,
      new_ten_percent_settings: false,
      di_rate_of_interest: false,
      di_threshold_settings: false,
      di_financial_settings: false,
      ach_financial_settings: false,
      changeM2Rstatus: false,
      submit: false,
      update_ihf_nff_algo: false
    };
  }
}
