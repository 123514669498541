import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { Email } from 'src/app/components/shared/regex';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-personal-info',
  templateUrl: './edit-personal-info.component.html',
  styleUrl: './edit-personal-info.component.scss'
})
export class EditPersonalInfoComponent {
  salutation: any = [];
  id: any;
  contract: any;
  dateConfig: Partial<BsDatepickerConfig>;
  zipcode_list: any = { employer: [], patient: [], office: [] };
  contract_data: any;
  employer_yes_id: any;
  other_services = false;
  other_service_value = '';
  payment_source = [
    { id: 1, name: "Family" },
    { id: 2, name: "Guardian" },
    { id: 3, name: "Siblings" },
    { id: 4, name: "Spouse" },
    { id: 5, name: "Acquaintance" },
    { id: 6, name: "Social Security Benefits" },
    { id: 7, name: "Crowdfunding/Fund Raising" },
    { id: 8, name: "Others" }
  ];
  office_address_check: boolean = false;
  admin_name: any;
  mobile_code_arr: any;
  selectedEmployerMobileCode: any;
  selectedPatientMobileCode: any;
  maxDate = new Date();
  zipTypes = {
    zip: 'zip',
    office_zip: 'office_zip',
    employer_zip: 'employer_zip'
  }
  editPersonalInfoForm: UntypedFormGroup;

  constructor(private route: ActivatedRoute, private _local: localStorageService, private _api: ApiService, private shared: SharedService, private datePipe: DatePipe, public location: Location, public _shared: SharedService, private _fb: FormBuilder) {
    this.dateConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement: 'bottom'
    });
    this.salutation = this._shared.getSalutations();
    this.editPersonalInfoForm = this._fb.group({
      patient_first_name: ['', [Validators.required, Validators.maxLength(50)]],
      patient_last_name: ['', [Validators.required, Validators.maxLength(50)]],
      patient_email: ['', [Validators.required, Validators.maxLength(100), Validators.email, Validators.pattern(Email)]],
      patient_ssn: ['', [Validators.required, Validators.maxLength(9)]],
      patient_mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      date_of_birth: ['', [Validators.required]],
      patient_address: ['', [Validators.required, Validators.maxLength(200)]],
      patient_zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{6})?$'), Validators.maxLength(10)]],
      patient_city: ['', [Validators.required, Validators.maxLength(100)]],
      patient_state: ['', [Validators.required, Validators.maxLength(100)]],
      notes: ['', [Validators.required]],
      customer_profile_update: [''],
      all_customer_profile_update: ['']
    });
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getContractInfo(this.id);
    this.admin_name = this._local.customGetItem('admin_name');
    this.mobile_code_arr = this._shared.getMobileCodeArr();
    this.selectedPatientMobileCode = this.mobile_code_arr[0];
    this.selectedEmployerMobileCode = this.mobile_code_arr[0];
  }

  getContractInfo(id: any) {
    let data = {
      contract_id: id
    };
    this._api.api(data, 'finance/get_contract_info', 'get')
      .subscribe((data: any) => {
        if (data.is_error == 1) this.shared.customPopups(data.flag, data.is_error);
        this.contract = data.contractInfo[0];
        this.contract.date_of_birth = this.contract.date_of_birth ? this.datePipe.transform(this.contract.date_of_birth, 'MM/dd/yyyy', '+0000') : '';
        this.contract.next_payment_date = this.contract.next_payment_date ? this.datePipe.transform(this.contract.next_payment_date, 'yyyy-MM-dd', '+0000') : '';
        if (this.contract.pf_status > 3) {
          this.shared.customPopups('The contract has already been completed', 1);
          this.location.back();
          return false;
        }
        this.selectedPatientMobileCode = this.contract.patient_mobile_country_code == null ? this.selectedPatientMobileCode : this.contract.patient_mobile_country_code;
        this.selectedEmployerMobileCode = this.contract.employer_mobile_country_code == null ? this.selectedEmployerMobileCode : this.contract.employer_mobile_country_code;
        this.editPersonalInfoForm.patchValue({
          patient_first_name: this.contract.patient_first_name || '',
          patient_last_name: this.contract.patient_last_name || '',
          patient_mobile: this.contract.patient_mobile || '',
          date_of_birth: this.datePipe.transform(this.contract.date_of_birth, 'MM/dd/YYYY') || '',
          patient_email: this.contract.patient_email || '',
          patient_ssn: '*****' + this.contract.patient_ssn.slice(5, 9) || '',
          patient_address: this.contract.patient_address || '',
          patient_zip: this.contract.zip || '',
          patient_city: this.contract.city || '',
          patient_state: this.contract.state || '',
        });
        return;
      }, (error: any) => {
        console.log(error);
      })
  }

  updateContractPersonalInfo() {
    this.contract_data = { ...this.contract };
    this.contract_data.patient_mobile_country_code = this.selectedPatientMobileCode || '';
    if (this.contract.patient_first_name.length <= 2) {
      this.shared.customPopups(`First Name should be greater than 2 words.`, 1);
      return
    }
    if (this.contract.patient_last_name.length <= 2) {
      this.shared.customPopups(`Last Name should be greater than 2 words.`, 1);
      return
    }
    if (this.editPersonalInfoForm.invalid) {
      this.editPersonalInfoForm.markAllAsTouched();
      this.editPersonalInfoForm.markAsDirty();
    } else {
      let data: any = {
        patient_first_name: this.editPersonalInfoForm.value.patient_first_name,
        patient_last_name: this.editPersonalInfoForm.value.patient_last_name,
        notes: this.editPersonalInfoForm.value.notes,
        cd_payment_type: 25,
        name: this.admin_name,
        // patient_ssn: this.editPersonalInfoForm.value.patient_ssn,
        patient_address: this.editPersonalInfoForm.value.patient_address,
        patient_mobile: this.editPersonalInfoForm.value.patient_mobile.replace(/[^0-9]/g, "") || null,
        patient_mobile_country_code: this.contract_data.patient_mobile_country_code,
        date_of_birth: this.datePipe.transform(this.editPersonalInfoForm.value.date_of_birth, 'yyyy-MM-dd') || null,
        contract_id: this.contract_data.contract_id
      }
      if (this.editPersonalInfoForm.value.customer_profile_update == true) {
        data['customer_details_updated'] = 1
      } else {
        data['customer_details_updated'] = 0
      }
      if (this.editPersonalInfoForm.value.all_customer_profile_update == true) {
        data['all_customer_details_updated'] = 1
      } else {
        data['all_customer_details_updated'] = 0
      }

      if (this.editPersonalInfoForm.controls['patient_address'].value !== this.contract_data.patient_address) {
        Object.assign(data, {
          patient_city: this.editPersonalInfoForm.controls['patient_city'].value,
          patient_zip: this.editPersonalInfoForm.controls['patient_zip'].value,
          patient_state: this.editPersonalInfoForm.controls['patient_state'].value,
        });
      } else {
        Object.assign(data, {
          city_id: this.contract_data.city_id,
        });
      }
      this._api.api(data, 'finance/edit_pf_details')
        .subscribe((data: any) => {
          if (data && !data.is_error) {
            this.location.back();
          } else {
            this._shared.customPopups(data.err || data.override_txt, 1);
            return;
          }
        }, (error: any) => {
          console.log(error);
        });
    }

  }

  maxCheckValue(num: number, no_deduct: any) {
    if (!num) {
      return 0;
    }
    num = Number(num);
    return no_deduct ? num : num - 1;
  }

  changeMobileCode(mobile_code: any) {
    this.selectedPatientMobileCode = mobile_code;
  }

  getZipselected(address: any) {
    this.editPersonalInfoForm.patchValue({
      patient_state: address.state_name,
      patient_city: address.city,
      patient_zip: address.zip,
      patient_address: address.item && address.item.name ? address.item.name : ''
    })
  }
}