<div class="row mb-3">
  <h3 id="manage-doctors" class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
    Pre Approval leads
  </h3>
  <div class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
    <span class="count rounded py-2"
      >Total Pre Approval leads: {{ totalRecords ? totalRecords : 0 }}</span
    >
  </div>
</div>
<div>
  <div class="row m-0 align-items-center mb-3 mt-lg-3" [hidden]="!show_filters">
    <app-filters
      class="row m-0 w-100"
      [filters]="filters"
      (finalValues)="filterValues($event)"
      (resetAllFilters)="resetFilters($event)"
    >
    </app-filters>
  </div>

  <div class="card">
    <div class="row m-0 py-2">
      <div class="col-sm-6 col-md-6 col-lg-6 pt-2">
        <p class="h4 text-bold">
          {{
            dateRangeValue && dateRangeValue[0]
              ? (dateRangeValue[0] | date : "mediumDate")
              : ""
          }}
          to
          {{
            dateRangeValue && dateRangeValue[1]
              ? (dateRangeValue[1] | date : "mediumDate")
              : ""
          }}
        </p>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 text-right">
        <button
          (click)="showFilter(!show_filters)"
          type="button"
          class="btn mb-3 mt-3"
          [ngClass]="{
            'btn-primary': show_filters,
            'btn-default': !show_filters
          }"
        >
          <span style="font-size: 16px">{{
            show_filters ? "Hide Filters" : "Show Filters"
          }}</span>
          <i class="fa fa-filter"></i>
        </button>
        <div
          class="btn-group ml-2"
          dropdown
          *ngIf="!_shared.routerAuth.restricted_buttons.export"
        >
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-basic"
          >
            Export <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right animated flipInX"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem">
              <button class="dropdown-item" tappable (click)="exportXLSX(0)">
                Export XLSX
              </button>
            </li>
            <li role="menuitem">
              <button class="dropdown-item" tappable (click)="exportXLSX(1)">
                Export CSV
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="table-responsive"
      *ngIf="business_leads && business_leads.length"
    >
      <table class="table">
        <thead>
          <tr>
            <!-- <th style="min-width: 50px;">SNo.</th> -->
            <th>S No.</th>
            <th>Leads Info</th>
            <th>Business Info</th>
            <th>Contract Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let leadlist of business_leads; index as i">
            <!-- <td>{{leadlist.id}}</td> -->
            <td>{{ (resData.pageNumber - 1) * 10 + (i + 1) }}</td>
            <td>
              <div class="row m-0">
                <div class="col-8 p-0">
                  <span class="text-muted"> Application ID : </span
                  >{{ leadlist.application_id || "-" }}
                </div>
              </div>

              <div class="row m-0">
                <div class="col-8 p-0">
                  <span class="text-muted">Email : </span
                  >{{ leadlist.customer_email || "-" }}
                </div>
              </div>
              <div class="row m-0">
                <div class="col-8 p-0">
                  <span class="text-muted">Phone : </span
                  >{{
                    leadlist.customer_mobile
                      ? leadlist.customer_mobile_country_code +
                        "-" +
                        leadlist.customer_mobile
                      : ""
                  }}
                </div>
              </div>
            </td>
            <td>
              <div class="row m-0">
                <div class="col-8 p-0">
                  <span class="text-muted">Name : </span
                  >{{
                    leadlist && leadlist.practice_name
                      ? leadlist.practice_name
                      : leadlist.doctor_name
                  }}
                </div>
              </div>

              <div class="row m-0">
                <div class="col-8 p-0">
                  <span class="text-muted">Email : </span
                  >{{
                    leadlist.practice_email
                      ? leadlist.practice_email
                      : leadlist.doctor_email || "-"
                  }}
                </div>
              </div>
            </td>
            <td>
              <div class="row m-0">
                <div class="col-8 p-0">
                  <span class="text-muted">Contract Id : </span
                  >{{ leadlist.contract_code || "-" }}
                </div>
              </div>
            </td>
            <td>
              <div class="col-12 p-0">
                <button
                  class="btn btn-primary btn-xs m-1"
                  (click)="leads_detail(leadlist)"
                >
                  View Details
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="row m-0 border-top pt-2 align-items-center"
      *ngIf="business_leads && business_leads.length"
    >
      <div class="col-md-6 col-sm-12">
        <p class="text-bold">
          Showing {{ 1 + (resData.pageNumber - 1) * 10 }} to
          {{ business_leads.length + (resData.pageNumber - 1) * 10 }} of
          {{ totalRecords || "all" }}
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <pagination
          class="pagination-sm float-md-right"
          [totalItems]="totalRecords"
          [maxSize]="5"
          [boundaryLinks]="true"
          [rotate]="false"
          (pageChanged)="pageChanged($event)"
          [(ngModel)]="resData.pageNumber"
        >
        </pagination>
      </div>
    </div>
  </div>
  <div class="col-12 text-center mt-3" *ngIf="business_leads.length == 0">
    <img src="./assets/img/SVG/empty/patients.svg" />
    <h4 style="color: #8592a5">No leads Found</h4>
  </div>
</div>
