import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MainService } from '../main.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { currencyType, SharedService } from '../shared.service';
import { localStorageService } from '../localstorage.service';
import { DatePipe } from '@angular/common';
import { AddNotesComponent } from '../add-notes/add-notes.component';
import { ApiService } from '../api.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-common-notes-listing',
  templateUrl: './common-notes-listing.component.html',
  styleUrl: './common-notes-listing.component.scss'
})

export class CommonNotesListingComponent implements OnInit, OnDestroy {
  bsConfig!: Partial<BsDatepickerConfig>;
  allNoteList: any = [];
  showNewNotes: number = 1;
  bsModalRef!: BsModalRef;
  id: any;
  loader: boolean = false;
  minimumDate: Date = new Date(2023, 0, 1);
  maximumDate: Date = new Date();
  dateRangeValue: any;
  export_type = 0;
  @Input() type!: string;
  @Input() data!: any;
  @Input() notesId!: number; //1 for Business, 2 for patient, 3 for partner, 4 for contracts, //5 for User
  timezone!: string;
  timezoneName!: string;
  getAdminList: Array<[]> = [];
  industryList: Array<[]> = [];
  /* Filter Notes */
  notesTicket: any = [
    {
      'label': 'All',
      'value': '0'
    },
    {
      'label': 'Yes',
      'value': '1'
    },
    {
      'label': 'No',
      'value': '2'
    }
  ];
  selectedNotesTicket = this.notesTicket[0].value;
  resetBtn!: boolean;
  dataArguements: any = {
    limit: 10,
    filter: '',
    admin_id: 0,
    is_issue: 0,
    order_by: 0,
    search_string: '',
    internal_ticket_filter: 0
  };

  filter: any = {
    pending: false,
    re_open: false,
    closed: false,
    follow_up: false
  }
  filtersArray: any = [];
  csr_request_types = [];
  timezone_subscription: any;
  currencyData!: currencyType;

  constructor(public mainService: MainService,
    private modalService: BsModalService,
    public _shared: SharedService,
    private _local: localStorageService,
    private _api: ApiService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    const country = this._shared.getCountryData();
    const con: any = country.find((item: any) => item['country_id'] == this._local.customGetItem('country_id'));
    if (con && con.currency && con.currency.length) {
      this.currencyData = con[0];
    }
    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();
    this.dateRangeValue = [this.minimumDate, this.maximumDate];
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement: 'bottom'
    });
    this.getAdminList = this._shared.getAdminList();
    this.industryList = this._shared.getIndustryList();
    this.getAssignments();
  }

  addContractNotesModal() {
    let initialState: any = {
      data: {
        doctor_profile: [this.data],
        contract_info: [this.data],
        proposal_info: [this.data],
        csr_notes: this.allNoteList,
        csr_request_types: this.csr_request_types,
        csr_requests: this.allNoteList,
        partner_id: this.data['partner_id'],
        patient_id: this.data['patient_id'],
        preapproval_lead_id: this.notesId
      }
    };
    if (this.type == 'contract') {
      initialState.data['assign_type'] = 4;
    }

    if (this.type == 'doctor') {
      initialState.data['assign_type'] = 1;
    }

    if (this.type == 'patient') {
      initialState.data['assign_type'] = 2;
    }

    if (this.type == 'partner') {
      initialState.data['assign_type'] = 3;
    }

    if (this.type == 'proposal') {
      initialState.data['assign_type'] = 6;
    }
    if (this.type == 'preapproval_leads') {
      initialState.data['assign_type'] = 7;
    }
    this.mainService.noteadded = false;
    this.bsModalRef = this.modalService.show(AddNotesComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.mainService.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          count = 1;
        }
      }
      this.getAssignments();
    })
  };

  dateChange() {
    this.resetBtn = true;
  }
  resetDate() {
    this.dateRangeValue = [this.minimumDate, this.maximumDate];
    this.resetBtn = false;
    this.getAssignments();
  }

  selectAdmin(event: any) {
    this.dataArguements.admin_id = event;
    this.getAssignments();
  }

  adminClear(event: any) {
    if (event.length == 0) {
      this.dataArguements.admin_id = 0;
      this.getAssignments();
    }
  }

  onChangeFilters(value: any) {
    if (this.filtersArray.includes(value)) {
      this.filtersArray.splice(this.filtersArray.indexOf(value), 1);
      this.getAssignments();
      return;
    }
    this.filtersArray.push(value);
    this.getAssignments();
  }

  resetSearch() {
    this.dataArguements.search_string = "";
    this.getAssignments();
  }

  searchRecords() {
    if (this.dataArguements.search_string && this.dataArguements.search_string.trim()) {
      this.dataArguements.search_string = this.dataArguements.search_string.trim()
      //this.getInternalTicket();
    }
  }

  filterNotesWithTicekt(d: number) {
    if (d == 2) {
      this.filtersArray = [];
      this.filter = {
        pending: false,
        re_open: false,
        closed: false,
        follow_up: false
      }
    }
    this.getAssignments();
  }

  /* Filter Notes */
  getAssignments() {
    this.allNoteList = [];
    let data: any = {
      limit: this.dataArguements.limit,
      start_date: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
      status: this.filtersArray && this.filtersArray.length ? this.filtersArray.toString() : undefined,
      admin_id: 0,
      notes_with_tickets: this.selectedNotesTicket
    };
    if (data.notes_with_tickets == 2) {
      delete data.status;
    }

    if (this.type == 'contract') {
      data['contract_id'] = this.notesId
      this.export_type = 4;
    }

    if (this.type == 'doctor') {
      data['doctor_id'] = this.notesId;
      this.export_type = 1;
    }

    if (this.type == 'patient') {
      data['patient_id'] = this.notesId;
      this.export_type = 2;
    }

    if (this.type == 'partner') {
      data['partner_id'] = this.notesId;
      this.export_type = 3;
    }

    if (this.type == 'proposal') {
      data['split_token'] = this.notesId;
      this.export_type = 6;
    }
    if (this.type == 'preapproval_leads') { //Preapproval leads
      data['preapproval_lead_id'] = this.notesId;
      this.export_type = 7;
    }

    if (this.dataArguements.admin_id > 0) {
      data.admin_id = parseInt(this.dataArguements.admin_id)
    } else {
      delete data.admin_id;
    }
    if (this.dataArguements.search_string &&  this.dataArguements.search_string.trim().length > 0) {
      data['search_string'] = this.dataArguements.search_string.trim();
    }
    this.loader = true;
    this._api.api(data, 'business/filter_with_notes')
      .subscribe((data: any) => {
        this.allNoteList = data.csr_notes;
        this.csr_request_types = data.csr_request_types;
        this.loader = false;
      });
  };

  latestTicketComment: any = [];
  idExist = false;
  getTicketComments(id: any) {
    this.idExist = false
    this.latestTicketComment.forEach((ele: any) => {
      if (ele.id === id) {
        this.idExist = true;
        return;
      }
    });
    if (!this.idExist) {
      let params = {
        req_id: id
      };
      this._api.api(params, 'csr_request_details')
        .subscribe((data: any) => {
          this.latestTicketComment.push({
            'id': id,
            'notes': data.notes
          });
        }, (error: any) => {
          console.log(error);
        });
    }
  }

  ngOnDestroy() {
    if (this.timezone_subscription) {
      this.timezone_subscription.unsubscribe()
    }
  }
}
