import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ApiService } from '../api.service';
import { localStorageService } from '../localstorage.service';
import { SharedService } from '../shared.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../customers.service';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-update',
  templateUrl: './customer-update.component.html',
  styleUrls: ['./customer-update.component.scss'],
})
export class UpdateCustomerModalComponent {
  public customerDetails!: UntypedFormGroup;
  statusClosed = true;
  public data: any;
  modalType = '';
  office_zip = '';
  zipPostalCode = '';
  customerDob = '';
  zipCode: any = {};
  empZipCode = {};
  currency_info: any = {};
  customerInfo: any = [];
  country_info: any = [];
  SameAddress: any;
  leads_data: any;

  email_regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  invalid_input_class = {};
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  phone_mask: any = '000-000-0000';

  allowed_payback_methods = [
    'Family',
    'Spouse',
    'Guardian',
    'Siblings',
    'Acquaintance',
    'Siblings',
    'Social Security Benefits',
    'Crowdfunding/Fundraising',
    'Others',
  ];

  bsConfig: Partial<BsDatepickerConfig>;
  constructor(
    public _local: localStorageService,
    public _shared: SharedService,
    private router: Router,
    private _route: ActivatedRoute,
    public _fb: FormBuilder,
    private datePipe: DatePipe,
    public _api: ApiService,
    public _customer: CustomerService
  ) {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-green',
        dateInputFormat: 'MM/DD/YYYY',
        placement: 'bottom',
      }
    );
  }

  ngOnInit() {
    if (this.modalType == this._customer.modalTypes.customerDetails) {
      console.log('--->', this.customerInfo);
      this.customerDetails = this._fb.group({
        gender: [this.customerInfo.gender],
        customerFirstName: this.customerInfo.customer_first_name,
        customerLastName: [this.customerInfo.customer_last_name],
        customerDob: [this.customerDob],
        socailSecuityNumber: [
          '',
          [Validators.pattern(/^[^*|\":<>[\]{}`\\()';#%!@&$]+$/)],
        ],
        phoneNumber: [this.customerInfo.customer_mobile],
        emailAddress: [
          this.customerInfo.customer_email,
          [Validators.pattern(this.email_regex)],
        ],
        dlNumber: [this.customerInfo.dl_number],
        address: [this.customerInfo.add_address],
        employed: [''],
        zipPostalCode: [''],
        state: [{ value: '', disabled: true }],
        city: [{ value: '', disabled: true }],
        empName: [''],
        empPhoneNumber: [''],
        empZipCode: [''],
        empAddress: [''],
        empState: [{ value: '', disabled: true }],
        empCity: [{ value: '', disabled: true }],
        empAnnualIncome: [''],
        document: [''],
        office_address: [''],
        office_state: [{ value: '', disabled: true }],
        office_zip: [''],
        office_city: [{ value: '', disabled: true }],
        office_city_id: [''],
        check_office: false,
        payment_source_reference: [''],
        payment_source: '',
      });
    }

    this.customerDetails.valueChanges.subscribe(() => {
      this.updateformstatus(
        this.customerDetails,
        this.invalid_input_class,
        false
      );
    });
  }

  omit_special_char(event: { charCode: any }) {
    let k;
    k = event.charCode; //k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  mobile_formate(m_number: any) {
    if (m_number.includes('+1-')) {
      m_number = m_number.slice(3);
    }
    m_number = m_number.replaceAll('-', '');
    m_number = m_number.split('');
    m_number.splice(3, 0, '-');
    m_number.splice(7, 0, '-');
    return m_number.join('');
  }

  changeFeilds(i: any) {
    try {
      if (i) {
      }
      this.customerDetails.get('employed')!.setValue(i);
      if (i == 0 || i == '0') {
        this.customerDetails.addControl(
          'zipPostalCode',
          this._fb.control(
            (this.customerInfo && this.customerInfo.customer_zip) || ''
          )
        );
        this.customerDetails.addControl(
          'empZipCode',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_zip) || ''
          )
        );
        this.empZipCode =
          this.customerInfo && this.customerInfo.employer_zip
            ? this.customerInfo && this.customerInfo.employer_zip
            : '';
        this.customerDetails.controls['empZipCode'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'empName',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_name) || ''
          )
        );
        this.customerDetails.controls['empName'].setValidators([
          Validators.required,
        ]);

        this.customerDetails.addControl(
          'empPhoneNumber',
          this._fb.control(
            this.customerInfo && this.customerInfo.employer_mobile
              ? this.customerInfo &&
                  this.mobile_formate(this.customerInfo.employer_mobile)
              : ''
          )
        );
        // this.customerDetails.controls.empPhoneNumber.setValidators([Validators.required]);

        this.customerDetails.addControl(
          'empAddress',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_address) || ''
          )
        );
        // this.customerDetails.controls.empAddress.setValidators([Validators.required]);

        this.customerDetails.addControl(
          'empAnnualIncome',
          this._fb.control(
            this.customerInfo && this.customerInfo.annual_income > 0
              ? this.customerInfo.annual_income
              : ''
          )
        );
        this.customerDetails.controls['empAnnualIncome'].setValidators([
          Validators.required,
        ]);

        this.customerDetails.addControl(
          'empState',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_state) || ''
          )
        );
        this.customerDetails.addControl(
          'empCity',
          this._fb.control(
            (this.customerInfo && this.customerInfo.employer_city) || ''
          )
        );
        this.customerDetails.controls['empCity'].setValidators([
          Validators.required,
        ]);

        // remove other controls
        if (this.customerDetails.controls['office_address'] !== undefined) {
          // this.customerDetails.removeControl('office_address');
          // this.customerDetails.removeControl('office_city');
          // this.customerDetails.removeControl('office_city_id');
          // this.customerDetails.removeControl('office_state');
          // this.customerDetails.removeControl('office_zip');
          // this.customerDetails.removeControl('profession');
          // this.customerDetails.removeControl('annual_income');
          this.customerDetails.get('check_office')!.setValue(false);
          this.office_zip = '';
        } else {
          // this.customerDetails.removeControl('payment_source');
        }
      }
      if (i == 1 || i == '1') {
        // this.customerDetails.addControl('payment_source', this.fb.control(this.allowed_payback_methods.includes(this.customerInfo.payback_methods) ? this.customerInfo.payback_methods : !this.customerInfo.payback_methods ? '' : 'Others', [Validators.required]));
        this.customerDetails
          .get('payment_source')!
          .setValue(
            this.allowed_payback_methods.includes(
              this.customerInfo.payback_methods
            )
              ? this.customerInfo.payback_methods
              : !this.customerInfo.payback_methods
              ? ''
              : 'Others'
          );

        if (
          !this.allowed_payback_methods.includes(
            this.customerInfo.payback_methods
          )
        ) {
          // this.customerDetails.controls.payment_source_reference.setValidators(Validators.required);
          this.customerDetails
            .get('payment_source_reference')!
            .setValue(this.customerInfo.payback_methods);
        }

        if (this.customerDetails.controls['office_address'] !== undefined) {
          // this.customerDetails.removeControl('office_address');
          // this.customerDetails.removeControl('office_city');
          // this.customerDetails.removeControl('office_city_id');
          // this.customerDetails.removeControl('office_state');
          // this.customerDetails.removeControl('office_zip');
          // this.customerDetails.removeControl('profession');
          // this.customerDetails.removeControl('annual_income');
          this.customerDetails.get('check_office')!.setValue(false);
          this.office_zip = '';
        }
        if (this.customerDetails.controls['empAddress'] !== undefined) {
          // this.customerDetails.removeControl('empZipCode');
          // this.customerDetails.removeControl('empName');
          // this.customerDetails.removeControl('empPhoneNumber');
          // this.customerDetails.removeControl('empAddress');
          // this.customerDetails.removeControl('empAnnualIncome');
        }
      }
      if (i == 2 || i == '2') {
        this.customerDetails.addControl(
          'office_address',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_address) || ''
          )
        );
        this.customerDetails.controls['office_address'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'profession',
          this._fb.control(
            (this.customerInfo && this.customerInfo.profession) || ''
          )
        );
        this.customerDetails.controls['profession'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'office_city',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_city) || ''
          )
        );
        this.customerDetails.controls['office_city'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'office_city_id',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_city_id) || ''
          )
        );
        // this.customerDetails.controls.office_city_id.setValidators([Validators.required]);
        this.customerDetails.addControl(
          'office_state',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_state) || ''
          )
        );
        this.customerDetails.controls['office_state'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'office_zip',
          this._fb.control(
            (this.customerInfo && this.customerInfo.office_zip) || ''
          )
        );
        this.customerDetails.controls['office_zip'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.addControl(
          'annual_income',
          this._fb.control(
            (this.customerInfo && this.customerInfo.annual_income) || ''
          )
        );
        if (this.customerDetails.value.annual_income == 0) {
          this.customerDetails.get('annual_income')!.setValue('');
        }
        this.customerDetails.controls['annual_income'].setValidators([
          Validators.required,
        ]);
        this.customerDetails.get('office_city')?.disable();
        this.customerDetails.get('office_state')?.disable();
        let zip = {
          zip: this.customerInfo && this.customerInfo.office_zip,
          state_name: this.customerInfo && this.customerInfo.office_state,
          city_id: this.customerInfo && this.customerInfo.office_city_id,
          city: this.customerInfo && this.customerInfo.office_city,
        };

        this.getOfficeZipselected(zip);

        // remove other controls
        if (this.customerDetails.controls['empAddress'] !== undefined) {
          // this.customerDetails.removeControl('empZipCode');
          // this.customerDetails.removeControl('empName');
          // this.customerDetails.removeControl('empPhoneNumber');
          // this.customerDetails.removeControl('empAddress');
          // this.customerDetails.removeControl('empAnnualIncome');
          // this.customerDetails.removeControl('empState');
          // this.customerDetails.removeControl('empCity');
        }
      }

      if (i === '1' || i === 1 || i === 2 || i === '2') {
        if (this.customerDetails.controls['empAddress'] !== undefined) {
          // this.customerDetails.removeControl('empZipCode');
          // this.customerDetails.removeControl('empName');
          // this.customerDetails.removeControl('empPhoneNumber');
          // this.customerDetails.removeControl('empAddress');
          // this.customerDetails.removeControl('empAnnualIncome');
        }
        if (i === 0 || i === '0' || i === '2' || i === 2) {
          // this.customerDetails.removeControl('payment_source')
        }
      }
      this.customerDetails.updateValueAndValidity();
    } catch (error) {
      console.log(error);
    }
  }

  Cancel() {
    throw new Error('Method not implemented.');
  }

  updateformstatus(
    form: UntypedFormGroup,
    invalid_class_array: {
      [x: string]: { [x: string]: boolean };
      hasOwnProperty?: any;
    },
    skip_edit_input_element_check: boolean,
    mark_all_input_valid = false
  ) {
    if (form.hasOwnProperty('controls')) {
      for (const key in form.controls) {
        const element = form.controls[key];
        if (element.hasOwnProperty('controls')) {
          if (!invalid_class_array.hasOwnProperty(key)) {
            invalid_class_array[key] = {};
          }
          this.updateformstatus(
            element as UntypedFormGroup,
            invalid_class_array[key] as any,
            skip_edit_input_element_check,
            mark_all_input_valid
          );
        } else {
          if (!invalid_class_array.hasOwnProperty(key)) {
            invalid_class_array[key] = { 'has-error': false };
          }
          if (
            (element.dirty == true || skip_edit_input_element_check) &&
            element.valid == false
          ) {
            invalid_class_array[key]['has-error'] = true;
          } else if (element.valid == true) {
            invalid_class_array[key]['has-error'] = false;
          }
          if (element.value != '') {
            element.markAsDirty();
          } else {
            element.markAsPristine();
          }
          if (mark_all_input_valid) {
            invalid_class_array[key]['has-error'] = false;
            // if (key == "annual_income") {
            //   element.markAsPristine()
            // }
          }
        }
      }
    } else return;
  }

  validate_ssn() {
    const invalid_ssn = [
      '002281852',
      '042103580',
      '062360749',
      '078051120',
      '095073645',
      '128036045',
      '135016629',
      '141186941',
      '165167999',
      '165187999',
      '165207999',
      '212099999',
      '165227999',
      '306302348',
      '165247999',
      '189092294',
      '308125070',
      '212097694',
      '549241889',
      '468288779',
    ];

    if (this.customerDetails.get('socailSecuityNumber')!.value) {
      let patient_ssn = String(
        this.customerDetails.get('socailSecuityNumber')!.value
      );
      if (
        patient_ssn.trim().length != 9 ||
        patient_ssn.slice(5, 9) == '0000' ||
        patient_ssn.slice(3, 5) == '00' ||
        /^(.)\1+$/.test(patient_ssn) ||
        /[a-zA-Z]/.test(patient_ssn) ||
        patient_ssn.slice(0, 3) == '000' ||
        patient_ssn.slice(0, 3) == '666' ||
        invalid_ssn.indexOf(patient_ssn) >= 0 ||
        (parseInt(patient_ssn) >= 987654320 &&
          parseInt(patient_ssn) <= 987654329)
      ) {
        this.customerDetails
          .get('socailSecuityNumber')!
          .setErrors({ invalidSSN: true });
        this.customerDetails.updateValueAndValidity();
      }
    }
  }

  getZipselected(zipObj: string, from_google_api = 0) {
    try {
      this.zipCode = zipObj;
      if (zipObj === '') {
        this.zipPostalCode = '';
        this.customerDetails.controls['zipPostalCode'].setValue('');
        this.customerDetails.controls['city'].setValue('');
        this.customerDetails.controls['state'].setValue('');
        this.customerDetails.markAsDirty();
      } else {
        this.zipPostalCode = this.zipCode.zip;
        this.customerDetails.controls['zipPostalCode'].setValue(
          this.zipCode.zip
        );
        this.customerDetails.controls['state'].setValue(
          this.zipCode.state_name
        );
        this.customerDetails.controls['city'].setValue(this.zipCode.city);
        this.customerDetails.markAsDirty();
      }
    } catch (err) {
      console.log(err);
    }
  }

  FillOfficeAddress(e: any) {
    if (e.target.checked) {
      this.SameAddress = true;
      this.customerDetails
        .get('office_address')!
        .setValue(this.customerDetails.value.address);
      this.getOfficeZipselected(this.zipCode);
    } else {
      this.SameAddress = false;
      this.getOfficeZipselected('');
      this.customerDetails.get('office_address')!.enable();
      this.customerDetails.get('office_address')!.setValue('');
      this.customerDetails.get('office_address')!.markAsPristine();
      this.customerDetails.get('office_zip')!.enable();
    }
    this.customerDetails.updateValueAndValidity();
  }

  /* Get Emp Zip Code */
  empZipCodeData: any = {
    city_id: '',
  };
  getEmpZipselected(zipObj: any, from_google_api = 0) {
    if (zipObj == '') {
      this.empZipCode = '';
      this.customerDetails.controls['empZipCode']!.setValue('');
      this.customerDetails.controls['empState']!.setValue('');
      this.customerDetails.controls['empCity']!.setValue('');
    } else {
      this.empZipCode = zipObj.zip;
      this.empZipCodeData = zipObj;
      this.customerDetails.controls['empZipCode'].setValue(zipObj.zip);
      this.customerDetails.controls['empState'].setValue(zipObj.state_name);
      this.customerDetails.controls['empCity'].setValue(zipObj.city);
    }
  }
  office_zipObj: any;
  getOfficeZipselected(zipObj: any, from_google_api = 0) {
    if (zipObj) {
      this.office_zipObj = zipObj;
      this.office_zip = zipObj.zip;
      this.customerDetails.controls['office_zip'].setValue(
        this.office_zipObj.zip || ''
      );
      this.customerDetails.controls['office_state'].setValue(
        this.office_zipObj.state_name || ''
      );
      this.customerDetails.controls['office_city'].setValue(
        this.office_zipObj.city || ''
      );
      this.customerDetails.controls['office_city_id'].setValue(
        this.office_zipObj.city_id || ''
      );
    } else {
      this.office_zip = '';
      this.customerDetails.controls['office_zip'].setValue('');
      this.customerDetails.controls['office_state'].setValue('');
      this.customerDetails.controls['office_city'].setValue('');
      this.customerDetails.controls['office_city_id'].setValue('');
    }
  }
  check_home_add_exist() {
    if (
      this.customerDetails.controls['city'].value &&
      this.customerDetails.controls['zipPostalCode'].value &&
      this.customerDetails.controls['address'].value
    ) {
      return true;
    }
    return false;
  }

  validateDateOfBirth(customerDetails: any) {
    return new Promise((resolve, reject) => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      let customerDob = customerDetails.get('customerDob').value;
      customerDob = moment(this.customerDob).format('MM-DD-YYYY');
      console.log(customerDob, 'customerDob');

      let valueSet: any;
      if (!customerDob || customerDob.trim() === '') {
        resolve({ futureDate: true });
        return;
      }

      if (customerDob.includes('-') || customerDob.includes('/')) {
        const dobParts = customerDob.split(/[-/]/);
        const change =
          dobParts[0].padStart(2, '0') +
          '/' +
          dobParts[1].padStart(2, '0') +
          '/' +
          dobParts[2];
        valueSet = change;
      } else {
        const dobParts = [
          customerDob.slice(0, 2),
          customerDob.slice(2, 4),
          customerDob.slice(4),
        ];
        const change = dobParts.join('/');
        valueSet = change;
        // customerDetails.controls.customerDob.setValue(change);
      }

      const dobParts = valueSet && valueSet.split('/');
      const month = parseInt(dobParts[0]);
      const day = parseInt(dobParts[1]);
      const year = parseInt(dobParts[2]);
      if (
        isNaN(month) ||
        isNaN(day) ||
        isNaN(year) ||
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31 ||
        year < 1900 ||
        year > currentYear
      ) {
        resolve({ futureDate: true });
        return;
      }

      const dobDate = new Date(year, month - 1, day);

      if (
        dobDate.getDate() !== day ||
        dobDate.getMonth() + 1 !== month ||
        dobDate.getFullYear() !== year
      ) {
        resolve({ futureDate: true });
        return;
      }

      if (dobDate > currentDate) {
        resolve({ futureDate: true });
        return;
      }

      const minDobDate = new Date(
        currentYear - 120,
        currentMonth - 1,
        currentDay
      );
      if (dobDate < minDobDate) {
        resolve({ futureDate: true });
        return;
      }

      resolve({ futureDate: false });
      // 04/23/1904
    });
  }

  changeSelection() {
    if (this.customerDetails.value.payment_source == 'Others') {
      // this.customerDetails.controls.payment_source_reference.setValidators(Validators.required);
      this.customerDetails.addControl(
        'payment_source_reference',
        this._fb.control(
          this.allowed_payback_methods.includes(
            this.customerInfo.payback_methods
          )
            ? ''
            : this.customerInfo.payback_methods
        )
      );
      this.customerDetails.controls['payment_source'].setValidators(
        Validators.required
      );
    }
    this.customerDetails.updateValueAndValidity();
  }

  async customerDetailsSubmit() {
    if (
      this.customerDetails.get('socailSecuityNumber') &&
      this.customerDetails.get('socailSecuityNumber')!.invalid == true
    ) {
      return;
    }
    if (
      this.customerDetails.get('phoneNumber') &&
      this.customerDetails.get('phoneNumber')!.invalid == true
    ) {
      return;
    }
    if (
      this.customerDetails.get('emailAddress') &&
      this.customerDetails.get('emailAddress')!.invalid == true
    ) {
      return;
    }
    if (
      this.customerDetails.get('empPhoneNumber') &&
      this.customerDetails.get('empPhoneNumber')!.invalid == true
    ) {
      return;
    }
    if (this.customerDob) {
      const dob_check: any = await this.validateDateOfBirth(
        this.customerDetails
      );
      if (dob_check && dob_check.futureDate) {
        this._shared.customPopups('please enter valid dob', 2);
        return;
      }
    }

    let form_value = this.customerDetails.value;

    let data: any = {
      application_id: this.leads_data.application_id,
      doctor_id: this.leads_data.doctor_id,
      is_update_custdetails: 1,
      customer_first_name: form_value.customerFirstName,
      customer_last_name: form_value.customerLastName,
      customer_email: form_value.emailAddress,
      customer_mobile: form_value.phoneNumber
        ? '+1-' + form_value.phoneNumber
        : '',
      customer_address: form_value.address,
      date_of_birth: this.datePipe.transform(
        form_value.customerDob,
        'yyyy-MM-dd'
      ),
      dl_number: form_value.dlNumber,
      ssn: form_value.socailSecuityNumber,
      annual_income: form_value.annual_income,
      employer_address: form_value.empAddress,
      employer_mobile: form_value.empPhoneNumber,
      employer_name: form_value.empName,
      employement_type:
        form_value.employed == 2
          ? 3
          : form_value.employed == 0
          ? 1
          : form_value.employed == 1
          ? 2
          : null,
      office_address: form_value.office_address,
      profession: form_value.profession,
      payback_methods: form_value.payment_source,
      customer_gender: form_value.gender,
    };

    if (
      this.customerDetails.controls &&
      this.customerDetails.controls['payment_source'] &&
      this.customerDetails.controls['payment_source'].value == 'Others'
    ) {
      data['payback_methods'] =
        this.customerDetails.controls['payment_source_reference'].value;
    }
    console.log(this.zipCode, 'zipCode');
    console.log(this.office_zipObj, 'office_zipObj');
    console.log(this.empZipCodeData, 'empZipCodeData');
    if (this.zipCode && this.zipCode.city_id) {
      (data.customer_city = (this.zipCode && this.zipCode.city) || null),
        (data.customer_city_id =
          (this.zipCode && this.zipCode.city_id) || null),
        (data.customer_state =
          (this.zipCode && this.zipCode.state_name) || null),
        (data.customer_zip = form_value.zipPostalCode);
    }
    if (form_value.employed == 0) {
      if (this.empZipCodeData && this.empZipCodeData.city_id) {
        data.employer_city =
          (this.empZipCodeData && this.empZipCodeData.city) || null;
        data.employer_city_id =
          (this.empZipCodeData && this.empZipCodeData.city_id) || null;
        data.employer_state =
          (this.empZipCodeData && this.empZipCodeData.state_name) || null;
        data.employer_zip = form_value.empZipCode;
      }
      data.annual_income = form_value.empAnnualIncome;
    } else if (form_value.employed == 2) {
      if (this.office_zipObj && this.office_zipObj.city_id) {
        data.office_city =
          (this.office_zipObj && this.office_zipObj.city) ||
          this.customerDetails.getRawValue().office_city ||
          null;
        data.office_city_id =
          (this.office_zipObj && this.office_zipObj.city_id) ||
          this.customerDetails.getRawValue().office_city_id ||
          null;
        data.office_state =
          (this.office_zipObj && this.office_zipObj.state_name) ||
          this.customerDetails.getRawValue().office_state ||
          null;
        data.office_zip =
          (this.office_zipObj && this.office_zipObj.zip) ||
          this.customerDetails.getRawValue().office_zip ||
          null;
      }
    }

    this._api
      .api(data, 'update_leads_details', 'put')
      .subscribe((data: any) => {
        this._customer.modalTypes.customerDetails = '';
        if (data.is_error == 0) {
          this._shared.customPopups('Updated successfully', 1);
          //   this.get_leads_info();
          //   this.load_notes = false;
        } else if (data.is_error == 1) {
          this._shared.customPopups(data.err, 1);
          // modal.hide();
        }
      });
  }

  enterNumberOnly(event: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
